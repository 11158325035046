import React, { ForwardedRef, ReactText, RefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';

import Accordion from '../Accordion/Accordion';
import { editorReducerValues, storeDataFromLayers } from '../../../../Editor.reducer';
import { settingsReducerValues } from '../../../../../Settings/Settings.reducer';
import {
    fetchPlacesTenants,
    tenantsReducerValues,
    storeSelectedPlace,
    selectTenant,
} from '../../../Tenants/Tenants.reducer';
import { PrimaryButton, TextField } from '@fluentui/react';
import { IPlaceByMarker, ITenant } from '../../../Tenants/Tenants.interfaces';
import Modal from '../../../../../Modals/Modals';
import { locationsReducerValues } from '../../../../../Locations/Locations.reducer';
import { useAppDispatch } from '../../../../../../store';

import { IProps } from './interfaces';
import ListItem from './components/ListItem/ListItem';
import CreateNewTenantModal from './components/CreateNewTenantModal/CreateNewTenantModal';
import { IModalStatus } from './components/CreateNewTenantModal/interfaces';
import { TenantsWrapper, buttonStyles } from './styles';
import StackHorizontal from '../../../../../StackHorizontal/StackHorizontal';

const TenantsList = ({ visible, onShowLabelIconClick, showLabelsIds }: IProps) => {
    const scollToRef = useRef<HTMLDivElement>(null);
    const [extendedPlacesSearch, setExtendedPlacesSearch] = React.useState<boolean>(true);
    const [extendedTenantsSearch, setExtendedTenantsSearch] = React.useState<boolean>(true);
    
    const [refreshRelations, setRefreshRelations] = React.useState<boolean>(true);
    const [addTenantModal, setAddTenantModal] = useState<IModalStatus>({ show: false });
    const [placesFilterValue, setPlacesFilterValue] = React.useState<string>('');
    const [tenantsFilterValue, setTenantsFilterValue] = React.useState<string>('');
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { allInitialLayers, savedLayersFlag, selectedObjectId, selectedChapter, backId, dataFromLayers } =
        useSelector(editorReducerValues);
    const { activeProjectLocation } = useSelector(locationsReducerValues);
    const { tenants, selectedTenant, placesByMarker, tenant2place, reloadData, places } = useSelector(tenantsReducerValues);
    const { generalSettings } = useSelector(settingsReducerValues);

    useEffect(() => {
        setRefreshRelations(() => true);
    }, [savedLayersFlag, allInitialLayers, reloadData, activeProjectLocation?.id]);

    useEffect(() => {
        if (activeProjectLocation?.id && visible) {
            dispatch(fetchPlacesTenants({ plId: String(activeProjectLocation?.id) }));
            setRefreshRelations(() => false);
        }
    }, [activeProjectLocation?.id, visible]);

    useEffect(() => {
        if (selectedChapter === 'tenants' && backId) {
            dispatch(storeSelectedPlace(backId));
        }
    }, [selectedChapter, backId]);

    useEffect(() => {
        const placeMarker = dataFromLayers?.['places_layer']?.objects?.find(
            (item: any) => item.front_id === selectedObjectId,
        )?.marker;

        const backId = places.find((item) => item.marker === placeMarker)?.id;

        if (backId) {
            dispatch(
                storeDataFromLayers({
                    id: 'places_layer',
                    selectedObjectId,
                    backId,
                }),
            );
        }
    }, [places, selectedObjectId, dataFromLayers]);

    useEffect(() => {
        scollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, [scollToRef, selectedObjectId]);

    const onPlaceSelect = (place: IPlaceByMarker | ITenant) => {
        const newPlace = place as IPlaceByMarker;
        dispatch(
            storeDataFromLayers({
                id: 'places_layer',
                selectedObjectId: String(newPlace.front_id) as string,
                backId: newPlace.id,
            }),
        );
    };

    const onTenantSelect = (obj: string | number | ITenant) => {
        dispatch(storeDataFromLayers({ id: 'places_layer', selectedObjectId: null }));
        dispatch(selectTenant(obj as ITenant));
    };

    const onFilterChange =
        (mode: string) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (mode === 'places') {
                setPlacesFilterValue(() => newValue || '');
            } else if (mode === 'tenants') {
                setTenantsFilterValue(() => newValue || '');
            }
        };

    const showAddTenantModal = () => {
        activeProjectLocation?.id && setAddTenantModal({ show: true, plId: String(activeProjectLocation?.id) });
    };

    const closeModal = () => {
        setAddTenantModal({ show: false });
    };

    const namesForPlaces = tenant2place.reduce(
        (acc, tenantPlace) => {
            const fullName = `${tenantPlace.tenant_id} / ${tenantPlace.tenant_object_name}`;
            const placeId = tenantPlace.place_id.toString();
            const prevNamesForPlace = acc?.[placeId] || [];
            return {
                ...acc,
                [placeId]: [...prevNamesForPlace, fullName.toLowerCase()],
            };
        },
        {} as Record<string, string[]>,
    );
    
    const placeslist = useMemo(() => {
        const tList = placesByMarker
            ? Object.keys(placesByMarker)
                  .filter((key) => {
                      const place = placesByMarker[key];
                      const fullName = `${place.id} / ${place.marker} / ${place.object_name}`;
                      const tenantsNames = namesForPlaces[place.id.toString()] || [];
                      const isPlaceAssignedWithCommonName = tenantsNames.some(
                        (name) => name.includes(placesFilterValue.toLowerCase())
                      );
                      return (extendedPlacesSearch && isPlaceAssignedWithCommonName) || fullName.toLowerCase().includes(placesFilterValue.toLowerCase());
                  })
                  .sort((a, b) => {
                      const afloor = placesByMarker[a].floor;
                      const bfloor = placesByMarker[b].floor;
                      if (afloor && bfloor) {
                          return Number(bfloor) - Number(afloor);
                      } else {
                          return 0;
                      }
                  })
                  .map((key) => {
                      const place = placesByMarker[key];
                      const selected = place.front_id === selectedObjectId;

                      return place.front_id === selectedObjectId ? (
                          <div ref={scollToRef} key={place.id}>
                              <ListItem place={place} selected={selected} onClick={onPlaceSelect} />
                          </div>
                      ) : (
                          <ListItem key={place.id} place={place} selected={selected} onClick={onPlaceSelect} />
                      );
                  })
            : [];
        return (
            <Accordion
                key={`places++--`}
                title={`${t('Places')}`}
                id={'places_layer'}
                onShowLabelIconClick={onShowLabelIconClick}
                showLabels={showLabelsIds.includes('places_layer')}
            >
                <Stack
                    horizontal 
                    styles={{ root: { 'display': 'flex', 'justify-content': 'space-between'}}}
                >
                    <TextField
                        styles={{ root: {flexGrow: 1, marginRight: '10px' }}}
                        placeholder={t('Filter')}
                        value={placesFilterValue}
                        onChange={onFilterChange('places')}
                    />
                    <Checkbox
                        key="search-for-places"
                        styles={{ root: { alignItems: 'center' }}}
                        checked={extendedPlacesSearch}
                        onChange={() => setExtendedPlacesSearch((state) => !state)}
                        label={t('Extended search')}
                        boxSide="start"
                    />
                </Stack>
                {tList}
            </Accordion>
        );
    }, [selectedObjectId, placesByMarker, extendedPlacesSearch, placesFilterValue, showLabelsIds]);


    const namesForTenant = tenant2place.reduce(
        (acc, tenantPlace) => {
            const fullName = `${tenantPlace.place_id} / ${tenantPlace.place_marker} / ${tenantPlace.place_object_name}`;
            const tenantId = tenantPlace.tenant_id.toString();
            const prevNamesForPlace = acc?.[tenantId] || [];
            return {
                ...acc,
                [tenantId]: [...prevNamesForPlace, fullName.toLowerCase()],
            };
        },
        {} as Record<string, string[]>,
    );
    
    const tenantslist = useMemo(() => {
        const tList = tenants
            .filter((tenant) => {
                const fullName = `${tenant.id} / ${tenant.object_name}`;
                const placeNames = namesForTenant[tenant.id.toString()] || [];
                const isTenantAssignedWithCommonName = placeNames.some(
                  (name) => name.includes(tenantsFilterValue.toLowerCase())
                );

                return (extendedTenantsSearch && isTenantAssignedWithCommonName) || fullName.toLowerCase().includes(tenantsFilterValue.toLowerCase());
            })
            .map((tenant) => {
                const selected = tenant.id === selectedTenant?.id || false;
                return <ListItem key={tenant.id} tenant={tenant} selected={selected} onClick={onTenantSelect} />;
            });
        return (
            <Accordion
                key={`tenants++--`}
                title={`${t('Tenants')}`}
            >
                <PrimaryButton
                    disabled={generalSettings.readOnly}
                    text={t('Create new tenant')}
                    onClick={showAddTenantModal}
                    allowDisabledFocus
                    styles={buttonStyles}
                />
                <Stack
                    horizontal 
                    styles={{ root: { 'display': 'flex', 'justify-content': 'space-between'}}}
                >
                    <TextField
                        styles={{ root: {flexGrow: 1, marginRight: '10px' }}}
                        placeholder={t('Filter')}
                        value={tenantsFilterValue}
                        onChange={onFilterChange('tenants')}
                    />
                    <Checkbox
                        key="search-for-tenants"
                        styles={{ root: { alignItems: 'center' }}}
                        checked={extendedTenantsSearch}
                        onChange={() => setExtendedTenantsSearch((state) => !state)}
                        label={t('Extended search')}
                        boxSide="start"
                    />
                </Stack>
                {tList}
            </Accordion>
        );
    }, [selectedTenant?.id, showAddTenantModal, t, extendedTenantsSearch, tenants, tenantsFilterValue]);

    // const getFileName = () => {
    //     try {
    //         return plIds.filter((item) => String(item.pl_id) === String(selectedItem?.key))[0].pl_name;
    //     } catch (error) {
    //         return '';
    //     }
    // };

    return (
        <TenantsWrapper visible={visible}>
            <Modal modalStatus={addTenantModal} title={t('Create new tenant')}>
                <CreateNewTenantModal modalStatus={addTenantModal} closeModal={closeModal} />
            </Modal>

            {activeProjectLocation?.id && (
                <>
                    {placeslist}
                    {tenantslist}
                    {/* <StyledCSVLink
                        data={tenant2place}
                        separator={';'}
                        filename={`${getFileName()}_tenants.csv`}
                        className={'ms-ContextualMenu-itemText'}
                    >
                        {`${t('Download .csv')}`}
                    </StyledCSVLink> */}
                </>
            )}
        </TenantsWrapper>
    );
};

export default TenantsList;
