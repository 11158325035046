import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPlace } from '../../../Editor/components/Tenants/Tenants.interfaces';
import { IEscalator } from '../../../Layers/components/Escalators/interfaces';
import { IExtendedZone, IReducer } from './interfaces';
import { RootState } from './store';
const escalators_places = require('../../../../lambda/escalators_places');

export const initialState: IReducer = {
    tupleAlias: 'escalators_places_tuple',
    intersections: [],
};

const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        /**
         * Запись в стейт информации о пересечении проходов и зон
         */
        storeIntersections: (
            state,
            action: PayloadAction<{
                placesObjects: Array<IPlace>;
                escalatorsObjects: Array<IEscalator>;
                planScale: number;
            }>,
        ) => {
            const { placesObjects, escalatorsObjects, planScale } = action.payload;

            const intersections = escalators_places.get_intersections({ placesObjects, escalatorsObjects, planScale });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
