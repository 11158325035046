import tools from './components/Tools';
import draw from './components/Draw';
import contextMenu from './components/ContextMenu';
import controls from './components/Controls';
import { initialState } from './reducer';

export const stairsLayer = {
    id: initialState.layerAlias,
    tools,
    draw,
    controls,
    contextMenu
};
