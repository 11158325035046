import { useParams } from 'react-router-dom';
import { ITables } from '../TablesPanel.interfaces';
import { useEffect, useState } from 'react';
import { TStructureElement } from '../../../../../tools/useRequestStructures/interfaces';
import useRequestRawRelations from '../../../../../tools/useRequestRawRelations/useRequestRawRelations';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

const useRawTables = (): ITables => {
    const { plId } = useParams();
    const response = useRequestRawRelations({ plId: plId ? Number(plId) : undefined });

    const [tables, setTables] = useState<ITables>({});

    useEffect(() => {
        if (response.data) {
            const result: ITables = {};

            Object.entries(response.data).forEach(([branch, value]: [string, { [x: string]: TStructureElement[] }]) => {
                result[branch] = Object.entries(value).map(([key, data]: [string, TStructureElement[]]) => {
                    return {
                        tableData: data?.map((item) => {
                            const newValue = cloneDeep(item);

                            if ('date_to' in newValue) {
                                const year = DateTime.fromISO(newValue.date_to).year;
                                if (year >= 2100 || newValue.date_to === null) {
                                    newValue.date_to = '-';
                                }
                            }

                            return newValue;
                        }),
                        branch,
                        tableName: key,
                        tableId: `raw/${branch}/${key}`,
                    };
                });
            });

            setTables(result);
        }
    }, [response.data]);
    return tables;
};

export default useRawTables;
