import { cloneDeep, isEqual } from 'lodash';
const turf = require('@turf/turf');

interface LayerObject {
    coords: { coordinates: Array<[number, number]> };
    marker: string;
}

const polygonLayers = ['places_layer', 'perimeter_layer', 'zones_layer', 'lifts_layer', 'stairs_layer'];

function getPolygonForTurf(coords: Array<[number, number]>, marker = ''): { poly: any; error: string } {
    const firstPoint = coords[0];
    const lastPoint = coords[coords.length - 1];

    const arr = cloneDeep(coords);

    if (!isEqual(firstPoint, lastPoint)) {
        arr.push(firstPoint);
    }

    const poly = turf.polygon([arr]);

    var kinks = turf.kinks(poly);
    if (kinks.features.length) {
        return { poly, error: `INCORRECT POLYGON -> ${marker}` };
    } else {
        return { poly, error: '' };
    }
}

const findIntersections = <T extends LayerObject>(objects: T[]) => {
    const result: { errors: string[] } = { errors: [] };
    while (objects.length) {
        const firstObj = objects.shift();
        if (firstObj) {
            const polygonToCompare = getPolygonForTurf(firstObj.coords.coordinates, firstObj.marker);
            if (polygonToCompare.error) {
                result.errors.push(polygonToCompare.error);
            }
            objects.forEach((obj) => {
                const turfObj = getPolygonForTurf(obj.coords.coordinates, obj.marker);
                const polygonsIntersection = turf.intersect(polygonToCompare.poly, turfObj.poly);
                if (polygonsIntersection?.geometry?.coordinates) {
                    result.errors.push(`POLYGONS INTERSECTION -> ${firstObj.marker}  >>><<<  ${obj.marker}`);
                }
            });
        }
    }
    return result;
};

export const validateGeometry = <T extends { id: string; objects: any[] }>(dataFromLayer: T): { errors: string[] } => {
    const result: { errors: string[] } = { errors: [] };

    // console.log(dataFromLayer);

    if (!polygonLayers.includes(dataFromLayer.id)) {
        return result;
    }
    const { objects } = cloneDeep(dataFromLayer);

    if (dataFromLayer.id === 'zones_layer') {
        objects.forEach((zonesGroup) => {
            result.errors.push(
                ...findIntersections(zonesGroup.zones.map((item: any) => ({ ...item, marker: item.zone_marker })))
                    .errors,
            );
        });
    } else if (dataFromLayer.id === 'lifts_layer' || dataFromLayer.id === 'stairs_layer') {
        result.errors.push(...findIntersections(objects.map((item) => ({ ...item, coords: item.geometry }))).errors);
    } else {
        result.errors.push(...findIntersections(objects).errors);
    }

    return result;
};
