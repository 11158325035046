import React from 'react';
import { Image } from 'react-konva';
import { sizes } from '../../../../../../constants/sizes';

import { IUrlImageProps, IUrlImageState } from './UrlImage.interfaces';

/**
 * Компонент Фоновой картинки.
 * Служит для отображения плана на фоне.
 * @param {string} src - url фоновой картинки.
 */
class URLImage extends React.Component<IUrlImageProps, IUrlImageState> {
    [x: string]: any;
    state = {
        image: undefined,
    };
    componentDidMount() {
        this.loadImage();
    }
    componentDidUpdate(oldProps: IUrlImageProps) {
        if (oldProps.src !== this.props.src) {
            this.loadImage();
        }
    }
    componentWillUnmount() {
        this.image.removeEventListener('load', this.handleLoad);
    }
    loadImage() {
        this.setState({
            image: undefined,
        });
        this.image = new window.Image();
        this.image.src = this.props.src;
        this.image.addEventListener('load', this.handleLoad);
    }
    handleLoad = () => {
        this.setState({
            image: this.image,
        });
    };
    render() {
        return (
            <Image
                draggable={false}
                scaleX={this.props.planBlueprintScale}
                scaleY={this.props.planBlueprintScale}
                x={sizes.GLOBAL_SHIFT + this.props.imageOffset[0]}
                y={sizes.GLOBAL_SHIFT + this.props.imageOffset[1]}
                image={this.state.image}
                ref={(node) => {
                    this.imageNode = node;
                }}
            />
        );
    }
}

export default URLImage;
