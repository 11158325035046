import { useEffect } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { changeSettings, settingsReducerValues } from '../components/Settings/Settings.reducer';
import { useTranslation } from 'react-i18next';
import { AppDispatch, RootState } from '../store';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

/**
 * Кастомный хук для работы с языком интерфейса
 */
const useSettings = (): void => {
    const { t, i18n } = useTranslation();

    const dispatch = useDispatch();

    const { generalSettings } = useSelector(settingsReducerValues);

    /**
     * Чтение значение языка из localStorage
     */
    useEffect(() => {
        const settingsTemp: string | null = localStorage.getItem('settings');
        dispatch(changeSettings(settingsTemp!));
    }, []);

    /**
     * Запись изменения языка в библиатеку i18n и в localStorage
     */
    useEffect(() => {
        if (!generalSettings) return;
        i18n.changeLanguage(generalSettings.lang);
        localStorage.setItem('settings', JSON.stringify(generalSettings));
    }, [generalSettings]);
};

export default useSettings;
