import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';

import { Layer, Line, Group, Shape, Circle } from 'react-konva';

import {
    placesValues,
    changeCoords,
    storeNewPlace,
    addAnchor,
    selectPlace,
    clearSelectedObjects,
    toggleTool,
    toggleContextMenu,
    changeShapeCoords,
    removeLastCoords,
    changeCoordsFullLayer,
} from '../../places.reducer';
import { ObjectLabel, Anchors, Helper, DrawNodes } from '../../../../layers';
import { WrapperLayer } from './Draw.styles';

import { formattingCoords } from './core/formattingCoords';
import { getIntersect } from './core/getIntersect';
import { commonTools } from '../../../../../../tools/commonTools';
import { IAnchorContextMenuArgs, IExtendedPlan, IPlan } from '../../../../layers.interfaces';
import { sizes } from '../../../../../../constants/sizes';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { IObjects, IPlace } from '../../places.interfaces';
import { ITenant2Place } from '../../../../../Editor/components/Tenants/Tenants.interfaces';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        showLabels,
        placesFrontId,
        generalSettings: { snapToGrid, readOnly },
        selectedObjects,
        currentPlanData,
        selectedChapter,
        tenantsData,
    } = useSelector(placesValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [coordsEnd, setCoordsEnd] = useState<number[]>([]);
    // const [idSelectedShape, setIdSelectedShape] = useState<string>('');

    const [statusOverShape, setStatusOverShape] = useState<boolean>(false);
    const [statusIntersection, setStatusIntersection] = useState<boolean>(false);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [isMouseDownMove, setIsMouseDownMove] = useState<boolean>(false);

    useEffect(() => {
        selectedChapter === 'tenants' && dispatch(toggleTool('anchorsEdit'));
    }, [selectedChapter]);

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw') {
            container.style.cursor = 'crosshair';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'anchorsEdit' && statusOverShape) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId, statusOverShape]);

    useEffect(() => {
        const container = props.stage.current.container();
        setIsMouseDownMove(false);
        if (activeToolId === 'draw') {
            if (container) {
                container.style.cursor = 'crosshair';
            }
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        }
    }, [activeToolId]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCoords = (coordsS: number[]) => {
        let tempCoords = coords;

        tempCoords[tempCoords.length - 1] = coordsS;

        setStatusIntersection(getIntersect(formattingCoords(objects, tempCoords)));

        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const updateCoordsAnchors = (coordsS: number[][] | undefined, front_id: string) => {
        if (activeToolId === 'anchorsEdit' && selectedChapter === 'layers') {
            dispatch(changeCoords({ coordsS, front_id }));
        }
    };
    const onMouseDown = (id: string) => (e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true);
        } else if (activeToolId === 'move') {
            // setIdSelectedShape(id);

            dispatch(selectPlace({ placeFrontId: id }));
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false);

            // setIdSelectedShape('')

            let _objects: IObjects = [];
            let selected_Objects: number[][] = [];
            objects.forEach((item, i) => {
                if (item.front_id === placesFrontId) {
                    selected_Objects = item.coords.coordinates;
                } else {
                    _objects.push(item);
                }
            });
            setStatusIntersection(commonTools.getIntersect(formattingCoords(_objects, selected_Objects)));
            if (commonTools.getIntersect(formattingCoords(_objects, selected_Objects))) {
                dispatch(removeLastCoords());
            } else {
                dispatch(changeShapeCoords());
            }
        } else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false);
        }
    };
    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (placesFrontId !== '') {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects.find((item) => item.front_id === placesFrontId);

                if (moveObj) {
                    _coordsShape = commonTools.processCoordsForMoveShape(
                        moveObj.coords.coordinates,
                        coords,
                        coordsOldShape,
                    );
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: placesFrontId }));

                setCoordsOldShape(coords);
            }
        } else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IObjects = [];
                objects.forEach((item, i) => {
                    _objects.push({
                        ...item,
                        coords: {
                            ...item.coords,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.coords.coordinates,
                                coords,
                                coordsOldShape,
                            ),
                        },
                    });
                });

                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }
        }
    };

    const addNewPoint = (coordsS: number[]) => {
        if (statusIntersection) {
            return;
        }
        let tempCoords = coords;
        if (tempCoords.length === 0) {
            tempCoords.push(coordsS);
        }

        tempCoords.push(coordsS);
        setStatusIntersection(getIntersect(formattingCoords(objects, tempCoords)));
        if (formattingCoords(objects, tempCoords)) {
            return;
        }
        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const onHelperClick = (objectId: string) => (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (objectId !== '') {
            if (activeToolId === 'anchorsEdit' && selectedChapter !== 'tenants') {
                if (selectedObjects) {
                    /*
                    Тут не надо привязывать к сетке
                     */
                    const coords = commonTools.getPointerCoords({ e, snapToGrid: false });
                    e.evt.preventDefault();
                    const coordsTemp = [coords![0], coords![1]];
                    dispatch(addAnchor({ anchorCoords: coordsTemp, objectId }));
                } else {
                    dispatch(selectPlace({ placeFrontId: objectId }));
                }
            }
        } else {
            if (activeToolId === 'draw') {
                updateIsDrawNow(true);
                newPoint && addNewPoint(newPoint);
            } else {
                e.evt.preventDefault();
                dispatch(clearSelectedObjects());
            }
        }
    };

    const onClickCircleAnd = () => {
        setIsDrawNow(false);
        let tempCoords = coords;
        tempCoords.splice(coords.length - 1, 1);
        dispatch(storeNewPlace(tempCoords));
        setCoords([]);
        setCoordsEnd([]);
    };

    const onShapeMove = (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (newPoint) {
            updateCoords(newPoint);
        }
    };

    const onAnchorContextMenu = (args: IAnchorContextMenuArgs) => {
        const { anchorIndex, pointsNum, cursor } = args;
        dispatch(toggleContextMenu({ show: true, cursor, anchorIndex, pointsNum }));
    };

    const newShape = useMemo(() => {
        if (!coords || coords.length === 0 || !props.isLayerActive) return null;

        let componentCircle = null;

        if (
            Math.abs(coordsEnd[0] - coords[0][0]) < 5 &&
            Math.abs(coordsEnd[1] - coords[0][1]) < 5 &&
            coords.length > 3
        ) {
            componentCircle = (
                <Group onClick={onClickCircleAnd}>
                    <Circle
                        id={`circleMeddle`}
                        key={`circleMeddle`}
                        x={coords[0][0]}
                        y={coords[0][1]}
                        radius={10 / props.scale.stageScale}
                        stroke={'#fff'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    <Circle
                        id={`circleBig`}
                        key={`circleBig`}
                        x={coords[0][0]}
                        y={coords[0][1]}
                        radius={12 / props.scale.stageScale}
                        stroke={'rgba(0,0,0,0.3)'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    <Circle
                        id={`circleSmall`}
                        key={`circleSmall`}
                        x={coords[0][0]}
                        y={coords[0][1]}
                        radius={8 / props.scale.stageScale}
                        stroke={'rgba(0,0,0,0.3)'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                </Group>
            );
        }

        return (
            <Layer key={props.key + '+++'}>
                <Group>
                    <Shape
                        id={'Shape!@##11'}
                        key={'Shape!@##11'}
                        onMouseMove={onShapeMove}
                        onClick={onHelperClick('')}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coords[0][0], coords[0][1]);
                            coords.forEach((coord, i) => {
                                if (i > 0) {
                                    context.lineTo(coord[0], coord[1]);
                                }
                            });
                            context.fillStrokeShape(shape);
                        }}
                        opacity={1}
                        stroke={'blue'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    {componentCircle}
                </Group>
            </Layer>
        );
    }, [coords, props.scale, coordsEnd]);

    const onShapeMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(true);
    };
    const onShapeMouseOut = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(false);
    };

    const getLabelCoords = (coordsList: number[][]) => {
        return commonTools.findPolygonCenter(coordsList);
    };

    const dateFilteredRelations = tenantsData?.tenant2place
        ? tenantsData?.tenant2place?.filter(
              (item) => item.date_to === null || DateTime.fromISO(item.date_to).toMillis() > DateTime.now().toMillis(),
          )
        : [];

    const getTenantsLabel = (item: IPlace, selected: boolean, coordsList: number[][]) => {
        if (
            // !placesHasTenantsMarkers.includes(item.marker) ||
            selectedChapter !== 'tenants'
        ) {
            return null;
        } else {
            const adminPlace = tenantsData?.places?.filter((pl) => {
                return pl.marker === item.marker;
            })[0];
            const total = tenantsData?.tenant2place?.filter((rel) => rel.place_id === adminPlace?.id).length;
            const placeText = `Place: ${adminPlace?.id} / ${adminPlace?.marker} / ${adminPlace?.object_name}`;

            const tenantText = dateFilteredRelations
                ?.filter((rel) => rel.place_marker === item.marker)
                .map((rel) => {
                    const dateTo = rel.date_to === null ? 'Now' : rel.date_to;
                    return `\nTenant: ${rel.tenant_id} / ${rel.tenant_object_name}\n${rel.date_from} - ${dateTo}\n(total: ${total})`;
                })
                .join('\n');

            const text = `${placeText}${tenantText}`;

            return (
                <ObjectLabel
                    stageScale={props.scale.stageScale}
                    x={getLabelCoords(coordsList)?.x}
                    y={getLabelCoords(coordsList)?.y}
                    marker={text}
                    selected={selected}
                />
            );
        }
    };

    const geometry = useMemo(() => {
        if (!objects || objects.length === 0) return null;

        const [selectedObject] = objects.filter((item) => item.front_id === placesFrontId);

        const componentNode = cloneDeep(objects).map((item, index) => {
            const selected = item.front_id === placesFrontId;
            const tenant2place = tenantsData?.tenant2place;
            let coordsList = item.coords.coordinates;

            const onClickItemColumn = (groupFrontId?: string, itemFrontId?: string) => () => {
                if (activeToolId === 'anchorsEdit') {
                    dispatch(selectPlace({ placeFrontId: itemFrontId }));
                }
            };

            const green = 'rgba(16,155,0,.3)';

            const placesHasTenantsMarkers = dateFilteredRelations.map((item) => item.place_marker);

            const getFill = () => {
                if (selectedChapter !== 'tenants') {
                    return item.is_opened ? green : 'rgba(0,0,0,0.5)';
                } else if (tenant2place && selectedChapter === 'tenants') {
                    return placesHasTenantsMarkers.includes(item.marker) ? 'rgba(0, 200, 200, 0.4)' : green;
                }
            };

            return (
                <Group onClick={onClickItemColumn(item.front_id, item.front_id)} key={index + '+++!!!!'}>
                    <Shape
                        onClick={onHelperClick(item.front_id)}
                        onMouseOver={onShapeMouseOver}
                        onMouseOut={onShapeMouseOut}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coordsList[0][0], coordsList[0][1]);
                            coordsList.forEach((coord, i) => {
                                if (i > 0) {
                                    context.lineTo(coord[0], coord[1]);
                                }
                            });
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        opacity={1}
                        onMouseDown={onMouseDown(item.front_id)}
                        stroke={'green'}
                        fill={placesFrontId === item.front_id ? 'rgba(255,0,0,.3)' : getFill()}
                        strokeWidth={
                            placesFrontId === item.front_id ? 4 / props.scale.stageScale : 2 / props.scale.stageScale
                        }
                    />
                    {placesFrontId === item.front_id && selectedChapter !== 'tenants' ? (
                        <Anchors
                            currentPlanData={currentPlanData!}
                            selectedChapter={selectedChapter!}
                            snapToGrid={snapToGrid}
                            activeToolId={activeToolId}
                            coords={coordsList}
                            updateCoords={updateCoords}
                            updateCoordsAnchors={updateCoordsAnchors}
                            scale={props.scale}
                            front_id={item.front_id}
                            onAnchorContextMenu={onAnchorContextMenu}
                        />
                    ) : null}
                    {getLabelCoords(coordsList) &&
                        selectedChapter === 'layers' &&
                        !isDrawNow &&
                        showLabels &&
                        !selected && (
                            <ObjectLabel
                                stageScale={props.scale.stageScale}
                                x={getLabelCoords(coordsList)?.x}
                                y={getLabelCoords(coordsList)?.y}
                                marker={item.marker}
                                selected={selected}
                            />
                        )}
                    {!selected && showLabels && getTenantsLabel(item, false, coordsList)}
                </Group>
            );
        });

        return (
            <>
                <Group>{componentNode}</Group>
                {selectedObject && selectedChapter === 'layers' && !isDrawNow && showLabels && (
                    <ObjectLabel
                        stageScale={props.scale.stageScale}
                        x={getLabelCoords(selectedObject.coords.coordinates)?.x}
                        y={getLabelCoords(selectedObject.coords.coordinates)?.y}
                        marker={selectedObject.marker}
                        selected={true}
                    />
                )}
                {selectedObject &&
                    selectedChapter === 'tenants' &&
                    getTenantsLabel(selectedObject, true, selectedObject.coords.coordinates)}
            </>
        );
    }, [
        objects,
        activeToolId,
        coords,
        props.scale,
        isDrawNow,
        showLabels,
        placesFrontId,
        selectedChapter,
        tenantsData,
        selectedChapter,
    ]);
    return (
        <>
            <WrapperLayer key={props.key} onMouseMove={moveShape} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}>
                {activeToolId === 'draw' && <DrawNodes stageScale={props.scale.stageScale} coordinates={coords} />}
                <Helper
                    updateCoords={updateCoords}
                    isDrawNow={isDrawNow}
                    currentPlanData={currentPlanData!}
                    snapToGrid={snapToGrid}
                    onHelperClick={onHelperClick('')}
                />
                {geometry}
            </WrapperLayer>
            {newShape}
        </>
    );
};

export default Draw;
