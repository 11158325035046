import styled from 'styled-components/macro';

export const ModalWrapper = styled.div`
    width: 70vw;
    height: 80vh;
    overflow: hidden;
`;

export const TableWrapper = styled.div`
    overflow: auto;
    height: 100%;
    table {
        margin-top: var(--space-xs);
        width: 100%;
        position: relative;
        table-layout: auto !important;
        tr {
            :first-child {
                border: 1px solid grey !important;
            }
        }
        td,
        th {
            text-align: left !important;
            color: black !important;
            > span {
                min-height: 20px;
                padding: 5px !important;
                display: flex !important;
                align-items: center !important;
                box-sizing: border-box;
                height: 100%;
            }
        }
        th {
            max-width: 200px;
            min-width: 100px;
            overflow: auto;
            position: sticky;
            padding: 10px 5px !important;
            font-weight: 700;
            top: -1px;
            > span {
                white-space: nowrap;
                background-color: whitesmoke;
            }
        }
        td {
            white-space: pre-line !important;
            background-color: transparent !important;
            > span {
                background-color: transparent !important;
            }
        }
    }
`;
