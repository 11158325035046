import { useMutation, useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../../../../../General.reducer';
import { accountsReducerValues } from '../../Accounts.reducer';
import { compareArrays } from './compareArrays';
import { executeRequest } from './apiHelpers';
import { CopySettings } from './types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TAccountWithProduct } from '../../Accounts.interfaces';

export type RequestStatus = {
    url: string;
    method: string;
    status: 'pending' | 'success' | 'error' | 'loading';
    message: string;
    productId?: number;
    locationId?: number;
};

export const useCopyProductsAndLocations = () => {
    const { t } = useTranslation();

    const {
        urls: { USER_2_PRODUCT_URL, PROJECT_LOCATIONS_PERMISSIONS_URL, ACCOUNT_PARAMETERS_URL },
        token,
    } = useSelector(generalReducerValues);

    const { user2product, user2location } = useSelector(accountsReducerValues);

    const [requestStatuses, setRequestStatuses] = useState<RequestStatus[]>([]);
    const [preMutationData, setPreMutationData] = useState<any>(null); // Состояние для хранения данных из предварительного запроса

    const mutation = useMutation({
        mutationFn: async (
            args: Array<{ url: string; data: object; method: string; productId?: number; locationId?: number }>,
        ) => {
            // Использование данных из предварительного запроса
            console.log('Data from pre-mutation query:', preMutationData);

            setRequestStatuses(
                args.map(({ url, method, productId, locationId }) => ({
                    url,
                    method,
                    status: 'pending',
                    message: t('Request in progress...'),
                    productId,
                    locationId,
                })),
            );

            const results = await Promise.allSettled(
                args.map(({ url, data, method }) => executeRequest({ url, data, method, token })),
            );

            const statuses: RequestStatus[] = results.map((result, index) => {
                const { url, method, productId, locationId } = args[index];
                if (result.status === 'fulfilled') {
                    return {
                        url,
                        method,
                        status: 'success',
                        message: `${productId ? t('Product') : t('Location')} ${t(
                            'copy request completed successfully',
                        )}`,
                        productId,
                        locationId,
                    };
                } else {
                    return {
                        url,
                        method,
                        status: 'error',
                        message: `${t('Error')}: ${(result.reason as Error).message}`,
                        productId,
                        locationId,
                    };
                }
            });

            setRequestStatuses(statuses);

            const successfulResponses = results
                .filter((result) => result.status === 'fulfilled')
                .map((result) => result);
            const failedResponses = results.filter((result) => result.status === 'rejected').map((result) => result);

            return { successfulResponses, failedResponses };
        },
        onSuccess: (data) => {
            if (data.successfulResponses.length > 0) {
                console.log(t('Successful requests:'), data.successfulResponses);
            }
            if (data.failedResponses.length > 0) {
                console.error(t('Failed requests:'), data.failedResponses);
            }
        },
        onError: (error) => {
            console.error(t('An error occurred:'), error);
        },
    });

    const copyProductsAndLocations = (args: CopySettings<TAccountWithProduct>) => {
        const { donorId, acceptorId, list } = args;

        const donor = list.find((item) => item.id === Number(donorId));
        const acceptor = list.find((item) => item.id === Number(acceptorId));

        if (!donor || !acceptor) return;

        const [productsToDelete, productsToAdd] = compareArrays(donor.productIds || [], acceptor.productIds || []);
        const [locationsToDelete, locationsToAdd] = compareArrays(donor.locationIds || [], acceptor.locationIds || []);

        const mutateProductsArgs = productsToDelete
            .map((productId) => {
                const bindingId = user2product.find(
                    (item) => item.user_id === Number(acceptorId) && item.product_id === productId,
                )?.id;
                const url = `${USER_2_PRODUCT_URL}${bindingId}/`;
                return { url, data: {}, method: 'DELETE', productId };
            })
            .concat(
                productsToAdd.map((productId) => ({
                    url: USER_2_PRODUCT_URL,
                    data: { product_id: productId, user_id: Number(acceptorId) },
                    method: 'POST',
                    productId,
                })),
            );

        const mutateLocationsArgs = locationsToDelete
            .map((locationId) => {
                const bindingId = user2location.find(
                    (item) => item.user_id === Number(acceptorId) && item.project_location_id === locationId,
                )?.id;
                const url = `${PROJECT_LOCATIONS_PERMISSIONS_URL}${bindingId}/`;
                return { url, data: {}, method: 'DELETE', locationId };
            })
            .concat(
                locationsToAdd.map((locationId) => ({
                    url: PROJECT_LOCATIONS_PERMISSIONS_URL,
                    data: { project_location_id: locationId, user_id: Number(acceptorId) },
                    method: 'POST',
                    locationId,
                })),
            );

        mutation.mutate([...mutateProductsArgs, ...mutateLocationsArgs]);
    };

    return { copyProductsAndLocations, requestStatuses };
};
