import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IconButton } from '@fluentui/react/lib/Button';
import { toggleTool, zonesValues } from '../../zones.reducer';
import { TooltipHost } from '@fluentui/react';
import { IIconProps } from '@fluentui/react';
import { getButtonStyles } from '../../../../../Editor/components/ToolsPanel/ToolsPanel.styles';
import { toolsList } from './toolsList';
import { useTranslation } from 'react-i18next';

/**
 * Компонент инструментов
 */
const Tools = () => {
    const dispatch = useDispatch();
    const { activeToolId, layerAlias, hotAddObject } = useSelector(zonesValues);
    const { t } = useTranslation();

    useEffect(() => {
        hotAddObject && layerAlias === hotAddObject.activeLayerId && dispatch(toggleTool(hotAddObject.toolId));
    }, [hotAddObject]);

    const onToolClick = (toolId: string) => () => {
        dispatch(toggleTool(toolId));
    };

    const content = toolsList.map((item, i) => {
        const iconProps: IIconProps = { iconName: item.icon.iconName, styles: { root: { fontSize: item.icon.size } } };
        return (
            <TooltipHost key={`++${item.id}`} content={t(item.tooltipText)}>
                <IconButton
                    iconProps={iconProps}
                    disabled={false}
                    onClick={onToolClick(item.id)}
                    styles={getButtonStyles(item.id === activeToolId)}
                />
            </TooltipHost>
        );
    });
    return <>{content}</>;
};

export default Tools;
