const tools = require('./tools');
const cloneDeep = require('lodash/cloneDeep');

function get_intersections({ objects, passWaysObjects, planScale }) {
    var intersections = [];
    const extendedPassWays = passWaysObjects.map((passWay) => {
        return Object.assign(cloneDeep(passWay), { arrowsCoords: tools.getArrowCoords({ passWay, planScale }) });
    });

    extendedPassWays.forEach((passWay) => {
        for (let i = 0; i < objects.length; i++) {
            const polygonObject = objects[i];

            const info = {
                stairsMarker: polygonObject.marker,
                stairsName: polygonObject.name,
                externalTraffic: polygonObject.external_traffic,
                passWayMarker: passWay.marker,
                passWayName: passWay.name,
            };

            intersections = intersections.concat(
                tools.getPwPolygonIntersections({
                    polygonObject,
                    info,
                    passWay,
                    geometryKeyName: 'geometry',
                    canEnclose: false,
                }),
            );
        }
    });
    // console.log('....');
    // console.dir({ objects, passWaysObjects, planScale, intersections }, { depth: null });

    return intersections;
}

module.exports = { get_intersections };
