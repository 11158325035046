import { FC } from 'react';
import { ITableCustomComponentProps } from '../../store';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ColorBox } from './styles';

import { IWorkingHours } from '../../../../../../tools/useRequestStructures/interfaces';
import { getWhStatus } from '../../tools/getWhStatus';

const WorkingHours: FC<ITableCustomComponentProps> = ({ toggleModal, ...props }) => {
    const { t } = useTranslation();

    const workingHours = props.cell.dataObject?.working_hours as IWorkingHours[];

    const status = getWhStatus(workingHours);

    const onDoubleClick = () => {
        if (props?.cell?.dataObject) {
            toggleModal && toggleModal({ show: true, objectData: props?.cell?.dataObject });
        }
    };

    return (
        <ColorBox status={status} onDoubleClick={onDoubleClick}>
            <div>{t(status)}</div>
        </ColorBox>
    );
};

export default WorkingHours;
