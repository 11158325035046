import React, { useContext, useState, useCallback } from 'react';
import {
    IconButton,
    IIconProps,
    IContextualMenuProps,
    Stack,
    Link,
    IStackTokens,
    IButtonStyles
} from '@fluentui/react';
import { ContextualMenu, ContextualMenuItemType, IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';
import { Lang, menuStyles } from './LangSwitсher.styles';

import { ILangSwitcherProps } from './LangSwitсher.interfaces';
import { SettingsContext } from '../../Settings.context';

const tokens: IStackTokens = { childrenGap: 8 };

/**
 * Компонент кнопки переводчика.
 */
const LangSwitcher: React.FunctionComponent<ILangSwitcherProps> = ({ lang }) => {
    const [showContextualMenu, setShowContextualMenu] = React.useState(false);

    const onShowContextualMenu = useCallback(() => setShowContextualMenu(true), []);
    const onHideContextualMenu = useCallback(() => setShowContextualMenu(false), []);

    const { changeLangTrans } = useContext(SettingsContext);

    const linkRef = React.useRef(null);

    const changeLang = (lang: string) => {
        changeLangTrans!(lang);
    };

    const menuItems = [
        {
            key: 'ru',
            text: 'ru',
            onClick: () => changeLang('ru')
        },
        {
            key: 'en',
            text: 'en',
            onClick: () => changeLang('en')
        }
    ];

    return (
        <Stack tokens={tokens} horizontal>
            <ContextualMenu
                styles={menuStyles}
                items={menuItems}
                hidden={!showContextualMenu}
                target={linkRef}
                onItemClick={onHideContextualMenu}
                onDismiss={onHideContextualMenu}
            />
            <Lang ref={linkRef} onClick={onShowContextualMenu}>
                {lang}
            </Lang>
        </Stack>
    );
};

export default LangSwitcher;
