import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './zones.store';

import { IZone, IGroupZones, IObjFloorsAndGroupZones, IZonesReducer, IObjects } from '../Zones/zones.interfaces';
import { IPlan } from '../../../Plans/Plans.interfaces';
import { commonTools } from '../../../../tools/commonTools';
import { clone, cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import compareNames from './tools/compareNames';
import compareNameZones from './tools/compareNamesZone';
import {
    IExtendedPlan,
    IGeneralSettings,
    INodeContextMenu,
    IHotAddObject,
    IVersionsData,
} from '../../layers.interfaces';
import { sizes } from '../../../../constants/sizes';

export const initialState: IZonesReducer = {
    layerAlias: 'zones_layer',
    selectedObjectId: null,
    activeToolId: null,
    versionsData: null,
    objects: [],
    createdAt: undefined,
    currentPlanData: null,
    indexSelectedGroupZones: 0,
    showLabels: false,
    groupFrontId: '',
    zoneFrontId: '',
    frontIdZonesByFrontIdGroups: null,
    groupDataByMarkerGroup: null,
    selectedGroupZones: null,
    selectedObjects: null,
    selectedGroupMarker: '',
    zoneDataByMarkerZone: null,
    selectedMarkerZone: '',
    generalSettings: {} as IGeneralSettings,
    isNamesValid: { valid: true },
    anotherLocationNames: { markers: [], names: [], markersGroupZones: [], namesGroupZones: [] },
    markersByNamesZones: null,
    namesByMarkersZone: null,
    markersByGroupZonesMarkers: null,
    namesGroupZonesByMarkersGroupZones: {},
    namesGroupZonesByMarkersGroupZonesGlobal: {},
    markersGroupZonesByNamesGroupZones: {},
    markersGroupZonesByNamesGroupZonesGlobal: {},
    anotherLocations: [],
    isShowTable: false,
    objFloorsAndGroupZones: {
        floors: [],
        groups: {},
    },
    groupDataByMarkerGroupGlobal: {},
    isMultiZone: false,
    floor: 0,
    contextMenu: { show: false },
    hotAddObject: null,
    objectsTemp: [],
};

export const ZonesReducer = createSlice({
    name: 'ZonesReducer',
    initialState,
    reducers: {
        pasteObjectFromClipboard: (state, action: PayloadAction<IZone>) => {
            const { objects, versionsData, selectedGroupZones } = cloneDeep(state);
            if (!selectedGroupZones) return;
            const front_id = `v${String(versionsData?.activeVersionId)}:zone:${commonTools.generateId()}`;
            const { is_multizone, zone_marker, zone_name, coords } = action.payload;
            const newObject = cloneDeep({ is_multizone, zone_marker, zone_name, coords, front_id });
            commonTools.shiftCoords(newObject, [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT]);
            state.objects = objects.map((group) => {
                if (group.front_id !== selectedGroupZones.front_id) {
                    return group;
                } else {
                    const zones = group.zones.filter((element) => element.zone_marker !== zone_marker);
                    zones.push(newObject);
                    return { ...group, zones };
                }
            });
            state.selectedMarkerZone = zone_marker;
            state.zoneFrontId = front_id;
            state.selectedObjects = newObject;
        },
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Открывает контекстное меню с выбором: удалить узелок (если их больше 3) или удалить весь рисунок
         */
        toggleContextMenu: (state, action: PayloadAction<INodeContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Удаление узелка
         */
        deleteAnchor: (state, action: PayloadAction<{ zoneFrontId: string; anchorIndex: number }>) => {
            const { zoneFrontId, anchorIndex } = action.payload;
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            const objects = cloneDeep(state.objects);

            const objectsOfZones = objects[indexSelectedGroupZones].zones.map((zone: IZone) => {
                if (zoneFrontId === zone.front_id) {
                    const coordinates = cloneDeep(zone.coords.coordinates);
                    coordinates.splice(anchorIndex, 1);
                    const result = { ...zone, coords: { ...zone.coords, coordinates } };
                    return result;
                }
                return zone;
            });

            objects[indexSelectedGroupZones].zones = objectsOfZones;

            state.objects = objects;
            state.objectsTemp = objects;
        },

        /**
         * Созжание нового помещений
         * @param state
         * @param action
         */
        storeNewZone: (state, action: PayloadAction<Array<Array<number>>>) => {
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            const objects = cloneDeep(state.objects[indexSelectedGroupZones].zones);
            const objects2 = cloneDeep(state.objects);
            const anotherLocationNames = cloneDeep(state.anotherLocationNames);
            let markersByNamesZones = cloneDeep(state.markersByNamesZones);
            let namesByMarkersZone = cloneDeep(state.namesByMarkersZone);
            let markersByGroupZonesMarkers = cloneDeep(state.markersByGroupZonesMarkers);

            let currentMarkersTemp: string[] = [];
            let currentMarkers: string[] = [];
            let currentNamesTemp: string[] = [];
            let currentNames: string[] = [];
            objects2.map((item) => {
                currentMarkersTemp = item.zones.map((item2) => item2.zone_marker);
                currentMarkers = currentMarkers.concat(currentMarkersTemp);

                currentNamesTemp = item.zones.map((item2) => item2.zone_name);
                currentNames = currentMarkers.concat(currentMarkersTemp);
                // item.marker
            });

            const nextNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers, ...currentMarkers],
                'Zone',
            );
            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:Zone${nextNumber}`;
            const name = 'Zone' + nextNumber;
            const user_area = null;
            const id = `v${String(activeVersionId)}:zone:${commonTools.generateId()}`;

            let frontIdZonesByFrontIdGroups: { [key: string]: string | undefined } = {};
            if (!markersByNamesZones) {
                markersByNamesZones = {};
            }
            if (!namesByMarkersZone) {
                namesByMarkersZone = {};
            }
            markersByNamesZones![name] = {};
            namesByMarkersZone![marker] = {};

            markersByNamesZones![name]![objects2![indexSelectedGroupZones!].group_marker!] = marker;
            namesByMarkersZone![marker][objects2![indexSelectedGroupZones!].group_marker!] = name;
            objects2[indexSelectedGroupZones].zones.push({
                front_id: id,
                zone_marker: marker,
                zone_name: name,
                user_area,
                coords: {
                    type: 'Polygon',
                    coordinates: action.payload,
                },
                is_multizone: false,
            });
            objects2.forEach((item, index) => {
                item.zones.forEach((item1, index1) => {
                    frontIdZonesByFrontIdGroups[item1.front_id] = item.front_id;
                });
            });

            state.frontIdZonesByFrontIdGroups = frontIdZonesByFrontIdGroups;
            state.objects = objects2;
            state.objectsTemp = objects2;
            state.markersByNamesZones = markersByNamesZones;
            state.namesByMarkersZone = namesByMarkersZone;
        },
        /**
         * Изменение координат выделеного объекта
         * @param state
         * @param action
         */
        changeCoords: (
            state,
            action: PayloadAction<{ coordsS: number[][] | undefined; front_id: string | undefined }>,
        ) => {
            const { coordsS, front_id } = action.payload;
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            const objects = cloneDeep(state.objects[indexSelectedGroupZones]);
            const index = objects.zones.findIndex((item) => item.front_id === front_id);

            index !== -1 &&
                (objects.zones[index] = {
                    ...objects.zones[index],
                    coords: {
                        ...objects.zones[index].coords,
                        coordinates: coordsS!,
                    },
                });

            state.objects[indexSelectedGroupZones] = objects;
        },

        /***
         * Запись в стор загруженных с сервера объектов
         * @param state
         * @param action
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IGroupZones>>) => {
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            let groupFrontId;
            let frontIdZonesByFrontIdGroups: { [key: string]: string | undefined } = {};
            let groupDataByMarkerGroup: { [key: string]: IGroupZones | undefined } = {};
            let namesGroupZonesByMarkersGroupZones = cloneDeep(state.namesGroupZonesByMarkersGroupZones);
            let markersGroupZonesByNamesGroupZones = cloneDeep(state.markersGroupZonesByNamesGroupZones);
            let markersByNamesZones = cloneDeep(state.markersByNamesZones);
            let namesByMarkersZone = cloneDeep(state.namesByMarkersZone);
            let selectedGroupZones: IGroupZones | null = null;
            let selectedGroupMarker: string | undefined = '';
            let tempObjects: IGroupZones[] = [];
            const tempObjects2 = cloneDeep(state.objects);

            if (!namesGroupZonesByMarkersGroupZones) {
                namesGroupZonesByMarkersGroupZones = {};
            }
            if (!markersGroupZonesByNamesGroupZones) {
                markersGroupZonesByNamesGroupZones = {};
            }

            try {
                tempObjects = action.payload.map((itemMap, indexMap) => {
                    return {
                        ...itemMap,
                        zones: itemMap.zones.map((itemMap2, indexMap2) => {
                            if (itemMap2.is_multizone === undefined) {
                                return {
                                    ...itemMap2,
                                    is_multizone: false,
                                };
                            }
                            return {
                                ...itemMap2,
                            };
                        }),
                    };
                });
            } catch (error) {}

            tempObjects.forEach((item, index) => {
                item.zones.forEach((item1, index1) => {
                    frontIdZonesByFrontIdGroups[item1.front_id] = item.front_id;
                    if (item1.is_multizone === undefined) {
                        item1['is_multizone'] = false;
                    }
                    if (item1.user_area === undefined) {
                        item1['user_area'] = null;
                    }
                    if (!markersByNamesZones) {
                        markersByNamesZones = {};
                    }
                    if (!namesByMarkersZone) {
                        namesByMarkersZone = {};
                    }
                    if (!markersByNamesZones![item1.zone_name]) {
                        markersByNamesZones![item1.zone_name] = {};
                        markersByNamesZones![item1.zone_name][item.group_marker!] = item1.zone_marker;
                    } else {
                        markersByNamesZones![item1.zone_name][item.group_marker!] = item1.zone_marker;
                    }

                    if (!namesByMarkersZone![item1.zone_marker!]) {
                        namesByMarkersZone![item1.zone_marker!] = {};
                        namesByMarkersZone![item1.zone_marker!][item.group_marker!] = item1.zone_name;
                    } else {
                        namesByMarkersZone![item1.zone_marker!][item.group_marker!] = item1.zone_name;
                    }
                });
                groupDataByMarkerGroup[item.group_marker!] = item;
                namesGroupZonesByMarkersGroupZones![item.group_marker!] = item.group_name!;
                markersGroupZonesByNamesGroupZones![item.group_name!] = item.group_marker!;
                if (index === indexSelectedGroupZones) {
                    selectedGroupMarker = item.group_marker;
                    groupFrontId = item.front_id;
                    selectedGroupZones = item;
                }
            });
            state.groupDataByMarkerGroup = groupDataByMarkerGroup;

            state.objects = tempObjects;
            state.objectsTemp = tempObjects;
            state.groupFrontId = groupFrontId;
            state.selectedObjects = null;
            state.zoneFrontId = '';
            state.frontIdZonesByFrontIdGroups = frontIdZonesByFrontIdGroups;
            state.selectedGroupZones = selectedGroupZones;
            state.selectedGroupMarker = selectedGroupMarker;
            state.namesGroupZonesByMarkersGroupZones = namesGroupZonesByMarkersGroupZones;
            state.markersByNamesZones = markersByNamesZones;
            state.namesByMarkersZone = namesByMarkersZone;
        },

        /**
         * Запись в стор поля createdAt
         * @param state
         * @param action
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
            if (action.payload.floor !== state.floor) {
                state.indexSelectedGroupZones = 0;
            }
            state.floor = action.payload.floor;
        },

        /**
         * Переключение показа маркеров на объектах
         * @param state
         * @param action
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Выбор группы зон
         * @param state
         * @param action
         */
        selectGroupZones: (state, action: PayloadAction<{ front_id: string | undefined }>) => {
            const { front_id } = action.payload;
            const objects = cloneDeep(state.objects);

            let indexGroup: number = 0;
            let selectedGroupZones: IGroupZones | null = null;
            objects.map((item, index) => {
                if (front_id === item.front_id) {
                    indexGroup = index;
                    selectedGroupZones = item;
                }
            });
            state.zoneFrontId = '';
            state.selectedObjects = null;
            state.indexSelectedGroupZones = indexGroup;
            state.groupFrontId = front_id;
            state.selectedGroupZones = selectedGroupZones;
        },

        /**
         * Сохранение новой группы зон
         * @param state
         * @param action
         */
        saveNewGroupZones: (
            state,
            action: PayloadAction<{ name?: string | undefined; marker?: string | undefined }>,
        ) => {
            const { name, marker } = action.payload;
            const objects = cloneDeep(state.objects);
            let groupDataByMarkerGroup: { [key: string]: IGroupZones | undefined } = {};
            let selectedGroupZones: IGroupZones | null = null;
            let selectedGroupMarker: string | undefined = '';
            let groupFrontId: string;

            let indexGroup: number = 0;
            // console.log('>>>>>>>>>>>>>>>>')
            selectedGroupZones = {
                front_id: `groupZone:${commonTools.generateId()}`,
                group_marker: marker,
                group_name: name,
                zones: [],
            };
            objects.push(selectedGroupZones);
            selectedGroupMarker = selectedGroupZones.group_marker;
            groupFrontId = selectedGroupZones!.front_id!;
            objects.forEach((item, index) => {
                groupDataByMarkerGroup![item!.group_marker!] = item;
            });

            indexGroup = objects.length - 1;
            state.objects = objects;
            state.objectsTemp = objects;
            state.groupFrontId = groupFrontId;
            state.indexSelectedGroupZones = indexGroup;
            state.groupDataByMarkerGroup = groupDataByMarkerGroup;
            state.selectedGroupZones = selectedGroupZones;
            state.selectedGroupMarker = selectedGroupMarker!;
        },

        /**
         * Добавление новых точек в полигон
         * @param state
         * @param action
         */
        addAnchor: (
            state,
            action: PayloadAction<{ anchorCoords?: number[] | undefined; objectId?: string | undefined }>,
        ) => {
            const { anchorCoords, objectId } = action.payload;
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            const objects = cloneDeep(state.objects);
            let objectsTemp = [];

            const zones = objects[indexSelectedGroupZones].zones.map((element) => {
                if (element.front_id !== objectId) {
                    return element;
                } else {
                    const coords = element.coords.coordinates;
                    let index = null;
                    let statusAndAnchors = false;
                    coords.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, anchorCoords!)) {
                                index = i;
                                statusAndAnchors = false;
                            }
                        } else if (i === 0) {
                            if (commonTools.between(arr[arr.length - 1], point, anchorCoords!)) {
                                statusAndAnchors = true;
                                index = null;
                            }
                        }
                    });

                    if (index !== null) {
                        coords.splice(index, 0, anchorCoords!);
                    } else if (statusAndAnchors) {
                        coords.push(anchorCoords!);
                    }
                    return {
                        ...element,
                        coords: {
                            ...element.coords,
                            coordinates: coords,
                        },
                    };
                }
            });

            objectsTemp = cloneDeep(objects);
            objectsTemp[indexSelectedGroupZones].zones = zones;
            //
            state.objects = objectsTemp;
            state.objectsTemp = objectsTemp;
        },

        /**
         * Выбор объекта
         * @param state
         * @param action
         */
        selectZone: (
            state,
            action: PayloadAction<{ groupFrontId: string | undefined; itemFrontId: string | undefined }>,
        ) => {
            const { groupFrontId, itemFrontId } = action.payload;
            const objects = cloneDeep(state.objects);
            let selectedObjects: IZone | null = null;
            let indexGroup: number = 0;
            let zoneDataByMarkerZone: { [key: string]: IZone } | null = {};
            let selectedMarkerZone: string = '';

            objects.map((item, index) => {
                if (groupFrontId === item.front_id) {
                    indexGroup = index;
                    item.zones.forEach((itemZone, indexZone) => {
                        zoneDataByMarkerZone![itemZone!.zone_marker] = itemZone;
                        if (itemZone.front_id === itemFrontId) {
                            selectedObjects = itemZone;
                            selectedMarkerZone = itemZone.zone_marker;
                        }
                    });
                }
            });

            state.selectedObjects = selectedObjects;
            state.indexSelectedGroupZones = indexGroup;
            state.groupFrontId = groupFrontId;
            state.zoneFrontId = itemFrontId;
            state.zoneDataByMarkerZone = zoneDataByMarkerZone!;
            state.selectedMarkerZone = selectedMarkerZone;
        },

        /**
         * Сохранение данных группы зон
         * @param state
         * @param action
         */
        saveDataGroup: (state, action: PayloadAction<{ name: string | undefined; marker: string | undefined }>) => {
            const { name, marker } = action.payload;
            let objects = cloneDeep(state.objects);
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            let groupDataByMarkerGroup: { [key: string]: IGroupZones | undefined } = {};
            let namesGroupZonesByMarkersGroupZones = cloneDeep(state.namesGroupZonesByMarkersGroupZones);
            const namesGroupZonesByMarkersGroupZonesGlobal = cloneDeep(state.namesGroupZonesByMarkersGroupZonesGlobal);
            let markersGroupZonesByNamesGroupZones = cloneDeep(state.markersGroupZonesByNamesGroupZones);
            const markersGroupZonesByNamesGroupZonesGlobal = cloneDeep(state.markersGroupZonesByNamesGroupZonesGlobal);
            let isNamesValid = cloneDeep(state.isNamesValid);
            const selectedGroupZones = cloneDeep(state.selectedGroupZones);

            objects[indexSelectedGroupZones].group_marker = marker;
            objects[indexSelectedGroupZones].group_name = name;

            objects.forEach((item, index) => {
                groupDataByMarkerGroup[item.group_marker!] = item;
            });
            state.objects = objects;
            state.objectsTemp = objects;
            state.groupDataByMarkerGroup = groupDataByMarkerGroup;
            state.isNamesValid = isNamesValid;
            state.namesGroupZonesByMarkersGroupZones = namesGroupZonesByMarkersGroupZones;
            state.markersGroupZonesByNamesGroupZones = markersGroupZonesByNamesGroupZones;
            // state.selectedGroupZones = selectedGroupZones;
        },

        /***
         * Сохранение данных зоны
         * Валидирует выбранную зону со всеми зонами внутри группы
         * @param state
         * @param action
         */
        saveDataZone: (
            state,
            action: PayloadAction<{
                name: string;
                marker: string;
                zoneFrontId: string | undefined;
                userArea?: string | null;
            }>,
        ) => {
            const { name, marker, zoneFrontId, userArea } = action.payload;
            let objects = cloneDeep(state.objects);
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            let zoneDataByMarkerZone: { [key: string]: IZone } | null = {};
            let markersByNamesZones = cloneDeep(state.markersByNamesZones);
            let namesByMarkersZone = cloneDeep(state.namesByMarkersZone);
            let markersByGroupZonesMarkers = cloneDeep(state.markersByGroupZonesMarkers);
            let selectedObjects = cloneDeep(state.selectedObjects);
            const anotherLocations = cloneDeep(state.anotherLocations);
            let arrayNames: String[] = [];
            let arrayMarkers: String[] = [];

            let status = false;

            objects[indexSelectedGroupZones].zones.forEach((_item, _index) => {
                if (!selectedObjects?.is_multizone) {
                    if (_item.zone_name === name) {
                        arrayNames.push(_item.zone_name);
                    }
                    if (_item.zone_marker === marker) {
                        arrayMarkers.push(_item.zone_marker);
                    }
                }
            });
            objects[indexSelectedGroupZones].zones.forEach((item, index) => {
                if (item.front_id === zoneFrontId) {
                    item.zone_marker = marker!;
                    item.zone_name = name!;
                    item.user_area = userArea && Number(userArea) > 0 ? Number(userArea) : null;
                }
                zoneDataByMarkerZone![item!.zone_marker] = item;
            });

            selectedObjects!.zone_marker = marker;
            selectedObjects!.zone_name = name;
            // selectedObjects!.user_area = userArea && Number(userArea) > 0 ? Number(userArea) : null;

            if (selectedObjects?.is_multizone || (arrayNames.length <= 1 && arrayMarkers.length <= 1)) {
                // selectedObjects!.zone_marker = marker;
                // selectedObjects!.zone_name = name;

                status = compareNameZones(
                    objects,
                    objects[indexSelectedGroupZones],
                    anotherLocations,
                    zoneFrontId!,
                    name,
                    marker,
                );
                if (status) {
                    if (!markersByNamesZones![name!]) {
                        markersByNamesZones![name!] = {};
                        markersByNamesZones![name][objects![indexSelectedGroupZones!].group_marker!] = marker;
                    } else {
                        markersByNamesZones![name!]![objects![indexSelectedGroupZones!].group_marker!] = marker;
                    }

                    if (!namesByMarkersZone![marker!]) {
                        namesByMarkersZone![marker!] = {};
                        namesByMarkersZone![marker!][objects![indexSelectedGroupZones!].group_marker!] = name;
                    } else {
                        namesByMarkersZone![marker!][objects![indexSelectedGroupZones!].group_marker!] = name;
                    }
                }

                state.isNamesValid = { valid: status };
            } else {
                state.isNamesValid = { valid: false };
            }

            state.objects = objects;
            state.objectsTemp = objects;
            state.zoneDataByMarkerZone = zoneDataByMarkerZone!;
            state.markersByNamesZones = markersByNamesZones;
            state.namesByMarkersZone = namesByMarkersZone;
        },

        /**
         * Уделение объекта
         * @param state
         * @param action
         */
        deleteZone: (state, action: PayloadAction<{ zoneFrontId: string | undefined }>) => {
            const { zoneFrontId } = action.payload;
            let objects = cloneDeep(state.objects);
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            let zoneDataByMarkerZone: { [key: string]: IZone } | null = {};
            let indexTemp: number = 0;
            objects[indexSelectedGroupZones].zones.forEach((item, index) => {
                if (zoneFrontId === item.front_id) {
                    indexTemp = index;
                }
            });
            objects[indexSelectedGroupZones].zones.splice(indexTemp, 1);
            objects[indexSelectedGroupZones].zones.forEach((item, index) => {
                zoneDataByMarkerZone![item!.zone_marker] = item;
            });

            state.objects = objects;
            state.objectsTemp = objects;
            state.zoneDataByMarkerZone = zoneDataByMarkerZone;
        },

        /**
         * Уделение группы зон
         * @param state
         * @param action
         */
        deleteGroupZone: (state, action: PayloadAction<{ front_id: string | undefined }>) => {
            const { front_id } = action.payload;
            let objects = cloneDeep(state.objects);
            let indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            let groupDataByMarkerGroup: { [key: string]: IGroupZones | undefined } = {};
            let indexTemp: number = 0;

            objects.forEach((item, index) => {
                if (item.front_id === front_id) {
                    indexTemp = index;
                }
            });
            objects.splice(indexTemp, 1);
            indexSelectedGroupZones = 0;

            objects.forEach((item, index) => {
                groupDataByMarkerGroup![item!.group_marker!] = item;
            });

            state.indexSelectedGroupZones = indexSelectedGroupZones;
            state.objects = objects;
            state.objectsTemp = objects;
            state.groupDataByMarkerGroup = groupDataByMarkerGroup;
        },

        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjects = null;
            state.zoneFrontId = '';
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Сохранение в стор всех имен зи других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];
            const names: string[] = [];
            const markersGroupZones: string[] = [];
            const namesGroupZones: string[] = [];
            let markersByNamesZones = cloneDeep(state.markersByNamesZones);
            let namesByMarkersZone = cloneDeep(state.namesByMarkersZone);
            let markersByGroupZonesMarkers: { [key: string]: { [key: string]: string } } = {};
            let namesGroupZonesByMarkersGroupZonesGlobal: { [key: string]: string } = {};
            let markersGroupZonesByNamesGroupZonesGlobal = cloneDeep(state.markersGroupZonesByNamesGroupZonesGlobal);

            if (!markersGroupZonesByNamesGroupZonesGlobal) {
                markersGroupZonesByNamesGroupZonesGlobal = {};
            }

            action?.payload.forEach((item: { data?: Array<IGroupZones> }) => {
                if (!item.data) return;

                item?.data.forEach((obj, index) => {
                    markersByGroupZonesMarkers[obj.group_marker!] = {};
                    namesGroupZonesByMarkersGroupZonesGlobal[obj.group_marker!] = obj.group_name!;
                    markersGroupZonesByNamesGroupZonesGlobal![obj.group_name!] = obj.group_marker!;
                    obj.zones.forEach((obj2, index) => {
                        markers.push(obj2.zone_marker!);
                        names.push(obj2.zone_name!);
                        if (!markersByNamesZones) {
                            markersByNamesZones = {};
                        }
                        if (!namesByMarkersZone) {
                            namesByMarkersZone = {};
                        }
                        markersByNamesZones[obj2.zone_name!] = {};
                        namesByMarkersZone[obj2.zone_marker!] = {};
                        markersByNamesZones[obj2.zone_name!][obj.group_marker!] = obj2.zone_marker;
                        namesByMarkersZone[obj2.zone_marker!][obj.group_marker!] = obj2.zone_name;

                        markersByGroupZonesMarkers[obj.group_marker!][obj2.zone_marker!] = obj2.zone_name;
                    });
                    markersGroupZones.push(obj.group_marker!);
                    namesGroupZones.push(obj.group_name!);
                });
            });
            const anotherLocationNames = { markers, names, markersGroupZones, namesGroupZones };
            state.anotherLocationNames = anotherLocationNames;
            state.markersByNamesZones = markersByNamesZones;
            state.namesByMarkersZone = namesByMarkersZone;
            state.markersByGroupZonesMarkers = markersByGroupZonesMarkers;
            state.namesGroupZonesByMarkersGroupZonesGlobal = namesGroupZonesByMarkersGroupZonesGlobal;
            state.markersGroupZonesByNamesGroupZonesGlobal = markersGroupZonesByNamesGroupZonesGlobal;
            // console.log('>>>>>>>>>>>>>>>>>>11', action?.payload)
            state.anotherLocations = action?.payload!;
        },

        /**
         * Валидация маркеров.
         */
        validateNames: (state) => {
            const { objects, anotherLocationNames, selectedObjects } = cloneDeep(state);
            const currentMarkers = objects.map((item) => item.group_marker!);
            const currentNames = objects.map((item) => item.group_name!);
            const anotherLocations = cloneDeep(state.anotherLocations);
            const selectedGroupZones = cloneDeep(state.selectedGroupZones);
            const groupFrontId = cloneDeep(state.groupFrontId);

            const isNamesValid = compareNames(objects, selectedGroupZones!, anotherLocations, groupFrontId!);

            // console.log('>>>>>>>>>>>>>>>>>>2', isNamesValid)

            if (!selectedObjects) {
                state.isNamesValid.valid = isNamesValid;
            }
        },
        showTable: (state) => {
            const anotherLocations = cloneDeep(state.anotherLocations);
            const currentPlanData = cloneDeep(state.currentPlanData);
            const objects = cloneDeep(state.objects);
            let groupDataByMarkerGroupGlobal = cloneDeep(state.groupDataByMarkerGroupGlobal);
            // console.log('>>>>>>>>>>>>>>>>>', anotherLocations, currentPlanData, objects);
            let objFloorsAndGroupZones: IObjFloorsAndGroupZones = {
                floors: [],
                groups: {},
            };

            objFloorsAndGroupZones.floors.push({
                floor: currentPlanData!.floor!,
                statusCurrent: true,
            });
            groupDataByMarkerGroupGlobal = {};
            objects?.forEach((item, index) => {
                objFloorsAndGroupZones.groups[item!.group_marker!] = {};
                objFloorsAndGroupZones.groups[item!.group_marker!][currentPlanData!.floor!] = [];
                groupDataByMarkerGroupGlobal![item!.group_marker!] = item;
                item!.zones.forEach((itemZone, indexZone) => {
                    objFloorsAndGroupZones.groups[item!.group_marker!][currentPlanData!.floor!].push(itemZone);
                });
            });

            anotherLocations?.forEach((itemAnother: { data?: Array<IGroupZones>; floor?: number }, indexAnother) => {
                if (!itemAnother.data) return;
                objFloorsAndGroupZones.floors.push({
                    floor: itemAnother.floor!,
                    statusCurrent: false,
                });

                itemAnother.data.forEach((itemGroupZones, indexGroupZones) => {
                    if (!objFloorsAndGroupZones.groups[itemGroupZones!.group_marker!]) {
                        objFloorsAndGroupZones.groups[itemGroupZones!.group_marker!] = {};
                        objFloorsAndGroupZones.groups[itemGroupZones!.group_marker!][itemAnother.floor!] = [];
                    }
                    groupDataByMarkerGroupGlobal![itemGroupZones!.group_marker!] = itemGroupZones;
                    itemGroupZones!.zones.forEach((itemZone2, indexZone2) => {
                        objFloorsAndGroupZones.groups[itemGroupZones!.group_marker!][itemAnother.floor!].push(
                            itemZone2,
                        );
                    });
                });
            });

            state.objFloorsAndGroupZones = objFloorsAndGroupZones;
            state.groupDataByMarkerGroupGlobal = groupDataByMarkerGroupGlobal;
            state.isShowTable = true;
        },
        hideTable: (state) => {
            state.isShowTable = false;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /***
         * Функия сохранения статуса многозонности и валидация
         * Валидирует выбранную зону со всеми зонами внутри группы
         * @param state
         * @param action
         */
        checkIsMultiZone: (
            state,
            action: PayloadAction<{ name: string; marker: string; status: boolean; zoneFrontId: string | undefined }>,
        ) => {
            const { status, zoneFrontId, name, marker } = action.payload;
            let objects = cloneDeep(state.objects);
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            let zoneDataByMarkerZone: { [key: string]: IZone } | null = {};
            let markersByNamesZones = cloneDeep(state.markersByNamesZones);
            let namesByMarkersZone = cloneDeep(state.namesByMarkersZone);
            let markersByGroupZonesMarkers = cloneDeep(state.markersByGroupZonesMarkers);
            let selectedObjects = cloneDeep(state.selectedObjects);
            const anotherLocations = cloneDeep(state.anotherLocations);
            let arrayNames: String[] = [];
            let arrayMarkers: String[] = [];

            let tempStatus = false;
            selectedObjects!.zone_marker = marker;
            selectedObjects!.zone_name = name;
            objects[indexSelectedGroupZones].zones.forEach((_item, _index) => {
                if (!status) {
                    if (_item.zone_name === selectedObjects!.zone_name) {
                        arrayNames.push(_item.zone_name);
                    }
                    if (_item.zone_marker === selectedObjects!.zone_marker) {
                        arrayMarkers.push(_item.zone_marker);
                    }
                }
            });
            selectedObjects!.is_multizone = status;

            objects[indexSelectedGroupZones].zones.forEach((item, index) => {
                if (item.front_id === zoneFrontId) {
                    item.is_multizone = status;
                }
                zoneDataByMarkerZone![item!.zone_marker] = item;
            });

            state.selectedObjects = selectedObjects;
            state.objects = objects;
            state.objectsTemp = objects;
            state.zoneDataByMarkerZone = zoneDataByMarkerZone!;
            state.markersByNamesZones = markersByNamesZones;
            state.namesByMarkersZone = namesByMarkersZone;

            if (status || (arrayNames.length <= 1 && arrayMarkers.length <= 1)) {
                state.isNamesValid.valid = true;
            } else {
                state.isNamesValid.valid = false;
            }
        },

        removeLastCoords: (state, action: PayloadAction) => {
            state.objects = state.objectsTemp;
        },

        changeShapeCoords: (state, action: PayloadAction) => {
            state.objectsTemp = state.objects;
        },

        changeCoordsFullLayer: (state, action: PayloadAction<IZone[]>) => {
            const indexSelectedGroupZones = cloneDeep(state.indexSelectedGroupZones);
            state.objects[indexSelectedGroupZones].zones = action.payload;
        },
    },
});

export const {
    toggleTool,
    changeCoords,
    storeNewZone,
    selectGroupZones,
    storeCurrentPlanData,
    storeInitialCreatedAt,
    storeInitialObjects,
    saveNewGroupZones,
    toggleShowLabels,
    addAnchor,
    selectZone,
    saveDataGroup,
    saveDataZone,
    deleteZone,
    deleteGroupZone,
    storeGeneralSettings,
    storeVersionsData,
    clearSelectedObjects,
    storeAnotherLocationNames,
    validateNames,
    showTable,
    hideTable,
    checkIsMultiZone,
    pasteObjectFromClipboard,
    toggleContextMenu,
    deleteAnchor,
    storeHotAddObject,
    removeLastCoords,
    changeShapeCoords,
    changeCoordsFullLayer,
} = ZonesReducer.actions;

export const zonesValues = (state: RootState) => state.ZonesReducer;

export default ZonesReducer.reducer;
