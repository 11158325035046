/**
 * Словарь соответствий алиасов слоев и отображаемых в контрольной пенели имен слоев.
 */
export const layerNamesMapping: Map<string, string> = new Map<string, string>([
    ['pass_ways_layer', 'Pass ways'],
    ['zones_layer', 'Zones'],
    ['escalators_layer', 'Escalators'],
    ['lifts_layer', 'Lifts'],
    ['stairs_layer', 'Stairs'],
    ['checkouts_layer', 'Checkouts'],
    ['pc_sensor_ipoints_layer', 'PC ipoints'],
    ['radio_sensor_ipoints_layer', 'Radio ipoints'],
    ['places_layer', 'Places'],
    ['perimeter_layer', 'Perimeter'],
    ['measureCut', 'Measure cut'],
    ['nav_polygons_layer', 'Nav polygons'],
    ['tracks_tracking_layer', 'Tracks tracking'],
    ['tracks_navigation_layer', 'Tracks navigation'],
    ['tracks_wifi_layer', 'Tracks wifi'],
]);
