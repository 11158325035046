import React, { useContext, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { Image, ImageFit } from '@fluentui/react/lib/Image';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { DetailsList, buildColumns, IColumn, DetailsRow, IDetailsRowProps } from '@fluentui/react/lib/DetailsList';
import { mergeStyles } from '@fluentui/react/lib/Styling';
import { plansReducerValues, storePdfModalStatus } from '../../Plans.reducer';

import { PlansContext } from '../../Plans.context';
import {
    ListWrapper,
    customStyles,
    customStylesNotMain,
    NotesContainer,
    classNames,
    GeoWrapper,
    Floor,
    ImageWrapper,
} from './List.styles';
import { IPlan } from '../../Plans.interfaces';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DefaultButton } from '@fluentui/react';
import { useDispatch } from 'react-redux';
import { commonTools } from '../../../Layers/layers';
import { storeActiveLayerId } from '../../../Editor/Editor.reducer';

/**
 * Компонента списка планов.
 */
const List = ({ ...props }) => {
    const {
        plansList = [],
        storePlanToEditTrans,
        toggleAlertTrans,
        activeLocation,
        readOnly,
    } = useContext(PlansContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    function myBuildColumns(items: IPlan[]) {
        if (items.length === 0) return [];
        const columnsToDisplay = items.map((item) => {
            const { image, planScale, activeFrom, notes, isMain, floor } = item;
            const geoMapping = '';
            const uploadActualPlan = floor;
            const edit = '';
            return readOnly
                ? { image, floor, activeFrom, uploadActualPlan, notes }
                : { image, floor, activeFrom, uploadActualPlan, notes, planScale, isMain, geoMapping, edit };
        });

        const columns = buildColumns(columnsToDisplay);
        const [imageColumn] = columns.filter((column) => column.name === 'image');
        imageColumn.minWidth = 100;
        imageColumn.maxWidth = 100;
        const [floorColumn] = columns.filter((column) => column.name === 'floor');
        floorColumn.minWidth = 50;
        floorColumn.maxWidth = 50;
        const [activeFromColumn] = columns.filter((column) => column.name === 'activeFrom');
        activeFromColumn.minWidth = 200;
        activeFromColumn.maxWidth = 200;

        return columns;
    }

    const onRowClick = (plan: IPlan & { isActual: boolean }) => () => {
        if (!plan || !plan.isActual) return;
        if (activeLocation?.is_geomapped) {
            storePlanToEditTrans(plan);
            navigate(`${document.location.pathname}/plan/${plan.planId}`);
        } else {
            const text = t('You need to GeoMap this location');
            toggleAlertTrans({ show: true, text });
        }
    };

    const renderRow = (props: IDetailsRowProps | undefined) => {
        if (props) {
            // if (props.itemIndex % 2 === 0) {
            //     customStyles.root = { display: 'flex', backgroundColor: themeLighterAlt, cursor: 'pointer' };
            // }

            return props.item.isActual ? (
                <div onClick={onRowClick(props.item)}>
                    <DetailsRow {...props} styles={customStyles} />
                </div>
            ) : (
                <div onClick={onRowClick(props.item)}>
                    <DetailsRow {...props} styles={customStylesNotMain} />
                </div>
            );
        }
        return null;
    };

    const hostStyles: Partial<ITooltipHostStyles> = {
        root: { display: 'inline-block', width: '100%', height: '100%' },
    };

    const onGeoClick = (item: { [x: string]: any }) => (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onGeoClick({ location: activeLocation, plan: item });
    };

    const onEditClick = (plan: { [x: string]: any }) => (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(storeActiveLayerId('measureCut'));
        props.onEditPlanClick(plan);
    };

    const onUploadClick =
        (floor: number) =>
        (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement>) => {
            e.stopPropagation();
            dispatch(storePdfModalStatus({ show: true, floor }));
        };

    function renderItemColumn(item: { [x: string]: any }, index: any, column: IColumn | undefined) {
        if (!column?.fieldName) return null;

        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case 'planId':
                return <span>{String(fieldContent)}</span>;
            case 'uploadActualPlan':
                return item.isActual ? (
                    <DefaultButton
                        text={t('Upload actual plan')}
                        onClick={onUploadClick(item.floor)}
                        allowDisabledFocus
                    />
                ) : null;
            case 'floor':
                return <Floor>{String(fieldContent)}</Floor>;
            case 'planScale':
                return <span>{fieldContent.toFixed(2)}</span>;
            case 'geoMapping':
                return (
                    <GeoWrapper onClick={onGeoClick(item)}>
                        {activeLocation?.is_geomapped ? (
                            <FontIcon iconName="DoubleChevronRight8" className={classNames.grey} />
                        ) : (
                            <FontIcon iconName="DoubleChevronRight8" className={classNames.red} />
                        )}
                    </GeoWrapper>
                );
            case 'edit':
                return (
                    <GeoWrapper onClick={onEditClick(item)}>
                        <FontIcon iconName="PageEdit" className={classNames.red} />
                    </GeoWrapper>
                );
            case 'image':
                return (
                    <ImageWrapper>
                        <Image
                            src={fieldContent}
                            width={80}
                            height={60}
                            // imageFit={ImageFit.cover}
                        />
                    </ImageWrapper>
                );
            case 'isMain':
                return (
                    <span>
                        {fieldContent ? (
                            <FontIcon iconName="CheckMark" className={classNames.green} onClick={() => {}} />
                        ) : (
                            <FontIcon iconName="CalculatorSubtract" className={classNames.red} />
                        )}
                    </span>
                );

            case 'activeFrom':
                const date = DateTime.fromISO(fieldContent).toFormat("yyyy LLL dd 'at' HH':'mm':'ss");
                return <span>{date}</span>;
            case 'notes':
                return (
                    <TooltipHost content={String(fieldContent)} styles={hostStyles}>
                        <NotesContainer>{String(fieldContent)}</NotesContainer>
                    </TooltipHost>
                );

            case 'color':
                return (
                    <span
                        data-selection-disabled={true}
                        className={mergeStyles({
                            color: fieldContent,
                            height: '100%',
                            display: 'block',
                        })}
                    >
                        {fieldContent}
                    </span>
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    const filteredPlanList = props.showLeasingPlans ? plansList : plansList.filter((plan) => plan.isActual);

    return (
        <ListWrapper>
            <DetailsList
                items={filteredPlanList}
                onRenderRow={renderRow}
                onRenderItemColumn={renderItemColumn}
                columns={myBuildColumns(filteredPlanList)}
                selectionMode={0}
            />
        </ListWrapper>
    );
};

export default List;
