import styled from "styled-components/macro";
import { theme } from "../../../../../../../../../constants/theme";


export const BoxTable = styled.div`
    display: grid;
    grid-template-columns: 150px auto;
    grid-template-rows: 50px auto;
  grid-template-areas: 
    "header header"
    "row main";
    box-sizing: border-box;
    //min-height: 400px;
    //min-width: 400px;
  
`;

export const TableComponentST = styled.div`
    //min-height: 400px;
    //min-width: 400px;
    padding-top: 10px;
`

export const ItemHeaderComponent = styled.div`
    height: 50px;
    width: 150px;
    //outline: 1px solid rgb(0,0,0);
    border: 1px solid rgba(0,0,0,.2);
    box-sizing: border-box;
    line-break: anywhere;
    padding: 5px;
`

export const ItemColumnComponent = styled.div`
    min-height: 50px;
    width: 150px;
    //outline: 1px solid rgb(0,0,0);
    border: 1px solid rgba(0,0,0,.2);
    //border-right: 1px solid rgba(0,0,0,.2);
    padding-bottom: 5px;
    box-sizing: border-box;
    align-items: center;
    text-align: center;
    display: grid;
`

export const FirstColumn = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100px 100px 100px;
    //min-height: 300px;
    //width: 100px;
    //grid-area: row;
    //outline: 1px solid rgb(0,0,0);
`

export const FirstRow = styled.div`
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-template-rows: 100%;
    //height: 100px;
    //min-width: 400px;
    grid-area: header;
    //outline: 1px solid rgb(0,0,0);
`

export const BodyTable = styled.div`
      display: grid;
      grid-template-columns: auto auto auto auto;
      grid-template-rows: auto;
      //min-height: 300px;
      //min-width: 300px;
      grid-area: main;
    //outline: 1px solid rgb(0,0,0);
`
export const ItemBodyComponent = styled.div`
    min-height: 50px;
    width: 150px;
    //outline: 1px solid rgb(0,0,0);
    border-left: 1px solid rgba(0,0,0,.2);
    border-right: 1px solid rgba(0,0,0,.2);
    padding-bottom: 5px;
    box-sizing: border-box;
`

export const ItemColumnDetailComponent = styled.div`
    height: 100px;
    width: 150px;
    //outline: 1px solid rgb(0,0,0);
    display: grid;
    border-bottom: 1px solid rgba(0,0,0,.2);
    //border-right: 1px solid rgba(0,0,0,.2);
    padding-bottom: 5px;
    box-sizing: border-box;
    align-items: center;
`

export const ItemColumnDetailNameComponent = styled.span`
    display: block;
    width: 100%;
    border-bottom: 1px solid rgba(0,0,0,.1);
    line-break: anywhere;
    text-align: center;
`
export const ItemColumnDetailFirstComponent = styled.span<{statusCurrent: boolean}>`
    display: block;
    width: 100%;
    //border-bottom: 1px solid rgba(0,0,0,.1);
    //line-break: anywhere;
    color: ${p => p.statusCurrent ? theme.palette.themeDark : null};
    text-align: center;
`
