import { Pivot } from '@fluentui/react';
import styled from 'styled-components/macro';

export const ChaptersWrapper = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
`;

export const StyledPivot = styled(Pivot)`
    button {
        height: 30px;
    }
`;
