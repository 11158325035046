import { IButtonStyles } from '@fluentui/react';
import styled from 'styled-components/macro';
import { theme } from '../../../../constants/theme';
import { IVersionIconName } from './VersionsHisory.interfaces';

export const iconVersionsName: IVersionIconName = {
    iconName: 'Dictionary',
    
}

export const iconVersionsButtonClassNames: IButtonStyles = {
    icon: {
        fontSize: '16px',
        paddingLeft: '2.5px'
    }, 

    root: {
        color: theme.palette.black,
        ':hover': {
            color: theme.palette.themeDark
        }
    }
}
