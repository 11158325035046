import { IContextMenu } from '../../tools/commonInterfaces';
import { cloneDeep, isEqual, isNull } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { layersMapping, plansMapping } from '../../constants/keysMapping';
import { RootState, AppThunk } from '../../store';
import { postRequest, getLocalBase64Image, getRequest, patchRequest } from '../../tools/api';
import { mapBack2Front, mapFront2Back } from '../../tools/mappingFrontAndBack';
import { storePlanToAddMeasureCut } from '../Plans/Plans.reducer';
import {
    IEditorReducerState,
    IInitialLayers,
    ILayerPostRequest,
    IConflictModal,
    IVersionsHistoryModalStatus,
    IHotAddObject,
} from './Editor.interfaces';
import { sizes } from '../../constants/sizes';
import { commonTools } from '../Layers/layers';
import { crossFloorsLayers } from '../../constants/crossFloorsLayers';
import axios from 'axios';
import { validateGeometry } from './tools/validateGeometry';
import { toggleAlert } from '../../General.reducer';

const initialState: IEditorReducerState = {
    selectedChapter: 'layers',
    activeLayerId: null,
    activeMonitoringLayerAlias: 'sensors_monitoring_layer',
    // plIds: [],
    visibleLayersIds: [],
    nonValidLayersIds: [],
    changedLayersIds: [],
    visibleTuplesIds: [],
    showLabelsIds: [],
    showMonitoringLabelsAliaces: [],
    dataFromLayers: {},
    otherLayers: [],
    initialLayers: [],
    allInitialLayers: [],
    selectedObjectId: null,
    backId: null,
    mainPlanId: null,
    contextMenu: { show: false, menuItems: [] },
    conflictModal: { show: false, data: {} },
    versionsHistoryModalStatus: { show: false },
    hotAddObject: null,
    savedLayersFlag: 0,
    objectFromDiff: null,
};

export const EditorReducer = createSlice({
    name: 'EditorReducer',
    initialState,
    reducers: {
        /**
         * Сохранение объекта из Diff
         */
        storeObjectFromDiff: (state, action: PayloadAction<{ obj: { [x: string]: any } }>) => {
            state.objectFromDiff = action.payload;
        },
        /**
         * Изменение Флага сохранения какого-либо слоя.
         */
        changeSavedLayersFlag: (state, action: PayloadAction<number>) => {
            state.savedLayersFlag = action.payload;
        },

        /**
         * Запись Id активного слоя.
         */
        storeActiveLayerId: (state, action: PayloadAction<string | null>) => {
            state.activeLayerId = action.payload;
        },

        /**
         * Запись Id активного слоя.
         */
        selectMonitoringLayer: (state, action: PayloadAction<string | null>) => {
            state.activeMonitoringLayerAlias = action.payload;
        },

        /**
         * Запись Id активного слоя.
         */
        toggleChapter: (state, action: PayloadAction<string>) => {
            state.selectedChapter = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Сброс выбранного объекта
         */
        resetSlectedObject: (state) => {
            state.selectedObjectId = null;
        },

        /**
         * Запись Id активного слоя.
         */
        changeNonValidLayersIds: (state, action: PayloadAction<{ id: string; show: boolean }>) => {
            const { show, id } = action.payload;
            const { nonValidLayersIds } = cloneDeep(state);
            if (show) {
                state.nonValidLayersIds = Array.from(new Set([...nonValidLayersIds, id]));
            } else {
                const arr = nonValidLayersIds;
                arr.splice(nonValidLayersIds.indexOf(id), 1);
                state.nonValidLayersIds = arr;
            }
        },

        /**
         * Запись данных из слоев.
         */
        storeDataFromLayers: (
            state,
            action: PayloadAction<{ id: string; selectedObjectId?: string | null; backId?: number }>,
        ) => {
            const { dataFromLayers, initialLayers, changedLayersIds, nonValidLayersIds } = cloneDeep(state);

            const { id, selectedObjectId, backId } = action.payload;

            dataFromLayers[id] = action.payload;
            state.dataFromLayers = dataFromLayers;
            state.selectedObjectId = selectedObjectId ? selectedObjectId : null;
            state.backId = backId ? backId : null;

            const initialLayer = initialLayers.filter((data) => data.layerType === id)[0];
            const initial = initialLayer?.data;
            const current = dataFromLayers[id]?.objects;

            const initialLayersString = JSON.stringify(initial);
            const currentObjectsString = JSON.stringify(current);

            const emptyNavPolygons =
                id === 'nav_polygons_layer' &&
                current?.polygons_in.length === 0 &&
                current?.polygons_out.length === 0 &&
                initial === null;

            if (
                (initialLayersString === 'null' || initialLayersString === undefined) &&
                (currentObjectsString === 'null' || currentObjectsString === '[]' || currentObjectsString === '{}')
            ) {
                state.changedLayersIds = changedLayersIds.filter((item) => item !== id);
            } else if (currentObjectsString !== initialLayersString && !emptyNavPolygons) {
                changedLayersIds.push(id);
                state.changedLayersIds = Array.from(new Set(changedLayersIds));
            } else if (
                currentObjectsString === initialLayersString ||
                emptyNavPolygons ||
                nonValidLayersIds.includes(id)
            ) {
                state.changedLayersIds = changedLayersIds.filter((item) => item !== id);
            }
        },

        /**
         * Запись данных из остальных слоев.
         */
        storeOtherLayers: (
            state,
            action: PayloadAction<{ id: string; createdAt: string; anotherLocationLayers: { [x: string]: any }[] }>,
        ) => {
            const { anotherLocationLayers, createdAt } = action.payload;
            state.otherLayers = anotherLocationLayers;
        },

        /**
         * Изменение Начальных слоев.
         */
        changeInitialLayers: (
            state,
            action: PayloadAction<{ changedLayer: { [x: string]: any }; imageOffset: number[] }>,
        ) => {
            const { initialLayers, allInitialLayers } = cloneDeep(state);

            const mappedLayer = mapBack2Front(layersMapping, action.payload.changedLayer) as {
                mainPlanId: string | number;
                layerType: string | number;
            };

            state.allInitialLayers = allInitialLayers.map((item) => {
                if (item.mainPlanId === mappedLayer.mainPlanId && item.layerType === mappedLayer.layerType) {
                    return mappedLayer;
                } else {
                    return item;
                }
            });

            state.initialLayers = initialLayers.map((item) => {
                if (item.layerType === mappedLayer.layerType) {
                    const shiftedLayer = cloneDeep(mappedLayer);
                    commonTools.shiftCoords(
                        shiftedLayer,
                        [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT],
                        // action.payload.imageOffset.map((item) => -item + sizes.GLOBAL_SHIFT)
                    );
                    return shiftedLayer;
                } else {
                    return item;
                }
            });
        },

        /**
         * Запись начальных данных по слоям всей локации.
         */
        storeAllInitialLayers: (state, action: PayloadAction<IInitialLayers>) => {
            const allInitialLayers = action.payload.map((item) => {
                return mapBack2Front(layersMapping, item) as { layerType: string };
            });
            state.allInitialLayers = allInitialLayers;
        },

        /**
         * Запись начальных данных по слоям текущего этажа.
         */
        storeInitialLayers: (state, action: PayloadAction<{ data: IInitialLayers; imageOffset: number[] }>) => {
            const { data, imageOffset } = action.payload;

            commonTools.shiftCoords(
                data,
                [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT],
                // imageOffset.map((item) => -item + sizes.GLOBAL_SHIFT)
            );

            state.initialLayers = data.map((item) => {
                return mapBack2Front(layersMapping, item) as { layerType: string };
            });
        },

        /**
         * Запись стянутых из Шопстера слоев.
         */
        addShopsterLayers: (state, action: PayloadAction<{ data: IInitialLayers; imageOffset: number[] }>) => {
            const { data, imageOffset } = action.payload;
            commonTools.shiftCoords(data, [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT]);
            state.initialLayers = data;
        },

        /**
         * Переключение контекстного меню.
         */
        toggleContextMenu: (state, action: PayloadAction<IContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Переключение модалки конфликтов.
         */
        toggleConflictModal: (state, action: PayloadAction<IConflictModal>) => {
            state.conflictModal = action.payload;
        },

        /**
         * Сброс редьюсера.
         */
        resetEditor: (state) => {
            // state.activeLayerId = null;
            // state.visibleLayersIds = [];
            state.visibleTuplesIds = [];
            state.dataFromLayers = {};
            state.initialLayers = [];
            state.changedLayersIds = [];
            state.selectedChapter = 'layers';
        },

        /**
         * Запись Id главного плана.
         */
        storeMainPlanId: (state, action: PayloadAction<string | number | null>) => {
            state.mainPlanId = action.payload;
        },

        /**
         * Запись видимых слоев
         */
        storeVisibleLayers: (state, action: PayloadAction<string[]>) => {
            state.visibleLayersIds = action.payload;
        },

        /**
         * Изменение массива Ids видимых слоев.
         */
        toggleVisible: (state, action: PayloadAction<string | null>) => {
            const visibleLayersIds = cloneDeep(state.visibleLayersIds);
            const id = action.payload;
            if (id && visibleLayersIds.includes(id)) {
                state.visibleLayersIds = visibleLayersIds.filter((item) => item !== id);
            } else if (id && !visibleLayersIds.includes(id)) {
                visibleLayersIds.push(id);
                state.visibleLayersIds = visibleLayersIds;
            }
        },

        /**
         * Изменение массива Ids видимых слоев.
         */
        toggleTupleVisible: (state, action: PayloadAction<{ alias: string | null }>) => {
            const visibleTuplesIds = cloneDeep(state.visibleTuplesIds);
            const { alias } = action.payload;
            if (alias && visibleTuplesIds.includes(alias)) {
                state.visibleTuplesIds = visibleTuplesIds.filter((item) => item !== alias);
            } else if (alias && !visibleTuplesIds.includes(alias)) {
                visibleTuplesIds.push(alias);
                state.visibleTuplesIds = visibleTuplesIds;
            }
        },

        /**
         * Изменение массива Ids слоев у которых отображаются метки.
         */
        changeShowLabelsIds: (state, action: PayloadAction<string | null>) => {
            const showLabelsIds = cloneDeep(state.showLabelsIds);
            const layerId = action.payload;
            if (layerId && showLabelsIds.includes(layerId)) {
                state.showLabelsIds = showLabelsIds.filter((item) => item !== layerId);
            } else if (layerId && !showLabelsIds.includes(layerId)) {
                showLabelsIds.push(layerId);
                state.showLabelsIds = showLabelsIds;
            }
        },

        /**
         * Изменение массива aliaces слоев мониторинга у которых отображаются метки.
         */
        changeShowMonitoringLabelsAliaces: (state, action: PayloadAction<string | null>) => {
            const showMonitoringLabelsAliaces = cloneDeep(state.showMonitoringLabelsAliaces);
            const alias = action.payload;

            if (alias && showMonitoringLabelsAliaces.includes(alias)) {
                state.showMonitoringLabelsAliaces = showMonitoringLabelsAliaces.filter((item) => item !== alias);
            } else if (alias && !showMonitoringLabelsAliaces.includes(alias)) {
                showMonitoringLabelsAliaces.push(alias);
                state.showMonitoringLabelsAliaces = showMonitoringLabelsAliaces;
            }
        },

        /**
         * Апись полученных PlIds.
         */
        // storePlIds: (state, action: PayloadAction<{ [x: string]: string | number }[]>) => {
        //     state.plIds = action.payload;
        // },

        /**
         * Запись в стейт статуса модалки для отображения истории версий
         */
        toggleVersionsHistoryModalStatus: (state, action: PayloadAction<IVersionsHistoryModalStatus>) => {
            state.versionsHistoryModalStatus = action.payload;
        },
    },
});

export const {
    resetSlectedObject,
    storeActiveLayerId,
    addShopsterLayers,
    toggleVisible,
    toggleTupleVisible,
    storeDataFromLayers,
    storeOtherLayers,
    storeInitialLayers,
    toggleChapter,
    resetEditor,
    selectMonitoringLayer,
    storeAllInitialLayers,
    changeShowLabelsIds,
    changeNonValidLayersIds,
    storeMainPlanId,
    toggleContextMenu,
    changeInitialLayers,
    changeShowMonitoringLabelsAliaces,
    // storePlIds,
    toggleConflictModal,
    changeSavedLayersFlag,
    toggleVersionsHistoryModalStatus,
    storeHotAddObject,
    storeObjectFromDiff,
    storeVisibleLayers,
} = EditorReducer.actions;

/**
 * Запрос списка слоев с сервера.
 */
export const fetchLayers =
    (locationId: string | number | null, versionId?: number | string): AppThunk =>
    (dispatch, getState) => {
        const { planToEdit, plansList, imageOffset } = cloneDeep(getState().PlansReducer);
        const { token, urls } = cloneDeep(getState().GeneralReducer);
        const floor = planToEdit?.floor;
        let mainPlanId: string | number | undefined = undefined;
        try {
            mainPlanId = plansList.filter((item) => item.floor === floor).filter((item) => item.isMain)[0].planId;
        } catch (error) {}
        const url = `${urls.LAYERS_URL}?location_id=${locationId}&version_id=${versionId}`;

        locationId &&
            getRequest({ url, dispatch, allowSpinner: true, token }).then((data) => {
                data &&
                    dispatch(
                        storeInitialLayers({
                            data: data.filter(
                                (item: { main_plan_id: string | number | null }) => item.main_plan_id === mainPlanId,
                            ),
                            imageOffset,
                        }),
                    );
                data && dispatch(storeAllInitialLayers(data));
                mainPlanId && dispatch(storeMainPlanId(mainPlanId));
            });
    };

/**
 * Запрос списка слоев с сервера и запись в стор одного слоя.
 */
export const changeOneLayer =
    ({
        url,
        token,
        locationId,
        activeVersionId,
        mainPlanId,
        layerType,
    }: {
        url: string;
        token: string;
        locationId: string | number | null;
        activeVersionId: string | number | null;
        mainPlanId: string | number | null;
        layerType: string;
    }): AppThunk =>
    (dispatch, getState) => {
        const { imageOffset } = cloneDeep(getState().PlansReducer);
        locationId &&
            mainPlanId &&
            getRequest({
                url: `${url}?location_id=${locationId}&version_id=${activeVersionId}`,
                dispatch,
                allowSpinner: true,
                token,
            }).then((data) => {
                const [changedLayer] = data
                    ?.filter((item: { main_plan_id: string | number | null }) => item.main_plan_id === mainPlanId)
                    .filter((item: { layer_type: string }) => item.layer_type === layerType);
                dispatch(changeInitialLayers({ changedLayer, imageOffset }));
            });
    };

/**
 * Запись слоя на сервер.
 *
 * @param args данные для записи
 */
export const saveLayerToServer =
    (args: { id: string; layersUrl: string; locationId?: string; isEdit?: boolean; planId?: number }): AppThunk =>
    (dispatch, getState) => {
        const { token, urls } = cloneDeep(getState().GeneralReducer);
        const locationId = cloneDeep(getState().LocationsReducer.activeLocation?.id);
        const activeVersionId = cloneDeep(getState().VersionsReducer.activeVersionId);

        switch (args.id) {
            case 'measureCut':
                const mappedData = mapFront2Back(plansMapping, { ...args }) as { image: string };

                if (!args.isEdit) {
                    mappedData.image &&
                        getLocalBase64Image(mappedData.image).then((image) => {
                            postRequest({
                                url: urls.PLANS_URL,
                                dispatch,
                                allowSpinner: true,
                                token,
                                data: { ...mappedData, image, scale: 100 },
                            }).then((data) => {
                                dispatch(storePlanToAddMeasureCut({ planToAddMeasureCut: null }));
                                window.location.reload();
                            });
                        });
                } else {
                    patchRequest({
                        url: `${urls.PLANS_URL}${args.planId}/`,
                        dispatch,
                        allowSpinner: true,
                        token,
                        data: { ...mappedData },
                    }).then((data) => {
                        dispatch(storePlanToAddMeasureCut({ planToAddMeasureCut: null }));
                        window.location.reload();
                    });
                }

                break;

            default:
                const dataFromLayer = cloneDeep(getState().EditorReducer.dataFromLayers)[args.id];
                const { mainPlanId, initialLayers, allInitialLayers } = cloneDeep(getState().EditorReducer);

                getRequest({
                    url: `${args.layersUrl}?location_id=${locationId}&version_id=${activeVersionId}`,
                    dispatch,
                    allowSpinner: true,
                    token,
                }).then((response) => {
                    const actualServerData = response
                        .map((item: object) => {
                            return mapBack2Front(layersMapping, item) as { layerType: string };
                        })
                        .filter(
                            (item: { mainPlanId: string | number; layerType: string }) =>
                                item.mainPlanId === mainPlanId && item.layerType === args.id,
                        )[0];

                    const initialServerData = initialLayers.filter(
                        (item) => item.mainPlanId === mainPlanId && item.layerType === args.id,
                    )[0];

                    const isConflict = actualServerData.createdAt !== initialServerData.createdAt;

                    if (isConflict) {
                        dispatch(
                            toggleConflictModal({ show: true, data: { conflictDate: actualServerData.createdAt } }),
                        );
                    } else {
                        dispatch(storeLayerToServer(args));
                    }
                });
                break;
        }
    };

/**
 * Запись слоя на сервер.
 *
 * @param args данные для записи
 */
export const storeLayerToServer =
    (args: {
        id: string;
        layersUrl: string;
        locationId?: string;
        isRewrite?: boolean;
        actualCreatedAt?: string;
    }): AppThunk =>
    async (dispatch, getState) => {
        const { token, urls } = cloneDeep(getState().GeneralReducer);
        const { imageOffset } = cloneDeep(getState().PlansReducer);

        const activeVersionId = cloneDeep(getState().VersionsReducer.activeVersionId);
        const currentPlan = cloneDeep(getState().PlansReducer.planToEdit);
        const locationId = cloneDeep(getState().LocationsReducer.activeLocation?.id);

        const dataFromLayer = cloneDeep(getState().EditorReducer.dataFromLayers)[args.id];

        const validateResult = validateGeometry(dataFromLayer);

        if (validateResult.errors.length) {
            const errors = validateResult.errors.map((err) => `${err}\n`).join('');
            dispatch(toggleAlert({ show: true, text: errors }));

            return;
        }

        const { mainPlanId, otherLayers, savedLayersFlag } = cloneDeep(getState().EditorReducer);

        const keysToTrim = ['marker', 'name', 'group_marker', 'group_name', 'zone_marker', 'zone_name'];

        const trimmedData = commonTools.modifyStringValues(dataFromLayer.objects, keysToTrim, (x: string) => x.trim());

        const data: ILayerPostRequest = {
            main_plan_id: mainPlanId,
            layer_type: args.id,
            version_id: activeVersionId,
            data: trimmedData,
        };
        if (dataFromLayer.lastCreatedAt) {
            data.last_created_at = dataFromLayer.lastCreatedAt;
        }
        if (args.isRewrite && args.actualCreatedAt) {
            data.last_created_at = args.actualCreatedAt;
        }

        commonTools.shiftCoords(data, [-sizes.GLOBAL_SHIFT, -sizes.GLOBAL_SHIFT]);
        dispatch(changeSavedLayersFlag(savedLayersFlag + 1));
        if (!crossFloorsLayers.includes(args.id)) {
            postRequest({ url: urls.LAYERS_URL, dispatch, allowSpinner: true, token, data }).then((data) => {
                currentPlan?.planId &&
                    locationId &&
                    dispatch(
                        changeOneLayer({
                            url: urls.LAYERS_URL,
                            activeVersionId,
                            token,
                            locationId,
                            mainPlanId,
                            layerType: args.id,
                        }),
                    );
            });
        } else if (crossFloorsLayers.includes(args.id)) {
            const layersRequests = otherLayers
                .filter((item) => item && item?.data !== null && item?.data?.length)
                .map((item) => {
                    const mappedItem = mapFront2Back(layersMapping, item) as { created_at?: string };
                    let last_created_at = mappedItem.created_at;
                    delete mappedItem.created_at;
                    return last_created_at
                        ? { ...mappedItem, version_id: activeVersionId, last_created_at }
                        : { ...mappedItem, version_id: activeVersionId };
                })
                .map((item) => {
                    return postRequest({ url: urls.LAYERS_URL, dispatch, allowSpinner: true, token, data: item });
                });
            layersRequests.push(postRequest({ url: urls.LAYERS_URL, dispatch, allowSpinner: true, token, data }));

            const res = await axios
                .all(layersRequests)
                .then(
                    axios.spread((...responses) => {
                        const report = responses.map((item) => {
                            window.location.reload();
                        });
                        return report;
                    }),
                )
                .catch((errors) => {
                    console.warn('Error in layersRequests: ', errors);
                });
        }
    };

/**
 * Запрос Pl gj Ml.
 *
 * @param args данные для записи
 */
// export const fetchPlIds =
//     (args: { url: string; mlId: number }): AppThunk =>
//     (dispatch, getState) => {
//         dispatch(resetTablesReducer());
//         const { token } = cloneDeep(getState().GeneralReducer);
//         getRequest({
//             url: `${args.url}?ml_id=${args.mlId}`,
//             dispatch,
//             allowSpinner: true,
//             token,
//         }).then((data) => {
//             dispatch(storePlIds(data));
//         });
//     };

export const editorReducerValues = (state: RootState) => state.EditorReducer;

export default EditorReducer.reducer;
