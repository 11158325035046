import React, { createContext } from 'react';

import { IEditorContext } from './Editor.interfaces';

export const EditorContext = createContext<IEditorContext>({
    selectedChapter: 'layers',
    dataFromLayers: {},
    versions: [],
    tuples: [],
    monitoringLayers: [],
    // plIds: [],
    layers: [],
    visibleLayersIds: [],
    changedLayersIds: [],
    visibleTuplesIds: [],
    plansList: [],
    storePlanToEditTrans: () => {},
    toggleTupleVisibleTrans: () => {},
    showTupleModalTrans: () => {},
    showMonitoringModalTrans: () => {},
    plansIdsByVersionIds: {},
    activeVersionId: null,
    hotAddObject: null,
    objectFromDiff: null,
    leftHandSplitPercentWidth: 75,
    generalSettings: {
        lang: 'en',
        snapToGrid: 0.25,
        gridValue: 0.25,
        readOnly: true,
        displayContent: true,
        objNamingMode: 'markers',
    },
});
