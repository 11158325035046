import { FC } from 'react';
import { ITableCustomComponentProps } from '../../store';
import React from 'react';
import { Stack } from '@fluentui/react';
import { Text } from './styles';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../../../constants/theme';
import { commonTools } from '../../../../../../tools/commonTools';

const ExternalIds: FC<ITableCustomComponentProps> = (props) => {
    const { t } = useTranslation();

    const externalIds = props.cell.dataObject?.external_ids as undefined | { [x: string]: unknown }[];

    const keysMap: { [x: string]: { [x: string]: string } } = {
        data_source: {
            text: 'Data source',
            key: 'data_source',
        },
        external_id: {
            text: 'External ID',
            key: 'external_id',
        },
    };

    if (!externalIds) return null;

    return (
        <Stack verticalAlign="start" tokens={{ childrenGap: 10 }} style={{ overflow: 'hidden' }}>
            {externalIds.map((externalId, index) => {
                return (
                    <Stack
                        style={{ borderTop: index !== 0 ? `1px solid ${theme.palette.neutralTertiary}` : undefined }}
                        key={JSON.stringify(externalId['data_source']) + JSON.stringify(externalId['external_id'])}
                        tokens={{ childrenGap: 5, padding: index !== 0 ? '10px 0 0 0' : undefined }}
                        verticalAlign="start"
                        wrap={true}
                    >
                        {Object.entries(externalId).map(([key, value]) => {
                            return (
                                <Text key={key}>
                                    <span>{t(keysMap[key]?.text || key)}:&nbsp;</span>
                                    <div>
                                        {typeof value !== 'string'
                                            ? commonTools.getReadableStringRecursive(value)
                                            : value}
                                    </div>
                                </Text>
                            );
                        })}
                    </Stack>
                );
            })}
        </Stack>
    );
};

export default ExternalIds;
