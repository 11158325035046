import { omit } from 'lodash';
import { ISensor } from '../../../../../../../interfaces';
import { IAdditionalData, ICell } from '../MonitoringDetailedData.interfaces';
import { MONITORING_DETAILED_DATA_COLUMNS } from '../constants/constants';
import { commonTools } from '../../../../../../../../../monitoringLayers';

interface IArgs {
    sensor?: ISensor;
    isHeader?: boolean;
    additionalData?: IAdditionalData;
}

const generateTableRow = (args: IArgs): ICell[] => {
    const result: ICell[] = MONITORING_DETAILED_DATA_COLUMNS.map((column) => {
        const frontId = commonTools.generateId();
        const cell: ICell = {
            ...omit(column, ['getCellValue', 'component']),
            frontId,
            sensor: args.sensor,
            isHeader: Boolean(args.isHeader),
            readOnly: true,
            value: '',
        };

        const valueFromSensor =
            column.sensorDataKey && args.sensor?.[column.sensorDataKey]
                ? String(args.sensor?.[column.sensorDataKey])
                : null;

        if (typeof valueFromSensor === 'string') {
            cell.value = valueFromSensor;
        }

        if (column.getCellValue) {
            cell.value = column.getCellValue(cell, { ...(args.additionalData || {}) });
        }

        if (args.isHeader) {
            cell.value = column.headerText;
        }

        return cell;
    });
    return result;
};

export default generateTableRow;
