import styled from 'styled-components/macro';
import { IStackProps } from '@fluentui/react/lib/Stack';
import { theme } from '../../../../constants/theme';

export const MainWrapper = styled.section``;

export const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
};

export const footerProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { marginTop: 25 } },
};

export const LabelWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
`;

export const FileLabel = styled.label`
    display: flex;
    align-items: center;
    flex-grow: 0;
`;
export const Image = styled.img`
    width: 100%;
`;

export const ChooseFile = styled.div`
    -webkit-tap-highlight-color: transparent;
    -webkit-box-direction: normal;
    /* background: transparent; */
    font: inherit;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    text-overflow: ellipsis;
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    font-weight: 600;
    box-sizing: border-box;
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding-top: 0px;
    padding-right: 16px;
    padding-bottom: 0px;
    padding-left: 16px;
    height: 32px;
    color: rgb(255, 255, 255);
    background-color: ${theme.palette.themePrimary};
    user-select: none;
    outline: transparent;
    /* border-width: 1px;
    border-style: solid;
    border-image: initial; */
    text-decoration: none;
    border-radius: 2px;
    font-family: inherit;
    flex-shrink: 1;
`;
