import { DefaultButton } from '@fluentui/react';
import styled from 'styled-components/macro';

export const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;
export const textFieldStyles = { root: { width: '100%', marginBottom: 10 } };
export const labelStyles = { root: { color: 'red' } };

export const MainWrapper = styled.div`
    display: flex;
    width: 600px;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
`;

export const TextWrapper = styled.div`
    width: 100%;
    font-size: 16px;
`;

export const PlanId = styled.div`
    span {
        font-weight: 600;
    }
    margin-right: 10px;
`;

export const StyledDefaultButton = styled(DefaultButton)`
    padding: 5px;
    border: none;
    margin-left: 10px;
    span {
        font-weight: 400 !important;
        justify-content: flex-start;
    }

    background-color: transparent !important;
`;

export const MainPlanIdTitle = styled.div`
    font-weight: 600;
`;

export const MainPlanInputWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
`;

export const ErrorMessage = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin-top: 5px;
`;
