import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { tuplesNamesMapping } from '../../../../../../constants/tuplesNamesMapping';
import Eye from '../../../../../Eye/Eye';
import { ITuple } from '../../../../../Tuples/interfaces';
import { EditorContext } from '../../../../Editor.context';
import { TuplesWrapper, Li, Container, StyledFontIcon, iconClass, Text } from './TuplesList.styles';

const TuplesList = ({ visible }: { visible: boolean }) => {
    const {
        tuples,
        visibleTuplesIds,
        toggleTupleVisibleTrans,
        showTupleModalTrans,
        dataFromLayers,
        currentPlanData,
        plansList,
        layers,
    } = useContext(EditorContext);
    const { t } = useTranslation();

    const onEyeClick = (alias: string) => {
        toggleTupleVisibleTrans(alias);
    };

    const onShowTupleModalClick = (alias: string) => () => {
        showTupleModalTrans(alias);
    };

    const list = useMemo(() => {
        // if (!visible) return null;
        return tuples.map((item: ITuple) => {
            const visible = visibleTuplesIds.includes(item.alias);
            const Controls = item.controls;
            const props = {
                key: item.alias,
                isLayerActive: false,
                dataFromLayers,
                currentPlanData,
                visible,
                plansList,
            };
            return (
                <Li key={item.alias}>
                    <Controls {...props} />
                    <Eye eyeVisible={visible} id={item.alias} onEyeClick={onEyeClick} />
                    <Text>{t(tuplesNamesMapping.get(item.alias) || '')}</Text>
                    <Container onClick={onShowTupleModalClick(item.alias)}>
                        <StyledFontIcon iconName="Table" className={iconClass('black')} />
                    </Container>
                </Li>
            );
        });
    }, [currentPlanData, dataFromLayers, onEyeClick, onShowTupleModalClick, tuples, visibleTuplesIds]);

    return <TuplesWrapper visible={visible}>{list}</TuplesWrapper>;
};

export default TuplesList;
