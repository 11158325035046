import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { CommandBar, ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { Coachmark } from '@fluentui/react/lib/Coachmark';
import { IComponentAsProps, IComponentAs } from '@fluentui/react/lib/Utilities';
import { TeachingBubbleContent } from '@fluentui/react/lib/TeachingBubble';
import { CommandBarButton, IButtonProps } from '@fluentui/react/lib/Button';
import { Layer, Line, Group, Shape, Circle, Tag, Text, Label } from 'react-konva';

import {
    trackingTracksValues,
    changeCoords,
    storeNewTrack,
    addAnchor,
    selectTrack,
    clearSelectedObjects,
    selectPoint,
    changeCoordsFullLayer
} from '../../reducer';
import { ObjectLabel, Anchors, Helper } from '../../../../layers';
import { WrapperLayer } from './Draw.styles';

import { formattingCoords } from './core/formattingCoords';
import { getIntersect } from './core/getIntersect';
import { commonTools } from '../../../../../../tools/commonTools';
import { IPlan } from '../../../../layers.interfaces';
import StepCircle from './components/StepCircle';
import { cloneDeep } from 'lodash';
import { IObjects } from '../../tracksWifi.interfaces';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        showLabels,
        trackFrontId,
        currentPlanData,
        generalSettings: { snapToGrid },
        selectedObjects,
        indexPoint,
    } = useSelector(trackingTracksValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [coordsEnd, setCoordsEnd] = useState<number[]>([]);
    const [statusOverShape, setStatusOverShape] = useState<boolean>(false);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [
        isMouseDownMove,
        setIsMouseDownMove
    ] = useState<boolean>(false)

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw') {
            if (container) {
                container.style.cursor = 'crosshair';
            }
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        }
    }, [activeToolId]);

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw') {
            container.style.cursor = 'crosshair';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'anchorsEdit' && statusOverShape) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId, statusOverShape]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCoords = (coordsS: number[]) => {
        let tempCoords = coords;

        tempCoords[tempCoords.length - 1] = coordsS;

        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const updateCoordsAnchors = (coordsS: number[][] | undefined, front_id: string) => {
        if (activeToolId === 'anchorsEdit') {
            dispatch(changeCoords({ coordsS, front_id }));
        }
    };

    const addNewPoint = (coordsS: number[]) => {
        let tempCoords = coords;
        if (tempCoords.length === 0) {
            tempCoords.push(coordsS);
        }

        tempCoords.push(coordsS);
        if (formattingCoords(objects, tempCoords)) {
            return;
        }
        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const onHelperClick = (objectId: string) => (e: KonvaEventObject<MouseEvent>) => {
        if (objectId !== '') {
            if (activeToolId === 'anchorsEdit') {
                if (selectedObjects) {
                    /*
                    Тут не надо привязывать к сетке
                     */
                    const coords = commonTools.getPointerCoords({ e, snapToGrid: false });
                    e.evt.preventDefault();
                    const coordsTemp = [coords![0], coords![1]];
                    dispatch(addAnchor({ anchorCoords: coordsTemp, objectId }));
                } else {
                    dispatch(selectTrack({ trackFrontId: objectId }));
                }
            }
        } else {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            if (activeToolId === 'draw') {
                updateIsDrawNow(true);
                newPoint && addNewPoint(newPoint);
            } else {
                e.evt.preventDefault();
                dispatch(clearSelectedObjects());
            }
        }
    };

    const onMouseDown = (id: string) => (e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        // setIdSelectedShape(id);
        if (activeToolId === 'move') {
            dispatch(selectTrack({ trackFrontId: id }));
        }
        else if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true)
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());
        }
        else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false)
        }
    };

    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (trackFrontId !== '') {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects.find((item) => item.front_id === trackFrontId);

                if (moveObj) {
                    _coordsShape = commonTools.processCoordsForMoveShape(
                        moveObj.points.coordinates,
                        coords,
                        coordsOldShape,
                    );
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: trackFrontId }));

                setCoordsOldShape(coords);
            }
        }
        else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IObjects = []
                objects.forEach((item, i) => {
                    _objects.push({
                        ...item,
                        points: {
                            ...item.points,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.points.coordinates,
                                coords,
                                coordsOldShape,
                            )
                        }
                    })
                })


                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }

        }
    };

    const onClickCircleAnd = () => {
        setIsDrawNow(false);
        let tempCoords = coords;
        tempCoords.splice(coords.length - 1, 1);
        dispatch(storeNewTrack(tempCoords));
        setCoords([]);
        setCoordsEnd([]);
    };

    const onShapeMove = (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (newPoint) {
            updateCoords(newPoint);
        }
    };

    const onClickAnchor = (coords: number[], index: number) => {
        // console.log('>>>>>>>>>>>>>>>', coords);
        dispatch(selectPoint(index));
    };

    const newShape = useMemo(() => {
        if (!coords || coords.length === 0 || !props.isLayerActive) return null;

        let componentCircle = null;

        if (
            Math.abs(coordsEnd[0] - coords[coords.length - 2][0]) < 5 &&
            Math.abs(coordsEnd[1] - coords[coords.length - 2][1]) < 5 &&
            coords.length > 2
        ) {
            componentCircle = (
                <Group onClick={onClickCircleAnd}>
                    <Circle
                        id={`circleMeddle`}
                        key={`circleMeddle`}
                        x={coords[coords.length - 2][0]}
                        y={coords[coords.length - 2][1]}
                        radius={10 / props.scale.stageScale}
                        stroke={'#fff'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    <Circle
                        id={`circleBig`}
                        key={`circleBig`}
                        x={coords[coords.length - 2][0]}
                        y={coords[coords.length - 2][1]}
                        radius={12 / props.scale.stageScale}
                        stroke={'rgba(0,0,0,0.3)'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    <Circle
                        id={`circleSmall`}
                        key={`circleSmall`}
                        x={coords[coords.length - 2][0]}
                        y={coords[coords.length - 2][1]}
                        radius={8 / props.scale.stageScale}
                        stroke={'rgba(0,0,0,0.3)'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                </Group>
            );
        }

        return (
            <Layer key={props.key + '+++'}>
                <Group>
                    <Shape
                        id={'Shape!@##11'}
                        key={'Shape!@##11'}
                        onMouseMove={onShapeMove}
                        onClick={onHelperClick('')}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coords[0][0], coords[0][1]);
                            coords.forEach((coord, i) => {
                                if (i > 0) {
                                    context.lineTo(coord[0], coord[1]);
                                }
                            });
                            context.fillStrokeShape(shape);
                        }}
                        opacity={1}
                        stroke={'grey'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    {componentCircle}
                </Group>
            </Layer>
        );
    }, [coords, props.scale, coordsEnd]);

    const onShapeMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(true);
    };
    const onShapeMouseOut = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(false);
    };
    const onClickItemColumn = (groupFrontId?: string, itemFrontId?: string) => () => {
        if (activeToolId === 'anchorsEdit') {
            dispatch(selectTrack({ trackFrontId: itemFrontId }));
        }
    };
    const labelCoords = (coordsList: number[][]) => {
        return commonTools.findLabelCoords(coordsList);
    };

    const geometry = useMemo(() => {
        if (!objects || objects.length === 0) return null;
        const [selectedObject] = objects.filter((item) => item.front_id === selectedObjects?.front_id);

        const componentNode = cloneDeep(objects).map((item, index) => {
            const selected = item.front_id === selectedObjects?.front_id;
            let coordsList = item.points.coordinates;

            return (
                <Group
                    onClick={onClickItemColumn(item.front_id, item.front_id)}
                    key={index + '+++!!!!'}
                    onMouseDown={onMouseDown(item.front_id)}
                >
                    <Shape
                        onClick={onHelperClick(item.front_id)}
                        onMouseOver={onShapeMouseOver}
                        onMouseOut={onShapeMouseOut}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coordsList[0][0], coordsList[0][1]);
                            coordsList.forEach((coord, i) => {
                                if (i > 0) {
                                    context.lineTo(coord[0], coord[1]);
                                }
                            });
                            // context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        opacity={1}
                        stroke={'rgb(2,140,245)'}
                        // fill={item.is_opened ? 'rgb(16,155,0,.3)' : 'rgba(0,0,0,0.5)'}
                        strokeWidth={
                            trackFrontId === item.front_id ? 4 / props.scale.stageScale : 2 / props.scale.stageScale
                        }
                    />
                    <StepCircle
                        steps={item.duration_mask}
                        scale={props.scale}
                        coords={coordsList}
                        statusSelect={trackFrontId === item.front_id}
                        indexPoint={indexPoint}
                    />
                    {trackFrontId === item.front_id ? (
                        <Anchors
                            currentPlanData={currentPlanData!}
                            onClickAnchor={onClickAnchor}
                            snapToGrid={snapToGrid}
                            activeToolId={activeToolId}
                            coords={coordsList}
                            updateCoords={updateCoords}
                            updateCoordsAnchors={updateCoordsAnchors}
                            scale={props.scale}
                            front_id={item.front_id}
                        />
                    ) : null}

                    {labelCoords(coordsList) && !isDrawNow && showLabels && !selected && (
                        <ObjectLabel
                            stageScale={props.scale.stageScale}
                            x={labelCoords(coordsList).x}
                            y={labelCoords(coordsList).y}
                            marker={item.front_id}
                            selected={false}
                        />
                    )}
                </Group>
            );
        });

        return (
            <>
                <Group>{componentNode}</Group>
                {selectedObject && !isDrawNow && showLabels && (
                    <ObjectLabel
                        stageScale={props.scale.stageScale}
                        x={labelCoords(selectedObject.points.coordinates)?.x}
                        y={labelCoords(selectedObject.points.coordinates)?.y}
                        marker={selectedObject.front_id}
                        selected={true}
                    />
                )}
            </>
        );
    }, [objects, activeToolId, coords, props.scale, isDrawNow, showLabels, trackFrontId, indexPoint]);
    return (
        <>
            <WrapperLayer key={props.key} onMouseMove={moveShape} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}>
                <Helper
                    currentPlanData={currentPlanData!}
                    updateCoords={updateCoords}
                    isDrawNow={isDrawNow}
                    snapToGrid={snapToGrid}
                    onHelperClick={onHelperClick('')}
                />
                {geometry}
            </WrapperLayer>
            {newShape}
        </>
    );
};

export default Draw;
