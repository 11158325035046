import React from 'react'
import { Provider } from 'react-redux'
import { store } from '../../store'
import Draw from './Draw'

export default ({...props}) => {
    return (
        <Provider store={store}>
            <Draw {...props}/>
        </Provider>
    )
}