import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, IStackProps, IStackTokens } from '@fluentui/react/lib/Stack';
import { changeObjectOptions, perimeterValues } from '../../../../perimeter.reducer';
import Flatten from '@flatten-js/core';

import { AreaWarning, ObjectOptionsWrapper, StyledStack } from './ObjectOptions.styles';
import { TextField } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

/**
 * Опции для выбюраного объекта
 * @constructor
 */
const ObjectOptions = () => {
    const { selectedObjects, currentPlanData, generalSettings } = useSelector(perimeterValues);
    const { t } = useTranslation();
    const [calculatedArea, setCalculatedArea] = useState<number>(0);
    const [userArea, setUserArea] = useState<string>('');
    const dispatch = useDispatch();
    const { Polygon } = Flatten;

    useEffect(() => {
        setUserArea(String(selectedObjects?.user_area || ''));
        const placeCoords = selectedObjects?.coords.coordinates;
        const placeArea = new Polygon(placeCoords as any[]).area() / Math.pow(currentPlanData?.planScale || 1, 2);
        setCalculatedArea(() => placeArea);
    }, [Polygon, currentPlanData?.planScale, selectedObjects]);

    const StackTokens: IStackTokens = {
        childrenGap: 5,
    };

    const onChangeUserArea = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const userArea = newValue && isFinite(Number(newValue)) && Number(newValue) >= 0 ? newValue : '';
        setUserArea(() => userArea);
        newValue &&
            dispatch(
                changeObjectOptions({
                    userArea: newValue,
                }),
            );
    };

    const areaWarning = useMemo(() => {
        if (
            userArea &&
            calculatedArea &&
            (Number(userArea) / calculatedArea > 2 || Number(userArea) / calculatedArea < 0.5)
        ) {
            return <AreaWarning>{t('Calculated and user areas are too different!')}</AreaWarning>;
        } else {
            return null;
        }
    }, [calculatedArea, t, userArea]);

    if (selectedObjects !== null) {
        return (
            <StyledStack tokens={StackTokens}>
                <TextField
                    underlined
                    label={t('Calculated area')}
                    value={`${calculatedArea.toFixed(2)}${t('m²')}`}
                    onChange={() => {}}
                />
                <TextField
                    type="number"
                    min={0.1}
                    step={0.1}
                    disabled={generalSettings.readOnly}
                    underlined
                    label={t('User area')}
                    value={String(userArea)}
                    onChange={onChangeUserArea}
                />
                {areaWarning}
            </StyledStack>
        );
    }

    return null;
};

export default ObjectOptions;
