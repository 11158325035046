export const convertLayers = (layer: { [x: string]: any }) => {
    if (layer.layer_type !== 'zones_layer') {
        return layer;
    } else {
        if (!layer.data) return layer;
        const data = layer.data.reduce(
            (
                acc: Array<{ front_id: string }>,
                group: {
                    group_marker: string;
                    front_id: string;
                    group_name: string;
                    zones: Array<{ front_id: string; zone_marker: string; zone_name: string }>;
                },
            ) => {
                const { group_marker, front_id, group_name } = group;
                const add = group.zones.map((zone) => {
                    return { ...zone, group_marker, group_front_id: front_id, group_name, marker: zone.zone_name };
                });
                return [...acc, ...add];
            },
            [],
        );
        return { ...layer, data };
    }
};
