import styled from 'styled-components/macro';
import eyeIcon from './icons/eye.svg';

export const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const EyeIcon = styled.div<{ eyeVisible: boolean }>`
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    background: ${(p) => (p.eyeVisible ? `url(${eyeIcon}) no-repeat center` : 'none')};
    background-size: 98%;
    border: ${(p) => (p.eyeVisible ? '1px solid transparent' : '1px solid rgba(0, 0, 0, 0.3)')};
    margin-right: 5px;
`;
