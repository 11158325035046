import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';

import { Line, Group, Circle, Arrow } from 'react-konva';
import { useTranslation } from 'react-i18next';
import { reducerValues, toggleContextMenu } from '../../../../reducer';
import { IIPointProps } from './interfaces';
import { commonTools, ObjectLabel } from '../../../../../../monitoringLayers';
import { IPoint } from '../../../../../../interfaces';
import { ContextualMenu } from '@fluentui/react';
import { theme } from '../../../../../../../../constants/theme';

/**
 * Компонент объект сенсора подсчета посетителей
 */
const PcIPoint: React.FC<IIPointProps> = ({
    centerPoint,
    planScale = 1,
    stageScale,
    onClick,
    selected,
    activeToolId,
}) => {
    const dispatch = useDispatch();
    const { showLabels, pcIpoints, selectedPassPointId } = useSelector(reducerValues);
    const { t } = useTranslation();

    const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);

    // const anchors = useMemo(() => {
    //     if (!selected) return null;
    //     const draggable = activeToolId === 'anchorsEdit';
    //     return Object.keys(passPoints).map((key: string) => {
    //         const isPassPointSelected = key === selectedPassPointId;
    //         const fill = isPassPointSelected ? 'rgba(0,255,0,0.5)' : 'transparent';
    //         const point = passPoints[key as keyof typeof passPoints].geometry;
    //         return (
    //             <Circle
    //                 key={`PCCAnchor--__${key}`}
    //                 draggable={false}
    //                 onContextMenu={onRightClick(key)}
    //                 x={point.coordinates[0]}
    //                 y={point.coordinates[1]}
    //                 radius={foo(10)}
    //                 fill={fill}
    //                 stroke={'red'}
    //                 strokeWidth={foo(2)}
    //             />
    //         );
    //     });
    // }, [selected, activeToolId, passPoints, stageScale]);

    // const slaveLeash = useMemo(() => {
    //     if (followedBy === null) {
    //         return null;
    //     } else {
    //         const masterCoords = pcIpoints?.filter((item) => item.marker === followedBy)[0]?.centerPoint?.coordinates;
    //         return masterCoords ? (
    //             <Line
    //                 points={[centerPoint.coordinates[0], centerPoint.coordinates[1], masterCoords[0], masterCoords[1]]}
    //                 stroke="#777"
    //                 strokeWidth={foo(2)}
    //                 dash={[5, 5]}
    //             />
    //         ) : null;
    //     }
    // }, [centerPoint.coordinates, pcIpoints, followedBy]);

    const centralCircle = useMemo(() => {
        const centralFill = theme.palette.white;
        const radius = foo(20);

        return centerPoint ? (
            <>
                <Circle
                    x={centerPoint.coordinates[0]}
                    y={centerPoint.coordinates[1]}
                    radius={radius}
                    fill={centralFill}
                    stroke={'black'}
                    strokeWidth={foo(2)}
                    // onDragMove={onCentralDragMove}
                    // draggable={selected}
                />

                {/* <Circle
                    x={centerPoint.coordinates[0] + foo(5)}
                    y={centerPoint.coordinates[1]}
                    radius={foo(3)}
                    fill={'white'}
                    onClick={onObjectClick}
                />
                <Circle
                    x={centerPoint.coordinates[0] - foo(5)}
                    y={centerPoint.coordinates[1]}
                    radius={foo(3)}
                    fill={'white'}
                    onClick={onObjectClick}
                /> */}
                {/* {selected && (
                    <Circle
                        x={centerPoint.coordinates[0]}
                        y={centerPoint.coordinates[1]}
                        radius={foo(20)}
                        stroke={'red'}
                        strokeWidth={foo(3)}
                        draggable={false}
                        fill="transparent"
                    />
                )} */}
                {/* {showLabels && (
                    <ObjectLabel
                        stageScale={stageScale}
                        x={centerPoint.coordinates[0]}
                        y={centerPoint.coordinates[1]}
                        marker={'YYYY'}
                        selected={selected}
                    />
                )} */}
            </>
        ) : null;
    }, [selected, centerPoint, planScale, stageScale, activeToolId, showLabels]);

    return <>{centralCircle}</>;
};

export default PcIPoint;
