import React, { useMemo } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { reducerValues } from '../../reducer';
import { ICrossObject } from '../../interfaces';
import { commonTools } from '../../../../../Layers/layers';
import { Layer, Star } from 'react-konva';
import { TupleLabel } from '../../../../../../tools/commonComponents';

const zeroLabel = { show: false, text: '', x: 0, y: 0 };

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const { tupleAlias, intersections } = useSelector(reducerValues);
    const [label, setLabel] = useState(zeroLabel);
    const { stageScale } = props.scale;

    const onMouseEnter = (obj: ICrossObject) => () => {
        const x = obj.coords[0];
        const y = obj.coords[1];
        const text = `escalator_marker: ${obj.info.escalatorMarker}\nescalator_name: ${obj.info.escalatorName}\nescalator_passpoint: ${obj.info.escalatorPassPoint}\nzone_marker: ${obj.info.zoneMarker}\nzone_name: ${obj.info.zoneName}\nzone_group_marker: ${obj.info.groupMarker}\nzone_group_name: ${obj.info.groupName}`;
        setLabel({ show: true, text, x, y });
    };
    const onMouseLeave = () => {
        setLabel({ ...zeroLabel });
    };

    const geometry = useMemo(() => {
        const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
        return intersections.map((obj, i) => {
            return (
                <Star
                    key={`))stt9fgharr${i}))`}
                    x={obj.coords[0]}
                    y={obj.coords[1]}
                    numPoints={8}
                    innerRadius={foo(5)}
                    outerRadius={foo(10)}
                    fill={'yellow'}
                    onMouseEnter={onMouseEnter(obj)}
                    onMouseLeave={onMouseLeave}
                />
            );
        });
    }, [intersections, stageScale]);

    try {
        return (
            <Layer key={tupleAlias}>
                {geometry}
                {label.show && <TupleLabel x={label.x} y={label.y} text={label.text} />}
            </Layer>
        );
    } catch (error) {
        return null;
    }
};

export default Draw;
