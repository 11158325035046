import {
    IRequestStructuresDAResponse,
    IRequestStructuresQueryFNResponse,
    IStructures,
    TStructureElement,
} from './interfaces';

interface IArgs {
    response: IRequestStructuresQueryFNResponse;
}

const dataAdapter = (args: IArgs): IRequestStructuresDAResponse => {
    const structures = Object.entries(args.response).reduce(
        (acc: { [x: string]: { [x: string]: TStructureElement[] } }, [key, value]: [string, TStructureElement[]]) => {
            const splittedKey = key.split('/');
            const branch = splittedKey[0];
            const tableName = splittedKey[1];

            acc[branch] = {
                ...(acc[branch] || {}),
                [tableName]: value,
            };
            return acc;
        },
        {},
    ) as unknown as IStructures;

    return {
        structures,
    };
};

export default dataAdapter;
