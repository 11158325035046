import React, { useState, useContext, useEffect } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

import { useTranslation } from 'react-i18next';

import { MainWrapper, columnProps, footerProps, Footer } from './styles';
import { commonTools } from '../../../../../Layers/layers';
import { Dropdown } from '@fluentui/react';
import { TAccountWithProduct } from '../../Accounts.interfaces';
import { useCopyProductsAndLocations } from './useCopyProductsAndLocations';
import { StatusTable } from './StatusTable';
import { useCopyAppParams } from './useCopyAppParams';
import { use } from 'i18next';
import { useAppDispatch } from '../../../../../../store';
import { fetchUsers } from '../../Accounts.reducer';
import { useParams } from 'react-router-dom';

const CopyModal = ({
    onOKClick,
    closeModal,
    list,
}: {
    onOKClick: (pass: string, email: string) => void;
    closeModal: () => void;
    list: TAccountWithProduct[];
}) => {
    const { projectId } = useParams<{ projectId: string }>();

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [donorId, setDonorId] = useState('');
    const [acceptorId, setAcceptorId] = useState('');
    const { copyProductsAndLocations, requestStatuses } = useCopyProductsAndLocations();
    const { handleCopyAppParams, requestStatuses: appParamsStatuses } = useCopyAppParams();

    useEffect(() => {
        const statuses = [...requestStatuses, ...appParamsStatuses];
        console.log(statuses);

        if (statuses.length !== 0 && statuses.every((status) => status.status === 'success')) {
            projectId && dispatch(fetchUsers(projectId));
            // closeModal();
        }
    }, [requestStatuses, appParamsStatuses, projectId]);

    const onCopyClick = async () => {
        if (!donorId || !acceptorId) {
            return;
        }

        copyProductsAndLocations({ donorId, acceptorId, list });
        handleCopyAppParams(donorId, acceptorId);
    };

    const donorOptions = list.map((item) => {
        return { key: item.id, text: item.username };
    });

    const acceptorOptions = React.useMemo(() => {
        return list
            .map((item) => {
                return { key: item.id, text: item.username };
            })
            .filter((item) => item.key.toString() !== donorId.toString());
    }, [list, donorId]);

    const onDonorChange = (event: React.FormEvent<HTMLDivElement>, option?: { key: string | number }) => {
        setDonorId(option?.key as string);
        setAcceptorId('');
    };

    const onAcceptorChange = (event: React.FormEvent<HTMLDivElement>, option?: { key: string | number }) => {
        setAcceptorId(option?.key as string);
    };

    const buttonDisabled = donorId === '' || acceptorId === '';

    return (
        <MainWrapper>
            <Stack {...columnProps}>
                <Dropdown
                    label={t('Donor account')}
                    placeholder={t('Donor account')}
                    disabled={false}
                    onChange={onDonorChange}
                    selectedKey={donorId}
                    options={donorOptions}
                />
                <Dropdown
                    label={t('Acceptor account')}
                    placeholder={t('Acceptor account')}
                    disabled={donorId === ''}
                    onChange={onAcceptorChange}
                    selectedKey={acceptorId}
                    options={acceptorOptions}
                />
            </Stack>
            <StatusTable requestStatuses={[...requestStatuses, ...appParamsStatuses]} />

            <Footer>
                {/* <DefaultButton
                    text={t('Relaunch to update')}
                    disabled={requestStatuses.length === 0}
                    onClick={() => window.location.reload()}
                    allowDisabledFocus
                /> */}
                <DefaultButton text={t('Close')} onClick={closeModal} allowDisabledFocus />
                <PrimaryButton text={t('Copy')} onClick={onCopyClick} allowDisabledFocus disabled={buttonDisabled} />
            </Footer>
        </MainWrapper>
    );
};

export default CopyModal;
