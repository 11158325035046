import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';

import { Line, Group, Circle, Arrow } from 'react-konva';
import { useTranslation } from 'react-i18next';
import {
    moveAnchor,
    moveCenter,
    PCCReducerValues,
    deletePassPoint,
    selectPassPoint,
    toggleContextMenu,
} from '../../../../reducer';
import { IPCCObjectProps } from './interfaces';
import { commonTools, ObjectLabel } from '../../../../../../layers';
import { theme } from '../../../../../../../../constants/theme';

/**
 * Компонент объект сенсора подсчета посетителей
 */
const PC_IPoint: React.FC<IPCCObjectProps> = ({
    centerPoint,
    passPoints,
    followedBy,
    planScale = 1,
    stageScale,
    onClick,
    selected,
    marker = '',
    frontId = '',
    activeToolId,
    onMouseDown,
}) => {
    const dispatch = useDispatch();
    const { showLabels, objects, selectedPassPointId } = useSelector(PCCReducerValues);
    const { t } = useTranslation();
    const onObjectClick = () => {
        onClick(frontId);
    };

    const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);

    const onAnchorDragMove = (passPointId: string) => (e: KonvaEventObject<MouseEvent>) => {
        if (activeToolId !== 'anchorsEdit') return;
        dispatch(selectPassPoint({ passPointId }));
        const { x, y } = e.target.attrs;
        const newCoords = [x, y];
        newCoords && dispatch(moveAnchor({ passPointId, frontId, newCoords }));
    };

    const onRightClick = (passPointId: string) => (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        const { x, y } = e.evt;
        const menuItems = [
            {
                key: 'delete_passpoint',
                text: t('Delete passpoint'),
                onClick: () => {
                    dispatch(deletePassPoint({ passPointId, frontId }));
                    dispatch(toggleContextMenu({ show: false }));
                },
            },
        ];
        dispatch(toggleContextMenu({ show: true, menuItems, cursor: { x, y } }));
    };

    const onCentralDragMove = (e: KonvaEventObject<MouseEvent>) => {
        if (activeToolId !== 'anchorsEdit') return;
        const { x, y } = e.target.attrs;
        const newCoords = [x, y];
        newCoords && dispatch(moveCenter({ frontId, newCoords }));
    };

    const onSelectPassPoint = (passPointId: string) => () => {
        activeToolId === 'anchorsEdit' && dispatch(selectPassPoint({ passPointId }));
    };

    const anchors = useMemo(() => {
        if (!selected) return null;
        const draggable = activeToolId === 'anchorsEdit';
        return Object.keys(passPoints).map((key: string) => {
            const isPassPointSelected = key === selectedPassPointId;
            const fill = isPassPointSelected ? 'rgba(0,255,0,0.5)' : 'transparent';
            const point = passPoints[key as keyof typeof passPoints];

            return (
                <React.Fragment key={`PCCAnchor--__${key}`}>
                    <Circle
                        draggable={draggable}
                        onClick={onSelectPassPoint(key)}
                        onDragMove={onAnchorDragMove(key)}
                        onContextMenu={onRightClick(key)}
                        x={point.geometry.coordinates[0]}
                        y={point.geometry.coordinates[1]}
                        radius={foo(10)}
                        fill={fill}
                        stroke={'red'}
                        strokeWidth={foo(2)}
                    />

                    {showLabels && (
                        <ObjectLabel
                            stageScale={stageScale}
                            x={point.geometry.coordinates[0]}
                            y={point.geometry.coordinates[1]}
                            marker={point.lineName}
                            selected={isPassPointSelected}
                        />
                    )}
                </React.Fragment>
            );
        });
    }, [selected, activeToolId, passPoints, stageScale]);

    const endPassPoints = useMemo(() => {
        return Object.keys(passPoints).map((key) => {
            const point = passPoints[key].geometry;
            return (
                <React.Fragment key={key}>
                    <Line
                        points={[
                            centerPoint.coordinates[0],
                            centerPoint.coordinates[1],
                            point.coordinates[0],
                            point.coordinates[1],
                        ]}
                        stroke="black"
                        strokeWidth={foo(2)}
                    />
                    <Circle x={point.coordinates[0]} y={point.coordinates[1]} radius={foo(3)} fill={'black'} />
                </React.Fragment>
            );
        });
    }, [selected, activeToolId, passPoints, stageScale]);

    const _onMouseDown = (e: any) => {
        onMouseDown(frontId, e);
    };

    const slaveLeash = useMemo(() => {
        if (followedBy === null) {
            return null;
        } else {
            const masterCoords = objects?.filter((item) => item.marker === followedBy)[0]?.centerPoint?.coordinates;
            return masterCoords ? (
                <Line
                    points={[centerPoint.coordinates[0], centerPoint.coordinates[1], masterCoords[0], masterCoords[1]]}
                    stroke="#777"
                    strokeWidth={foo(2)}
                    dash={[5, 5]}
                    onMouseDown={_onMouseDown}
                />
            ) : null;
        }
    }, [centerPoint.coordinates, objects, followedBy, _onMouseDown]);

    

    const centralCircle = useMemo(() => {
        const centralFill = followedBy !== null ? theme.palette.blueLight : theme.palette.orangeLighter;
        return (
            <>
                <Circle
                    x={centerPoint.coordinates[0]}
                    y={centerPoint.coordinates[1]}
                    radius={foo(10)}
                    fill={centralFill}
                    stroke={'black'}
                    strokeWidth={foo(2)}
                    onClick={onObjectClick}
                    onMouseDown={_onMouseDown}
                    // onDragMove={onCentralDragMove}
                    // draggable={selected}
                />

                <Circle
                    x={centerPoint.coordinates[0] + foo(5)}
                    y={centerPoint.coordinates[1]}
                    radius={foo(3)}
                    fill={'white'}
                    onClick={onObjectClick}
                    onMouseDown={_onMouseDown}
                    // stroke={'black'}
                    // strokeWidth={foo(1)}
                />
                <Circle
                    x={centerPoint.coordinates[0] - foo(5)}
                    y={centerPoint.coordinates[1]}
                    radius={foo(3)}
                    fill={'white'}
                    onClick={onObjectClick}
                    onMouseDown={_onMouseDown}
                    // stroke={'black'}
                    // strokeWidth={foo(1)}
                />
                {selected && (
                    <Circle
                        x={centerPoint.coordinates[0]}
                        y={centerPoint.coordinates[1]}
                        radius={foo(15)}
                        stroke={'red'}
                        strokeWidth={foo(2)}
                        draggable={activeToolId === 'anchorsEdit'}
                        fill="transparent"
                        onDragMove={onCentralDragMove}
                    />
                )}
                {showLabels && (
                    <ObjectLabel
                        stageScale={stageScale}
                        x={centerPoint.coordinates[0]}
                        y={centerPoint.coordinates[1]}
                        marker={marker}
                        selected={selected}
                    />
                )}
            </>
        );
    }, [
        selected, 
        onObjectClick, 
        centerPoint, 
        planScale, 
        stageScale, 
        activeToolId, 
        followedBy, 
        showLabels , 
        _onMouseDown
    ]);

    

    return (
        <>
            <Group
             key={`group == PCC${frontId}`}
             
             >{endPassPoints}</Group>
            {slaveLeash}
            {anchors}
            {centralCircle}
        </>
    );
};

export default PC_IPoint;
