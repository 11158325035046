import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../../store';
import { postRequest, getLocalBase64Image, getRequest, patchRequest, deleteRequest } from '../../../../tools/api';
import { ITenantsReducerState, IPlace, ITenant, IPlacesByMarker, ITenant2Place } from './Tenants.interfaces';
import axios from 'axios';
import { commonTools } from '../../../../tools/commonTools';
import { DateTime } from 'luxon';
import { IInitialLayers } from '../../Editor.interfaces';
import { resetSlectedObject } from '../../Editor.reducer';
import items from '../../../Layers/components/TracksTraking/components/Controls/components/ObjectOptions/data/data';
import { IVersion } from '../../../Versions/Versions.interfaces';

const initialState: ITenantsReducerState = {
    places: [],
    tenants: [],
    tenant2place: [],
    selectedPlace: null,
    selectedTenant: null,
    placesByMarker: {},
    reloadData: 0,
};

export const Tenants = createSlice({
    name: 'Tenants',
    initialState,
    reducers: {
        /**
         * Запись полученных помещений и арендаторов и связок.
         */
        storePlacesTenants: (
            state,
            action: PayloadAction<{
                placesData: Array<IPlace>;
                tenantsData: Array<ITenant>;
                tenant2placeData: Array<ITenant2Place>;
                allInitialLayers: IInitialLayers;
                activeVersion: IVersion | null;
            }>,
        ) => {
            const { placesData, tenantsData, tenant2placeData, allInitialLayers, activeVersion } = action.payload;

            const placesLayers = allInitialLayers.filter((layer) => layer.layerType === 'places_layer');
            const placesLayersByMarker: { [x: string]: { floor: number; front_id: string } } = {};
            placesLayers.forEach((floor) => {
                floor.data &&
                    floor.data.forEach((place: { marker: string; front_id: string }) => {
                        placesLayersByMarker[place.marker] = { floor: floor.floor, front_id: place.front_id };
                    });
            });
            const placesByMarker: IPlacesByMarker = {};

            placesData.forEach((place) => {
                const { marker } = place;
                const active = placesLayersByMarker.hasOwnProperty(marker);

                let hasTenants = false;
                if (tenant2placeData.some((item) => item.place_id === place.id)) {
                    hasTenants = true;
                }
                let hasTrouble = false;
                if (
                    tenant2placeData.some((item) => {
                        return (
                            !active &&
                            hasTenants &&
                            activeVersion &&
                            (item.date_to === null ||
                                DateTime.fromISO(item.date_to).toMillis() >=
                                    DateTime.fromISO(activeVersion?.date_from).toMillis())
                        );
                    })
                ) {
                    hasTrouble = true;
                }

                const floor = active ? placesLayersByMarker[marker].floor : '';
                const front_id = active ? placesLayersByMarker[marker].front_id : '';
                placesByMarker[marker] = { ...place, active, floor, front_id, hasTenants, hasTrouble };
            });
            const expandedTenant2placeData = tenant2placeData.map((item) => {
                const tenant = tenantsData.filter((ten) => ten.id === item.tenant_id)[0];
                const place = placesData.filter((plc) => plc.id === item.place_id)[0];
                const tenant_object_name = tenant.object_name;
                const place_marker = place.marker;
                const floor = placesByMarker[place_marker].floor;
                const place_object_name = place.object_name;
                return { ...item, tenant_object_name, place_marker, place_object_name, floor };
            });

            state.placesByMarker = placesByMarker;
            state.places = placesData;
            state.tenants = tenantsData;
            state.tenant2place = expandedTenant2placeData;
        },

        /**
         * Запись Выбранного помещения.
         */
        storeSelectedPlace: (state, action: PayloadAction<number | null>) => {
            const { placesByMarker } = cloneDeep(state);
            const backId = action.payload;

            if (backId === null) {
                state.selectedPlace = null;
            } else {
                state.selectedTenant = null;
                const selectedPlace = Object.values(placesByMarker).filter((value) => value.id === backId)[0];
                state.selectedPlace = selectedPlace;
            }
        },

        /**
         * Запись Выбранного арендвтора.
         */
        selectTenant: (state, action: PayloadAction<ITenant>) => {
            state.selectedPlace = null;
            const { selectedTenant } = state;
            if (action.payload.id === selectedTenant?.id) {
                state.selectedTenant = null;
            } else {
                state.selectedTenant = action.payload;
            }
        },

        /**
         * сброс редьюсера.
         */
        resetReducer: (state) => {
            const { reloadData } = cloneDeep(state);
            state.places = [];
            state.tenants = [];
            state.tenant2place = [];
            state.selectedPlace = null;
            state.selectedTenant = null;
            state.placesByMarker = {};
            state.reloadData = reloadData + 1;
        },
    },
});

export const { storePlacesTenants, storeSelectedPlace, selectTenant, resetReducer } = Tenants.actions;

const reset = (): AppThunk => (dispatch, getState) => {
    dispatch(resetSlectedObject());
    dispatch(resetReducer());
};

export const fetchPlacesTenants =
    (args: { plId: string }): AppThunk =>
    async (dispatch, getState) => {
        // dispatch(resetReducer());
        const {
            token,
            urls: { PLACES_URL, TENANTS_URL, TENANT_2_PLACE_URL },
        } = cloneDeep(getState().GeneralReducer);
        const { allInitialLayers } = cloneDeep(getState().EditorReducer);
        const { activeVersionId, versionsById } = cloneDeep(getState().VersionsReducer);
        const activeVersion: IVersion | null = versionsById?.[activeVersionId || 0] || null;

        const placesRequest = getRequest({
            url: `${PLACES_URL}?project_location_id=${args.plId}`,
            dispatch,
            allowSpinner: true,
            token,
        });

        const tenantsRequest = getRequest({
            url: `${TENANTS_URL}?project_location_id=${args.plId}`,
            dispatch,
            allowSpinner: true,
            token,
        });

        const tenants2placesRequest = getRequest({
            url: `${TENANT_2_PLACE_URL}?project_location_id=${args.plId}`,
            dispatch,
            allowSpinner: true,
            token,
        });

        const [placesData = [], tenantsData = [], tenant2placeData = []] = await Promise.all([
            placesRequest,
            tenantsRequest,
            tenants2placesRequest,
        ]);

        dispatch(
            storePlacesTenants({
                placesData,
                tenantsData,
                tenant2placeData,
                allInitialLayers,
                activeVersion,
            }),
        );
    };

export const createNewTenant =
    (args: {
        project_location_id: string;
        object_name: string;
        multi_place: boolean;
        marker?: string;
        is_active: boolean;
    }): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { TENANTS_URL },
        } = cloneDeep(getState().GeneralReducer);
        const data = { ...args };
        postRequest({ url: TENANTS_URL, dispatch, allowSpinner: true, token, data }).then(() => {
            dispatch(reset());
            dispatch(fetchPlacesTenants({ plId: args.project_location_id }));
        });
    };

export const postNewRelation =
    (args: { tenant_id: number; place_id: number; date_from: string; date_to: string | null }): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { TENANT_2_PLACE_URL },
        } = cloneDeep(getState().GeneralReducer);
        const { activeProjectLocation } = cloneDeep(getState().LocationsReducer);

        const data = { ...args };
        postRequest({
            url: `${TENANT_2_PLACE_URL}?tenant_id=${args.tenant_id}&place_id=${args.place_id}`,
            dispatch,
            allowSpinner: true,
            token,
            data,
        }).then(() => {
            dispatch(reset());
            activeProjectLocation?.id && dispatch(fetchPlacesTenants({ plId: String(activeProjectLocation?.id) }));
        });
    };

export const changeTenant =
    (tenant: ITenant): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { TENANTS_URL },
        } = cloneDeep(getState().GeneralReducer);
        const { activeProjectLocation } = cloneDeep(getState().LocationsReducer);

        patchRequest({
            url: `${TENANTS_URL}${tenant.id}/`,
            dispatch,
            allowSpinner: true,
            token,
            data: tenant,
        }).then(() => {
            dispatch(reset());
            activeProjectLocation?.id && dispatch(fetchPlacesTenants({ plId: String(activeProjectLocation?.id) }));
        });
    };

export const changeRelation =
    (data: ITenant2Place): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { TENANT_2_PLACE_URL },
        } = cloneDeep(getState().GeneralReducer);
        const { activeProjectLocation } = cloneDeep(getState().LocationsReducer);
        patchRequest({ url: `${TENANT_2_PLACE_URL}${data.id}/`, dispatch, allowSpinner: true, token, data }).then(
            () => {
                dispatch(reset());
                activeProjectLocation?.id && dispatch(fetchPlacesTenants({ plId: String(activeProjectLocation?.id) }));
            },
        );
    };

export const deleteRelation =
    (id: number): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { TENANT_2_PLACE_URL },
        } = cloneDeep(getState().GeneralReducer);
        const { activeProjectLocation } = cloneDeep(getState().LocationsReducer);
        deleteRequest({ url: `${TENANT_2_PLACE_URL}${id}/`, dispatch, allowSpinner: true, token }).then(() => {
            dispatch(reset());
            activeProjectLocation?.id && dispatch(fetchPlacesTenants({ plId: String(activeProjectLocation?.id) }));
        });
    };

export const tenantsReducerValues = (state: RootState) => state.TenantsReducer;

export default Tenants.reducer;
