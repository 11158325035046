
import {
	IObjects
} from '../../../tracksWifi.interfaces';
import {IItemCoords} from "../../../tracksWifi.interfaces";


/**
 * Форматирование координат
 * @param objects
 * @param tempObjects
 */
export const formattingCoords = (objects: IObjects, tempObjects: number[][]) => {
	let objArrayTempCoords: IItemCoords[] = [];
	let objsArrayTempCoords: IItemCoords[][] = [];
	tempObjects.forEach((itemTempCoords, indexTempCoords) => {
		objArrayTempCoords.push({
			x: itemTempCoords[0],
			y: itemTempCoords[1]
		})
	})
	objects.forEach((item, index) => {
		let objArrayItem: any[] = [];
		item.points.coordinates.forEach((itemTempCoords2, indexTempCoords) => {
			objArrayItem.push({
				x: itemTempCoords2[0],
				y: itemTempCoords2[1]
			})
		})
		objsArrayTempCoords.push(objArrayItem)

	})

	return {objects: objsArrayTempCoords, object: objArrayTempCoords};
}