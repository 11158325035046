import { IPassWay } from '../../../Layers/components/PassWays/passWays.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ICrossObject, IReducer } from './interfaces';
import { IPlace } from '../../../Layers/components/Places/places.interfaces';
const passways_places = require('../../../../lambda/passways_places');

export const initialState: IReducer = {
    tupleAlias: 'passways_places_tuple',
    intersections: [],
};

export const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        storeIntersections: (
            state,
            action: PayloadAction<{ placesObjects: Array<IPlace>; passWaysObjects: Array<IPassWay>; planScale: number }>
        ) => {
            const { placesObjects, passWaysObjects, planScale } = action.payload;
            const intersections = passways_places.get_intersections({ placesObjects, passWaysObjects, planScale });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
