import {
    DatePicker,
    Dropdown,
    FontIcon,
    IDropdownOption,
    Label,
    PrimaryButton,
    Stack,
    TextField,
} from '@fluentui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { tenantsReducerValues, postNewRelation, changeRelation, deleteRelation } from '../../Tenants.reducer';
import { settingsReducerValues } from '../../../../../Settings/Settings.reducer';

import { buttonStyles, ButtonsWrapper, dropdownStyles, DropdownWrapper, Header, ItemWrapper, Wrapper } from './styles';
import useDayPickerStrings from '../../../../../../constants/useDayPickerStrings';
import { DateTime, Interval } from 'luxon';
import { theme } from '../../../../../../constants/theme';
import { ITenant2Place } from '../../Tenants.interfaces';
import { commonTools } from '../../../../../../tools/commonTools';
import SearchableDropdown from '../../../../../SearchableDropdown/SearchableDropdown';
import { useAppDispatch } from '../../../../../../store';

const Tenant2PlaceItem: React.FC<{ item?: ITenant2Place; mode: string; placeActiveIntervals: Interval[] }> = ({
    item = undefined,
    mode = 'old',
    placeActiveIntervals,
}) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const DAY_PICKER_STRINGS = useDayPickerStrings();

    const { placesByMarker, selectedPlace, tenants, tenant2place } = useSelector(tenantsReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);

    const [fieldsDisabled, setFieldsDisabled] = React.useState(mode === 'old' ? true : false);
    const [dateFrom, setDateFrom] = React.useState<Date>(new Date());
    const [dateTo, setDateTo] = React.useState<Date | null>(null);
    const [cancelCounter, setCancelCounter] = React.useState(0);
    const [selectedTenant, setSelectedTenant] = React.useState<{ key: number | string; text: string } | undefined>(
        item?.tenant_id
            ? {
                  key: item.tenant_id,
                  text: item.tenant_object_name,
              }
            : undefined,
    );

    useEffect(() => {
        if (item) {
            const date_to = item.date_to ? new Date(item.date_to) : null;
            setDateFrom(new Date(item.date_from));
            setDateTo(date_to);
            setSelectedTenant({
                key: item.tenant_id,
                text: item.tenant_object_name,
            });
        }
    }, [item, cancelCounter]);

    // Проверяем что выбранные даты лежат в пределах интервалов существования помещения
    const mergedIntervals = Interval.merge(placeActiveIntervals);
    const isPlaceDatesValid = mergedIntervals.some((interval) => {
        return (
            interval.contains(DateTime.fromJSDate(dateFrom)) &&
            ((dateTo && interval.contains(DateTime.fromJSDate(dateTo))) ||
                (interval.end && interval.end > DateTime.now()))
        );
    });

    const onParseDateFromString = React.useCallback(
        (mode: string) =>
            (newValue: string): Date => {
                return mode === 'date_from'
                    ? commonTools.getDateFromString({ value: dateFrom, newValue })
                    : commonTools.getDateFromString({ value: dateTo, newValue });
            },
        [dateFrom, dateTo],
    );

    const dateToPlaceHolder = useMemo(() => {
        return !dateTo || dateTo.getFullYear() > 2100 ? 'Now' : dateTo.toDateString();
    }, [dateTo]);

    const onChange = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedTenant(item);
    };

    const tenantOptions = useMemo(() => {
        const tenantsHasPlaceIds = tenant2place
            .filter(
                (item) =>
                    item.date_to === null || DateTime.fromISO(item.date_to).toMillis() > DateTime.now().toMillis(),
            )
            .map((item) => item.tenant_id);

        return tenants
            .filter((item) => {
                const hasThePlaceThisTenant = tenant2place
                    .filter((t2p) => t2p.tenant_id === item.id)
                    .some((t2p) => t2p.place_id === selectedPlace?.id);
                if (tenantsHasPlaceIds.includes(item.id) && mode === 'old') {
                    return true;
                } else if (tenantsHasPlaceIds.includes(item.id) && mode === 'new' && !item.multi_place) {
                    if (hasThePlaceThisTenant) {
                        return true;
                    } else {
                        return false;
                    }
                } else if (!tenantsHasPlaceIds.includes(item.id)) {
                    return true;
                } else {
                    return true;
                }
            })
            .map((item) => ({ key: item.id, text: `${item.id} / ${item.object_name}` }));
    }, [tenants, tenant2place]);

    const handleAddClick = () => {
        if (selectedTenant?.key && selectedPlace?.id && dateFrom && mode === 'new') {
            dispatch(
                postNewRelation({
                    tenant_id: Number(selectedTenant?.key),
                    place_id: selectedPlace?.id,
                    date_from: DateTime.fromJSDate(dateFrom).toISODate() || '',
                    date_to: dateTo ? DateTime.fromJSDate(dateTo).toISODate() : null,
                }),
            );
        } else if (mode === 'old') {
            setFieldsDisabled(() => false);
        }
    };

    const handleCancelClick = () => {
        setFieldsDisabled(() => true);
        setCancelCounter((cancelCounter) => cancelCounter + 1);
    };

    const handleChangeClick =
        (dateTo: Date | null, dateFrom: Date, selectedTenant: { key: number | string; text: string } | undefined) =>
        () => {
            if (item) {
                const data = {
                    ...item,
                    date_from: DateTime.fromJSDate(dateFrom).toISODate() ?? '',
                    date_to: dateTo ? DateTime.fromJSDate(dateTo).toISODate() : null,
                    tenant_id: Number(selectedTenant?.key),
                };

                dispatch(changeRelation(data));
            }
        };

    const handleDeleteClick = () => {
        if (item?.id) {
            dispatch(deleteRelation(item.id));
        }
    };

    const buttons = useMemo(() => {
        if (mode === 'new') {
            return (
                <PrimaryButton
                    text={t('Add')}
                    onClick={handleAddClick}
                    allowDisabledFocus
                    disabled={!selectedTenant || !isPlaceDatesValid}
                    // styles={buttonStyles}
                />
            );
        } else if (mode === 'old') {
            if (fieldsDisabled) {
                return (
                    <ButtonsWrapper>
                        <PrimaryButton
                            text={t('Edit')}
                            onClick={handleAddClick}
                            allowDisabledFocus
                            disabled={!selectedTenant || readOnly}
                        />
                        <PrimaryButton
                            text={t('Delete')}
                            onClick={handleDeleteClick}
                            allowDisabledFocus
                            disabled={!selectedTenant || readOnly}
                            styles={{
                                root: { backgroundColor: theme.palette.red },
                                rootHovered: { backgroundColor: theme.palette.redDark },
                            }}
                        />
                    </ButtonsWrapper>
                );
            } else if (!fieldsDisabled) {
                return (
                    <ButtonsWrapper>
                        <PrimaryButton
                            text={t('Cancel')}
                            onClick={handleCancelClick}
                            allowDisabledFocus
                            disabled={!selectedTenant}
                            styles={{
                                root: { backgroundColor: theme.palette.red },
                                rootHovered: { backgroundColor: theme.palette.redDark },
                            }}
                        />
                        <PrimaryButton
                            text={t('Save')}
                            onClick={handleChangeClick(dateTo, dateFrom, selectedTenant)}
                            allowDisabledFocus
                            disabled={!selectedTenant || !isPlaceDatesValid}
                            styles={{
                                root: { backgroundColor: theme.palette.green },
                                rootHovered: { backgroundColor: theme.palette.greenDark },
                            }}
                        />
                    </ButtonsWrapper>
                );
            }
        }
    }, [dateFrom, dateTo, fieldsDisabled, handleAddClick, handleDeleteClick, mode, selectedTenant, t]);

    const dropdown = useMemo(() => {
        // console.log('>>>>',tenantOptions);

        return (
            <SearchableDropdown
                disabled={fieldsDisabled}
                selectedKey={selectedTenant ? selectedTenant.key : undefined}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChange}
                placeholder={t('Select a tenant')}
                options={tenantOptions}
                styles={dropdownStyles}
            />
        );
    }, [fieldsDisabled, selectedTenant, t, tenantOptions]);

    return (
        <ItemWrapper>
            <DatePicker
                disabled={fieldsDisabled}
                // componentRef={datePickerRef}
                allowTextInput
                ariaLabel="Select a date. Input format is day slash month slash year."
                // placeholder={placeholder}
                value={dateFrom}
                onSelectDate={setDateFrom as (date?: Date | undefined | null) => void}
                formatDate={commonTools.onFormatDate}
                parseDateFromString={onParseDateFromString('date_from')}
                // className={styles.control}
                strings={DAY_PICKER_STRINGS}
            />
            <DatePicker
                disabled={fieldsDisabled}
                // componentRef={datePickerRef}
                allowTextInput
                ariaLabel="Select a date. Input format is day slash month slash year."
                placeholder={dateToPlaceHolder}
                value={dateTo || undefined}
                onSelectDate={setDateTo as (date?: Date | undefined | null) => void}
                formatDate={commonTools.onFormatDate}
                parseDateFromString={onParseDateFromString('date_to')}
                // className={styles.control}
                strings={DAY_PICKER_STRINGS}
            />
            {dropdown}
            {!isPlaceDatesValid && (
                <Label style={{ color: theme.palette.redDark, padding: 0 }}>
                    {t('There was no this place at that time!')}
                </Label>
            )}
            {buttons}
        </ItemWrapper>
    );
};

export default Tenant2PlaceItem;
