import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Checkbox, Stack } from '@fluentui/react';
import { reducerValues } from '../../../../../../reducer';
import { IRadioSensor } from '../../../../../../interfaces';
import { ItemWrapper, Wrapper } from './MultiSelect.styles';
import { IMultiSelectProps } from './MultiSelect.interfaces';

const MultiSelect = ({ sensor, onChange, disabled }: IMultiSelectProps) => {
    const dispatch = useDispatch();
    const { sensorTypesDict, generalSettings } = useSelector(reducerValues);
    const { t } = useTranslation();

    const onChangeTrans =
        (sensorType: string) => (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) => {
            if (!generalSettings.readOnly) {
                onChange(sensorType);
            }
        };

    return (
        <>
            {sensorTypesDict.map((item) => {
                const checked = sensor.sensor_types.includes(item);
                return (
                    <Wrapper key={`${sensor.front_id}-${item}`}>
                        <Checkbox disabled={disabled} checked={checked} label={item} onChange={onChangeTrans(item)} />
                    </Wrapper>
                );
            })}
        </>
    );
};

export default MultiSelect;
