import styled from 'styled-components/macro';
import React from 'react';
import { theme } from '../../../../../../../../constants/theme';
import { IListItem } from '../../interfaces';
import { IPlaceByMarker, ITenant } from '../../../../../Tenants/Tenants.interfaces';

const ListItem: React.FC<IListItem> = ({ place, tenant, selected, onClick }) => {
    const onSelectPlace = (place: IPlaceByMarker) => () => {
        // place?.active && onClick(String(id));
        onClick(place);
    };
    const onSelectTenant = (obj: ITenant) => () => {
        onClick(obj);
    };
    return place ? (
        <Container onClick={onSelectPlace(place)} opacity={place.active ? 1 : 0.3} hasTrouble={place.hasTrouble}>
            <Floor>{`${place.floor}`}</Floor>
            <Cell selected={selected}>{`${place.id} / ${place.marker} / ${place.object_name}`}</Cell>
        </Container>
    ) : tenant ? (
        <Container onClick={onSelectTenant(tenant)} opacity={tenant.is_active ? 1 : 0.3}>
            <Cell selected={selected}>{`${tenant.id} / ${tenant.object_name}`}</Cell>
        </Container>
    ) : null;
};

export default ListItem;

const Floor = styled.div`
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.palette.themePrimary};
    color: white;
    margin-right: 10px;
    flex-shrink: 0;
    width: 15px;
    height: 15px;
    font-weight: 700;
    font-size: 12px;
`;

const Container = styled.div<{ opacity: number; hasTrouble?: boolean }>`
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 5px;
    cursor: pointer;
    opacity: ${(p) => p.opacity};
    background-color: ${(p) => (p.hasTrouble ? 'red' : 'transparent')};
`;

const Cell = styled.div<{ selected: boolean }>`
    color: ${(p) => (p.selected ? theme.palette.themePrimary : 'black')};
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    user-select: none;
    display: flex;
    align-items: center;
`;
