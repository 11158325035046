import { IGeneralSettings, IVersionsData } from '../../layers.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from './store';
import { commonTools } from '../../layers';
import { IPlan, IPoint } from '../../layers.interfaces';

import { IPCCReducer, IPCC, IObjects } from './interfaces';
import { IContextMenu, IHotAddObject } from '../../layers.interfaces';
import { sizes } from '../../../../constants/sizes';


export const initialState: IPCCReducer = {
    activeToolId: null,
    selectedObjectId: null,
    selectedObjectMarker: null,
    generalSettings: {} as IGeneralSettings,
    selectedPassPointId: null,
    createdAt: undefined,
    versionsData: null,
    objects: null,
    currentPlanData: null,
    showLabels: false,
    layerAlias: 'pc_sensor_ipoints_layer',
    isNamesValid: { valid: true },
    anotherLocationNames: { markers: [] },
    contextMenu: { show: false },
    hotAddObject: null,
};

export const PCCReducer = createSlice({
    name: 'PCCReducer',
    initialState,
    reducers: {
        /**
         * Копирование объекта из буфера
         */
        pasteObjectFromClipboard: (state, action: PayloadAction<IPCC>) => {
            const { objects, versionsData, selectedObjectId } = cloneDeep(state);

            if (objects) {
                const frontId = `v${String(versionsData?.activeVersionId)}:passway:${commonTools.generateId()}`;
                const newObjects = objects.filter((item) => item.frontId !== selectedObjectId);
                const newObject = cloneDeep({ ...action.payload, frontId });
                commonTools.shiftCoords(newObject, [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT]);
                newObjects.push(newObject);
                state.objects = newObjects;
                state.selectedObjectId = frontId;
                state.selectedObjectMarker = action.payload.marker;
            }
        },

        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string | null>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Изменяет параметр Slave of
         */
        changeSlaveOf: (state, action: PayloadAction<{ id: string; key: string }>) => {
            const { id, key } = action.payload;
            let objects = cloneDeep(state.objects) || [];

            const newObjects = objects.map((item) => {
                if (item.frontId !== id) {
                    return item;
                } else {
                    if (key === 'null') {
                        return { ...item, followedBy: null };
                    } else {
                        return {
                            ...item,
                            followedBy: key,
                            // passPoints: {}
                        };
                    }
                }
            });
            state.objects = newObjects;
        },

        /**
         * Переключает контекстное меню
         */
        toggleContextMenu: (state, action: PayloadAction<IContextMenu>) => {
            const contextMenu = action.payload as IContextMenu;
            state.contextMenu = contextMenu;
        },

        /**
         * Сохранение нового объекта в сторе
         */
        storeNewObject: (state, action: PayloadAction<{ center: number[]; planScale: number | undefined }>) => {
            let objects = cloneDeep(state.objects);
            const anotherLocationNames = cloneDeep(state.anotherLocationNames);

            if (!objects) {
                objects = [];
            }
            const { center, planScale } = action.payload;
            if (!planScale) return;
            const start = [center[0], center[1] + 2 * planScale];
            const end = [center[0], center[1] - 2 * planScale];
            const currentMarkers = objects.map((item) => item.marker);

            const nextNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers, ...currentMarkers],
                'pc_ipoint',
            );

            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:pc_ipoint${nextNumber}`;
            const frontId = `v${String(activeVersionId)}:pc_ipoint:${commonTools.generateId()}`;

            const centerPoint: IPoint = {
                type: 'Point',
                coordinates: center,
            };
            const newObject: IPCC = {
                centerPoint,
                passPoints: {
                    [`passPoint:${commonTools.generateId()}`]: {
                        geometry: { type: 'Point', coordinates: end },
                        lineName: `Линия 0`,
                    },
                },
                followedBy: null,
                mountingHeight: null,
                marker,
                frontId,
            };
            objects.push(newObject as IPCC);
            state.objects = objects;
            state.selectedObjectId = frontId;
            state.selectedObjectMarker = marker;
        },

        /**
         * Добавление нового PassPoint
         */
        addPassPoint: (state, action: PayloadAction<{ id: string; planScale: number | undefined }>) => {
            const { id, planScale } = action.payload;
            if (!planScale) return;

            let objects = cloneDeep(state.objects) || [];

            const newObjects = objects.map((item) => {
                if (item.frontId !== id) {
                    return item;
                } else {
                    const { passPoints } = item;
                    const currentPassPointsNumber = Object.keys(passPoints).length;
                    const angle = Math.PI / 2 - (currentPassPointsNumber * Math.PI) / 6;
                    const center = item.centerPoint.coordinates;
                    const end = [
                        center[0] + 2 * planScale * Math.cos(angle),
                        center[1] - 2 * planScale * Math.sin(angle),
                    ];
                    const newId = `passPoint:${commonTools.generateId()}`;

                    const allLineNames = Object.keys(passPoints).map((key) => {
                        return passPoints[key].lineName;
                    });

                    passPoints[newId] = {
                        geometry: { type: 'Point', coordinates: end },
                        lineName: commonTools.getNextMarkerNumber(allLineNames, 'Линия '),
                    };

                    return { ...item, passPoints };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Удаление PassPoint
         */
        deletePassPoint: (state, action: PayloadAction<{ passPointId: string; frontId: string }>) => {
            const { passPointId, frontId } = action.payload;

            let objects = cloneDeep(state.objects) || [];

            const newObjects = objects.map((item) => {
                if (item.frontId !== frontId) {
                    return item;
                } else {
                    const { passPoints } = item;
                    delete passPoints[passPointId];
                    return { ...item, passPoints };
                }
            });
            state.objects = newObjects;
        },

        /**
         * выбор PassPoint
         */
        selectPassPoint: (state, action: PayloadAction<{ passPointId: string }>) => {
            const { passPointId } = action.payload;
            state.selectedPassPointId = passPointId;
        },

        /**
         * Выбор объекта
         */
        selectObject: (state, action: PayloadAction<string | undefined>) => {
            const objects = cloneDeep(state.objects);
            const selectedObjectMarker = objects?.find((item) => item.frontId === action.payload)?.marker;
            if (action.payload === undefined) {
                state.selectedObjectId = null;
                state.selectedObjectMarker = null;
            } else {
                state.selectedObjectId = action.payload;
                state.selectedObjectMarker = selectedObjectMarker || null;
            }
        },

        /**
         * Удаление объекта
         */
        deleteObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload !== undefined) {
                const objects = cloneDeep(state.objects);
                if (objects) {
                    state.selectedObjectId = null;
                    state.selectedObjectMarker = null;
                    state.objects = objects.filter((item) => item.frontId !== action.payload);
                }
            }
        },

        /**
         * Изменение параметров объекта
         */
        changeObjectParams: (
            state,
            action: PayloadAction<{ key: keyof IPCC; newValue: string | number | undefined | null; id: string }>,
        ) => {
            const { id, key, newValue } = action.payload;
            const objects = cloneDeep(state.objects) || [];
            const newObjects = objects.map((item) => {
                if (item.frontId !== id) {
                    return item;
                } else {
                    let value;
                    switch (key) {
                        default:
                            value = newValue;
                            break;
                    }
                    return { ...item, [key]: value };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Изменение Имени уса
         */
        renamePassPoint: (
            state,
            action: PayloadAction<{ passPointId: string; newValue: string | undefined; frontId: string }>,
        ) => {
            const { passPointId, frontId, newValue } = action.payload;
            const objects = cloneDeep(state.objects) || [];
            const newObjects = objects.map((item) => {
                if (item.frontId !== frontId || newValue === undefined) {
                    return item;
                } else {
                    const { passPoints } = item;
                    passPoints[passPointId] = {
                        ...passPoints[passPointId],
                        lineName: newValue,
                    };
                    return { ...item, passPoints };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IPCC>>) => {
            state.objects = action.payload;
        },

        /**
         * Запись в стор поля createdAt
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Изменение положения узла
         */
        moveAnchor: (
            state,
            action: PayloadAction<{ passPointId: string; frontId: string; newCoords: Array<number> }>,
        ) => {
            const { passPointId, frontId, newCoords } = action.payload;
            const temp = cloneDeep(state.objects) || [];
            const objects = temp.map((item) => {
                if (item.frontId !== frontId) {
                    return item;
                } else {
                    const { passPoints } = item;
                    const geometry = { type: 'Point', coordinates: newCoords };
                    passPoints[passPointId] = { ...passPoints[passPointId], geometry };
                    return { ...item, passPoints };
                }
            });
            state.objects = objects;
        },

        /**
         * Изменение положения узла
         */
        moveCenter: (state, action: PayloadAction<{ frontId: string; newCoords: Array<number> }>) => {
            const { frontId, newCoords } = action.payload;
            const temp = cloneDeep(state.objects) || [];
            const objects = temp.map((item) => {
                if (item.frontId !== frontId) {
                    return item;
                } else {
                    const centerPoint: IPoint = { type: 'Point', coordinates: newCoords };
                    return { ...item, centerPoint };
                }
            });
            state.objects = objects;
        },

        /**
         * Сохранение в стор всех имен из других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];

            action?.payload.forEach((item: { data?: any }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string }) => {
                    markers.push(obj.marker);
                });
            });
            const anotherLocationNames = { markers };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Валидация маркеров.
         */

        validateNames: (state) => {
            const { objects, anotherLocationNames } = cloneDeep(state);
            if (!objects) return;
            const currentMarkers = objects.map((item) => item.marker);
            const isNamesValid = commonTools.isNamesValid([...currentMarkers, ...anotherLocationNames.markers]);
            state.isNamesValid = isNamesValid;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        clearSelectedObjects: (state, action: PayloadAction) => {


            state.selectedObjectId = null;
            state.selectedObjectMarker = null;
            state.selectedPassPointId = null;
        },


        changeCoords: (
            state,
            action: PayloadAction<{
                coordsS: number[] | undefined;
                front_id: string | null;
                _coordsLine: { [x: string]: number[] };
            }>,
        ) => {
            const { coordsS, front_id, _coordsLine } = action.payload;
            let {objects} = cloneDeep(state);
        



            if (objects) {
                const index = objects?.findIndex((item) => item.frontId === front_id);

                
                if (index !== -1) {
                    objects[index] = {
                        ...objects[index],
                        centerPoint: {
                            ...objects[index].centerPoint,
                            coordinates: coordsS!,
                        },
                    };
    
                    Object.keys(objects[index].passPoints).forEach((item2, i2) => {
                       
                        objects && (objects[index].passPoints[item2].geometry.coordinates = _coordsLine[item2])
                    });
                }
    
            }


            state.objects = objects;
        },
        changeCoordsFullLayer: (state, action: PayloadAction<IObjects>) => {

            state.objects = action.payload;
        },
    },
});

export const {
    toggleTool,
    storeNewObject,
    addPassPoint,
    deletePassPoint,
    selectPassPoint,
    toggleContextMenu,
    selectObject,
    deleteObject,
    changeObjectParams,
    storeInitialObjects,
    storeInitialCreatedAt,
    storeGeneralSettings,
    moveAnchor,
    moveCenter,
    changeSlaveOf,
    toggleShowLabels,
    storeVersionsData,
    storeCurrentPlanData,
    validateNames,
    renamePassPoint,
    storeAnotherLocationNames,
    storeHotAddObject,
    pasteObjectFromClipboard,
    clearSelectedObjects,
    changeCoords,
    changeCoordsFullLayer
} = PCCReducer.actions;

export const PCCReducerValues = (state: RootState) => state.reducer;

export default PCCReducer.reducer;
