import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Group, Circle } from 'react-konva';

import {
    reducerValues,
    storeNewObject,
    selectObject,
    toggleTool,
    addAnchor,
    clearSelectedObjects,
    changeCoords,
    changeCoordsFullLayer
} from '../../reducer';
import { ObjectLabel, Anchors, Helper } from '../../../../layers';
import Escalator from './components/Escalator/Escalator';
import { commonTools } from '../../../../layers';
import { IExtendedPlan, ILineString, IPlan } from '../../../../layers.interfaces';
import { sizes } from '../../../../../../constants/sizes';
import { InOut, TDirection, IObjects } from '../../interfaces';
import { cloneDeep } from 'lodash';
import { log } from 'console';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        selectedObjectId,
        currentPlanData,
        generalSettings: { snapToGrid },
    } = useSelector(reducerValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [canTransform, setCanTransform] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [currentPointerCoords, setCurrentPointerCoords] = useState<number[] | undefined>(undefined);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [
        isMouseDownMove,
        setIsMouseDownMove
    ] = useState<boolean>(false)


    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw' && container) {
            container.style.cursor = 'crosshair';
        } else if (activeToolId === 'anchorsEdit' && container) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCanTransform = (e: KonvaEventObject<MouseEvent>, can: boolean) => {
        props.stage.current.setAttr('draggable', !can);
        setCanTransform(() => can);
    };

    const updateCoords = (coord: Array<number>) => {
        setCoords([coords[0], coord]);
    };

    const addNewPoint = (coord: Array<number>) => {
        if (coords.length < 2) {
            setCoords([coord, coord]);
        } else {
            onFinishClick();
        }
    };

    const onHelperClick = (objectId: string) => (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (activeToolId === 'draw') {
            updateIsDrawNow(true);
            newPoint && addNewPoint(newPoint);
        } else {
            e.evt.preventDefault();
            dispatch(selectObject(undefined));
        }
    };

    const onLineClick = (e: KonvaEventObject<MouseEvent>, front_id?: string) => {
        if (activeToolId === 'draw') {
            // updateIsDrawNow(true);
            // currentPointerCoords && updateCoords(currentPointerCoords);
        } else if (activeToolId === 'anchorsEdit' && selectedObjectId !== front_id) {
            dispatch(selectObject(front_id));
        } else if (activeToolId === 'anchorsEdit' && selectedObjectId === front_id) {
            // const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            // dispatch(addAnchor({ front_id, newPoint }));
        }
    };

    const onMouseDown = (id: string | undefined, e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true)
        }
        else if (activeToolId === 'move') {
            // setIdSelectedShape(id);
            dispatch(selectObject(id));
        }
    };

    const onMouseUp = (e: any) => {

        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());
        }
        else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false)
        }

    };

    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (selectedObjectId !== null) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                let _coordsLine: { [x: string]: number[] } = {};
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects.find((item) => item.front_id === selectedObjectId);

                if (moveObj) {
                    Object.keys(moveObj.passPoints).forEach((item2, i2) => {
                        _coordsLine[item2] = commonTools.processCoordsForMoveShape(
                            [moveObj.passPoints[item2].geometry.coordinates],
                            coords,
                            coordsOldShape,
                        )[0];
                    });

                    _coordsShape = commonTools.processCoordsForMoveShape(
                        moveObj.line.coordinates,
                        coords,
                        coordsOldShape,
                    );
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: selectedObjectId, _coordsLine }));

                setCoordsOldShape(coords);
            }
        }
        else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IObjects = []
                objects.forEach((item, i) => {
                    let objTemp: any = {}



                    Object.keys(item.passPoints).forEach((item2, i2) => {
                        objTemp[item2] = {geometry: null}
                        objTemp[item2].geometry = {
                            ...item.passPoints[item2].geometry,
                            coordinates: commonTools.processCoordsForMoveShape(
                                [item.passPoints[item2].geometry.coordinates],
                                coords,
                                coordsOldShape,
                            )[0]
                        };
                    });

                    let _obj: any = {
                        ...item,
                        line: {
                            ...item.line,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.line.coordinates,
                                coords,
                                coordsOldShape,
                            )
                        },
                        passPoints: {
                            ...objTemp
                        }
                    }


                    _objects.push(_obj)
                })


                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }

        }
    };

    const onFinishClick = () => {
        setIsDrawNow(false);
        dispatch(toggleTool(null));
        dispatch(
            storeNewObject({
                marker: '',
                floors: [],
                passPoints: {},
                name: '',
                front_id: '',
                width: 1,
                external_traffic: false,
                direction: 'up',
                in_out: 'in',
                line: { type: 'LineString', coordinates: coords },
            }),
        );
        setCoords([]);
    };

    const newObject = useMemo(() => {
        if (!coords || coords.length === 0 || !props.isLayerActive || !currentPlanData?.planScale) return null;
        const { planScale } = currentPlanData;

        const args = {
            currentPlanData,
            width: 1,
            external_traffic: false,
            passPoints: {},
            floors: [],
            in_out: 'in' as InOut,
            direction: 'up' as TDirection,
            stageScale: props.scale.stageScale,
            front_id: '',
            marker: '',
            name: '',
            line: { type: 'LineString', coordinates: coords } as ILineString,
            isDrawNow,
        };

        return (
            <Layer key={props.key + '++'}>
                <Escalator
                    {...args}
                    onClick={onHelperClick('')}
                    updateCoords={updateCoords}
                    activeToolId={activeToolId}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                />
            </Layer>
        );
    }, [coords, props.scale, activeToolId, selectedObjectId, currentPointerCoords]);

    const geometry = useMemo(() => {
        if (!objects || Object.keys(objects).length === 0 || !currentPlanData?.planScale) return null;
        const arr = cloneDeep(objects)
            .sort((a, _) => {
                if (a.front_id === selectedObjectId) return 1;
                return -1;
            })
            .map((escalator) => {
                try {
                    const {
                        width,
                        marker,
                        front_id,
                        line,
                        name,
                        in_out,
                        floors,
                        external_traffic = false,
                        direction,
                        passPoints,
                    } = escalator;
                    const selected = front_id === selectedObjectId;
                    const args = {
                        line,
                        marker,
                        direction,
                        passPoints,
                        in_out,
                        floors,
                        external_traffic,
                        name,
                        width,
                        currentPlanData,
                        stageScale: props.scale.stageScale,
                        key: front_id,
                        front_id,
                    };
                    return (
                        <Escalator
                            {...args}
                            onClick={onLineClick}
                            updateCoords={updateCoords}
                            selected={selected}
                            activeToolId={activeToolId}
                            onMouseDown={onMouseDown}
                            onMouseUp={onMouseUp}
                        />
                    );
                } catch (error) {
                    console.log('>>>>> ERROR in Escalator Draw');
                    return null;
                }
            });

        return <Group>{arr}</Group>;
    }, [objects, selectedObjectId, props.scale.stageScale, canTransform, activeToolId]);

    try {
        return (
            <>
                <Layer key={props.key}
                onMouseMove={moveShape}
                onMouseLeave={onMouseUp}
                onMouseUp={onMouseUp}
                >
                    <Helper
                        updateCoords={updateCoords}
                        isDrawNow={isDrawNow}
                        currentPlanData={currentPlanData!}
                        snapToGrid={snapToGrid}
                        onHelperClick={onHelperClick('')}
                    />

                    {geometry}
                </Layer>
                {newObject}
            </>
        );
    } catch (error) {
        return null;
    }
};

export default Draw;
