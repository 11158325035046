import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import GeneralReducer from './General.reducer';
import LocationsReducer from './components/Locations/Locations.reducer';
import AccountsReducer from './components/Locations/components/Accounts/Accounts.reducer';
import ProjectsReducer from './components/Projects/reducer';
import PlansReducer from './components/Plans/Plans.reducer';
import VersionsReducer from './components/Versions/Versions.reducer';
import SettingsReducer from './components/Settings/Settings.reducer';
import TablesPanelReducer from './components/Editor/components/TablesPanel/TablesPanel.reducer';
import TenantsReducer from './components/Editor/components/Tenants/Tenants.reducer';
import EditorReducer from './components/Editor/Editor.reducer';
import VersionsDiffReducer from './components/VersionsDiff/reducer';
import SyncPanelReducer from './components/Editor/components/SyncPanel/SyncPanel.reducer';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

export const store = configureStore({
    reducer: {
        GeneralReducer,
        LocationsReducer,
        ProjectsReducer,
        PlansReducer,
        SettingsReducer,
        VersionsReducer,
        TablesPanelReducer,
        VersionsDiffReducer,
        SyncPanelReducer,
        TenantsReducer,
        EditorReducer,
        AccountsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
