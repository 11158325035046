// apiHelpers.ts
import { deleteRequest, getRequest, patchRequest, postRequest } from '../../../../../../tools/api';

/**
 * Выполняет запросы в зависимости от метода (POST или DELETE).
 * @param url - URL для запроса.
 * @param data - данные для отправки (только для POST).
 * @param method - метод запроса (POST или DELETE).
 * @param token - токен авторизации.
 * @returns Promise с результатом выполнения запроса.
 */
export async function executeRequest({
    url,
    data,
    method,
    token,
}: {
    url: string;
    data: object;
    method: string;
    token: string;
}) {
    if (method === 'POST') {        
        return postRequest({ url, token, data });
    } else if (method === 'DELETE') {
        return deleteRequest({ url, token });
    } else if (method === 'GET') {
        return getRequest({ url, token });
    } else if (method === 'PATCH') {
        return patchRequest({ url, token, data });
    }
    return Promise.reject(new Error(`Invalid method: ${method}`));
}
