import React, { FC, ReactNode, useDeferredValue, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generalReducerValues } from '../../../../General.reducer';
import Datasheet from 'react-datasheet';

import { useSelector } from 'react-redux';
import { getRequest } from '../../../../tools/api';
import { locationsReducerValues } from '../../../Locations/Locations.reducer';
import { CustomCell, CustomHeader, FilterIndicator, Wrapper } from './styles';
import { commonTools } from '../../../../tools/commonTools';
import { ICell, IStore, ITableCustomComponentProps, useObjectManagementStore } from './store';
import ReactDataSheet from 'react-datasheet';
import ContextMenu from './components/ContextMenu';
import { COMPONENT_BY_COL } from './constants/constants';
import Modal from '../../../Modals/Modals';
import { IWorkingHours } from '../../../../tools/useRequestStructures/interfaces';
import { useTranslation } from 'react-i18next';
import { WorkingHoursModal } from './components/WorkingHoursModal/WorkingHoursModal';
import { IwhModalStatus } from './interfaces';
import { getWhStatus } from './tools/getWhStatus';

export interface GridElement extends ReactDataSheet.Cell<GridElement, number> {
    value: number | null;
}

const ObjectManagement = () => {
    const filters = useDeferredValue(useObjectManagementStore((state: IStore) => state.filters));
    const sorting = useObjectManagementStore((state: IStore) => state.sorting);
    const toggleContextMenu = useObjectManagementStore((state: IStore) => state.toggleContextMenu);
    const { t } = useTranslation();
    const [whModalStatus, setWhModalStatus] = useState<IwhModalStatus>({
        show: false,
        objectData: null,
    });

    const { activeProjectLocation } = useSelector(locationsReducerValues);
    const {
        urls: { PROJECT_LOCATIONS_URL },
        token,
    } = useSelector(generalReducerValues);

    const plId = activeProjectLocation?.id;

    const { isLoading, error, data } = useQuery({
        queryKey: ['dataObjects', plId],
        queryFn: () => {
            const url = `${PROJECT_LOCATIONS_URL}${plId}/`;
            return getRequest({ url, token }).then((data) => data.data_objects);
        },
        staleTime: 15 * 60 * 1000,
    });

    function compareStrings(a: any, b: any, order = 1) {
        if (!a) {
            a = '0';
        }

        if (!b) {
            b = '0';
        }

        if (a < b) {
            return -1 * order;
        }
        if (a > b) {
            return 1 * order;
        }
        return 0;
    }

    const getReadableString = (val: object) => {
        if (Array.isArray(val)) {
            return val.reduce((acc, block: object) => {
                const stringBlock = Object.entries(block).reduce((acc, entry) => {
                    const [key, value] = entry;
                    return acc + `${key}: ${value}\n`;
                }, '');
                return acc + stringBlock + '-------' + '\n';
            }, '');
        } else {
            return JSON.stringify(val);
        }
    };

    const grid: ICell[][] = useMemo(() => {
        if (!data?.[0]) return [];
        const result = [];
        const header = Object.keys(data[0]).map((key) => {
            return { value: key, readOnly: true, types: ['header'], colName: key };
        });
        result.push(header);

        let rest = data.filter((item: any) => {
            for (let key in filters) {
                let value = item[key];
                if (key === 'working_hours') {
                    value = getWhStatus(item[key] as IWorkingHours[]);
                }

                if (
                    filters[key]?.filter &&
                    !filters[key]?.mode &&
                    !JSON.stringify(value)?.toLowerCase().includes(filters[key].filter.toLowerCase())
                ) {
                    return false;
                } else if (filters[key]?.filter && filters[key]?.mode && filters[key]?.mode === 'strict') {
                    const val = commonTools.isObject(value)
                        ? JSON.stringify(value).toLowerCase()
                        : String(value).toLowerCase();
                    if (val !== filters[key].filter.toLowerCase()) {
                        return false;
                    }
                }
            }
            return true;
        });

        if (sorting.colName && sorting.order) {
            const key = sorting.colName;
            rest.sort((a: any, b: any) => {
                return compareStrings(a[key], b[key], sorting.order);
            });
        }

        rest = rest.map((row: { [x: string]: any }) => {
            return Object.keys(row).map((key: string) => {
                const val = row[key as string];

                let value = commonTools.isObject(val) ? getReadableString(val) : String(val);
                if (key === 'working_hours') {
                    value = getWhStatus(val as IWorkingHours[]);
                }
                const types: string[] = ['cell'];
                if (key === 'date_from' || key === 'date_to') {
                    types.push('date');
                }
                if (commonTools.isObject(val)) {
                    types.push('object');
                }
                return { value, types, colName: key, readOnly: true, dataObject: row };
            });
        });

        result.push(...rest);
        return result;
    }, [data, filters, sorting]);

    const toggleModal = (args: IwhModalStatus) => {
        setWhModalStatus(args);
    };

    const cellRenderer = (props: ReactDataSheet.CellRendererProps<ICell>) => {
        const { children, editing, ...rest } = props;

        if (props.cell.types?.includes('cell')) {
            let Component: React.FC<ITableCustomComponentProps> | undefined = undefined;

            Component = COMPONENT_BY_COL[props.cell.colName || ''] as React.FC<ITableCustomComponentProps> | undefined;

            return (
                <CustomCell {...rest} style={{ ...rest.style }} types={props.cell.types || []}>
                    {Component ? <Component {...props} toggleModal={toggleModal} /> : children}
                </CustomCell>
            );
        } else if (props.cell.types?.includes('header')) {
            const filterValue = filters[props.cell.colName || '']?.filter;
            const filterText = filterValue ? `( ${filterValue} )` : '';

            return (
                <CustomHeader {...rest} style={{ ...rest.style }} types={props.cell.types || []}>
                    {children}
                    <FilterIndicator>{filterText}</FilterIndicator>
                </CustomHeader>
            );
        } else {
            return null;
        }
    };

    const onContextMenu = (e: MouseEvent, cell: GridElement, i: number, j: number) => {
        e.preventDefault();
        const cursorCoords = { x: e.clientX, y: e.clientY };
        toggleContextMenu({ show: true, cursorCoords, cell });
    };

    const dataSheet = useMemo(() => {
        return (
            <Datasheet
                onContextMenu={onContextMenu}
                cellRenderer={cellRenderer}
                // attributesRenderer={(cell) => (cell.value ? { 'data-hint': cell.value } : {})}
                data={grid}
                valueRenderer={(cell: any) => cell.value}
            />
        );
    }, [grid]);

    const updateTable = (newTableData: object[] | null) => {
        // if (newTableData === null) {
        //     fillFullTable();
        // }
    };

    return (
        <Wrapper>
            <Modal
                modalStatus={whModalStatus}
                title={`${t('Working hours')}: ${whModalStatus.objectData?.object_type} ${
                    whModalStatus.objectData?.object_name
                }`}
            >
                <WorkingHoursModal objectData={whModalStatus.objectData} />
            </Modal>
            {data ? <ContextMenu selectedTable={data} updateTable={updateTable} /> : null}
            {isLoading ? <div>Loading...</div> : dataSheet}
        </Wrapper>
    );
};

export default ObjectManagement;
