import { IPassWay } from '../../../Layers/components/PassWays/passWays.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IReducer } from './interfaces';
import { IPlace } from '../../../Layers/components/Places/places.interfaces';
const passways_perimeter = require('../../../../lambda/passways_perimeter');

export const initialState: IReducer = {
    tupleAlias: 'passways_perimeter_tuple',
    intersections: [],
};

export const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        storeIntersections: (
            state,
            action: PayloadAction<{ perimeterObjects: Array<IPlace>; passWaysObjects: Array<IPassWay>; planScale: number }>
        ) => {
            const { perimeterObjects, passWaysObjects, planScale } = action.payload;
            const intersections = passways_perimeter.get_intersections({ perimeterObjects, passWaysObjects, planScale });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
