import { FontIcon, mergeStyles } from '@fluentui/react';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

export const TuplesWrapper = styled.ul<{ visible: boolean }>`
    display: ${(p) => (p.visible ? 'block' : 'none')};
    list-style: none;
    width: 100%;
    height: calc(100% - 44px);
    margin: 0;
    box-sizing: border-box;
    background-color: ${theme.palette.neutralLight};
    padding: 10px;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 50px;
`;
export const Li = styled.li`
    padding: 10px 0;
    display: flex;
    align-items: center;
    list-style: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;
export const Text = styled.span`
    padding: 0 5px;
    font-size: 12px;
`;

export const Container = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    i {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const StyledFontIcon = styled(FontIcon)`
    cursor: pointer;
`;

export const iconClass = (color: string) =>
    mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        // marginRight: '5px',
        color,
    });
