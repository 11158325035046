import { KonvaEventObject } from 'konva/lib/Node';
import { cloneDeep } from 'lodash';
import { IExtendedPlan } from '../../../components/Layers/layers.interfaces';
import { sizes } from '../../../constants/sizes';
import { IPlan } from '../../commonInterfaces';
import { commonTools } from '../../commonTools';

/**
 * Обновление координат точек
 * @param pointIndex
 * @param coords
 * @param e
 * @param snapToGrid
 */
export const updateAnchorCoords = (
    pointIndex: number,
    coords: Array<number[]>,
    e: KonvaEventObject<MouseEvent>,
    currentPlanData: IPlan,
    snapToGrid: number | boolean
) => {
    const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
    const currentCoords = cloneDeep(coords);
    if (newPoint) {
        currentCoords[pointIndex] = newPoint;
        return [...currentCoords];
    }
};
