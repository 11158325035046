import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTranslation } from 'react-i18next';
import { IStackTokens } from '@fluentui/react/lib/Stack';

import { reducerValues, changeObjectParams } from '../../../../reducer';
import { StyledStack } from './ObjectOptions.styles';
import { ICheckout } from '../../../../checkouts.interfaces';
import { commonTools } from '../../../../../../layers';

const ObjectOptions = () => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, generalSettings } = useSelector(reducerValues);
    const [selectedObject] = objects.filter((item) => item.id === selectedObjectId);
    const { t } = useTranslation();

    const StackTokens: IStackTokens = {
        childrenGap: 5,
    };

    const onChange =
        (key: keyof ICheckout) => (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            dispatch(changeObjectParams({ key, newValue: commonTools.matchChars(newValue), id: selectedObject.id }));
        };

    return selectedObject ? (
        <StyledStack tokens={StackTokens}>
            <TextField
                underlined
                disabled={generalSettings.readOnly}
                label={t('Marker')}
                value={String(selectedObject.marker)}
                onChange={onChange('marker')}
            />
        </StyledStack>
    ) : null;
};

export default ObjectOptions;
