import React, { useContext, MouseEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DetailsList,
    buildColumns,
    IColumn,
    DetailsRow,
    IDetailsRowProps,
    IDetailsHeaderProps,
} from '@fluentui/react/lib/DetailsList';
import { useTranslation } from 'react-i18next';

import { GeneralContext } from '../../../../General.context';

import { EditWrapper, ListWrapper, classNames } from './List.styles';
import { IColumns, IItems } from './List.interfaces';
import { IProjectLocation } from '../../Locations.interfaces';
import { FontIcon, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType } from '@fluentui/react';
import { commonTools } from '../../../Layers/layers';
import { settingsReducerValues } from '../../../Settings/Settings.reducer';
import { useSelector } from 'react-redux';
import { log } from 'console';

const List: React.FC<{ list: IProjectLocation[]; onEditLocationClick: (x: IProjectLocation) => void }> = ({
    list,
    ...props
}) => {
    const nav = useNavigate();
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const { theme } = useContext(GeneralContext);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [sortedItems, setSortedItems] = useState<IItems>([]);

    const { themeLighterAlt, neutralLighterAlt, white } = theme.palette;
    const { t } = useTranslation();

    const items = list.map((location) => {
        const { name, id, latitude, longitude, timezone, address, date_from, date_to } = location;
        const coordinates = `${latitude}, ${longitude}`;
        const edit = '';
        return readOnly
            ? { id, name, coordinates, date_from, date_to, timezone, address }
            : { id, name, coordinates, date_from, date_to, timezone, address, edit };
    });

    useEffect(() => {
        const columns = myBuildColumns(items);
        setColumns(columns);
        setSortedItems(items);
    }, [list]);

    function myBuildColumns(items: IColumns[]) {
        if (items.length === 0) return [];
        const columns = buildColumns(items);
        const [idCol] = columns.filter((column) => column.name === 'id');
        idCol.maxWidth = 30;
        const [zoneCol] = columns.filter((column) => column.name === 'timezone');
        zoneCol.maxWidth = 200;
        const [coordsCol] = columns.filter((column) => column.name === 'coordinates');
        coordsCol.maxWidth = 200;
        const [fromCol] = columns.filter((column) => column.name === 'date_from');
        fromCol.maxWidth = 100;
        const [toCol] = columns.filter((column) => column.name === 'date_to');
        toCol.maxWidth = 100;
        // const [thumbnailColumn] = columns.filter((column) => column.name === 'thumbnail');
        // const [deleteCellColumn] = columns.filter((column) => column.name === 'deleteCell');

        // thumbnailColumn.name = 'thumbnail';
        // thumbnailColumn.minWidth = 80;
        // thumbnailColumn.maxWidth = 80;
        // thumbnailColumn.ariaLabel = 'Thumbnail';

        // deleteCellColumn.name = '';
        return columns;
    }

    const onRowClick = (id: number) => () => {
        nav(`${document.location.pathname}/pl/${id}`);
    };

    const renderRow = (props: IDetailsRowProps | undefined) => {
        const customStyles = {
            root: {
                // display: 'flex',
                backgroundColor: white,
                cursor: 'pointer',
            },
            cell: {
                // display: 'flex',
                width: 'auto',
                // alignItems: 'center',
            },
        };

        if (props) {
            if (props.itemIndex % 2 === 0) {
                customStyles.root = { backgroundColor: themeLighterAlt, cursor: 'pointer' };
            }
            return (
                <div onClick={onRowClick(props.item.id)}>
                    <DetailsRow {...props} styles={customStyles} />
                </div>
            );
        }
        return null;
    };

    const onEditClick = (location: IProjectLocation) => (e: React.MouseEvent) => {
        e.stopPropagation();
        props.onEditLocationClick(location);
    };

    function renderItemColumn(item: { [x: string]: any }, index: any, column: IColumn | undefined) {
        if (!column?.fieldName) return null;

        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case 'azimuth':
                return <span>{Math.round(Number(fieldContent))}</span>;
            case 'address':
                return fieldContent;
            case 'date_to':
                return fieldContent ? <span>{fieldContent}</span> : <span>Now</span>;
            case 'edit':
                return (
                    <EditWrapper onClick={onEditClick(item as IProjectLocation)}>
                        <FontIcon iconName="PageEdit" className={classNames.red} />
                    </EditWrapper>
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    const onColumnClick = (event: React.MouseEvent<HTMLElement> | undefined, column: IColumn | undefined): void => {
        const allowedKeys = ['id', 'name', 'coordinates', 'date_from', 'date_to', 'timezone', 'address'];
        if (!column || !allowedKeys.includes(column.key)) return;
        let isSortedDescending = column.isSortedDescending;

        // If we've sorted this column, flip it.
        if (column.isSorted) {
            isSortedDescending = !isSortedDescending;
        }

        // Sort the items.
        const items = commonTools.copyAndSort(sortedItems, column.fieldName!, isSortedDescending);

        // Reset the items and columns to match the state.
        setSortedItems(items);
        setColumns(
            columns.map((col) => {
                col.isSorted = col.key === column.key;
                if (col.isSorted) {
                    col.isSortedDescending = isSortedDescending;
                }
                return col;
            }),
        );
    };

    const customTableHeader = (
        headerProps: IDetailsHeaderProps | undefined,
        defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined,
    ) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    return (
        <ListWrapper>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    onRenderDetailsHeader={customTableHeader}
                    // layoutMode={DetailsListLayoutMode.fixedColumns}
                    onColumnHeaderClick={onColumnClick}
                    items={sortedItems}
                    onRenderItemColumn={renderItemColumn}
                    setKey="set"
                    columns={columns}
                    onRenderRow={renderRow}
                    selectionMode={0}
                    // constrainMode={ConstrainMode.unconstrained}
                    // constrainMode={0}
                    isHeaderVisible={true}
                />
            </ScrollablePane>
        </ListWrapper>
    );
};

export default List;
