import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Group, Circle } from 'react-konva';

import {
    reducerValues,
    storeNewObject,
    selectObject,
    toggleTool,
    addAnchor,
    clearSelectedObjects,
    changeShapeCoords,
    removeLastCoords,
    changeCoords,
} from '../../reducer';
import { ObjectLabel, Anchors, Helper, DrawNodes } from '../../../../layers';
import Lift from './components/Lift/Lift';
import { commonTools } from '../../../../layers';
import { IExtendedPlan, ILineString, IPlan, IPolygon } from '../../../../layers.interfaces';
import { cloneDeep } from 'lodash';
import { formattingCoords } from '../core/formattingCoords';
import { IObjects } from '../../interfaces';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        selectedObjectId,
        currentPlanData,
        generalSettings: { snapToGrid },
    } = useSelector(reducerValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [canTransform, setCanTransform] = useState(false);
    const [showFinalRing, setShowFinalRing] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [currentPointerCoords, setCurrentPointerCoords] = useState<number[] | undefined>(undefined);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw' && container) {
            container.style.cursor = 'crosshair';
        } else if (activeToolId === 'anchorsEdit' && container) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCanTransform = (e: KonvaEventObject<MouseEvent>, can: boolean) => {
        props.stage.current.setAttr('draggable', !can);
        setCanTransform(() => can);
    };

    const updateCoords = (newPoint: Array<number>) => {
        const existingCoords = cloneDeep(coords);
        if (existingCoords.length >= 2) {
            existingCoords.pop();
            if (
                existingCoords.length >= 3 &&
                Math.abs(newPoint[0] - existingCoords[0][0]) < 5 &&
                Math.abs(newPoint[1] - existingCoords[0][1]) < 5
            ) {
                setShowFinalRing(() => true);
            } else if (showFinalRing) {
                setShowFinalRing(() => false);
            }
        }
        setCoords([...existingCoords, newPoint]);
    };

    const addNewPoint = (coord: Array<number>) => {
        if (showFinalRing) {
            onFinishClick();
        } else {
            setCoords([...coords, coord]);
        }
    };

    const onHelperClick = (objectId: string) => (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (activeToolId === 'draw') {
            updateIsDrawNow(true);
            newPoint && addNewPoint(newPoint);
        } else {
            e.evt.preventDefault();
            dispatch(selectObject(undefined));
        }
    };

    const onLineClick = (e: KonvaEventObject<MouseEvent>, front_id?: string) => {
        if (activeToolId === 'draw') {
            // updateIsDrawNow(true);
            // currentPointerCoords && updateCoords(currentPointerCoords);
        } else if (activeToolId === 'anchorsEdit' && selectedObjectId !== front_id) {
            dispatch(selectObject(front_id));
        } else if (activeToolId === 'anchorsEdit' && selectedObjectId === front_id) {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            dispatch(addAnchor({ front_id, newPoint }));
        }
    };

    const onFinishClick = () => {
        const coordinates = cloneDeep(coords);
        coordinates.pop();
        setIsDrawNow(false);
        dispatch(toggleTool(null));
        dispatch(
            storeNewObject({
                marker: '',
                floors: [],
                external_traffic: false,
                name: '',
                front_id: '',
                geometry: { type: 'Polygon', coordinates },
            }),
        );
        setCoords([]);
        setShowFinalRing(() => false);
    };

    const onMouseDown = (id: string | undefined, e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        // setIdSelectedShape(id);
        if (activeToolId === 'move') {
            dispatch(selectObject(id));
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());

            // setIdSelectedShape('')

            let _objects: IObjects = [];
            let selected_Objects: number[][] = [];

            objects.forEach((item, i) => {
                if (item.front_id === selectedObjectId) {
                    selected_Objects = item.geometry.coordinates;
                } else {
                    _objects.push(item);
                }
            });

            if (commonTools.getIntersect(formattingCoords(_objects, selected_Objects))) {
                dispatch(removeLastCoords(selectedObjectId!));
            } else {
                dispatch(changeShapeCoords());
            }
        }
    };

    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (selectedObjectId) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects.find((item) => item.front_id === selectedObjectId);

                if (moveObj) {
                    _coordsShape = commonTools.processCoordsForMoveShape(
                        moveObj.geometry.coordinates,
                        coords,
                        coordsOldShape,
                    );
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: selectedObjectId }));

                setCoordsOldShape(coords);
            }
        }
    };

    const newObject = useMemo(() => {
        if (!coords || coords.length === 0 || !props.isLayerActive || !currentPlanData?.planScale) return null;

        const args = {
            currentPlanData,
            width: 1,
            floors: [],
            external_traffic: false,
            stageScale: props.scale.stageScale,
            front_id: '',
            marker: '',
            name: '',
            geometry: { type: 'Polygon', coordinates: coords } as IPolygon,
            isDrawNow,
            showFinalRing,
        };

        return (
            <Layer key={props.key + 'lift++'}>
                <Lift
                    {...args}
                    onClick={onHelperClick('')}
                    updateCoords={updateCoords}
                    activeToolId={activeToolId}
                    onMouseDown={onMouseDown}
                />
            </Layer>
        );
    }, [coords, props.scale, activeToolId, selectedObjectId, currentPointerCoords]);

    const geometry = useMemo(() => {
        if (!objects || Object.keys(objects).length === 0 || !currentPlanData?.planScale) return null;
        const arr = cloneDeep(objects)
            .sort((a, _) => {
                if (a.front_id === selectedObjectId) return 1;
                return -1;
            })
            .map((lift) => {
                try {
                    const { marker, front_id, geometry, name, floors, external_traffic } = lift;
                    const selected = front_id === selectedObjectId;
                    const args = {
                        geometry,
                        marker,
                        floors,
                        external_traffic,
                        name,
                        currentPlanData,
                        stageScale: props.scale.stageScale,
                        key: front_id,
                        front_id,
                    };
                    return (
                        <Lift
                            {...args}
                            closed
                            onClick={onLineClick}
                            updateCoords={updateCoords}
                            selected={selected}
                            activeToolId={activeToolId}
                            onMouseDown={onMouseDown}
                        />
                    );
                } catch (error) {
                    console.log('>>>>> ERROR in Lift Draw');
                    return null;
                }
            });

        return <Group>{arr}</Group>;
    }, [objects, selectedObjectId, props.scale.stageScale, canTransform, activeToolId]);

    try {
        return (
            <>
                <Layer key={props.key} onMouseMove={moveShape} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}>
                    {activeToolId === 'draw' && <DrawNodes stageScale={props.scale.stageScale} coordinates={coords} />}
                    <Helper
                        updateCoords={updateCoords}
                        isDrawNow={isDrawNow}
                        currentPlanData={currentPlanData!}
                        snapToGrid={snapToGrid}
                        onHelperClick={onHelperClick('')}
                    />
                    {geometry}
                </Layer>
                {newObject}
            </>
        );
    } catch (error) {
        return null;
    }
};

export default Draw;
