import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Stack, IStackProps, IStackTokens } from '@fluentui/react/lib/Stack';
import { List, ScrollToMode, IList } from '@fluentui/react/lib/List';
import { IItem, IItems } from './ObjectOptions.interface';

import { selectStepPoint, trackingTracksValues } from '../../../../reducer';

import { StyledStack, styles, stylesSelected } from './ObjectOptions.styles';
import items from './data/data';

/**
 * Опции для выбюраного объекта
 * @constructor
 */
const ObjectOptions = () => {
    const [step, setStep] = useState<number | null>(null);
    const [itemsTemp, setItemsTemp] = useState<IItems | null>(null);
    const { indexPoint, selectedObjects, stepValue } = useSelector(trackingTracksValues);
    const listRef: React.RefObject<IList> = React.useRef(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const StackTokens: IStackTokens = {
        childrenGap: 5,
    };

    useEffect(() => {
        if (indexPoint) {
            setStep(selectedObjects?.duration_mask![indexPoint] as number | null);
            let itemsArray = items;
            itemsArray.forEach((item, index) => {
                item.active = item.shape === selectedObjects?.duration_mask![indexPoint!];
            });
            setItemsTemp(itemsArray);
        }
    }, [indexPoint, selectedObjects, stepValue]);

    if (indexPoint !== null) {
        const onChange = (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
            // setIsOpened(isChecked!);
        };

        const onRenderCell = (item?: IItem, index?: number): JSX.Element => {
            const onClickItem = (step1: number) => () => {
                dispatch(selectStepPoint(step1));
            };
            return (
                <div key={`stepp${index}`} data-is-focusable onClick={onClickItem(item!.shape)}>
                    <div className={styles.itemContent}>{item?.name}</div>
                </div>
            );
        };

        if (itemsTemp) {
            return (
                <StyledStack tokens={StackTokens}>
                    <div className={styles.container} data-is-scrollable>
                        <List componentRef={listRef} items={itemsTemp} onRenderCell={onRenderCell} />
                    </div>
                </StyledStack>
            );
        }
    }

    return null;
};

export default ObjectOptions;
