import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Group, Circle } from 'react-konva';

import {
    passWaysReducerValues,
    changeCoords,
    storeNewObject,
    selectObject,
    toggleTool,
    addAnchor,
    clearSelectedObjects,
    changeCoordsFullLayer
} from '../../passWays.reducer';
import Helper from './components/Helper/Helper';
import { IHelperMouseMoveArgs } from './components/Helper/Helper.interfaces';
import PassWay from './components/PassWay/PassWay';
import { commonTools } from '../../../../layers';
import { IExtendedPlan, IPlan } from '../../../../layers.interfaces';
import { sizes } from '../../../../../../constants/sizes';
import { cloneDeep } from 'lodash';
import { IObjects } from '../../passWays.interfaces';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        selectedObjectId,
        currentPlanData,
        generalSettings: { snapToGrid },
    } = useSelector(passWaysReducerValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [canTransform, setCanTransform] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [currentPointerCoords, setCurrentPointerCoords] = useState<number[] | undefined>(undefined);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [
        isMouseDownMove,
        setIsMouseDownMove
    ] = useState<boolean>(false)

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw' && container) {
            container.style.cursor = 'crosshair';
        } else if (activeToolId === 'anchorsEdit' && container) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCanTransform = (e: KonvaEventObject<MouseEvent>, can: boolean) => {
        props.stage.current.setAttr('draggable', !can);
        setCanTransform(() => can);
    };

    const updateCoords = (coord: Array<number>) => {
        if (coords.length < 2) {
            setCoords([coord, coord]);
        } else {
            setCoords([...coords, coord]);
        }
    };

    const onHelperClick = (e: KonvaEventObject<MouseEvent>, marker?: string) => {
        if (activeToolId === 'draw') {
            updateIsDrawNow(true);
            currentPointerCoords && updateCoords(currentPointerCoords);
        } else {
            dispatch(selectObject());
        }
    };

    const onLineClick = (e: KonvaEventObject<MouseEvent>, id?: string) => {
        console.log('>>>> selectedObjectId', selectedObjectId);
        console.log('>>>> id', id);

        if (activeToolId === 'draw') {
            updateIsDrawNow(true);
            currentPointerCoords && updateCoords(currentPointerCoords);
        } else if (activeToolId === 'anchorsEdit' && selectedObjectId !== id) {
            dispatch(selectObject(id));
        } else if (activeToolId === 'anchorsEdit' && selectedObjectId === id) {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            dispatch(addAnchor({ id, newPoint }));
        }
    };

    const onHelperMouseMove = ({ e }: IHelperMouseMoveArgs) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        setCurrentPointerCoords(newPoint);
        if (isDrawNow === true) {
            if (newPoint) {
                coords.pop();
                setCoords([...coords, newPoint]);
            }
        }
    };

    const onFinishClick = () => {
        setIsDrawNow(false);
        dispatch(toggleTool(null));
        coords.pop();
        dispatch(
            storeNewObject({
                marker: '',
                name: '',
                id: '',
                passMargin: 0.4,
                connectivityMargin: 2,
                passLine: { type: 'LineString', coordinates: coords },
            }),
        );
        setCoords([]);
    };

    const moveLine = (e: any) => {
        if (activeToolId === 'move') {
            if (selectedObjectId !== null) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects.find((item) => item.id === selectedObjectId);

                if (moveObj) {
                    _coordsShape = commonTools.processCoordsForMoveShape(
                        moveObj.passLine.coordinates,
                        coords,
                        coordsOldShape,
                    );
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: selectedObjectId }));

                setCoordsOldShape(coords);
            }
        }
        else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IObjects = []
                objects.forEach((item, i) => {
                    _objects.push({
                        ...item,
                        passLine: {
                            ...item.passLine,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.passLine.coordinates,
                                coords,
                                coordsOldShape,
                            )
                        }
                    })
                })


                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }

        }
    };

    const onMouseDown = (id: string | undefined, e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        // setIdSelectedShape(id);
        if (activeToolId === 'move') {
            dispatch(selectObject(id));
        }
        else if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true)
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());
        }
        else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false)
        }
    };

    const newPassWay = useMemo(() => {
        if (!coords || coords.length === 0 || !props.isLayerActive || !currentPlanData?.planScale) return null;
        const { planScale } = currentPlanData;

        const showFinishCircle: boolean =
            coords?.length > 2 &&
            currentPointerCoords &&
            Math.abs(currentPointerCoords[0] - coords[coords.length - 2][0]) < 5 &&
            Math.abs(currentPointerCoords[1] - coords[coords.length - 2][1]) < 5
                ? true
                : false;

        const args = {
            currentPlanData,
            passMargin: 0.4,
            connectivityMargin: 2,
            stageScale: props.scale.stageScale,
            id: '',
            coords,
            showFinishCircle,
            isDrawNow,
        };

        return (
            <Layer key={props.key + '++'}>
                <PassWay
                    {...args}
                    onClick={onHelperClick}
                    onHelperMouseMove={onHelperMouseMove}
                    activeToolId={activeToolId}
                    onMouseDown={onMouseDown}
                    onMouseUp={onMouseUp}
                />
                {showFinishCircle && (
                    <Circle
                        onClick={onFinishClick}
                        x={coords[coords.length - 2][0]}
                        y={coords[coords.length - 2][1]}
                        radius={0.5 * 0.4 * planScale}
                        fill={'transparent'}
                        stroke={'red'}
                        strokeWidth={4 / props.scale.stageScale}
                    />
                )}
            </Layer>
        );
    }, [coords, props.scale, activeToolId, selectedObjectId, currentPointerCoords]);

    const geometry = useMemo(() => {
        if (!objects || Object.keys(objects).length === 0 || !currentPlanData?.planScale) return null;
        const arr = cloneDeep(objects)
            .sort((a, b) => {
                if (a.id === selectedObjectId) return 1;
                return -1;
            })
            .map((passWay) => {
                try {
                    const { passMargin, marker, id, passLine, name, connectivityMargin } = passWay;
                    const selected = id === selectedObjectId;
                    const args = {
                        showFinishCircle: false,
                        coords: passLine.coordinates,
                        marker,
                        name,
                        passMargin,
                        connectivityMargin,
                        currentPlanData,
                        stageScale: props.scale.stageScale,
                        key: id,
                        id,
                    };
                    return (
                        <PassWay
                            {...args}
                            onClick={onLineClick}
                            onHelperMouseMove={onHelperMouseMove}
                            selected={selected}
                            activeToolId={activeToolId}
                            onMouseDown={onMouseDown}
                            onMouseUp={onMouseUp}
                        />
                    );
                } catch (error) {
                    console.log('>>>>> ERROR in PassWay Draw');
                    return null;
                }
            });

        return <Group>{arr}</Group>;
    }, [objects, selectedObjectId, props.scale.stageScale, canTransform, activeToolId]);

    try {
        return (
            <>
                <Layer key={props.key} onMouseMove={moveLine} onMouseLeave={onMouseUp} onMouseUp={onMouseUp}>
                    <Helper
                        currentPlanData={currentPlanData}
                        isDrawNow={isDrawNow}
                        coords={coords}
                        onHelperClick={onHelperClick}
                        onHelperMouseMove={onHelperMouseMove}
                        updateCanTransform={updateCanTransform}
                    />
                    {geometry}
                </Layer>
                {newPassWay}
            </>
        );
    } catch (error) {
        return null;
    }
};

export default Draw;
