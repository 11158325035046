import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Group } from 'react-konva';
import { 
    reducerValues, 
    storeNewObject, 
    selectObject,
     moveObject, 
     transformObject ,
     clearSelectedObjects,
     changeCoords,
    changeCoordsFullLayer
    } from '../../reducer';

import Helper from './components/Helper/Helper';
import { commonTools } from '../../../../layers';
import { IHelperMouseMoveArgs } from './components/Helper/Helper.interfaces';
import Object from './components/Object/Object';
import { ICheckout, IMoveObjectAction, IObjects } from '../../checkouts.interfaces';
import { getCentroid } from './components/Object/core/getCentroid';
import { scaleShape } from './core/scaleShape';
import { rotateShape } from './core/rotateShape';
import { cloneDeep } from 'lodash';

/**
 * Компонент графического редактора
 */
const Draw = (props: any) => {
    const {
        activeToolId,
        objects,
        selectedObjectId,
        currentPlanData,
        showLabels,
        generalSettings: { readOnly, snapToGrid },
    } = useSelector(reducerValues);
    const dispatch = useDispatch();
    const [canTransform, setCanTransform] = useState(false);
    const [initialPointerCoords, setInitialPointerCoords] = useState<number[] | undefined>(undefined);
    const [currentObject, setCurrentObject] = useState<ICheckout | undefined>(undefined);
    const [mode, setMode] = useState<string | null>(null);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [
        isMouseDownMove,
        setIsMouseDownMove
    ] = useState<boolean>(false)

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw' && container) {
            container.style.cursor = 'crosshair';
        } else if ((activeToolId === 'resize' || activeToolId === 'rotate') && container) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId]);

    const updateCanTransform = (e: KonvaEventObject<MouseEvent>, can: boolean, mode: string | null) => {
        props.stage.current.setAttr('draggable', !can);
        setCanTransform(() => can);

        if (can) {
            const [currentObject] = objects.filter((item) => item.id === selectedObjectId);
            setInitialPointerCoords(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: false }));
            setCurrentObject(() => currentObject);
            setMode(() => mode);
        } else {
            setInitialPointerCoords(() => undefined);
        }
    };

    const onHelperClick = (e: KonvaEventObject<MouseEvent>, marker?: string) => {
        if (activeToolId === 'draw') {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: false });
            dispatch(storeNewObject(newPoint));
        } else {
            dispatch(selectObject());
        }
    };

    const onObjectClick = (e: KonvaEventObject<MouseEvent>, id?: string) => {
        if (activeToolId === 'resize' || activeToolId === 'rotate') {
            dispatch(selectObject(id));
        }
    };

    const onObjectDragEnd = (args: IMoveObjectAction) => {
        if (activeToolId === 'resize' || activeToolId === 'rotate') {
            dispatch(moveObject(args));
        }
    };


    const onMouseDown = (id: string | undefined, e: any)  => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true)
        }
        else if (activeToolId === 'move') {
            // setIdSelectedShape(id);
            dispatch(selectObject(id));
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());
        }
        else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false)
        }
        
    };

    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (selectedObjectId) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let coordsStaffArea: number[][] = [];
                let coordsCustomerArea: number[][] = [];
                let coordsCheckoutPoint: number[] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects.find((item) => item.id === selectedObjectId);

                

                if (moveObj) {
                    coordsCustomerArea = commonTools.processCoordsForMoveShape(
                        moveObj.customerArea.coordinates,
                        coords,
                        coordsOldShape,
                    );
                    coordsStaffArea = commonTools.processCoordsForMoveShape(
                        moveObj.staffArea.coordinates,
                        coords,
                        coordsOldShape,
                    );
                    coordsCheckoutPoint = commonTools.processCoordsForMoveShape(
                        [moveObj.checkoutPoint.coordinates],
                        coords,
                        coordsOldShape,
                    )[0];
                }

                dispatch(changeCoords({ 
                    front_id: selectedObjectId ,
                    coordsCheckoutPoint,
                  coordsCustomerArea,
                  coordsStaffArea
                }));

                setCoordsOldShape(coords);
            }
        }
        else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IObjects = []
                objects.forEach((item, i) => {
                    _objects.push({
                        ...item,
                        customerArea: {
                            ...item.customerArea,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.customerArea.coordinates,
                                coords,
                                coordsOldShape,
                            )
                        },
                        staffArea: {
                            ...item.staffArea,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.staffArea.coordinates,
                                coords,
                                coordsOldShape,
                            )
                        },
                        checkoutPoint: {
                            ...item.checkoutPoint,
                            coordinates: commonTools.processCoordsForMoveShape(
                                [item.checkoutPoint.coordinates],
                                coords,
                                coordsOldShape,
                            )[0]
                        }
                    })
                })


                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }

        }
    };

    const onHelperMouseMove = ({ e }: IHelperMouseMoveArgs) => {
        if ((activeToolId === 'resize' || activeToolId === 'rotate') && currentObject && canTransform) {
            const currentPointerCoords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: false });
            const stage = e.target.getStage();
            const p = stage?.getPointerPosition();
            if (
                canTransform &&
                mode &&
                stage &&
                p &&
                initialPointerCoords &&
                currentPointerCoords &&
                currentObject &&
                currentPlanData?.planScale
            ) {
                const center =
                    mode === 'customer'
                        ? getCentroid(currentObject.customerArea)
                        : getCentroid(currentObject.staffArea);
                const initialPolygon = mode === 'customer' ? currentObject.customerArea : currentObject.staffArea;

                if (activeToolId === 'resize') {
                    const newPolygon = scaleShape({
                        center,
                        initialPointerCoords,
                        currentPointerCoords,
                        initialPolygon,
                    });
                    dispatch(transformObject({ newPolygon, id: currentObject.id, mode }));
                } else if (activeToolId === 'rotate') {
                    const newPolygon = rotateShape({
                        center,
                        initialPointerCoords,
                        currentPointerCoords,
                        initialPolygon,
                    });
                    dispatch(transformObject({ newPolygon, id: currentObject.id, mode }));
                }
            }
        }
    };

    const geometry = useMemo(() => {
        if (!objects || objects.length === 0 || !currentPlanData?.planScale) return null;

        const arr = cloneDeep(objects)
            .sort((a, _) => {
                if (a.id === selectedObjectId) return 1;
                return -1;
            })
            .map((item) => {
                const selected = item.id === selectedObjectId;

                return (
                    <Object
                        key={item.id}
                        item={item}
                        planScale={currentPlanData.planScale}
                        stageScale={props.scale.stageScale}
                        onClick={onObjectClick}
                        onDragEnd={onObjectDragEnd}
                        onHelperMouseMove={onHelperMouseMove}
                        selected={selected}
                        updateCanTransform={updateCanTransform}
                        activeToolId={activeToolId}
                        showLabels={showLabels}
                        onMouseDown={onMouseDown}
                    />
                );
            });

        return <Group>{arr}</Group>;
    }, [
        objects,
        currentPlanData,
        props.scale.stageScale,
        selectedObjectId,
        canTransform,
        activeToolId,
        currentObject,
        showLabels,
    ]);

    return (
        <Layer key={props.key}
            onMouseMove={moveShape}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseUp}
        >
            {currentPlanData && (
                <Helper
                    currentPlanData={currentPlanData}
                    onHelperClick={onHelperClick}
                    onHelperMouseMove={onHelperMouseMove}
                    updateCanTransform={updateCanTransform}
                />
            )}
            {geometry}
        </Layer>
    );
};

export default Draw;
