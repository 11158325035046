import { theme } from './../../constants/theme';
import styled from 'styled-components/macro';

export const MainWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 600px;
`;

export const NewVersionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EmptyDays = styled.div`
    padding: 5px;
    color: red;
`;

export const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;
