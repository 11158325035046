import React, { useContext } from 'react';

import { ToolsPanelWrapper, getButtonStyles, Split, Footer, iconClass } from './ToolsPanel.styles';
import { EditorContext } from '../../Editor.context';
import { GeneralContext } from '../../../../General.context';
import { ILayer } from '../../../Layers/layers.interfaces';

/**
 * Компонента панели инструментов.
 * Отображает панель инструментов, соответствующую текущему активному слою.
 * Данные приходят из слоя в свойстве .tools
 */

const ToolsPanel = () => {
    const { layers, activeLayerId } = useContext(EditorContext);
    const { generalSettings } = useContext(GeneralContext);

    if (!layers || layers.length === 0 || !activeLayerId) return <ToolsPanelWrapper>{null}</ToolsPanelWrapper>;

    const tools = () => {
        const activeLayer = layers.filter((item: ILayer) => item.id === activeLayerId || item.id === 'measureCut')[0];
        const ToolsComponenta = activeLayer?.tools;
        return activeLayer && !generalSettings?.readOnly ? (
            <>
                <ToolsComponenta />
                {/* <Split /> */}
            </>
        ) : null;
    };

    return <ToolsPanelWrapper>{tools()}</ToolsPanelWrapper>;
};

export default ToolsPanel;
