import { useTranslation } from 'react-i18next';

/**
 * Хук с данными для календаря.
 * @returns Возвращает объект с данными для календаря
 */
const useDayPickerStrings = () => {
    const { t } = useTranslation();
    return {
        months: [
            t('January'),
            t('February'),
            t('March'),
            t('April'),
            t('May'),
            t('June'),
            t('July'),
            t('August'),
            t('September'),
            t('October'),
            t('November'),
            t('December'),
        ],

        shortMonths: [
            t('Jan'),
            t('Feb'),
            t('Mar'),
            t('Apr'),
            t('May'),
            t('Jun'),
            t('Jul'),
            t('Aug'),
            t('Sep'),
            t('Oct'),
            t('Nov'),
            t('Dec'),
        ],
        days: [t('Sunday'), t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday')],

        shortDays: [t('Sun'), t('Mon'), t('Tue'), t('Wed'), t('Thu'), t('Fri'), t('Sat')],

        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year',
        closeButtonAriaLabel: 'Close date picker',
        isRequiredErrorMessage: 'Start date is required.',
        invalidInputErrorMessage: 'Invalid date format.',
    };
};

export default useDayPickerStrings;
