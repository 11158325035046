import styled from 'styled-components/macro';
import {
    FontWeights,
    mergeStyleSets,
    mergeStyles,
    IStackStyles,
    IStackTokens,
    IButtonStyles,
} from '@fluentui/react';
import { theme } from '../../../../../../../../constants/theme';

export const ObjectsListWrapper = styled.div`
    height: auto;
    max-height: 190px;
    display: flex;
    flex-direction: column;
    /* background-color: #FFEFD5; */
    overflow: auto;
    position: relative;
`;

const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
    // margin: '0 25px',
});

export const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'greenyellow' }, iconClass],
    salmon: [{ color: 'salmon' }, iconClass],
});

export const ObjectWrapper = styled.div<{ selected: boolean }>`
    cursor: pointer;
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const IconButtonWrapper = styled.div`
    //position: absolute;
    //right: 0px;
    //top: calc((100% - 32px) / 2);
    background: rgba(255, 255, 255, 0.5);
`;

export const GroupHeaderWrapper = styled.div<{ statusSelect: boolean }>`
    position: relative;

    .title-92 {
        text-decoration: ${(p) => (p.statusSelect ? `underline` : null)};
    }
`;

export const ItemColumnWrapper = styled.div<{ statusSelect: boolean }>`
    font-weight: ${(p) => (p.statusSelect ? `bold` : null)};
    height: 100%;
    width: 100%;
    text-align: center;
`;

export const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

export const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const DeleteGroupZonesButtonWrapper = styled.div`
    position: absolute;
    top: 5px;
    right: 0px;
`;
