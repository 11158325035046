import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    navPolygonsValues,
    selectGroupNavPolygons,
    storeCurrentPlanData,
    storeInitialCreatedAt,
    storeInitialObjects,
    toggleShowLabels,
    selectNavPolygon,
    deleteNavPolygon,
    storeGeneralSettings,
    clearSelectedObjects,
    storeHotAddObject,
} from '../../../../navPolygons.reducer';
import { useGroupForNavPolygons } from './core/useGroupForNavPolygons';
import { ObjectsListWrapper, GroupHeaderWrapper, ItemColumnWrapper, classNames } from './ObjectsList.styles';
import { IGroupHeaderPropsCustom, IDetailsGroupRenderPropsCustom } from '../../../../navPolygons.interfaces';
import { DefaultButton } from '@fluentui/react/lib/Button';
import {
    DetailsList,
    IColumn,
    IDetailsHeaderProps,
    IRenderFunction,
    Modal,
    IDragOptions,
    ContextualMenu,
    IStackTokens,
} from '@fluentui/react';
import { GroupHeader } from '@fluentui/react/lib/GroupedList';
import { buildColumns, DetailsRow, IDetailsRowProps } from '@fluentui/react/lib/DetailsList';
import { IIconProps } from '@fluentui/react';

import { commonTools } from '../../../../../../../../tools/commonTools';
import ContentModal from './componetns/ContentModal';
import { StyledStack } from '../ObjectOptions/ObjectOptions.styles';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { selectObject } from '../../../../../PassWays/passWays.reducer';

const addIcon: IIconProps = { iconName: 'Add' };
const cancelIcon: IIconProps = { iconName: 'Cancel' };
const StackTokens: IStackTokens = {
    childrenGap: 5,
};

/**
 * Список объектов
 */
const ObjectsList = ({ ...props }) => {
    const dispatch = useDispatch();
    const [isShowModal, setIsShowModal] = useState(false);
    const [dataModal, setDataModal] = useState<{
        name: string | undefined;
        marker: string | undefined;
    } | null>(null);
    const { t } = useTranslation();
    const { objects, createdAt, navPolygonFrontId, typeSelect, generalSettings } = useSelector(navPolygonsValues);

    const { items, groups } = useGroupForNavPolygons(objects, typeSelect, props?.generalSettings?.objNamingMode);

    useEffect(() => {
        props.revertLayer &&
            dispatch(
                storeInitialObjects(
                    props.initialData.data || {
                        polygons_out: [],
                        polygons_in: [],
                    },
                ),
            );
    }, [props.revertLayer]);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        props.layerChange({ id: 'nav_polygons_layer', objects, lastCreatedAt: createdAt, selectedObjectId: null });
    }, [objects]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        if (!props?.initialData) return;
        dispatch(
            storeInitialObjects(
                props.initialData.data || {
                    polygons_out: [],
                    polygons_in: [],
                },
            ),
        );
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(clearSelectedObjects());
    }, [props.active]);

    const onClickItemColumn = (itemFrontId?: string) => () => {
        dispatch(selectNavPolygon({ itemFrontId }));
    };

    const renderItemColumn = (item?: { [x: string]: any }, index?: any, column?: IColumn | undefined) => {
        let statusSelect = item!.front_id === navPolygonFrontId;

        const onClickItemDelete = (e: React.MouseEvent) => {
            e.stopPropagation();
            dispatch(deleteNavPolygon({ navPolygonFrontId: item!.front_id }));
        };

        return (
            <ItemColumnWrapper
                statusSelect={statusSelect}
                key={`ItemColumnWrapper:${index}`}
                onClick={onClickItemColumn(item!.front_id)}
            >
                <span>{item!.labelText || index}</span>

                {!generalSettings.readOnly && (
                    <FontIcon iconName="Delete" className={classNames.salmon} onClick={onClickItemDelete} />
                )}
            </ItemColumnWrapper>
        );
    };

    const renderRowHandler = (props: IDetailsRowProps | undefined) => {
        const customStyles = {
            root: {
                backgroundColor: 'transparent',
            },
        };

        if (props) {
            return <DetailsRow {...props} styles={customStyles} />;
        }
        return <DetailsRow {...(props as unknown as IDetailsRowProps)} styles={customStyles} />;
    };

    const onRenderDetailsHeader = (
        props?: IDetailsHeaderProps,
        _defaultRender?: IRenderFunction<IDetailsHeaderProps>,
    ) => {
        if (props) {
            return null;
        } else {
            return null;
        }
    };

    function myBuildColumns() {
        const columnsToDisplay = ['groupZones'].map((item) => {
            return { 'group zones': 'group zones' };
        });

        const columns = buildColumns(columnsToDisplay);

        return columns;
    }
    const onRenderHeader = (props?: IGroupHeaderPropsCustom): JSX.Element => {
        const onClickHeaderGroup = (): void => {
            dispatch(selectGroupNavPolygons({ key: props!.group!.key }));
        };

        let statusSelect = props!.group!.key === typeSelect;
        return (
            <GroupHeaderWrapper
                key={`GroupHeaderWrapper:${props!.group!.key}`}
                statusSelect={statusSelect}
                onClick={onClickHeaderGroup}
            >
                <GroupHeader {...props} />
            </GroupHeaderWrapper>
        );
    };

    const onClickButtonShowModal = () => {
        // const newMarker = commonTools.generateNameNumber(objects, 'Group_Zones_marker', 'group_marker');
        // const newName = commonTools.generateNameNumber(objects, 'Group_Zones', 'group_name');
        // setDataModal({
        //     name: newName,
        //     marker: newMarker,
        // });
        // setIsShowModal(true);
    };

    const onClickButtonHideModal = () => {
        setIsShowModal(false);
    };

    const onChangeMarkerGroupZones = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        setDataModal({
            ...dataModal!,
            marker: newValue,
        });
    };

    const onChangeNameGroupZones = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string,
    ) => {
        setDataModal({
            ...dataModal!,
            name: newValue,
        });
    };

    let objGroupProps: IDetailsGroupRenderPropsCustom = {
        showEmptyGroups: true,
        onRenderHeader: onRenderHeader,
    };

    const dragOptions: IDragOptions = {
        moveMenuItemText: 'Move',
        closeMenuItemText: 'Close',
        menu: ContextualMenu,
    };

    const stackTokens: IStackTokens = { childrenGap: 10 };

    return (
        <ObjectsListWrapper>
            <StyledStack tokens={StackTokens}>
                <DetailsList
                    items={items}
                    groups={groups}
                    onRenderRow={renderRowHandler}
                    onRenderItemColumn={renderItemColumn}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    columns={myBuildColumns()}
                    selectionMode={0}
                    groupProps={objGroupProps}
                    compact={true}
                />
            </StyledStack>
        </ObjectsListWrapper>
    );
};

export default ObjectsList;
