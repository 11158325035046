import { ITenant2Place, ITenant, IPlace as ITPlace } from './../../../Editor/components/Tenants/Tenants.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './places.store';

import { IPlacesReducer } from './places.interfaces';
import { IPlace } from '../Places/places.interfaces';
import { IPlan } from '../../../Plans/Plans.interfaces';
import { commonTools } from '../../../../tools/commonTools';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import {
    IExtendedPlan,
    IGeneralSettings,
    INodeContextMenu,
    IHotAddObject,
    IVersionsData,
} from '../../layers.interfaces';
import { ITenantsReducerState } from '../../../Editor/components/Tenants/Tenants.interfaces';
import { sizes } from '../../../../constants/sizes';
import { IObjects } from '../Lifts/interfaces';

export const initialState: IPlacesReducer = {
    selectedObjectId: null,
    activeToolId: null,
    tenantsData: null,
    versionsData: null,
    selectedChapter: '',
    objects: [],
    createdAt: undefined,
    currentPlanData: null,
    showLabels: false,
    generalSettings: {} as IGeneralSettings,
    placesFrontId: '',
    placesIndexByPlacesFrontId: null,
    selectedObjects: null,
    layerAlias: 'places_layer',
    isNamesValid: { valid: true },
    anotherLocationNames: { markers: [] },
    contextMenu: { show: false },
    hotAddObject: null,
    objectsTemp: [],
};

export const PlacesReducer = createSlice({
    name: 'PlacesReducer',
    initialState,
    reducers: {
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Запись информации об арендаторах
         */
        storeTenantsData: (
            state,
            action: PayloadAction<{ tenant2place: ITenant2Place[]; tenants: ITenant[]; places: ITPlace[] }>,
        ) => {
            state.tenantsData = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeSelectedChapter: (state, action: PayloadAction<string>) => {
            state.selectedChapter = action.payload;
        },

        /**
         * Открывает контекстное меню с выбором: удалить узелок (если их больше 3) или удалить весь рисунок
         */
        toggleContextMenu: (state, action: PayloadAction<INodeContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Удаление узелка
         */
        deleteAnchor: (state, action: PayloadAction<{ placeFrontId: string; anchorIndex: number }>) => {
            const { placeFrontId, anchorIndex } = action.payload;

            const objects = cloneDeep(state.objects).map((place: IPlace) => {
                if (place.front_id === placeFrontId) {
                    const coordinates = cloneDeep(place.coords.coordinates);
                    coordinates.splice(anchorIndex, 1);
                    const result = { ...place, coords: { ...place.coords, coordinates } };
                    return result;
                }
                return place;
            });

            state.objects = objects;
            state.objectsTemp = objects;
        },

        pasteObjectFromClipboard: (state, action: PayloadAction<IPlace>) => {
            const { objects, versionsData, selectedObjects } = cloneDeep(state);

            if (selectedObjects?.marker === action.payload.marker) {
                const newObjects = objects.filter((item) => item.front_id !== selectedObjects.front_id);
                const front_id = `v${String(versionsData?.activeVersionId)}:passway:${commonTools.generateId()}`;
                const newObject = cloneDeep({ ...action.payload, front_id });
                commonTools.shiftCoords(newObject, [sizes.GLOBAL_SHIFT, sizes.GLOBAL_SHIFT]);
                newObjects.push(newObject);
                state.objects = newObjects;

                let placesIndexByPlacesFrontId: { [key: string]: number } | null = {};

                newObjects.forEach((item, index) => {
                    placesIndexByPlacesFrontId![item.front_id] = index;
                });

                state.placesIndexByPlacesFrontId = placesIndexByPlacesFrontId;
                state.placesFrontId = front_id!;
                state.selectedObjects = newObject;
            } else {
                console.log('WRONG SELECTED PLACE');
            }
        },

        /***
         * Созжание нового помещений
         * @param state
         * @param action
         */
        storeNewPlace: (state, action: PayloadAction<Array<Array<number>>>) => {
            const objects = cloneDeep(state.objects);
            const objects2 = cloneDeep(state.objects);
            const anotherLocationNames = cloneDeep(state.anotherLocationNames);

            const currentMarkers = objects.map((item) => item.marker);
            const nextNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers, ...currentMarkers],
                'Place',
            );
            let placesIndexByPlacesFrontId: { [key: string]: number } | null = {};

            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:Place${nextNumber}`;
            const id = `v${String(activeVersionId)}:place:${commonTools.generateId()}`;

            let arrayEntrances: null[] = [];

            action.payload.forEach((item1, index1) => {
                arrayEntrances.push(null);
            });

            objects2.push({
                front_id: id,
                entrances: arrayEntrances,
                marker: marker,
                coords: {
                    type: 'Polygon',
                    coordinates: action.payload,
                },
                is_opened: true,
                user_area: null,
            });

            objects2.forEach((item, index) => {
                placesIndexByPlacesFrontId![item.front_id] = index;
            });

            state.placesIndexByPlacesFrontId = placesIndexByPlacesFrontId;
            state.objects = objects2;
            state.objectsTemp = objects2;
        },

        /**
         * Изменение координат выделеного объекта
         * @param state
         * @param action
         */
        changeCoords: (state, action: PayloadAction<{ coordsS: number[][] | undefined; front_id: string }>) => {
            const { coordsS, front_id } = action.payload;
            const objects = cloneDeep(state.objects);
            const index = objects.findIndex((item) => item.front_id === front_id);

            index !== -1 &&
                (objects[index] = {
                    ...objects[index],
                    coords: {
                        ...objects[index].coords,
                        coordinates: coordsS!,
                    },
                });

            state.objects = objects;
        },

        changeCoordsFullLayer: (state, action: PayloadAction<Array<IPlace>>) => {
            state.objects = action.payload;
        },

        /***
         * Запись в стор загруженных с сервера объектов
         * @param state
         * @param action
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IPlace>>) => {
            let placesIndexByPlacesFrontId: { [key: string]: number } | null = {};
            action.payload.forEach((item, index) => {
                placesIndexByPlacesFrontId![item.front_id] = index;
            });

            const modifyPlaces = (arr: IPlace[]) => {
                return Array.isArray(arr)
                    ? arr.map((place) => {
                          if (place.user_area === undefined) {
                              return { ...place, user_area: null };
                          } else {
                              return place;
                          }
                      })
                    : [];
            };

            state.objects = modifyPlaces(action.payload);
            state.objectsTemp = modifyPlaces(action.payload);
            state.placesIndexByPlacesFrontId = placesIndexByPlacesFrontId;
            state.selectedObjects = null;
        },

        /**
         * Запись в стор поля createdAt
         * @param state
         * @param action
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Переключение показа маркеров на объектах
         * @param state
         * @param action
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Добавление новых точек в полигон
         * @param state
         * @param action
         */
        addAnchor: (
            state,
            action: PayloadAction<{ anchorCoords?: number[] | undefined; objectId?: string | undefined }>,
        ) => {
            const { anchorCoords, objectId } = action.payload;
            const objects = cloneDeep(state.objects);
            let objectsTemp = [];

            const places = objects.map((element) => {
                if (element.front_id !== objectId) {
                    return element;
                } else {
                    const coords = element.coords.coordinates;
                    let index = null;
                    let statusAndAnchors = false;
                    coords.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, anchorCoords!)) {
                                index = i;
                                statusAndAnchors = false;
                            }
                        } else if (i === 0) {
                            if (commonTools.between(arr[arr.length - 1], point, anchorCoords!)) {
                                statusAndAnchors = true;
                                index = null;
                            }
                        }
                    });

                    if (index !== null) {
                        coords.splice(index, 0, anchorCoords!);
                    } else if (statusAndAnchors) {
                        coords.push(anchorCoords!);
                    }
                    return {
                        ...element,
                        coords: {
                            ...element.coords,
                            coordinates: coords,
                        },
                    };
                }
            });

            objectsTemp = places;
            //
            state.objects = objectsTemp;
            state.objectsTemp = objectsTemp;
        },

        /**
         * Выбор объекта
         * @param state
         * @param action
         */
        selectPlace: (state, action: PayloadAction<{ placeFrontId: string | undefined }>) => {
            const { placeFrontId } = action.payload;
            const objects = cloneDeep(state.objects);
            let selectedObjects: IPlace | null = null;
            let placesIndexByPlacesFrontId = cloneDeep(state.placesIndexByPlacesFrontId);
            if (!placeFrontId) {
                state.selectedObjects = null;
                state.placesFrontId = '';
            } else {
                let indexTemp = placesIndexByPlacesFrontId![placeFrontId!];
                selectedObjects = objects[indexTemp!];
                state.selectedObjects = selectedObjects;
                state.placesFrontId = placeFrontId!;
            }
        },

        /**
         * Сохранение данных
         * @param state
         * @param action
         */
        saveDataPlace: (
            state,
            action: PayloadAction<{ isOpened: boolean; marker: string | undefined; userArea: string }>,
        ) => {
            const { isOpened, marker, userArea } = action.payload;
            let objects = cloneDeep(state.objects);
            let placesFrontId = cloneDeep(state.placesFrontId);
            let placesIndexByPlacesFrontId = cloneDeep(state.placesIndexByPlacesFrontId);

            let indexTemp = cloneDeep(placesIndexByPlacesFrontId![placesFrontId]);
            objects[indexTemp!].marker = marker!;
            objects[indexTemp!].is_opened = isOpened;
            objects[indexTemp!].user_area = Number(userArea) > 0 ? Number(userArea) : null;

            objects.forEach((item, index) => {
                placesIndexByPlacesFrontId![item.front_id!] = index;
            });
            state.objects = objects;
            state.objectsTemp = objects;
            state.placesIndexByPlacesFrontId = placesIndexByPlacesFrontId;
        },

        /**
         * Уделение объекта
         * @param state
         * @param action
         */
        deletePlace: (state, action: PayloadAction<{ placeFrontId: string }>) => {
            const { placeFrontId } = action.payload;
            let objects = cloneDeep(state.objects);
            let placesIndexByPlacesFrontId = cloneDeep(state.placesIndexByPlacesFrontId);
            let indexTemp = cloneDeep(placesIndexByPlacesFrontId![placeFrontId!]);

            objects.splice(indexTemp!, 1);
            placesIndexByPlacesFrontId = {};
            objects.forEach((item, index) => {
                placesIndexByPlacesFrontId![item.front_id] = index;
            });

            state.selectedObjects = null;
            state.objects = objects;
            state.objectsTemp = objects;
            state.placesIndexByPlacesFrontId = placesIndexByPlacesFrontId;
        },

        /**
         * Очистка выделеного объекта
         * @param state
         * @param action
         */
        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjects = null;
            state.placesFrontId = '';
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Сохранение в стор всех имен зи других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];
            const names: string[] = [];

            action?.payload.forEach((item: { data?: Array<{ marker: string; name: string }> }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string; name: string }) => {
                    markers.push(obj.marker);
                });
            });
            const anotherLocationNames = { markers };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Валидация маркеров.
         */
        validateNames: (state) => {
            const { objects, anotherLocationNames } = cloneDeep(state);
            const currentMarkers = objects.map((item) => item.marker);
            const isNamesValid = commonTools.isNamesValid([...currentMarkers, ...anotherLocationNames.markers]);
            state.isNamesValid = isNamesValid;
        },
        changeShapeCoords: (state, action: PayloadAction) => {
            state.objectsTemp = state.objects;
        },

        removeLastCoords: (state, action: PayloadAction) => {
            state.objects = state.objectsTemp;
        },
    },
});

export const {
    pasteObjectFromClipboard,
    toggleTool,
    changeCoords,
    storeGeneralSettings,
    storeNewPlace,
    storeCurrentPlanData,
    storeInitialCreatedAt,
    storeInitialObjects,
    toggleShowLabels,
    addAnchor,
    storeTenantsData,
    selectPlace,
    storeSelectedChapter,
    saveDataPlace,
    storeVersionsData,
    deletePlace,
    clearSelectedObjects,
    storeAnotherLocationNames,
    validateNames,
    toggleContextMenu,
    deleteAnchor,
    storeHotAddObject,
    changeShapeCoords,
    removeLastCoords,
    changeCoordsFullLayer,
} = PlacesReducer.actions;

export const placesValues = (state: RootState) => state.PlacesReducer;

export default PlacesReducer.reducer;
