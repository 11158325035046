export const layerGroups = [
    {
        shortName: 'core',
        fullName: 'Core Structure',
        layerAliases: [
            'perimeter_layer',
            'pass_ways_layer',
            'places_layer',
            'zones_layer',
            'escalators_layer',
            'lifts_layer',
            'stairs_layer',
        ],
    },
    {
        shortName: 'fpc',
        fullName: 'People Counting',
        layerAliases: ['pc_sensor_ipoints_layer'],
    },
    {
        shortName: 'fsf',
        fullName: 'Sales Flow',
        layerAliases: ['checkouts_layer'],
    },
    {
        shortName: 'shr',
        fullName: 'Radio Analytics',
        layerAliases: [
            'radio_sensor_ipoints_layer',
            'tracks_wifi_layer',
            'tracks_tracking_layer',
            'tracks_navigation_layer',
            'nav_polygons_layer',
        ],
    },
];
