import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from './store';
import { postRequest, getLocalBase64Image, getRequest, patchRequest } from './tools/api';

import {
    IAlert,
    IGeneralReducerState,
    IUrls,
    IHosts,
    IChangeGeneralFeatures,
    ICurrent,
    ILocales,
} from './General.interfaces';
import { cloneDeep } from 'lodash';

const initialState: IGeneralReducerState = {
    permissions: ['map-service.layers.core.viewer'],
    alert: { show: false, text: '', type: 'warning' },
    showSpinner: false,
    locales: null,
    showBackgroundPlanImage: true,
    spinnersCount: 0,
    token: '',
    appDomain: '',
    current: null,
    windowSize: { width: 0, height: 0 },
    urls: {},
    hosts: {},
    backEnd: {},
};

export const GeneralReducer = createSlice({
    name: 'GeneralReducer',
    initialState,

    reducers: {
        /**
         * Включение/выключение спиннера.
         */
        toggleSpinner: (state, action: PayloadAction<boolean>) => {
            let { spinnersCount } = cloneDeep(state);

            if (action.payload) {
                spinnersCount++;
            } else {
                spinnersCount--;
            }

            state.spinnersCount = spinnersCount;

            state.showSpinner = spinnersCount <= 0 ? false : true;
        },
        /**
         * Включение/выключение фоновой картинки плана этажа.
         */
        toggleBackground: (state) => {
            const { showBackgroundPlanImage } = cloneDeep(state);
            state.showBackgroundPlanImage = !showBackgroundPlanImage;
        },

        /**
         * Запись токена в стор.
         */
        storeToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },

        storeLocales: (state, action: PayloadAction<ILocales>) => {
            state.locales = action.payload;
        },

        storeHosts: (state, action: PayloadAction<{ [x: string]: string }>) => {
            state.hosts = action.payload;
        },

        storeAppDomain: (state, action: PayloadAction<string>) => {
            state.appDomain = action.payload;
        },
        /**
         * Запись токена в стор.
         */
        storeCurrent: (state, action: PayloadAction<ICurrent>) => {
            // console.log(action.payload);

            state.current = action.payload;
            state.permissions = action.payload.permissions as string[];
        },

        /**
         * Включение/выключение алерта.
         */
        toggleAlert: (state, action: PayloadAction<IAlert>) => {
            state.alert = !action.payload.show ? { show: false, text: '', type: 'warning' } : action.payload;
        },

        /**
         * Запись токена в стор.
         */
        storeWindowSize: (state, action: PayloadAction<{ width: number; height: number }>) => {
            state.windowSize = action.payload;
        },

        /**
         * Запись backend в стор.
         */
        fillBackEnd: (state, action: PayloadAction<IHosts>) => {
            state.backEnd = action.payload;
        },

        /**
         * ЗПринудительная установка режима только для чтения.
         */
        setReadOnlyMode: (state) => {
            state.permissions = ['map-service.layers.core.viewer'];
        },

        /**
         * Запись backend в стор.
         */
        fillUrls: (state, action: PayloadAction<IUrls>) => {
            state.urls = action.payload;
        },
    },
});

export const {
    storeAppDomain,
    toggleSpinner,
    storeToken,
    storeHosts,
    storeCurrent,
    storeWindowSize,
    toggleBackground,
    storeLocales,
    toggleAlert,
    fillBackEnd,
    fillUrls,
    setReadOnlyMode,
} = GeneralReducer.actions;

export const generalReducerValues = (state: RootState) => state.GeneralReducer;

export default GeneralReducer.reducer;

/**
 * Запрос на ручку Current
 */
export const getCurrent =
    (args: { token: string }): AppThunk =>
    (dispatch, getState) => {
        const {
            urls: { CURRENT_URL, AUTH_URL },
        } = cloneDeep(getState().GeneralReducer);

        getRequest({
            url: `${CURRENT_URL}`,
            dispatch,
            allowSpinner: true,
            token: args.token,
        })
            .then((data) => {
                if (data) {
                    dispatch(storeToken(args.token));
                    dispatch(storeCurrent(data));
                } else {
                    const currentUrl = window.location.origin + window.location.pathname;
                    console.log('>>>', currentUrl);

                    window.location.replace(`${AUTH_URL}?next=${currentUrl}`);
                }
            })
            .catch((err) => {
                console.log('>>> ERROR', err);
            });
    };
