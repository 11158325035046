// StatusTable.tsx
import React from 'react';
import styled from 'styled-components';
import { RequestStatus } from './useCopyProductsAndLocations';

// Стили для таблицы
const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
`;

const TableHead = styled.thead`
    background-color: #f5f5f5;
`;

const TableRow = styled.tr`
    &:nth-child(even) {
        background-color: #f9f9f9;
    }
`;

const TableHeader = styled.th`
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
    font-weight: bold;
`;

const TableCell = styled.td<{ status: string }>`
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;

    // Статусные стили
    color: ${({ status }) => (status === 'success' ? '#2e7d32' : status === 'error' ? '#d32f2f' : '#ffa000')};
    font-weight: ${({ status }) => (status === 'loading' ? 'bold' : 'normal')};
`;

// Компонент таблицы статусов
export const StatusTable = ({ requestStatuses }: { requestStatuses: RequestStatus[] }) => {
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableHeader>ID</TableHeader>
                    <TableHeader>Method</TableHeader>
                    <TableHeader>Status</TableHeader>
                    <TableHeader>Message</TableHeader>
                </TableRow>
            </TableHead>
            <tbody>
                {requestStatuses.map((status, i) => (
                    <TableRow key={status.message + `<>${i}`}>
                        <TableCell status={status.status}>
                            {status.productId
                                ? `Product id: ${status.productId}`
                                : status.locationId
                                ? `Location id: ${status.locationId}`
                                : 'N/A'}
                        </TableCell>
                        <TableCell status={status.status}>{status.method}</TableCell>
                        <TableCell status={status.status}>{status.status}</TableCell>
                        <TableCell status={status.status}>{status.message}</TableCell>
                    </TableRow>
                ))}
            </tbody>
        </Table>
    );
};
