import { store } from '../../places.store';
import { Provider } from 'react-redux';
import React from 'react';
import Draw from './Draw';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <Draw {...props} />
        </Provider>
    );
};
