import { IDropdownStyles } from '@fluentui/react';
import { PERMISSIONS_SELECT_WIDTH } from '../../../Accounts/constants/constants';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

export const dropdownStyles: Partial<IDropdownStyles> = {
    // callout: { width: PERMISSIONS_SELECT_WIDTH, height: 'auto' },
    dropdown: { width: PERMISSIONS_SELECT_WIDTH, height: 'auto' },
    title: { height: 'auto', paddingTop: 3, paddingBottom: 3 },
    caretDownWrapper: { display: 'flex', height: '100%', alignItems: 'center' },
};

export const OptionsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;
export const ProductWrapper = styled.div`
    margin-top: 15px;
`;
export const OptionBubble = styled.div`
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
    margin: 2px;

    background-color: ${theme.palette.neutralLighter};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
`;

export const Controls = styled.div`
    display: flex;
    padding: 10px;
    padding-bottom: 20px;
    gap: 10px;
    align-items: flex-end;
    border: ${() => '1px solid ' + theme.palette.neutralQuaternary};
`;
