import axios, { AxiosError } from 'axios';

import { toggleAlert } from './reducer';
import { Dispatch } from '@reduxjs/toolkit';
import { getHeaders } from '../../../../tools/getHeaders';

/**
 * Get запрос.
 * @param args Аргументы для запроса на сервер.
 */
export const getRequest = async (args: { dispatch?: Dispatch; url: string; token?: string | null }) => {
    const { url, token, dispatch } = args;
    const headers = getHeaders(token || '');
    try {
        const response = await axios({
            method: 'get',
            url,
            headers,
            responseType: 'json',
        });
        return response.data;
    } catch (error) {
        handleError(error as AxiosError, dispatch);
    }
};

/**
 * Обработчик ошибок.
 *
 * @param error Объект описывающий ошибку.
 */
export const handleError = (error: AxiosError, dispatch?: Dispatch): void => {
    if (error.response) {
        // Запрос был сделан и сервер ответил
        dispatch &&
            dispatch(
                toggleAlert({
                    show: true,
                    // text: `Network Error! Status: ${error.response.status}`,
                    text: `Network Error! Status: ${error.response.status}`,
                }),
            );
        // console.log(error.response.data);
        console.warn(error.response.status);
        console.warn(error.response.headers);
    } else if (error.request) {
        // Запрос был сделан но ответа не получено
        console.warn(error.request);
    } else {
        // Что-то вызвало ошибку при запросе.

        console.warn('Error', error.message);
    }
};
