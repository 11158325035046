import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { ISaveLayerModalProps } from './SaveLayerModal.interfaces';
import { Footer, MainWrapper, TextWrapper } from './SaveLayerModal.styles';
import { useDispatch, useSelector } from 'react-redux';
import { saveLayerToServer } from '../../../Editor/Editor.reducer';
import { versionsReducerValues } from '../../../Versions/Versions.reducer';
import { DateTime } from 'luxon';
import { useAppDispatch } from '../../../../store';

/**
 * Компонент Модалка удаления плана.
 */
const SaveLayerModal: React.FC<ISaveLayerModalProps> = React.memo(({ modalStatus, closeModal }) => {
    const { activeVersionId, actualVersionId, versionsById } = useSelector(versionsReducerValues);
    const { t } = useTranslation();
    const stackTokens = { childrenGap: 10 };
    const dispatch = useAppDispatch();

    const okHandler = () => {
        modalStatus.saveLayerData && dispatch(saveLayerToServer({ ...modalStatus.saveLayerData }));
        closeModal();
    };

    const actualText = activeVersionId === actualVersionId ? 'actual' : 'irrelevant';
    const from = activeVersionId
        ? DateTime.fromISO(versionsById[activeVersionId].date_from as string).toLocaleString(DateTime.DATE_MED)
        : '';
    const to =
        !activeVersionId || versionsById[activeVersionId].date_to === null
            ? 'now'
            : DateTime.fromISO(versionsById[activeVersionId].date_to as string).toLocaleString(DateTime.DATE_MED);

    return (
        <MainWrapper>
            <TextWrapper>
                <p>You are saving the layer at</p>
                <h3>{`${actualText} version`}</h3>
                <p>{`from ${from} to ${to}`}</p>
            </TextWrapper>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={t('Save')} width={'100px'} onClick={okHandler} />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default SaveLayerModal;
