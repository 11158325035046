import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import {
    EditorWrapper,
    MonitoringLayerOptionsWrapper,
    SelectedObjectWrapper,
    StyledText,
    StyledSplit,
    LeftHandWrapper,
} from './Editor.styles';
import { GeneralContext } from '../../General.context';
import { EditorContext } from './Editor.context';
import {
    saveLayerToServer,
    storeLayerToServer,
    storeDataFromLayers,
    storeOtherLayers,
    storeActiveLayerId,
    editorReducerValues,
    toggleContextMenu,
    toggleTupleVisible,
    toggleVisible,
    // fetchPlIds,
    toggleVersionsHistoryModalStatus,
    toggleConflictModal,
    storeObjectFromDiff,
    fetchLayers,
    resetEditor,
    // storePlIds,
} from './Editor.reducer';
import { fetchPlans, plansReducerValues, storePlanToEdit } from '../Plans/Plans.reducer';
import { versionsReducerValues, fetchVersions } from '../Versions/Versions.reducer';
import { tenantsReducerValues } from './components/Tenants/Tenants.reducer';
import { generalReducerValues } from '../../General.reducer';
import { useTranslation } from 'react-i18next';
import ToolsPanel from './components/ToolsPanel/ToolsPanel';
import DrawPanel from './components/DrawPanel/DrawPanel';
import TablesPanel from './components/TablesPanel/TablesPanel';
import ControlPanel from './components/ControlPanel/ControlPanel';
import { locationsReducerValues } from '../Locations/Locations.reducer';
import { IPlan } from '../Plans/Plans.interfaces';
import { geoMatrixConverner, MultiplyMatrix } from '../../tools/geoMatrixConverter';
import Modal from '../Modals/Modals';
import ShopsterPullModal from '../Modals/components/ShopsterPullModal/ShopsterPullModal';
import ConflictModal from '../Modals/components/ConflictModal/ConflictModal';
import tuples from '../Tuples/tuples';
import monitoringLayers from '../Monitoring/monitoringLayers';
import { useMemo } from 'react';
import { ILayer } from '@fluentui/react';
import { theme } from '../../constants/theme';
import VesrionsHistoryModal from '../Modals/components/VersionsHistoryModal/VersionsHistoryModal';
import ContextMenuPanel from './components/ContextMenuPanel/ContextMenuPanel';
import PlacePanel from './components/Tenants/components/PlacePanel/PlacePanel';
import TenantPanel from './components/Tenants/components/TenantPanel/TenantPanel';
import { settingsReducerValues } from '../Settings/Settings.reducer';
import { tuplesNamesMapping } from '../../constants/tuplesNamesMapping';
import VersionsDiff from '../VersionsDiff';
import SyncPanel from './components/SyncPanel/SyncPanel';
import { sizes } from '../../constants/sizes';
import { useAppDispatch } from '../../store';
import { useRouterParams } from '../../tools/useRouterParams';

/**
 * Отображает основной графический редактор.
 */
const Editor = ({ ...props }) => {
    useRouterParams();

    const dispatch = useAppDispatch();
    const { layers, currentPlanData, image } = props;
    const {
        activeLayerId,
        visibleLayersIds,
        visibleTuplesIds,
        changedLayersIds,
        initialLayers,
        contextMenu,
        conflictModal,
        objectFromDiff,
        dataFromLayers,
        selectedChapter,
        // plIds,
        versionsHistoryModalStatus,
        activeMonitoringLayerAlias,
        hotAddObject,
    } = useSelector(editorReducerValues);
    const { token, windowSize } = useContext(GeneralContext);
    const { activeLocation } = useSelector(locationsReducerValues);
    const { generalSettings } = useSelector(settingsReducerValues);
    const { planToEdit, plansList, planBlueprintScale, imageOffset, plansIdsByVersionIds } =
        useSelector(plansReducerValues);

    const { versions, activeVersionId } = useSelector(versionsReducerValues);
    const { selectedPlace, selectedTenant } = useSelector(tenantsReducerValues);
    const { t } = useTranslation();
    const [shopsterPullModal, setShopsterPullModal] = useState({ show: false });
    const [versionsDiff, setVersionsDiff] = useState({ show: false, collapsed: false });
    const [tupleModal, setTupleModal] = useState({ show: false, alias: '' });
    const [monitoringModal, setMonitoringModal] = useState({ show: false, alias: '' });
    const [leftHandSplitPercentWidth, setLeftHandSplitPercentWidth] = useState(75);

    const {
        urls: { PLANS_URL, LAYERS_URL, PROJECT_LOCATIONS_URL, VERSIONS_URL, PL_BY_ML_URL },
    } = useSelector(generalReducerValues);

    useHotkeys('shift+s+p', () => !generalSettings?.readOnly && setShopsterPullModal({ show: true }), [
        generalSettings,
    ]);
    useHotkeys('shift+s+d', () => setVersionsDiff({ show: true, collapsed: false }));

    const storePlanToEditTrans = (planToEdit: IPlan | null) => {
        planToEdit && dispatch(storePlanToEdit({ planToEdit }));
    };

    const onHideContextualMenu = () => {
        dispatch(toggleContextMenu({ show: false }));
    };

    // useEffect(() => {
    //     if (!props?.match?.params?.locationId) return;
    //     dispatch(fetchPlIds({ mlId: Number(props.match.params.locationId), url: PL_BY_ML_URL }));
    //     if (!activeLocation?.id) {
    //         dispatch(changeActiveLocationId({ activeLocationId: Number(props.match.params.locationId) }));
    //         dispatch(fetchVersions(VERSIONS_URL, token, Number(props.match.params.locationId)));
    //     }
    // }, []);

    useEffect(() => {
        if (!props?.match?.params?.planId) return;
        const plansListIds = plansList?.map((item) => item.planId);

        if (!plansListIds?.includes(planToEdit?.planId)) {
            const [planToEdit] = plansList.filter((item) => String(item.planId) === String(props.match.params.planId));
            dispatch(storePlanToEdit({ planToEdit }));
        }
    }, [plansList]);

    // useEffect(() => {
    //     if (!props?.match?.params?.plId) return;
    // }, [dispatch, props?.match?.params?.plId]);

    useEffect(() => {
        let locationId = String(activeLocation?.id);
        // versions.length !== 0 && activeLocation?.id && dispatch(fetchPlans(PLANS_URL, token, locationId));
        dispatch(resetEditor());
    }, [PLANS_URL, activeLocation?.id, dispatch, token, versions]);

    // useEffect(() => {
    //     if (layers && layers.length !== 0 && !activeLayerId) {
    //         const id = layers[0].id;
    //         dispatch(storeActiveLayerId(id));
    //         dispatch(toggleVisible(id));
    //     }
    // }, [activeLayerId, dispatch, layers]);

    useEffect(() => {
        const locationId = activeLocation?.id;
        planToEdit?.planId && locationId && activeVersionId && dispatch(fetchLayers(locationId, activeVersionId));
    }, [activeLocation?.id, activeVersionId, dispatch, planToEdit]);

    const layerChange = (args: { id: string }) => {
        if (args?.id === 'measureCut') {
            dispatch(saveLayerToServer({ ...args, layersUrl: LAYERS_URL }));
        } else {
            dispatch(storeDataFromLayers(args));
        }
    };

    const otherLayersChange = (args: {
        id: string;
        createdAt: string;
        anotherLocationLayers: { [x: string]: any }[];
    }) => {
        dispatch(storeOtherLayers(args));
    };

    const closeVersionModal = () => {
        dispatch(toggleVersionsHistoryModalStatus({ show: false }));
    };

    const closeConflictModal = () => {
        dispatch(toggleConflictModal({ show: false, data: {} }));
    };

    const toggleTupleVisibleTrans = (alias: string) => {
        dispatch(toggleTupleVisible({ alias }));
    };

    const showTupleModalTrans = (alias: string) => {
        setTupleModal({ show: true, alias });
    };

    const showMonitoringModalTrans = (alias: string) => {
        setMonitoringModal({ show: true, alias });
    };

    const editorContextValue = {
        layers,
        versions,
        tuples,
        monitoringLayers,
        changedLayersIds,
        currentPlanData,
        // plIds,
        image,
        hotAddObject,
        activeLayerId,
        activeMonitoringLayerAlias,
        visibleLayersIds,
        visibleTuplesIds,
        plansList,
        planToEdit,
        contextMenu,
        dataFromLayers,
        selectedChapter,
        storePlanToEditTrans,
        toggleTupleVisibleTrans,
        plansIdsByVersionIds,
        activeVersionId,
        showTupleModalTrans,
        showMonitoringModalTrans,
        objectFromDiff,
        generalSettings,
        leftHandSplitPercentWidth,
    };

    const tupleModalContent = useMemo(() => {
        if (tupleModal.alias === '') return null;
        const ModalElement = tuples.filter((item) => item.alias === tupleModal.alias)[0].modal;
        return <ModalElement />;
    }, [tupleModal]);

    const monitoringModalContent = useMemo(() => {
        if (monitoringModal.alias === '') return null;
        const ModalElement = monitoringLayers.filter((item) => item.alias === monitoringModal.alias)[0].modal;
        return <ModalElement />;
    }, [monitoringModal.alias]);

    const mainPlan = plansList.filter((plan) => plan.floor === planToEdit?.floor).filter((plan) => plan.isMain)[0];

    const activeLayer = layers.filter((item: { id: string }) => item.id === activeLayerId)[0];

    const SelectedObjectOptions = activeLayer?.controls?.objectOptions ? activeLayer.controls.objectOptions : null;

    const activeMonitoringLayer = monitoringLayers.filter(
        (item: { alias: string }) => item.alias === activeMonitoringLayerAlias,
    )[0];

    const MonitoringLayerOptions = activeMonitoringLayer?.controls?.objectOptions;

    const showSelectedObjectOptions =
        activeLayerId &&
        dataFromLayers[activeLayerId] &&
        dataFromLayers[activeLayerId].selectedObjectId !== null &&
        dataFromLayers[activeLayerId].selectedObjectId !== '';

    const onConflictButtonClick = (mode: string, actualCreatedAt: string) => {
        dispatch(toggleConflictModal({ show: false, data: {} }));
        if (mode === 'rewrite' && activeLayerId) {
            dispatch(
                storeLayerToServer({ id: activeLayerId, layersUrl: LAYERS_URL, isRewrite: true, actualCreatedAt }),
            );
        }
    };
    const closeMonitoringModal = () => {
        setMonitoringModal({ show: false, alias: '' });
    };

    const closeDiff = () => {
        setVersionsDiff({ show: false, collapsed: false });
    };

    const toggleCollapseDiff = () => {
        setVersionsDiff({ ...versionsDiff, collapsed: !versionsDiff.collapsed });
    };

    const copyObject = (args: { obj: any }) => {
        dispatch(storeObjectFromDiff(args));
    };

    const onSplitDragEnd = (sizes: number[]) => {
        setLeftHandSplitPercentWidth(sizes[0]);
    };

    return (
        <EditorContext.Provider value={editorContextValue}>
            {!generalSettings?.readOnly && token && activeLocation?.id ? (
                <VersionsDiff
                    key={versionsDiff.show ? 1 : 0}
                    show={versionsDiff.show}
                    collapsed={versionsDiff.collapsed}
                    closeDiff={closeDiff}
                    toggleCollapseDiff={toggleCollapseDiff}
                    copyObject={copyObject}
                    token={token}
                    locationId={String(activeLocation.id)}
                />
            ) : null}
            <ContextMenuPanel />

            <Modal modalStatus={shopsterPullModal} title={t('Pull Shopster layers')}>
                <ShopsterPullModal
                // onOKClick={onModalOKClick}
                />
            </Modal>
            <Modal
                modalStatus={conflictModal}
                title={t('Conflict')}
                topColor={theme.palette.red}
                closeModal={closeConflictModal}
            >
                <ConflictModal modalData={conflictModal} onConflictButtonClick={onConflictButtonClick} />
            </Modal>
            <Modal modalStatus={tupleModal} title={t(tuplesNamesMapping.get(tupleModal.alias) || '')}>
                {tupleModalContent}
            </Modal>

            <Modal
                // topColor={theme.palette.red}
                modalStatus={monitoringModal}
                title={''}
                closeModal={closeMonitoringModal}
            >
                {monitoringModalContent}
            </Modal>

            <Modal
                modalStatus={versionsHistoryModalStatus}
                title={t('Versions history')}
                closeModal={closeVersionModal}
                isDraggable={true}
            >
                <VesrionsHistoryModal />
            </Modal>
            <EditorWrapper>
                <StyledSplit
                    onDragEnd={onSplitDragEnd}
                    gutterSize={5}
                    sizes={[75, 25]}
                    minSize={sizes.CONTROL_PANEL_WIDTH}
                    // gutterStyle={getGutterStyles}
                >
                    <LeftHandWrapper>
                        {/* <ToolsPanel /> */}
                        {['layers', 'tuples', 'tenants', 'monitoring'].includes(selectedChapter) && (
                            <DrawPanel
                                image={image}
                                planBlueprintScale={planBlueprintScale}
                                imageOffset={imageOffset}
                                layers={layers}
                                activeLayerId={activeLayerId}
                                onHideContextualMenu={onHideContextualMenu}
                            />
                        )}
                        {['tables'].includes(selectedChapter) && <TablesPanel />}
                        {['sync'].includes(selectedChapter) && <SyncPanel />}
                        {showSelectedObjectOptions && selectedChapter === 'layers' ? (
                            <SelectedObjectWrapper>
                                <StyledText>{t('Object options')}</StyledText>
                                <SelectedObjectOptions />
                            </SelectedObjectWrapper>
                        ) : null}
                        {selectedChapter === 'monitoring' && MonitoringLayerOptions ? (
                            <MonitoringLayerOptionsWrapper>
                                <MonitoringLayerOptions />
                            </MonitoringLayerOptionsWrapper>
                        ) : null}
                        {selectedPlace && selectedChapter === 'tenants' ? <PlacePanel /> : null}
                        {selectedTenant && selectedChapter === 'tenants' ? <TenantPanel /> : null}
                        {/* {selectedChapter === 'monitoring' ? <TenantPanel /> : null} */}
                    </LeftHandWrapper>
                    <ControlPanel
                        layerChange={layerChange}
                        otherLayersChange={otherLayersChange}
                        initialLayers={initialLayers}
                        mainPlan={{ ...mainPlan, planBlueprintScale, imageOffset }}
                    />
                </StyledSplit>
            </EditorWrapper>
        </EditorContext.Provider>
    );
};

export default Editor;
