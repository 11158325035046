import React, { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { Label } from '@fluentui/react/lib/Label';
import { useTranslation } from 'react-i18next';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { commonTools } from '../../../../../../layers';

import {
    PCCReducerValues,
    changeObjectParams,
    addPassPoint,
    renamePassPoint,
    changeSlaveOf,
    deletePassPoint,
    selectPassPoint,
} from '../../../../reducer';
import { StyledStack, ObjectWrapper, classNames, StyledTextField } from './ObjectOptions.styles';
import { IPCC } from '../../../../interfaces';
import FilterInput from '../../../../../../../FilterInput/FilterInput';
import { cloneDeep } from 'lodash';

const ObjectOptions = () => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, currentPlanData, selectedPassPointId, generalSettings } =
        useSelector(PCCReducerValues);
    const { t } = useTranslation();
    const [options, setOptions] = useState<{ key: string; text: string }[]>([]);
    const [filteredOptions, setFilteredOptions] = useState<{ key: string; text: string }[]>([]);

    useEffect(() => {
        if (!objects) return;
        const options = cloneDeep(objects)
            .filter((item) => item.frontId !== selectedObjectId)
            .filter((item) => item.followedBy === null)
            .map((item) => {
                return { key: item.marker, text: item.marker };
            });
        options.push({ key: 'null', text: 'null' });
        setOptions(options);
        setFilteredOptions(options);
    }, [objects, selectedObjectId]);

    const onChange = useCallback(
        (key: keyof IPCC) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            let value: string | number | undefined | null = newValue;
            if (key === 'mountingHeight') {
                value = commonTools.matchNumbers(value, 2);
            } else if (key === 'marker') {
                value = commonTools.matchChars(value);
            }

            console.log('>>>>', newValue, value);

            selectedObjectId &&
                dispatch(
                    changeObjectParams({
                        key,
                        newValue: value,
                        id: selectedObjectId,
                    }),
                );
        },
        [dispatch, selectedObjectId],
    );

    const updateFilteredArray = (arr: object[]) => {
        setFilteredOptions(arr as { key: string; text: string }[]);
    };

    const filter = React.useMemo(() => {
        return (
            <FilterInput
                disabled={generalSettings.readOnly}
                inputArray={options}
                matchField={'text'}
                updateFilteredArray={updateFilteredArray}
            />
        );
    }, [options]);

    if (!objects) return null;
    const [selectedObject] = objects.filter((item) => item.frontId === selectedObjectId);

    const StackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const onClick = () => {
        dispatch(addPassPoint({ id: selectedObject.frontId, planScale: currentPlanData?.planScale }));
    };

    const dropdownStyles: Partial<IDropdownStyles> = {
        dropdown: { width: 300 },
    };

    const onSlaveChange = (
        event: FormEvent<HTMLDivElement>,
        option?: IDropdownOption | undefined,
        index?: number | undefined,
    ): void => {
        if (!option) return;
        dispatch(changeSlaveOf({ id: selectedObject.frontId, key: String(option.key) }));
    };

    const onSelectPassPoint = (passPointId: string) => () => {
        dispatch(selectPassPoint({ passPointId }));
    };

    const onDeleteIconClick = (passPointId: string) => () => {
        dispatch(deletePassPoint({ passPointId, frontId: selectedObject.frontId }));
    };

    const onPassPointChange =
        (passPointId: string) =>
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            newValue !== undefined &&
                newValue?.length < 30 &&
                dispatch(
                    renamePassPoint({
                        passPointId,
                        newValue: newValue || '',
                        frontId: selectedObject.frontId,
                    }),
                );
        };

    const objectsList = selectedObject?.passPoints
        ? Object.keys(selectedObject.passPoints).map((key) => {
              const { lineName } = selectedObject.passPoints[key];

              const selected = key === selectedPassPointId;

              return (
                  <ObjectWrapper key={key} selected={selected} onClick={onSelectPassPoint(key)}>
                      <StyledTextField
                          disabled={generalSettings.readOnly}
                          underlined
                          value={lineName}
                          onChange={onPassPointChange(key)}
                      />
                      {!generalSettings.readOnly && (
                          <FontIcon iconName="Cancel" className={classNames.salmon} onClick={onDeleteIconClick(key)} />
                      )}
                  </ObjectWrapper>
              );
          })
        : null;

    return selectedObject ? (
        <StyledStack tokens={StackTokens}>
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Marker')}
                value={String(selectedObject.marker)}
                onChange={onChange('marker')}
            />
            <TextField
                type="number"
                step={0.01}
                min={0}
                label={t('Mounting height (m)')}
                value={selectedObject.mountingHeight === 0 ? '0' : String(selectedObject.mountingHeight || '')}
                onChange={onChange('mountingHeight')}
            />
            <Label>{t('Followed by')}</Label>
            {filter}
            <Dropdown
                placeholder="Select an option"
                options={filteredOptions}
                onChange={onSlaveChange}
                selectedKey={selectedObject.followedBy === null ? 'null' : selectedObject.followedBy}
                disabled={generalSettings.readOnly}
            />
            <DefaultButton
                disabled={generalSettings.readOnly}
                text={t('+ Add passPoint')}
                onClick={onClick}
                allowDisabledFocus
            />
            {objectsList}
        </StyledStack>
    ) : null;
};

export default ObjectOptions;
