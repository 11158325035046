import React, { useContext } from 'react'; // Import the useDispatch function
import { EditorContext } from '../../../../Editor.context';
import { IIconProps, TooltipHost } from '@fluentui/react';
import { StyledIconButton, ToggleBackgroundWrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { generalReducerValues, toggleBackground } from '../../../../../../General.reducer';
import { useSelector, useDispatch } from 'react-redux';
import { theme } from '../../../../../../constants/theme';

const ToggleBackground = () => {
    const { themePrimary, white, neutralDark } = theme.palette;

    const { showBackgroundPlanImage } = useSelector(generalReducerValues);

    const { t } = useTranslation();
    const dispatch = useDispatch(); // Add the useDispatch hook to get the dispatch function

    const iconProps: IIconProps = {
        iconName: 'Nav2DMapView',
        styles: { root: { fontSize: 18, color: showBackgroundPlanImage ? white : themePrimary } },
    };

    const onToggleClick = () => {
        dispatch(toggleBackground());
    };

    return (
        <>
            <ToggleBackgroundWrapper showBackgroundPlanImage={showBackgroundPlanImage} onClick={onToggleClick}>
                <TooltipHost content={t('Toggle background plan')}>
                    <StyledIconButton iconProps={iconProps} />
                </TooltipHost>
            </ToggleBackgroundWrapper>
        </>
    );
};

export default ToggleBackground;
