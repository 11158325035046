import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    editorReducerValues,
    storeActiveLayerId,
    storeVisibleLayers,
    toggleVisible,
} from '../components/Editor/Editor.reducer';

import getUserInterfaceState from './getUserInterfaceState';
import { locationsReducerValues } from '../components/Locations/Locations.reducer';
import { plansReducerValues, storePlanToEdit } from '../components/Plans/Plans.reducer';
import { USER_INTERFACE_STATE } from '../constants/localStorageKeys';

import { ILayer } from '../components/Layers/layers.interfaces';

/**
 * Кастомный хук для работы с localStorage
 */
const useInitialData = (layers: ILayer[]) => {
    const { activeLayerId, visibleLayersIds } = useSelector(editorReducerValues);
    const { planToEdit, plansList } = useSelector(plansReducerValues);
    const { activeLocation } = useSelector(locationsReducerValues);

    const [isLocalStorageLayersReceived, setLocalStorageLayersReceived] = useState(false);
    const [isLocalStoragePlDataReceived, setLocalStoragePlDataReceived] = useState(false);

    const dispatch = useDispatch();

    /**
     * Сохранение id выбранного плана в localStorage
     */
    useEffect(() => {
        if (planToEdit?.planId && activeLocation?.id && isLocalStoragePlDataReceived) {
            let parsedUserInterfaceState = getUserInterfaceState();
            if (parsedUserInterfaceState) {
                if (!parsedUserInterfaceState['projectLocations']) {
                    parsedUserInterfaceState['projectLocations'] = {};
                }
                if (!parsedUserInterfaceState['projectLocations'][activeLocation.id]) {
                    parsedUserInterfaceState['projectLocations'][activeLocation.id] = {};
                }

                parsedUserInterfaceState['projectLocations'][activeLocation.id] = {
                    ...parsedUserInterfaceState['projectLocations'][activeLocation.id],
                    selectedPlanId: planToEdit.planId,
                };
            } else {
                parsedUserInterfaceState = {
                    projectLocations: {
                        [activeLocation.id]: {
                            selectedPlanId: planToEdit.planId,
                        },
                    },
                };
            }
            localStorage.setItem(USER_INTERFACE_STATE, JSON.stringify(parsedUserInterfaceState));
        }
    }, [planToEdit, activeLocation, isLocalStoragePlDataReceived]);

    /**
     * Сохранение выбранного слоя и видимых слоев в localStorage
     */
    useEffect(() => {
        if (isLocalStorageLayersReceived && activeLayerId !== 'measureCut') {
            let parsedUserInterfaceState = getUserInterfaceState();

            if (parsedUserInterfaceState) {
                parsedUserInterfaceState['layers'] = {
                    ...(parsedUserInterfaceState['layers'] || {}),
                    activeLayerId: activeLayerId,
                    visibleLayers: visibleLayersIds,
                };
            } else {
                parsedUserInterfaceState = {
                    layers: {
                        activeLayerId: activeLayerId,
                        visibleLayers: visibleLayersIds,
                    },
                };
            }
            localStorage.setItem(USER_INTERFACE_STATE, JSON.stringify(parsedUserInterfaceState));
        }
    }, [activeLayerId, visibleLayersIds, isLocalStorageLayersReceived]);

    /**
     * Запись данных состояния пользовательского интерфейса для активного и видимых слоев из localStorage
     */
    useEffect(() => {
        const parsedUserInterfaceState = getUserInterfaceState();

        if (parsedUserInterfaceState?.layers?.activeLayerId) {
            dispatch(storeActiveLayerId(parsedUserInterfaceState.layers.activeLayerId));
            if (parsedUserInterfaceState.layers?.visibleLayers?.length) {
                dispatch(storeVisibleLayers(parsedUserInterfaceState.layers.visibleLayers));
            } else {
                dispatch(toggleVisible(parsedUserInterfaceState.layers.activeLayerId));
            }
        } else if (layers && layers.length !== 0) {
            const id = layers[0].id;
            dispatch(storeActiveLayerId(id));
            dispatch(toggleVisible(id));
        }

        setLocalStorageLayersReceived(true);
    }, []);

    /**
     * Запись данных состояния пользовательского интерфейса для выбранного плана из localStorage
     */
    useEffect(() => {
        const parsedUserInterfaceState = getUserInterfaceState();

        if (parsedUserInterfaceState) {
            if (
                plansList?.length &&
                activeLocation?.id &&
                parsedUserInterfaceState.projectLocations?.[activeLocation.id]?.selectedPlanId
            ) {
                const currentPlan = plansList?.find(
                    (plan) =>
                        activeLocation?.id &&
                        plan.planId === parsedUserInterfaceState.projectLocations?.[activeLocation.id].selectedPlanId,
                );

                if (currentPlan) {
                    dispatch(storePlanToEdit({ planToEdit: currentPlan }));
                }
                setLocalStoragePlDataReceived(true);
            }
        } else {
            setLocalStoragePlDataReceived(true);
        }
    }, [activeLocation, plansList]);
};

export default useInitialData;
