import React, { FormEvent, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton, Stack, Callout, Text, IChoiceGroupOption, ChoiceGroup, Toggle } from '@fluentui/react';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { settingsReducerValues, storeObjNamingMode, toggleSnapToGrid } from '../../Settings.reducer';
import {
    styles,
    stackStyles,
    iconSettingsName,
    numericalSpacingStackTokens,
    classNamesIconButtonSettings,
} from './SettingsButton.style';
import LangSwitcher from '../LangSwitсher/LangSwitсher';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { displayOptions, snapToGridOptions } from './constants/constants';
import { TObjNamingMode } from '../../Settings.interfaces';

/**
 * Компонент обертка контекстного меню.
 * В него входит кнопка вызова панели и сама панель
 */
const SettingsButton: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const {
        generalSettings: { lang, snapToGrid, gridValue, readOnly, objNamingMode },
    } = useSelector(settingsReducerValues);
    const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
    const [isChecked, setIsChecked] = React.useState(snapToGrid ? true : false);
    const [radioGridValue, setRadioGridValue] = React.useState(gridValue);

    const buttonId = useId('settings-callout');
    const { t } = useTranslation();

    useHotkeys('g', () => {
        !readOnly && setIsChecked((prev) => !prev);
    });

    useEffect(() => {
        if (isChecked) {
            dispatch(toggleSnapToGrid(radioGridValue));
        } else {
            dispatch(toggleSnapToGrid(false));
        }
    }, [isChecked, radioGridValue]);

    const onChange = React.useCallback(
        (ev?: FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined): void => {
            setIsChecked(!!checked);
        },
        [],
    );

    const onSnapToGridChange = React.useCallback(
        (_: FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
            option && setRadioGridValue(Number(option.text));
        },
        [],
    );

    const onObjNamingModeChange = React.useCallback(
        (_: FormEvent<HTMLElement | HTMLInputElement> | undefined, option: IChoiceGroupOption | undefined): void => {
            option && dispatch(storeObjNamingMode(option.key as TObjNamingMode));
        },
        [],
    );

    return (
        <div>
            <IconButton
                id={buttonId}
                iconProps={iconSettingsName}
                styles={classNamesIconButtonSettings}
                title="GlobalNavButton"
                ariaLabel="GlobalNavButton"
                onClick={toggleIsCalloutVisible}
            />
            {isCalloutVisible ? (
                <Callout
                    className={styles.callout}
                    ariaLabelledBy={'callout-label'}
                    ariaDescribedBy={'callout-description'}
                    role="alertdialog"
                    gapSpace={0}
                    target={`#${buttonId}`}
                    onDismiss={toggleIsCalloutVisible}
                    setInitialFocus
                >
                    <div className={styles.header}>
                        <Text className={styles.title} id={'callout-label'}>
                            {t(`Settings`)}
                        </Text>
                    </div>
                    <Stack horizontal styles={stackStyles} tokens={numericalSpacingStackTokens}>
                        {t(`Language`)}:
                        <LangSwitcher lang={lang} />
                    </Stack>
                    <Stack tokens={numericalSpacingStackTokens}>
                        <ChoiceGroup
                            options={displayOptions.map((element) => ({ ...element, text: t(element.text) }))}
                            onChange={onObjNamingModeChange}
                            selectedKey={objNamingMode}
                            label={t(`Display`)}
                        />
                    </Stack>
                    {!readOnly && (
                        <>
                            <Stack horizontal styles={stackStyles} tokens={numericalSpacingStackTokens}>
                                <Checkbox label={t(`Snap to grid`)} checked={isChecked} onChange={onChange} />
                            </Stack>
                            {isChecked && (
                                <Stack horizontal styles={stackStyles} tokens={numericalSpacingStackTokens}>
                                    <ChoiceGroup
                                        selectedKey={String(gridValue)}
                                        options={snapToGridOptions}
                                        label={t(`Grid size in meters`)}
                                        onChange={onSnapToGridChange}
                                    />
                                </Stack>
                            )}
                        </>
                    )}
                </Callout>
            ) : null}
        </div>
    );
};

export default SettingsButton;
