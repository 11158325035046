import React from 'react';
import { iconClass, StyledFontIcon } from './RevertIcon.styles';

/**
 * 
 * @returns Компонента с мигающей иконкой для сохранения изменений в слое.
 */
export default function SaveIcon() {
    return <StyledFontIcon iconName="RevToggleKey" className={iconClass} />;
}
