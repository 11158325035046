import {
    DropdownMenuItemType,
    IDropdownOption,
    IDropdownProps,
    IDropdownStyleProps,
    IDropdownStyles,
    ISelectableOption,
    SearchBox,
} from '@fluentui/react';
import React from 'react';
import { JustText, SeachWrapper, StyledDropdown } from './styles';

const SearchableDropdown: React.FunctionComponent<IDropdownProps> = (props) => {
    const [searchText, setSearchText] = React.useState<string>('');

    function renderOption(option?: ISelectableOption): JSX.Element {
        return option?.itemType === DropdownMenuItemType.Header && option?.key === 'FilterHeader' ? (
            <SeachWrapper>
                <SearchBox
                    onChange={(ev, newValue) => {
                        newValue !== undefined && setSearchText(newValue);
                    }}
                    // underlined={true}
                    placeholder="Search options"
                />
            </SeachWrapper>
        ) : (
            <>{option?.text}</>
        );
    }

    const dropdownStyles: Partial<IDropdownStyles> = {
        // dropdown: { width: 300 },
        dropdownItemSelected: {
            selectors: {
                '&:before': {
                    content: '""',
                    position: 'absolute',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    width: '4px',
                    background: 'rgb(0, 120, 212)',
                },
            },
        },
    };

    return !props.disabled ? (
        <StyledDropdown
            {...props}
            options={[
                { key: 'FilterHeader', text: '-', itemType: DropdownMenuItemType.Header },
                { key: 'divider_filterHeader', text: '-', itemType: DropdownMenuItemType.Divider },
                ...props.options.filter((option) => {
                    return option.text.toLowerCase().includes(searchText.toLowerCase());
                }),
            ]}
            calloutProps={{ shouldRestoreFocus: false, setInitialFocus: false }} //not working
            onRenderOption={renderOption}
            onDismiss={() => setSearchText('')}
            dropdownWidth="auto"
            styles={dropdownStyles}
        />
    ) : (
        <JustText>{props?.options?.find((item) => item?.key === props?.selectedKey)?.text || ''}</JustText>
    );
};

export default SearchableDropdown;
