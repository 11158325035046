import { passWays_PCC_Tuple } from './components/PassWays_PCC';
import { passWays_Places_Tuple } from './components/PassWays_Places';
import { passWays_Perimeter_Tuple } from './components/PassWays_Perimeter';
import { passWays_Zones_Tuple } from './components/PassWays_Zones';
import { places_Zones_Tuple } from './components/Places_Zones';
import { passWays_Escalators_Tuple } from './components/PassWays_Escalators';
import { passWays_Lifts_Tuple } from './components/PassWays_Lifts';
import { passWays_Stairs_Tuple } from './components/PassWays_Stairs';
import { escalators_Zones_Tuple } from './components/Escalators_Zones';
import { escalators_Places_Tuple } from './components/Escalators_Places';
import { stairs_Zones_Tuple } from './components/Stairs_Zones';
import { stairs_Places_Tuple } from './components/Stairs_Places';
import { lifts_Zones_Tuple } from './components/Lifts_Zones';
import { lifts_Places_Tuple } from './components/Lifts_Places';

const tuples = [
    passWays_PCC_Tuple,
    passWays_Places_Tuple,
    passWays_Perimeter_Tuple,
    passWays_Zones_Tuple,
    places_Zones_Tuple,
    passWays_Escalators_Tuple,
    passWays_Lifts_Tuple,
    passWays_Stairs_Tuple,
    escalators_Zones_Tuple,
    escalators_Places_Tuple,
    stairs_Zones_Tuple,
    stairs_Places_Tuple,
    lifts_Zones_Tuple,
    lifts_Places_Tuple,
];

export default tuples;

// export { ObjectLabel, Anchors, Helper } from '../../tools/commonComponents';
export { commonTools } from '../../tools/commonTools';
