import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducerValues, storeIntersections } from '../../reducer';
import { CustomCell, CustomReactDataSheet, IntersectModalWrapper, IntersectTableWrapper } from './styles';
import { cloneDeep } from 'lodash';

/**
 * Компонент содержимое модального окна
 */
const Modal = ({ ...props }) => {
    const dispatch = useDispatch();
    const { intersections } = useSelector(reducerValues);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [selected, setSelected] = useState<any>({ start: null, end: null });

    const namesMap: {
        [x: string]: string;
    } = {
        passway_marker: 'passWayMarker',
        passway_name: 'passWayName',
        escalator_marker: 'escalatorMarker',
        escalator_name: 'escalatorName',
        external_traffic: 'externalTraffic',
        line_name: 'lineName',
        direction: 'direction',
    };

    const grid: any[] = [
        [
            { value: 'line_name', width: '20%', readOnly: true },
            { value: 'escalator_marker', width: '20%', readOnly: true },
            { value: 'escalator_name', width: '15%', readOnly: true },
            { value: 'external_traffic', width: '10%', readOnly: true },
            { value: 'passway_marker', width: '10%', readOnly: true },
            { value: 'passway_name', width: '15%', readOnly: true },
            { value: 'direction', width: '10%', readOnly: true },
        ],
    ];

    intersections.forEach((item) => {
        const result: Array<{ [x: string]: string }> = [];
        grid[0].forEach((row: { value: string }) => {
            result.push({ value: item.info[namesMap[row.value]] });
        });
        grid.push(result);
    });

    const onMouseUp = (value: boolean) => () => setIsMouseDown(value);

    const onMouseOver = (props: any) => (e: any) => {
        if (isMouseDown) {
            setSelected((prevState: any) => {
                const newState = cloneDeep(prevState);
                newState.end = { i: props.row, j: props.col };
                return newState;
            });
            props.onMouseOver(e);
        }
    };

    const onMouseDown = (props: any) => (e: any) => {
        setIsMouseDown(true);
        setSelected({ start: { i: props.row, j: props.col }, end: { i: props.row, j: props.col } });
        props.onMouseDown(e);
    };

    const cellRenderer = (props: any) => {
        return (
            <CustomCell
                onMouseDown={onMouseDown(props)}
                onMouseUp={onMouseUp(false)}
                onMouseMove={onMouseOver(props)}
                className={props.className}
            >
                {props.children}
            </CustomCell>
        );
    };

    const wrapperClickHandler = () => {
        setSelected({ start: null, end: null });
    };

    return (
        <IntersectModalWrapper onClick={wrapperClickHandler}>
            <IntersectTableWrapper>
                <CustomReactDataSheet
                    selected={selected}
                    cellRenderer={cellRenderer}
                    data={grid}
                    valueRenderer={(cell: any) => String(cell.value)}
                />
            </IntersectTableWrapper>
        </IntersectModalWrapper>
    );
};

export default Modal;
