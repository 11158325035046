import tools from './components/Tools';
import draw from './components/Draw';
import controls from './components/Controls';
import contextMenu from './components/ContextMenu';

export const navPolygonsLayer = {
    id: 'nav_polygons_layer',
    tools,
    draw,
    controls,
    contextMenu
};
