import { IStackStyles, Stack } from '@fluentui/react';
import { IStackProps } from '@fluentui/react/lib/Stack';
import styled from 'styled-components';

export const topStackStyles: Partial<IStackProps> = {
    tokens: { childrenGap: 15, padding: '0 0 10px 0' },
};

export const StStack = styled(Stack)`
    display: flex;
    align-items: center;
    padding: 10px 0;
`;
