/**
 * Словарь соответствий полей локации между бэком и фронтом.
 */
export const locationsMapping: Map<string, string> = new Map<string, string>([
    ['id', 'id'],
    ['address', 'address'],
    ['date_from', 'date_from'],
    ['date_to', 'date_to'],
    ['latitude', 'latitude'],
    ['longitude', 'longitude'],
    ['map_service_link', 'map_service_link'],
    ['name', 'name'],
    ['timezone', 'timezone'],
 
]);

/**
 * Словарь соответствий полей копии локации между бэком и фронтом.
 */
export const locationsCloneMapping: Map<string, string> = new Map<string, string>([
    ['cloneId', 'location_id'],
    ['name', 'name'],
    ['geoPosition', 'point'],
]);

/**
 * Словарь соответствий полей плана между бэком и фронтом.
 */
export const plansMapping: Map<string, string> = new Map<string, string>([
    ['locationId', 'location_id'],
    ['length', 'length'],
    ['geo2plan', 'geo2plan'],
    ['plan2geo', 'plan2geo'],
    ['planScale', 'scale'],
    ['planWidth', 'width'],
    ['planHeight', 'height'],
    ['planId', 'id'],
    ['floor', 'floor'],
    ['image', 'image'],
    ['isMain', 'is_main'],
    ['isAnchor', 'is_anchor'],
    ['activeFrom', 'active_from'],
    ['notes', 'notes'],
    ['line', 'line'],
    ['measureCutLength', 'length'],
    ['geoTopLeft', 'geo_top_left'],
    ['geoTopRight', 'geo_top_right'],
    ['geoBottomLeft', 'geo_bottom_left'],
    ['geoBottomRight', 'geo_bottom_right'],
]);

/**
 * Словарь соответствий полей слоя между бэком и фронтом.
 */
export const layersMapping: Map<string, string> = new Map<string, string>([
    ['createdAt', 'created_at'],
    ['floor', 'floor'],
    ['mainPlanId', 'main_plan_id'],
    ['data', 'data'],
    ['layerType', 'layer_type'],
]);
