import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

import { ITracksWifiReducer, IObjects, ITrack } from './tracksWifi.interfaces';
import { IPlan } from '../../../Plans/Plans.interfaces';
import { commonTools } from '../../../../tools/commonTools';
import { cloneDeep } from 'lodash';
import { IExtendedPlan, IGeneralSettings, IHotAddObject } from '../../layers.interfaces';

const initialState: ITracksWifiReducer = {
    layerAlias: 'tracks_wifi_layer',
    selectedObjectId: null,
    activeToolId: null,
    objects: [],
    createdAt: undefined,
    currentPlanData: null,
    showLabels: false,
    generalSettings: {} as IGeneralSettings,
    trackFrontId: '',
    indexSelectedObjects: null,
    selectedObjects: null,
    indexPoint: null,
    stepValue: 0,
    hotAddObject: null,
};

export const TracksWifiReducer = createSlice({
    name: 'TracksWifiReducer',
    initialState,
    reducers: {
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /***
         * Созжание нового помещений
         * @param state
         * @param action
         */
        storeNewTrack: (state, action: PayloadAction<Array<Array<number>>>) => {
            const objects = cloneDeep(state.objects);
            const objects2: IObjects = cloneDeep(state.objects);
            const id: string = `track_wifi:${commonTools.generateId()}`;
            let duration_mask: number[] = [];
            action.payload.forEach((item) => {
                duration_mask.push(1);
            });

            objects2.push({
                duration_mask: duration_mask,
                front_id: id,
                points: {
                    type: 'LineString',
                    coordinates: action.payload,
                },
                track_type: 'tracking',
            });

            // objects2.forEach((item, index) => {
            //     placesIndexByPlacesFrontId![item.front_id] = index;
            // });

            state.objects = objects2;
        },

        /**
         * Изменение координат выделеного объекта
         * @param state
         * @param action
         */
        changeCoords: (state, action: PayloadAction<{ coordsS: number[][] | undefined; front_id: string }>) => {
            const { coordsS, front_id } = action.payload;
            const objects = cloneDeep(state.objects);
            objects.forEach((item, index) => {
                if (item.front_id === front_id) {
                    item.points.coordinates = coordsS!;
                }
            });

            state.objects = objects;
        },

        /***
         * Запись в стор загруженных с сервера объектов
         * @param state
         * @param action
         */
        storeInitialObjects: (state, action: PayloadAction<Array<ITrack>>) => {
            // let placesIndexByPlacesFrontId: { [key: string]: number } | null = {};
            // action.payload.forEach((item, index) => {
            //     placesIndexByPlacesFrontId![item.front_id] = index;
            // });

            state.objects = action.payload;
            // state.placesIndexByPlacesFrontId = placesIndexByPlacesFrontId;
        },

        /**
         * Запись в стор поля createdAt
         * @param state
         * @param action
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Переключение показа маркеров на объектах
         * @param state
         * @param action
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Добавление новых точек в полигон
         * @param state
         * @param action
         */
        addAnchor: (
            state,
            action: PayloadAction<{ anchorCoords?: number[] | undefined; objectId?: string | undefined }>,
        ) => {
            const { anchorCoords, objectId } = action.payload;
            const objects = cloneDeep(state.objects);
            let objectsTemp = [];

            const tracks = objects.map((element) => {
                if (element.front_id !== objectId) {
                    return element;
                } else {
                    const coords = element.points.coordinates;
                    let index = null;
                    let statusAndAnchors = false;
                    coords.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, anchorCoords!)) {
                                index = i;
                                statusAndAnchors = false;
                            }
                        } else if (i === 0) {
                            if (commonTools.between(arr[arr.length - 1], point, anchorCoords!)) {
                                statusAndAnchors = true;
                                index = null;
                            }
                        }
                    });

                    if (index !== null) {
                        coords.splice(index, 0, anchorCoords!);
                    } else if (statusAndAnchors) {
                        coords.push(anchorCoords!);
                    }
                    return {
                        ...element,
                        points: {
                            ...element.points,
                            coordinates: coords,
                        },
                    };
                }
            });

            objectsTemp = tracks;
            //
            state.objects = objectsTemp;
        },

        /**
         * Выбор объекта
         * @param state
         * @param action
         */
        selectTrack: (state, action: PayloadAction<{ trackFrontId: string | undefined }>) => {
            const { trackFrontId } = action.payload;
            const objects = cloneDeep(state.objects);
            let selectedObjects: ITrack | null = null;

            objects.forEach((item, index) => {
                if (trackFrontId === item.front_id) {
                    selectedObjects = item;
                }
            });

            state.selectedObjects = selectedObjects;
            state.trackFrontId = trackFrontId!;
        },

        /**
         * Уделение объекта
         * @param state
         * @param action
         */
        deleteTrack: (state, action: PayloadAction<{ trackFrontId: string }>) => {
            const { trackFrontId } = action.payload;
            let objects = cloneDeep(state.objects);
            // let placesIndexByPlacesFrontId = cloneDeep(state.placesIndexByPlacesFrontId);
            let indexTemp: number = 0;

            // placesIndexByPlacesFrontId = {};
            objects.forEach((item, index) => {
                if (trackFrontId === item.front_id) {
                    indexTemp = index;
                }
            });

            objects.splice(indexTemp, 1);

            state.selectedObjects = null;
            state.objects = objects;
            state.trackFrontId = '';
            state.indexPoint = null;
            // state.placesIndexByPlacesFrontId = placesIndexByPlacesFrontId;
        },

        /**
         * Очистка выделеного объекта
         * @param state
         * @param action
         */
        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjects = null;
            state.trackFrontId = '';
            state.indexPoint = null;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        selectPoint: (state, action: PayloadAction<number | null>) => {
            state.indexPoint = action.payload;
        },
        selectStepPoint: (state, action: PayloadAction<number>) => {
            let objects = cloneDeep(state.objects);
            let selectedObjects = cloneDeep(state.selectedObjects);
            let trackFrontId = cloneDeep(state.trackFrontId);
            let indexPoint = cloneDeep(state.indexPoint);

            objects.forEach((item, index) => {
                if (item.front_id === trackFrontId) {
                    item.duration_mask[indexPoint!] = action.payload;
                }
            });
            selectedObjects!.duration_mask[indexPoint!] = action.payload;

            state.objects = objects;
            state.selectedObjects = selectedObjects;
            state.stepValue = action.payload;
            // state.indexPoint = action.payload;
        },
        changeCoordsFullLayer: (state, action: PayloadAction<IObjects>) => {

            state.objects = action.payload;
        },
    },
});

export const {
    toggleTool,
    changeCoords,
    storeGeneralSettings,
    storeNewTrack,
    storeInitialCreatedAt,
    storeInitialObjects,
    toggleShowLabels,
    addAnchor,
    storeCurrentPlanData,
    selectTrack,
    deleteTrack,
    clearSelectedObjects,
    selectPoint,
    selectStepPoint,
    storeHotAddObject,
    changeCoordsFullLayer
} = TracksWifiReducer.actions;

export const trackingTracksValues = (state: RootState) => state.TracksWifiReducer;

export default TracksWifiReducer.reducer;
