import { FC } from 'react';
import { ITableCustomComponentProps } from '../../store';
import React from 'react';
import { Stack } from '@fluentui/react';
import { Text } from './styles';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../../../../constants/theme';
import { commonTools } from '../../../../../../tools/commonTools';

const AreaHistory: FC<ITableCustomComponentProps> = (props) => {
    const { t } = useTranslation();

    const data = props.cell.dataObject?.area_history as undefined | { [x: string]: unknown }[];

    const keysMap: { [x: string]: { [x: string]: string } } = {
        date_from: {
            text: 'Date from',
            key: 'date_from',
        },
        date_to: {
            text: 'Date to',
            key: 'date_to',
        },
        area: {
            text: 'Area',
            key: 'area',
        },
    };

    if (!data) return null;

    return (
        <Stack verticalAlign="start" tokens={{ childrenGap: 10 }} style={{ overflow: 'hidden' }}>
            {data.map((item, index) => {
                return (
                    <Stack
                        style={{ borderTop: index !== 0 ? `1px solid ${theme.palette.neutralTertiary}` : undefined }}
                        key={JSON.stringify(item['date_from']) + JSON.stringify(item['date_to']) + index}
                        tokens={{ childrenGap: 5, padding: index !== 0 ? '10px 0 0 0' : undefined }}
                        verticalAlign="start"
                        wrap={true}
                    >
                        {Object.entries(item).map(([key, value]) => {
                            return (
                                <Text key={key}>
                                    <span>{t(keysMap[key]?.text || key)}:&nbsp;</span>
                                    <div>
                                        {typeof value !== 'string'
                                            ? commonTools.getReadableStringRecursive(value)
                                            : value}
                                    </div>
                                </Text>
                            );
                        })}
                    </Stack>
                );
            })}
        </Stack>
    );
};

export default AreaHistory;
