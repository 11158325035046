import React, { useContext } from 'react';
import {
    HeaderWrapper,
    NavMenuWrapper,
    SettingsButtonWrapper,
    StyledLink,
    VersionsVarning,
    Container,
    VersionData,
    menuStyles,
    StIconButton,
} from './Header.styles';
import { settingsReducerValues } from '../Settings/Settings.reducer';
import { locationsReducerValues } from '../Locations/Locations.reducer';
import { plansReducerValues, storePdfModalStatus } from '../Plans/Plans.reducer';
import { versionsReducerValues } from '../Versions/Versions.reducer';
import { GeneralContext } from '../../General.context';
import { useTranslation } from 'react-i18next';
import Settings from '../Settings/Settings';
import Logo from '../Logo/Logo';
import { useDispatch, useSelector } from 'react-redux';
import SnapToGridStatus from './components/SnapToGridStatus/SnapToGridStatus';
import { Title } from './components/Title/Title';
import { useMemo } from 'react';
import VersionsHisory from './components/VersionsHisory/VersionsHisory';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { ILocationState } from './Header.interfaces';
import { editorReducerValues, toggleVersionsHistoryModalStatus } from '../Editor/Editor.reducer';
import { generalReducerValues } from '../../General.reducer';
import { IVersionsHistoryModalStatus } from '../Editor/Editor.interfaces';
import { DateTime } from 'luxon';
import { CommandBarButton, DirectionalHint, IconButton, TooltipHost } from '@fluentui/react';
import { Breadcrumbs } from './components/Crumbs/Crumbs';
import { useCheckAllNeededData } from '../../tools/useCheckAllNeededData';

/**
 * Компонент Шапки приложения.
 */
const Header = React.memo((props) => {
    const { theme } = useContext(GeneralContext);
    const { themeDarker, themeLighterAlt } = theme.palette;
    const { t } = useTranslation();
    const {
        generalSettings: { snapToGrid, readOnly },
    } = useSelector(settingsReducerValues);
    const { selectedChapter } = useSelector(editorReducerValues);
    const { projectLocationsList, activeLocation } = useSelector(locationsReducerValues);
    const { activeVersionId, actualVersionId, versionsById } = useSelector(versionsReducerValues);
    const {
        current,
        urls: { LOGOUT_URL, AUTH_URL },
    } = useSelector(generalReducerValues);
    const location = useLocation();
    const [showVersionHistory, setShowVersionsHistory] = useState(false);
    const [showUploadPdfButton, setShowUploadPdfButton] = useState(false);
    const dispatch = useDispatch();
    useCheckAllNeededData();

    useEffect(() => {
        const pathnameRegExp = /\/plan\/\d{1,}/gm;

        if (pathnameRegExp.test(location.pathname)) {
            setShowVersionsHistory(true);
        } else {
            setShowVersionsHistory(false);
        }
    }, [location]);

    useEffect(() => {
        const pathnameRegExp = /\/plans\/\d{1,}/gm;
        if (pathnameRegExp.test(location.pathname)) {
            setShowUploadPdfButton(true);
        } else {
            setShowUploadPdfButton(false);
        }
    }, [location]);

    const currentLocation = useMemo(() => {
        return projectLocationsList.filter((item) => item.id === activeLocation?.id)[0];
    }, [activeLocation?.id, projectLocationsList]);

    const showVersionsWarning = useMemo(() => {
        return activeVersionId !== actualVersionId;
    }, [activeVersionId, actualVersionId]);

    const toggleVersionsHistoryModalStatusTrans = (payload: IVersionsHistoryModalStatus) => {
        dispatch(toggleVersionsHistoryModalStatus(payload));
    };

    const from = activeVersionId
        ? DateTime.fromISO(versionsById[activeVersionId].date_from as string).toLocaleString(DateTime.DATE_MED)
        : '';
    const to =
        activeVersionId && versionsById[activeVersionId].date_to === null
            ? 'now'
            : !activeVersionId
            ? ''
            : DateTime.fromISO(versionsById[activeVersionId].date_to as string).toLocaleString(DateTime.DATE_MED);

    const onLogout = () => {
        localStorage.removeItem('xtoken');
        window.location.replace(`${LOGOUT_URL}?next=/admin/login/?next=/sso/ajax-token?next=` + window.location.href);
    };

    const logoutItems = [
        {
            key: 'logout',
            text: t('Logout'),
            onClick: () => onLogout(),
            // iconProps: { iconName: 'Mail' }
        },
    ];

    const logoutMenuProps = {
        // alignTargetEdge: false,
        // coverTarget: true,
        directionalHint: DirectionalHint.bottomRightEdge,
        styles: menuStyles,
        items: logoutItems,
    };

    // const onPdfUploadClick = () => {
    //     dispatch(storePdfModalStatus({ show: true }));
    // };

    return (
        <HeaderWrapper bgcolor={'rgb(233,237,241)'} color={themeDarker}>
            <NavMenuWrapper>
                <Logo />
                {/* <StyledLink to="/">{t('Locations')}</StyledLink> */}
                <Breadcrumbs />
            </NavMenuWrapper>
            <Container>
                <div className="location">
                    <Title selectedChapter={selectedChapter} currentLocation={activeLocation} />
                    {from !== '' && !readOnly && <VersionData>{`(${from} - ${to})`}</VersionData>}
                </div>
                {showVersionsWarning && <VersionsVarning>{t('Not actual version')}</VersionsVarning>}
            </Container>
            <SettingsButtonWrapper>
                {/* {showUploadPdfButton && (
                    <TooltipHost content={t('Upload new plan request')}>
                        <StIconButton iconProps={{ iconName: 'ViewOriginal' }} onClick={onPdfUploadClick} />
                    </TooltipHost>
                )} */}
                {/* {!readOnly && <SnapToGridStatus snapToGrid={snapToGrid} />} */}
                <Settings />
                {showVersionHistory && (
                    <VersionsHisory toggleVersionsHistoryModalStatusTrans={toggleVersionsHistoryModalStatusTrans} />
                )}
                {current?.username && (
                    <CommandBarButton text={current.username as string} menuProps={logoutMenuProps} />
                )}
            </SettingsButtonWrapper>
        </HeaderWrapper>
    );
});

export default Header;
