import { useDeferredValue, useEffect, useState, useTransition } from 'react';
import { ICell, ITableDataItem, ITableListItem } from '../TablesPanel.interfaces';
import useTable from './useTable';
import { orderBy, sortBy } from 'lodash';
import { commonTools } from '../../../../Layers/layers';
import { tablesPanelReducerValues } from '../TablesPanel.reducer';
import { useSelector } from 'react-redux';

const useGrid = (): { isPending: boolean; grid: ICell[][]; table: ITableListItem | undefined } => {
    const { filters, sorting } = useSelector(tablesPanelReducerValues);

    const [initialGrid, setInitialGrid] = useState<ICell[][]>([]);
    const [grid, setGrid] = useState<ICell[][]>([]);
    const [isPending, startTransition] = useTransition();
    const deferredFilters = useDeferredValue(filters);

    const deferredGrid = useDeferredValue(grid);

    const table = useTable();

    /**
     * Получение начальной таблицы
     */
    useEffect(() => {
        if (table && table.tableData?.[0]) {
            const result: ICell[][] = [];

            const header = Object.keys(table.tableData[0]).map((key) => {
                return { value: key, readOnly: true, types: ['header'], colName: key };
            });
            result.push(header);

            const headerColumns = header.map((item) => item.colName);
            const body = table.tableData.map((item: ITableDataItem) => {
                return sortBy(Object.keys(item), (key) => headerColumns.indexOf(key)).map((key: string) => {
                    const val = item[key as string];
                    const value = commonTools.isObject(val) ? JSON.stringify(val) : String(val);
                    const types: string[] = ['cell'];
                    if (key === 'date_from' || key === 'date_to') {
                        types.push('date');
                    }
                    if (commonTools.isObject(val)) {
                        types.push('object');
                    }
                    const cell: ICell = {
                        value,
                        types,
                        colName: key,
                        readOnly: true,
                        tableDataItem: item,
                    };
                    return cell;
                });
            });
            result.push(...body);

            startTransition(() => {
                setInitialGrid(result);
                setGrid(result);
            });
        } else {
            startTransition(() => {
                setInitialGrid([]);
                setGrid([]);
            });
        }
    }, [table]);

    /**
     * Фильтрация таблицы
     */
    useEffect(() => {
        if (initialGrid) {
            if (Object.keys(deferredFilters).length) {
                setGrid((prevState) => {
                    if (prevState.length) {
                        const filteredBody = prevState.slice(1).filter((item) => {
                            for (let key in deferredFilters) {
                                const value = item.find((cell) => cell.colName === key)?.value as
                                    | string
                                    | undefined
                                    | null;
                                if (
                                    deferredFilters[key]?.filter &&
                                    !deferredFilters[key]?.mode &&
                                    !JSON.stringify(value)
                                        ?.toLowerCase()
                                        ?.includes(deferredFilters[key]?.filter?.toLowerCase())
                                ) {
                                    return false;
                                } else if (
                                    deferredFilters[key]?.filter &&
                                    deferredFilters[key]?.mode &&
                                    deferredFilters[key]?.mode === 'strict'
                                ) {
                                    if (value !== deferredFilters[key]?.filter) {
                                        return false;
                                    }
                                }
                            }
                            return true;
                        });
                        return [prevState[0], ...filteredBody];
                    }
                    return prevState;
                });
            } else {
                setGrid(initialGrid);
            }
        }
    }, [deferredFilters, initialGrid]);

    /**
     * Сортировка таблицы
     */
    useEffect(() => {
        if (initialGrid) {
            if (sorting.colName) {
                setGrid((prevState) => {
                    if (prevState.length) {
                        const sortedBody = orderBy(
                            prevState.slice(1),
                            (row) => {
                                const cell = row.find((cell) => cell.colName === sorting.colName);
                                return cell?.tableDataItem ? cell.tableDataItem[sorting.colName || ''] : cell?.value;
                            },
                            sorting.order === 1 ? 'asc' : 'desc',
                        );
                        return [prevState[0], ...sortedBody];
                    }
                    return prevState;
                });
            } else {
                setGrid(initialGrid);
            }
        }
    }, [initialGrid, sorting]);

    return { isPending, grid: deferredGrid, table };
};

export default useGrid;
