import { theme } from '../../constants/theme';
import styled from 'styled-components/macro';
import { Checkbox, IDropdownStyles, IIconProps } from '@fluentui/react';

export const MainWrapper = styled.div<{ collapsed: boolean }>`
    transform: ${(props) => (!props.collapsed ? 'translate(0px)' : 'translate(calc(100% - 30px))')};
    transition: transform 0.2s ease-out;
    box-sizing: border-box;
    box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.3);
    padding: ${(p) => (p.collapsed ? '20px 0' : '20px')};
    position: absolute;
    top: 50px;
    /* left: ${(p) => (p.collapsed ? 'calc(100% - 30px)' : '0px')}; */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;
    height: ${(p) => (p.collapsed ? '80px' : 'calc(100% - 50px)')};
    background-color: white;
    z-index: 200;
    * {
        box-sizing: border-box;
    }
`;
export const StyledCheckbox = styled(Checkbox)`
    width: 200px;
    margin-left: 10px;
`;
export const Header = styled.div`
    width: 100%;
    display: flex;
    margin-bottom: 30px;
`;
export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
`;
export const WorkArea = styled.div`
    height: calc(100% - 50px);
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${theme.spacing.s2};
`;
export const ItemWrapper = styled.div<{ bgColor: string }>`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* .floor {
        :first-child {
            .MuiTreeItem-label {
                background-color: ${(p) => p.bgColor};
            }
        }
    } */
`;
export const Panel = styled.div`
    height: calc(100% - 0px);
    overflow: hidden;
`;

export const IconsWrapper = styled.div<{ collapsed: boolean }>`
    display: flex;
    justify-content: ${(p) => (p.collapsed ? 'flex-start' : 'flex-end')};
    width: 100%;
`;

const {
    spacing: { l1, l2, s1, s2, m },
} = theme;

/**
 * Стили для кнопки закрытия модалки.
 */
export const iconButtonStyles = {
    root: {
        color: theme.palette.themePrimary,
        // marginLeft: 'auto',
        marginTop: s2,
        marginBottom: s2,
        selectors: {
            i: { fontSize: '20px !important' },
        },
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const cancelIcon: IIconProps = { iconName: 'Cancel' };
export const flickRight: IIconProps = { iconName: 'FlickRight' };
export const flickLeft: IIconProps = { iconName: 'Flickleft' };

export const dropdownStyles: Partial<IDropdownStyles> = { root: { width: '100%' } };
