import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { IEngineeringModalProps } from './EngineeringModal.interfaces';
import { Container, Footer, MainWrapper, StCheckbox, StStack, StTextField, Wrapper } from './EngineeringModal.styles';
import { setReadOnlyMode } from '../../../../General.reducer';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

/**
 * Компонента Инженерная модалка.
 */
const EngineeringModal: React.FC<IEngineeringModalProps> = React.memo(({ modalData, onOKClick, ...props }) => {
    const [localBackend, setLocalBackend] = useState(modalData);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const stackTokens = { childrenGap: 10 };

    const onUrlChange =
        (key: string) =>
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
            if (newValue !== undefined) {
                const loc = { ...localBackend, [key]: { checked: localBackend[key].checked, url: newValue } };
                setLocalBackend({ ...loc });
            }
        };

    const onCheckChange =
        (key: string) =>
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
            if (checked !== undefined) {
                const loc = { ...localBackend, [key]: { checked, url: localBackend[key].url } };
                setLocalBackend({ ...loc });
            }
        };

    const handleOKClick = (options: typeof modalData) => () => {
        onOKClick(options);
    };

    const readOnlyClick = () => {
        dispatch(setReadOnlyMode());
    };

    return (
        <MainWrapper>
            <Container>
                <StStack tokens={{ childrenGap: 15 }}>
                    {Object.keys(localBackend).map((key, i) => {
                        const checked = localBackend[key].checked;
                        return (
                            <Wrapper key={`${i} - ${key}`}>
                                <StCheckbox checked={checked} onChange={onCheckChange(key)} />
                                <StTextField
                                    label={key}
                                    disabled={!checked}
                                    value={localBackend[key].url || ''}
                                    onChange={onUrlChange(key)}
                                />
                            </Wrapper>
                        );
                    })}
                </StStack>
            </Container>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <DefaultButton text={t('Read Only Mode')} width={'100px'} onClick={readOnlyClick} />
                    <PrimaryButton text={'OK'} width={'100px'} onClick={handleOKClick({ ...localBackend })} />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default EngineeringModal;
