import { AnimationStyles, DefaultPalette, IDropdownStyles, IStackStyles, IStackTokens } from '@fluentui/react';
import styled, { keyframes } from 'styled-components/macro';
import { sizes } from '../../../../../../constants/sizes';
import { theme } from '../../../../../../constants/theme';

export const EditorWrapper = styled.section`
    height: calc(100vh - ${sizes.HEADER_HEIGHT}px);
    width: 100%;
    overflow: hidden;
    display: grid;
    /* grid-template-columns: ${sizes.TOOLS_PANEL_WIDTH + 'px auto ' + sizes.CONTROL_PANEL_WIDTH + 'px'}; */
    grid-template-columns: ${'auto ' + sizes.CONTROL_PANEL_WIDTH + 'px'};
`;

const appearence = keyframes`
  0% { top: -800px; }
  100% { top: 10px; }
`;
/**
 * Компонент Обертка контекста выделенного объекта.
 */
export const Wrapper = styled.div`
    margin-top: 10px;
    width: 700px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 10px;
    background-color: ${theme.palette.white};
    animation: ${appearence} 0.4s ease-in-out;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
`;

export const DropdownWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Header = styled.div`
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
`;

/**
 * Компонент Стилизация библиотечного компонента.
 */
export const StyledText = styled.p`
    margin: 0;
    padding: 0;
    color: ${() => theme.palette.themePrimary};
    font-size: ${() => theme.fonts.large + 'px'};
    margin-bottom: 10px;
`;

export const ItemWrapper = styled.div`
    display: grid;
    grid-template-columns: minmax(0, 3fr) minmax(0, 3fr) minmax(0, 6fr) minmax(0, 4fr);
    grid-gap: 5px;
    margin-bottom: 5px;
`;

export const TableWrapper = styled.div`
    max-height: 300px;
    overflow: auto;
    margin: 10px 0;
    padding: 5px 0;
    border-top: 1px solid rgba(0, 0, 0, 0.3);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;
export const PlaceWasActiveWrapper = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;

    max-width: 300px;
    max-height: 300px;
    overflow: auto;
    margin: 0px;
    padding: 5px 0;
    padding-bottom: 20px;
    margin-bottom: 10px;
    /* border-bottom: 1px solid rgba(0, 0, 0, 0.3); */
    font-size: 14px;
`;

export const AllInfoTable = styled.table`
    font-size: 12px;
    th {
        text-align: left;
    }
    * {
        padding: 0 10px;
        empty-cells: show;
        min-height: 10px;
    }
    .someclass {
        white-space: nowrap;
        min-width: 10px !important;
        vertical-align: top;
    }
`;

export const buttonStyles = {
    root: {
        // width: '100%',
        marginTop: 10,
        narginLeft: 'auto',
    },
};

export const stackStyles: IStackStyles = {
    root: {
        // borderStyle: 'solid',
        // borderWidth: 1,
        width: '100%',
        // borderColor: DefaultPalette.neutralTertiary,
        ...AnimationStyles.fadeIn200,
    },
};

export const stackTokens: IStackTokens = {
    childrenGap: 5,
};

export const dropdownStyles: Partial<IDropdownStyles> = { root: { width: '100%' } };
