import React from 'react';
import './Accordion.styles.scss';
import AccordionItem from './components/AccordionItem/AccordionItem';
import { IAccordionItemProps } from './components/AccordionItem/AccordionItem.interfaces';
import { AccordionWrapper } from './styles';

/**
 * Компонент аккордеон.
 * Список элементов. Все пропсы, которые принимает, прокидывает вниз по дереву элементам списка.
 */
const Accordion = ({ visible = true, ...props }) => {
    return (
        <AccordionWrapper visible={visible}>
            <ul {...{ className: 'accordion-list' }}>
                {props.data.map((item: IAccordionItemProps) => {
                    return (
                        <li {...{ className: 'accordion-list__item', key: item.title }}>
                            <AccordionItem {...item} {...props} />
                        </li>
                    );
                })}
            </ul>
        </AccordionWrapper>
    );
};

export default Accordion;
