import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontIcon } from '@fluentui/react/lib/Icon';

import { editorReducerValues, toggleChapter } from '../../../../Editor.reducer';
import { settingsReducerValues } from '../../../../../Settings/Settings.reducer';
import { ChaptersWrapper, StyledPivot } from './styles';
import { PivotItem, TooltipHost } from '@fluentui/react';
import { controlPanelChaptersMap } from '../../../../../../constants/controlPanelChaptersMap';

const Chapters = () => {
    const { selectedChapter } = useSelector(editorReducerValues);
    const {
        generalSettings: { readOnly, displayTables, displayFpcMonitoring },
    } = useSelector(settingsReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            dispatch(toggleChapter(item.props.itemKey!));
        }
    };

    const getTabId = (itemKey: string) => {
        return `ShapeColorPivot_${itemKey}`;
    };

    const chapters = [
        { key: 'layers', iconName: 'MapLayers', visible: true },
        { key: 'tuples', iconName: 'ReleaseGateCheck', visible: true },
        { key: 'tenants', iconName: 'ReminderPerson', visible: !readOnly },
        { key: 'tables', iconName: 'ExcelLogoInverse16', visible: displayTables },
        { key: 'monitoring', iconName: 'Zoom', visible: displayFpcMonitoring },
        { key: 'sync', iconName: 'Sync', visible: !readOnly },
    ];

    const pivotItems = chapters
        .filter((item) => item.visible)
        .map((item) => {
            return <PivotItem key={item.key} onRenderItemLink={() => onRenderItemLink(item.key)} itemKey={item.key} />;
        });

    const onRenderItemLink = (key: string) => {
        const [element] = chapters.filter((item) => item.key === key);
        return (
            <TooltipHost content={t(controlPanelChaptersMap[element.key] || element.key)}>
                <FontIcon iconName={element.iconName} />
            </TooltipHost>
        );
    };

    return (
        <ChaptersWrapper>
            <StyledPivot
                selectedKey={selectedChapter}
                // eslint-disable-next-line react/jsx-no-bind
                onLinkClick={handleLinkClick}
                headersOnly={true}
                getTabId={getTabId}
            >
                {pivotItems}
            </StyledPivot>
        </ChaptersWrapper>
    );
};

export default Chapters;
