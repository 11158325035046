import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getTheme } from '@fluentui/react/lib/Styling';

import { generalReducerValues } from '../../../../General.reducer';
import StackHorizontal from '../../../StackHorizontal/StackHorizontal';
import FilterInput from '../../../FilterInput/FilterInput';
import { accountsReducerValues } from './Accounts.reducer';
import { settingsReducerValues } from '../../../Settings/Settings.reducer';
import List from './components/List/List';
import { fetchUsers } from './Accounts.reducer';
import { TAccountWithProduct } from './Accounts.interfaces';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import Modal from '../../../Modals/Modals';
import NewUserModal from './components/NewUserModal/NewUserModal';
import { postRequest } from '../../../../tools/api';
import ShowUserModal from './components/ShowUserModal/ShowUserModal';
import { useAppDispatch } from '../../../../store';
import { lowerFirst } from 'lodash';
import { log } from 'console';
import CopyModal from './components/CopyModal/CopyModal';

/**
 * Отображает список локаций (реальные отчетные объекты, обладающие набором свойств.)
 */
const Accounts: React.FC<{ projectId: string | undefined }> = ({ projectId }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const theme = getTheme();

    const [modalStatus, setModalStatus] = useState({ show: false });
    const [modalCopyStatus, setCopyModalStatus] = useState<{ list: TAccountWithProduct[]; show: boolean }>({
        show: false,
        list: [],
    });
    const [showUser, setShowUser] = useState<{ show: boolean; pass?: string; email?: string }>({ show: false });

    const { extendedAccountsData } = useSelector(accountsReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const {
        urls: { CREATE_USER_URL },
        token,
    } = useSelector(generalReducerValues);

    const [filteredAccountsList, setFilteredAccountsList] = useState(extendedAccountsData);

    useEffect(() => {
        if (token && projectId) {
            dispatch(fetchUsers(projectId));
        }
    }, [token, projectId]);

    // useEffect(() => {
    //     setFilteredAccountsList(extendedAccountsData as TAccountWithProduct[]);
    // }, [extendedAccountsData]);

    const updateFilteredArray = (filteredArray: Array<object>) => {
        setFilteredAccountsList([...filteredArray] as TAccountWithProduct[]);
    };

    const onAddNewUserClick = () => {
        setModalStatus({ show: true });
    };

    const onCopyClick = () => {
        setCopyModalStatus({ show: true, list: filteredAccountsList });
    };

    const onCreateNewUserClick = (pass: string, email: string) => {
        if (token && projectId) {
            const data = { project_id: projectId, password: pass, email };
            postRequest({ url: CREATE_USER_URL, data, token, dispatch, allowSpinner: true }).then(() => {
                dispatch(fetchUsers(projectId));
                closeModal();
                setShowUser({ show: true, pass, email });
            });
        }
    };

    const onCopyModalOKClick = (pass: string, email: string) => {
        closeModal();
    };

    const closeModal = () => {
        setModalStatus({ show: false });
    };

    const closeShowUserModal = () => {
        setShowUser({ show: false });
    };

    return (
        <>
            <Modal modalStatus={modalStatus} title={t('Create new user')} closeModal={closeModal}>
                <NewUserModal onOKClick={onCreateNewUserClick} closeModal={closeModal} />
            </Modal>

            <Modal
                modalStatus={modalCopyStatus}
                title={t('Copy user settings')}
                closeModal={() => setCopyModalStatus({ show: false, list: [] })}
            >
                <CopyModal
                    list={modalCopyStatus.list}
                    onOKClick={onCopyModalOKClick}
                    closeModal={() => setCopyModalStatus({ show: false, list: [] })}
                />
            </Modal>

            {showUser.pass && showUser.email && (
                <Modal
                    modalStatus={showUser}
                    title={t('Save user login and password!')}
                    closeModal={closeModal}
                    topColor={theme.palette.red}
                >
                    <ShowUserModal
                        pass={showUser.pass || ''}
                        email={showUser.email || ''}
                        closeModal={closeShowUserModal}
                    />
                </Modal>
            )}
            <StackHorizontal>
                <FilterInput
                    inputArray={extendedAccountsData}
                    updateFilteredArray={updateFilteredArray}
                    matchField={'username'}
                    matchField1={'id'}
                />
                {!readOnly && (
                    <>
                        <PrimaryButton
                            text={t('Add new user')}
                            onClick={onAddNewUserClick}
                            allowDisabledFocus
                            disabled={false}
                        />
                        <PrimaryButton
                            style={{ marginLeft: '10px' }}
                            text={t('Copy user settings')}
                            onClick={onCopyClick}
                            allowDisabledFocus
                            disabled={false}
                        />
                    </>
                )}
            </StackHorizontal>
            {projectId && filteredAccountsList.length ? (
                <List list={filteredAccountsList} projectId={projectId} />
            ) : (
                <div>{t('There are no accounts in this project yet.')}</div>
            )}
        </>
    );
};

export default Accounts;
