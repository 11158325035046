import React, { useContext } from 'react';
import { IconButton } from '@fluentui/react/lib/Button';
import { EditorContext } from '../../../../Editor.context';
import { IPlan } from '../../../../../Layers/layers.interfaces';
import { IIconProps } from '@fluentui/react';
import { Floor } from '../FloorsButtons/FloorsButtons.styles';
import { StyledIconButton } from './LeasingPlans.styles';
import './LeasingPlan.scss';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

/**
 * Компонента кнопки выбора лизинг плана.
 */

const ToolsPanel = () => {
    const {
        plansList,
        planToEdit,
        storePlanToEditTrans,
        activeLayerId,
        changedLayersIds,
        selectedChapter,
        versions,
        activeVersionId,
    } = useContext(EditorContext);

    const { t } = useTranslation();

    const onMenuClick = (item: IPlan) => () => {
        storePlanToEditTrans(item);
    };

    const getTS = (str: string) => DateTime.fromISO(str).valueOf();

    const activeVersion = versions.filter((item) => item.id === activeVersionId)[0];

    const menuItems = plansList
        .filter((item: IPlan) => item.floor === planToEdit?.floor)
        .map((item: IPlan) => {
            const floor = `${t('Floor')}: ${item.floor}`;
            const text =
                item.planId === planToEdit?.planId
                    ? `${floor}  ${String(item.activeFrom)} (selected)`
                    : `${floor}  ${String(item.activeFrom)}`;

            const disabled =
                changedLayersIds.length !== 0 ||
                (selectedChapter !== 'layers' && selectedChapter !== 'tenants' && selectedChapter !== 'monitoring');
            let className = 'leasing-color-green';

            if (
                // getTS(item.activeFrom as string) < getTS(activeVersion.date_from as string) ||
                activeVersion?.date_to &&
                getTS(item.activeFrom as string) > getTS(activeVersion?.date_to as string)
            ) {
                className = 'leasing-color-red';
            }
            return {
                disabled,
                key: String(item.planId),
                text,
                onClick: onMenuClick(item),
                className,
            };
        });

    const menuProps = {
        items: menuItems,
    };

    const addmapsIcon: IIconProps = { iconName: 'MapLayers', styles: { root: { fontSize: 18 } } };

    return (
        <Floor isActiveFloor={false} onClick={() => {}}>
            <StyledIconButton
                disabled={activeLayerId === 'measureCut'}
                iconProps={addmapsIcon}
                menuIconProps={{ iconName: '', styles: { root: { width: 0 } } }}
                menuProps={menuProps}
            />
        </Floor>
    );
};

export default ToolsPanel;
