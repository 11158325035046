import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import {
    locationsReducerValues,
    fetchProjectLocations,
    addNewLocation,
    cloneLocation,
    storeActiveLocation,
} from './Locations.reducer';
import { generalReducerValues } from '../../General.reducer';
import { settingsReducerValues } from '../Settings/Settings.reducer';
import { LocationsWrapper, StyledPivot } from './Locations.styles';
import {
    IAddNewLocationArgs,
    IEditModalStatus,
    IHandleCreateNewLocationArgs,
    ILocation,
    IProjectLocation,
} from './Locations.interfaces';
import { GeneralContext } from '../../General.context';
import { LocationsContext } from './Locations.context';
import List from './components/List/List';
import Modal from '../Modals/Modals';
import CreateNewLocation from './components/CreateNewLocation/CreateNewLocation';
import StackHorizontal from '../StackHorizontal/StackHorizontal';
import FilterInput from '../FilterInput/FilterInput';
import { resetPlansReducer } from '../Plans/Plans.reducer';
import EditLocationModal from '../Modals/components/EditLocationModal/EditLocationModal';
import { cloneDeep } from 'lodash';
import { resetVersionsReducer } from '../Versions/Versions.reducer';
import { projectsReducerValues } from '../Projects/reducer';
import { PivotItem } from '@fluentui/react';
import Accounts from './components/Accounts/Accounts';
import { useAppDispatch } from '../../store';
import { useParams } from 'react-router';
import { Project } from './components/Project/Project';
import { log } from 'console';

/**
 * Отображает список локаций (реальные отчетные объекты, обладающие набором свойств.)
 */
const Locations = () => {
    const { t } = useTranslation();
    const params = useParams();

    const dispatch = useAppDispatch();
    const [selectedTab, setSelectedTab] = useState('locationsList');
    // const [selectedTab, setSelectedTab] = useState('accounts');

    const [modalStatus, setModalStatus] = useState({ show: false });
    const { projectLocationsList, projectLocationsById } = useSelector(locationsReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const {
        urls: { PROJECT_LOCATIONS_URL },
    } = useSelector(generalReducerValues);
    const { activeProjectId } = useSelector(projectsReducerValues);

    const [filteredLocationsList, setFilteredLocationsList] = useState(projectLocationsList);
    const { token, theme } = useContext(GeneralContext);
    const { neutralLighterAlt, neutralDark } = theme.palette;
    const [editLocationModalStatus, setEditLocationModalStatus] = useState<IEditModalStatus>({
        show: false,
        modalData: null,
    });

    useEffect(() => {
        if (token && params.projectId) {
            dispatch(resetPlansReducer());
            dispatch(resetVersionsReducer());
            dispatch(fetchProjectLocations({ url: PROJECT_LOCATIONS_URL, token, projectId: params.projectId }));
            dispatch(storeActiveLocation(null));
        }
    }, [PROJECT_LOCATIONS_URL, dispatch, params.projectId, token]);

    // useEffect(() => {
    //     if (projectLocationsList.length > 0) setEditLocationModalStatus({ show: false, modalData: null });
    // }, [projectLocationsList]);

    const updateFilteredArray = (filteredArray: Array<object>) => {
        setFilteredLocationsList(filteredArray as IProjectLocation[]);
    };

    const onAddNewLocationClick = () => {
        setModalStatus({ show: true });
    };

    // const changeActiveLocationIdTrans = (activeLocationId: string | number | null) => {
    //     dispatch(changeActiveLocationId({ activeLocationId }));
    // };

    const onCreateNewLocationClick = (data: {
        date_from: string;
        latitude: number;
        longitude: number;
        name: string;
        project_id: number;
    }) => {
        dispatch(addNewLocation({ url: PROJECT_LOCATIONS_URL, token, data }));
        // if (!data?.cloneChecked) {
        //     delete data.cloneId;
        // } else if (data?.cloneChecked) {
        //     dispatch(cloneLocation({ data }));
        // }˝
        setModalStatus({ show: false });
    };

    // const setEditLocationModalStatusTrans = (id: string) => {
    //     const locationForEdit = projectLocationsList
    //         .filter((location: ILocation) => location.id)
    //         .find((location: ILocation) => location.id === id);

    //     if (locationForEdit) {
    //         setEditLocationModalStatus({ show: true, modalData: locationForEdit });
    //     } else {
    //         setEditLocationModalStatus({ show: false, modalData: null });
    //     }
    // };

    const tabs = [
        {
            key: 'locationsList',
            text: t('Locations'),
        },
    ];

    if (!readOnly) {
        tabs.push(
            {
                key: 'accounts',
                text: t('Accounts'),
            },
            {
                key: 'project',
                text: t('Project'),
            },
        );
    }

    const pivotItems = tabs.map((item) => {
        return <PivotItem key={item.key} itemKey={item.key} headerText={t(item.text)} />;
    });

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            setSelectedTab(() => item.props.itemKey!);
        }
    };

    const onEditLocationClick = (location: IProjectLocation) => {
        params.projectId &&
            setEditLocationModalStatus({
                show: true,
                modalData: { ...location, project_id: Number(params.projectId) },
            });
    };

    const closeModal = () => {
        setEditLocationModalStatus({ show: false, modalData: null });
    };


    const locationsList =
        <>
            <StackHorizontal>
                <FilterInput
                    inputArray={projectLocationsList}
                    updateFilteredArray={updateFilteredArray}
                    matchField={'name'}
                    matchField1={'id'}
                />
                {!readOnly && (
                    <PrimaryButton
                        text={t('Add new location')}
                        onClick={onAddNewLocationClick}
                        allowDisabledFocus
                        disabled={false}
                    />
                )}
            </StackHorizontal>
            {projectLocationsList && projectLocationsList.length !== 0 ? (
                <List list={filteredLocationsList} onEditLocationClick={onEditLocationClick} />
            ) : null}
        </>

    return (
        <>
            <Modal modalStatus={modalStatus} title={t('Create new location')}>
                <CreateNewLocation onOKClick={onCreateNewLocationClick} />
            </Modal>
            <Modal modalStatus={editLocationModalStatus} title={t('Edit location')}>
                <EditLocationModal modalData={editLocationModalStatus.modalData} closeModal={closeModal} />
            </Modal>
            <LocationsWrapper bgcolor={neutralLighterAlt} color={neutralDark}>
                <StyledPivot selectedKey={selectedTab} onLinkClick={handleLinkClick} headersOnly={true}>
                    {pivotItems}
                </StyledPivot>
                {selectedTab === 'locationsList' && locationsList}
                {selectedTab === 'accounts' && !readOnly && <Accounts projectId={params.projectId} />}
                {selectedTab === 'project' && !readOnly && <Project />}
            </LocationsWrapper>
        </>
    );
};

export default Locations;
