import { store } from '../../../../perimeter.store';
import { Provider } from 'react-redux';
import React from 'react';
import ObjectsList from './ObjectsList';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <ObjectsList {...props} />
        </Provider>
    );
};
