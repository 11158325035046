import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { editProject, projectsReducerValues } from '../../../Projects/reducer';
import { useTranslation } from 'react-i18next';
import { Controls, Title, drodownStyles, pickerStyles } from './styles';
import { useParams } from 'react-router-dom';
import { Dropdown, Stack } from '@fluentui/react';

import { TextField } from '@fluentui/react/lib/TextField';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { DatePicker, IDropdownOption } from '@fluentui/react';
import { commonTools } from '../../../../tools/commonTools';
import { useAppDispatch } from '../../../../store';
import { log } from 'console';
import { DateTime } from 'luxon';
import { IProject } from '../../../Projects/interfaces';
import { Products } from './components/Products/Products';
import { generalReducerValues } from '../../../../General.reducer';

export const Project: React.FC = () => {
    const { projectsById, owners } = useSelector(projectsReducerValues);
    const { locales } = useSelector(generalReducerValues);
    const { t } = useTranslation();
    const params = useParams();
    const projectId = params.projectId;
    const [name, setName] = useState('');
    const [selectedLang, setSelectedLang] = useState('');
    const dispatch = useAppDispatch();

    const now: Date = new Date();
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [alias, setAlias] = useState('');
    const [selectedOwner, setSelectedOwner] = useState<number | null>(null);

    useEffect(() => {
        projectId && projectsById?.[projectId] && setSelectedOwner(() => projectsById?.[projectId].owner_id);
        projectId && projectsById?.[projectId] && setName(() => projectsById?.[projectId].name);
        projectId && projectsById?.[projectId] && setSelectedLang(() => projectsById?.[projectId].default_lang);
        projectId && projectsById?.[projectId] && setAlias(() => projectsById?.[projectId].alias);
        projectId &&
            projectsById?.[projectId] &&
            setStartDate(() => DateTime.fromISO(projectsById?.[projectId].date_from).toJSDate());
    }, [projectId, projectsById]);

    const onNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setName(newValue || '');
        const translitted = commonTools.translit(newValue || '');
        setAlias(() => translitted);
    };

    const onAliasChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setAlias(newValue || '');
    };

    const onChangeDatePicker = (date: Date | null | undefined) => {
        date && setStartDate(date);
    };

    const onEditClick = () => {
        if (selectedOwner && startDate && Number(params.projectId)) {
            const data: Omit<IProject, 'date_to'> = {
                id: Number(params.projectId),
                name,
                default_lang: selectedLang as IProject['default_lang'],
                alias,
                date_from: DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd'),
                owner_id: selectedOwner,
            };
            dispatch(editProject(data));
        }
    };

    const ownerOptions = owners.map((item) => ({ key: item.id, text: item.name }));

    const langOptions =
        locales?.languages.map((key) => {
            return { key: key, text: key };
        }) || [];

    const selectOwner = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
        option && setSelectedOwner(() => Number(option.key));
    };
    const selectLang = (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption<any> | undefined) => {
        option && setSelectedLang(() => option.key as string);
    };
    const buttonDisabled = !name || !alias || !selectedOwner || !startDate;

    if (params.projectId && Object.keys(projectsById).length) {
        const title: string = projectsById[params.projectId].name;
        return (
            <>
                <Title>{`${t('Edit')} ${title}`}</Title>
                <Controls>
                    <Dropdown
                        required
                        label={t('Owner')}
                        selectedKey={selectedOwner}
                        onChange={selectOwner}
                        placeholder={t('Select an owner')}
                        options={ownerOptions}
                        styles={drodownStyles}
                    />

                    <TextField label={t('Project name')} value={name} onChange={onNameChange} required />
                    <TextField label={t('Alias')} value={alias} onChange={onAliasChange} required />
                    <DatePicker
                        styles={pickerStyles}
                        label={t('Start date')}
                        id="dateFrom"
                        onSelectDate={onChangeDatePicker}
                        value={startDate}
                    />
                    <Dropdown
                        style={{ width: '150px' }}
                        label={t('Default language')}
                        required
                        selectedKey={selectedLang}
                        onChange={selectLang}
                        placeholder={t('Select a default language')}
                        options={langOptions}
                        styles={drodownStyles}
                    />
                    <PrimaryButton
                        text={t('Save')}
                        onClick={onEditClick}
                        allowDisabledFocus
                        disabled={buttonDisabled}
                    />
                </Controls>
                <Products />
            </>
        );
    } else {
        return null;
    }
};
