import { FontIcon, mergeStyles, Pivot } from '@fluentui/react';
import { IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

export const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: '100%' } };

export const TablesWrapper = styled.ul<{ visible: boolean }>`
    display: ${(p) => (p.visible ? 'block' : 'none')};
    list-style: none;
    width: 100%;
    height: calc(100% - 44px);
    margin: 0;
    box-sizing: border-box;
    background-color: ${theme.palette.neutralLight};
    padding: 10px;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 50px;
    .ms-Dropdown-container {
        margin-bottom: 5px;
    }
`;
export const Li = styled.li<{ selected: boolean }>`
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    align-items: center;
    list-style: none;
    color: ${(p) => (p.selected ? theme.palette.themePrimary : 'black')};
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    :hover {
        font-weight: 700;
    }
    span {
        font-size: 12px;
    }
`;

export const Container = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    i {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const StyledFontIcon = styled(FontIcon)`
    cursor: pointer;
`;
export const StyledPivot = styled(Pivot)`
    cursor: pointer;
`;

export const iconClass = (color: string) =>
    mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        // marginRight: '5px',
        color,
    });
