import React, {useState, useEffect} from 'react';


/**
 * Опции для выбюраного объекта
 * @constructor
 */
const ObjectOptions = () => {




    return null
};

export default ObjectOptions;
