import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Line, Group, Circle, Arrow, Shape } from 'react-konva';

import { deleteAnchor, deleteObject, moveAnchor, reducerValues, toggleContextMenu } from '../../../../reducer';
import { IDrawStairsProps } from './interfaces';
import { commonTools, ObjectLabel } from '../../../../../../layers';
import { useTranslation } from 'react-i18next';
import { FloorsLabel } from '../../../../../../../../tools/commonComponents';
import { cloneDeep } from 'lodash';
import { theme } from '../../../../../../../../constants/theme';

/**
 * Компонент эскалатор
 */
const Stairs: React.FC<IDrawStairsProps> = (props) => {
    const {
        isDrawNow,
        geometry: { coordinates },
        currentPlanData: { planScale },
        stageScale,
        onClick,
        selected,
        marker = '',
        name = '',
        front_id = '',
        activeToolId,
        showFinalRing = false,
        closed = false,
        floors = [],
        external_traffic = false,
        onMouseDown
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        showLabels,
        generalSettings: { snapToGrid },
        currentPlanData,
    } = useSelector(reducerValues);

    const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);

    const onShapeClick = (id: string | undefined) => (e: KonvaEventObject<MouseEvent>) => {
        // e.evt.stopPropagation();
        onClick(e, id);
    };

    const onAnchorDragMove = (pointIndex: number) => (e: KonvaEventObject<MouseEvent>) => {
        const newCoords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        newCoords && dispatch(moveAnchor({ pointIndex, front_id, newCoords }));
    };

    const onAnchorContextMenu =
        (elementId: string, anchorIndex: number, pointsNum: number) => (e: KonvaEventObject<MouseEvent>) => {
            e.evt.preventDefault();
            if (activeToolId !== 'anchorsEdit') {
                return;
            } else {
                const { x, y } = e.evt;
                dispatch(toggleContextMenu({ show: true, cursor: { x, y }, anchorIndex, pointsNum }));
            }
        };

    const anchors = useMemo(() => {
        if (!selected || !planScale) return null;
        const radius = foo(15);
        const draggable = activeToolId === 'anchorsEdit';
        return coordinates.map((point, i) => {
            return (
                <Circle
                    key={`Anchor--__${i}`}
                    draggable={draggable}
                    onDragMove={onAnchorDragMove(i)}
                    onContextMenu={onAnchorContextMenu(front_id, i, coordinates.length)}
                    x={point[0]}
                    y={point[1]}
                    radius={radius}
                    fill={'transparent'}
                    stroke={theme.palette.green}
                    strokeWidth={4 / stageScale}
                />
            );
        });
    }, [selected, activeToolId, coordinates, planScale, stageScale]);

    const finalRing = useMemo(() => {
        if (!planScale) return null;
        const radius = foo(15);
        return showFinalRing ? (
            <Circle
                onClick={onShapeClick(front_id)}
                draggable={false}
                x={coordinates[0][0]}
                y={coordinates[0][1]}
                radius={radius}
                fill={'transparent'}
                stroke={theme.palette.red}
                strokeWidth={4 / stageScale}
            />
        ) : null;
    }, [coordinates, planScale, showFinalRing, stageScale]);

    const labelCoords = commonTools.findPolygonCenter(coordinates);

    const onObjectMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (isDrawNow === true) {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            if (newPoint) {
                props.updateCoords(newPoint);
            }
        }
    };

    const floorsLabelText = useMemo(() => {
        if (!floors.length) return '';
        const sortedFloors = cloneDeep(floors).sort((a, b) => a - b);
        return `${JSON.stringify(sortedFloors)}${external_traffic ? '\nexternal_traffic' : ''}`;
    }, [external_traffic, floors]);

    const _onMouseDown = (e: any) => {
        onMouseDown(front_id, e);
    };

    return (
        <Group
        key={`group == ${front_id}`} 
        onMouseDown={_onMouseDown}
        >
            <Group
            onMouseMove={onObjectMouseMove}
            
            >
                <Shape
                    id={'Shape!@##11'}
                    key={'Shape!@##11'}
                    onClick={onShapeClick(front_id)}
                    sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.moveTo(coordinates[0][0], coordinates[0][1]);
                        coordinates.forEach((coord, i) => {
                            if (i > 0) {
                                context.lineTo(coord[0], coord[1]);
                            }
                        });
                        closed && context.closePath();
                        context.fillStrokeShape(shape);
                    }}
                    opacity={1}
                    stroke={'red'}
                    fill={'rgba(255,0,255,0.5)'}
                    strokeWidth={2 / stageScale}
                />
            </Group>
            {finalRing}
            {anchors}
            {labelCoords && (
                <FloorsLabel
                    stageScale={stageScale}
                    planScale={planScale || 1}
                    x={labelCoords?.x}
                    y={labelCoords?.y}
                    text={floorsLabelText}
                />
            )}
            {labelCoords && !isDrawNow && showLabels && (
                <ObjectLabel
                    stageScale={stageScale}
                    x={labelCoords?.x}
                    y={labelCoords?.y}
                    marker={marker}
                    selected={selected}
                    name={name}
                />
            )}
        </Group>
    );
};

export default Stairs;
