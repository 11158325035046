

import { IconButton } from '@fluentui/react'
import React from 'react'
import { IVersionsHistoryProps } from './VersionsHisory.interfaces'
import { iconVersionsButtonClassNames, iconVersionsName } from './VersionsHisory.styles'

/**
 * Компонент отрисовывает кнопку для открытия модалки с историей версий
 */
const VersionsHisory: React.FC<IVersionsHistoryProps> = ({toggleVersionsHistoryModalStatusTrans}) => {

    const openHistoryModal = () => toggleVersionsHistoryModalStatusTrans({show: true})
    
    return <IconButton iconProps={iconVersionsName} styles={iconVersionsButtonClassNames} onClick={openHistoryModal}/>
    
}

export default VersionsHisory
