import React, { useMemo } from 'react';
import { IElementAdminDataObject, IWorkingHours } from '../../../../../../tools/useRequestStructures/interfaces';
import { getIntervals } from '../../tools/getIntervals';
import { Intraday, IntervalWrapper, Dates, WorkingHoursModalWrapper, Time, Text1, Plate } from './styles';
import { useTranslation } from 'react-i18next';
import { TimePicker } from '@fluentui/react';
import { DateTime } from 'luxon';
import { log } from 'console';

const INTRADAY_MAP: { text: string; keys: [keyof IWorkingHours, keyof IWorkingHours] }[] = [
    { text: 'Monday', keys: ['mon_time_from', 'mon_time_to'] },
    { text: 'Tuesday', keys: ['tue_time_from', 'tue_time_to'] },
    { text: 'Wednesday', keys: ['wed_time_from', 'wed_time_to'] },
    { text: 'Thursday', keys: ['thu_time_from', 'thu_time_to'] },
    { text: 'Friday', keys: ['fri_time_from', 'fri_time_to'] },
    { text: 'Saturday', keys: ['sat_time_from', 'sat_time_to'] },
    { text: 'Sunday', keys: ['sun_time_from', 'sun_time_to'] },
];

export const WorkingHoursModal: React.FC<{ objectData: IElementAdminDataObject | null }> = ({ objectData }) => {
    const { t } = useTranslation();

    if (objectData?.working_hours) {
        const { sortedWh, skips } = getIntervals(objectData?.working_hours);
        const dateAnchor = new Date('February 27, 2023 08:00:00');

        const result = sortedWh.reverse().map((dates, i) => {
            return (
                <Plate key={String(dates.date_from) + i}>
                    <Dates>{`from: ${dates.date_from} to: ${dates.date_to}`}</Dates>
                    <IntervalWrapper>
                        {INTRADAY_MAP.map((item, j) => {
                            const nowDate = DateTime.now().toISODate();
                            const timeFrom = DateTime.fromISO(`${nowDate}T${dates[item.keys[0]]}`).toJSDate();
                            const timeTo = DateTime.fromISO(`${nowDate}T${dates[item.keys[1]]}`).toJSDate();

                            return (
                                <Intraday key={String(dates?.date_from) + i + j}>
                                    <b>{t(item.text)}</b>
                                    <Time>
                                        <Text1>{`from:`}</Text1>
                                        <TimePicker
                                            styles={{ root: { width: 100 } }}
                                            placeholder="Select a time"
                                            showSeconds={true}
                                            // contentEditable={true}
                                            dateAnchor={dateAnchor}
                                            value={timeFrom}
                                            // onChange={onTimePickerChange}
                                            ariaLabel="Time picker"
                                        />
                                    </Time>
                                    <Time>
                                        <Text1>{`to:`}</Text1>
                                        <TimePicker
                                            styles={{ root: { width: 100 } }}
                                            placeholder="Select a time"
                                            showSeconds={true}
                                            // contentEditable={true}
                                            dateAnchor={dateAnchor}
                                            value={timeTo}
                                            // onChange={onTimePickerChange}
                                            ariaLabel="Time picker"
                                        />
                                    </Time>
                                </Intraday>
                            );
                        })}
                    </IntervalWrapper>
                </Plate>
            );
        });

        return <WorkingHoursModalWrapper>{result}</WorkingHoursModalWrapper>;
    } else return null;
};
