import { convertLayers } from './tools/convertLayers';
import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import roundCoords from './tools/roundCoords';
import { IReducerState, IVersion } from './interfaces';
import { getRequest } from './tools/api';
import { DateTime } from 'luxon';
import { getUrls } from '../../constants/urls';
import { AppThunk, RootState } from '../../store';

const initialState: IReducerState = {
    activeVersionId: null,
    actualVersionId: null,
    versionToEdit: null,
    versions: [],
    versionsById: {},
    leftLayersByFloor: {},
    rightLayersByFloor: {},
    locationId: '',
    token: '',
    diffs: null,
};

export const reducer = createSlice({
    name: 'reducer',
    initialState,
    reducers: {
        resetReducer: (state, action: PayloadAction) => {
            state.leftLayersByFloor = {};
            state.rightLayersByFloor = {};
        },

        storeLocationId: (state, action: PayloadAction<string>) => {
            state.locationId = action.payload;
        },

        storeToken: (state, action: PayloadAction<string>) => {
            state.token = action.payload;
        },

        storeLayers: (
            state,
            action: PayloadAction<{ layers: Array<{ [x: string]: any }> | undefined; panel: string }>,
        ) => {
            const layersByFloor: { [x: string]: any[] } = {};
            action?.payload?.layers &&
                action.payload.layers
                    .map((layer) => convertLayers(layer))
                    .forEach((layer) => {
                        if (layersByFloor[String(layer.floor)]) {
                            layersByFloor[String(layer.floor)].push(layer);
                        } else {
                            layersByFloor[String(layer.floor)] = [layer];
                        }
                    });
            if (action.payload.panel === 'left') {
                state.leftLayersByFloor = layersByFloor;
            } else if (action.payload.panel === 'right') {
                const left = cloneDeep(state.leftLayersByFloor);

                const diffs: { [x: string]: { [x: string]: { [x: string]: Array<number> } } } = {};

                Object.keys(left).forEach((floor) => {
                    const leftFloorDiffs: { [x: string]: Array<number> } = {};
                    const rightFloorDiffs: { [x: string]: Array<number> } = {};
                    left[floor].forEach((layer) => {
                        const leftTextData =
                            layer.data && Array.isArray(layer.data)
                                ? layer.data.map((item) => {
                                      const clonnedItem = cloneDeep(item);
                                      roundCoords(clonnedItem);
                                      return JSON.stringify(clonnedItem);
                                  })
                                : [];
                        if (layersByFloor[floor]) {
                            const rightLayer = layersByFloor[floor].filter(
                                (item) => item.layer_type === layer.layer_type,
                            )[0];

                            const rightTextData =
                                rightLayer.data && Array.isArray(rightLayer.data)
                                    ? rightLayer.data.map((item: any) => {
                                          const clonnedItem = cloneDeep(item);
                                          roundCoords(clonnedItem);
                                          return JSON.stringify(clonnedItem);
                                      })
                                    : [];

                            let intersection = leftTextData.filter((x: string) => rightTextData.includes(x));

                            let leftDiff = leftTextData
                                .map((geometry: string, index: number) => ({ geometry, index }))
                                .filter((x: { geometry: string; index: number }) => !rightTextData.includes(x.geometry))
                                .map((item: { geometry: string; index: number }) => item.index);

                            let rightDiff = rightTextData
                                .map((geometry: string, index: number) => ({ geometry, index }))
                                .filter((x: { geometry: string; index: number }) => !leftTextData.includes(x.geometry))
                                .map((item: { geometry: string; index: number }) => item.index);

                            if (leftDiff.length) {
                                leftFloorDiffs[layer.layer_type] = leftDiff;
                            }
                            if (rightDiff.length) {
                                rightFloorDiffs[layer.layer_type] = rightDiff;
                            }
                        }
                    });
                    diffs[floor as string] = { left: leftFloorDiffs, right: rightFloorDiffs };
                });

                state.rightLayersByFloor = layersByFloor;
                state.diffs = diffs;
            }
        },

        storeVersions: (state, action: PayloadAction<IVersion[]>) => {
            const getTS = (str: string | null) => (str ? DateTime.fromISO(str).valueOf() : null);

            const nowTS = new Date().getTime();
            let activeVersionId = null;
            let actualVersionId = null;

            const oldest = Math.max(
                ...(action.payload
                    // .filter((item) => item.date_to !== null)
                    ?.reduce((acc: Array<number | null>, item: IVersion) => {
                        return [...acc, DateTime.fromFormat(item.date_from as string, 'yyyy-MM-dd').valueOf()];
                    }, []) as number[]),
            );

            for (let i = 0; i < action.payload.length; i++) {
                const version = action.payload[i];
                const startTS = getTS(version.date_from);
                const endTS = getTS(version.date_to);

                if (
                    (endTS && startTS && startTS <= nowTS && endTS >= nowTS) ||
                    (endTS === null && startTS && startTS <= nowTS)
                ) {
                    activeVersionId = version.id;
                    actualVersionId = version.id;
                    break;
                } else if (startTS === oldest) {
                    activeVersionId = version.id;
                    break;
                } else {
                    activeVersionId = version.id;
                }
            }

            state.activeVersionId = activeVersionId;
            state.actualVersionId = actualVersionId;
            state.versions = action.payload.sort((a, b) => {
                return Number(getTS(b.date_from)) - Number(getTS(a.date_from));
            });

            const versionsById: { [x: string]: IVersion } = {};
            action.payload.forEach((version) => {
                const startTS = getTS(version.date_from);
                const endTS = getTS(version.date_to);

                versionsById[version.id] = version;
            });
            state.versionsById = versionsById;
        },
    },
});

export const { storeVersions, storeLocationId, storeToken, storeLayers, resetReducer } = reducer.actions;

export const reducerValues = (state: RootState) => state.VersionsDiffReducer;

export default reducer.reducer;

/**
 * Запрос списка версий с сервера.
 */
export const fetchVersions =
    ({ token, locationId }: { token: string; locationId: string }): AppThunk =>
    (dispatch, getState) => {
        const { token, hosts } = cloneDeep(getState().GeneralReducer);
        const url = `${getUrls(hosts).VERSIONS_URL}?location_id=${locationId}`;
        getRequest({ url, dispatch, token }).then((data: Array<IVersion>) => {
            dispatch(storeVersions(data));
        });
    };

export const fetchLayers =
    ({ versionId, panel }: { versionId: number | string; panel: string }): AppThunk =>
    (dispatch, getState) => {
        const { token, hosts } = cloneDeep(getState().GeneralReducer);
        const { locationId } = cloneDeep(getState().VersionsDiffReducer);
        const url = `${getUrls(hosts).LAYERS_URL}?location_id=${locationId}&version_id=${versionId}`;

        locationId &&
            getRequest({ url, dispatch, token }).then((layers) => {
                layers && dispatch(storeLayers({ layers, panel }));
            });
    };
