import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { reducerValues, toggleContextMenu, deleteObject, deleteAnchor } from '../../reducer';

import { ContextualMenu, IIconProps } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

/**
 * Компонент Контекстного меню
 */
const ContextMenu = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { contextMenu, selectedObjectId, objects } = useSelector(reducerValues);

    const onHideContextualMenu = () => {
        dispatch(toggleContextMenu({ show: false }));
    };

    const menuItems = useMemo(() => {
        const result = [
            {
                key: 'delete_el',
                text: t('Delete lift'),
                onClick: () => {
                    selectedObjectId && dispatch(deleteObject(selectedObjectId));
                }
            }
        ];

        if (contextMenu.pointsNum && contextMenu.pointsNum > 3) {
            result.unshift({
                key: 'delete_anchor',
                text: t('Delete anchor'),
                onClick: () => {
                    selectedObjectId &&
                        contextMenu.anchorIndex !== undefined &&
                        dispatch(deleteAnchor({ elementId: selectedObjectId, anchorIndex: contextMenu.anchorIndex }));
                }
            });
        }

        return result;
    }, [contextMenu.anchorIndex, contextMenu.pointsNum, dispatch, selectedObjectId, t]);

    return menuItems ? (
        <ContextualMenu
            items={menuItems}
            hidden={!contextMenu.show}
            target={contextMenu.cursor}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
        />
    ) : null;
};

export default ContextMenu;
