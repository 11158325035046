const tools = require('./tools');

function get_intersections({ ipointsObjects, passWaysObjects, planScale }) {
    var intersections = [];
    ipointsObjects.forEach((ipoint) => {
        Object.keys(ipoint.passPoints).forEach((key) => {
            const coords = ipoint.passPoints[key].geometry.coordinates;
            passWaysObjects.forEach((passWay) => {
                const info = {
                    lineName: ipoint.passPoints[key].lineName,
                    pccMarker: ipoint.marker,
                    passWayMarker: passWay.marker,
                    passWayName: passWay.name,
                };
                const passLineCoords = passWay.passLine.coordinates;
                if (tools.isPointInsidePassWay(coords, passLineCoords, (planScale * passWay.passMargin) / 2)) {
                    intersections.push({ coords, info });
                }
            });
        });
    });

    // console.dir({ ipointsObjects, passWaysObjects, planScale, intersections }, { depth: null });

    return intersections;
}

module.exports = { get_intersections };
