import { useSelector } from 'react-redux';
import { reducerValues } from '../../../../../../../reducer';
import { useEffect, useState } from 'react';
import { ICell } from '../MonitoringDetailedData.interfaces';
import generateTableRow from '../tools/generateTableRow';
import useRequestStructures from '../../../../../../../../../../../tools/useRequestStructures/useRequestStructures';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';

const useTable = (): ICell[][] => {
    const { plId } = useParams();
    const { sensors, maxMeasures, dateFrom, dateTo } = useSelector(reducerValues);
    const [table, setTable] = useState<ICell[][]>([]);
    const [sortedTable, setSortedTable] = useState<ICell[][]>([]);

    const structuresResponse = useRequestStructures({ plId: plId ? Number(plId) : undefined });

    useEffect(() => {
        if (sensors) {
            const result: ICell[][] = sensors.map((sensor) => {
                const row = generateTableRow({
                    sensor,
                    additionalData: {
                        structures: structuresResponse.data?.structures,
                        maxMeasures,
                        dateFrom,
                        dateTo,
                    },
                });
                return row;
            });
            result.unshift(generateTableRow({ isHeader: true }));
            setTable(result);
        }
    }, [dateFrom, dateTo, maxMeasures, sensors, structuresResponse.data?.structures]);

    useEffect(() => {
        const operationMap: { [x: string]: number } = {
            full: 0,
            partial: -1,
            no: -2,
        };
        setSortedTable(
            sortBy(table, (row) => {
                if (row[0].isHeader) return -Infinity;
                const statusValue = row.find((cell) => cell.columnId === 'sensor_data_status')?.value;
                return operationMap[statusValue || ''] || 0;
            }),
        );
    }, [table]);
    return sortedTable;
};

export default useTable;
