import defaultDataAdapter from './dataAdapter';

import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';

import { IRawRelationsQueryFNResponse } from './interfaces';
import { GET_RAW_RELATIONS_QUERY_KEY } from '../../constants/queryKeys';
import { postRequest } from '../api';
import { GeneralContext } from '../../General.context';

interface IArgs {
    plId?: number;
}

const useRequestRawRelations = (args: IArgs) => {
    const { token } = useContext(GeneralContext);

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        if (token && args.plId) {
            const data = JSON.stringify({
                x_token: token,
                pl_id: args.plId,
            });
            return {
                queryKey: [GET_RAW_RELATIONS_QUERY_KEY, args.plId],
                queryFn: () =>
                    postRequest({
                        url: 'https://lambda.focustech.xyz/core/v1/pl-structure/',
                        token,
                        data,
                    }) as unknown as Promise<IRawRelationsQueryFNResponse>,
                dataAdapter: (response: IRawRelationsQueryFNResponse) => defaultDataAdapter({ response }),
            };
        }

        return {};
    }, [args.plId, token]);

    return useQuery<IRawRelationsQueryFNResponse, unknown, IRawRelationsQueryFNResponse, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryFn) && Boolean(queryKey) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 1000 * 60 * 15,
    });
};

export default useRequestRawRelations;
