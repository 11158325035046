import { createContext } from 'react';

import { ISettingsContext } from './Settings.interfaces';

export const initialSettingsData: ISettingsContext = {
    generalSettings: {
        lang: 'en',
        snapToGrid: 0.5,
        readOnly: true,
        gridValue: 0.5,
        displayContent: true,
        objNamingMode: 'markers',
    },
};

export const SettingsContext = createContext<ISettingsContext>({ ...initialSettingsData });
