export const describeSensors = (values: Array<{ v: number }>, maxMeasures: number) => {
    const averageMeasure =
        values.reduce((acc, val) => {
            return acc + val.v;
        }, 0) / maxMeasures;

    let fill = '#7CFC00';
    let operation = 'full';
    if (averageMeasure === 0) {
        fill = '#FF0000';
        operation = 'no';
    } else if (averageMeasure < 0.999999 && averageMeasure > 0) {
        fill = '#FFFF00';
        operation = 'partial';
    }
    return { fill, operation };
};
