import React, {useMemo} from 'react';
import {Circle, Label, Tag, Text} from "react-konva";

const StepCircle = ({...props}) => {

    const { steps, scale, coords,statusSelect, indexPoint } = props;

    const anchors = useMemo(() => {
        // if (activeToolId !== 'anchorsEdit') return null;
        const result: Array<JSX.Element> = [];
        steps.forEach((step: number, i: number) => {
            if (step === 1) {
                result.push(
                    <Circle
                        id={`!!__:${i}`}
                        key={`@@++:${i}`}
                        // draggable
                        x={coords[i][0]}
                        y={coords[i][1]}
                        radius={6 / scale.stageScale}
                        // opacity={0.6}
                        stroke={'green'}
                        strokeWidth={1 / scale.stageScale}
                        fill={statusSelect ? indexPoint === i ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,.5)' : 'rgba(0,0,0,.5)'}
                        // onDragMove={onUpdateGeometry(i)}
                        // onClick={onClickCircle(i)}
                        // onMouseEnter={onCircleMouseEnter}
                        // onMouseLeave={onCircleMouseLeave}
                    />
                )
            }
            else if (step >= 20 && step <= 30) {
                result.push(
                    <>
                        <Circle
                            id={`!!__:${i}`}
                            key={`@@++:${i}`}
                            // draggable
                            x={coords[i][0]}
                            y={coords[i][1]}
                            radius={12 / scale.stageScale}
                            // opacity={0.6}
                            stroke={'green'}
                            strokeWidth={1 / scale.stageScale}
                            fill={statusSelect ? indexPoint === i ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,.5)' : 'rgba(0,0,0,.5)'}
                            // onDragMove={onUpdateGeometry(i)}
                            // onClick={onClickCircle(i)}
                            // onMouseEnter={onCircleMouseEnter}
                            // onMouseLeave={onCircleMouseLeave}
                        />
                        <Label
                            x={coords[i][0] - (8 / scale.stageScale)}
                            y={coords[i][1] - (5 / scale.stageScale)}
                        >
                            <Text
                                text={`${String(step)}s`}
                                fontSize={10 / scale.stageScale}
                                // padding={5}
                                align={'center'}
                                fill={'white'}

                            />
                        </Label>


                    </>


                )
            }
            else if (step >= 60) {
                result.push(
                    <>
                        <Circle
                            id={`!!__:${i}`}
                            key={`@@++:${i}`}
                            // draggable
                            x={coords[i][0]}
                            y={coords[i][1]}
                            radius={12 / scale.stageScale}
                            // opacity={0.6}
                            stroke={'green'}
                            strokeWidth={1 / scale.stageScale}
                            fill={statusSelect ? indexPoint === i ? 'rgba(0,0,0,1)' : 'rgba(0,0,0,.5)' : 'rgba(0,0,0,.5)'}
                            // onDragMove={onUpdateGeometry(i)}
                            // onClick={onClickCircle(i)}
                            // onMouseEnter={onCircleMouseEnter}
                            // onMouseLeave={onCircleMouseLeave}
                        />
                        <Label
                            x={coords[i][0] - (7 / scale.stageScale)}
                            y={coords[i][1] - (5 / scale.stageScale)}
                        >
                            <Text
                                text={`${String(step / 60)}m`}
                                fontSize={10 / scale.stageScale}
                                // padding={5}
                                align={'center'}
                                fill={'white'}

                            />
                        </Label>


                    </>


                )
            }

        });

        return result;
    }, [coords, scale.stageScale, steps, statusSelect, indexPoint]);
    return (
        <>
            {anchors}
        </>
    );
}

export default StepCircle;