import { IExtendedPlan, IGeneralSettings, IVersionsData } from './../../layers.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from './store';
import { commonTools } from '../../layers';
import { IPlan, IPolygon, IPoint, IHotAddObject } from '../../layers.interfaces';

import { ICheckout, ICheckoutReducer, IMoveObjectAction, IObjects, IResizeObjectAction } from './checkouts.interfaces';


export const initialState: ICheckoutReducer = {
    layerAlias: 'checkouts_layer',
    activeToolId: null,
    selectedObjectId: null,
    createdAt: undefined,
    versionsData: null,
    objects: [],
    currentPlanData: null,
    showLabels: false,
    generalSettings: {} as IGeneralSettings,
    isNamesValid: { valid: true },
    anotherLocationNames: { markers: [] },
    hotAddObject: null,
};

export const reducer = createSlice({
    name: 'reducer',
    initialState,
    reducers: {
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Сохранение нового объекта в сторе
         */
        storeNewObject: (state, action: PayloadAction<any | null>) => {
            const objects = cloneDeep(state.objects);
            const anotherLocationNames = cloneDeep(state.anotherLocationNames);
            const currentPlanData = cloneDeep(state.currentPlanData);
            if (!currentPlanData?.planScale) return;
            const { planScale } = currentPlanData;
            const center = action.payload;

            const currentMarkers = objects.map((item) => item.marker);

            const nextNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers, ...currentMarkers],
                'checkout',
            );

            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:checkout${nextNumber}`;
            const id = `v${String(activeVersionId)}:checkout:${commonTools.generateId()}`;

            const checkoutPoint: IPoint = { type: 'Point', coordinates: center };

            const customerPoint1 = [center[0] - planScale, center[1] - planScale / 2];
            const customerPoint2 = [customerPoint1[0], customerPoint1[1] - planScale];
            const customerPoint3 = [customerPoint2[0] + 2 * planScale, customerPoint2[1]];
            const customerPoint4 = [customerPoint3[0], customerPoint3[1] + planScale];

            const customerArea: IPolygon = {
                type: 'Polygon',
                coordinates: [customerPoint1, customerPoint2, customerPoint3, customerPoint4],
            };

            const staffPoint1 = [center[0] - planScale, center[1] + planScale / 2];
            const staffPoint2 = [staffPoint1[0], staffPoint1[1] + planScale];
            const staffPoint3 = [staffPoint2[0] + 2 * planScale, staffPoint2[1]];
            const staffPoint4 = [staffPoint3[0], staffPoint3[1] - planScale];

            const staffArea: IPolygon = {
                type: 'Polygon',
                coordinates: [staffPoint1, staffPoint2, staffPoint3, staffPoint4],
            };

            const newObject = { checkoutPoint, customerArea, staffArea, marker, id };
            objects.push(newObject);
            state.objects = objects;
            state.selectedObjectId = id;
        },

        /**
         * Выбор объекта
         */
        selectObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload === undefined) {
                state.selectedObjectId = null;
            } else {
                state.selectedObjectId = action.payload;
            }
        },

        /**
         * Удаление объекта
         */
        deleteObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload !== undefined) {
                const objects = cloneDeep(state.objects);
                state.objects = objects.filter((item) => item.id !== action.payload);
            }
        },

        /**
         * Изменение параметров объекта
         */
        changeObjectParams: (
            state,
            action: PayloadAction<{ key: keyof ICheckout; newValue: string | undefined; id: string }>,
        ) => {
            const { id, key, newValue } = action.payload;
            const objects = cloneDeep(state.objects);
            const newObjects = objects.map((item) => {
                if (item.id !== id) {
                    return item;
                } else {
                    return { ...item, [key]: newValue };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Перемещение разных частей объекта
         */
        moveObject: (state, action: PayloadAction<IMoveObjectAction>) => {
            const { id, coordsChange, mode } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.id !== id) {
                    return item;
                } else {
                    const { customerArea, staffArea, checkoutPoint } = item;
                    let newCoords, newData;
                    switch (mode) {
                        case 'customer':
                            newCoords = customerArea.coordinates.map((item) => [
                                item[0] + coordsChange.dx,
                                item[1] + coordsChange.dy,
                            ]);
                            newData = { ...customerArea, coordinates: newCoords };
                            return { ...item, customerArea: newData };
                        case 'staff':
                            newCoords = staffArea.coordinates.map((item) => [
                                item[0] + coordsChange.dx,
                                item[1] + coordsChange.dy,
                            ]);
                            newData = { ...staffArea, coordinates: newCoords };
                            return { ...item, staffArea: newData };
                        case 'desk':
                            newCoords = [
                                checkoutPoint.coordinates[0] + coordsChange.dx,
                                checkoutPoint.coordinates[1] + coordsChange.dy,
                            ];
                            newData = { ...checkoutPoint, coordinates: newCoords };
                            return { ...item, checkoutPoint: newData };

                        default:
                            return item;
                    }
                }
            });
            state.objects = objects;
        },

        /**
         * Поворот и масштабирование разных зон объекта
         */
        transformObject: (state, action: PayloadAction<IResizeObjectAction>) => {
            const { id, newPolygon, mode } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.id !== id) {
                    return item;
                } else {
                    switch (mode) {
                        case 'customer':
                            return { ...item, customerArea: newPolygon };
                        case 'staff':
                            return { ...item, staffArea: newPolygon };
                        default:
                            return item;
                    }
                }
            });
            state.objects = objects;
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<Array<ICheckout>>) => {
            state.objects = action.payload || [];
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Запись в стор поля createdAt
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Сохранение в стор всех имен зи других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];

            action?.payload.forEach((item: { data?: any }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string }) => {
                    markers.push(obj.marker);
                });
            });
            const anotherLocationNames = { markers };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Валидация маркеров.
         */
        validateNames: (state) => {
            const { objects, anotherLocationNames } = cloneDeep(state);
            const currentMarkers = objects.map((item) => item.marker);
            const isNamesValid = commonTools.isNamesValid([...currentMarkers, ...anotherLocationNames.markers]);
            state.isNamesValid = isNamesValid;
        },

        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjectId = null;
        },

        changeCoords: (state, action: PayloadAction<{ 
            front_id: string;
            coordsCheckoutPoint: number[];
            coordsCustomerArea: number[][];
            coordsStaffArea: number[][];
        }>) => {
            const {
                  front_id,
                  coordsCheckoutPoint,
                  coordsCustomerArea,
                  coordsStaffArea
                 } = action.payload;
            const objects = cloneDeep(state.objects);
            const index = objects.findIndex((item) => item.id === front_id);


            index !== -1 && (objects[index] = {
                 ...objects[index],
                 customerArea: {
                     ...objects[index].customerArea,
                      coordinates: coordsCustomerArea! 
                    },
                    checkoutPoint: {
                        ...objects[index].checkoutPoint,
                        coordinates: coordsCheckoutPoint
                    },
                    staffArea: {
                        ...objects[index].staffArea,
                        coordinates: coordsStaffArea
                    }
                })


            state.objects = objects;
        },
        changeCoordsFullLayer: (state, action: PayloadAction<IObjects>) => {

            state.objects = action.payload;
        },
    },
});

export const {
    toggleTool,
    selectObject,
    storeNewObject,
    moveObject,
    transformObject,
    storeCurrentPlanData,
    storeInitialObjects,
    storeVersionsData,
    storeInitialCreatedAt,
    deleteObject,
    changeObjectParams,
    storeAnotherLocationNames,
    toggleShowLabels,
    storeGeneralSettings,
    validateNames,
    storeHotAddObject,
    clearSelectedObjects,
    changeCoords,
    changeCoordsFullLayer
} = reducer.actions;

export const reducerValues = (state: RootState) => state.reducer;

export default reducer.reducer;
