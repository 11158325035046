/**
 * Словарь соответствий между ключом и его значением в более читаемом виде
 */
export const controlPanelChaptersMap: { [chapterKey: string]: string } = {
    layers: 'Layers',
    tuples: 'Tuples',
    tenants: 'Tenants',
    tables: 'Tables',
    monitoring: 'Monitoring',
    sync: 'Sync',
};
