import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Line, Group, Circle, Arrow, Shape } from 'react-konva';

import {
    deleteAnchor,
    deleteObject,
    moveAnchor,
    reducerValues,
    toggleContextMenu,
    movePassPoint,
    selectPassPoint,
} from '../../../../reducer';
import { IDrawEscalatorProps } from './Escalator.interfaces';
import { commonTools, ObjectLabel } from '../../../../../../layers';
import { getEscalatorArrowPoint } from '../../../core/getEscalatorArrowPoint';
import { useTranslation } from 'react-i18next';
import { INodeContextMenu } from '../../../../../../layers.interfaces';
import { ContextualMenu } from '@fluentui/react';

/**
 * Компонент эскалатор
 */
const Escalator: React.FC<IDrawEscalatorProps> = (props) => {
    const {
        isDrawNow,
        line,
        in_out,
        direction,
        width,
        passPoints,
        currentPlanData: { planScale },
        stageScale,
        onClick,
        selected,
        external_traffic = false,
        marker = '',
        name = '',
        front_id = '',
        activeToolId,
        onMouseDown,
        onMouseUp,
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [contextMenu, set_contextMenu] = useState<INodeContextMenu>({ show: false });
    const {
        showLabels,
        generalSettings: { snapToGrid },
        currentPlanData,
        selectedPassPointId,
    } = useSelector(reducerValues);

    const onShapeClick = (id: string | undefined) => (e: KonvaEventObject<MouseEvent>) => {
        // e.evt.stopPropagation();
        onClick(e, id);
    };

    const onAnchorDragMove = (pointIndex: number) => (e: KonvaEventObject<MouseEvent>) => {
        const newCoords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        newCoords && dispatch(moveAnchor({ pointIndex, front_id, newCoords }));
    };

    const strokeWidth = planScale ? width * planScale : 2;
    const stroke = selected ? 'rgba(0, 0, 0, 0.7)' : 'rgba(0, 0, 0, 0.4)';

    const points = useMemo(() => {
        const result: Array<number> = [];
        line.coordinates.forEach((point) => {
            result.push(...point);
        });
        return result;
    }, [line.coordinates]);

    const onAnchorContextMenu =
        (elementId: string, anchorIndex: number, pointsNum: number) => (e: KonvaEventObject<MouseEvent>) => {
            e.evt.preventDefault();
            if (activeToolId !== 'anchorsEdit') {
                return;
            } else {
                const { x, y } = e.evt;
                dispatch(toggleContextMenu({ show: true, cursor: { x, y }, anchorIndex, pointsNum }));
            }
        };

    const anchors = useMemo(() => {
        if (!selected || !planScale) return null;
        const radius = 0.3 * planScale;
        const draggable = activeToolId === 'anchorsEdit';
        return line.coordinates.map((point, i) => {
            return (
                <Circle
                    key={`Anchor--__${i}`}
                    draggable={draggable}
                    onDragMove={onAnchorDragMove(i)}
                    onContextMenu={onAnchorContextMenu(front_id, i, line.coordinates.length)}
                    x={point[0]}
                    y={point[1]}
                    radius={radius}
                    fill={'transparent'}
                    stroke={'green'}
                    strokeWidth={4 / stageScale}
                />
            );
        });
    }, [selected, activeToolId, line.coordinates, width, planScale, stageScale]);

    const labelCoords = useMemo(() => {
        return commonTools.findLabelCoords(line.coordinates);
    }, [selected, line.coordinates]);

    const arrowStart = getEscalatorArrowPoint(line.coordinates[0], line.coordinates[1], planScale || 1);
    const arrowEnd = getEscalatorArrowPoint(line.coordinates[1], line.coordinates[0], planScale || 1);
    // const inOutRing = in_out === 'in' ? arrowEnd : arrowStart;
    const inOutRing = in_out === 'in' ? line.coordinates[1] : line.coordinates[0];

    const arrow = useMemo(() => {
        return (
            <>
                <Circle
                    draggable={false}
                    x={inOutRing[0]}
                    y={inOutRing[1]}
                    radius={((planScale as number) * width) / 4}
                    stroke="white"
                />
                <Circle
                    draggable={false}
                    onClick={onShapeClick(front_id)}
                    x={inOutRing[0]}
                    y={inOutRing[1]}
                    radius={((planScale as number) * width) / 4}
                    fill={'red'}
                />
                <Arrow
                    draggable={false}
                    onClick={onShapeClick(front_id)}
                    points={[arrowStart[0], arrowStart[1], arrowEnd[0], arrowEnd[1]]}
                    stroke="white"
                    strokeWidth={strokeWidth / 5}
                    pointerWidth={strokeWidth / 4}
                    pointerLength={strokeWidth / 3}
                />
            </>
        );
    }, [line.coordinates, planScale, in_out, width, stageScale]);

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    var gradient = useMemo(() => {
        return direction === 'up'
            ? ctx.createLinearGradient(points[0], points[1], points[2], points[3])
            : ctx.createLinearGradient(points[2], points[3], points[0], points[1]);
    }, [ctx, direction, points]);

    gradient.addColorStop(1.0, 'rgba(0,0,0,0.1)');
    gradient.addColorStop(0.0, 'rgba(0,0,0,0.7)');

    const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);

    const onSelectPassPoint = (passPointId: string) => () => {
        activeToolId === 'anchorsEdit' && dispatch(selectPassPoint({ passPointId }));
    };

    const onPassPointDragMove = (passPointId: string) => (e: KonvaEventObject<MouseEvent>) => {
        if (activeToolId !== 'anchorsEdit') return;
        dispatch(selectPassPoint({ passPointId }));
        const { x, y } = e.target.attrs;
        const newCoords = [x, y];
        newCoords && dispatch(movePassPoint({ passPointId, front_id, newCoords }));
    };

    const passpointAnchors = useMemo(() => {
        if (!selected || !passPoints) return null;
        const draggable = activeToolId === 'anchorsEdit';
        return Object.keys(passPoints).map((key: string) => {
            const isPassPointSelected = key === selectedPassPointId;
            const fill = isPassPointSelected ? 'rgba(0,255,0,0.5)' : 'transparent';
            const point = passPoints[key as keyof typeof passPoints].geometry;
            return (
                <Circle
                    key={`PCCAnchor--__${key}`}
                    draggable={draggable}
                    onClick={onSelectPassPoint(key)}
                    onDragMove={onPassPointDragMove(key)}
                    x={point.coordinates[0]}
                    y={point.coordinates[1]}
                    radius={foo(10)}
                    fill={fill}
                    stroke={'red'}
                    strokeWidth={foo(2)}
                />
            );
        });
    }, [selected, activeToolId, passPoints, stageScale]);

    const endPassPoints = useMemo(() => {
        if (!passPoints || Object.keys(passPoints).length === 0) return null;
        return Object.keys(passPoints).map((key) => {
            const point = passPoints[key].geometry;
            return (
                <React.Fragment key={key}>
                    <Line
                        points={[inOutRing[0], inOutRing[1], point.coordinates[0], point.coordinates[1]]}
                        stroke="black"
                        strokeWidth={foo(2)}
                    />
                    <Circle x={point.coordinates[0]} y={point.coordinates[1]} radius={foo(3)} fill={'black'} />
                </React.Fragment>
            );
        });
    }, [selected, activeToolId, passPoints, stageScale]);

    const onObjectMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (isDrawNow === true) {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            if (newPoint) {
                props.updateCoords(newPoint);
            }
        }
    };

    const _onMouseDown = (e: any) => {
        onMouseDown(front_id, e);
    };

    return (
        <>
            <Group
                key={`group == ${front_id}`}
                onMouseMove={onObjectMouseMove}
                onMouseDown={_onMouseDown}
                onMouseUp={onMouseUp}
            >
                <Line
                    onClick={onShapeClick(front_id)}
                    points={points}
                    stroke={gradient as any}
                    strokeWidth={strokeWidth}
                />
                {arrow}
            </Group>
            {anchors}
            <Group key={`group == PCC${front_id}`}>{endPassPoints}</Group>
            {passpointAnchors}
            {labelCoords && !isDrawNow && showLabels && (
                <ObjectLabel
                    stageScale={stageScale}
                    x={labelCoords?.x}
                    y={labelCoords?.y}
                    marker={marker}
                    name={name}
                    someText={`${external_traffic ? '\n[external traffic]' : ''}`}
                    selected={selected}
                />
            )}
        </>
    );
};

export default Escalator;
