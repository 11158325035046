import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { commonTools } from '../../../../tuples';

import { reducerValues, storeIntersections } from '../../reducer';

/**
 * Компонент контрольной панели
 */
const Controls = ({ ...props }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const escalatorsObjects = props?.dataFromLayers['escalators_layer']?.objects || [];
        const passWaysObjects = props?.dataFromLayers['pass_ways_layer']?.objects || [];
        const plansList = props.plansList || [];
        props.visible &&
            dispatch(
                storeIntersections({
                    escalatorsObjects,
                    passWaysObjects,
                    planScale: props?.currentPlanData?.planScale,
                    plansList,
                }),
            );
    }, [
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['escalators_layer']?.objects)),
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['pass_ways_layer']?.objects)),
        props?.currentPlanData?.planScale,
        props.visible,
        props.plansList,
    ]);

    return <></>;
};

export default Controls;
