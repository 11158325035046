import styled from 'styled-components/macro';
import { Stack } from '@fluentui/react/lib/Stack';

export const ObjectOptionsWrapper = styled.div``;
export const StyledStack = styled(Stack)`
    .ms-TextField-fieldGroup {
        background-color: transparent;
    }
    label {
        padding-left: 0px;
    }
`;
