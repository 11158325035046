import React from 'react';
import { Circle, Group, Layer, Shape } from 'react-konva';
import { INewShapeProps } from './NewShape.interfaces';

/**
 * Компонента Новый Полигон.
 */
const NewShape: React.FC<INewShapeProps> = ({ coords, isLayerActive, coordsEnd, onClickCircleAnd, ...props }) => {
    if (!coords || coords.length === 0 || !isLayerActive) return null;

    let componentCircle = null;

    if (Math.abs(coordsEnd[0] - coords[0][0]) < 5 && Math.abs(coordsEnd[1] - coords[0][1]) < 5 && coords.length > 3) {
        componentCircle = (
            <Group onClick={onClickCircleAnd}>
                <Circle
                    id={`circleMeddle`}
                    key={`circleMeddle`}
                    x={coords[0][0]}
                    y={coords[0][1]}
                    radius={10 / props.scale.stageScale}
                    stroke={'#fff'}
                    strokeWidth={2 / props.scale.stageScale}
                />
                <Circle
                    id={`circleBig`}
                    key={`circleBig`}
                    x={coords[0][0]}
                    y={coords[0][1]}
                    radius={12 / props.scale.stageScale}
                    stroke={'rgba(0,0,0,0.3)'}
                    strokeWidth={2 / props.scale.stageScale}
                />
                <Circle
                    id={`circleSmall`}
                    key={`circleSmall`}
                    x={coords[0][0]}
                    y={coords[0][1]}
                    radius={8 / props.scale.stageScale}
                    stroke={'rgba(0,0,0,0.3)'}
                    strokeWidth={2 / props.scale.stageScale}
                />
            </Group>
        );
    }

    return (
        <Layer key={props.key + '+++'}>
            <Group>
                <Shape
                    id={'Shape!@##1'}
                    key={'Shape!@##1'}
                    onMouseMove={props.onShapeMove}
                    onClick={props.onHelperClick('')}
                    sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.moveTo(coords[0][0], coords[0][1]);
                        coords.forEach((coord, i) => {
                            if (i > 0) {
                                context.lineTo(coord[0], coord[1]);
                            }
                        });
                        // context.closePath();
                        context.fillStrokeShape(shape);
                    }}
                    // fill={color}
                    opacity={1}
                    stroke={'black'}
                    strokeWidth={2 / props.scale.stageScale}
                />
                {componentCircle}
            </Group>
        </Layer>
    );
};

export default NewShape;
