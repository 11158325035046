const tools = require('./tools');
const cloneDeep = require('lodash/cloneDeep');

function get_intersections({ placesObjects, passWaysObjects, planScale }) {
    var intersections = [];
    const extendedPassWays = passWaysObjects.map((passWay) => {
        return Object.assign(cloneDeep(passWay), { arrowsCoords: tools.getArrowCoords({ passWay, planScale }) });
    });

    extendedPassWays.forEach((passWay) => {
        for (let i = 0; i < placesObjects.length; i++) {
            const polygonObject = placesObjects[i];

            const info = {
                placeMarker: polygonObject.marker,
                passWayMarker: passWay.marker,
                passWayName: passWay.name,
            };

            intersections = intersections.concat(tools.getPwPolygonIntersections({ polygonObject, info, passWay }));
        }
    });
    // console.dir({ placesObjects, passWaysObjects, planScale, intersections }, { depth: null });

    return intersections;
}

module.exports = { get_intersections };
