import { ITextFieldStyles } from '@fluentui/react';
import styled from 'styled-components/macro';

export const MainWrapper = styled.div`
    display: flex;
    width: 500px;
    gap: 10px;
    flex-direction: column;
    justify-content: space-between;
`;

export const textFiledStyles: Partial<ITextFieldStyles> = {
    fieldGroup: { width: 300 }
};

export const ButtonWrapper = styled.div`
margin-top: 10px;
    display: flex;
    justify-content: flex-end;
`;
