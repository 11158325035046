import { ILift } from './../../../Layers/components/Lifts/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { IPlace } from '../../../Layers/components/Places/places.interfaces';
import {
    ICrossObject,
    ICurrentPlaceLabelInfo,
    ICurrentPlaceZoneInfo,
    IReducer,
    ICurrentPlaceLeashInfo,
} from './interfaces';
import { RootState } from './store';
import { commonTools } from '../../tuples';
const lifts_places = require('../../../../lambda/lifts_places');

export const initialState: IReducer = {
    tupleAlias: 'lifts_places_tuple',
    intersections: [],
    dependenciesForCurrentLifts: null,
};

const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        /**
         * Запись в стор всех пересечений
         */
        storeIntersections: (
            state,
            action: PayloadAction<{
                liftsObjects: Array<ILift>;
                placesObjects: Array<IPlace>;
                planScale: number;
            }>,
        ) => {
            const { liftsObjects, placesObjects, planScale } = action.payload;

            const intersections = lifts_places.get_intersections({ liftsObjects, placesObjects, planScale });

            state.intersections = intersections;
        },

        /**
         * запись в стор всех зависимостей для выбранного place
         */
        storeDependenciesForCurrentLifts: (state, action: PayloadAction<{ liftMarker: string }>) => {
            const { liftMarker } = action.payload;
            const copy = cloneDeep(state.intersections);

            const labelsInformation = [] as Array<ICurrentPlaceLabelInfo>;
            const placeInformation = [] as Array<ICurrentPlaceZoneInfo>;
            const leashsInfornation = [] as Array<ICurrentPlaceLeashInfo>;

            copy.forEach((element: ICrossObject) => {
                if (element.info.liftMarker === liftMarker) {
                    const labelCoords = commonTools.findLabelCoords([
                        element.coords.liftPolygonCenter,
                        element.coords.placePolygonCenter,
                    ]);

                    const leashCoords = [...element.coords.liftPolygonCenter, ...element.coords.placePolygonCenter];

                    const text = `lift_marker: ${element.info.liftMarker}\nlift_name: ${element.info.liftName}\nplace_marker: ${element.info.placeMarker}`;

                    labelsInformation.push({ coords: labelCoords, text });
                    placeInformation.push({ coords: element.coords.placePolygonCenter });
                    leashsInfornation.push({ coords: leashCoords });
                }
            });

            state.dependenciesForCurrentLifts = { labelsInformation, placeInformation, leashsInfornation };
        },

        /**
         * Обнуление всех зависимостей для выьранного place
         */
        resetDependenciesForCurrentLifts: (state) => {
            state.dependenciesForCurrentLifts = null;
        },
    },
});

export const { storeIntersections, storeDependenciesForCurrentLifts, resetDependenciesForCurrentLifts } =
    Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
