import React from 'react';
import { Provider } from 'react-redux';
import { store } from '../../navPolygons.store';
import ContextMenu from './ContextMenu';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <ContextMenu {...props} />
        </Provider>
    );
};
