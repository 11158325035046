import React, { useMemo, useState, useEffect, useCallback, useContext } from 'react';
import { Stack } from '@fluentui/react/lib/Stack';
import { DateTime } from 'luxon';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { IShopsterPullModalProps } from './ShopsterPullModal.interfaces';
import { Container, Footer, MainWrapper, StCheckbox, StStack, StTextField, Wrapper } from './ShopsterPullModal.styles';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { EditorContext } from '../../../Editor/Editor.context';
import { useDispatch, useSelector } from 'react-redux';
import { editorReducerValues, addShopsterLayers } from '../../../Editor/Editor.reducer';
import { plansReducerValues } from '../../../Plans/Plans.reducer';
import { cloneDeep } from 'lodash';
import { IInitialLayers } from '../../../Editor/Editor.interfaces';
import { commonTools } from '../../../../tools/commonTools';
import { getHeaders } from '../../../../tools/getHeaders';

const layerToPull: Map<string, { [x: string]: string }> = new Map<string, { [x: string]: string }>([
    ['zones_layer', { layerName: 'zone_layer', data: 'groups' }],
    ['radio_sensor_ipoints_layer', { layerName: 'shopster_ipoint_layer', data: 'ipoints' }],
    ['places_layer', { layerName: 'place_layer', data: 'places' }],
    ['perimeter_layer', { layerName: 'appearance_layer', data: 'perimeters' }],
    // ['perimeter_layer', 'perimeter'],
]);

/**
 * Компонента Инженерная модалка.
 */
const ShopsterPullModal: React.FC<IShopsterPullModalProps> = React.memo(({ ...props }) => {
    const { activeLayerId, visibleLayersIds, initialLayers, contextMenu } = useSelector(editorReducerValues);
    const { imageOffset } = useSelector(plansReducerValues);
    const { plansList, storePlanToEditTrans, planToEdit } = useContext(EditorContext);
    const dispatch = useDispatch();

    const stackTokens = { childrenGap: 10 };
    const [localState, setLocalState] = useState<{ [s: string]: boolean }>({});
    const [locationUuid, setLocationUuid] = useState<string>('9fc5740d-7765-4490-9dca-b2e568572779');
    const { t } = useTranslation();

    useEffect(() => {
        const result: { [s: string]: boolean } = {};
        layerToPull.forEach((value: { [x: string]: string }, key: string) => {
            result[key] = false;
        });
        setLocalState(result);
    }, []);

    const onCheckChange =
        (key: string) =>
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
            if (checked !== undefined) {
                setLocalState({ ...localState, [key]: !localState[key] });
            }
        };

    const choices = useMemo(() => {
        const result: JSX.Element[] = [];
        layerToPull.forEach((value: { [x: string]: string }, key: string) => {
            result.push(
                <Wrapper key={`${value} - ${key}`}>
                    <StCheckbox checked={localState[key]} label={key} onChange={onCheckChange(key)} />
                </Wrapper>,
            );
        });
        return result;
    }, [localState]);

    const onUuidChange = (
        event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
        newValue?: string | undefined,
    ) => {
        if (newValue !== undefined) {
            setLocationUuid(() => newValue);
        }
    };

    const getShopsterLayers = async () => {
        try {
            const response = await axios({
                method: 'get',
                url: `https://maps.getshopster.net/geometry/plan_set/${locationUuid}/`,
                responseType: 'json',
                headers: getHeaders(),
            });
            return response.data;
        } catch (error) {}
    };

    const convertData = (data: any, layerType: string) => {
        switch (layerType) {
            case 'zones_layer':
                return data.map((item: { [x: string]: any }) => {
                    const zones = item.zones.map((zone: { [x: string]: any }) => {
                        const coords = { coordinates: zone.coords, type: 'Polygon' };
                        return { ...zone, coords, front_id: `zone:${+DateTime.local()}:${commonTools.generateId()}` };
                    });
                    return { ...item, zones, front_id: `groupZone:${commonTools.generateId()}` };
                });

            case 'perimeter_layer':
                return data.map((item: { [x: string]: any }) => {
                    const coords = { coordinates: item, type: 'Polygon' };
                    return { coords, front_id: `perimeter:${+DateTime.local()}:${commonTools.generateId()}` };
                });

            case 'places_layer':
                return data.map((item: { [x: string]: any }) => {
                    const coords = { coordinates: item.coords, type: 'Polygon' };
                    const entrances: Array<null | number[]> = [];
                    item.coords.forEach(() => {
                        entrances.push(null);
                    });
                    return {
                        ...item,
                        coords,
                        entrances,
                        front_id: `place:${+DateTime.local()}:${commonTools.generateId()}`,
                    };
                });

            case 'radio_sensor_ipoints_layer':
                return data.map((item: { [x: string]: any }) => {
                    return {
                        point: { coordinates: item.coords, type: 'Point' },
                        is_active: true,
                        marker: item.marker,
                        sensor_types: ['wifi_mac_sensor', 'ibeacon_transmitter'],
                        front_id: `radio_ipoint:${commonTools.generateId()}`,
                        wifi_scanner_radius: 8,
                    };
                });

            default:
                return data;
        }
    };

    const handleOKClick = async () => {
        if (!locationUuid) return;
        const response = await getShopsterLayers();
        let newInitialLayers = cloneDeep(initialLayers);

        if (planToEdit?.floor) {
            const { layers } = response.plans.filter((item: { floor: number }) => item.floor === planToEdit.floor)[0];

            layerToPull.forEach((value: { [x: string]: string }, key: string) => {
                if (layers?.[value.layerName]?.[value.data] && localState[key]) {
                    newInitialLayers = newInitialLayers.map((item) => {
                        if (key !== item.layerType) {
                            return item;
                        } else {
                            // const oldData = cloneDeep(item.data);
                            const data = convertData(layers?.[value.layerName]?.[value.data], key);
                            // Тут надо думать, есть опасность многократного слияния
                            // const data = item.data ? oldData.concat(newData) : newData;
                            return { ...item, data };
                        }
                    }) as IInitialLayers;
                }
            });
        }
        dispatch(addShopsterLayers({ data: newInitialLayers, imageOffset }));
    };

    return (
        <MainWrapper>
            <Container>
                <StTextField label={t('Enter location uuid')} value={locationUuid} onChange={onUuidChange} />
                <StStack tokens={{ childrenGap: 10 }}>{choices}</StStack>
            </Container>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={'OK'} width={'100px'} onClick={handleOKClick} />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default ShopsterPullModal;
