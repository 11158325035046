import React from 'react';
import { iconClass, StyledFontIcon } from './styles';
import { TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

/**
 * Иконка вставки из буфера
 */
export default function PasteIcon({ ...props }) {
    const { t } = useTranslation();
    return (
        <TooltipHost content={t('Paste from clipboard')}>
            <StyledFontIcon iconName="PasteAsCode" className={iconClass(props.color)} showLabels={props.showLabels} />
        </TooltipHost>
    );
}
