import { FontWeights, mergeStyleSets, mergeStyles, IStackStyles, IStackTokens, IButtonStyles } from '@fluentui/react';
import { theme } from '../../../../constants/theme';
import { ICalloutTarget, IIconName } from './SettingsButton.interfaces';

export const styles = mergeStyleSets({
    buttonArea: {
        verticalAlign: 'top',
        display: 'inline-block',
        textAlign: 'center',
        margin: '0 100px',
        minWidth: 130,
        height: 32,
    },
    callout: {
        maxWidth: 300,
    },
    header: {
        padding: '18px 24px 12px',
    },
    title: [
        theme.fonts.xLarge,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    inner: {
        height: '100%',
        padding: '0 24px 20px',
    },
    actions: {
        position: 'relative',
        marginTop: 20,
        width: '100%',
        whiteSpace: 'nowrap',
    },
    subtext: [
        theme.fonts.small,
        {
            margin: 0,
            fontWeight: FontWeights.semilight,
        },
    ],
    link: [
        theme.fonts.medium,
        {
            color: theme.palette.neutralPrimary,
        },
    ],
});

export const stackStyles: IStackStyles = {
    root: {
        // background: theme.palette.themeTertiary,
        // border: `1px solid ${theme.palette.themeTertiary}`,
        width: 300,
    },
};

export const target: ICalloutTarget = {
    x: window.innerWidth - 10,
    y: 30,
};

export const iconSettingsName: IIconName = {
    iconName: 'Settings',
};

export const numericalSpacingStackTokens: IStackTokens = {
    childrenGap: 10,
    padding: 10,
};

export const classNamesIconButtonSettings: IButtonStyles = {
    icon: {
        fontSize: '16px',
    },
    root: {
        color: theme.palette.black,
        ':hover': {
            color: theme.palette.themeDark,
        },
    },
};
