import styled from 'styled-components/macro';
import { theme } from '../../../../constants/theme';

/**
 * Компонент Обертка панели рисования.
 */
export const DrawPanelWrapper = styled.section`
    height: 100%;
    width: 100%;
    background-color: ${theme.palette.neutralTertiary};
    position: relative;
`;

export const FloatingPanel = styled.div`
    position: absolute;
    z-index: 100;
    left: 10px;
`;
