import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';

import { Layer, Group, Shape, Circle } from 'react-konva';

import {
    zonesValues,
    changeCoords,
    storeNewZone,
    addAnchor,
    selectZone,
    clearSelectedObjects,
    toggleContextMenu,
    removeLastCoords,
    changeShapeCoords,
    changeCoordsFullLayer
} from '../../zones.reducer';
import { ObjectLabel, Anchors, Helper, DrawNodes } from '../../../../layers';
import { WrapperLayer } from './Draw.styles';

import { formattingCoords } from './core/formattingCoords';
import { useTranslation } from 'react-i18next';

import { getIntersect } from './core/getIntersect';
import { commonTools } from '../../../../layers';
import { IAnchorContextMenuArgs, IPlan } from '../../../../layers.interfaces';
import { cloneDeep } from 'lodash';
import { IGroupZones, IObjects, IZone } from '../../zones.interfaces';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        indexSelectedGroupZones,
        showLabels,
        generalSettings: { snapToGrid },
        zoneFrontId,
        currentPlanData,
        selectedObjects,
    } = useSelector(zonesValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [coordsEnd, setCoordsEnd] = useState<number[]>([]);

    const [statusOverShape, setStatusOverShape] = useState<boolean>(false);
    const [statusIntersection, setStatusIntersection] = useState<boolean>(false);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [
        isMouseDownMove,
        setIsMouseDownMove
    ] = useState<boolean>(false)

    const { t } = useTranslation();

    useEffect(() => {
        if (activeToolId === 'draw') {
            const container = props.stage.current.container();
            if (container) {
                container.style.cursor = 'crosshair';
            }
        }
    }, [activeToolId]);

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw') {
            container.style.cursor = 'crosshair';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'anchorsEdit' && statusOverShape) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
        }  else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId, statusOverShape]);

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        }
        else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        }
    }, [activeToolId]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCoords = (coordsS: number[]) => {
        let tempCoords = coords;

        if (objects.length === 0) {
            return;
        }

        tempCoords[tempCoords.length - 1] = coordsS;

        setStatusIntersection(
            getIntersect(formattingCoords(objects[indexSelectedGroupZones], tempCoords, props.scale.stageScale)),
        );

        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const updateCoordsAnchors = (coordsS: number[][] | undefined, front_id: string) => {
        if (activeToolId === 'anchorsEdit') {
            dispatch(changeCoords({ coordsS, front_id }));
        }
    };

    const addNewPoint = (coordsS: number[]) => {
        if (objects.length === 0) {
            alert(t('No zone group'));
            return;
        }

        if (statusIntersection) {
            return;
        }
        let tempCoords = coords;
        if (tempCoords.length === 0) {
            tempCoords.push(coordsS);
        }

        tempCoords.push(coordsS);

        setStatusIntersection(
            getIntersect(formattingCoords(objects[indexSelectedGroupZones], tempCoords, props.scale.stageScale)),
        );

        setCoords(tempCoords);
        setCoordsEnd(coordsS);
    };

    const onMouseDown = (id: string) => (e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        // setIdSelectedShape(id);
        // dispatch(selectPlace({ placeFrontId: id }));
        if (activeToolId === 'move') {
            dispatch(
                selectZone({
                    groupFrontId: objects[indexSelectedGroupZones].front_id,
                    itemFrontId: id,
                }),
            );
        }
        else if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true)
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());

            let _objects: IGroupZones = JSON.parse(JSON.stringify(objects[indexSelectedGroupZones]));
            let selected_Objects: number[][] = [];

            _objects.zones = [];

            objects[indexSelectedGroupZones].zones.forEach((item, i) => {
                if (item.front_id === zoneFrontId) {
                    selected_Objects = item.coords.coordinates;
                } else {
                    _objects.zones.push(item);
                }
            });
            setStatusIntersection(
                commonTools.getIntersect(formattingCoords(_objects, selected_Objects, props.scale.stageScale)),
            );
            if (commonTools.getIntersect(formattingCoords(_objects, selected_Objects, props.scale.stageScale))) {
                dispatch(removeLastCoords());
            } else {
                dispatch(changeShapeCoords());
            }
        }
        else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false)
        }
    };

    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (zoneFrontId !== '') {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects[indexSelectedGroupZones].zones.find((item) => item.front_id === zoneFrontId);

                if (moveObj) {
                    _coordsShape = commonTools.processCoordsForMoveShape(
                        moveObj.coords.coordinates,
                        coords,
                        coordsOldShape,
                    );
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: zoneFrontId }));

                setCoordsOldShape(coords);
            }
        }
        else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IZone[] = []
                objects[indexSelectedGroupZones].zones.forEach((item, i) => {
                    _objects.push({
                        ...item,
                        coords: {
                            ...item.coords,
                            coordinates: commonTools.processCoordsForMoveShape(
                                item.coords.coordinates,
                                coords,
                                coordsOldShape,
                            )
                        }
                    })
                })


                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }

        }
    };

    const onHelperClick = (objectId: string) => (e: KonvaEventObject<MouseEvent>) => {
        if (objectId !== '') {
            if (activeToolId === 'anchorsEdit') {
                if (selectedObjects) {
                    let coords = commonTools.getPointerCoords({ e, snapToGrid: false });
                    e.evt.preventDefault();
                    const coordsTemp = [coords![0], coords![1]];
                    dispatch(addAnchor({ anchorCoords: coordsTemp, objectId }));
                }
                // else {
                //     dispatch(selectZone({ itemFrontId: objectId }));
                // }
            }
        } else {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            if (activeToolId === 'draw') {
                updateIsDrawNow(true);
                newPoint && addNewPoint(newPoint);
            } else {
                e.evt.preventDefault();
                dispatch(clearSelectedObjects());
            }
        }
    };

    const onClickCircleAnd = () => {
        setIsDrawNow(false);
        let tempCoords = coords;
        tempCoords.splice(coords.length - 1, 1);
        dispatch(storeNewZone(tempCoords));
        setCoords([]);
        setCoordsEnd([]);
    };

    const onShapeMove = (e: KonvaEventObject<MouseEvent>) => {
        const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        if (newPoint) {
            updateCoords(newPoint);
        }
    };

    const onAnchorContextMenu = (args: IAnchorContextMenuArgs) => {
        const { anchorIndex, pointsNum, cursor } = args;
        dispatch(toggleContextMenu({ show: true, cursor, anchorIndex, pointsNum }));
    };

    const newShape = useMemo(() => {
        if (!coords || coords.length === 0 || !props.isLayerActive) return null;

        let componentCircle = null;

        if (
            Math.abs(coordsEnd[0] - coords[0][0]) < 5 &&
            Math.abs(coordsEnd[1] - coords[0][1]) < 5 &&
            coords.length > 3
        ) {
            componentCircle = (
                <Group onClick={onClickCircleAnd}>
                    <Circle
                        id={`circleMeddle`}
                        key={`circleMeddle`}
                        x={coords[0][0]}
                        y={coords[0][1]}
                        radius={10 / props.scale.stageScale}
                        stroke={'#fff'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    <Circle
                        id={`circleBig`}
                        key={`circleBig`}
                        x={coords[0][0]}
                        y={coords[0][1]}
                        radius={12 / props.scale.stageScale}
                        stroke={'rgba(0,0,0,0.3)'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    <Circle
                        id={`circleSmall`}
                        key={`circleSmall`}
                        x={coords[0][0]}
                        y={coords[0][1]}
                        radius={8 / props.scale.stageScale}
                        stroke={'rgba(0,0,0,0.3)'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                </Group>
            );
        }

        return (
            <Layer key={props.key + '+++'}>
                <Group>
                    <Shape
                        id={'Shape!@##1'}
                        key={'Shape!@##1'}
                        onMouseMove={onShapeMove}
                        onClick={onHelperClick('')}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coords[0][0], coords[0][1]);
                            coords.forEach((coord, i) => {
                                if (i > 0) {
                                    context.lineTo(coord[0], coord[1]);
                                }
                            });
                            // context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        // fill={color}
                        opacity={1}
                        stroke={'red'}
                        strokeWidth={2 / props.scale.stageScale}
                    />
                    {componentCircle}
                </Group>
            </Layer>
        );
    }, [coords, props.scale, coordsEnd]);

    const onShapeMouseOver = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(true);
    };
    const onShapeMouseOut = (e: KonvaEventObject<MouseEvent>) => {
        setStatusOverShape(false);
    };

    const onClickItemColumn = (groupFrontId?: string, itemFrontId?: string) => () => {
        if (activeToolId === 'anchorsEdit') {
            dispatch(selectZone({ groupFrontId, itemFrontId }));
        }
    };

    const labelCoords = (coordsList: number[][]) => commonTools.findPolygonCenter(coordsList);

    const geometry = useMemo(() => {
        if (!objects || Object.keys(objects).length === 0) return null;
        const [selectedObject] = objects[indexSelectedGroupZones]?.zones.filter(
            (item) => item.front_id === selectedObjects?.front_id,
        );
        const componentNode = cloneDeep(objects[indexSelectedGroupZones]?.zones).map((item, index) => {
            const selected = item.front_id === selectedObjects?.front_id;
            let coordsList = item.coords.coordinates;

            return (
                <Group
                    onClick={onClickItemColumn(objects[indexSelectedGroupZones].front_id, item.front_id)}
                    key={index + '+++!!'}
                >
                    <Shape
                        onClick={onHelperClick(item.front_id)}
                        onMouseOver={onShapeMouseOver}
                        onMouseOut={onShapeMouseOut}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coordsList[0][0], coordsList[0][1]);
                            coordsList.forEach((coord, i) => {
                                if (i > 0) {
                                    context.lineTo(coord[0], coord[1]);
                                }
                            });
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        // fill={color}
                        opacity={1}
                        onMouseDown={onMouseDown(item.front_id)}
                        // fill={'rgba(255,0,0,.3)'}
                        stroke={'red'}
                        strokeWidth={
                            zoneFrontId === item.front_id ? 4 / props.scale.stageScale : 2 / props.scale.stageScale
                        }
                        fill={zoneFrontId === item.front_id ? 'rgba(0,255,0,.3)' : 'rgba(255,0,0,.3)'}
                    />
                    {zoneFrontId === item.front_id ? (
                        <Anchors
                            activeToolId={activeToolId}
                            coords={coordsList}
                            updateCoords={updateCoords}
                            updateCoordsAnchors={updateCoordsAnchors}
                            scale={props.scale}
                            front_id={item.front_id}
                            currentPlanData={currentPlanData!}
                            snapToGrid={snapToGrid}
                            onAnchorContextMenu={onAnchorContextMenu}
                        />
                    ) : null}
                    {labelCoords(coordsList) && !isDrawNow && showLabels && !selected && (
                        <ObjectLabel
                            stageScale={props.scale.stageScale}
                            x={labelCoords(coordsList)?.x}
                            y={labelCoords(coordsList)?.y}
                            marker={item.zone_marker}
                            name={item.zone_name}
                            selected={false}
                        />
                    )}
                </Group>
            );
        });

        return (
            <>
                <Group>{componentNode}</Group>
                {selectedObject && !isDrawNow && showLabels && (
                    <ObjectLabel
                        stageScale={props.scale.stageScale}
                        x={labelCoords(selectedObject.coords.coordinates)?.x}
                        y={labelCoords(selectedObject.coords.coordinates)?.y}
                        marker={selectedObject.zone_marker}
                        name={selectedObject.zone_name}
                        selected={true}
                    />
                )}
            </>
        );
    }, [objects, activeToolId, props.scale, indexSelectedGroupZones, isDrawNow, showLabels, zoneFrontId]);

    return (
        currentPlanData && (
            <>
                <WrapperLayer key={props.key} onMouseUp={onMouseUp} onMouseMove={moveShape} onMouseLeave={onMouseUp}>
                    {activeToolId === 'draw' && <DrawNodes stageScale={props.scale.stageScale} coordinates={coords} />}
                    <Helper
                        updateCoords={updateCoords}
                        isDrawNow={isDrawNow}
                        currentPlanData={currentPlanData}
                        snapToGrid={snapToGrid}
                        onHelperClick={onHelperClick('')}
                    />
                    {geometry}
                </WrapperLayer>
                {newShape}
            </>
        )
    );
};

export default Draw;
