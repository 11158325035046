import { PrimaryButton } from '@fluentui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { editorReducerValues } from '../../../../Editor.reducer';
import { settingsReducerValues } from '../../../../../Settings/Settings.reducer';
import { tenantsReducerValues } from '../../Tenants.reducer';
import Tenant2PlaceItem from './Tenant2PlaceItem';

import { AllInfoTable, buttonStyles, Header, PlaceWasActiveWrapper, TableWrapper, Wrapper } from './styles';
import { ITenant2Place } from '../../Tenants.interfaces';
import { DateTime, Interval } from 'luxon';
import useRequestStructures from '../../../../../../tools/useRequestStructures/useRequestStructures';
import { useParams } from 'react-router-dom';
import { sortBy } from 'lodash';
import { generalReducerValues } from '../../../../../../General.reducer';
import { locationsReducerValues } from '../../../../../Locations/Locations.reducer';

const PlacePanel: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { activeProjectLocation } = useSelector(locationsReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const { placesByMarker, selectedPlace, tenants, tenant2place } = useSelector(tenantsReducerValues);
    const [showAddPanel, setShowAddPanel] = useState(false);
    const { plId } = useParams();
    const structureResponse = useRequestStructures({ plId: plId ? Number(plId) : undefined });

    const selectedPlaceData = structureResponse.data?.structures.core.elements_ms_data_objects
        .filter((item) => item.object_type === 'place')
        .filter((item) => item.marker === selectedPlace?.marker);

    // Вычисляем интервалы активности помещения

    const placeActiveData = sortBy(
        selectedPlaceData?.map((item) => {
            const interval = Interval.fromDateTimes(
                DateTime.fromISO(item.date_from).setZone(activeProjectLocation?.timezone),
                DateTime.fromISO(item.date_to).setZone(activeProjectLocation?.timezone),
            );
            return { interval, area: item.area };
        }),
        (item) => item.interval.end?.toMillis(),
    ).reverse();

    const placeExistsTable = placeActiveData.map((item) => {
        let finish = item.interval.end?.toFormat('y-MM-dd');
        if (item.interval.end && item.interval.end > DateTime.now()) {
            finish = 'Now';
        }
        return (
            <>
                <span>{`${item.interval.start?.toFormat('y-MM-dd')} - ${finish}`}</span>
                <span>{`area: ${item.area} m²`}</span>
            </>
        );
    });

    const toggleShowAddPanel = () => {
        setShowAddPanel(() => !showAddPanel);
    };

    useEffect(() => {
        setShowAddPanel(() => false);
    }, [selectedPlace]);

    const list = useMemo(() => {
        return tenant2place
            .filter((item) => item.place_id === selectedPlace?.id)
            .map((item, i) => {
                return (
                    <Tenant2PlaceItem
                        key={`${item.place_id}--${i}`}
                        item={item}
                        mode={'old'}
                        placeActiveIntervals={placeActiveData.map((item) => item.interval)}
                    />
                );
            });
    }, [selectedPlace?.id, tenant2place]);

    const AllTenantsInfo = useMemo(() => {
        const thisPlaceTenants = tenant2place.filter((item) => item.place_id === selectedPlace?.id);

        const InfoByTenantId: { [x: string]: Array<ITenant2Place> } = {};

        thisPlaceTenants.forEach((item) => {
            const tenantHistory = tenant2place.filter((ten) => ten.tenant_id === item.tenant_id);
            tenantHistory.forEach((item) => {
                if (InfoByTenantId[item.tenant_id]) {
                    if (
                        !InfoByTenantId[item.tenant_id].some(
                            (currInfo) =>
                                currInfo.tenant_id === item.tenant_id &&
                                currInfo.place_id === item.place_id &&
                                currInfo.date_from === item.date_from,
                        )
                    ) {
                        InfoByTenantId[item.tenant_id] = [...InfoByTenantId[item.tenant_id], item];
                    }
                } else {
                    InfoByTenantId[item.tenant_id] = [item];
                }
            });
        });

        const result = Object.values(InfoByTenantId).map((infoArr) => {
            const tenantId = infoArr[0].tenant_id;
            const tenantName = infoArr[0].tenant_object_name;
            const rows = infoArr
                .sort((a, b) => DateTime.fromISO(a.date_from).toMillis() - DateTime.fromISO(b.date_from).toMillis())
                .sort((a, b) => a.id - b.id)
                .map((row) => {
                    return (
                        <tr key={`${row.tenant_id}--${row.place_id}`}>
                            <td>{row.place_id}</td>
                            <td>{row.place_marker}</td>
                            <td>{row.date_from}</td>
                            <td>{row.date_to || 'Now'}</td>
                        </tr>
                    );
                });
            return (
                <React.Fragment key={tenantId}>
                    <tr>
                        <td className="someclass" colSpan={4}></td>
                    </tr>
                    <tr>
                        <th colSpan={4}>{tenantName}</th>
                    </tr>
                    <tr>
                        <th>Place id</th>
                        <th>Place marker</th>
                        <th>Date from</th>
                        <th>Date to</th>
                    </tr>
                    {rows}
                </React.Fragment>
            );
        });

        return <AllInfoTable>{result}</AllInfoTable>;
    }, [selectedPlace?.id, tenant2place]);

    return (
        <Wrapper>
            <Header>{'Place ' + selectedPlace?.marker}</Header>
            <Header>{t('active at periods:')}</Header>
            <PlaceWasActiveWrapper>{placeExistsTable}</PlaceWasActiveWrapper>
            <Header>{t('Tenants in the place:')}</Header>
            {list}
            <TableWrapper>{AllTenantsInfo}</TableWrapper>
            {!showAddPanel &&
            selectedPlace?.marker &&
            !placesByMarker[selectedPlace?.marker].active ? null : !showAddPanel ? (
                <PrimaryButton
                    text={t('Add new tenant')}
                    onClick={toggleShowAddPanel}
                    allowDisabledFocus
                    disabled={readOnly}
                    styles={buttonStyles}
                />
            ) : (
                <>
                    <Tenant2PlaceItem
                        mode={'new'}
                        placeActiveIntervals={placeActiveData.map((item) => item.interval)}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default PlacePanel;
