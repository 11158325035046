import styled from 'styled-components/macro';

export const Text = styled.div`
    display: flex;
    flex-wrap: wrap;
    word-wrap: break-word;
    span {
        font-weight: 500;
        white-space: nowrap;
        flex-shrink: 0;
        align-self: flex-start;
    }

    div {
        white-space: pre-line;
        font-size: 12px;
        color: rgb(32, 31, 30);
        text-align: left;
    }
`;
