import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { IPlace } from '../../../Layers/components/Places/places.interfaces';
import { IGroupZones } from '../../../Layers/components/Zones/zones.interfaces';
import {
    ICrossObject,
    ICurrentPlaceLabelInfo,
    ICurrentPlaceZoneInfo,
    IExtendedZone,
    IReducer,
    ICurrentPlaceLeashInfo,
} from './interfaces';
import { RootState } from './store';
import { commonTools } from '../../tuples';
const places_zones = require('../../../../lambda/places_zones');

export const initialState: IReducer = {
    tupleAlias: 'places_zones_tuple',
    intersections: [],
    dependenciesForCurrentPlace: null,
};

const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        /**
         * Запись в стор всех пересечений
         */
        storeIntersections: (
            state,
            action: PayloadAction<{ placesObjects: Array<IPlace>; zonesGroups: Array<IGroupZones>; planScale: number }>,
        ) => {
            const { placesObjects, zonesGroups, planScale } = action.payload;

            const zonesObjects = [] as Array<IExtendedZone>;

            zonesGroups.forEach((group) => {
                if (group.zones.length > 0) {
                    group.zones.forEach((zone) => {
                        zonesObjects.push({ ...zone, group_marker: group.group_marker, group_name: group.group_name });
                    });
                }
            });

            const intersections = places_zones.get_intersections({ placesObjects, zonesObjects, planScale });

            state.intersections = intersections;
        },

        /**
         * запись в стор всех зависимостей для выбранного place
         */
        storeDependenciesForCurrentPlace: (state, action: PayloadAction<{ placeMarker: string }>) => {
            const { placeMarker } = action.payload;
            const copy = cloneDeep(state.intersections);

            const labelsInformation = [] as Array<ICurrentPlaceLabelInfo>;
            const zonesInformation = [] as Array<ICurrentPlaceZoneInfo>;
            const leashsInfornation = [] as Array<ICurrentPlaceLeashInfo>;

            copy.forEach((element: ICrossObject) => {
                if (element.info.placeMarker === placeMarker) {
                    const labelCoords = commonTools.findLabelCoords([
                        element.coords.placePolygonCenter,
                        element.coords.zonePolygonCenter,
                    ]);

                    const leashCoords = [...element.coords.placePolygonCenter, ...element.coords.zonePolygonCenter];

                    const text = `place_marker: ${element.info.placeMarker}\nzone_marker: ${element.info.zoneMarker}\nzone_name: ${element.info.zoneName}\nzone_group_marker: ${element.info.groupMarker}\nzone_group_name: ${element.info.groupName}\ncontent_percentage: ${element.info.contentPercentage}`;

                    labelsInformation.push({ coords: labelCoords, text });
                    zonesInformation.push({ coords: element.coords.zonePolygonCenter });
                    leashsInfornation.push({ coords: leashCoords });
                }
            });

            state.dependenciesForCurrentPlace = { labelsInformation, zonesInformation, leashsInfornation };
        },

        /**
         * Обнуление всех зависимостей для выьранного place
         */
        resetDependenciesForCurrentPlace: (state) => {
            state.dependenciesForCurrentPlace = null;
        },
    },
});

export const { storeIntersections, storeDependenciesForCurrentPlace, resetDependenciesForCurrentPlace } =
    Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
