import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTranslation } from 'react-i18next';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { commonTools } from '../../../../../../layers';
import { reducerValues, changeObjectParams, changeObjectFloors } from '../../../../reducer';
import { classNames, Divider, ObjectWrapper, StyledStack, StyledTextField } from './ObjectOptions.styles';
import { IStairs } from '../../../../interfaces';
import { Label, Stack } from '@fluentui/react';
import { Checkbox, FontIcon } from '@fluentui/react';

/**
 * Компонента параметров выделенного объекта.
 */
const ObjectOptions = () => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, generalSettings, allTheFloors, currentPlanData, selectedPassPointId } =
        useSelector(reducerValues);
    const [selectedObject] = objects.filter((item) => item.front_id === selectedObjectId);
    const { t } = useTranslation();

    const stackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const floorsStackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const onChange =
        (key: keyof IStairs) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            dispatch(
                changeObjectParams({
                    key,
                    newValue: key === 'marker' ? commonTools.matchChars(newValue) : newValue,
                    front_id: selectedObject.front_id,
                }),
            );
        };

    const floorsChange =
        (floor: number) =>
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            checked !== undefined &&
                dispatch(
                    changeObjectFloors({
                        checked,
                        floor,
                        front_id: selectedObject.front_id,
                    }),
                );
        };

    const onExTrafficChange = (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
        dispatch(
            changeObjectParams({ key: 'external_traffic', newValue: isChecked, front_id: selectedObject.front_id }),
        );
    };

    const floorCheckboxes = allTheFloors.map((floor) => {
        if (!selectedObject) return null;
        const { floors } = selectedObject;
        const checked = floors.includes(floor);

        if (floor !== currentPlanData?.floor) {
            return (
                <Checkbox
                    disabled={generalSettings.readOnly}
                    key={`floor-${floor}`}
                    checked={checked}
                    onChange={floorsChange(floor)}
                    label={String(floor)}
                    boxSide="start"
                />
            );
        } else {
            return (
                <Checkbox
                    key={`floor-${floor}`}
                    disabled={true}
                    checked={checked}
                    label={String(floor)}
                    boxSide="start"
                />
            );
        }
    });

    return selectedObject ? (
        <StyledStack tokens={stackTokens}>
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Marker')}
                value={String(selectedObject.marker)}
                onChange={onChange('marker')}
            />
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Name')}
                value={String(selectedObject.name)}
                onChange={onChange('name')}
            />

            <Label>{t('Floors')}</Label>
            <Stack wrap tokens={floorsStackTokens} horizontal>
                {floorCheckboxes}
            </Stack>
            <Divider />
            <Checkbox
                disabled={generalSettings.readOnly}
                label={t('External traffic')}
                checked={selectedObject.external_traffic}
                onChange={onExTrafficChange}
            />
        </StyledStack>
    ) : null;
};

export default ObjectOptions;
