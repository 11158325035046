import styled from 'styled-components/macro';

export const Lang = styled.div`
    cursor: pointer;
    padding: 0 10px 0 10px;
`;

export const itemStyles = {
    root: [
        {
            selectors: {
                ':focus': {
                    outline: 'none',
                },
            },
        },
    ],
    label: { fontSize: 14 },
};

export const menuStyles = {
    root: { minWidth: 'auto', right: '0px' },
    subComponentStyles: { menuItem: itemStyles, callout: {} },
};
