import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { IConflictModalProps } from './ConflictModal.interfaces';
import { Container, Footer, MainWrapper, StCheckbox, StStack, StTextField, Wrapper } from './ConflictModal.styles';
import { DateTime } from 'luxon';

/**
 * Компонент Модалка конфликтов.
 */
const ConflictModal: React.FC<IConflictModalProps> = React.memo(({ modalData, onConflictButtonClick, ...props }) => {
    const { t } = useTranslation();
    // const [localBackend, setLocalBackend] = useState(modalData);
    // const dispatch = useDispatch();
    const stackTokens = { childrenGap: 10 };

    // const onUrlChange =
    //   (key: string) =>
    //   (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => {
    //     if (newValue !== undefined) {
    //       const loc = {
    //         ...localBackend,
    //         [key]: { checked: localBackend[key].checked, url: newValue }
    //       };
    //       setLocalBackend({ ...loc });
    //     }
    //   };

    // const onCheckChange =
    //   (key: string) =>
    //   (ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined, checked?: boolean | undefined) => {
    //     if (checked !== undefined) {
    //       const loc = {
    //         ...localBackend,
    //         [key]: { checked, url: localBackend[key].url }
    //       };
    //       setLocalBackend({ ...loc });
    //     }
    //   };

    const onClick = (mode: string, actualCreatedAt: string) => () => {
        onConflictButtonClick(mode, actualCreatedAt);
    };

    const text = `${t('Layer was changed from outside at ')}${DateTime.fromISO(
        modalData.data.conflictDate
    ).toLocaleString(DateTime.DATETIME_FULL)}`;

    return (
        <MainWrapper>
            <Container>{text}</Container>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <DefaultButton text={t('Cancel')} width={'100px'} onClick={onClick('cancel', '')} />
                    <PrimaryButton
                        text={t('Rewrite?')}
                        width={'100px'}
                        onClick={onClick('rewrite', modalData.data.conflictDate)}
                    />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default ConflictModal;
