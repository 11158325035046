import React, { useState, useEffect } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Line, Group, Circle, Shape, Image, Text } from 'react-konva';
import useImage from 'use-image';

import { ICheckoutIconProps } from './CheckoutIcon.interfaces';
import Icon from '../../assets/order';

/**
 * Компонент объекта касса
 */
const CheckoutIcon = (props: ICheckoutIconProps) => {
    const {
        item,
        draggable,
        planScale = 1,
        stageScale,
        selected,
        onMouseMove,
        onMouseUp,
        onDragMove,
        onShapeDragEnd,
        onShapeClick,
        onShapeMouseDown
    } = props;

    const url = 'data:image/svg+xml;base64,' + window.btoa(Icon);
    const [image] = useImage(url);

    /**
     * Отрисовка зоны кассы
     */
    const drawIcon = () => {
        const deskArea = planScale / 2;
        const center = item.checkoutPoint.coordinates;
        const x = center[0] - deskArea / 2;
        const y = center[1] - deskArea / 2;
        const circleX = center[0];
        const circleY = center[1];
        const radius = deskArea * 0.7;
        const strokeWidth = selected ? 2 / stageScale : 0;

        const p1 = [center[0] - radius, center[1] - radius];
        const p2 = [center[0] + radius, center[1] - radius];
        const p3 = [center[0] + radius, center[1] + radius];
        const p4 = [center[0] - radius, center[1] + radius];

        return (
            <Group
                onMouseMove={onMouseMove}
                onMouseUp={onMouseUp}
                onMouseDown={onShapeMouseDown}
                draggable={draggable}
                onDragMove={onDragMove('desk')}
                onDragEnd={onShapeDragEnd('desk')}
                onClick={onShapeClick(item.id)}
            >
                <Circle x={circleX} y={circleY} radius={radius} fill={'blue'} stroke={'red'} strokeWidth={strokeWidth} />
                <Image width={deskArea} height={deskArea} x={x} y={y} image={image} />
                <Shape
                    sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.moveTo(p1[0], p1[1]);
                        context.lineTo(p2[0], p2[1]);
                        context.lineTo(p3[0], p3[1]);
                        context.lineTo(p4[0], p4[1]);
                        context.closePath();
                        context.fillStrokeShape(shape);
                    }}
                    fill={'transparent'}
                />
            </Group>
        );
    };

    return drawIcon();
};

export default CheckoutIcon;
