import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleAlert, generalReducerValues } from '../../General.reducer';
import { AlertWrapper } from './Alert.styles';

/**
 * Компонент Алерт.
 * @param type: string - тип алерта, влияет на цвет фона.
 * @param text: string - текст алерта.
 */
const Alert = () => {
    const {
        alert: { text = '', type = 'warning', lifeTime = 10000 },
    } = useSelector(generalReducerValues);
    const dispatch = useDispatch();

    useEffect(() => {
        if (lifeTime !== 0) {
            setTimeout(() => {
                dispatch(toggleAlert({ show: false }));
            }, lifeTime);
        }
    }, []);

    return <AlertWrapper type={type}>{text}</AlertWrapper>;
};

export default Alert;
