import styled, { keyframes } from 'styled-components/macro';
import { sizes } from '../../constants/sizes';
import { theme } from '../../constants/theme';
import Split from 'react-split';

export const StyledSplit = styled(Split)`
    display: flex;
    flex-direction: row;
    height: 100%;

    .gutter {
        background-color: #eee;
        background-repeat: no-repeat;
        background-position: 50%;
        z-index: 1;
    }

    .gutter-horizontal {
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==');
        cursor: col-resize;
    }
`;

export const LeftHandWrapper = styled.div`
    position: relative;
`;

export const EditorWrapper = styled.section`
    height: calc(100vh - ${sizes.HEADER_HEIGHT}px);
    width: 100%;
    overflow: hidden;
    /* display: grid; */
    /* grid-template-columns: ${sizes.TOOLS_PANEL_WIDTH + 'px auto ' + sizes.CONTROL_PANEL_WIDTH + 'px'}; */
    /* grid-template-columns: ${'auto ' + sizes.CONTROL_PANEL_WIDTH + 'px'}; */
`;

const appearence = keyframes`
  0% { top: -800px; }
  100% { top: 10px; }
`;
/**
 * Компонент Обертка контекста выделенного объекта.
 */
export const SelectedObjectWrapper = styled.div`
    margin-top: 10px;
    min-width: 180px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 10px;
    background-color: ${theme.palette.white};
    animation: ${appearence} 0.4s ease-in-out;
`;

export const MonitoringLayerOptionsWrapper = styled.div`
    margin-top: 10px;
    width: calc(100% - 100px);
    overflow: auto;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.95);
    animation: ${appearence} 0.4s ease-in-out;
`;

/**
 * Компонент Стилизация библиотечного компонента.
 */
export const StyledText = styled.p`
    margin: 0;
    padding: 0;
    color: ${() => theme.palette.themePrimary};
    font-size: ${() => theme.fonts.large + 'px'};
    margin-bottom: 10px;
`;
