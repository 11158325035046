import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTranslation } from 'react-i18next';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { reducerValues, changeObjectParams, changeIsActive } from '../../../../reducer';
import { StyledCheckbox, StyledStack } from './ObjectOptions.styles';
import { IRadioSensor } from '../../../../interfaces';

import MultiSelect from './components/MultiSelect/MultiSelect';
import { commonTools } from '../../../../../../layers';

const ObjectOptions = () => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, generalSettings } = useSelector(reducerValues);
    const [selectedObject] = objects.filter((item) => item.front_id === selectedObjectId);
    const { t } = useTranslation();

    const StackTokens: IStackTokens = {
        childrenGap: 5,
    };

    const onChange =
        (key: keyof IRadioSensor) =>
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            dispatch(
                changeObjectParams({
                    key,
                    newValue: key === 'marker' ? commonTools.matchChars(newValue) : newValue,
                    front_id: selectedObject.front_id,
                }),
            );
        };

    const onActiveChange = (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
        dispatch(changeIsActive({ isChecked, front_id: selectedObject.front_id }));
    };

    const onSensorTypeChange = (newValue: string) => {
        dispatch(changeObjectParams({ key: 'sensor_types', newValue, front_id: selectedObject.front_id }));
    };

    return selectedObject ? (
        <StyledStack tokens={StackTokens}>
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Marker')}
                value={String(selectedObject.marker)}
                onChange={onChange('marker')}
            />
            <StyledCheckbox
                disabled={generalSettings.readOnly}
                label="Active"
                checked={selectedObject.is_active}
                onChange={onActiveChange}
            />
            <MultiSelect disabled={generalSettings.readOnly} sensor={selectedObject} onChange={onSensorTypeChange} />
            <TextField
                disabled={!selectedObject.sensor_types.includes('wifi_mac_sensor') || generalSettings.readOnly}
                type="number"
                min={1}
                step={0.1}
                label={t('WiFi scanner radius (m)')}
                value={String(selectedObject.wifi_scanner_radius)}
                onChange={onChange('wifi_scanner_radius')}
            />
        </StyledStack>
    ) : null;
};

export default ObjectOptions;
