import React from 'react';
import { IEyeProps } from './Eye.interfaces';
import { Wrapper, EyeIcon } from './Eye.styles';

/**
 * Компонента иконки с глазом.
 */
const Eye = React.memo(({ eyeVisible, id, onEyeClick, ...props }: IEyeProps) => {
    const onClick = (id: string) => (e: React.MouseEvent) => {
        e.stopPropagation();
        onEyeClick && onEyeClick(id);
    };

    return (
        <Wrapper>
            <EyeIcon eyeVisible={eyeVisible} onClick={onClick(id)} />
        </Wrapper>
    );
});

export default Eye;
