import draw from './components/Draw';
import modal from './components/Modal';
import controls from './components/Controls';
import { initialState } from './reducer';

export const stairs_Places_Tuple = {
    alias: initialState.tupleAlias,
    draw,
    modal,
    controls
};
