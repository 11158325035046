import {
	IGroupZones,
	IItemCoords
} from '../../../zones.interfaces';

/**
 * Форматирование координат
 * @param objects
 * @param tempObjects
 */
export const formattingCoords = (objects: IGroupZones, tempObjects: number[][], stageScale: number) => {
	let objArrayTempCoords: IItemCoords[] = [];
	let objsArrayTempCoords: IItemCoords[][] = [];
	tempObjects.forEach((itemTempCoords, indexTempCoords) => {
		let [x, y] = itemTempCoords;
		objArrayTempCoords.push({
			x: x / stageScale,
			y: y / stageScale
		})
	})
	objects.zones.forEach((item, index) => {
		let objArrayItem: any[] = [];
		item.coords.coordinates.forEach((itemTempCoords2, indexTempCoords) => {
			objArrayItem.push({
				x: itemTempCoords2[0] / stageScale,
				y: itemTempCoords2[1] / stageScale
			})
		})
		objsArrayTempCoords.push(objArrayItem);

	})

	return {objects: objsArrayTempCoords, object: objArrayTempCoords};
}