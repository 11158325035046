import { IEscalator } from './../../../Layers/components/Escalators/interfaces';
import { cloneDeep } from 'lodash';
import { IPassWay } from '../../../Layers/components/PassWays/passWays.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ICrossObject, IPlan, IReducer } from './interfaces';
const passways_escalators = require('../../../../lambda/passways_escalators');

export const initialState: IReducer = {
    tupleAlias: 'passways_escalators_tuple',
    intersections: [],
};

export const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        storeIntersections: (
            state,
            action: PayloadAction<{
                escalatorsObjects: Array<IEscalator>;
                passWaysObjects: Array<IPassWay>;
                planScale: number;
                plansList: IPlan[];
            }>,
        ) => {
            const { escalatorsObjects, passWaysObjects, planScale, plansList } = action.payload;

            const allTheFloors = cloneDeep(plansList)
                .filter((item: IPlan) => item.isActual)
                .map((item: IPlan) => item.floor);

            const intersections = passways_escalators.get_intersections({
                escalatorsObjects,
                passWaysObjects,
                planScale,
                allTheFloors,
            });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
