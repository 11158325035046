import { USER_INTERFACE_STATE } from '../constants/localStorageKeys';
import { TUserInterfaceState } from '../components/Editor/Editor.interfaces';

/**
 * Функция для получения состояние пользовательского интерфейса
 */
const getUserInterfaceState = (): TUserInterfaceState => {
    const userInterfaceStateFromLocalStorage = localStorage.getItem(USER_INTERFACE_STATE);

    let parsedUserInterfaceState: TUserInterfaceState = null;

    try {
        if (userInterfaceStateFromLocalStorage) {
            parsedUserInterfaceState = JSON.parse(userInterfaceStateFromLocalStorage);
        }
    } catch (error) {
        console.log(`Ann error accrued while parsing local storage user interface state!`, error);
    }

    return parsedUserInterfaceState;
};

export default getUserInterfaceState;
