import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    trackingTracksValues,
    storeInitialCreatedAt,
    storeInitialObjects,
    toggleShowLabels,
    selectTrack,
    storeCurrentPlanData,
    storeGeneralSettings,
    deleteTrack,
    clearSelectedObjects,
    storeHotAddObject,
} from '../../../../reducer';
import { ObjectsListWrapper, ObjectWrapper, classNames } from './ObjectsList.styles';

import { FontIcon } from '@fluentui/react/lib/Icon';
import { commonTools } from '../../../../../../layers';

/**
 * Список объектов
 * @param props
 * @constructor
 */
const ObjectsList = ({ ...props }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { objects, createdAt, trackFrontId, generalSettings } = useSelector(trackingTracksValues);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        props.revertLayer && dispatch(storeInitialObjects(props.initialData.data || []));
    }, [props.revertLayer]);

    useEffect(() => {
        props.layerChange({ id: 'tracks_navigation_layer', objects, lastCreatedAt: createdAt, selectedObjectId: null });
    }, [objects]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        if (!props?.initialData) return;
        dispatch(storeInitialObjects(props.initialData.data || []));
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(clearSelectedObjects());
    }, [props.active]);

    const onDelete = (frontId: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(deleteTrack({ trackFrontId: frontId }));
    };

    const onClickObject = (trackFrontId: string) => () => {
        dispatch(selectTrack({ trackFrontId }));
    };

    const objectsList = objects.map((object) => {
        const selected = object.front_id === trackFrontId;
        return (
            <ObjectWrapper key={object.front_id} selected={selected} onClick={onClickObject(object.front_id)}>
                {commonTools.getObjectLabelByMode({
                    objNamingMode: props?.generalSettings?.objNamingMode,
                    fallbackText: object.front_id,
                })}
                {!generalSettings.readOnly && (
                    <FontIcon iconName="Delete" className={classNames.salmon} onClick={onDelete(object.front_id)} />
                )}
            </ObjectWrapper>
        );
    });

    return <ObjectsListWrapper>{objectsList}</ObjectsListWrapper>;
};

export default ObjectsList;
