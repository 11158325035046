import styled from 'styled-components/macro';
import React from 'react';
import { IPlan } from '../../Plans/Plans.interfaces';
import { DateTime } from 'luxon';

const LeasingPlan: React.FC<{ plan: IPlan }> = ({ plan }) => {
    return (
        <Container>
            <Cell>{`floor: ${plan.floor}`}</Cell>
            <Cell>{`Id: ${plan.planId}`}</Cell>
            <Cell>{`Active from: ${DateTime.fromISO(plan.activeFrom as string).toLocaleString(
                DateTime.DATE_MED
            )}`}</Cell>
        </Container>
    );
};

export default LeasingPlan;

const Container = styled.div`
    display: grid;
    grid-template-columns: 70px 70px auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 5px;
`;

const Cell = styled.div`
    display: flex;
    align-items: center;
`;
