import { store } from '../../store';
import { Provider } from 'react-redux';
import React from 'react';
import Modal from './Modal';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <Modal {...props} />
        </Provider>
    );
};
