import { useSelector } from 'react-redux';
import { useState } from 'react';
import { executeRequest } from './apiHelpers';
import { useTranslation } from 'react-i18next';
import { generalReducerValues } from '../../../../../../General.reducer';
import { useParams } from 'react-router-dom';

export type RequestStatus = {
    url: string;
    method: string;
    status: 'pending' | 'success' | 'error' | 'loading';
    message: string;
    productId?: number;
    locationId?: number;
};

export const useCopyAppParams = () => {
    const { projectId } = useParams<{ projectId: string }>();
    const { t } = useTranslation();
    const {
        urls: { COPY_ACCOUNT_PARAMETERS_URL },
        token,
    } = useSelector(generalReducerValues);

    const [requestStatuses, setRequestStatuses] = useState<RequestStatus[]>([]);

    const addRequestStatus = (url: string, method: string, status: RequestStatus['status'], message: string) => {
        setRequestStatuses(() => [{ url, method, status, message }]);
    };

    const fetchData = async (donorId: string, acceptorId: string) => {
        try {
            addRequestStatus(
                `${COPY_ACCOUNT_PARAMETERS_URL}`,
                'POST',
                'pending',
                t(`Copy parameters request in progress...`),
            );
            const response = await executeRequest({
                url: `${COPY_ACCOUNT_PARAMETERS_URL}`,
                token,
                method: 'POST',
                data: { project_id: Number(projectId), user_id_from: Number(donorId), user_id_to: Number(acceptorId) },
            }).then((response) => response[0]);
            addRequestStatus(
                `${COPY_ACCOUNT_PARAMETERS_URL}`,
                'POST',
                'success',
                t(`Copy parameters request completed successfully`),
            );
            return { response };
        } catch (error) {
            addRequestStatus(
                `${COPY_ACCOUNT_PARAMETERS_URL}`,
                'POST',
                'error',
                `${t('Copy parameters error')}: ${error instanceof Error ? error.message : 'Unknown error'}`,
            );
            console.error(`Copy parameters request failed:`, error);
            return null;
        }
    };

    const handleCopyAppParams = async (donorId: string, acceptorId: string) => {
        const donorResult = await fetchData(donorId, acceptorId);
    };
    return { handleCopyAppParams, requestStatuses };
};
