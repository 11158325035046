import React, { useState, useContext } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';

import { useTranslation } from 'react-i18next';

import { MainWrapper, columnProps, footerProps, Footer } from './styles';
import { commonTools } from '../../../../../Layers/layers';

/**
 * Компонент для добавления новой локации.
 *
 * @param onOKClick Внешний обработчик кнопки ОК.
 */
const NewUserModal = ({
    onOKClick,
    closeModal,
}: {
    onOKClick: (pass: string, email: string) => void;
    closeModal: () => void;
}) => {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const onEmailChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setEmail(newValue || '');
        if (newValue && validateEmail(newValue)) {
            setButtonDisabled(() => false);
        } else {
            setButtonDisabled(() => true);
            // return 'Enter valid e-mail!';
        }
    };

    const onCreateClick = () => {
        const pass = commonTools.generateId(16);
        onOKClick(pass, email);
    };

    function validateEmail(email: string) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }

    // const getErrorMessage = (value: string): string => {
    //     console.log('value', value);

    //     if (validateEmail(value)) {
    //         setButtonDisabled(() => false);
    //         return '';
    //     } else {
    //         setButtonDisabled(() => true);
    //         return 'Enter valid e-mail!';
    //     }
    // };

    return (
        <MainWrapper>
            <Stack {...columnProps}>
                <TextField
                    label={t('User e-mail')}
                    placeholder={t('Enter valid e-mail')}
                    value={email}
                    // onGetErrorMessage={getErrorMessage}
                    onChange={onEmailChange}
                    required
                    type="email"
                />
            </Stack>
            <Footer>
                <DefaultButton text={t('Cancel')} onClick={closeModal} allowDisabledFocus />
                <PrimaryButton
                    text={t('Create')}
                    onClick={onCreateClick}
                    allowDisabledFocus
                    disabled={buttonDisabled}
                />
            </Footer>
        </MainWrapper>
    );
};

export default NewUserModal;
