import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTranslation } from 'react-i18next';
import { Stack, IStackProps, IStackTokens } from '@fluentui/react/lib/Stack';
import { Checkbox } from '@fluentui/react/lib/Checkbox';

import { saveDataPlace, placesValues } from '../../../../places.reducer';

import { AreaWarning, StyledStack } from './ObjectOptions.styles';
import { commonTools } from '../../../../../../layers';
import Flatten from '@flatten-js/core';
import { Label } from 'react-konva';

/**
 * Опции для выбюраного объекта
 * @constructor
 */
const ObjectOptions = () => {
    const [markerPlace, setMarkerPlace] = useState<string | undefined>('');
    const [userArea, setUserArea] = useState<string>('');
    const [calculatedArea, setCalculatedArea] = useState<number>(0);
    const [isOpened, setIsOpened] = useState<boolean>(true);
    const [errorMassage, setErrorMassage] = useState<string | undefined>(undefined);
    const { selectedObjects, placesIndexByPlacesFrontId, generalSettings, currentPlanData } = useSelector(placesValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { Polygon } = Flatten;

    const StackTokens: IStackTokens = {
        childrenGap: 5,
    };

    useEffect(() => {
        setMarkerPlace(selectedObjects?.marker);
        setUserArea(String(selectedObjects?.user_area || ''));
        setIsOpened(selectedObjects?.is_opened!);
        const placeCoords = selectedObjects?.coords.coordinates;
        const placeArea = new Polygon(placeCoords as any[]).area() / Math.pow(currentPlanData?.planScale || 1, 2);
        setCalculatedArea(() => placeArea);
    }, [Polygon, currentPlanData?.planScale, selectedObjects]);

    const areaWarning = useMemo(() => {
        if (
            userArea &&
            calculatedArea &&
            (Number(userArea) / calculatedArea > 2 || Number(userArea) / calculatedArea < 0.5)
        ) {
            return <AreaWarning>{t('Calculated and user areas are too different!')}</AreaWarning>;
        } else {
            return null;
        }
    }, [calculatedArea, t, userArea]);

    if (selectedObjects !== null) {
        const onChangeMarker = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            if (placesIndexByPlacesFrontId![newValue!] === undefined) {
                setMarkerPlace(commonTools.matchChars(newValue));
                setErrorMassage(undefined);
                dispatch(
                    saveDataPlace({
                        isOpened,
                        marker: commonTools.matchChars(newValue),
                        userArea,
                    }),
                );
            } else {
                setMarkerPlace(commonTools.matchChars(newValue));
                setErrorMassage(t('Such a marker already exists'));
            }
        };

        const onChangeUserArea = (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
        ) => {
            const userArea = newValue && isFinite(Number(newValue)) && Number(newValue) >= 0 ? newValue : '';
            setUserArea(() => userArea);
            dispatch(
                saveDataPlace({
                    isOpened,
                    marker: markerPlace,
                    userArea,
                }),
            );
        };

        const onChange = (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
            if (!errorMassage) {
                dispatch(
                    saveDataPlace({
                        isOpened: isChecked!,
                        marker: markerPlace,
                        userArea,
                    }),
                );
            }
            setIsOpened(isChecked!);
        };

        return (
            <StyledStack tokens={StackTokens}>
                <TextField
                    disabled={generalSettings.readOnly}
                    underlined
                    label={t('Marker')}
                    value={String(markerPlace)}
                    onChange={onChangeMarker}
                    errorMessage={errorMassage}
                />
                {/* <Label>{`${t('Calculated area')}: ${Math.round(calculatedArea)}${t('m²')}`}</Label> */}
                <TextField
                    underlined
                    label={t('Calculated area')}
                    value={`${calculatedArea.toFixed(2)}${t('m²')}`}
                    onChange={() => {}}
                />
                <TextField
                    type="number"
                    min={0.1}
                    step={0.1}
                    disabled={generalSettings.readOnly}
                    underlined
                    label={t('User area')}
                    value={String(userArea)}
                    onChange={onChangeUserArea}
                />
                {areaWarning}
                <Checkbox
                    disabled={generalSettings.readOnly}
                    label="Is opened"
                    checked={isOpened}
                    onChange={onChange}
                />
            </StyledStack>
        );
    }

    return null;
};

export default ObjectOptions;
