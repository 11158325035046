import styled from 'styled-components/macro';
import React from 'react';
import { IListItem } from '../../../TablesPanel/TablesPanel.interfaces';
import { theme } from '../../../../../../constants/theme';

const ListItem: React.FC<IListItem> = ({ table, selected, onClick, disabled }) => {
    const onTableSelect = (tableId: string) => () => {
        onClick(tableId);
    };
    return (
        <Container onClick={onTableSelect(table.tableId)} disabled={disabled}>
            <Cell selected={selected}>{`${table.tableName}`}</Cell>
        </Container>
    );
};

export default ListItem;

const Container = styled.div<{ disabled: boolean }>`
    opacity: ${(p) => (p.disabled ? 0.5 : 1)};
    pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
    display: grid;
    grid-template-columns: 70px 70px auto;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding: 5px;
    cursor: pointer;
`;

const Cell = styled.div<{ selected: boolean }>`
    color: ${(p) => (p.selected ? theme.palette.themePrimary : 'black')};
    font-weight: ${(p) => (p.selected ? '700' : '400')};

    display: flex;
    align-items: center;
`;
