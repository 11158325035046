import { DefaultButton, PrimaryButton } from '@fluentui/react';
import { DateTime } from 'luxon';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { generalReducerValues } from '../../General.reducer';
import Modals from '../Modals/Modals';
import { ButtonsWrapper, ContentWrapper, Wrapper } from './styles';

const TokenExpiration: React.FC = () => {
    const [modalStatus, setModalStatus] = useState({ show: false, hardReset: false });
    const { current } = useSelector(generalReducerValues);
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            if (!current?.token_expires) return;
            const now = DateTime.now();
            const diff = DateTime.fromISO(current?.token_expires as string).diff(now, ['minutes', 'seconds']);
            const minutesToExpire = diff.toObject().minutes || 60;
            if (minutesToExpire < 15) {
                onRestart();
                // setModalStatus({ show: true, hardReset: true });
            } else if (minutesToExpire < 60) {
                if (!modalStatus.show) {
                    setModalStatus({ ...modalStatus, show: true });
                }
            }
        }, 600000);

        return () => clearTimeout(interval);
    }, [modalStatus, modalStatus.show, current?.token_expires]);

    const closeModal = () => {
        setModalStatus({ ...modalStatus, show: false });
    };

    const onRestart = () => {
        localStorage.removeItem('xtoken');
        window.location.reload();
    };

    return (
        <Modals topColor="red" modalStatus={modalStatus} title={t('Session expiration')} closeModal={closeModal}>
            <Wrapper>
                <ContentWrapper>
                    <div>{t('Your session will expire soon! You need to restart browser.')}</div>
                </ContentWrapper>
                <ButtonsWrapper>
                    <DefaultButton onClick={closeModal}>{t('Cancel')}</DefaultButton>
                    <PrimaryButton onClick={onRestart}>{t('Restart')}</PrimaryButton>
                </ButtonsWrapper>
            </Wrapper>
        </Modals>
    );
};

export default TokenExpiration;
