import React from 'react';
import useTable from './hooks/useTable';
import ReactDataSheet from 'react-datasheet';
import { ICell } from './MonitoringDetailedData.interfaces';
import { ModalWrapper, TableWrapper } from './MonitoringDetailedData.styles';
import CellRenderer from './components/CellRenderer/CellRenderer';

const MonitoringDetailedData = () => {
    const table = useTable();

    const cellRenderer = (props: ReactDataSheet.CellRendererProps<ICell>) => {
        return <CellRenderer {...props} />;
    };

    return (
        <ModalWrapper>
            <TableWrapper>
                <ReactDataSheet valueRenderer={(cell: ICell) => cell.value} cellRenderer={cellRenderer} data={table} />
            </TableWrapper>
        </ModalWrapper>
    );
};

export default MonitoringDetailedData;
