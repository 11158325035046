import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    perimeterValues,
    storeCurrentPlanData,
    storeInitialCreatedAt,
    storeInitialObjects,
    toggleShowLabels,
    selectPerimeter,
    storeGeneralSettings,
    deletePerimeter,
    clearSelectedObjects,
    storeHotAddObject,
    pasteObjectFromClipboard,
    storeVersionsData,
} from '../../../../perimeter.reducer';
import { ObjectsListWrapper, ObjectWrapper, classNames } from './ObjectsList.styles';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { commonTools } from '../../../../../../layers';

/**
 * Список объектов
 * @param props
 * @constructor
 */
const ObjectsList = ({ ...props }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { objects, createdAt, perimeterFrontId, generalSettings } = useSelector(perimeterValues);

    useEffect(() => {
        if (props.clipboardData) {
            if (!perimeterFrontId) {
                props.toggleAlertTrans &&
                    props.toggleAlertTrans({
                        text: t('Perimeter not selected'),
                        show: true,
                    });
            } else {
                dispatch(pasteObjectFromClipboard(props.clipboardData.obj));
            }
        }
    }, [props.clipboardData]);

    useEffect(() => {
        props.versionsData && dispatch(storeVersionsData(props.versionsData));
    }, [props.versionsData]);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        props.revertLayer && dispatch(storeInitialObjects(props.initialData.data || []));
    }, [props.revertLayer]);

    useEffect(() => {
        props.layerChange({ id: 'perimeter_layer', objects, lastCreatedAt: createdAt, selectedObjectId: perimeterFrontId });
    }, [objects, perimeterFrontId]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        if (!props?.initialData) return;
        dispatch(storeInitialObjects(props.initialData.data || []));
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(clearSelectedObjects());
    }, [props.active]);
    const onDelete = (frontId: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(deletePerimeter({ perimeterFrontId: frontId }));
    };

    const onClickObject = (perimeterFrontIdTemp: string) => () => {
        dispatch(selectPerimeter({ perimeterFrontId: perimeterFrontIdTemp }));
    };

    const objectsList = objects.map((object, index) => {
        const selected = object.front_id === perimeterFrontId;
        return (
            <ObjectWrapper key={object.front_id} selected={selected} onClick={onClickObject(object.front_id)}>
                {commonTools.getObjectLabelByMode({
                    objNamingMode: props?.generalSettings?.objNamingMode,
                    fallbackText: index.toString(),
                })}
                {!generalSettings.readOnly && (
                    <FontIcon iconName="Delete" className={classNames.salmon} onClick={onDelete(object.front_id)} />
                )}
            </ObjectWrapper>
        );
    });

    return <ObjectsListWrapper>{objectsList}</ObjectsListWrapper>;
};

export default ObjectsList;
