import { IPassWay } from '../../../Layers/components/PassWays/passWays.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IReducer } from './interfaces';
import { ILift } from '../../../Layers/components/Lifts/interfaces';
const passways_lifts = require('../../../../lambda/passways_lifts');

export const initialState: IReducer = {
    tupleAlias: 'passways_lifts_tuple',
    intersections: [],
};

export const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        storeIntersections: (
            state,
            action: PayloadAction<{ liftsObjects: Array<ILift>; passWaysObjects: Array<IPassWay>; planScale: number }>,
        ) => {
            const { liftsObjects, passWaysObjects, planScale } = action.payload;
            const intersections = passways_lifts.get_intersections({
                objects: liftsObjects,
                passWaysObjects,
                planScale,
            });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
