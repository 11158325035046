import React from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

import { Layer, Rect } from 'react-konva';
import { getPointerCoords } from '../../core/getPointerCoords';
import { IHelperProps } from './Helper.interfaces';

const Helper = ({ updateCoords, coords, onHelperClick, isDrawNow }: IHelperProps) => {
    const onHelperMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (isDrawNow === true) {
            const newPoint = getPointerCoords(e);
            if (newPoint) {
                const newCoords = [coords[0], newPoint];
                updateCoords(newCoords);
            }
        }
    };

    return (
        <Rect
            onMouseMove={onHelperMouseMove}
            onClick={onHelperClick}
            height={20000}
            width={20000}
            // fill={'rgba(255,0,0,0.3)'}
            fill={'transparent'}
        />
    );
};

export default Helper;
