import styled from 'styled-components/macro';
import { Stack, IStackProps, IStackTokens } from '@fluentui/react/lib/Stack';
import { mergeStyles, mergeStyleSets, TextField } from '@fluentui/react';

export const ObjectOptionsWrapper = styled.div``;
export const Divider = styled.div`
    border-bottom: 1px solid rgb(96, 94, 92);
    margin: 5px 0;
`;

export const ObjectWrapper = styled.div<{ selected: boolean }>`
    cursor: pointer;
    padding: 1px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 7px !important;
    justify-content: space-between;
`;
export const StyledStack = styled(Stack)`
    .ms-TextField-fieldGroup {
        background-color: transparent;
    }
    label {
        padding-left: 0px;
    }
`;

export const StyledTextField = styled(TextField)`
    * {
        border: 0 !important;
    }
`;

const iconClass = mergeStyles({
    fontSize: 12,
    height: 16,
    width: 16,
});

export const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'greenyellow' }, iconClass],
    salmon: [{ color: 'salmon' }, iconClass],
});
