import styled from 'styled-components/macro';

export const ErrorWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 0 0 0;
    font-size: 30px;
`;
