import React, { useContext } from 'react';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';
import { useTranslation } from 'react-i18next';

import { SpinnerWrapper } from './Spinner.styles';
import { GeneralContext } from '../../General.context';

/**
 * Спиннер.
 */
const SpinnerComponent: React.FunctionComponent = () => {
    const { t } = useTranslation();
    const { theme } = useContext(GeneralContext);
    const { neutralLighterAlt } = theme.palette;

    return (
        <SpinnerWrapper bgcolor={neutralLighterAlt}>
            <Spinner label={t('Loading...')} ariaLive="assertive" labelPosition="top" size={SpinnerSize.large} />
        </SpinnerWrapper>
    );
};

export default SpinnerComponent;
