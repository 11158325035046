import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { IconButton, PrimaryButton } from '@fluentui/react/lib/Button';
import { useDispatch, useSelector } from 'react-redux';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { useTranslation } from 'react-i18next';
import { ICopyArgs, IProps } from './interfaces';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import {
    MainWrapper,
    iconButtonStyles,
    cancelIcon,
    flickRight,
    flickLeft,
    WorkArea,
    Panel,
    dropdownStyles,
    ItemWrapper,
    IconsWrapper,
    Header,
    ButtonsWrapper,
    StyledCheckbox,
} from './styles';
import {
    reducerValues,
    storeLocationId,
    storeToken,
    fetchVersions,
    fetchLayers,
    storeLayers,
    resetReducer,
} from './reducer';
import './styles.scss';
import StyledTreeItem from './components/StyledTreeItem';

import TreeView from '@mui/lab/TreeView';
import { CloseSquare, MinusSquare, PlusSquare } from './components/Icons';
import { Checkbox } from '@fluentui/react';
import { useAppDispatch } from '../../store';

/**
 * Компонент для отображения Разницы в версиях
 */
const VersionsDiff: React.FC<IProps> = ({ show, collapsed, locationId, token, copyObject, ...props }) => {
    const dispatch = useDispatch<any>();
    const { versions, leftLayersByFloor, rightLayersByFloor, diffs } = useSelector(reducerValues);
    const [selectedLeft, setSelectedLeft] = React.useState<IDropdownOption | undefined>(undefined);
    const [showChangesOnly, setShowChangesOnly] = React.useState(true);
    const [selectedRight, setSelectedRight] = React.useState<IDropdownOption | undefined>(undefined);
    const [expanded, setExpanded] = React.useState<string[]>([]);

    useEffect(() => {
        show && locationId && dispatch(storeLocationId(locationId));
        show && token && dispatch(storeToken(token));
        show && token && locationId && dispatch(fetchVersions({ token, locationId }));
    }, [locationId, token, show]);

    useEffect(() => {}, [leftLayersByFloor]);

    useEffect(() => {
        setSelectedLeft(undefined);
        setSelectedRight(undefined);
    }, [versions]);

    useEffect(() => {
        setSelectedRight(undefined);
        selectedLeft?.key && dispatch(fetchLayers({ versionId: selectedLeft.key, panel: 'left' }));
    }, [selectedLeft]);

    useEffect(() => {
        if (selectedRight?.key) {
            dispatch(fetchLayers({ versionId: selectedRight.key, panel: 'right' }));
        } else if (!selectedRight) {
            dispatch(storeLayers({ layers: undefined, panel: 'right' }));
        }
    }, [selectedRight]);

    useEffect(() => {
        !show && dispatch(resetReducer());
    }, [show]);

    const rightDropDown = useMemo(() => {
        const onChangeRight = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
            setSelectedRight(() => item);
        };
        const rightOptions = versions
            .filter((ver) => {
                const [leftVersion] = versions.filter((item) => String(item.id) === String(selectedLeft?.key));
                return String(ver.id) !== String(selectedLeft?.key) && ver.date_from > leftVersion?.date_from;
            })
            .map((ver) => {
                return { key: ver.id, text: `Version id: ${ver.id}, Dates: ${ver.date_from} - ${ver.date_to}` };
            });
        const selectedKey = selectedRight?.key ? selectedRight.key : undefined;
        return (
            <Dropdown
                disabled={!selectedLeft}
                selectedKey={selectedKey}
                onChange={onChangeRight}
                placeholder="Select version 2"
                options={rightOptions}
                styles={dropdownStyles}
                notifyOnReselect={true}
            />
        );
    }, [selectedLeft, selectedRight, versions]);

    const onCopy = (args: ICopyArgs) => () => {
        copyObject && copyObject(args);
    };

    const tree = (panel: string) => {
        const layersByFloor = panel === 'left' ? leftLayersByFloor : rightLayersByFloor;
        return Object.keys(layersByFloor)
            .sort((a, b) => Number(b) - Number(a))
            .map((floor) => {
                let floorBgColor = '';
                const layers = layersByFloor[floor].map((layer) => {
                    let layerDiffs: number[] = [];
                    if (diffs?.hasOwnProperty(floor)) {
                        if (diffs[floor][panel][layer.layer_type]) {
                            layerDiffs.push(...diffs[floor][panel][layer.layer_type]);
                        }
                    }
                    let layerBgColor = '';
                    const objects =
                        layer?.data && Array.isArray(layer.data)
                            ? layer.data.map((obj: { [x: string]: any }, i: number) => {
                                  let bgColor = '';
                                  //   layerBgColor = bgColor;
                                  //   floorBgColor = bgColor;
                                  if (layerDiffs.includes(i)) {
                                      bgColor = panel === 'left' ? '#e7092a' : '#008800';
                                      layerBgColor = bgColor;
                                      floorBgColor = bgColor;
                                  }
                                  let objName = '';
                                  if (obj.marker) {
                                      objName = obj.marker;
                                  } else if (obj.id) {
                                      objName = obj.id;
                                  } else if (obj.front_id) {
                                      objName = obj.front_id;
                                  } else if (obj.frontId) {
                                      objName = obj.frontId;
                                  }

                                  const Copy = () => {
                                      return (
                                          <CopyToClipboard
                                              text={JSON.stringify({ floor, layer_type: layer.layer_type, obj })}
                                              onCopy={onCopy({ floor, layer_type: layer.layer_type, obj })}
                                          >
                                              <FontIcon
                                                  iconName="Copy"
                                                  style={{ cursor: 'pointer', marginRight: '10px' }}
                                              />
                                          </CopyToClipboard>
                                      );
                                  };

                                  return bgColor === '' && showChangesOnly ? null : (
                                      <ItemWrapper bgColor={bgColor} key={objName}>
                                          <StyledTreeItem
                                              nodeId={`${floor}:${objName}`}
                                              label={objName}
                                              className={bgColor}
                                          ></StyledTreeItem>
                                          <Copy />
                                      </ItemWrapper>
                                  );
                              })
                            : null;
                    return layerBgColor === '' && showChangesOnly ? null : (
                        <StyledTreeItem
                            key={`${floor}:${layer.layer_type}`}
                            nodeId={`${floor}:${layer.layer_type}`}
                            label={layer.layer_type}
                            className={layerBgColor}
                        >
                            {objects}
                        </StyledTreeItem>
                    );
                });

                return (
                    <ItemWrapper bgColor={floorBgColor} key={`UUUttt${floor}`}>
                        <StyledTreeItem nodeId={floor} className={floorBgColor} label={`Floor: ${floor}`}>
                            {layers}
                        </StyledTreeItem>
                    </ItemWrapper>
                );
            });
    };

    if (!show) return null;

    const closeDiff = () => {
        props.closeDiff && props.closeDiff();
    };

    const toggleCollapseDiff = () => {
        props.toggleCollapseDiff && props.toggleCollapseDiff();
    };

    const leftOptions = versions
        // .filter((ver) => String(ver.id) !== String(selectedRight?.key))
        .map((ver) => {
            return { key: ver.id, text: `Version id: ${ver.id}, Dates: ${ver.date_from} - ${ver.date_to}` };
        });

    const onChangeLeft = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        setSelectedLeft(item);
    };

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        const floors = nodeIds.filter((item) => !item.includes(':'));
        const lays = nodeIds.filter((item) => item.includes(':'));
        const nodes = lays[0] ? [lays[0], ...floors] : floors;
        setExpanded(nodes);

        // Если хотим чтобы все разворачивалось:
        // setExpanded(nodeIds);
    };

    const onRefreshClick = () => {
        selectedLeft?.key && dispatch(fetchLayers({ versionId: selectedLeft.key, panel: 'left' }));
        if (selectedRight?.key) {
            dispatch(fetchLayers({ versionId: selectedRight.key, panel: 'right' }));
        }
    };

    function onShowChangesOnlyChange(ev?: React.FormEvent<HTMLElement | HTMLInputElement>, isChecked?: boolean) {
        setShowChangesOnly(() => isChecked || false);
    }

    return ReactDOM.createPortal(
        <MainWrapper collapsed={collapsed}>
            <Header>
                {!collapsed && (
                    <ButtonsWrapper>
                        <PrimaryButton text={'Refresh'} onClick={onRefreshClick} allowDisabledFocus />
                        <StyledCheckbox
                            checked={showChangesOnly}
                            onChange={onShowChangesOnlyChange}
                            label={'Show changes only'}
                            boxSide="start"
                        />
                    </ButtonsWrapper>
                )}
                <IconsWrapper collapsed={collapsed}>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={collapsed ? flickRight : flickLeft}
                        ariaLabel="Close popup modal"
                        onClick={toggleCollapseDiff}
                    />
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={closeDiff}
                    />
                </IconsWrapper>
            </Header>
            <WorkArea>
                <Panel>
                    <Dropdown
                        selectedKey={selectedLeft ? selectedLeft.key : undefined}
                        onChange={onChangeLeft}
                        placeholder="Select version 1"
                        options={leftOptions}
                        styles={dropdownStyles}
                    />
                    <TreeView
                        aria-label="customized"
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                        sx={{
                            height: 'auto',
                            maxHeight: '100%',
                            flexGrow: 1,
                            overflowY: 'auto',
                            paddingTop: '10px',
                            paddingBottom: '30px',
                        }}
                    >
                        {tree('left')}
                    </TreeView>
                </Panel>
                <Panel>
                    {rightDropDown}
                    <TreeView
                        aria-label="customized"
                        expanded={expanded}
                        onNodeToggle={handleToggle}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                        sx={{
                            height: 'auto',
                            maxHeight: '100%',
                            flexGrow: 1,
                            overflowY: 'auto',
                            paddingTop: '10px',
                            paddingBottom: '30px',
                        }}
                    >
                        {tree('right')}
                    </TreeView>
                </Panel>
            </WorkArea>
        </MainWrapper>,
        document.body,
    );
};

export default VersionsDiff;
