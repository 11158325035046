import React, { useState, useEffect } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Group } from 'react-konva';

import { IDrawPassWayProps } from './Object.interfaces';
import { getCentroid } from './core/getCentroid';
import { ICheckout } from '../../../../checkouts.interfaces';
import CheckoutIcon from './components/CheckoutIcon/CheckoutIcon';
import Area from './components/Area/Area';
import { ObjectLabel } from '../../../../../../layers';

/**
 * Компонент объекта касса
 */
const CheckoutObject = (props: IDrawPassWayProps) => {
    const {
        item,
        showLabels,
        planScale = 1,
        stageScale,
        selected,
        onClick,
        onDragEnd,
        onHelperMouseMove,
        updateCanTransform = () => {},
        activeToolId,
        onMouseDown
    } = props;

    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [initialItem, setInitialItem] = useState<ICheckout | null>(null);
    const [deskCenter, setDeskCenter] = useState<Array<number> | null>(null);
    const [customerAreaCenter, setCustomerAreaCenter] = useState<{ x: number; y: number } | null>(null);
    const [staffAreaCenter, setStaffAreaCenter] = useState<{ x: number; y: number } | null>(null);

    const draggable = (activeToolId === 'resize' || activeToolId === 'rotate') && selected;

    useEffect(() => {
        setInitialItem({ ...item });
        setCustomerAreaCenter(getCentroid(item.customerArea));
        setStaffAreaCenter(getCentroid(item.staffArea));
        setDeskCenter([...item.checkoutPoint.coordinates]);
    }, [JSON.stringify(item)]);

    /**
     * Обработчик перетаскивания
     */
    const onDragMove = (mode: string) => (e: KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (!stage || !initialItem) return;
        const deltaX = (e.target.getAbsolutePosition().x - stage.attrs.x) / stageScale;
        const deltaY = (e.target.getAbsolutePosition().y - stage.attrs.y) / stageScale;
        if (mode === 'customer') {
            const initialCoords = getCentroid(initialItem?.customerArea);
            const coords = { x: 0, y: 0 };
            coords.x = initialCoords.x + deltaX;
            coords.y = initialCoords.y + deltaY;
            setCustomerAreaCenter(coords);
        } else if (mode === 'staff') {
            const initialCoords = getCentroid(initialItem?.staffArea);
            const coords = { x: 0, y: 0 };
            coords.x = initialCoords.x + deltaX;
            coords.y = initialCoords.y + deltaY;
            setStaffAreaCenter(coords);
        } else if (mode === 'desk') {
            const initialCoords = initialItem.checkoutPoint.coordinates;
            const x = initialCoords[0] + deltaX;
            const y = initialCoords[1] + deltaY;
            setDeskCenter([x, y]);
        }
    };

    /**
     * Обработчик конца перетаскивания
     */
    const onShapeDragEnd = (mode: string) => (e: KonvaEventObject<MouseEvent>) => {
        const stage = e.target.getStage();
        if (!stage || !initialItem) return;
        const dx = (e.target.getAbsolutePosition().x - stage.attrs.x) / stageScale;
        const dy = (e.target.getAbsolutePosition().y - stage.attrs.y) / stageScale;
        onDragEnd && onDragEnd({ coordsChange: { dx, dy }, id: item.id, mode });
        e.target.setAbsolutePosition(position);
    };

    /**
     * Обработчик клика
     */
    const onShapeClick = (id: string | undefined) => (e: KonvaEventObject<MouseEvent>) => {
        onClick && onClick(e, id);
    };

    /**
     * Обработчик MouseDown на элементах объекта
     */
    const onShapeMouseDown = (e: KonvaEventObject<MouseEvent>) => {
        setPosition(e.target.getAbsolutePosition());
    };

    /**
     * Обработчик MouseDown на узлах трансформации
     */
    const onTransformNodeMouseDown = (mode: string) => (e: KonvaEventObject<MouseEvent>) => {
        updateCanTransform(e, true, mode);
    };

    /**
     * Обработчик MouseUp
     */
    const onMouseUp = (e: KonvaEventObject<MouseEvent>) => {
        updateCanTransform(e, false, null);
    };

    /**
     * Обработчик движения мыши
     */
    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        onHelperMouseMove && onHelperMouseMove({ e });
    };

    const partProps = {
        item,
        draggable,
        planScale,
        stageScale,
        selected,
        onMouseMove,
        onMouseUp,
        onDragMove,
        onShapeDragEnd,
        onShapeClick,
        onShapeMouseDown,
    };

    const areaProps = { ...partProps, onTransformNodeMouseDown, deskCenter, customerAreaCenter, staffAreaCenter };

    const _onMouseDown = (e: any) => {
        onMouseDown(item.id, e);
    };

    return (
        <Group
        onMouseDown={_onMouseDown}
        >
            <Area {...areaProps} mode={'customer'} />
            <Area {...areaProps} mode={'staff'} />
            <CheckoutIcon {...partProps} />
            {showLabels && (
                <ObjectLabel
                    stageScale={stageScale}
                    x={item.checkoutPoint.coordinates[0]}
                    y={item.checkoutPoint.coordinates[1]}
                    marker={item.marker}
                    selected={selected}
                />
            )}
        </Group>
    );
};

export default CheckoutObject;
