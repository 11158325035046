import { IPassWay } from '../../../Layers/components/PassWays/passWays.interfaces';
import { IPCC } from '../../../Layers/components/PC_IPoints/interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { ICrossObject, IReducer } from './interfaces';
const passways_ipoints = require('../../../../lambda/passways_ipoints');

export const initialState: IReducer = {
    tupleAlias: 'passways_pcc_tuple',
    intersections: [],
};

export const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        storeIntersections: (
            state,
            action: PayloadAction<{ pccObjects: Array<IPCC>; passWaysObjects: Array<IPassWay>; planScale: number }>
        ) => {
            const { pccObjects, passWaysObjects, planScale } = action.payload;
            const intersections = passways_ipoints.get_intersections({
                ipointsObjects: pccObjects,
                passWaysObjects,
                planScale,
            });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
