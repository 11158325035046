import styled from 'styled-components/macro';

export const SpinnerWrapper = styled.section<{ bgcolor: string }>`
    width: 100%;
    height: 100vh;
    background-color: ${(p) => p.bgcolor};
    opacity: 0.95;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000000;
`;
