import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './measureCut.store';

import { IPlanMeasureCutReducer, ICoords } from './measureCut.interfaces';

const initialState: IPlanMeasureCutReducer = {
    activeToolId: null,
    coords: [],
};

export const measureCutReducer = createSlice({
    name: 'measureCutReducer',
    initialState,
    reducers: {
        /**
         * Переключение активного инструмента.
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Изменение координат.
         */
        changeCoords: (state, action: PayloadAction<ICoords>) => {
            state.coords = action.payload;
        },
    },
});

export const { toggleTool, changeCoords } = measureCutReducer.actions;

export const measureCutReducerValues = (state: RootState) => state.measureCutReducer;

export default measureCutReducer.reducer;
