// export const hosts = {
//     adminService: 'https://admin.focustech.xyz',
//     structureService: 'https://lambda.focustech.xyz',
//     mapService: 'https://map-service.focustech.xyz',
//     geoService: 'https://geomapping.focustech.xyz',
//     fpcService: ' https://fpct-metric-storage.focustech.xyz',
// };

export const hostsMap: { [x: string]: string } = {
    'core/structure-service': 'structureService',
    'core/admin-service': 'adminService',
    'core/map-service-back': 'mapService',
    'fpc/metric-storage': 'fpcService',
    'app/app-backend': 'appBackend',
};

/**
 * Объект с изначальными адресами для ручек.
 */
export const getUrls = (hosts: { [x: string]: string }) => {
    return {
        ACCOUNT_PARAMETERS_URL: `${hosts.appBackend}/api/user-settings/account-parameters/`,
        COPY_ACCOUNT_PARAMETERS_URL: `${hosts.appBackend}/api/user-settings/copy-account-parameters/`,
        AUTH_URL: `${hosts.adminService}/sso/ajax-token`,
        LOGOUT_URL: `${hosts.adminService}/admin/logout/`,
        CURRENT_URL: `${hosts.structureService}/core-current/`,
        CACHED_STRUCTURE_URL: `${hosts.structureService}/structure-service/v1/cached_structure/`,
        RAW_RELATIONS_URL: `${hosts.structureService}/core/v1/pl-structure/`,
        PL_BY_ML_URL: `${hosts.adminService}/api/pl-by-ml`,
        PROJECTS_URL: `${hosts.adminService}/api/projects/`,
        OWNERS_URL: `${hosts.adminService}/api/owners/`,
        USERS_URL: `${hosts.adminService}/api/access/users/`,
        CREATE_USER_URL: `${hosts.adminService}/api/access/create-user/`,
        PRODUCTS_URL: `${hosts.adminService}/api/access/products/`,
        PROJECT_2_PRODUCT_URL: `${hosts.adminService}/api/access/project-2-product/`,
        USER_2_PRODUCT_URL: `${hosts.adminService}/api/access/user-2-product/`,
        USER_TOKEN_URL: `${hosts.adminService}/api/access/user-token/`,
        PROJECT_LOCATIONS_PERMISSIONS_URL: `${hosts.adminService}/api/access/project-location-permissions/`,
        LOCATIONS_URL: `${hosts.mapService}/api/locations/`,
        PROJECT_LOCATIONS_URL: `${hosts.adminService}/api/project-locations/`,
        SYNC_URL: `${hosts.adminService}/api/project-location-sync/`,
        LOCATION_CLONE_URL: `${hosts.mapService}/api/location-clone/`,
        PLANS_URL: `${hosts.mapService}/api/plans/`,
        PLANS_ADD_URL: `${hosts.mapService}/api/add-plan-requests/`,
        LAYERS_URL: `${hosts.mapService}/api/layers/`,
        VERSIONS_URL: `${hosts.mapService}/api/versions/`,
        GEO_URL: `${hosts.geoService}/geo-mapping/`,

        PLACES_URL: `${hosts.adminService}/api/places/`,
        TENANTS_URL: `${hosts.adminService}/api/tenants/`,
        TENANT_2_PLACE_URL: `${hosts.adminService}/api/tenant-2-place/`,

        FPC_SENSOR_STATUS_URL: `${hosts.fpcService}/api/sensor-status/`,
    };
};
