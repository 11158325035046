import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IEscalator } from '../../../Layers/components/Escalators/interfaces';
import { IGroupZones } from '../../../Layers/components/Zones/zones.interfaces';
import { IExtendedZone, IReducer } from './interfaces';
import { RootState } from './store';
const escalators_zones = require('../../../../lambda/escalators_zones');

export const initialState: IReducer = {
    tupleAlias: 'escalators_zones_tuple',
    intersections: [],
};

const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        /**
         * Запись в стейт информации о пересечении проходов и зон
         */
        storeIntersections: (
            state,
            action: PayloadAction<{
                zonesGroups: Array<IGroupZones>;
                escalatorsObjects: Array<IEscalator>;
                planScale: number;
            }>,
        ) => {
            const { zonesGroups, escalatorsObjects, planScale } = action.payload;

            const zonesObjects = [] as Array<IExtendedZone>;

            zonesGroups.forEach((group) => {
                if (group.zones.length > 0) {
                    group.zones.forEach((zone) => {
                        zonesObjects.push({ ...zone, group_marker: group.group_marker, group_name: group.group_name });
                    });
                }
            });

            const intersections = escalators_zones.get_intersections({ zonesObjects, escalatorsObjects, planScale });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
