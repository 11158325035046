import React, { useState, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTranslation } from 'react-i18next';
import { Stack, IStackProps, IStackTokens } from '@fluentui/react/lib/Stack';
import { Checkbox } from '@fluentui/react';
import { saveDataGroup, saveDataZone, zonesValues, checkIsMultiZone } from '../../../../zones.reducer';

import { AreaWarning, StyledStack } from './ObjectOptions.styles';
import { commonTools } from '../../../../../../layers';
import Flatten from '@flatten-js/core';

/**
 * Опции для выбраного объекта
 * @constructor
 */
const ObjectOptions = () => {
    const [nameGroup, setNameGroup] = useState<string | undefined>('');
    const [markerGroup, setMarkerGroup] = useState<string | undefined>('');
    const [nameZone, setNameZone] = useState<string | undefined>('');
    const [markerZone, setMarkerZone] = useState<string | undefined>('');
    const [isChecked, setIsChecked] = useState<boolean>(false);
    const [userArea, setUserArea] = useState<string>('');
    const [errorMassage, setErrorMassage] = useState<string | undefined>(undefined);
    const [calculatedArea, setCalculatedArea] = useState<number>(0);
    const { selectedGroupZones, selectedObjects, zoneFrontId, generalSettings, currentPlanData } =
        useSelector(zonesValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { Polygon } = Flatten;

    const StackTokens: IStackTokens = {
        childrenGap: 5,
    };

    useEffect(() => {
        setNameGroup(selectedGroupZones?.group_name);
        setMarkerGroup(selectedGroupZones?.group_marker);
    }, [selectedGroupZones]);

    useEffect(() => {
        if (selectedObjects) {
            setNameZone(selectedObjects!.zone_name);
            setMarkerZone(selectedObjects!.zone_marker);
            setIsChecked(selectedObjects.is_multizone);
            setUserArea(String(selectedObjects?.user_area || ''));

            const coords = selectedObjects?.coords.coordinates;
            const calculatedArea = new Polygon(coords as any[]).area() / Math.pow(currentPlanData?.planScale || 1, 2);
            setCalculatedArea(() => calculatedArea);
        }
    }, [Polygon, currentPlanData?.planScale, selectedObjects]);

    const areaWarning = useMemo(() => {
        if (
            userArea &&
            calculatedArea &&
            (Number(userArea) / calculatedArea > 2 || Number(userArea) / calculatedArea < 0.5)
        ) {
            return <AreaWarning>{t('Calculated and user areas are too different!')}</AreaWarning>;
        } else {
            return null;
        }
    }, [calculatedArea, t, userArea]);

    if (selectedObjects === null) {
        if (markerGroup) {
            const onChangeName = (
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string,
            ) => {
                setNameGroup(newValue);
                dispatch(
                    saveDataGroup({
                        name: newValue,
                        marker: markerGroup,
                    }),
                );
            };
            const onChangeMarker = (
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string,
            ) => {
                setMarkerGroup(commonTools.matchChars(newValue));
                setErrorMassage(undefined);
                dispatch(
                    saveDataGroup({
                        name: nameGroup,
                        marker: commonTools.matchChars(newValue),
                    }),
                );
            };

            return (
                <StyledStack tokens={StackTokens}>
                    <TextField
                        disabled={generalSettings.readOnly}
                        underlined
                        label={t('Marker')}
                        value={String(markerGroup)}
                        onChange={onChangeMarker}
                        // errorMessage={errorMassage}
                    />
                    <TextField
                        disabled={generalSettings.readOnly}
                        underlined
                        label={t('Name')}
                        value={String(nameGroup)}
                        onChange={onChangeName}
                    />
                </StyledStack>
            );
        } else {
            return null;
        }
    } else {
        const onChangeName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setNameZone(newValue);
            dispatch(
                saveDataZone({
                    name: newValue!,
                    marker: markerZone!,
                    zoneFrontId: zoneFrontId,
                }),
            );
        };

        const onChangeMarker = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            setMarkerZone(commonTools.matchChars(newValue));
            setErrorMassage(undefined);
            dispatch(
                saveDataZone({
                    name: nameZone!,
                    marker: commonTools.matchChars(newValue)!,
                    zoneFrontId: zoneFrontId,
                }),
            );
        };

        const onChangeCheckbox = (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
            dispatch(
                checkIsMultiZone({
                    status: checked!,
                    name: nameZone!,
                    marker: markerZone!,
                    zoneFrontId: zoneFrontId,
                }),
            );
        };

        const onChangeUserArea = (
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string,
        ) => {
            const userArea = newValue && isFinite(Number(newValue)) && Number(newValue) >= 0 ? newValue : '';
            setUserArea(() => userArea);

            dispatch(
                saveDataZone({
                    name: nameZone!,
                    marker: markerZone!,
                    zoneFrontId: zoneFrontId,
                    userArea,
                }),
            );
        };

        return (
            <StyledStack tokens={StackTokens}>
                <Checkbox
                    disabled={generalSettings.readOnly}
                    label={t('Multi zone')}
                    onChange={onChangeCheckbox}
                    checked={isChecked}
                />
                <TextField
                    disabled={generalSettings.readOnly}
                    underlined
                    label={t('Marker')}
                    value={String(markerZone)}
                    onChange={onChangeMarker}
                    // errorMessage={errorMassage}
                />
                <TextField
                    disabled={generalSettings.readOnly}
                    underlined
                    label={t('Name')}
                    value={String(nameZone)}
                    onChange={onChangeName}
                />
                <TextField
                    underlined
                    label={t('Calculated area')}
                    value={`${calculatedArea.toFixed(2)}${t('m²')}`}
                    onChange={() => {}}
                />
                <TextField
                    type="number"
                    min={0.1}
                    step={0.1}
                    disabled={generalSettings.readOnly}
                    underlined
                    label={t('User area')}
                    value={String(userArea)}
                    onChange={onChangeUserArea}
                />
                {areaWarning}
            </StyledStack>
        );
    }
};

export default ObjectOptions;
