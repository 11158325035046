import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { generalReducerValues } from '../../../../../../../../../General.reducer';
import { postRequest } from '../../../../../../../../../tools/api';
import { tenantsReducerValues } from '../../../../../../Tenants/Tenants.reducer';

type UseFetchComparisonArgs = { name: string; enabled: boolean };

export const useFetchComparison = (args: UseFetchComparisonArgs) => {
    const { tenants, selectedTenant, placesByMarker, tenant2place, reloadData, places } =
        useSelector(tenantsReducerValues);
    const { name, enabled } = args;
    const tenantsNames = tenants.map((item) => item.object_name);
    const params = useParams();
    const queryKey = ['comparison', name, params.plId];
    const queryFn = async () => {
        const data = { a: [name], b: tenantsNames };
        const response = postRequest({
            url: 'https://admin.focustech.xyz/api/categories/levenshtein-comparison/',
            data,
            allowSpinner: true,
        });
        return response;
    };
    const select = (
        response: { a: string; b: string; distance: number }[],
    ): { isNameValid: boolean; similarNames: string[] } => {
        const filtered = response.filter((item) => item.distance < 0.3);
        if (filtered.some((item) => item.distance === 0)) {
            const name = filtered.find((item) => item.distance === 0)?.b || '';
            return { isNameValid: false, similarNames: [name] };
        } else {
            return {
                isNameValid: true,
                similarNames: filtered.map((item) => item.b),
            };
        }
    };
    return useQuery({
        queryKey,
        queryFn,
        enabled,
        select,
    });
};
