import defaultDataAdapter from './dataAdapter';

import { useQuery } from '@tanstack/react-query';
import { useContext, useMemo } from 'react';

import { IRequestStructuresDAResponse, IRequestStructuresQueryFNResponse } from './interfaces';
import { GET_CACHED_STRUCTURES_QUERY_KEY } from '../../constants/queryKeys';
import { postRequest } from '../api';
import { GeneralContext } from '../../General.context';

interface IArgs {
    plId?: number;
}

const useRequestStructures = (args: IArgs) => {
    const { token, urls } = useContext(GeneralContext);

    const { queryKey, queryFn, dataAdapter } = useMemo(() => {
        if (token && args.plId && urls['CACHED_STRUCTURE_URL']) {
            return {
                queryKey: [GET_CACHED_STRUCTURES_QUERY_KEY, args.plId],
                queryFn: () =>
                    postRequest({
                        url: urls['CACHED_STRUCTURE_URL'],
                        token,
                        data: {
                            auth: {
                                xtoken: token,
                            },
                            queries: [
                                {
                                    key: 'core/elements_admin_data_objects',
                                    item: `pl_structure/pl${args.plId}/core/elements_admin_data_objects.json`,
                                },
                                {
                                    key: 'core/elements_geo_objects',
                                    item: `pl_structure/pl${args.plId}/core/elements_geo_objects.json`,
                                },
                                {
                                    key: 'core/elements_ms_data_objects',
                                    item: `pl_structure/pl${args.plId}/core/elements_ms_data_objects.json`,
                                },
                                {
                                    key: 'core/relations_dataobj2floor',
                                    item: `pl_structure/pl${args.plId}/core/relations_dataobj2floor.json`,
                                },
                                {
                                    key: 'core/relations_passway2dataobj',
                                    item: `pl_structure/pl${args.plId}/core/relations_passway2dataobj.json`,
                                },
                                {
                                    key: 'core/relations_place2zone',
                                    item: `pl_structure/pl${args.plId}/core/relations_place2zone.json`,
                                },
                                {
                                    key: 'core/relations_tenant2place',
                                    item: `pl_structure/pl${args.plId}/core/relations_tenant2place.json`,
                                },
                                {
                                    key: 'core/relations_tenant2zone',
                                    item: `pl_structure/pl${args.plId}/core/relations_tenant2zone.json`,
                                },
                                {
                                    key: 'core/relations_tenant2floor',
                                    item: `pl_structure/pl${args.plId}/core/relations_tenant2floor.json`,
                                },
                                {
                                    key: 'core/relations_tenant2location',
                                    item: `pl_structure/pl${args.plId}/core/relations_tenant2location.json`,
                                },
                                {
                                    key: 'fpc/elements_pc_ipoints',
                                    item: `pl_structure/pl${args.plId}/fpc/elements_pc_ipoints.json`,
                                },
                                {
                                    key: 'fpc/elements_pc_sensors',
                                    item: `pl_structure/pl${args.plId}/fpc/elements_pc_sensors.json`,
                                },
                                {
                                    key: 'fpc/relations_sensor2pcipoint',
                                    item: `pl_structure/pl${args.plId}/fpc/relations_sensor2pcipoint.json`,
                                },
                                {
                                    key: 'fpc/relations_pcipoint2passway',
                                    item: `pl_structure/pl${args.plId}/fpc/relations_pcipoint2passway.json`,
                                },
                                {
                                    key: 'fpc/relations_sensor2passway',
                                    item: `pl_structure/pl${args.plId}/fpc/relations_sensor2passway.json`,
                                },
                                {
                                    key: 'fpc/relations_sensor2dataobj',
                                    item: `pl_structure/pl${args.plId}/fpc/relations_sensor2dataobj.json`,
                                },
                            ],
                        },
                    }) as unknown as Promise<IRequestStructuresQueryFNResponse>,
                dataAdapter: (response: IRequestStructuresQueryFNResponse) => defaultDataAdapter({ response }),
            };
        }

        return {};
    }, [args.plId, token, urls]);

    return useQuery<IRequestStructuresQueryFNResponse, unknown, IRequestStructuresDAResponse, any>({
        queryKey,
        queryFn,
        enabled: Boolean(queryFn) && Boolean(queryKey) && Boolean(dataAdapter),
        select: dataAdapter,
        staleTime: 1000 * 60 * 15,
    });
};

export default useRequestStructures;
