import React, { useContext, useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';
import { DatePicker, IDatePickerStrings } from '@fluentui/react/lib/DatePicker';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { useTranslation } from 'react-i18next';

import { PlansContext } from '../../Plans.context';

import { MainWrapper, columnProps, footerProps, LabelWrapper, FileLabel, ChooseFile, Image } from './AddNewPlan.styles';
import { parseDateFromString } from './core/parseDateFromString';
import { formatDate } from './core/formatDate';
import { getImageFile } from './core/getImageFile';
import { MAX_PLAN_IMG_SIZE_MB } from '../../../../constants/files';

import useDayPickerStrings from '../../../../constants/useDayPickerStrings';
import { commonTools } from '../../../../tools/commonTools';
import { useDispatch } from 'react-redux';
import { storeActiveLayerId } from '../../../Editor/Editor.reducer';
import { DayOfWeek } from '@fluentui/date-time-utilities';

/**
 * Компонент для добавления нового плана.
 *
 * @param onOKClick Внешний обработчик кнопки ОК.
 */
const AddNewPlan = ({ onOKClick }: any) => {
    const dispatch = useDispatch();

    const { activeLocation } = useContext(PlansContext);
    const DAY_PICKER_STRINGS = useDayPickerStrings();
    const { t } = useTranslation();
    const [activeFrom, setActiveFrom] = useState(new Date());
    const [floor, setFloor] = useState('1');
    const [image, setImage] = useState('');
    const [imageFileName, setImageFileName] = useState('');
    const [notes, setNotes] = useState('');

    const firstDayOfWeek = DayOfWeek.Monday;

    const disabled = image === '';

    const onFloorChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue !== undefined && setFloor(newValue || '');
    };
    const onNotesChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue !== undefined && setNotes(newValue || '');
    };

    const onCreateClick = () => {
        dispatch(storeActiveLayerId('measureCut'));
        onOKClick({ floor: Number(floor), image, activeFrom: activeFrom.toISOString(), notes, locationId: activeLocation?.id });
    };

    const onFileChange = (e: React.FormEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        const { fileName, image } = getImageFile(files, MAX_PLAN_IMG_SIZE_MB, t('File too large'));
        fileName && setImageFileName(fileName);
        image && setImage(image);
    };

    const onSelectDate = (date: Date | null | undefined) => {
        date && setActiveFrom(date);
    };

    return (
        <MainWrapper>
            <Stack {...columnProps}>
                <TextField label={t('Floor')} value={floor} onChange={onFloorChange} type={'number'} min={-1000} required />
                <input accept="image/*" onChange={onFileChange} style={{ display: 'none' }} id="raised-button-file" type="file" />
                <LabelWrapper>
                    <FileLabel className="FileLabel" htmlFor="raised-button-file">
                        <ChooseFile>{t('Choose image file')}</ChooseFile>
                    </FileLabel>
                </LabelWrapper>
                {image && <Image src={image}></Image>}
                {imageFileName && <span>{imageFileName}</span>}
                <DatePicker
                    label={t('Plan is active from')}
                    isRequired={true}
                    allowTextInput={true}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DAY_PICKER_STRINGS}
                    value={activeFrom}
                    onSelectDate={onSelectDate}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={parseDateFromString}
                />
                <TextField label={t('Notes')} value={notes} multiline rows={5} onChange={onNotesChange} />
            </Stack>
            <Stack {...footerProps}>
                <Stack.Item align={'end'}>
                    <PrimaryButton text={t('Create')} onClick={onCreateClick} allowDisabledFocus disabled={disabled} />
                </Stack.Item>
            </Stack>
        </MainWrapper>
    );
};

export default AddNewPlan;
