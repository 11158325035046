import { FontIcon, mergeStyles } from '@fluentui/react';
import { IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { CSVLink } from 'react-csv';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

export const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: '100%' } };

export const TenantsWrapper = styled.ul<{ visible: boolean }>`
    display: ${(p) => (p.visible ? 'block' : 'none')};
    list-style: none;
    width: 100%;
    height: calc(100% - 44px);
    margin: 0;
    box-sizing: border-box;
    background-color: ${theme.palette.neutralLight};
    padding: 10px;
    position: absolute;
    z-index: 10;
    right: 0;
    top: 50px;
    overflow: auto;
    .ms-Dropdown-container {
        margin-bottom: 5px;
    }
    .ms-TextField {
        position: sticky;
        top: -10px;
        z-index: 1;
    }
`;

export const Li = styled.li<{ selected: boolean }>`
    cursor: pointer;
    padding: 10px 0;
    display: flex;
    align-items: center;
    list-style: none;
    color: ${(p) => (p.selected ? theme.palette.themePrimary : 'black')};
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    :hover {
        font-weight: 700;
    }
    span {
        font-size: 12px;
    }
`;

export const buttonStyles = {
    root: {
        width: '100%',
        marginBottom: 10,
    },
};

export const Container = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    i {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
`;

export const StyledFontIcon = styled(FontIcon)`
    cursor: pointer;
`;

export const iconClass = (color: string) =>
    mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        // marginRight: '5px',
        color,
    });

export const StyledCSVLink = styled(CSVLink)`
    -webkit-text-size-adjust: 100%;
    line-height: 1.15;
    margin: 0;
    overflow: visible;
    text-transform: none;
    outline: transparent;
    position: relative;
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto,
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-align: center;
    font-weight: 700;
    cursor: pointer;
    padding: 0px 16px;
    border-radius: 2px;
    min-width: 80px;
    height: 32px;
    background-color: ${theme.palette.themePrimary};
    color: rgb(255, 255, 255);
    margin-top: 10px;
    user-select: none;
    -webkit-appearance: button;
    :hover {
        background-color: ${theme.palette.themeDark};
    }
`;
