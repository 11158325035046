import React, { createContext } from 'react';

import { IPlansContext } from './Plans.interfaces';

export const PlansContext = createContext<IPlansContext>({
    readOnly: true,
    plansList: [],
    storePlanToEditTrans: () => {},
    toggleAlertTrans: () => {},
});
