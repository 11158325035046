import React from 'react';
import { Line, Group, Circle, Shape, Text } from 'react-konva';

import { IAreaProps } from './Area.interfaces';

/**
 * Компонент область (персонала или потребирелей)
 */
const Area = (props: IAreaProps) => {
    const {
        mode,
        draggable,
        item,
        stageScale,
        selected,
        onMouseMove,
        onMouseUp,
        onShapeMouseDown,
        onShapeDragEnd,
        onDragMove,
        onTransformNodeMouseDown,
        onShapeClick,
        deskCenter,
        customerAreaCenter,
        staffAreaCenter,
    } = props;

    /**
     * Отрисовка узлов трансформации
     */
    const transformNodes = (mode: string, real = false) => {
        const coordinates = mode === 'customer' ? item.customerArea.coordinates : item.staffArea.coordinates;
        return coordinates.map((point, i) => {
            return (
                <Circle
                    key={`${i}++${point[0]}++${String(real)}`}
                    x={point[0]}
                    y={point[1]}
                    radius={5 / stageScale}
                    fill={'transparent'}
                    stroke={real ? 'transparent' : 'red'}
                    strokeWidth={2 / stageScale}
                    onMouseDown={real ? onTransformNodeMouseDown(mode) : () => {}}
                    onMouseUp={real ? onMouseUp : () => {}}
                    onMouseMove={real ? onMouseMove : () => {}}
                />
            );
        });
    };

    /**
     * Отрисовка зон клиентов и персонала
     */
    const drawArea = (mode: string) => {
        const coordinates = mode === 'customer' ? item.customerArea.coordinates : item.staffArea.coordinates;
        const fillColor = mode === 'customer' ? 'rgba(0, 255, 0, 0.3)' : 'rgba(0, 255, 255, 0.3)';
        const strokeWidth = selected ? 2 / stageScale : 0;
        return (
            <>
                <Group
                    draggable={draggable}
                    onDragMove={onDragMove(mode)}
                    onDragEnd={onShapeDragEnd(mode)}
                    onMouseDown={onShapeMouseDown}
                    onMouseUp={onMouseUp}
                    onMouseMove={onMouseMove}
                >
                    <Shape
                        onClick={onShapeClick(item.id)}
                        sceneFunc={(context, shape) => {
                            context.beginPath();
                            context.moveTo(coordinates[0][0], coordinates[0][1]);
                            coordinates.forEach((coord, i) => {
                                if (i > 0) context.lineTo(coord[0], coord[1]);
                            });
                            context.closePath();
                            context.fillStrokeShape(shape);
                        }}
                        fill={fillColor}
                        stroke={'red'}
                        strokeWidth={strokeWidth}
                    />
                    {selected ? transformNodes(mode, false) : null}
                </Group>
                {selected ? transformNodes(mode, true) : null}
                {drawText(mode)}
                {drawDashLine(mode)}
            </>
        );
    };

    /**
     * Отрисовка пунктирных линий
     */
    const drawDashLine = (mode: string) => {
        const coords = mode === 'customer' ? customerAreaCenter : staffAreaCenter;
        if (coords === null || deskCenter === null) return null;
        const points = [...deskCenter, coords.x, coords.y];
        return <Line points={points} stroke={'red'} strokeWidth={2 / stageScale} dash={[8, 2]} />;
    };

    /**
     * Отрисовка текста
     */
    const drawText = (mode: string) => {
        const centerCoords = mode === 'customer' ? customerAreaCenter : staffAreaCenter;
        if (centerCoords === null || deskCenter === null) return null;
        return (
            <Text
                x={centerCoords.x + 5}
                y={centerCoords.y - 5}
                text={mode}
                fontSize={10}
                fontStyle={'bold'}
                fill={'rgba(255,255,255, 0.7)'}
            />
        );
    };

    return drawArea(mode);
};

export default Area;
