import { Pivot } from '@fluentui/react';
import styled, { keyframes } from 'styled-components/macro';
import { theme } from '../../../../constants/theme';

/**
 * Компонент Обертка контрольной панели.
 */
export const ControlPanelWrapper = styled.section`
    height: 100%;
    background-color: rgb(253,252,251);
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    overflow: scroll;
    position: relative;
    padding: 0 10px;
`;

export const Footer = styled.div`
    width: 100%;
    left: 0;
    bottom: 5px;
    padding: 10px;
    position: absolute;
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
`;

export const Split = styled.div`
    margin: 5px 0;
    width: calc(100%);
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;

export const PivotWrapper = styled.div`
    padding-left: 5px;
    height: 40px;
    display: flex;
    align-items: center;
`;

export const StyledPivot = styled(Pivot)`
    button {
        height: 30px;
    }
`;
