import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../../store';
import { postRequest } from '../../../../tools/api';
import { ITablesPanelReducerState, ITables, IContextMenu, ISorting, ITable } from './TablesPanel.interfaces';
import { DateTime } from 'luxon';

const initialState: ITablesPanelReducerState = {
    tables: {},
    selectedTableId: null,
    contextMenu: { show: false },
    filters: {},
    sorting: { colName: null, order: 1 },
};

export const TablesPanel = createSlice({
    name: 'TablesPanel',
    initialState,
    reducers: {
        /**
         * Выбор таблицы.
         */
        selectTable: (state, action: PayloadAction<string | null>) => {
            state.contextMenu = { show: false };
            state.filters = {};
            state.selectedTableId = action.payload;
        },

        /**
         * Переключение контекстного меню.
         */
        toggleContextMenu: (state, action: PayloadAction<IContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Изменение текущих фильтров.
         */
        changeFilters: (state, action: PayloadAction<{ value: string; colName: string; mode?: string }>) => {
            const { filters } = cloneDeep(state);
            const { colName, value, mode } = action.payload;
            
            if (!value || value === '') {
                delete filters[colName];
                state.filters = filters;
            } else {
                if (mode) {
                    state.filters = { ...filters, [colName]: { filter: value, mode } };
                } else {
                    state.filters = { ...filters, [colName]: { filter: value } };
                }
            }
        },

        /**
         * Изменение текущих сортировок.
         */
        changeSorting: (state, action: PayloadAction<ISorting>) => {
            state.sorting = action.payload;
        },

        /**
         * Сброс текущих фильтров.
         */
        resetFilters: (state) => {
            state.filters = {};
        },

        /**
         * Сброс редьюсера.
         */
        resetTablesReducer: (state) => {
            state.tables = {};
            state.selectedTableId = null;
            state.contextMenu = { show: false };
            state.filters = {};
        },
    },
});

export const { selectTable, changeFilters, toggleContextMenu, resetTablesReducer, changeSorting, resetFilters } =
    TablesPanel.actions;

export const tablesPanelReducerValues = (state: RootState) => state.TablesPanelReducer;

export default TablesPanel.reducer;
