//@ts-nocheck

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datasheet from 'react-datasheet';
import { DateTime } from 'luxon';
import { Label, PrimaryButton, Stack, TextField, Toggle } from '@fluentui/react';
import { settingsReducerValues } from '../../../../../Settings/Settings.reducer';
import { editorReducerValues } from '../../../../Editor.reducer';
import { tenantsReducerValues, changeTenant } from '../../Tenants.reducer';

import { CustomCell, CustomHeader, Footer, Header, Wrapper, textFieldStyles, labelStyles } from './styles';
import { useTranslation } from 'react-i18next';
import { useBoolean } from '@fluentui/react-hooks';

const TenantPanel: React.FC = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { selectedObjectId } = useSelector(editorReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const { placesByMarker, selectedTenant, tenant2place, tenants } = useSelector(tenantsReducerValues);
    const [multiPlace, setMultiplace] = useState<boolean>(selectedTenant?.multi_place || false);
    const [isActive, setIsActive] = useState<boolean>(selectedTenant?.is_active || true);
    const [name, setName] = useState<string>(selectedTenant?.object_name || '');

    useEffect(() => {
        selectedTenant?.multi_place !== undefined && setMultiplace(() => selectedTenant?.multi_place as boolean);
        selectedTenant?.is_active !== undefined && setIsActive(() => selectedTenant?.is_active as boolean);
        setName(() => selectedTenant?.object_name);
    }, [selectedTenant]);

    const stackTokens = { childrenGap: 10 };

    const saveHandler = () => {
        if (
            selectedTenant &&
            (selectedTenant?.multi_place !== multiPlace ||
                selectedTenant?.is_active !== isActive ||
                selectedTenant.object_name !== name)
        ) {
            dispatch(
                changeTenant({
                    ...selectedTenant,
                    multi_place: multiPlace,
                    is_active: isActive,
                    object_name: name,
                }),
            );
        }
    };

    const activeDisabled = () => {
        let result = true;
        const numberOfInvolvedPlaces = tenant2place.filter((item) => item.tenant_id === selectedTenant?.id).length;
        if (numberOfInvolvedPlaces === 0) {
            result = false;
        }
        return result;
    };

    const multiPlaceDisabled = () => {
        let result = false;
        const numberOfSelectedtenantHasPlaceIds = tenant2place
            .filter(
                (item) =>
                    item.date_to === null || DateTime.fromISO(item.date_to).toMillis() > DateTime.now().toMillis(),
            )
            .map((item) => item.tenant_id)
            .filter((item) => item === selectedTenant?.id).length;
        if (numberOfSelectedtenantHasPlaceIds > 1 && selectedTenant?.multi_place) {
            result = true;
        }
        return result;
    };

    function toggleMultiPlace(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        checked !== undefined && setMultiplace(() => checked);
    }

    function toggleActive(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        checked !== undefined && setIsActive(() => checked);
    }

    const tenant2PlaceMap = {
        date_from: 'Date from',
        date_to: 'Date to',
        place_id: 'Place id',
        place: 'Place',
        floor: 'Floor',
        tenant_id: 'Tenant id',
        tenant: 'Tenant',
        tenant_object_name: 'Tenant name',
        place_marker: 'Place marker',
        place_object_name: 'Place name',
    };

    const grid = useMemo(() => {
        const result = [];
        const filtered = tenant2place
            .filter((obj) => obj.tenant_id === selectedTenant?.id)
            .map((obj) => {
                
                const { id, date_from, date_to, floor } = obj;
                const place = `${obj.place_id} / ${obj.place_marker} / ${obj.place_object_name}`;
                const tenant = `${obj.tenant_id} / ${obj.tenant_object_name}`;
                return { id, place, floor, tenant, date_from, date_to };
            });

        if (filtered.length === 0) {
            return [];
        }
        const header = Object.keys(filtered[0]).map((key) => {
            return {
                value: tenant2PlaceMap[key] ? tenant2PlaceMap[key] : key,
                readOnly: true,
                types: ['header'],
                colName: key,
            };
        });
        result.push(header);
        const rest = filtered.map((row: { [x: string]: any }) => {
            return Object.keys(row).map((key: string) => {
                const val = row[key as string];
                return { value: val, colName: key, types: ['cell'], readOnly: true };
            });
        });
        result.push(...rest);
        return result;
    }, [selectedTenant?.id, tenant2place]);

    const cellRenderer = (props: any) => {
        const { children, editing, ...rest } = props;
        if (props.cell.types.includes('cell')) {
            return (
                <CustomCell {...rest} types={props.cell.types}>
                    {children}
                </CustomCell>
            );
        } else if (props.cell.types.includes('header')) {
            return (
                <CustomHeader {...rest} types={props.cell.types}>
                    {children}
                </CustomHeader>
            );
        }
    };

    const changeName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setName(() => newValue || '');
    };

    const isNewNameLong = useMemo(() => {
        return name.length >= 2;
    }, [name]);

    const buttonDisabled = useMemo(() => {
        let result = true;
        if (
            selectedTenant?.multi_place !== multiPlace ||
            selectedTenant?.is_active !== isActive ||
            selectedTenant?.object_name !== name ||
            isNewNameLong
        ) {
            result = false;
        }
        return result;
    }, [
        selectedTenant?.multi_place,
        selectedTenant?.is_active,
        selectedTenant?.object_name,
        multiPlace,
        isActive,
        name,
        isNewNameLong,
    ]);

    const headerText = `${t('Tenant')}: ${selectedTenant?.id} / ${selectedTenant?.object_name}`;

    return (
        <Wrapper>
            <Header>{headerText}</Header>
            <TextField
                disabled={readOnly}
                value={name}
                label={t('Name')}
                onChange={changeName}
                styles={textFieldStyles}
            />
            {!isNewNameLong && <Label styles={labelStyles}>{t('Name is less than 2 symbols')}</Label>}
            <Toggle
                inlineLabel
                label={t('Multi place')}
                checked={multiPlace}
                onChange={toggleMultiPlace}
                disabled={multiPlaceDisabled() || readOnly}
            />
            <Toggle
                inlineLabel
                label={t('Active')}
                checked={isActive}
                onChange={toggleActive}
                disabled={activeDisabled() || readOnly}
            />
            <Datasheet
                // onContextMenu={onContextMenu}
                cellRenderer={cellRenderer}
                // attributesRenderer={(cell) => (cell.value ? { 'data-hint': cell.value } : {})}
                data={grid}
                valueRenderer={(cell: any) => cell.value}
            />
            {!readOnly && (
                <Footer>
                    <Stack horizontal tokens={stackTokens}>
                        <PrimaryButton
                            text={t('Save changes')}
                            width={'100px'}
                            onClick={saveHandler}
                            disabled={buttonDisabled}
                        />
                    </Stack>
                </Footer>
            )}
        </Wrapper>
    );
};

export default TenantPanel;
