import React, { useState, useMemo, useEffect, MouseEventHandler } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducerValues } from '../../reducer';
import { ResponsiveHeatMapCanvas, TooltipProps } from '@nivo/heatmap';
import { CustomCell, CustomReactDataSheet, ModalWrapper, IntersectTableWrapper, ChartWrapper } from './styles';
import { cloneDeep } from 'lodash';
import { IProps } from './interfaces';
import { DateTime } from 'luxon';
import CustomTooltip from './CustomTooltip';

/**
 * Компонент содержимое модального окна
 */
const HeatMap: React.FC<IProps> = ({ sensors, mode, ...props }) => {
    const dispatch = useDispatch();
    const { generalSettings, selectedSensorId } = useSelector(reducerValues);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [selected, setSelected] = useState<any>({ start: null, end: null });

    const data = sensors?.map((item) => {
        return {
            id: item.sensor_serial,
            data: item.values.map((val) => {
                const y = val.v;
                return { x: DateTime.fromISO(val.t).toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS), y };
            }),
        };
    });

    const chartSize = useMemo(() => {
        if (mode === 'full') {
            return data[0]?.data?.length
                ? { width: data[0].data.length * 15 + 300, height: data.length * 25 + 1600 }
                : // ? { width: data[0].data.length * 15 + 300, height: data.length * 25 }
                  { ...(generalSettings.windowSize as { width: number; height: number }) };
        } else {
            return data[0]?.data?.length
                ? {
                      width: generalSettings?.windowSize?.width ? generalSettings?.windowSize?.width - 200 : 400,
                      height: 500,
                  }
                : { width: 0, height: 0 };
            // return data[0]?.data?.length ? { width: data[0].data.length * 15, height: 500 } : { width: 0, height: 0 };
        }
    }, [data, generalSettings.windowSize, mode]);

    const visualOptions = useMemo(() => {
        if (mode === 'full') {
            return {
                axisTop: { tickSize: 5, tickPadding: 5, tickRotation: -90 },
                axisLeft: { tickSize: 5, tickPadding: 5, tickRotation: 0 },
                margin: { top: 200, right: 20, bottom: 20, left: 120 },
            };
        } else {
            return { axisTop: null, axisLeft: null, margin: { top: 20, right: 120, bottom: -80, left: 120 } };
        }
    }, [mode]);

    return data ? (
        <ModalWrapper>
            <ChartWrapper windowSize={chartSize}>
                <ResponsiveHeatMapCanvas
                    data={data}
                    margin={visualOptions.margin}
                    // valueFormat=">-.2s"
                    forceSquare={true}
                    xInnerPadding={0.05}
                    yInnerPadding={0.05}
                    axisTop={visualOptions.axisTop}
                    axisLeft={visualOptions.axisLeft}
                    colors={{
                        type: 'sequential',
                        scheme: 'red_yellow_green',
                        // divergeAt: 0.5,
                        minValue: 0,
                        maxValue: 1,
                        // steps: 11,
                    }}
                    // borderRadius={2}
                    emptyColor="#19b1d7"
                    enableLabels={false}
                    // isInteractive={false}
                    tooltip={CustomTooltip}
                    // legends={[
                    //     {
                    //         anchor: 'bottom',
                    //         translateX: 0,
                    //         translateY: 30,
                    //         length: 400,
                    //         thickness: 8,
                    //         direction: 'row',
                    //         tickPosition: 'after',
                    //         tickSize: 3,
                    //         tickSpacing: 4,
                    //         tickOverlap: false,
                    //         tickFormat: '>-.2s',
                    //         title: 'Value →',
                    //         titleAlign: 'start',
                    //         titleOffset: 4,
                    //     },
                    // ]}
                />
            </ChartWrapper>
        </ModalWrapper>
    ) : null;
};

export default HeatMap;
