import { Provider } from "react-redux"
import { store } from "../../store"
import Controls from "./Controls"
import React from 'react';

export default ({...props}) => {
    return (
        <Provider store={store}>
            <Controls {...props}/>
        </Provider>
    )
}