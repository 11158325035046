import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { IPlace } from '../../../Layers/components/Places/places.interfaces';
import { IGroupZones } from '../../../Layers/components/Zones/zones.interfaces';
import {
    ICrossObject,
    ICurrentPlaceLabelInfo,
    ICurrentPlaceZoneInfo,
    IExtendedZone,
    IReducer,
    ICurrentPlaceLeashInfo,
} from './interfaces';
import { RootState } from './store';
import { commonTools } from '../../tuples';
import { IStairs } from '../../../Layers/components/Stairs/interfaces';
const stairs_places = require('../../../../lambda/stairs_places');

export const initialState: IReducer = {
    tupleAlias: 'stairs_places_tuple',
    intersections: [],
    dependenciesForCurrentStairs: null,
};

const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        /**
         * Запись в стор всех пересечений
         */
        storeIntersections: (
            state,
            action: PayloadAction<{
                stairsObjects: Array<IStairs>;
                placesObjects: Array<IPlace>;
                planScale: number;
            }>,
        ) => {
            const { stairsObjects, placesObjects, planScale } = action.payload;

            const intersections = stairs_places.get_intersections({ stairsObjects, placesObjects, planScale });

            state.intersections = intersections;
        },

        /**
         * запись в стор всех зависимостей для выбранного place
         */
        storeDependenciesForCurrentStairs: (state, action: PayloadAction<{ stairsMarker: string }>) => {
            const { stairsMarker } = action.payload;
            const copy = cloneDeep(state.intersections);

            const labelsInformation = [] as Array<ICurrentPlaceLabelInfo>;
            const placeInformation = [] as Array<ICurrentPlaceZoneInfo>;
            const leashsInfornation = [] as Array<ICurrentPlaceLeashInfo>;

            copy.forEach((element: ICrossObject) => {
                if (element.info.stairsMarker === stairsMarker) {
                    const labelCoords = commonTools.findLabelCoords([
                        element.coords.stairsPolygonCenter,
                        element.coords.placePolygonCenter,
                    ]);

                    const leashCoords = [...element.coords.stairsPolygonCenter, ...element.coords.placePolygonCenter];

                    const text = `stairs_marker: ${element.info.stairsMarker}\nstairs_name: ${element.info.stairsName}\nplace_marker: ${element.info.placeMarker}`;

                    labelsInformation.push({ coords: labelCoords, text });
                    placeInformation.push({ coords: element.coords.placePolygonCenter });
                    leashsInfornation.push({ coords: leashCoords });
                }
            });

            state.dependenciesForCurrentStairs = { labelsInformation, placeInformation, leashsInfornation };
        },

        /**
         * Обнуление всех зависимостей для выьранного place
         */
        resetDependenciesForCurrentStairs: (state) => {
            state.dependenciesForCurrentStairs = null;
        },
    },
});

export const { storeIntersections, storeDependenciesForCurrentStairs, resetDependenciesForCurrentStairs } =
    Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
