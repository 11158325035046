/**
 * Рекурсивно обходит структуру, и если есть геоджейсон, округляет координаты до сотых
 * @param {any} instance Структура любого типа
 */
const roundCoords = (instance: any): any => {
    if (Array.isArray(instance)) {
        instance.forEach((item) => {
            roundCoords(item);
        });
    } else if (typeof instance === 'object' && instance !== null && instance.hasOwnProperty('type')) {
        if (instance.type === 'Polygon' || instance.type === 'LineString') {
            instance.coordinates = instance.coordinates.map((item: number[]) => round(item));
        } else if (instance.type === 'Point') {
            instance.coordinates = round(instance.coordinates);
        } else {
            Object.keys(instance).forEach((k) => {
                roundCoords(instance[k]);
            });
        }
    } else if (typeof instance === 'object' && instance !== null) {
        Object.keys(instance).forEach((k) => {
            roundCoords(instance[k]);
        });
    } else {
        return;
    }
};

const round = (arr: number[]) => {
    return [Math.round(arr[0]),Math.round(arr[1])];
    // return [Math.round(arr[0] * 100) / 100, Math.round(arr[1] * 100) / 100];
};

export default roundCoords;
