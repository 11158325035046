import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import EscalatorsReducer from './reducer';

export const store = configureStore({
    reducer: {
        EscalatorsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
