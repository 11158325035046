import { KonvaEventObject } from 'konva/lib/Node';

export const getPointerCoords = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const scale = stage?.scaleX();
    const p = stage?.getPointerPosition();    
    if (stage && p && scale) {
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        return [x / scale, y / scale];
    }
};
