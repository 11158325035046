export const toolsList = [
    {
        id: 'anchorsEdit',
        name: 'Anchors edit',
        icon: { iconName: 'RadioBtnOn', size: '16px' },
        tooltipText: 'Anchors edit (Shift + E)',
    },
    {
        id: 'draw',
        name: 'Draw',
        icon: { iconName: 'CircleAddition', size: '20px' },
        tooltipText: 'Draw (Shift + A)',
    },
    {
        id: 'move',
        name: 'Move',
        icon: { iconName: 'Move', size: '20px' },
        tooltipText: 'Move',
    },
];
