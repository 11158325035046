import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';
import { DateTime } from 'luxon';

import { getRequest, postRequest, patchRequest } from '../../tools/api';
import { mapBack2Front } from '../../tools/mappingFrontAndBack';
import { IVersionsReducerState, IVersion } from './Versions.interfaces';
import { plansMapping } from '../../constants/keysMapping';
import { geoMatrixConverner } from '../../tools/geoMatrixConverter';
import { fetchLayers, toggleVersionsHistoryModalStatus } from '../Editor/Editor.reducer';
import { storePlansWithVersionChange } from '../Plans/Plans.reducer';
import { ACTIVE_VERSION_ID } from '../../constants/localStorageKeys';

const initialState: IVersionsReducerState = {
    activeVersionId: null,
    actualVersionId: null,
    versionToEdit: null,
    versions: [],
    versionsById: {},
};

export const VersionsReducer = createSlice({
    name: 'VersionsReducer',
    initialState,
    reducers: {
        /**
         * Изменение объекта с версиями.
         */
        changeVersionsById: (state, action: PayloadAction<{ versionId: number; args: Partial<IVersion> }>) => {
            const { versionId, args } = action.payload;

            const { versionsById } = cloneDeep(state);

            versionsById[versionId] = { ...versionsById[versionId], ...args };
            state.versionsById = versionsById;
        },

        /**
         * Сброс Редьюсера.
         */
        resetVersionsReducer: (state) => {
            state.activeVersionId = null;
            state.actualVersionId = null;
            state.versionToEdit = null;
            state.versions = [];
            state.versionsById = {};
        },

        /**
         * Сохранение Айди редактируемой версии.
         */
        toggleVersionToEdit: (state, action: PayloadAction<number | null>) => {
            state.versionToEdit = action.payload;
        },

        /**
         * Сохранение списка версий и активной версии.
         */
        storeVersions: (state, action: PayloadAction<IVersion[]>) => {
            const getTS = (str: string | null) => (str ? DateTime.fromISO(str).valueOf() : null);

            const nowTS = new Date().getTime();
            let activeVersionIdFromLocalStorage = localStorage.getItem(ACTIVE_VERSION_ID);
            let activeVersionId = null;
            let actualVersionId = null;

            const oldest = Math.max(
                ...(action.payload
                    // .filter((item) => item.date_to !== null)
                    ?.reduce((acc: Array<number | null>, item: IVersion) => {
                        return [...acc, DateTime.fromFormat(item.date_from as string, 'yyyy-MM-dd').valueOf()];
                    }, []) as number[]),
            );

            for (let i = 0; i < action.payload.length; i++) {
                const version = action.payload[i];
                const startTS = getTS(version.date_from);
                const endTS = getTS(version.date_to);

                if (
                    (endTS && startTS && startTS <= nowTS && endTS >= nowTS) ||
                    (endTS === null && startTS && startTS <= nowTS)
                ) {
                    activeVersionId = version.id;
                    actualVersionId = version.id;
                    break;
                } else if (startTS === oldest) {
                    activeVersionId = version.id;
                    break;
                } else {
                    activeVersionId = version.id;
                }
            }

            const versionsById: { [x: string]: IVersion } = {};
            action.payload.forEach((version) => {
                const startTS = getTS(version.date_from);
                const endTS = getTS(version.date_to);

                versionsById[version.id] = version;
            });

            if (!versionsById[activeVersionIdFromLocalStorage || '']) {
                localStorage.setItem(ACTIVE_VERSION_ID, activeVersionId?.toString() || '');
                activeVersionIdFromLocalStorage = null;
            }

            state.activeVersionId = activeVersionIdFromLocalStorage
                ? Number(activeVersionIdFromLocalStorage)
                : activeVersionId;
            state.actualVersionId = actualVersionId;
            state.versions = action.payload.sort((a, b) => {
                return Number(getTS(b.date_from)) - Number(getTS(a.date_from));
            });

            state.versionsById = versionsById;
        },

        /**
         * изменение активной версии.
         */
        changeActiveVersion: (state, action: PayloadAction<number>) => {
            localStorage.setItem(ACTIVE_VERSION_ID, action.payload.toString());
            state.activeVersionId = action.payload;
        },
    },
});

/**
 * Запрос списка версий с сервера.
 */
export const fetchVersions =
    (versionsUrl: string, token: string | null, activeLocationId: string | number): AppThunk =>
    (dispatch) => {
        const url = `${versionsUrl}?location_id=${activeLocationId}`;
        getRequest({ url, dispatch, allowSpinner: true, token }).then((data: Array<IVersion>) => {
            dispatch(storeVersions(data));
        });
    };

/**
 * Cоздание новой версии на сервере.
 *
 * @param args данные для записи
 */
export const createNewVersion =
    (dates: { date_from: Date | undefined; date_to: Date | undefined }): AppThunk =>
    (dispatch, getState) => {
        const { token, urls } = cloneDeep(getState().GeneralReducer);
        const locationId = cloneDeep(getState().LocationsReducer.activeLocation?.id);

        const data: { location_id: number | undefined; date_from: string; date_to?: string } = {
            location_id: locationId,
            date_from: dates.date_from ? DateTime.fromJSDate(dates.date_from).toFormat('yyyy-MM-dd') : '',
        };

        if (dates.date_to) {
            data.date_to = DateTime.fromJSDate(dates.date_to).toFormat('yyyy-MM-dd');
        }

        postRequest({ url: urls.VERSIONS_URL, allowSpinner: true, token, data }).then((data) => {
            dispatch(toggleVersionsHistoryModalStatus({ show: false }));
            window.location.reload();
            // locationId && dispatch(fetchVersions(urls.VERSIONS_URL, token, locationId));
        });
    };

export const selectVersion =
    (versionId: number): AppThunk =>
    (dispatch, getState) => {
        const { token, urls } = cloneDeep(getState().GeneralReducer);
        const { activeVersionId, versions } = cloneDeep(getState().VersionsReducer);
        if (activeVersionId === versionId) return;
        const locationId = cloneDeep(getState().LocationsReducer.activeLocation?.id);
        dispatch(changeActiveVersion(versionId));
        locationId && dispatch(fetchLayers(locationId, versionId));
        dispatch(storePlansWithVersionChange());
    };

export const patchVersion =
    (versionId: number): AppThunk =>
    (dispatch, getState) => {
        const { token, urls } = cloneDeep(getState().GeneralReducer);
        const { activeVersionId, versionsById } = cloneDeep(getState().VersionsReducer);
        const activeLocationId = cloneDeep(getState().LocationsReducer.activeLocation?.id);
        const patchData: Partial<IVersion> = versionsById[versionId];

        patchRequest({
            url: `${urls.VERSIONS_URL}${activeVersionId}/`,
            allowSpinner: true,
            token,
            data: patchData,
        }).then((data) => {
            dispatch(toggleVersionToEdit(null));
            activeLocationId && dispatch(fetchVersions(urls.VERSIONS_URL, token, activeLocationId));
        });
    };

export const { storeVersions, changeActiveVersion, toggleVersionToEdit, changeVersionsById, resetVersionsReducer } =
    VersionsReducer.actions;

export const versionsReducerValues = (state: RootState) => state.VersionsReducer;

export default VersionsReducer.reducer;
