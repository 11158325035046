const Flatten = require('@flatten-js/core');
const turf = require('@turf/turf');
const tools = require('./tools');

const OVERLAP_FRACTION = 95;

function get_intersections({ placesObjects, zonesObjects, planScale }) {
    var intersections = [];

    const { Polygon } = Flatten;

    zonesObjects.forEach((zone) => {
        //tools.getPolygonForTurf(zone.coords.coordinates);
        // получение полигона из объекта зоны
        //const zonePolygon = turf.polygon([[...zone.coords.coordinates, [...zone.coords.coordinates[0]]]]);
        var zonePolygon = tools.getPolygonForTurf(zone.coords.coordinates, zone.zone_marker);
        placesObjects.forEach((place) => {
            // получение координат полигона места (place)
            const placePolygonCoordinates = place.coords.coordinates;
            // получение площади полигона места (place)
            const placePolygonArea = new Polygon(placePolygonCoordinates).area();
            // получение полигона из объекта места (place)
            //const placePoly = turf.polygon([[...place.coords.coordinates, [...place.coords.coordinates[0]]]]);
            var placePoly = tools.getPolygonForTurf(place.coords.coordinates, place.marker);
            // получение точек пересечения двух полигонов (zone, place)
            try {
                const polygonsIntersection = turf.intersect(zonePolygon, placePoly);
                if (polygonsIntersection?.geometry?.coordinates) {
                    // Получение координат от получившегося пересечения двух полигонов
                    //const intersectionsCoords = [...polygonsIntersection.geometry.coordinates][0];
                    const intersectionsCoords = polygonsIntersection.geometry.coordinates[0];
                    // Получаем площадь получивегося пересечения
                    const intersectionArea = new Polygon(intersectionsCoords).area();
                    // Получаем процент содержания полигона place в полигоне zone
                    const contentPercentage = (intersectionArea / placePolygonArea) * 100;
                    contentPercentage < OVERLAP_FRACTION &&
                        console.log(
                            '<<< INTERSECTION PERSENTAGE >>>',
                            place.marker,
                            ' --> ',
                            zone.zone_marker,
                            contentPercentage,
                            '%',
                        );
                    if (contentPercentage >= OVERLAP_FRACTION) {
                        const zonePolygonCenter = tools.findPolygonCenter(zone.coords.coordinates);
                        const placePolygonCenter = tools.findPolygonCenter(place.coords.coordinates);
                        const info = {
                            placeMarker: place.marker,
                            zoneMarker: zone.zone_marker,
                            zoneName: zone.zone_name,
                            groupMarker: zone.group_marker,
                            groupName: zone.group_name,
                            contentPercentage: Number(contentPercentage.toFixed(2)),
                        };
                        intersections.push({
                            coords: {
                                zonePolygonCenter,
                                placePolygonCenter,
                            },
                            info,
                        });
                    }
                }
            } catch (err) {
                console.warn('get_intersections >>>>', err);
            }
        });
    });

    return intersections;
}

module.exports = { get_intersections };
