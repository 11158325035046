import React from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { commonTools } from '../../../../tuples';
import { storeIntersections } from '../../reducer';

/**
 * Компонент контрольной панели
 */
const Controls = ({ ...props }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const zonesGroups = props?.dataFromLayers['zones_layer']?.objects || [];
        const escalatorsObjects = props?.dataFromLayers['escalators_layer']?.objects || [];        
        props.visible &&
            dispatch(
                storeIntersections({ zonesGroups, escalatorsObjects, planScale: props?.currentPlanData?.planScale }),
            );
    }, [
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['zones_layer']?.objects)),
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['escalators_layer']?.objects)),
        props?.currentPlanData?.planScale,
        props.visible,
    ]);

    return <></>;
};

export default Controls;
