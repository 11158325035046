/**
 * Список инструментов
 */
export const toolsList = [
    {
        id: 'resize',
        name: 'Resize',
        icon: { iconName: 'ScaleVolume', size: '20px' },
        tooltipText: 'Resize',
    },
    {
        id: 'rotate',
        name: 'Rotate',
        icon: { iconName: 'Rotate', size: '20px' },
        tooltipText: 'Rotate',
    },
    {
        id: 'draw',
        name: 'Draw',
        icon: { iconName: 'CircleAddition', size: '20px' },
        tooltipText: 'Draw (Shift + A)',
    },
    {
        id: 'move',
        name: 'Move',
        icon: { iconName: 'Move', size: '20px' },
        tooltipText: 'Move',
    },
    {
        id: 'moveLayer',
        name: 'Move layer',
        icon: { iconName: 'SIPMove', size: '20px' },
        tooltipText: 'Move layer',
    },
];
