import { getIntervals } from './getIntervals';
import { IWorkingHours } from '../../../../../tools/useRequestStructures/interfaces';

export const getWhStatus = (workingHours: IWorkingHours[]): 'full' | 'empty' | 'incorrect' => {
    let status: 'full' | 'empty' | 'incorrect' = 'full';

    if (!workingHours || !workingHours.length) {
        status = 'empty';
    } else {
        const { sortedWh, skips } = getIntervals(workingHours);

        if (skips.length || sortedWh[0].date_from !== null || sortedWh[sortedWh.length - 1].date_to !== null) {
            status = 'incorrect';
        }
    }

    return status;
};
