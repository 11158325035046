import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import styled from 'styled-components/macro';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { theme } from '../../../../constants/theme';

const { green, red, white } = theme.palette;

export const ListWrapper = styled.section`
    width: 100%;
    height: calc(100% - 100px);
    flex-direction: column;
    position: relative;
    .ms-DetailsHeader {
        cursor: pointer;
        padding: 0;
        span {
            font-size: 16px;
        }
    }
`;
export const StyledDefaultButton = styled(DefaultButton)`
    border: none;
    padding: 5px;
    span {
        font-weight: 400 !important;
    }
    background-color: transparent !important;
`;

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    // margin: '0 25px',
});

export const classNames = mergeStyleSets({
    red: [{ color: red }, iconClass],
    green: [{ color: green }, iconClass],
});

export const EditWrapper = styled.div`
    :hover {
        cursor: pointer;
    }
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;
