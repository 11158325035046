import React, { useMemo } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Circle } from 'react-konva';
import { updateAnchorCoords } from './core/updateAnchorCoords';
import { IAnchorsProps } from './Anchors.interfaces';

/**
 * Компонент точек
 * @param activeToolId
 * @param updateCoords
 * @param updateCoordsAnchors
 * @param coords
 * @param scale
 * @param front_id
 * @param snapToGrid
 * @param props
 * @constructor
 */
export const Anchors = ({
    activeToolId,
    currentPlanData,
    updateCoords,
    updateCoordsAnchors,
    coords,
    scale,
    front_id,
    snapToGrid,
    statusBackground,
    onClickAnchor,
    onAnchorContextMenu,
    selectedChapter = 'layers',
    ...props
}: IAnchorsProps) => {
    const onUpdateGeometry = (pointIndex: number) => (e: KonvaEventObject<MouseEvent>) => {
        if (!statusBackground) {
            e.evt.preventDefault();
            const newCoords = updateAnchorCoords(pointIndex, coords, e, currentPlanData, snapToGrid);
            newCoords && updateCoords(newCoords[newCoords.length - 1]);
            updateCoordsAnchors(newCoords, front_id);
        }
    };

    const onCircleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
        if (!statusBackground) {
            const container = e?.target?.getStage()?.container();
            if (container) {
                container.style.cursor = 'pointer';
            }
        }
    };

    const onCircleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        if (!statusBackground) {
            const container = e?.target?.getStage()?.container();
            if (container) {
                container.style.cursor = 'default';
            }
        }
    };

    const onClickCircle = (pointIndex: number) => (e: KonvaEventObject<MouseEvent>) => {
        if (onAnchorContextMenu) {
            e.evt.preventDefault();
            if (activeToolId === 'anchorsEdit') {
                const { x, y } = e.evt;
                onAnchorContextMenu({
                    cursor: { x, y },
                    anchorIndex: pointIndex,
                    pointsNum: coords.length,
                });
            }
        }

        if (onClickAnchor) {
            e.evt.preventDefault();
            const newCoords = updateAnchorCoords(pointIndex, coords, e, currentPlanData, snapToGrid);
            onClickAnchor(newCoords![pointIndex], pointIndex);
        }
    };

    const anchors = useMemo(() => {
        if (activeToolId !== 'anchorsEdit') return null;
        const result: Array<JSX.Element> = [];
        const draggable = selectedChapter === 'layers';
        coords.forEach((point: number[], i: number) => {
            result.push(
                <Circle
                    id={`__:${i}`}
                    key={`++:${i}`}
                    draggable={draggable}
                    x={point[0]}
                    y={point[1]}
                    radius={10 / scale.stageScale}
                    // opacity={0.6}
                    stroke={'red'}
                    strokeWidth={4 / scale.stageScale}
                    onDragMove={onUpdateGeometry(i)}
                    onContextMenu={onClickCircle(i)}
                    onMouseEnter={onCircleMouseEnter}
                    onMouseLeave={onCircleMouseLeave}
                />,
            );
        });

        return result;
    }, [activeToolId, coords, scale.stageScale, selectedChapter]);

    return <React.Fragment>{anchors}</React.Fragment>;
};
