import { isNumber, isUndefined } from 'lodash';
import { IAdditionalData, ICell, IColumn } from '../MonitoringDetailedData.interfaces';
import { describeSensors } from '../../../../../../../core/describeSensors';
import DataStatus from '../components/DataStatus/DataStatus';
import { dateRangesFilter } from '../tools/filterValidDateRanges';
import { IStructures, TObjectType } from '../../../../../../../../../interfaces';

export const MONITORING_DETAILED_DATA_COLUMNS: IColumn[] = [
    {
        columnId: 'sensor_id',
        headerText: 'Sensor ID',
        sensorDataKey: 'sensor_id',
    },
    {
        columnId: 'sensor_type',
        headerText: 'Sensor type',
        sensorDataKey: 'sensor_type',
    },
    {
        columnId: 'sensor_serial',
        headerText: 'Sensor serial',
        sensorDataKey: 'sensor_serial',
    },
    {
        columnId: 'ipoint_marker',
        headerText: 'IPoint marker',
        sensorDataKey: 'ipoint_marker',
    },
    {
        columnId: 'sensor_data_status',
        headerText: 'Data status',
        getCellValue: (cell, additionalData) => {
            if (cell.sensor && additionalData && isNumber(additionalData?.maxMeasures)) {
                return describeSensors(cell.sensor.values, additionalData.maxMeasures).operation;
            }
            return '';
        },
        forceComponent: true,
        component: DataStatus,
    },
    {
        columnId: 'sensor_passways_relations',
        headerText: 'Passways relations',
        getCellValue: (cell, additionalData) => {
            return getRelationsString({ cell, additionalData, objectType: 'passway' });
        },
    },
    {
        columnId: 'sensor_tenants_relations',
        headerText: 'Tenants relations',
        getCellValue: (cell, additionalData) => {
            return getRelationsString({ cell, additionalData, objectType: 'tenant' });
        },
    },
    {
        columnId: 'sensor_zones_relations',
        headerText: 'Zones relations',
        getCellValue: (cell, additionalData) => {
            return getRelationsString({ cell, additionalData, objectType: 'zone' });
        },
    },
];

const getRelationsString = (args: {
    cell: ICell;
    objectType: TObjectType;
    additionalData?: IAdditionalData;
}): string => {
    if (
        args.cell.sensor &&
        args.additionalData &&
        !isUndefined(args.additionalData.structures) &&
        args.additionalData.dateFrom &&
        args.additionalData.dateTo
    ) {
        return args.additionalData.structures.fpc.relations_sensor2dataobj
            .filter((relation) => {
                return (
                    relation.dataobj_type === args.objectType &&
                    relation.sensor_id === args.cell.sensor?.sensor_id &&
                    dateRangesFilter(relation, {
                        dateFrom: args.additionalData!.dateFrom!,
                        dateTo: args.additionalData!.dateTo!,
                    })
                );
            })
            .reduce((acc, relation) => {
                const name = getObjectName(
                    relation.dataobj_marker,
                    args.additionalData?.structures?.core.elements_admin_data_objects || [],
                );

                const fullText = name ? `${relation.dataobj_marker} (${name})` : relation.dataobj_marker;
                if (!acc) {
                    acc = fullText;
                } else {
                    acc += `,\n ${fullText}`;
                }

                return acc;
            }, '');
    }
    return '';
};

const getObjectName = (
    marker: string,
    elementsAdminDataObjects: IStructures['core']['elements_admin_data_objects'],
): string | null => {
    const dataObject = elementsAdminDataObjects.find((item) => item.marker === marker);
    return dataObject?.name || null;
};
