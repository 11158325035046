import { cloneDeep } from 'lodash';
import { sizes } from '../../../../../../constants/sizes';
import { commonTools } from '../../../../layers';
import { IPlan } from '../../../../layers.interfaces';
import { IStairs } from '../../interfaces';

export const changeOtherLayers = ({
    currentPlanData,
    stairs,
    allTheFloors,
    plansList,
    anotherLocationLayers,
}: {
    currentPlanData: IPlan;
    stairs: IStairs;
    allTheFloors: number[];
    plansList: IPlan[];
    anotherLocationLayers: { [x: string]: any }[];
}) => {
    const currentFloor = currentPlanData.floor;
    const plans = plansList.filter((pl) => pl.isMain);
    const [currentMain] = plans.filter((pl) => pl.floor === currentFloor);

    const result = anotherLocationLayers.map((layer) => {
        const [plan] = plans.filter((pl) => {
            if (pl?.floor === undefined || layer?.floor === undefined) return false;
            return pl.floor === layer.floor;
        });

        const newLift = { ...cloneDeep(stairs) };

        commonTools.shiftCoords(newLift, [-sizes.GLOBAL_SHIFT, -sizes.GLOBAL_SHIFT]);
        commonTools.transformCoords(newLift, currentMain.plan2geo);
        commonTools.transformCoords(newLift, plan.geo2plan);

        if (stairs.floors.includes(layer.floor)) {
            if (layer.data === null || layer.data.length === 0) {
                return { ...layer, data: [{ ...newLift }] };
            } else if (layer.data.length !== 0) {
                const ids = layer.data.map((item: IStairs) => item.front_id);
                if (ids.includes(stairs.front_id)) {
                    const data = layer.data.map((item: IStairs) => {
                        if (item.front_id !== stairs.front_id) {
                            return item;
                        } else {
                            return { ...newLift };
                        }
                    });
                    return { ...layer, data };
                } else {
                    const data = [...layer.data, { ...newLift }];
                    return { ...layer, data };
                }
            }
        } else if (!stairs.floors.includes(layer.floor)) {
            if (layer?.data?.length) {
                const ids = layer.data.map((item: IStairs) => item.front_id);
                if (ids.includes(stairs.front_id)) {
                    const data = layer.data.filter((item: IStairs) => item.front_id !== stairs.front_id);
                    return { ...layer, data };
                } else {
                    return layer;
                }
            } else {
                return layer;
            }
        }
    });
    return result;
};
