import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FontIcon } from '@fluentui/react/lib/Icon';

import { ICheckout } from '../../../../checkouts.interfaces';
import {
    reducerValues,
    selectObject,
    storeInitialObjects,
    storeInitialCreatedAt,
    deleteObject,
    toggleShowLabels,
    storeAnotherLocationNames,
    storeGeneralSettings,
    storeCurrentPlanData,
    storeVersionsData,
    validateNames,
    storeHotAddObject,
} from '../../../../reducer';
import { ObjectsListWrapper, ObjectWrapper, classNames } from './ObjectsList.styles';
import { commonTools } from '../../../../../../layers';

/**
 * Компонент списка объектов
 */
const ObjectsList = ({ ...props }) => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, currentPlanData, createdAt, isNamesValid, layerAlias, generalSettings } =
        useSelector(reducerValues);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        props.versionsData && dispatch(storeVersionsData(props.versionsData));
    }, [props.versionsData]);

    useEffect(() => {
        props.revertLayer && dispatch(storeInitialObjects(props.initialData.data));
    }, [props.revertLayer]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        props.layerChange({ id: layerAlias, objects, lastCreatedAt: createdAt, selectedObjectId });
    }, [objects, selectedObjectId]);

    useEffect(() => {
        dispatch(storeAnotherLocationNames(props.anotherLocationLayers));
    }, [props.anotherLocationLayers]);

    useEffect(() => {
        dispatch(validateNames());
    }, [objects]);

    useEffect(() => {
        props.toggleValidateAlert({
            id: layerAlias,
            show: !isNamesValid.valid,
            floor: currentPlanData?.floor,
            names: isNamesValid.names,
        });
    }, [isNamesValid.valid]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(selectObject());
    }, [props.active]);

    useEffect(() => {
        if (!props.initialData) return;
        dispatch(storeInitialObjects(props.initialData.data));
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    const { t } = useTranslation();

    const onObjectClick = (id: string) => () => {
        dispatch(selectObject(id));
    };

    const onDeleteIconClick = (id: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(deleteObject(id));
    };

    const objectsList = objects.map((object: ICheckout) => {
        const selected = object.id === selectedObjectId;
        return (
            <ObjectWrapper key={object.id} selected={selected} onClick={onObjectClick(object.id)}>
                {commonTools.getObjectLabelByMode({ objNamingMode: props?.generalSettings?.objNamingMode, object })}
                {!generalSettings.readOnly && (
                    <FontIcon iconName="Cancel" className={classNames.salmon} onClick={onDeleteIconClick(object.id)} />
                )}
            </ObjectWrapper>
        );
    });

    return <ObjectsListWrapper>{objectsList}</ObjectsListWrapper>;
};

export default ObjectsList;
