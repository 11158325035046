import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    DetailsList,
    buildColumns,
    IColumn,
    DetailsRow,
    IDetailsRowProps,
    IDetailsHeaderProps,
} from '@fluentui/react/lib/DetailsList';
import { useTranslation } from 'react-i18next';

import { ListWrapper } from './List.styles';
import { IColumns, IItems } from './List.interfaces';
import { useSelector } from 'react-redux';
import {
    ConstrainMode,
    DefaultButton,
    DetailsListLayoutMode,
    IDetailsRowStyles,
    ScrollablePane,
    ScrollbarVisibility,
    Sticky,
    StickyPositionType,
    TextField,
} from '@fluentui/react';
import { GeneralContext } from '../../../../../../General.context';
import { commonTools } from '../../../../../Layers/layers';
import { TAccountWithProduct } from '../../Accounts.interfaces';
import { accountsReducerValues, getLink } from '../../Accounts.reducer';
import ProductsCell from '../ProductsCell/ProductsCell';
import LocationsCell from '../LocationsCell/LocationsCell';
import { locationsReducerValues } from '../../../../Locations.reducer';
import { PERMISSIONS_SELECT_WIDTH } from '../../constants/constants';
import { useAppDispatch } from '../../../../../../store';
import StackHorizontal from '../../../../../StackHorizontal/StackHorizontal';
import { toggleAlert } from '../../../../../../General.reducer';

const List: React.FC<{ list: TAccountWithProduct[]; projectId: string }> = ({ list, projectId }) => {
    const dispatch = useAppDispatch();
    const { theme } = useContext(GeneralContext);
    const [columns, setColumns] = useState<IColumn[]>([]);
    const [sortedItems, setSortedItems] = useState<IItems>([]);
    const { products, appLinkByUserId } = useSelector(accountsReducerValues);
    const { projectLocationsList } = useSelector(locationsReducerValues);

    const { themeLighterAlt, white } = theme.palette;
    const { t } = useTranslation();

    const items = list.map((item) => {
        const { username, id, productIds, locationIds } = item;
        const userLink = id;
        return { id, username, productIds, locationIds, userLink };
    });

    useEffect(() => {
        const columns = myBuildColumns(items);
        setColumns(columns);
        setSortedItems(items);
    }, [list, appLinkByUserId]);

    function myBuildColumns(items: IColumns[]) {
        if (items.length === 0) return [];
        const columns = buildColumns(items);
        const [idCol] = columns.filter((column) => column.name === 'id');
        idCol.maxWidth = 60;
        idCol.name = 'User ID';
        const [nameCol] = columns.filter((column) => column.name === 'username');
        nameCol.name = 'User name';
        nameCol.minWidth = 100;
        nameCol.maxWidth = 200;
        const [productsCol] = columns.filter((column) => column.name === 'productIds');
        productsCol.name = 'Products';
        productsCol.minWidth = PERMISSIONS_SELECT_WIDTH;
        productsCol.maxWidth = PERMISSIONS_SELECT_WIDTH;
        const [locationsCol] = columns.filter((column) => column.name === 'locationIds');
        locationsCol.name = 'Project locations';
        locationsCol.minWidth = PERMISSIONS_SELECT_WIDTH;
        locationsCol.maxWidth = PERMISSIONS_SELECT_WIDTH;
        const [linkCol] = columns.filter((column) => column.name === 'userLink');
        linkCol.name = 'APP with user login';

        // const [thumbnailColumn] = columns.filter((column) => column.name === 'thumbnail');
        // const [deleteCellColumn] = columns.filter((column) => column.name === 'deleteCell');

        // thumbnailColumn.name = 'thumbnail';
        // thumbnailColumn.minWidth = 80;
        // thumbnailColumn.maxWidth = 80;
        // thumbnailColumn.ariaLabel = 'Thumbnail';

        // deleteCellColumn.name = '';
        return columns;
    }

    const renderRow = (props: IDetailsRowProps | undefined) => {
        const customStyles = {
            root: {
                display: 'flex',
                alignItems: 'center',
                backgroundColor: white,
                cursor: 'pointer',
                width: '100%',
            },
            cell: {
                display: 'flex',
                alignItems: 'center',
            },
        };

        if (props) {
            if (props.itemIndex % 2 === 0) {
                customStyles.root['backgroundColor'] = themeLighterAlt;
            }
            return (
                <div
                // onClick={onRowClick(props.item.id)}
                >
                    <DetailsRow {...props} styles={customStyles} />
                </div>
            );
        }
        return null;
    };

    const onGetLinkClick = (userId: number) => () => {
        dispatch(getLink(userId));
    };
    async function copyTextToClipboard(text: string) {
        try {
            if ('clipboard' in navigator) {
                navigator.clipboard.writeText(text);
            } else {
                document?.execCommand('copy', true, text);
            }
            dispatch(toggleAlert({ show: true, type: 'success', lifeTime: 10000, text: 'URL copied to clipboard.' }));
        } catch (error) {
            dispatch(toggleAlert({ show: true, type: 'warning', lifeTime: 10000, text: 'Something went wrong.' }));
            console.error(error);
        }
    }

    function renderItemColumn(item: { [x: string]: any }, index: any, column: IColumn | undefined) {
        if (!column?.fieldName) return null;

        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case 'productIds':
                return (
                    <ProductsCell
                        products={products}
                        assignedProductIds={fieldContent}
                        accountId={item.id}
                        projectId={projectId}
                    />
                );
            case 'locationIds':
                return (
                    <LocationsCell
                        locations={projectLocationsList}
                        assignedLocationsIds={fieldContent}
                        accountId={item.id}
                        projectId={projectId}
                    />
                );
            case 'userLink':
                if (appLinkByUserId[fieldContent]) {
                    return (
                        <StackHorizontal>
                            <DefaultButton
                                iconProps={{ iconName: 'Copy' }}
                                onClick={() => copyTextToClipboard(appLinkByUserId[fieldContent].link)}
                            >
                                {t('Copy link')}
                            </DefaultButton>
                            <TextField value={appLinkByUserId[fieldContent].link} readOnly={true} />
                        </StackHorizontal>
                    );
                }
                return (
                    <DefaultButton iconProps={{ iconName: 'Copy' }} onClick={onGetLinkClick(fieldContent)}>
                        {t('Get link')}
                    </DefaultButton>
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    const customTableHeader = (
        headerProps: IDetailsHeaderProps | undefined,
        defaultRender: ((props?: IDetailsHeaderProps | undefined) => JSX.Element | null) | undefined,
    ) => {
        if (!defaultRender) return null;
        return (
            <Sticky
                stickyPosition={StickyPositionType.Header}
                isScrollSynced={true}
                stickyBackgroundColor="transparent"
            >
                <div>{defaultRender(headerProps)}</div>
            </Sticky>
        );
    };

    const listStyles: Partial<IDetailsRowStyles> = { root: { width: '100%', overflowY: 'auto' } };

    return (
        <ListWrapper>
            <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                <DetailsList
                    styles={listStyles}
                    onRenderDetailsHeader={customTableHeader}
                    // layoutMode={DetailsListLayoutMode.fixedColumns}
                    // onColumnHeaderClick={onColumnClick}
                    items={sortedItems}
                    onRenderItemColumn={renderItemColumn}
                    constrainMode={ConstrainMode.unconstrained}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    setKey="set"
                    columns={columns}
                    onRenderRow={renderRow}
                    selectionMode={0}
                    // constrainMode={ConstrainMode.unconstrained}
                    // constrainMode={0}
                    onShouldVirtualize={() => {
                        return false;
                    }}
                    isHeaderVisible={true}
                />
            </ScrollablePane>
        </ListWrapper>
    );
};

export default List;
