import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DatePicker, PrimaryButton } from '@fluentui/react';
import { Label } from '@fluentui/react/lib/Label';
import { useTranslation } from 'react-i18next';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { commonTools } from '../../../../../../monitoringLayers';

import {
    reducerValues,
    toggleLayerModal,
    fetchRelations,
    resetSensors,
    storeDateFrom,
    storeDateTo,
    storeDate,
} from '../../../../reducer';
import { StyledStack, buttonStyles, OptionsWrapper, TopWrapper, Buttons } from './ObjectOptions.styles';
import FilterInput from '../../../../../../../FilterInput/FilterInput';
import useDayPickerStrings from '../../../../../../../../constants/useDayPickerStrings';
import { theme } from '../../../../../../../../constants/theme';
import { IVersionsData } from '../../../../../../interfaces';
import HeatMap from '../../../HeatMap/HeatMap';
import { useAppDispatch } from '../../../../../../../../store';
import { ButtonsWrapper } from '../../../../../../../Editor/components/Tenants/components/PlacePanel/styles';

const ObjectOptions = () => {
    const dispatch = useDispatch<any>();
    const { selectedSensorId, sensors, spinner, versionsData, activePlId, isLayerVisible, dateFrom, dateTo, date } =
        useSelector(reducerValues);
    const { t } = useTranslation();
    const [options, setOptions] = useState<{ key: string; text: string }[]>([]);
    const [refreshRelations, setRefreshRelations] = React.useState<boolean>(false);
    const DAY_PICKER_STRINGS = useDayPickerStrings();

    const [filteredOptions, setFilteredOptions] = useState<{ key: string; text: string }[]>([]);

    // useEffect(() => {
    //     setRefreshRelations(() => false);
    // }, [plIds]);

    useEffect(() => {
        if (refreshRelations && isLayerVisible) {
            dispatch(resetSensors());
            setRefreshRelations(() => false);
        }
    }, [refreshRelations, isLayerVisible, dispatch]);

    useEffect(() => {
        activePlId && setRefreshRelations(() => true);
    }, [activePlId]);

    // useEffect(() => {
    //     if (!versionsData) return;
    //     const { versionsById, activeVersionId } = versionsData as IVersionsData;
    //     if (versionsById && activeVersionId) {
    //         const currentVersion = versionsById[activeVersionId];
    //         let { date_from, date_to } = currentVersion;
    //         if (date_to === null) {
    //             date_to = '2200-12-31';
    //         }
    //         const maxDateFrom = new Date(Math.min(new Date().getTime(), new Date(date_to as string).getTime()));
    //         const maxDateTo = maxDateFrom;
    //         setDateTo(maxDateTo);
    //         setDateFrom(new Date(Math.max(maxDateTo.getTime() - 604800000, new Date(date_from as string).getTime())));
    //     }
    // }, [versionsData]);

    const setDateFrom = (date: Date) => dispatch(storeDateFrom(date));
    const setDateTo = (date: Date) => dispatch(storeDateTo(date));
    const setDate = (date: Date) => {
        dispatch(resetSensors());
        dispatch(storeDate(date));
    };

    const onParseDateFromString = useCallback(
        (mode: string) =>
            (newValue: string): Date => {
                return mode === 'date_from'
                    ? commonTools.getDateFromString({ value: new Date(dateFrom), newValue })
                    : commonTools.getDateFromString({ value: new Date(dateTo), newValue });
            },
        [dateFrom, dateTo],
    );

    const dateToPlaceHolder = useMemo(() => {
        return !dateTo || new Date(dateTo).getFullYear() > 2100 ? 'Now' : new Date(dateTo).toDateString();
    }, [dateTo]);

    const updateFilteredArray = (arr: object[]) => {
        setFilteredOptions(arr as { key: string; text: string }[]);
    };

    const filter = React.useMemo(() => {
        return <FilterInput inputArray={options} matchField={'text'} updateFilteredArray={updateFilteredArray} />;
    }, [options]);

    const selectedObject = sensors ? sensors.filter((item) => item.sensor_id === selectedSensorId)[0] : null;

    const StackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const dateBorders = useMemo(() => {
        if (!versionsData) return;
        const { versionsById, activeVersionId } = versionsData as IVersionsData;
        if (versionsById && activeVersionId) {
            const currentVersion = versionsById[activeVersionId];
            let { date_from, date_to } = currentVersion;
            if (date_to === null) {
                date_to = '2200-12-31';
            }
            const maxDateFrom = new Date(Math.min(new Date().getTime(), new Date(date_to as string).getTime()));
            const minDateFrom = new Date(date_from as string);
            const maxDateTo = maxDateFrom;
            const minDateTo = minDateFrom;
            return { maxDateFrom, minDateFrom, maxDateTo, minDateTo };
        }
        dispatch(resetSensors());
    }, [dispatch, versionsData]);

    const onRequestClick = () => {
        dispatch(resetSensors());
        dispatch(fetchRelations());
    };

    const onShowInfoClick = () => {
        dispatch(toggleLayerModal({ show: true }));
    };

    const selectedSensor = sensors?.filter((item) => item.sensor_id === selectedSensorId);

    const damagedSensors = sensors?.filter((sensor) => {
        return sensor.values.some((item) => item.v < 1);
    });

    return (
        <OptionsWrapper>
            <TopWrapper>
                {selectedObject ? (
                    <StyledStack tokens={StackTokens}>
                        <Label styles={{ root: { color: theme.palette.themePrimary } }}>
                            {'Selected sensor options:'}
                        </Label>
                        <Label
                            styles={{ root: { paddingBottom: 0, marginRight: '10px' } }}
                        >{`Sensor id: ${selectedObject.sensor_id} | Sensor serial: ${selectedObject.sensor_serial} | Sensor type: ${selectedObject.sensor_type} | PC iPoint marker: ${selectedObject.ipoint_marker}`}</Label>
                        {/* <Label>{`Sensor id: ${selectedObject.sensor_id}`}</Label> */}
                        {/* <Label>{`Sensor serial: ${selectedObject.sensor_serial}`}</Label>
                        <Label>{`Sensor type: ${selectedObject.sensor_type}`}</Label>
                        <Label>{`PC iPoint marker: ${selectedObject.ipoint_marker}`}</Label> */}
                    </StyledStack>
                ) : null}

                {/* <DatePicker
                    allowTextInput
                    ariaLabel="Select a date."
                    // placeholder={placeholder}
                    value={new Date(dateFrom)}
                    onSelectDate={setDateFrom as (date?: Date | undefined | null) => void}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={onParseDateFromString('date_from')}
                    // className={styles.control}
                    strings={DAY_PICKER_STRINGS}
                    maxDate={dateBorders?.maxDateFrom}
                    minDate={dateBorders?.minDateFrom}
                />
                <DatePicker
                    allowTextInput
                    ariaLabel="Select a date."
                    placeholder={dateToPlaceHolder}
                    value={new Date(dateTo) || undefined}
                    onSelectDate={setDateTo as (date?: Date | undefined | null) => void}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={onParseDateFromString('date_to')}
                    // className={styles.control}
                    strings={DAY_PICKER_STRINGS}
                    maxDate={dateBorders?.maxDateTo}
                    minDate={dateBorders?.minDateTo}
                /> */}
                <DatePicker
                    label={t('Select date')}
                    allowTextInput
                    ariaLabel="Select a date."
                    placeholder={dateToPlaceHolder}
                    value={new Date(date) || undefined}
                    onSelectDate={setDate as (date?: Date | undefined | null) => void}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={onParseDateFromString('date_to')}
                    // className={styles.control}
                    strings={DAY_PICKER_STRINGS}
                    minDate={dateBorders?.minDateFrom}
                    maxDate={dateBorders?.maxDateTo}
                />
                <Buttons>
                    <PrimaryButton
                        text={t('Request data')}
                        onClick={onRequestClick}
                        allowDisabledFocus
                        disabled={!activePlId || spinner}
                        styles={buttonStyles}
                    />
                    <PrimaryButton
                        text={t('Show problems')}
                        onClick={onShowInfoClick}
                        allowDisabledFocus
                        disabled={!sensors || damagedSensors?.length === 0}
                        styles={buttonStyles}
                    />
                </Buttons>
            </TopWrapper>

            {selectedSensor?.length ? <HeatMap sensors={selectedSensor} mode="small" /> : null}
        </OptionsWrapper>
    );
};

export default ObjectOptions;
