import React from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { useTranslation } from 'react-i18next';

import { MainWrapper, columnProps, footerProps } from './styles';

const ShowUserModal = ({ closeModal, pass, email }: { closeModal: () => void; pass: string; email: string }) => {
    const { t } = useTranslation();

    return (
        <MainWrapper>
            <Stack {...columnProps}>
                <TextField label={t('User login')} value={email} readOnly underlined />
                <TextField label={t('User password')} value={pass} readOnly underlined />
            </Stack>
            <Stack {...footerProps}>
                <Stack.Item align={'end'}>
                    <PrimaryButton text={t('OK')} onClick={closeModal} />
                </Stack.Item>
            </Stack>
        </MainWrapper>
    );
};

export default ShowUserModal;
