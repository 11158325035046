import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { reducerValues } from '../../reducer';
import { ICell } from './interfaces';
import { IntersectModalWrapper, IntersectTableWrapper, CustomCell } from './styles';
import ReactDataSheet from 'react-datasheet';
import { cloneDeep } from 'lodash';

/**
 * Компонент содержимое модального окна
 */
const Modal = ({ ...props }) => {
    const { intersections } = useSelector(reducerValues);

    const [isMouseDown, setIsMouseDown] = useState(false);
    const [selected, setSelected] = useState<any>({ start: null, end: null });

    const grid: ICell[][] = [
        [
            { value: 'lift_marker', width: '25%', readOnly: true },
            { value: 'lift_name', width: '25%', readOnly: true },
            { value: 'zone_marker', width: '25%', readOnly: true },
            { value: 'zone_name', width: '25%', readOnly: true },
            { value: 'zone_group_marker', width: '25%', readOnly: true },
            { value: 'zone_group_name', width: '25%', readOnly: true },
        ],
    ];

    intersections.forEach((item) => {
        const result: Array<ICell> = [];
        Object.keys(item.info).forEach((key) => {
            result.push({ value: item.info[key] });
        });
        grid.push(result);
    });

    const filteredGrid = Array.from(new Set(grid.map((item: ICell[]) => JSON.stringify(item)))).map((item) =>
        JSON.parse(item),
    );

    const onMouseUp = (value: boolean) => () => setIsMouseDown(value);

    const onMouseOver =
        (props: { row: number; col: number; onMouseOver: React.MouseEventHandler }) => (e: React.MouseEvent) => {
            if (isMouseDown) {
                setSelected((prevState: any) => {
                    const newState = cloneDeep(prevState);
                    newState.end = { i: props.row, j: props.col };
                    return newState;
                });
                props.onMouseOver(e);
            }
        };

    const onMouseDown =
        (props: { row: number; col: number; onMouseDown: React.MouseEventHandler }) => (e: React.MouseEvent) => {
            setIsMouseDown(true);
            setSelected({ start: { i: props.row, j: props.col }, end: { i: props.row, j: props.col } });
            props.onMouseDown(e);
        };

    const cellRenderer = (props: any) => {
        return (
            <CustomCell
                onMouseDown={onMouseDown(props)}
                onMouseUp={onMouseUp(false)}
                onMouseMove={onMouseOver(props)}
                className={props.className}
            >
                {props.children}
            </CustomCell>
        );
    };

    return (
        <IntersectModalWrapper>
            <IntersectTableWrapper>
                <ReactDataSheet
                    selected={selected}
                    cellRenderer={cellRenderer}
                    data={filteredGrid}
                    valueRenderer={(cell: any) => cell.value}
                />
            </IntersectTableWrapper>
        </IntersectModalWrapper>
    );
};

export default Modal;
