import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layer, Group, Circle, Star } from 'react-konva';

import { reducerValues } from '../../reducer';
import { commonTools, ObjectLabel } from '../../../../../Layers/layers';
import { ICrossObject } from '../../interfaces';
import { TupleLabel } from '../../../../../../tools/commonComponents';

const zeroLabel = { show: false, text: '', x: 0, y: 0 };

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const dispatch = useDispatch();
    const { tupleAlias, intersections } = useSelector(reducerValues);
    const [label, setLabel] = useState(zeroLabel);
    const { stageScale } = props.scale;

    const onMouseEnter = (obj: ICrossObject) => () => {
        const x = obj.coords[0];
        const y = obj.coords[1];
        const text = `stairs_marker: ${obj.info.stairsMarker}\nstairs_name: ${obj.info.stairsName}\nexternal_traffic: ${obj.info.externalTraffic}\npassway_marker: ${obj.info.passWayMarker}\npassway_name: ${obj.info.passWayName}\ndirection: ${obj.info.direction}`;
        setLabel({ show: true, text, x, y });
    };
    const onMouseLeave = () => {
        setLabel({ ...zeroLabel });
    };

    const geometry = useMemo(() => {
        const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
        return intersections.map((obj, i) => {
            return obj.coords ? (
                <Star
                    key={`))st9fuear${i}))`}
                    x={obj.coords[0]}
                    y={obj.coords[1]}
                    numPoints={8}
                    innerRadius={foo(5)}
                    outerRadius={foo(10)}
                    fill={'green'}
                    onMouseEnter={onMouseEnter(obj)}
                    onMouseLeave={onMouseLeave}
                />
            ) : null;
        });
    }, [intersections, stageScale]);

    try {
        return (
            <>
                <Layer key={tupleAlias}>
                    {geometry}
                    {label.show && <TupleLabel x={label.x} y={label.y} text={label.text} />}
                </Layer>
            </>
        );
    } catch (error) {
        return null;
    }
};

export default Draw;
