import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';

import { Layer, Line, Group } from 'react-konva';

import { measureCutReducerValues, changeCoords } from '../../measureCut.reducer';
import Helper from './components/Helper/Helper';
import Anchors from './components/Anchors/Anchors';
import { drawNewCut } from './core/drawNewCut';

const Draw = ({ ...props }) => {
    const { activeToolId, coords } = useSelector(measureCutReducerValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);

    useEffect(() => {
        if (activeToolId === 'draw') {
            const container = props.stage.current.container();
            if (container) {
                container.style.cursor = 'crosshair';
            }
        }
    }, [activeToolId]);

    const updateIsDrawNow = (isDrawNow: boolean) => {
        setIsDrawNow(isDrawNow);
    };

    const updateCoords = (coords: Array<number[]>) => {
        dispatch(changeCoords(coords));
    };

    const onHelperClick = (e: KonvaEventObject<MouseEvent>) => {
        if (activeToolId === 'draw') {
            drawNewCut({ coords, isDrawNow, e, updateIsDrawNow, updateCoords });
        }
    };

    const geometry = useMemo(() => {
        if (!coords || coords.length === 0) return null;
        return (
            <Group>
                <Line
                    points={[...coords[0], ...coords[1]]}
                    stroke={'red'}
                    strokeWidth={2 / props.scale.stageScale}
                    opacity={1}
                    onClick={onHelperClick}
                />
                <Anchors activeToolId={activeToolId} coords={coords} updateCoords={updateCoords} scale={props.scale} />
            </Group>
        );
    }, [activeToolId, coords, props.scale]);

    return (
        <Layer key={props.key}>
            <Helper updateCoords={updateCoords} isDrawNow={isDrawNow} coords={coords} onHelperClick={onHelperClick} />
            {geometry}
        </Layer>
    );
};

export default Draw;
