import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';

import { TextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';
import { DatePicker, IDatePickerStrings } from '@fluentui/react/lib/DatePicker';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { changeCoords, measureCutReducerValues } from '../../../../measureCut.reducer';

import { ObjectsListWrapper, FooterWrapper } from './ObjectsList.styles';
import useDayPickerStrings from '../../../../../../../../constants/useDayPickerStrings';
import { parseDateFromString } from './core/parseDateFromString';
import { useEffect } from 'react';
import { commonTools } from '../../../../../../layers';
import { storeActiveLayerId } from '../../../../../../../Editor/Editor.reducer';
import { DayOfWeek } from '@fluentui/date-time-utilities';

interface IControlsProps {
    currentPlanData: { floor: number; activeFrom: string; notes: string; image: Blob; locationId: string | undefined };
    key: string;
    layerChange: (args: object) => void;
}

const ObjectsList = ({ ...props }) => {
    const { coords } = useSelector(measureCutReducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [floor, setFloor] = useState(String(props.currentPlanData.floor));
    const [cut, setCut] = useState('');
    const [activeFrom, setActiveFrom] = useState(new Date(props.currentPlanData.activeFrom));
    const [notes, setNotes] = useState(props.currentPlanData.notes);

    const DAY_PICKER_STRINGS = useDayPickerStrings();
    const firstDayOfWeek = DayOfWeek.Monday;
    const isCoordsExist = coords.length !== 0 && coords[0].length === 2 && coords[1].length === 2;
    const disabled: boolean = cut === undefined || cut === '' || floor === undefined || floor === '' || !isCoordsExist;

    useEffect(() => {
        if (props?.currentPlanData?.line?.coordinates) {
            dispatch(changeCoords(props.currentPlanData.line.coordinates));
        }
        if (props?.currentPlanData?.length) {
            setCut(() => String(props.currentPlanData.length));
        }
    }, [props.currentPlanData]);

    const onFloorChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue !== undefined && setFloor(newValue || '');
    };

    const onCutChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue !== undefined && setCut(newValue || '');
    };

    const onSelectDate = (date: Date | null | undefined) => {
        date && setActiveFrom(date);
    };

    const onNotesChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue !== undefined && setNotes(newValue || '');
    };

    const onSavePlanClick = () => {
        const line = {
            type: 'LineString',
            coordinates: coords,
        };

        props.layerChange({
            id: props.id,
            length: cut,
            activeFrom,
            notes,
            line,
            floor,
            image: props.currentPlanData.image,
            locationId: props.currentPlanData.locationId,
        });
    };

    const onEditPlanClick = () => {
        const line = {
            type: 'LineString',
            coordinates: coords,
        };

        props.layerChange({
            id: props.id,
            planId: props.currentPlanData.planId,
            isEdit: true,
            length: cut,
            notes,
            activeFrom,
            line,
            locationId: props.currentPlanData.locationId,
        });
    };

    return (
        <ObjectsListWrapper>
            <Stack>
                <TextField
                    label={t('Floor')}
                    value={String(floor)}
                    onChange={onFloorChange}
                    type={'number'}
                    min={-1000}
                    required
                />

                <DatePicker
                    label={t('Plan is active from')}
                    isRequired={true}
                    allowTextInput={true}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DAY_PICKER_STRINGS}
                    value={activeFrom}
                    onSelectDate={onSelectDate}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={parseDateFromString}
                />
                <TextField label={t('Notes')} value={notes} multiline rows={5} onChange={onNotesChange} />
                <TextField
                    label={t('Measure cut length (m)')}
                    step={0.01}
                    value={String(cut)}
                    onChange={onCutChange}
                    type={'number'}
                    min={0}
                    required
                />
            </Stack>
            <FooterWrapper>
                <Stack>
                    <Stack.Item align={'end'}>
                        {!props.currentPlanData.planId ? (
                            <PrimaryButton
                                text={t('Save plan')}
                                onClick={onSavePlanClick}
                                allowDisabledFocus
                                disabled={disabled}
                            />
                        ) : (
                            <PrimaryButton
                                text={t('Edit plan')}
                                onClick={onEditPlanClick}
                                allowDisabledFocus
                                disabled={disabled}
                            />
                        )}
                    </Stack.Item>
                </Stack>
            </FooterWrapper>
        </ObjectsListWrapper>
    );
};

export default ObjectsList;
