import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { FontIcon } from '@fluentui/react/lib/Icon';

import { IPCC, ISensor } from '../../../../interfaces';
import {
    reducerValues,
    selectSensor,
    storeInitialObjects,
    storeCurrentPlanData,
    storeGeneralSettings,
    storeVisible,
    updateSensors,
    storeVersionsData,
    toggleShowLabels,
    resetSensors,
    storeActivePlId,
} from '../../../../reducer';
import { ObjectsListWrapper, ObjectWrapper, classNames, dropdownStyles, Circle } from './ObjectsList.styles';
import { DefaultButton, Dropdown, IDropdownOption, Spinner } from '@fluentui/react';

import { describeSensors } from '../../../../core/describeSensors';
import { cloneDeep, sortBy } from 'lodash';

/**
 * Компонент списка объектов слоя
 */
const ObjectsList = ({ ...props }) => {
    const dispatch = useDispatch();
    const { selectedSensorId, sensors, spinner, relations, layerModal, layerAlias, alert, maxMeasures } =
        useSelector(reducerValues);

    useEffect(() => {
        props.versionsData && dispatch(storeVersionsData(props.versionsData));
    }, [props.versionsData]);

    useEffect(() => {
        props.activePlId && dispatch(storeActivePlId(props.activePlId));
    }, [dispatch, props.activePlId]);

    useEffect(() => {
        dispatch(storeVisible(props.visible));
    }, [props.visible]);

    useEffect(() => {
        layerModal.show && props.showMonitoringLayerModal(layerAlias);
    }, [layerModal]);

    useEffect(() => {
        props.toggleMonitoringAlert(alert);
    }, [alert]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        if (!props.pcIpoints) return;
        dispatch(storeInitialObjects(props.pcIpoints.data));
        if (relations) {
            dispatch(resetSensors());
            dispatch(updateSensors(props.pcIpoints.data));
        }
    }, [JSON.stringify(props.pcIpoints)]);

    const { t } = useTranslation();

    const onObjectClick = (id: number) => () => {
        dispatch(selectSensor(id));
    };

    const operationMap: { [x: string]: number } = {
        full: 0,
        partial: -1,
        no: -2,
    };

    const objectsList = sensors
        ? sortBy(sensors, (sensor) => {
              const operation = describeSensors(sensor.values, maxMeasures).operation;
              return operationMap[operation] || 0;
          }).map((sensor: ISensor) => {
              const selected = sensor.sensor_id === Number(selectedSensorId);

              return (
                  <ObjectWrapper key={sensor.sensor_id} selected={selected} onClick={onObjectClick(sensor.sensor_id)}>
                      <Circle style={{ background: describeSensors(sensor.values, maxMeasures).fill }} />
                      {`${sensor.sensor_id} ${sensor.sensor_serial}`}
                  </ObjectWrapper>
              );
          })
        : null;

    return (
        <ObjectsListWrapper>
            {spinner ? <Spinner label={t('Loading...')} ariaLive="assertive" labelPosition="right" /> : objectsList}
        </ObjectsListWrapper>
    );
};

export default ObjectsList;
