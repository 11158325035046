import styled from 'styled-components/macro';
import { sizes } from '../../../../constants/sizes';
import { theme } from '../../../../constants/theme';

export const ObjectWrapper = styled.div``;

export const Wrapper = styled.section`
    height: calc(100vh - ${sizes.HEADER_HEIGHT}px - 100px) !important ;
    width: calc(100% - 20px);
    padding: 10px;
    overflow: auto !important;
    background-color: ${theme.palette.neutralLighterAlt};
    position: relative;
    table {
        width: 100%;
        table-layout: auto !important;
        /* position: relative; */
        th {
            position: sticky;
            top: -10px;
        }
    }
`;

export const CustomHeader = styled.th<{ types: string[] }>`
    vertical-align: middle !important;
    padding: 8px 5px !important;
    span {
        font-size: 14px;
        color: ${theme.palette.neutralDark};
        font-weight: 700;
        display: flex !important;
        /* overflow: auto; */
        align-items: center;
        justify-content: flex-start;
        text-align: left !important;
    }
`;
export const CustomCell = styled.td<{ types: string[] }>`
    padding: 8px 5px !important;
    background-color: white !important;
    vertical-align: middle !important;
    white-space: ${(p) => (p.types.includes('date') ? 'nowrap !important' : 'wrap')};
    max-width: ${(p) => (p.types.includes('object') ? '200px !important' : 'none')};
    span {
        font-size: 12px;
        color: ${theme.palette.neutralDark};
        display: flex !important;
        /* overflow: auto; */
        overflow: hidden;
        align-items: center;
        justify-content: flex-start;
        text-align: left !important;
        white-space: pre-wrap;
    }
`;

export const FilterIndicator = styled.div`
    text-align: left !important;
    width: 100%;
    min-height: 14px;
    padding-top: 5px;
    font-size: 12px;
    overflow: hidden;
`;
