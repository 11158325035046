import tools from './components/Tools';
import draw from './components/Draw';
import controls from './components/Controls';
import { initialState } from './reducer';

export const wifiSensorsLayer = {
    id: initialState.layerAlias,
    tools,
    draw,
    controls,
};
