import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPlan } from '../../../../../Plans/Plans.interfaces';
import { EditorContext } from '../../../../Editor.context';

import { FloorsWrapper, Floor } from './FloorsButtons.styles';

/**
 * Компонента кнопок этажей.
 */

const FloorsButtons = () => {
    const { t } = useTranslation();
    const { plansList, storePlanToEditTrans, planToEdit, plansIdsByVersionIds, activeVersionId } =
        useContext(EditorContext);

    const onFloorClick = (plan: IPlan) => () => {
        storePlanToEditTrans(plan);
    };

    const floorsButtons = useMemo(() => {
        return plansList
            .filter(
                (item: IPlan) => item.isActual,
                // && plansIdsByVersionIds[activeVersionId as number].includes(item.planId as number)
            )
            .sort((a: IPlan, b: IPlan) => Number(b.floor) - Number(a.floor))
            .map((item: IPlan) => {
                const isActiveFloor = item.floor === planToEdit?.floor;
                return (
                    // <TooltipHost key={`${item.floor}`} content={String(`${t('Floor')}: ${item?.floor}`)}>
                    <Floor key={`${item.floor}`} isActiveFloor={isActiveFloor} onClick={onFloorClick(item)}>
                        {item.floor}
                    </Floor>
                    // </TooltipHost>
                );
            });
    }, [plansList, plansIdsByVersionIds, activeVersionId, planToEdit?.floor]);

    return <FloorsWrapper>{floorsButtons}</FloorsWrapper>;
};

export default FloorsButtons;
