import { useEffect, useState } from 'react';
import { ITableListItem } from '../TablesPanel.interfaces';
import useCachedTables from './useCachedTables';
import useRawTables from './useRawTables';
import { useDispatch, useSelector } from 'react-redux';
import { resetFilters, tablesPanelReducerValues } from '../TablesPanel.reducer';

const useTable = (): ITableListItem | undefined => {
    const { selectedTableId } = useSelector(tablesPanelReducerValues);

    const [table, setTable] = useState<ITableListItem>();

    const cachedTables = useCachedTables();
    const rawTables = useRawTables();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetFilters());
    }, [dispatch, selectedTableId]);

    useEffect(() => {
        if (selectedTableId) {
            const selectedBranch = selectedTableId?.split('/')?.[1] || '';

            if (selectedBranch) {
                const allTables = [...(cachedTables[selectedBranch] ?? []), ...(rawTables[selectedBranch] ?? [])];

                const table = allTables.find((table) => table.tableId === selectedTableId);

                if (table) {
                    setTable(table);
                }
            }
        } else {
            setTable(undefined);
        }
    }, [cachedTables, rawTables, selectedTableId]);

    return table;
};

export default useTable;
