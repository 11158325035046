import { commonTools } from '../../../../../../../layers';
import { TObjNamingMode } from '../../../../../../../layers.interfaces';
import { IObjects, IZone, IItemGroupDetailsList } from '../../../../../zones.interfaces';
import React, { useEffect, useState } from 'react';

export const useGroupForZones = (list: IObjects, objNamingMode: TObjNamingMode = 'markers') => {
    const [items, setItems] = useState<IZone[] | []>([]);
    const [groups, setGroups] = useState<IItemGroupDetailsList[] | []>([]);

    useEffect(() => {
        let tempGroup: IItemGroupDetailsList[] = [];
        let arrZones: IZone[] = [];
        let tempStartIndex: number = 0;

        list.forEach((item, index) => {
            let tempArrZones: IZone[] = [];
            tempStartIndex = arrZones.length;

            let groupFallbackText = item!.group_marker;

            switch (objNamingMode) {
                case 'markers':
                    groupFallbackText = item.group_marker;
                    break;
                case 'names':
                    groupFallbackText = item.group_name;
                    break;

                default:
                    groupFallbackText = item.group_marker;
                    break;
            }

            item.zones.forEach((itemZones) => {
                let zoneFallbackText = itemZones.zone_marker;

                switch (objNamingMode) {
                    case 'markers':
                        zoneFallbackText = itemZones.zone_marker;
                        break;
                    case 'names':
                        zoneFallbackText = itemZones.zone_name;
                        break;

                    default:
                        zoneFallbackText = itemZones.zone_marker;
                        break;
                }
                const value = {
                    ...itemZones,
                    labelText: commonTools.getObjectLabelByMode({
                        objNamingMode,
                        fallbackText: zoneFallbackText,
                    }),
                };
                tempArrZones.push(value);
                arrZones.push(value);
            });

            tempGroup.push({
                count: tempArrZones.length,
                key: item.front_id!,
                level: 0,
                name: commonTools.getObjectLabelByMode({
                    objNamingMode,
                    fallbackText: groupFallbackText,
                }),
                startIndex: tempStartIndex,
                front_id: item.front_id!,
            });
        });

        setItems(arrZones);
        setGroups(tempGroup);
    }, [list, objNamingMode]);

    return { items, groups, objNamingMode };
};
