const tools = require('./tools');
const cloneDeep = require('lodash/cloneDeep');
const THREE = require('three');

// function defineExternalTraffic(escalator, locationFloors) {
//     function getMaxOfArray(numArray) {
//         return Math.max.apply(null, numArray);
//     }

//     function getMinOfArray(numArray) {
//         return Math.min.apply(null, numArray);
//     }

//     const maxLocFloor = getMaxOfArray(locationFloors);
//     const minLocFloor = getMinOfArray(locationFloors);

//     if (
//         escalator.floors.length === 1 &&
//         ((escalator.floors.indexOf(minLocFloor) !== -1 && escalator.direction === 'down') ||
//             (escalator.floors.indexOf(maxLocFloor) !== -1 && escalator.direction === 'up'))
//     ) {
//         return true;
//     } else {
//         return false;
//     }
// }

// const getDistance = function (point1, point2) {
//     const xs = Math.pow(point2[0] - point1[0], 2);
//     const ys = Math.pow(point2[1] - point2[1], 2);
//     return Math.sqrt(xs + ys);
// };

const getDirection = (mountingPoint, passPointCoords, arrowsCoords) => {
    // eslint-disable-next-line no-undef
    const passPointVector = new THREE.Vector3(passPointCoords[0], passPointCoords[1], 0).sub(
        new THREE.Vector3(mountingPoint[0], mountingPoint[1], 0),
    );

    const angles = arrowsCoords.map((item) => {
        const arrowVector = new THREE.Vector3(item.arrowStartPoint[0], item.arrowStartPoint[1], 0).sub(
            new THREE.Vector3(item.arrowEndPoint[0], item.arrowEndPoint[1], 0),
        );
        const angle = (arrowVector.angleTo(passPointVector) * 180) / Math.PI;
        return angle;
    });

    const averageAngle =
        angles.reduce((acc, value) => {
            return acc + value;
        }, 0) / angles.length;

    // const startDistSum = arrowsCoords.reduce((acc, arrow) => {
    //     return acc + getDistance(mountingPoint, arrow.arrowStartPoint);
    // }, 0);

    // const endDistSum = arrowsCoords.reduce((acc, arrow) => {
    //     return acc + getDistance(mountingPoint, arrow.arrowEndPoint);
    // }, 0);

    // return endDistSum >= startDistSum ? 'OUT' : 'IN';

    return averageAngle <= 90 ? 'IN' : 'OUT';
};

function get_intersections({ escalatorsObjects, passWaysObjects, planScale, allTheFloors }) {
    var intersections = [];

    const extendedPassWays = passWaysObjects.map((passWay) => {
        return Object.assign(cloneDeep(passWay), { arrowsCoords: tools.getArrowCoords({ passWay, planScale }) });
    });

    escalatorsObjects.forEach((esc) => {
        const mountingPoint = esc.in_out === 'in' ? esc.line.coordinates[1] : esc.line.coordinates[0];

        // console.log('>>>', mountingPoint);
        // const externalTraffic = defineExternalTraffic(cloneDeep(esc), cloneDeep(allTheFloors));

        if (esc.passPoints && Object.keys(esc.passPoints).length) {
            extendedPassWays.forEach((passWay) => {
                const info = { direction: 'OUT' };
                Object.keys(esc.passPoints).forEach((key) => {
                    const coords = esc.passPoints[key].geometry.coordinates;
                    Object.assign(info, {
                        lineName: esc.passPoints[key].lineName,
                        escalatorMarker: esc.marker,
                        escalatorName: esc.name,
                        externalTraffic: esc.external_traffic || false, // для обратной совместимости изза добавления поля external_traffic
                        passWayMarker: passWay.marker,
                        passWayName: passWay.name,
                    });
                    const passLineCoords = passWay.passLine.coordinates;
                    if (tools.isPointInsidePassWay(coords, passLineCoords, (planScale * passWay.passMargin) / 2)) {
                        const { arrowsCoords } = passWay;
                        info.direction = getDirection(mountingPoint, coords, arrowsCoords);
                        intersections.push({ coords, info });
                    }
                });
            });
        }
    });

    // console.dir({ ipointsObjects, passWaysObjects, planScale, intersections }, { depth: null });
    // console.log('>>', intersections);
    return intersections;
}

module.exports = { get_intersections };
