import { KonvaEventObject } from 'konva/lib/Node';
import { IGetPointerCoordsArgs, IPlan } from './commonInterfaces';
import Flatten from '@flatten-js/core';
import { DateTime } from 'luxon';
import { IExtendedPlan } from '../components/Layers/layers.interfaces';
import { sizes } from '../constants/sizes';
import { tabooChars } from '../constants/tabooChars';
import { geoMatrixConverner } from './geoMatrixConverter';
import { cloneDeep } from 'lodash';
import { TObjNamingMode } from '../components/Settings/Settings.interfaces';
import { IItemCoords } from '../components/Layers/components/Places/places.interfaces';
var polygonsIntersect = require('polygons-intersect');

const { Point, Segment, Polygon } = Flatten;

/**
 * Функция возвращает координату ближайшего к позиции курсора пересечения линии виртуальной сетки с осью на плане.
 * @param {Array<number>} currentCoords Координаты курсора.
 * @param {number} size Линейный размер плана в пикселях.
 * @param {number} planScale Масштаб плана в пикселях на метр.
 * @param {number} gridStep Шаг сетки в метрах.
 */
const getGrigPoint = (snapToGrid: number, planScale: number) => (currentCoord: number, size: number) => {
    const arr = [];
    let currCoord = 0;

    while (currCoord <= size) {
        arr.push(currCoord);
        currCoord += snapToGrid * planScale;
    }
    const closestLeft = Math.max(...arr.filter((v) => v < currentCoord));
    const closestRight = Math.min(...arr.filter((v) => v > currentCoord));
    return Math.abs(currentCoord - closestLeft) >= Math.abs(currentCoord - closestRight) ? closestRight : closestLeft;
};

/**
 * Взвращает координаты курсора
 * @param {KonvaEventObject<MouseEvent>} e Событие мыши.
 */
const getCurrentCoords = (e: KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const scale = stage?.scaleX();
    const p = stage?.getPointerPosition();
    if (stage && p && scale) {
        const x = stage.attrs.x ? p.x - stage.attrs.x : p.x;
        const y = stage.attrs.y ? p.y - stage.attrs.y : p.y;
        return [x / scale, y / scale];
    }
};

export const commonTools = {
    translit: (str: string): string => {
        var sp = '_';
        var text = str.toLowerCase();
        let transl = {
            '\u0430': 'a',
            '\u0431': 'b',
            '\u0432': 'v',
            '\u0433': 'g',
            '\u0434': 'd',
            '\u0435': 'e',
            '\u0451': 'e',
            '\u0436': 'zh',
            '\u0437': 'z',
            '\u0438': 'i',
            '\u0439': 'j',
            '\u043a': 'k',
            '\u043b': 'l',
            '\u043c': 'm',
            '\u043d': 'n',
            '\u043e': 'o',
            '\u043f': 'p',
            '\u0440': 'r',
            '\u0441': 's',
            '\u0442': 't',
            '\u0443': 'u',
            '\u0444': 'f',
            '\u0445': 'h',
            '\u0446': 'c',
            '\u0447': 'ch',
            '\u0448': 'sh',
            '\u0449': 'shch',
            '\u044a': "'",
            '\u044b': 'y',
            '\u044c': '',
            '\u044d': 'e',
            '\u044e': 'yu',
            '\u044f': 'ya',
            '\u00AB': '_',
            '\u00BB': '_', // «»
            ' ': sp,
            _: sp,
            '`': sp,
            '~': sp,
            '!': sp,
            '@': sp,
            '#': sp,
            $: sp,
            '%': sp,
            '^': sp,
            '&': sp,
            '*': sp,
            '(': sp,
            ')': sp,
            '-': sp,
            '=': sp,
            '+': sp,
            '[': sp,
            ']': sp,
            '\\': sp,
            '|': sp,
            '/': sp,
            '.': sp,
            ',': sp,
            '{': sp,
            '}': sp,
            "'": sp,
            '"': sp,
            ';': sp,
            ':': sp,
            '?': sp,
            '<': sp,
            '>': sp,
            '№': sp,
        };
        var result = '';
        var curent_sim = '';
        for (let i = 0; i < text.length; i++) {
            const key = text[i] as keyof typeof transl;
            const elem = transl[key];
            if (elem !== undefined) {
                if (curent_sim !== elem || curent_sim !== sp) {
                    result += elem;
                    curent_sim = elem;
                }
            } else {
                result += text[i];
                curent_sim = text[i];
            }
        }
        result = result.replace(/^_/, '').replace(/_$/, ''); // trim
        return result;
    },

    modifyStringValues: (obj: unknown, keys: string[], callback: (x: string) => void): unknown => {
        /** Если переданная структура не является объектом, то возвращаем ее */
        if (typeof obj !== 'object' || obj === null) {
            return obj;
        }

        if (Array.isArray(obj)) {
            /** Если передан массив, то рекурсивно вызываем функцию для каждого элемента */
            return obj.map((item) => commonTools.modifyStringValues(item, keys, callback));
        }

        const modifiedObj: Record<string, unknown> = {};

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = (obj as { [x: string]: unknown })[key];

                if (keys.includes(key) && typeof value === 'string') {
                    modifiedObj[key] = callback(value);
                } else {
                    /** Если значение по ключу не является строкой, то рекурсивно вызываем функцию для значения ключа */
                    modifiedObj[key] = commonTools.modifyStringValues(value, keys, callback);
                }
            }
        }

        return modifiedObj;
    },

    validateCrossFloorMarkersAndNames: (
        objects: { marker: string; name: string; front_id: string }[],
        anotherLocationNames: { markers: string[]; names: string[] },
    ) => {
        const currentMarkers = objects.map((item) => `${item.marker}✚${item.front_id}`);
        const currentNames = objects.map((item) => `${item.name}✚${item.front_id}`);

        let isValid = true;
        const names: string[] = [];

        currentMarkers.forEach((marker) => {
            if (
                currentMarkers.some((mm) => {
                    return marker.split('✚')[0] === mm.split('✚')[0] && marker.split('✚')[1] !== mm.split('✚')[1];
                })
            ) {
                names.push(marker);
                isValid = false;
            }

            anotherLocationNames.markers.forEach((otherMarker) => {
                if (
                    marker.split('✚')[0] === otherMarker.split('✚')[0] &&
                    marker.split('✚')[1] !== otherMarker.split('✚')[1]
                ) {
                    names.push(marker);
                    isValid = false;
                }
            });
        });

        currentNames.forEach((name) => {
            if (
                currentNames.some((nn) => {
                    return name.split('✚')[0] === nn.split('✚')[0] && name.split('✚')[1] !== nn.split('✚')[1];
                })
            ) {
                names.push(name);
                isValid = false;
            }
            anotherLocationNames.names.forEach((otherName) => {
                if (name.split('✚')[0] === otherName.split('✚')[0] && name.split('✚')[1] !== otherName.split('✚')[1]) {
                    isValid = false;
                    names.push(name);
                }
            });
        });

        return { valid: isValid, names };
    },

    onFormatDate: (date?: Date): string => {
        const result = !date ? '' : DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
        return result;
    },

    isToday: (someDate: Date) => {
        const today = new Date();
        return (
            someDate.getDate() === today.getDate() &&
            someDate.getMonth() === today.getMonth() &&
            someDate.getFullYear() === today.getFullYear()
        );
    },

    getDateFromString: ({ value, newValue }: { value?: Date | null; newValue: string }): Date => {
        const previousValue = value || new Date();
        const newValueParts = (newValue || '').trim().split('-');
        const day =
            newValueParts.length > 0
                ? Math.max(1, Math.min(31, parseInt(newValueParts[2], 10)))
                : previousValue.getDate();
        const month =
            newValueParts.length > 1
                ? Math.max(1, Math.min(12, parseInt(newValueParts[1], 10))) - 1
                : previousValue.getMonth();
        let year = newValueParts.length > 2 ? parseInt(newValueParts[0], 10) : previousValue.getFullYear();
        if (year < 100) {
            year += previousValue.getFullYear() - (previousValue.getFullYear() % 100);
        }
        return new Date(year, month, day);
    },

    /**
     * Функция транслитерации
     */
    transliter: (str: string) => {
        const ru: { [x: string]: string } = {
                а: 'a',
                б: 'b',
                в: 'v',
                г: 'g',
                д: 'd',
                е: 'e',
                ё: 'e',
                ж: 'j',
                з: 'z',
                и: 'i',
                к: 'k',
                л: 'l',
                м: 'm',
                н: 'n',
                о: 'o',
                п: 'p',
                р: 'r',
                с: 's',
                т: 't',
                у: 'u',
                ф: 'f',
                х: 'h',
                ц: 'c',
                ч: 'ch',
                ш: 'sh',
                щ: 'shch',
                ы: 'y',
                э: 'e',
                ю: 'u',
                я: 'ya',
            },
            n_str = [];

        str = str.replace(/[ъь]+/g, '').replace(/й/g, 'i');

        for (var i = 0; i < str.length; ++i) {
            n_str.push(
                ru[str[i]] ||
                    (ru[str[i].toLowerCase()] === undefined && str[i]) ||
                    ru[str[i].toLowerCase()].toUpperCase(),
            );
        }

        return n_str.join('');
    },

    /**
     * Функция возвращает только буквы и цифры из входного параметра
     */
    onlyNumbersAndLetters: (value: string) => {
        if (value) return value.replace(/[^a-zA-Z0-9]/g, '');
    },

    isObject: (val: any) => {
        if (val === null) {
            return false;
        }
        return typeof val === 'function' || typeof val === 'object';
    },
    extractGeometry: (arr: { name?: string; marker?: string }[]) => {
        if (!arr) return [];
        return arr.map((item: { name?: string; marker?: string }) => {
            const cloneItem = cloneDeep(item);
            delete cloneItem.name;
            delete cloneItem.marker;
            return cloneItem;
        });
    },

    addShift: (arr: number[], shift: number[]) => {
        return [arr[0] + shift[0], arr[1] + shift[1]];
    },

    /**
     * Рекурсивно обходит структуру, и если есть геоджейсон, применяе к нему сдвиг координат
     * @param {any} instance Структура любого типа
     * @param {number[]} shift сдвиг по осям
     */
    shiftCoords: (instance: any, shift: number[]): any => {
        if (Array.isArray(instance)) {
            instance.forEach((item) => {
                commonTools.shiftCoords(item, shift);
            });
        } else if (typeof instance === 'object' && instance !== null && instance.hasOwnProperty('type')) {
            if (instance.type === 'Polygon' || instance.type === 'LineString') {
                instance.coordinates = instance.coordinates.map((item: number[]) => commonTools.addShift(item, shift));
            } else if (instance.type === 'Point') {
                instance.coordinates = commonTools.addShift(instance.coordinates, shift);
            } else {
                Object.keys(instance).forEach((k) => {
                    commonTools.shiftCoords(instance[k], shift);
                });
            }
        } else if (typeof instance === 'object' && instance !== null) {
            Object.keys(instance).forEach((k) => {
                commonTools.shiftCoords(instance[k], shift);
            });
        } else {
            return;
        }
    },

    applyTransform: (arr: number[], transformMatrix: number[][]) => {
        return geoMatrixConverner(arr[0], arr[1], transformMatrix);
    },

    /**
     * Рекурсивно обходит структуру, и если есть геоджейсон, применяе к нему сдвиг координат
     * @param {any} instance Структура любого типа
     * @param {number[][]} transformMatrix
     */
    transformCoords: (instance: any, transformMatrix: number[][]): any => {
        if (Array.isArray(instance)) {
            instance.forEach((item) => {
                commonTools.transformCoords(item, transformMatrix);
            });
        } else if (typeof instance === 'object' && instance !== null && instance.hasOwnProperty('type')) {
            if (instance.type === 'Polygon' || instance.type === 'LineString') {
                instance.coordinates = instance.coordinates.map((item: number[]) =>
                    commonTools.applyTransform(item, transformMatrix),
                );
            } else if (instance.type === 'Point') {
                instance.coordinates = commonTools.applyTransform(instance.coordinates, transformMatrix);
            } else {
                Object.keys(instance).forEach((k) => {
                    commonTools.transformCoords(instance[k], transformMatrix);
                });
            }
        } else if (typeof instance === 'object' && instance !== null) {
            Object.keys(instance).forEach((k) => {
                commonTools.transformCoords(instance[k], transformMatrix);
            });
        } else {
            return;
        }
    },

    /**
     * Функция для нахождения расстояния от точки до прямой
     * @param {array} initPoint точка от которой нужно найти расстояние
     * @param {array} initSegment прямая до которой нужно найти расстояние
     * @returns расстояние от точки до прямой
     */
    distanceFinder: (initPoint: number[], initSegment: number[][]) => {
        const p = new Point(initPoint[0], initPoint[1]);
        const s = new Segment(
            new Point(initSegment[0][0], initSegment[0][1]),
            new Point(initSegment[1][0], initSegment[1][1]),
        );
        return p.distanceTo(s)[0];
    },

    /**
     * Функция для посиска расстояния между точкой и полилайном
     * @param {array} beamPoint массив с координатами точки
     * @param {array} polyLine массив точек для прохода
     * @param {array} polygonWidth ширина прохода
     * @returns расстояние от точки до полилайна
     */
    isPointInsidePassWay: (beamPoint: number[], polyLine: number[][], polygonWidth: number) => {
        let flag = false;
        let distance = null;
        polyLine.forEach((element, i, arr) => {
            if (i !== arr.length - 1 && !flag) {
                const res = commonTools.distanceFinder(beamPoint, [element, arr[i + 1]]);
                if (res <= polygonWidth) {
                    distance = res;
                    flag = true;
                }
            }
        });

        return distance;
    },

    /**
     * Проверка строки на запрещенные символы
     */
    hasTabooChars: (str: string | undefined) => {
        if (str === undefined) return true;
        let result = false;
        tabooChars.forEach((char) => {
            if (str.includes(char)) {
                result = true;
            }
        });
        return result;
    },

    /**
     * Проверка строки на запрещенные символы
     */
    matchChars: (str: string | number | undefined | null): string => {
        const arr = str ? String(str).match(/[\/\.\+\(\)\[\]\{\}a-zA-Z0-9:_ -]/g) : [''];
        if (arr && arr[0] === ' ') {
            arr?.shift();
        }
        return arr ? arr.join('') : '';
    },

    /**
     * Проверка строки на цифры и число знаков после запятой
     */
    matchNumbers: (str: string | number | undefined | null, digits: number): number | null => {
        // if (str === '0' || str === 0) return 0;
        if (!str ) return null;
        const f = (x: string | number | undefined | null) =>
            x?.toString().includes('.') ? x.toString()?.split('.')?.pop()?.length || 0 : 0;
        if (isFinite(Number(str)) && f(str) <= digits) {
            return Number(str);
        } else if (isFinite(Number(str)) && f(str) > digits) {
            return Number(Number(str).toFixed(digits));
        } else {
            return null;
        }
    },

    /**
     * Валидирует массив на повторения
     * @param arr
     */
    isNamesValid: (arr: Array<string>) => {
        let valid = true;
        let names: string[] = [];
        commonTools.calcRepeat(arr).forEach((item) => {
            if (Number(item[1]) > 1) {
                names.push(String(item[0]));
                valid = false;
            }
        });
        return { valid, names };
    },

    /**
     * Находит повторяющиеся строки в массиве строк
     * @param arr
     */
    calcRepeat: (arr: Array<string>) => {
        const counts: { [x: string]: number } = {};
        const res: Array<Array<string | number>> = [];
        for (const i in arr) {
            counts[arr[i]] = (counts[arr[i]] || 0) + 1;
        }
        Object.keys(counts)
            .sort((a, b) => counts[b] - counts[a])
            .forEach((el, idx, arr) => {
                res.push([el, counts[el]]);
            });

        return res;
    },

    /**
     * Находит размер в зависимости от масштаба
     */
    scaleTransformFoo: (size: number, scale: number, factor: number = 0.5) => {
        return size / scale ** factor;
    },

    findPolygonCenter: (coords: Array<number[]>): { x: number; y: number } | null => {
        if (coords.length === 0) return null;
        const coordsCopy = cloneDeep(coords);
        const first = coordsCopy[0];
        const last = coordsCopy[coordsCopy.length - 1];
        if (first[0] !== last[0] || first[1] !== last[1]) coordsCopy.push(first);
        let twicearea = 0;
        let x = 0;
        let y = 0;
        const nPts = coordsCopy.length;
        let p1;
        let p2;
        let f;
        for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
            p1 = coordsCopy[i];
            p2 = coordsCopy[j];
            f = p1[0] * p2[1] - p2[0] * p1[1];
            twicearea += f;
            x += (p1[0] + p2[0]) * f;
            y += (p1[1] + p2[1]) * f;
        }
        f = twicearea * 3;
        return { x: x / f, y: y / f };
    },

    /**
     * Создает статус жля точки на полигоне, входит ли она в границу после клика по границам полигона
     * @param point1
     * @param point2
     * @param anchorCoord
     */
    between: (point1: number[], point2: number[], anchorCoord: number[], threshold: number = 100) => {
        const dxc = anchorCoord[0] - point1[0];
        const dyc = anchorCoord[1] - point1[1];
        const dxl = point2[0] - point1[0];
        const dyl = point2[1] - point1[1];
        const cross = dxc * dyl - dyc * dxl;
        const len = Math.sqrt(dxl * dxl + dyl * dyl);
        let result = false;
        if (Math.abs(dxl) >= Math.abs(dyl))
            result =
                dxl > 0
                    ? point1[0] <= anchorCoord[0] && anchorCoord[0] <= point2[0]
                    : point2[0] <= anchorCoord[0] && anchorCoord[0] <= point1[0];
        else
            result =
                dyl > 0
                    ? point1[1] <= anchorCoord[1] && anchorCoord[1] <= point2[1]
                    : point2[1] <= anchorCoord[1] && anchorCoord[1] <= point1[1];

        return result && Math.abs(cross) < len * 4;
        // return result;
    },

    generateNameNumber: (arr: Array<object> = [], word: string, field: string = 'name') => {
        if (!arr || arr.length === 0) {
            return `${word}1`;
        } else {
            const nameNumbers = arr
                .filter((item: { [key: string]: any }) => item[field].includes(word))
                .map((item: { [key: string]: any }) => {
                    return Number(item[field].match(/-?\d+\.?\d*$/));
                });
            const maxNumber = nameNumbers.length !== 0 ? Math.max(...nameNumbers) : 0;
            return `${word}${maxNumber + 1}`;
        }
    },

    /**
     * Функция возвращает координаты ближайшего к позиции курсора пересечения линий фиртуальной сетки на плане.
     * @param {KonvaEventObject<MouseEvent>} e Событие мыши.
     * @param {number} planHeight Высота плана в пикселях.
     * @param {number} planWidth Ширина плана в пикселях.
     * @param {number} planScale Масштаб плана в пикселях на метр.
     * @param {number} gridStep Шаг сетки в метрах.
     * @param {boolean} snapToGrid Шаг сетки в метрах.
     */
    getPointerCoords: ({ e, currentPlanData, snapToGrid = false }: IGetPointerCoordsArgs) => {
        const currentCoords = getCurrentCoords(e) as Array<number>;
        if (snapToGrid && !isNaN(Number(snapToGrid))) {
            const {
                planHeight = 2000,
                planWidth = 2000,
                planScale = 1,
                imageOffset = [0, 0],
            } = currentPlanData as IPlan;
            const getPoint = getGrigPoint(snapToGrid as number, planScale);
            const pointX = getPoint(
                currentCoords[0],
                planWidth + imageOffset[0] + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND,
            );
            const pointY = getPoint(
                currentCoords[1],
                planHeight + imageOffset[0] + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND,
            );
            return [pointX, pointY];
        }
        return currentCoords;
    },

    getNextMarkerNumber: (arr: Array<string> | null | undefined, word: string) => {
        if (!arr || arr.length === 0) {
            return `1`;
        }
        const nameNumbers = arr
            ?.filter((item) => item?.includes(word))
            ?.map((item) => Number(item?.match(/-?\d+\.?\d*$/)));
        const maxNumber = nameNumbers.length !== 0 ? Math.max(...nameNumbers) : 0;
        return `${maxNumber + 1}`;
    },

    copyAndSort: <T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] => {
        const key = columnKey as keyof T;
        return items.slice(0).sort((a: T, b: T) => {
            const one = a[key] ? a[key] : '0';
            const two = b[key] ? b[key] : '0';
            return (isSortedDescending ? one < two : one > two) ? 1 : -1;
        });
    },

    generateId: (n = 8) => {
        const getRandomInt = (min: number, max: number) => {
            min = Math.ceil(min);
            max = Math.floor(max);
            return Math.floor(Math.random() * (max - min)) + min;
        };
        const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
        return new Array(n)
            .fill(1)
            .map(() => chars[getRandomInt(0, chars.length)])
            .join('');
    },
    radianToDegrees: (radians: number | string | undefined): number | undefined => {
        return radians !== undefined ? (Number(radians) * 180) / Math.PI : undefined;
    },
    degreesToRadians: (degrees: number | string | undefined): number | undefined => {
        return degrees !== undefined ? (Number(degrees) * Math.PI) / 180 : undefined;
    },

    /**
     * Нахождение позиции для маркера.
     * @param coords Координаты узлов прохода
     * @returns Объект с координатами для размещения маркера.
     */
    findLabelCoords: (coords: Array<number[]>): { x: number; y: number } => {
        if (coords.length % 2 === 0) {
            const first = coords[coords.length / 2 - 1];
            const second = coords[coords.length / 2];
            return { x: (first[0] + second[0]) / 2, y: (first[1] + second[1]) / 2 };
        }
        const center = coords[Math.floor(coords.length / 2)];
        return { x: center[0], y: center[1] };
    },

    /**
     *   Создание новыых координат полигона по координатам курсора
     *   @param coordsShape Координаты полигона coordsCursor Координаты курсора настоящие oldCoordsCursor Координаты курсора предидущие
     *  @returns Массив массивов с новыми координатами полигона.
     */
    processCoordsForMoveShape: (
        coordsShape: Array<number[]>,
        coordsCursor: number[],
        oldCoordsCursor: number[],
    ): number[][] => {
        let deltaCoords: number[] = [coordsCursor[0] - oldCoordsCursor[0], coordsCursor[1] - oldCoordsCursor[1]];
        let newCoordsShape: number[][] = [];
        coordsShape.forEach((item, i) => {
            newCoordsShape.push([item[0] + deltaCoords[0], item[1] + deltaCoords[1]]);
        });
        return newCoordsShape;
    },

    // processCoordsForMovePoints: (
    //     coordsShape: Array<number[]>,
    //     coordsCursor: number[],
    //     oldCoordsCursor: number[],
    // ): number[][] => {
    //     let deltaCoords: number[] = [coordsCursor[0] - oldCoordsCursor[0], coordsCursor[1] - oldCoordsCursor[1]];
    //     let newCoordsShape: number[][] = [];
    //     coordsShape.forEach((item, i) => {
    //         newCoordsShape.push([item[0] + deltaCoords[0], item[1] + deltaCoords[1]]);
    //     });
    //     return newCoordsShape;
    // },
    /**
     * Функция для получения лейбла объекта в списке.
     * @param objNamingMode Режим отображения
     * @param object Объект у которого нужно выбрать поле для отображения
     * @param fallbackText Текст, который отобразить, если в объекте нет подходящих свойств
     */
    getObjectLabelByMode: ({
        objNamingMode = 'markers',
        object,
        fallbackText,
    }: {
        objNamingMode?: TObjNamingMode;
        object?: { marker?: string; name?: string };
        fallbackText?: string;
    }): string => {
        const defaultText = object?.marker || fallbackText || '';

        switch (objNamingMode) {
            case 'markers':
                return defaultText;
            case 'names':
                return object?.name || defaultText;

            default:
                return defaultText;
        }
    },
    getIntersect: (data: { objects: IItemCoords[][]; object: IItemCoords[] }) => {
        const { objects, object } = data;
        let intersectStatus = false;
        objects.forEach((item, i) => {
            // console.log('!@!@!@!', item);
            if (polygonsIntersect(item, object).length > 0) {
                intersectStatus = true;
            }
        });

        return intersectStatus;
    },
    /**
     * функция для преобразования любой сущности в читаемую строку
     */
    getReadableStringRecursive(val: unknown): string {
        if (Array.isArray(val)) {
            return val.reduce((acc, item, index) => {
                return acc + (index !== 0 ? '-------\n' : '') + this.getReadableStringRecursive(item);
            }, '');
        } else if (typeof val === 'object' && val !== null) {
            return Object.entries(val).reduce((acc, entry) => {
                const [key, value] = entry;
                const formattedValue = typeof value !== 'string' ? this.getReadableStringRecursive(value) : value;
                return acc + `${key}: ${formattedValue}\n`;
            }, '');
        } else {
            return JSON.stringify(val) + '\n';
        }
    },
};
