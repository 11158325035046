import {IGroupZones, IObjects} from "../zones.interfaces";

const compareNames = (objects: IObjects, itemSelect: IGroupZones, anotherLocations: Array<object>, groupFrontId: string) => {

    let marker: string, name: string;
    objects.forEach((itemGroup, indexGroup) => {
        if (itemGroup.front_id === groupFrontId) {
            marker = itemGroup.group_marker!;
            name = itemGroup.group_name!;
        }
    })

    let status: boolean = true;
    objects.forEach((itemGroup, indexGroup) => {
        if (itemGroup.front_id !== groupFrontId) {
           if (itemGroup.group_name === name || itemGroup.group_marker === marker) {
               status = false;
           }
        }
    })

    anotherLocations?.forEach((itemLocations: {data?: Array<IGroupZones>}, index) => {

        if (!itemLocations.data) return;
        itemLocations?.data.forEach((_item, _index) => {
            // console.log('>>>>>>>>>>>>>>>>>',  _item);
            if ((_item.group_marker === marker && _item.group_name !== name) ||
                (_item.group_marker !== marker && _item.group_name === name)) {
                status = false;
            }
        })
    })

    return status;
}


export default compareNames;