import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { NewVersionWrapper, Footer } from '../Versions.styles';
import { createNewVersion } from '../Versions.reducer';
import { DatePicker, PrimaryButton, Stack } from '@fluentui/react';
import { locationsReducerValues } from '../../Locations/Locations.reducer';
import useDayPickerStrings from '../../../constants/useDayPickerStrings';
import { commonTools } from '../../../tools/commonTools';
import { useAppDispatch } from '../../../store';

/**
 * Компонент для отображения модалки создания новой версии
 */
const CreateNewVersion = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const DAY_PICKER_STRINGS = useDayPickerStrings();
    const { activeLocation } = useSelector(locationsReducerValues);

    const [value, setValue] = React.useState<{ date_from: Date | undefined; date_to: Date | undefined }>({
        date_from: undefined,
        date_to: undefined,
    });

    const onParseDateFromString = React.useCallback(
        (mode: keyof typeof value) =>
            (newValue: string): Date => {
                const previousValue = value[mode] || new Date();
                return commonTools.getDateFromString({ value: previousValue, newValue });
            },
        [value],
    );

    const stackTokens = { childrenGap: 10 };

    const onCreateNewVersionClick = () => {
        if (!value.date_from) return;
        activeLocation?.id && value.date_from && dispatch(createNewVersion(value));
    };

    const onSelectDate = (mode: keyof typeof value) => (newValue: Date | undefined | null) => {
        setValue({ ...value, [mode]: newValue });
    };

    return (
        <NewVersionWrapper>
            <Stack horizontal tokens={stackTokens}>
                <DatePicker
                    label={t('From')}
                    allowTextInput
                    value={value.date_from}
                    strings={DAY_PICKER_STRINGS}
                    onSelectDate={onSelectDate('date_from')}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={onParseDateFromString('date_from')}
                />
                <DatePicker
                    label={t('To')}
                    allowTextInput
                    value={value.date_to}
                    strings={DAY_PICKER_STRINGS}
                    onSelectDate={onSelectDate('date_to')}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={onParseDateFromString('date_to')}
                />
            </Stack>
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={t('Create')} width={'100px'} onClick={onCreateNewVersionClick} />
                </Stack>
            </Footer>
        </NewVersionWrapper>
    );
};

export default CreateNewVersion;
