import * as React from 'react';
import { DefaultPalette, AnimationClassNames, AnimationStyles } from '@fluentui/react/lib/Styling';
import { IconButton, Stack, IStackItemStyles, IStackStyles } from '@fluentui/react';
import { useState } from 'react';
import { theme } from '../../../../../../constants/theme';
import ShowLabelIcon from '../../../../../Accordion/components/AccordionItem/components/ShowLabelIcon/ShowLabelIcon';
import { IAccordionProps } from './interfaces';
import { AccordionContainer, Container, Header, Title } from './styles';
import { useHotkeys } from 'react-hotkeys-hook';

const Accordion: React.FC<IAccordionProps> = ({ isInitialOpen = false, maxContentHeight = 400, ...props }) => {
    const [open, setSOpen] = useState(isInitialOpen);
    const [counter, setCounter] = useState(0);

    useHotkeys(
        'shift+t+l',
        () => {
            if (props.id && props.onShowLabelIconClick) {
                props.onShowLabelIconClick(props.id);
            }
        },
        [props.id, props.onShowLabelIconClick],
    );

    const accordionHeader: IStackItemStyles = {
        root: {
            background: 'rgb(253, 252, 251)',
            padding: 5,
            cursor: 'pointer',
        },
    };
    const accordionStyle: IStackStyles = {
        root: {
            marginBottom: 5,
            borderStyle: 'solid',
            borderWidth: 1,
            width: '100%',
            borderColor: DefaultPalette.neutralQuaternary,
            ...AnimationStyles.fadeIn200,
        },
    };
    const accordionContentStyle: IStackStyles = {
        root: {
            maxHeight: maxContentHeight,
            overflow: 'auto',
            height: open ? 'auto' : 0,
            padding: open ? 10 : 0,
        },
    };

    const showContent = (e: React.MouseEvent) => {
        setSOpen(() => !open);
    };

    const onShowLabelClick = (id: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        props.onShowLabelIconClick && props.onShowLabelIconClick(id);
    };

    return (
        <>
            <Stack horizontal={false} styles={accordionStyle}>
                <Stack.Item styles={accordionHeader}>
                    <Header onClick={(e) => showContent(e as React.MouseEvent)}>
                        <IconButton
                            onClick={(e) => showContent(e as React.MouseEvent)}
                            iconProps={{
                                iconName: open ? 'ChevronDown' : 'ChevronRight',
                            }}
                        />
                        <Title>{props.title}</Title>
                        {props.id && (
                            <Container onClick={onShowLabelClick(props.id)}>
                                <ShowLabelIcon
                                    hotKeyText={'Shift + T + L'}
                                    color={theme.palette.neutralPrimaryAlt}
                                    showLabels={props.showLabels}
                                />
                            </Container>
                        )}
                    </Header>
                </Stack.Item>
                {/* {open && ( */}

                <Stack.Item className={AnimationClassNames.fadeIn200} styles={accordionContentStyle}>
                    <AccordionContainer>{props.children}</AccordionContainer>
                </Stack.Item>
                {/* )} */}
            </Stack>
        </>
    );
};

export default Accordion;
