import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Group } from 'react-konva';

import {
    PCCReducerValues,
    storeNewObject,
    selectObject,
    toggleContextMenu,
    clearSelectedObjects,
    changeCoords,
    changeCoordsFullLayer
} from '../../reducer';
import Helper from './components/Helper/Helper';
import { commonTools } from '../../../../layers';
import { IHelperMouseMoveArgs } from './components/Helper/Helper.interfaces';
import PC_IPoint from './components/PC_IPoint/PC_IPoint';
import { cloneDeep } from 'lodash';
import { IObjects } from '../../interfaces';


/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        selectedObjectId,
        currentPlanData,
        contextMenu,
        generalSettings: { snapToGrid },
    } = useSelector(PCCReducerValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [canTransform, setCanTransform] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [currentPointerCoords, setCurrentPointerCoords] = useState<number[] | undefined>(undefined);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);
    const [
        isMouseDownMove,
        setIsMouseDownMove
    ] = useState<boolean>(false)

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw' && container) {
            container.style.cursor = 'crosshair';
        } else if (activeToolId === 'anchorsEdit' && container) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'move') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
            dispatch(clearSelectedObjects());
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId]);

    const updateCanTransform = (e: KonvaEventObject<MouseEvent>, can: boolean) => {
        props.stage.current.setAttr('draggable', !can);
        setCanTransform(() => can);
    };

    const onHelperClick = (e: KonvaEventObject<MouseEvent>) => {
        if (activeToolId === 'draw') {
            const newPoint = commonTools.getPointerCoords({ e, snapToGrid: false });
            newPoint &&
                currentPlanData &&
                dispatch(storeNewObject({ center: newPoint, planScale: currentPlanData.planScale }));
        } else if (contextMenu.show) {
            dispatch(toggleContextMenu({ show: false }));
        } else {
            dispatch(selectObject());
        }
    };

    const onObjectClick = (frontId?: string) => {
        if (activeToolId === 'anchorsEdit') {
            dispatch(selectObject(frontId));
        }
    };

    const onHelperMouseMove = ({ e }: IHelperMouseMoveArgs) => {
        setCurrentPointerCoords(commonTools.getPointerCoords({ e, snapToGrid: false }));
        if (isDrawNow === true) {
            const newPoint = commonTools.getPointerCoords({ e, snapToGrid: false });
            if (newPoint) {
                coords.pop();
                setCoords([...coords, newPoint]);
            }
        }
    };

    const onMouseDown = (id: string | undefined, e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        // setIdSelectedShape(id);

        if (activeToolId === 'move') {
            dispatch(selectObject(id));
        }
        else if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true)
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());
        }
        else if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false)
        }
    };

    const moveShape = (e: any) => {
        if (activeToolId === 'move') {
            if (selectedObjectId !== null) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[] = [];
                let _coordsLine: { [x: string]: number[] } = {};
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects?.find((item) => item.frontId === selectedObjectId);

                if (moveObj) {
                    Object.keys(moveObj.passPoints).forEach((item2, i2) => {
                        _coordsLine[item2] = commonTools.processCoordsForMoveShape(
                            [moveObj.passPoints[item2].geometry.coordinates],
                            coords,
                            coordsOldShape,
                        )[0];
                    });

                    _coordsShape = commonTools.processCoordsForMoveShape(
                        [moveObj.centerPoint.coordinates],
                        coords,
                        coordsOldShape,
                    )[0];
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: selectedObjectId, _coordsLine }));

                setCoordsOldShape(coords);
            }
        }
        else if (activeToolId === 'moveLayer') {
            if (isMouseDownMove && objects) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IObjects = []
                objects.forEach((item, i) => {
                    let objTemp: any = {}



                    Object.keys(item.passPoints).forEach((item2, i2) => {
                        objTemp[item2] = {geometry: null}
                        objTemp[item2].geometry = {
                            ...item.passPoints[item2].geometry,
                            coordinates: commonTools.processCoordsForMoveShape(
                                [item.passPoints[item2].geometry.coordinates],
                                coords,
                                coordsOldShape,
                            )[0]
                        };
                    });

                    let _obj: any = {
                        ...item,
                        centerPoint: {
                            ...item.centerPoint,
                            coordinates: commonTools.processCoordsForMoveShape(
                                [item.centerPoint.coordinates],
                                coords,
                                coordsOldShape,
                            )[0]
                        },
                        passPoints: {
                            ...objTemp
                        }
                    }


                    _objects.push(_obj)
                })


                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }

        }
    };

    const geometry = useMemo(() => {
        if (!objects || !currentPlanData?.planScale) return null;

        const arr = cloneDeep(objects)
            .sort((a, b) => {
                if (a.followedBy !== null) {
                    return -1;
                } else {
                    return 1;
                }
            })
            .sort((a, b) => {
                if (a.frontId === selectedObjectId) return 1;
                return -1;
            })
            .map((item) => {
                const { planScale } = currentPlanData;
                const { marker, frontId, centerPoint, passPoints, followedBy } = item;
                const selected = frontId === selectedObjectId;
                const args = {
                    centerPoint,
                    passPoints,
                    followedBy,
                    marker,
                    planScale,
                    stageScale: props.scale.stageScale,
                    key: frontId,
                    frontId,
                };
                return (
                    <PC_IPoint
                        {...args}
                        onClick={onObjectClick}
                        onHelperMouseMove={onHelperMouseMove}
                        selected={selected}
                        activeToolId={activeToolId}
                        onMouseDown={onMouseDown}
                    />
                );
            });

        return <Group>{arr}</Group>;
    }, [
        objects,
        currentPlanData,
        selectedObjectId,
        coords,
        props.scale.stageScale,
        onObjectClick,
        onHelperMouseMove,
        updateCanTransform,
        canTransform,
        activeToolId,
    ]);

    try {
        return (
            <>
                <Layer key={props.key} onMouseMove={moveShape} onMouseUp={onMouseUp} onMouseLeave={onMouseUp}>
                    <Helper
                        currentPlanData={currentPlanData}
                        isDrawNow={isDrawNow}
                        coords={coords}
                        onHelperClick={onHelperClick}
                        onHelperMouseMove={onHelperMouseMove}
                        updateCanTransform={updateCanTransform}
                    />
                    {geometry}
                </Layer>
            </>
        );
    } catch (error) {
        return null;
    }
};

export default Draw;
