import tools from './components/Tools';
import draw from './components/Draw';
import controls from './components/Controls';

export const measureCutLayer = {
    id: 'measureCut',
    tools,
    draw,
    controls,
};
