import React, { useMemo } from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

import { Circle } from 'react-konva';

import { updateAnchorCoords } from './core/updateAnchorCoords';

import { IAnchorsProps } from './Anchors.interfaces';

const Anchors = ({ activeToolId, updateCoords, coords, scale, ...props }: IAnchorsProps) => {
    const onUpdateGeometry = (pointIndex: number) => (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        const newCoords = updateAnchorCoords(pointIndex, coords, e);
        newCoords && updateCoords(newCoords);
    };

    const onCircleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
        const container = e?.target?.getStage()?.container();
        if (container) {
            container.style.cursor = 'pointer';
        }
    };

    const onCircleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
        const container = e?.target?.getStage()?.container();
        if (container) {
            container.style.cursor = 'default';
        }
    };

    const anchors = useMemo(() => {
        if (activeToolId !== 'anchorsEdit') return null;
        const result: Array<JSX.Element> = [];
        coords.forEach((point: number[], i: number) => {
            result.push(
                <Circle
                    id={`__:${i}`}
                    key={`++:${i}`}
                    draggable
                    x={point[0]}
                    y={point[1]}
                    radius={10 / scale.stageScale}
                    opacity={0.6}
                    stroke={'red'}
                    strokeWidth={2 / scale.stageScale}
                    onDragMove={onUpdateGeometry(i)}
                    onMouseEnter={onCircleMouseEnter}
                    onMouseLeave={onCircleMouseLeave}
                />
            );
        });

        return result;
    }, [activeToolId, coords, scale.stageScale]);

    return <React.Fragment>{anchors}</React.Fragment>;
};

export default Anchors;
