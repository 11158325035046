import React, { useMemo } from 'react';
import { Star, Layer, Group, Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { TupleLabel } from '../../../../../../tools/commonComponents';
import { commonTools } from '../../../../../Layers/layers';
import { ICrossObject } from '../../interfaces';
import { reducerValues, resetDependenciesForCurrentPlace, storeDependenciesForCurrentPlace } from '../../reducer';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const { tupleAlias, intersections, dependenciesForCurrentPlace } = useSelector(reducerValues);
    const { stageScale } = props.scale;
    const dispatch = useDispatch();

    const onMouseEnter = (obj: ICrossObject) => () => {
        dispatch(storeDependenciesForCurrentPlace({ placeMarker: obj.info.placeMarker as string }));
    };
    const onMouseLeave = () => {
        dispatch(resetDependenciesForCurrentPlace());
    };

    const backgroundGeometry = useMemo(() => {
        const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
        if (dependenciesForCurrentPlace) {
            return (
                <Group>
                    {dependenciesForCurrentPlace.zonesInformation.map((zone, i) => {
                        return (
                            <Star
                                key={`((zonestar${i}))`}
                                x={zone.coords[0]}
                                y={zone.coords[1]}
                                numPoints={8}
                                innerRadius={foo(5)}
                                outerRadius={foo(10)}
                                fill={'red'}
                            />
                        );
                    })}

                    {dependenciesForCurrentPlace.leashsInfornation.map((leash, i) => {
                        return (
                            <Line
                                key={`((lesh12${i}))`}
                                points={leash.coords}
                                stroke="#777"
                                strokeWidth={foo(1.5)}
                                dash={[1.5, 1.5]}
                            />
                        );
                    })}

                    {dependenciesForCurrentPlace.labelsInformation.map((label, i) => {
                        return (
                            <TupleLabel
                                key={`((tupleLable1${i}))`}
                                x={label.coords.x}
                                y={label.coords.y}
                                text={label.text}
                            />
                        );
                    })}
                </Group>
            );
        }

        return null;
    }, [dependenciesForCurrentPlace, stageScale]);

    const geometry = useMemo(() => {
        const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
        return intersections.map((obj, i) => {
            return (
                <Star
                    key={`((zonestar2${i}(())`}
                    x={obj.coords.placePolygonCenter[0]}
                    y={obj.coords.placePolygonCenter[1]}
                    numPoints={8}
                    innerRadius={foo(5)}
                    outerRadius={foo(10)}
                    fill={'cyan'}
                    onMouseEnter={onMouseEnter(obj)}
                    onMouseLeave={onMouseLeave}
                />
            );
        });
    }, [intersections, stageScale]);

    try {
        return (
            <Layer key={tupleAlias}>
                {geometry}
                {backgroundGeometry}
            </Layer>
        );
    } catch (_) {
        return null;
    }
};

export default Draw;
