import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { commonTools } from '../../../../tuples';

import { reducerValues, storeIntersections } from '../../reducer';

/**
 * Компонент контрольной панели
 */
const Controls = ({ ...props }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const liftsObjects = props?.dataFromLayers['lifts_layer']?.objects || [];
        const passWaysObjects = props?.dataFromLayers['pass_ways_layer']?.objects || [];
        props.visible &&
            dispatch(
                storeIntersections({ liftsObjects, passWaysObjects, planScale: props?.currentPlanData?.planScale }),
            );
    }, [
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['lifts_layer']?.objects)),
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['pass_ways_layer']?.objects)),
        props?.currentPlanData?.planScale,
        props.visible,
    ]);

    return <></>;
};

export default Controls;
