import { DatePicker, PrimaryButton, TextField } from '@fluentui/react';
import React from 'react';
import { useContext } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { GeneralContext } from '../../../../General.context';
import { generalReducerValues } from '../../../../General.reducer';
import { patchLocation } from '../../../Locations/Locations.reducer';
import { IEditLocationModalProps } from './EditLocationModal.interfaces';
import { ButtonWrapper, MainWrapper, textFiledStyles } from './EditLocationModal.styles';
import { useAppDispatch } from '../../../../store';
import { DateTime } from 'luxon';
import { commonTools } from '../../../Layers/layers';
import useDayPickerStrings from '../../../../constants/useDayPickerStrings';
import { IProjectLocation } from '../../../Locations/Locations.interfaces';

/**
 * Модальное окно для изменения локации
 */
const EditLocationModal: React.FC<IEditLocationModalProps> = ({ modalData, closeModal }) => {
    const {
        urls: { PROJECT_LOCATIONS_URL },
    } = useSelector(generalReducerValues);
    const DAY_PICKER_STRINGS = useDayPickerStrings();

    const { token } = useContext(GeneralContext);
    const [locationName, setLocationName] = useState('');
    const [dateFrom, setDateFrom] = useState<Date | undefined>(undefined);
    const [dateTo, setDateTo] = useState<Date | undefined>(undefined);
    const [locationNameError, setLocationNameError] = useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (modalData) {
            setLocationName(modalData.name);
            setDateFrom(DateTime.fromISO(modalData.date_from).toJSDate());
            if (modalData.date_to) {
                setDateTo(DateTime.fromISO(modalData.date_to).toJSDate());
            }
        }
    }, [modalData]);

    const locationNameHandler = useCallback((_: any, newValue?: string) => {
        setLocationName(newValue || '');
        setLocationNameError(false);
    }, []);

    const updateLocationHandler = () => {
        if (locationName) {
            const data: Partial<IProjectLocation> = {
                name: locationName,
                date_to: dateTo ? DateTime.fromJSDate(dateTo).toFormat('yyyy-MM-dd') : null,
                project_id: modalData?.project_id,
            };

            if (dateFrom) {
                data.date_from = DateTime.fromJSDate(dateFrom).toFormat('yyyy-MM-dd');
            }

            if (modalData?.id) {
                dispatch(patchLocation({ url: PROJECT_LOCATIONS_URL, token, locationId: String(modalData.id), data }));
                closeModal();
            }
        } else {
            if (!locationName) setLocationNameError(true);
        }
    };

    const onParseDateFromString = React.useCallback(
        (mode: string) =>
            (newValue: string): Date => {
                return commonTools.getDateFromString({ value: dateFrom, newValue });
            },
        [dateFrom],
    );

    return (
        <MainWrapper>
            <TextField
                value={locationName}
                onChange={locationNameHandler}
                label={t('Change location name')}
                // styles={textFiledStyles}
                errorMessage={locationNameError ? t('Required') : undefined}
            />
            <DatePicker
                allowTextInput
                label={t('Start date')}
                ariaLabel="Select a date. Input format is day slash month slash year."
                value={dateFrom}
                onSelectDate={setDateFrom as (date?: Date | undefined | null) => void}
                formatDate={commonTools.onFormatDate}
                parseDateFromString={onParseDateFromString('date_from')}
                strings={DAY_PICKER_STRINGS}
            />
            <DatePicker
                allowTextInput
                label={t('End date')}
                ariaLabel="Select a date. Input format is day slash month slash year."
                value={dateTo}
                onSelectDate={setDateTo as (date?: Date | undefined | null) => void}
                formatDate={commonTools.onFormatDate}
                parseDateFromString={onParseDateFromString('date_from')}
                strings={DAY_PICKER_STRINGS}
            />
            <ButtonWrapper>
                <PrimaryButton text={t('Edit')} onClick={updateLocationHandler} />
            </ButtonWrapper>
        </MainWrapper>
    );
};

export default EditLocationModal;
