import React, { useMemo } from 'react';
import { Star, Layer, Group, Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { TupleLabel } from '../../../../../../tools/commonComponents';
import { commonTools } from '../../../../../Layers/layers';
import { ICrossObject } from '../../interfaces';
import { reducerValues, resetDependenciesForCurrentStairs, storeDependenciesForCurrentStairs } from '../../reducer';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const { tupleAlias, intersections, dependenciesForCurrentStairs } = useSelector(reducerValues);
    const { stageScale } = props.scale;
    const dispatch = useDispatch();

    const onMouseEnter = (obj: ICrossObject) => () => {
        dispatch(storeDependenciesForCurrentStairs({ stairsMarker: obj.info.stairsMarker as string }));
    };
    const onMouseLeave = () => {
        dispatch(resetDependenciesForCurrentStairs());
    };

    const backgroundGeometry = useMemo(() => {
        const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
        if (dependenciesForCurrentStairs) {
            return (
                <Group>
                    {dependenciesForCurrentStairs.placeInformation.map((zone, i) => {
                        return (
                            <Star
                                key={`((55ddsdestar${i}))`}
                                x={zone.coords[0]}
                                y={zone.coords[1]}
                                numPoints={8}
                                innerRadius={foo(5)}
                                outerRadius={foo(10)}
                                fill={'red'}
                            />
                        );
                    })}

                    {dependenciesForCurrentStairs.leashsInfornation.map((leash, i) => {
                        return (
                            <Line
                                key={`(32ddh12${i}))`}
                                points={leash.coords}
                                stroke="#777"
                                strokeWidth={foo(1.5)}
                                dash={[1.5, 1.5]}
                            />
                        );
                    })}

                    {dependenciesForCurrentStairs.labelsInformation.map((label, i) => {
                        return (
                            <TupleLabel
                                key={`(345ldsfeLable1${i}))`}
                                x={label.coords.x}
                                y={label.coords.y}
                                text={label.text}
                            />
                        );
                    })}
                </Group>
            );
        }

        return null;
    }, [dependenciesForCurrentStairs, stageScale]);

    const geometry = useMemo(() => {
        const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
        return intersections.map((obj, i) => {
            return (
                <Star
                    key={`(32wstddffs88ar2${i}(())`}
                    x={obj.coords.stairsPolygonCenter[0]}
                    y={obj.coords.stairsPolygonCenter[1]}
                    numPoints={8}
                    innerRadius={foo(5)}
                    outerRadius={foo(10)}
                    fill={'cyan'}
                    onMouseEnter={onMouseEnter(obj)}
                    onMouseLeave={onMouseLeave}
                />
            );
        });
    }, [intersections, stageScale]);

    try {
        return (
            <Layer key={tupleAlias}>
                {geometry}
                {backgroundGeometry}
            </Layer>
        );
    } catch (_) {
        return null;
    }
};

export default Draw;
