import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../../store';

import { getRequest } from '../../../../tools/api';
import { IAccount, IReducerState, IProduct, IUser2Product, IUser2Location, IAppLink } from './Accounts.interfaces';
import axios from 'axios';
import { cloneDeep } from 'lodash';

const initialState: IReducerState = {
    products: [],
    user2product: [],
    user2location: [],
    onEdit: null,
    localProductIds: {},
    localLocationIds: {},
    accounts: [],
    extendedAccountsData: [],
    appLinkByUserId: {},
};

export const AccountsReducer = createSlice({
    name: 'AccountsReducer',
    initialState,
    reducers: {
        storeAccountssData: (
            state,
            action: PayloadAction<{
                products: IProduct[];
                user2product: IUser2Product[];
                user2location: IUser2Location[];
                accounts: IAccount[];
            }>,
        ) => {
            const { accounts, products, user2product, user2location } = action.payload;

            const extendedAccountsData = accounts.map((account) => {
                const productIds = user2product
                    ?.filter((item) => item.user_id === account.id)
                    .map((item) => item.product_id);
                const locationIds = user2location
                    .filter((item) => item.user_id === account.id)
                    .map((item) => item.project_location_id);
                return { ...account, productIds, locationIds };
            });

            state.accounts = accounts;
            state.products = products;
            state.user2product = user2product;
            state.user2location = user2location;
            state.extendedAccountsData = extendedAccountsData;
        },

        storeLocalProductIds: (state, action: PayloadAction<{ accountId: number; ids: number[] }>) => {
            const { accountId, ids } = action.payload;

            const { localProductIds } = cloneDeep(state);

            state.localProductIds = { ...localProductIds, [accountId]: ids };
        },

        storeOnEdit: (state, action: PayloadAction<{ type: 'products' | 'locations'; accountId: number } | null>) => {
            state.onEdit = action.payload;
        },

        /** Запись новой ссылки на кабинет */
        storeNewAppLinkByUserId: (state, action: PayloadAction<IAppLink>) => {
            state.appLinkByUserId[action.payload.userId] = action.payload;
        },
    },
});

export const { storeAccountssData, storeLocalProductIds, storeOnEdit, storeNewAppLinkByUserId } =
    AccountsReducer.actions;

export const fetchUsers =
    (projectId: string): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { USERS_URL, USER_2_PRODUCT_URL, PRODUCTS_URL, PROJECT_LOCATIONS_PERMISSIONS_URL },
        } = getState().GeneralReducer;

        getRequest({ url: `${USERS_URL}?project_id=${projectId}`, dispatch, allowSpinner: true, token }).then(
            (accounts: IAccount[]) => {
                const accIds = accounts?.map((item) => item.id);

                const locationsPermissionsRequest = getRequest({
                    url: `${PROJECT_LOCATIONS_PERMISSIONS_URL}?user_id=${accIds}`,
                    dispatch,
                    allowSpinner: true,
                    token,
                });

                const user2ProductRequest = getRequest({
                    url: `${USER_2_PRODUCT_URL}?user_id=${accIds}`,
                    dispatch,
                    allowSpinner: true,
                    token,
                });

                const productsRequest = getRequest({
                    url: `${PRODUCTS_URL}`,
                    dispatch,
                    allowSpinner: true,
                    token,
                });

                axios.all([locationsPermissionsRequest, user2ProductRequest, productsRequest]).then(
                    axios.spread((...res) => {
                        dispatch(
                            storeAccountssData({
                                accounts,
                                user2location: res[0],
                                user2product: res[1],
                                products: res[2],
                            }),
                        );
                    }),
                );
            },
        );
    };

export const getLink =
    (userId: number): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            appDomain,
            urls: { USER_TOKEN_URL },
        } = getState().GeneralReducer;

        getRequest({ url: `${USER_TOKEN_URL}${userId}/`, dispatch, allowSpinner: true, token }).then((res) => {
            if (res?.token && appDomain) {
                const appUrl = `https://${appDomain}?token=${res.token}&service_mode=true`;
                dispatch(storeNewAppLinkByUserId({ userId, link: appUrl }));
            }
        });
    };

export const accountsReducerValues = (state: RootState) => state.AccountsReducer;

export default AccountsReducer.reducer;
