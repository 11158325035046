import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

export const Text = styled.div`
    display: flex;
    flex-wrap: wrap;
    span {
        font-weight: 500;
        white-space: nowrap;
        flex-shrink: 0;
        align-self: flex-start;
    }

    div {
        font-size: 12px;
        color: rgb(32, 31, 30);
        text-align: left;
    }
`;
export const ColorBox = styled.div<{ status: 'full' | 'empty' | 'incorrect' }>`
    cursor: pointer;
    border-radius: 5px;
    background-color: ${(p) =>
        p.status === 'empty'
            ? theme.palette.neutralLighter
            : p.status === 'full'
            ? '#74E291'
            : '#FF8080'};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    div {
        font-size: 12px;
        color: rgb(32, 31, 30);
        text-align: left;
    }
`;
