// @ts-nocheck
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { Stage, Layer, Rect } from 'react-konva';

import { DrawPanelWrapper, FloatingPanel } from './DrawPanel.styles';
import UrlImage from './components/UrlImage/UrlImage';

import { GeneralContext } from '../../../../General.context';
import { KonvaEventObject } from 'konva/lib/Node';
import { handleWheelScale } from './core/handleWheelScale';
import { fitStage } from './core/fitStage';
import { EditorContext } from '../../Editor.context';
import { IDrawPanelProps } from './DrawPanel..interfaces';
import { ContextualMenu, TooltipHost } from '@fluentui/react';
import { ITuple } from '../../../Tuples/interfaces';
import FloorsButtons from './components/FloorsButtons/FloorsButtons';
import LeasingPlans from './components/LeasingPlans/LeasingPlans';
import ToggleBackground from './components/ToggleBackground/ToggleBackground';
import { IMonitoringLayer } from '../../../Monitoring/interfaces';
import { generalReducerValues } from '../../../../General.reducer';
import { useSelector } from 'react-redux';

/**
 * Компонента основного графического редактора.
 * Информация об объектах приходит из слоев в поле .draw
 * Активный в данный момент слой - всегда сверху (activeGeometry).
 * Остальные слои в (passiveGeometry).
 * Самый нижний - слой с планом (чертежом).
 * Вся логика работы с объектами - отвнтственность слоя.
 * Тут только отображение.
 */

const DrawPanel = ({
    image,
    layers,
    activeLayerId,
    planBlueprintScale,
    imageOffset,
    onHideContextualMenu,
}: IDrawPanelProps) => {
    const stageRef = useRef(null);
    const [stageDimensions, setStageDimensions] = useState({ width: 0, height: 0 });
    const { windowSize, sizes } = useContext(GeneralContext);
    const { showBackgroundPlanImage } = useSelector(generalReducerValues);

    const {
        visibleLayersIds,
        visibleTuplesIds,
        contextMenu,
        tuples,
        dataFromLayers,
        currentPlanData,
        monitoringLayers,
        selectedChapter,
        leftHandSplitPercentWidth,
    } = useContext(EditorContext);

    const [scale, set_scale] = useState({
        stageScale: 1,
        stageX: -sizes.GLOBAL_SHIFT,
        stageY: -sizes.GLOBAL_SHIFT,
    });

    useEffect(() => {
        let stageScale = fitStage(currentPlanData, stageDimensions.width);
        if (isNaN(stageScale) || stageScale === 0) stageScale = 1;
        const { imageOffset = [0, 0] } = currentPlanData;
        set_scale({
            stageScale,
            stageX: (-sizes.GLOBAL_SHIFT - imageOffset[0]) * stageScale,
            stageY: (-sizes.GLOBAL_SHIFT - imageOffset[1]) * stageScale,
        });
    }, [currentPlanData.planWidth, sizes.GLOBAL_SHIFT, stageDimensions.width]);

    useEffect(() => {
        if (windowSize) {
            let height = windowSize?.height - sizes.HEADER_HEIGHT;
            let width = (windowSize?.width * leftHandSplitPercentWidth) / 100;
            width >= 0 && height >= 0 && setStageDimensions({ width, height });
        }
    }, [
        sizes.CONTROL_PANEL_WIDTH,
        sizes.HEADER_HEIGHT,
        sizes.TOOLS_PANEL_WIDTH,
        windowSize,
        leftHandSplitPercentWidth,
    ]);

    const onStageWheel = (e: KonvaEventObject<WheelEvent>) => {
        e.evt.preventDefault();
        set_scale(handleWheelScale(e));
    };

    const onStageDblClick = (e: KonvaEventObject<MouseEvent>) => {
        if (!currentPlanData) return;
        e.evt.preventDefault();
        const { imageOffset = [0, 0] } = currentPlanData;
        let stageScale = fitStage(currentPlanData, stageDimensions.width, e);
        if (isNaN(stageScale)) stageScale = 1;
        set_scale({
            stageScale,
            stageX: (-sizes.GLOBAL_SHIFT - imageOffset[0]) * stageScale,
            stageY: (-sizes.GLOBAL_SHIFT - imageOffset[1]) * stageScale,
        });
    };

    const geometry = useMemo(() => {
        const activeGeometry = () => {
            const [activeLayer] = layers.filter(
                (item) => item.id === activeLayerId && visibleLayersIds.includes(item.id),
            );
            if (!activeLayer) return null;
            const Componenta = activeLayer.draw;
            const props = { scale, stage: stageRef, key: activeLayer.id, isLayerActive: true };
            return <Componenta {...props} />;
        };

        const passiveGeometry = () => {
            const passiveLayers = layers.filter(
                (item) => item.id !== activeLayerId && visibleLayersIds.includes(item.id),
            );
            if (!passiveLayers) return null;
            return passiveLayers.map((item) => {
                const Componenta = item.draw;
                const props = { scale, stage: stageRef, key: item.id, isLayerActive: false };
                return <Componenta {...props} />;
            });
        };

        const placesGeometry = () => {
            const [places] = layers.filter((item) => item.id === 'places_layer');
            if (!places) return null;
            const Componenta = places.draw;
            const props = { scale, stage: stageRef, key: 'places_layer', isLayerActive: false };
            return <Componenta {...props} />;
        };

        const tuplesGeometry = () => {
            return tuples
                .filter((item: ITuple) => visibleTuplesIds.includes(item.alias))
                .map((item: ITuple) => {
                    const Componenta = item.draw;
                    const props = {
                        scale,
                        stage: stageRef,
                        key: item.alias,
                        isLayerActive: false,
                        dataFromLayers,
                        currentPlanData,
                    };
                    return <Componenta {...props} />;
                });
        };

        const monitoringGeometry = () => {
            return monitoringLayers.map((item: IMonitoringLayer) => {
                const Componenta = item.draw;
                const props = {
                    scale,
                    stage: stageRef,
                    key: item.alias,
                    isLayerActive: false,
                    dataFromLayers,
                    currentPlanData,
                };
                return <Componenta {...props} />;
            });
        };

        switch (selectedChapter) {
            case 'tuples':
                return (
                    <>
                        {passiveGeometry()}
                        {activeGeometry()}
                        {tuplesGeometry()}
                    </>
                );
            case 'tenants':
                return <>{placesGeometry()}</>;
            case 'monitoring':
                return <>{monitoringGeometry()}</>;

            default:
                return (
                    <>
                        {passiveGeometry()}
                        {tuplesGeometry()}
                        {activeGeometry()}
                    </>
                );
        }
    }, [
        activeLayerId,
        currentPlanData,
        dataFromLayers,
        layers,
        scale,
        selectedChapter,
        tuples,
        visibleLayersIds,
        visibleTuplesIds,
    ]);

    return (
        <DrawPanelWrapper>
            {activeLayerId !== 'measureCut' && (
                <FloatingPanel>
                    <FloorsButtons />
                    <LeasingPlans />
                    <ToggleBackground />
                </FloatingPanel>
            )}
            {contextMenu?.menuItems && (
                <ContextualMenu
                    // styles={menuStyles}
                    items={contextMenu.menuItems}
                    hidden={!contextMenu.show}
                    target={contextMenu.cursor}
                    // onItemClick={onHideContextualMenu}
                    // onDismiss={onHideContextualMenu}
                />
            )}
            <Stage
                ref={stageRef}
                width={stageDimensions.width}
                height={stageDimensions.height}
                draggable
                scaleX={scale.stageScale}
                scaleY={scale.stageScale}
                x={scale.stageX}
                y={scale.stageY}
                onWheel={onStageWheel}
                onDblClick={onStageDblClick}
            >
                <Layer id={'imageLayer'}>
                    <UrlImage src={image} planBlueprintScale={planBlueprintScale} imageOffset={imageOffset} />
                </Layer>
                {!showBackgroundPlanImage && (
                    <Layer>
                        <Rect
                            fill="white"
                            draggable={false}
                            width={currentPlanData.planWidth}
                            height={currentPlanData.planHeight}
                            // scaleX={planBlueprintScale}
                            // scaleY={planBlueprintScale}
                            x={sizes.GLOBAL_SHIFT + imageOffset[0]}
                            y={sizes.GLOBAL_SHIFT + imageOffset[1]}
                        />
                    </Layer>
                )}
                {geometry}
            </Stage>
        </DrawPanelWrapper>
    );
};

export default DrawPanel;
