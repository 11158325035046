import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Line, Group, Circle, Arrow, Shape } from 'react-konva';

import {
    deleteAnchor,
    deleteObject,
    moveAnchor,
    passWaysReducerValues,
    toggleContextMenu,
} from '../../../../passWays.reducer';
import { IDrawPassWayProps } from './PassWay.interfaces';
import { commonTools, ObjectLabel } from '../../../../../../layers';
import { getArrowPoint } from '../../../../../../../../tools/getArrowPoint';
import { useTranslation } from 'react-i18next';
import { INodeContextMenu } from '../../../../../../layers.interfaces';
import { ContextualMenu } from '@fluentui/react';

/**
 * Компонент объект прохода
 */
const PassWay: React.FC<IDrawPassWayProps> = (props) => {
    const {
        isDrawNow,
        showFinishCircle,
        coords,
        passMargin,
        connectivityMargin = 2,
        currentPlanData: { planScale },
        stageScale,
        onClick,
        onHelperMouseMove,
        selected,
        marker = '',
        name = '',
        id = '',
        activeToolId,
        onMouseDown,
        onMouseUp
    } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [contextMenu, set_contextMenu] = useState<INodeContextMenu>({ show: false });
    const {
        showLabels,
        generalSettings: { snapToGrid },
        currentPlanData,
    } = useSelector(passWaysReducerValues);

    const onShapeClick = (id: string | undefined) => (e: KonvaEventObject<MouseEvent>) => {
        onClick(e, id);
    };

    const onAnchorDragMove = (pointIndex: number) => (e: KonvaEventObject<MouseEvent>) => {
        const newCoords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
        newCoords && dispatch(moveAnchor({ pointIndex, id, newCoords }));
    };

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        onHelperMouseMove && onHelperMouseMove({ e });
    };

    const strokeWidth = planScale ? passMargin * planScale : 2;
    const stroke = selected ? 'rgba(255, 0, 0, 0.7)' : 'rgba(255, 0, 0, 0.4)';

    const points = useMemo(() => {
        const result: Array<number> = [];
        coords.forEach((point) => {
            result.push(...point);
        });
        return result;
    }, [coords]);

    const onAnchorContextMenu =
        (elementId: string, anchorIndex: number, pointsNum: number) => (e: KonvaEventObject<MouseEvent>) => {
            e.evt.preventDefault();
            if (activeToolId !== 'anchorsEdit') {
                return;
            } else {
                const { x, y } = e.evt;
                dispatch(toggleContextMenu({ show: true, cursor: { x, y }, anchorIndex, pointsNum }));
            }
        };

    const _onMouseDown = (e: any) => {
        onMouseDown(id, e)
    }

    const anchors = useMemo(() => {
        if (!selected || !planScale) return null;
        const radius = 0.5 * passMargin * planScale;
        const draggable = activeToolId === 'anchorsEdit';
        return coords.map((point, i) => {
            return (
                <Circle
                    key={`Anchor--__${i}`}
                    draggable={draggable}
                    onDragMove={onAnchorDragMove(i)}
                    onContextMenu={onAnchorContextMenu(id, i, coords.length)}
                    x={point[0]}
                    y={point[1]}
                    radius={radius}
                    fill={'transparent'}
                    stroke={'green'}
                    strokeWidth={4 / stageScale}
                />
            );
        });
    }, [selected, activeToolId, coords, passMargin, planScale, stageScale]);

    const labelCoords = useMemo(() => commonTools.findLabelCoords(coords), [selected, coords]);

    const arrows = useMemo(() => {
        const result: JSX.Element[] = [];
        coords.forEach((point, i, arr) => {
            if (i === 0) return;
            const center = [(point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2];
            const arrowEndPoint = getArrowPoint(point, i, arr, planScale || 1, connectivityMargin / 2, 'end');
            const arrowStartPoint = getArrowPoint(point, i, arr, planScale || 1, connectivityMargin / 2, 'start');
            !showFinishCircle &&
                result.push(
                    <React.Fragment key={`${i}--${point[0]}`}>
                        <Arrow
                            points={[arrowStartPoint[0], arrowStartPoint[1], arrowEndPoint[0], arrowEndPoint[1]]}
                            stroke="red"
                            strokeWidth={commonTools.scaleTransformFoo(3, stageScale)}
                            pointerWidth={commonTools.scaleTransformFoo(8, stageScale)}
                            pointerLength={commonTools.scaleTransformFoo(12, stageScale)}
                        />
                        {/* <Circle
                            draggable={false}
                            x={arrowStartPoint[0]}
                            y={arrowStartPoint[1]}
                            radius={commonTools.scaleTransformFoo(5, stageScale)}
                            fill={'red'}
                        /> */}
                    </React.Fragment>,
                );
        });
        return result;
    }, [connectivityMargin, coords, planScale, showFinishCircle, stageScale]);



    return (
        <>
            <Group key={`group == ${id}`}
                   onMouseDown={_onMouseDown}
                   onMouseUp={onMouseUp}
            >
                {/* {connectivityShape} */}
                <Line
                    onClick={onShapeClick(id)}
                    onMouseMove={onMouseMove}
                    points={points}
                    stroke={stroke}
                    strokeWidth={strokeWidth}
                    lineJoin={'round'}
                    lineCap={'round'}
                />
                {arrows}
            </Group>
            {anchors}
            {labelCoords && !isDrawNow && showLabels && (
                <ObjectLabel
                    stageScale={stageScale}
                    x={labelCoords?.x}
                    y={labelCoords?.y}
                    marker={marker}
                    name={name}
                    selected={selected}
                />
            )}
        </>
    );
};

export default PassWay;
