import * as THREE from 'three';
import { IPolygon } from '../../../../../layers.interfaces';
import { IScaleShapeArgs } from '../../../checkouts.interfaces';

/**
 * Вращение полигона.
 * @param center  //Координаты центроида.
 * @param initialPointerCoords  //Начальные координаты курсора.
 * @param currentPointerCoords  //Текущие координаты курсора.
 * @param initialPolygon  //Начальный полигон в формате геоджейсон.
 */
export const rotateShape = ({ center, initialPointerCoords, currentPointerCoords, initialPolygon }: IScaleShapeArgs): IPolygon => {
    const centerV = new THREE.Vector3(center.x, center.y, 0);
    const initV = new THREE.Vector3(...initialPointerCoords, 0).sub(centerV);
    const currentV = new THREE.Vector3(...currentPointerCoords, 0).sub(centerV);
    const normalV = new THREE.Vector3(0, 0, 1);

    let angle = Math.acos(currentV.normalize().dot(initV.normalize()));
    const cross = currentV.cross(initV);
    if (normalV.dot(cross) < 0) {
        angle = -angle;
    }

    const matr = new THREE.Matrix3();
    matr.set(Math.cos(angle), Math.sin(angle), 0, -Math.sin(angle), Math.cos(angle), 0, 0, 0, 0);

    const newCoords = initialPolygon.coordinates.map((point) => {
        const pointV = new THREE.Vector3(...point, 0);
        const centeredPointV = pointV.sub(centerV);
        const scaledCenteredPointV = centeredPointV.applyMatrix3(matr);
        const scaledPointV = scaledCenteredPointV.add(centerV);
        return [scaledPointV.x, scaledPointV.y];
    });
    return { type: 'Polygon', coordinates: newCoords };
};
