import styled from 'styled-components/macro';

export const IntersectModalWrapper = styled.div`
    height: 100%;
    max-height: 700px;
`;

export const IntersectTableWrapper = styled.div`
    margin-top: 20px;
    min-height: 150px;
    max-height: 600px;
    overflow: auto;
`;

export const CustomCell = styled.td`
    span {
        font-size: 14px;
        padding: 8px 5px;
        display: flex !important;
        overflow: auto;
        align-items: center;
        justify-content: flex-start;
    }
`;
