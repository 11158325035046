import { KonvaEventObject } from 'konva/lib/Node';
import { cloneDeep } from 'lodash';
import { getPointerCoords } from '../../../core/getPointerCoords';

export const updateAnchorCoords = (pointIndex: number, coords: Array<number[]>, e: KonvaEventObject<MouseEvent>) => {
    const newCoords = getPointerCoords(e);
    const currentCoords = cloneDeep(coords);
    if (newCoords) {
        currentCoords[pointIndex] = newCoords;
        return [...currentCoords];
    }
};
