import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPlans, fetchPlData, plansReducerValues, storePlanToEdit } from '../components/Plans/Plans.reducer';

import { locationsReducerValues } from '../components/Locations/Locations.reducer';
import { generalReducerValues } from '../General.reducer';
import { useAppDispatch } from '../store';

export const useRouterParams = () => {
    const { planToEdit, plansList, planBlueprintScale, imageOffset, plansIdsByVersionIds } =
        useSelector(plansReducerValues);

    const params = useParams();

    const dispatch = useAppDispatch();
    const { activeLocation, activeProjectLocation } = useSelector(locationsReducerValues);
    const {
        token,
        urls: { PROJECT_LOCATIONS_URL, PLANS_URL },
    } = useSelector(generalReducerValues);

    useEffect(() => {
        if (params.plId && activeProjectLocation === null) {
            dispatch(fetchPlData(PROJECT_LOCATIONS_URL, token, params.plId));
        }
    }, [PROJECT_LOCATIONS_URL, activeProjectLocation, params.plId, token]);

    useEffect(() => {
        if (token && activeLocation?.id && PLANS_URL) {
            dispatch(fetchPlans(PLANS_URL, token, activeLocation?.id));
        }
    }, [PLANS_URL, activeLocation, token]);

    useEffect(() => {
        if (params.planId && plansList.length) {
            const planToEdit = plansList.find((item) => String(item.planId) === params.planId);
            planToEdit && dispatch(storePlanToEdit({ planToEdit }));
        }
    }, [params.planId, plansList]);
};
