import { IItems } from '../ObjectOptions.interface';


const items: IItems = [
    {
        // color: "yellow",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        height: 159,
        key: "item-0 Lorem ipsum dolor sit1",
        // location: "Portland",
        name: "1 sec",
        shape: 1,
        // thumbnail: "//placehold.it/159x159",
        width: 159,
        active:false
    },
    {
        // color: "yellow",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        height: 159,
        key: "item-0 Lorem ipsum dolor sit2",
        // location: "Portland",
        name: "20 sec",
        shape: 20,
        // thumbnail: "//placehold.it/159x159",
        width: 159,
        active:false
    },
    {
        // color: "yellow",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        height: 159,
        key: "item-0 Lorem ipsum dolor sit3",
        // location: "Portland",
        name: "30 sec",
        shape: 30,
        // thumbnail: "//placehold.it/159x159",
        width: 159,
        active:false
    },
    {
        // color: "yellow",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        height: 159,
        key: "item-0 Lorem ipsum dolor sit4",
        // location: "Portland",
        name: "1 min",
        shape: 60,
        // thumbnail: "//placehold.it/159x159",
        width: 159,
        active:false
    },
    {
        // color: "yellow",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        height: 159,
        key: "item-0 Lorem ipsum dolor sit5",
        // location: "Portland",
        name: "2 min",
        shape: 120,
        // thumbnail: "//placehold.it/159x159",
        width: 159,
        active:false
    },
    {
        // color: "yellow",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        height: 159,
        key: "item-0 Lorem ipsum dolor sit6",
        // location: "Portland",
        name: "3 min",
        shape: 180,
        // thumbnail: "//placehold.it/159x159",
        width: 159,
        active:false
    },
    {
        // color: "yellow",
        // description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo",
        height: 159,
        key: "item-0 Lorem ipsum dolor sit7",
        // location: "Portland",
        name: "5 min",
        shape: 300,
        // thumbnail: "//placehold.it/159x159",
        width: 159,
        active:false
    }

];

export default items;
