import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import NavPolygonsReducer from './navPolygons.reducer';

export const store = configureStore({
    reducer: {
        NavPolygonsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
