/**
 * Получение Имени и блоба из файла
 * @param files Список файлов
 * @param maxSize Максимальный размер файла в МБ
 * @param alertString Строка предупреждения о превышении размера
 */
export const getImageFile = (files: FileList | null, maxSize: number, alertString: string) => {
    let fileName: string | undefined = '';
    let image: string | null = null;
    if (files) {
        for (let i = 0; i < files.length; i++) {
            let file = files.item(i);
            if (file && file?.size > maxSize * 1024 * 1024) {
                alert(alertString);
            } else {
                fileName = file?.name;
                let URL = window.webkitURL || window.URL;
                image = URL.createObjectURL(file as Blob | MediaSource);
            }
        }
    }
    return { fileName, image };
};
