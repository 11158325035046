import { store } from '../../../../store';
import { Provider } from 'react-redux';
import React from 'react';
import CommonData from './CommonData';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <CommonData {...props} />
        </Provider>
    );
};
