import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';

import { Line, Group, Circle, Arrow } from 'react-konva';
import { useTranslation } from 'react-i18next';
import { reducerValues, toggleContextMenu } from '../../../../reducer';
import { ISensorProps } from './interfaces';
import { commonTools, ObjectLabel } from '../../../../../../monitoringLayers';
import { theme } from '../../../../../../../../constants/theme';
import { describeSensors } from '../../../../core/describeSensors';

/**
 * Компонент объект сенсора подсчета посетителей
 */
const Sensor: React.FC<ISensorProps> = ({
    centerPoint,
    planScale = 1,
    stageScale,
    maxMeasures,
    onClick,
    values,
    selected,
    sensor_id,
    activeToolId,
}) => {
    const dispatch = useDispatch();
    const { showLabels, pcIpoints, selectedPassPointId } = useSelector(reducerValues);
    const { t } = useTranslation();

    const onObjectClick = () => {
        onClick(sensor_id);
    };

    const onContextMenu = (e: KonvaEventObject<MouseEvent>) => {
        e.evt.preventDefault();
        const { x, y } = e.evt;
        dispatch(toggleContextMenu({ show: true, cursor: { x, y }, sensor_id }));
    };

    const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);

    const centralCircle = useMemo(() => {
        const radius = selected ? foo(20) : foo(10);

        return centerPoint ? (
            <>
                <Circle
                    draggable={false}
                    x={centerPoint.coordinates[0]}
                    y={centerPoint.coordinates[1]}
                    radius={radius}
                    fill={describeSensors(values, maxMeasures).fill}
                    stroke={'black'}
                    strokeWidth={foo(2)}
                    onClick={onObjectClick}
                    // onContextMenu={onContextMenu}
                />
            </>
        ) : null;
    }, [values, selected, foo, centerPoint, onObjectClick]);

    return <>{centralCircle}</>;
};

export default Sensor;
