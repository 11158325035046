import React, { useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { MonitoringWrapper, dropdownStyles, Wrapper } from './styles';
import {
    editorReducerValues,
    selectMonitoringLayer,
    changeShowMonitoringLabelsAliaces,
} from '../../../../Editor.reducer';
import { EditorContext } from '../../../../Editor.context';
import { versionsReducerValues } from '../../../../../Versions/Versions.reducer';
import { toggleAlert } from '../../../../../../General.reducer';

import Accordion from '../Accordion/Accordion';
import { useTranslation } from 'react-i18next';
import { IOptionsByAlias } from './interfaces';
import { IMonitoringLayer } from '../../../../../Monitoring/interfaces';
import { GeneralContext } from '../../../../../../General.context';
import { monitoringNamesMapping } from '../../../../../../constants/monitoringNamesMapping';
import { IVersion } from '../../../../../Versions/Versions.interfaces';
import { IVersionsData } from '../../../../../../tools/commonInterfaces';
import { IAlert } from '../../../../../../General.interfaces';
import { locationsReducerValues } from '../../../../../Locations/Locations.reducer';

const MonitoringList = ({ visible }: { visible: boolean }) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { savedLayersFlag, initialLayers, showMonitoringLabelsAliaces } = useSelector(editorReducerValues);
    const { activeProjectLocation } = useSelector(locationsReducerValues);
    const { versionsById, activeVersionId } = useSelector(versionsReducerValues);
    const { monitoringLayers, currentPlanData, showMonitoringModalTrans } = useContext(EditorContext);
    const { generalSettings, token, urls, windowSize } = useContext(GeneralContext);

    const toggleMonitoringAlert = (args: IAlert) => {
        dispatch(toggleAlert(args));
    };

    const optionsByAlias: IOptionsByAlias = useMemo(() => {
        return {
            sensors_monitoring_layer: {
                pcIpoints: initialLayers.filter((layer) => layer.layerType === 'pc_sensor_ipoints_layer')[0],
                savedLayersFlag,
                visible,
                activePlId: activeProjectLocation?.id,
                toggleMonitoringAlert,
                urls,
            },
        };
    }, [initialLayers, savedLayersFlag, token, urls, visible]);

    const selectLayer = (alias: string) => (e: React.MouseEvent) => {
        e.stopPropagation();
        dispatch(selectMonitoringLayer(alias));
        // !visibleLayersIds.includes(alias) && dispatch(toggleVisible(alias));
    };

    const onShowLabelIconClick = (alias: string) => {
        dispatch(changeShowMonitoringLabelsAliaces(alias));
    };

    const showMonitoringLayerModal = (alias: string) => {
        showMonitoringModalTrans(alias);
    };

    const list = useMemo(() => {
        const versionsData: IVersionsData = {
            activeVersionId,
            versionsById,
        };
        return monitoringLayers.map((layer: IMonitoringLayer) => {
            const ObjectsList = layer.controls.objectsList;
            const CommonData = layer.controls.commonData;
            const title = t(monitoringNamesMapping.get(layer.alias) || '');
            const showLabels = showMonitoringLabelsAliaces.includes(layer.alias);
            const newGeneralSettings = { ...generalSettings, token, windowSize, urls };

            return (
                <Wrapper
                    //  onClick={selectLayer(layer.alias)}
                    key={layer.alias}
                >
                    <Accordion
                        isInitialOpen={true}
                        title={title}
                        showLabels={showLabels}
                        key={`m_layer ${layer.alias}`}
                        onShowLabelIconClick={onShowLabelIconClick}
                        id={layer.alias}
                        // maxContentHeight='none'
                    >
                        <ObjectsList
                            showLabels={showLabels}
                            generalSettings={newGeneralSettings}
                            currentPlanData={currentPlanData}
                            versionsData={versionsData}
                            showMonitoringLayerModal={showMonitoringLayerModal}
                            {...optionsByAlias[layer.alias]}
                        />
                    </Accordion>
                    <CommonData />
                </Wrapper>
            );
        });
    }, [
        activeVersionId,
        versionsById,
        activeVersionId,
        monitoringLayers,
        showMonitoringLabelsAliaces,
        generalSettings,
        currentPlanData,
        optionsByAlias,
    ]);

    return <MonitoringWrapper visible={visible}>{list}</MonitoringWrapper>;
};

export default MonitoringList;
