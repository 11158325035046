import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';

import { getRequest, patchRequest, postRequest } from '../../tools/api';
import { mapFront2Back, mapBack2Front } from '../../tools/mappingFrontAndBack';
import { IReducerState, IProject, IOwner } from './interfaces';
import { locationsCloneMapping, locationsMapping } from '../../constants/keysMapping';
import GeneralReducer, { toggleAlert } from '../../General.reducer';
import _ from 'lodash';

const initialState: IReducerState = {
    owners: [],
    projectsList: [],
    activeProjectId: null,
    projectsById: {},
};

export const ProjectsReducer = createSlice({
    name: 'ProjectsReducer',
    initialState,
    reducers: {
        /**
         * Сохранение списка локаций в стор.
         */
        storeProjects: (state, action: PayloadAction<any>) => {
            if (!action.payload) return;

            const byId = action.payload.reduce((acc: { [x: string]: IProject }, value: IProject) => {
                if (value.id) acc[value.id] = value;
                return acc;
            }, {});

            state.projectsById = byId;
            state.projectsList = action.payload;
        },

        storeOwners: (state, action: PayloadAction<IOwner[]>) => {
            if (!action.payload) return;

            state.owners = action.payload;
        },
        /**
         * Изменение Id активного проекта.
         */
        changeActiveProjectId: (state, action: PayloadAction<{ id: number | null }>) => {
            state.activeProjectId = action.payload.id;
        },
    },
});

export const { changeActiveProjectId, storeOwners } = ProjectsReducer.actions;

/**
 * Запрос списка проектов с сервера.
 */
export const fetchProjects =
    (url: string, token: string | null): AppThunk =>
    (dispatch) => {
        getRequest({ url, dispatch, allowSpinner: true, token }).then((data: Array<IProject>) => {
            dispatch(ProjectsReducer.actions.storeProjects(data));
        });
    };

export const fetchOwners =
    (url: string, token: string | null): AppThunk =>
    (dispatch) => {
        getRequest({ url, dispatch, allowSpinner: true, token }).then((data: Array<IOwner>) => {
            dispatch(storeOwners(data as IOwner[]));
        });
    };

export const createProject =
    (args: Omit<IProject, 'id' | 'date_to'>): AppThunk =>
    (dispatch, getState) => {
        const {
            urls: { PROJECTS_URL },
            token,
        } = getState().GeneralReducer;

        postRequest({ url: PROJECTS_URL, dispatch, allowSpinner: true, token, data: args }).then((data) => {
            dispatch(fetchProjects(PROJECTS_URL, token));
        });
    };

export const editProject =
    (args: Omit<IProject, 'date_to'>): AppThunk =>
    (dispatch, getState) => {
        const {
            urls: { PROJECTS_URL },
            token,
        } = getState().GeneralReducer;

        patchRequest({
            url: `${PROJECTS_URL}${args.id}/`,
            dispatch,
            allowSpinner: true,
            token,
            data: _.omit(args, 'id'),
        }).then((data) => {
            dispatch(fetchProjects(PROJECTS_URL, token));
        });
    };

/**
 * Запись новой локации на сервер.
 *
 * @param args url, token, data, getUrl
 */
// export const addNewLocation =
//     (args: IAddNewLocationArgs): AppThunk =>
//     (dispatch) => {
//         const { url, token, data } = args;

//         const mappedData = mapFront2Back(locationsMapping, data);

//         postRequest({ url, dispatch, allowSpinner: true, token, data: mappedData }).then((data) => {
//             dispatch(fetchLocations(url, token));
//         });
//     };

/**
 * Запрос на клонирование локации на сервере.
 *
 * @param args  data
 */
// export const cloneLocation =
//     (args: ICloneLocationArgs): AppThunk =>
//     (dispatch, getState) => {
//         const {
//             token,
//             urls: { LOCATIONS_URL, LOCATION_CLONE_URL },
//         } = getState().GeneralReducer;
//         const { data } = args;

//         if (token) {
//             const mappedData = mapFront2Back(locationsCloneMapping, data);

//             postRequest({ url: LOCATION_CLONE_URL, dispatch, allowSpinner: true, token, data: mappedData }).then(
//                 (data) => {
//                     if (!data) {
//                         const text = 'Clone location error';
//                         dispatch(toggleAlert({ show: true, text, lifeTime: 3000 }));
//                     } else {
//                         dispatch(fetchLocations(LOCATIONS_URL, token));
//                     }
//                 },
//             );
//         }
//     };

/**
 * Обновление информации о локации
 * @param args url, token, data, locationId
 */
// export const patchLocation =
//     (args: IPatchLocationArgs): AppThunk =>
//     (dispatch) => {
//         const { url, token, data, locationId } = args;

//         patchRequest({ url: `${url}${locationId}/`, dispatch, token, data }).then(() => {
//             dispatch(fetchLocations(url, token));
//         });
//     };

export const projectsReducerValues = (state: RootState) => state.ProjectsReducer;

export default ProjectsReducer.reducer;
