import styled from 'styled-components/macro';

export const GeneralWrapper = styled.main`
    height: 100vh;
    overflow: hidden;
`;

export const NoPermissions = styled.div`
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;
