import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';
import { ContextualMenu, ContextualMenuItemType } from '@fluentui/react/lib/ContextualMenu';
import { IContextualMenuSubComponentStyles } from '@fluentui/react/lib';
import { CSVLink } from 'react-csv';
import ReactDataSheet from 'react-datasheet';

export const StyledContextualMenu = styled(ContextualMenu)`
    /* .ms-Callout {
        overflow: visible !important;
    } */
`;



export const StyledCSVLink = styled(CSVLink)`
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-text-size-adjust: 100%;
    visibility: visible;
    list-style-type: none;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    line-height: 36px;
    cursor: pointer;
    text-align: left;
    font-family: inherit;
    margin: 0px 4px;
    vertical-align: middle;
    display: inline-block;
    flex-grow: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const Container = styled.div`
    -webkit-text-size-adjust: 100%;
    visibility: visible;
    list-style-type: none;
    margin: 0;
    overflow: visible;
    text-transform: none;
    -webkit-appearance: button;
    outline: transparent;
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    background-color: transparent;
    border: none;
    width: 100%;
    height: 36px;
    line-height: 36px;
    display: block;
    cursor: pointer;
    padding: 0px 8px 0px 4px;
    text-align: left;
    font-family: inherit;
    :hover {
        background-color: rgb(237, 235, 233);
    }
`;
