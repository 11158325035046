import { mergeStyles } from '@fluentui/react/lib/Styling';
import styled from 'styled-components/macro';
import { FontIcon } from '@fluentui/react/lib/Icon';

export const iconClass = (color: string) =>
    mergeStyles({
        fontSize: 16,
        height: 20,
        width: 20,
        // marginRight: '5px',
        color,
    });

export const StyledFontIcon = styled(FontIcon)<{ showLabels: boolean }>`
    cursor: pointer;
    opacity: ${(p) => (p.showLabels ? '1' : '0.2')};
`;
