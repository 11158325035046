import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { useMemo } from 'react';
import { reducerValues, toggleContextMenu, toggleLayerModal } from '../../reducer';
import { ContextualMenu } from '@fluentui/react';

/**
 * Компонент Контекстного меню
 */
const ContextMenu = () => {
    const { contextMenu } = useSelector(reducerValues);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onHideContextualMenu = () => {
        dispatch(toggleContextMenu({ show: false }));
    };

    const menuItems = useMemo(() => {
        const result = [
            {
                key: 'show_info',
                text: t('Show sensor info'),
                onClick: () => {
                    // dispatch(toggleLayerModal({ show: true }));
                },
            },
        ];
        return result;
    }, [contextMenu]);

    return menuItems ? (
        <ContextualMenu
            items={menuItems}
            hidden={!contextMenu.show}
            target={contextMenu.cursor}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
        />
    ) : null;
};

export default ContextMenu;
