import { Group, Circle, Text, Label, Tag } from 'react-konva';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { IFloorsLabelProps, IObjectLabelProps, ITupleLabelProps, IDrawNodes} from './commonInterfaces';
import { commonTools } from './commonTools';

export const ObjectLabel = ({
    x,
    y,
    marker,
    name,
    someText = '',
    showTips = true,
    stageScale = 1,
    selected = false,
    ...props
}: IObjectLabelProps) => {
    const markerText = marker ? `${marker}` : '';
    const nameText = name ? `\n(${name})` : '';
    const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
    const fill = selected ? 'rgba(0,0,0,0.8)' : 'rgba(0,0,0,0.5)';

    return x && y ? (
        <Label
            x={x}
            y={y}
            // opacity={selected ? 0.8 : 0.5}
        >
            <Tag
                fill={fill}
                pointerDirection={'down'}
                pointerWidth={foo(selected ? 20 : 10)}
                pointerHeight={foo(selected ? 20 : 10)}
                lineJoin={'round'}
                // shadowColor={'black'}
                // shadowBlur={2}
                // shadowOffsetX={2}
                // shadowOffsetY={2}
                // shadowOpacity={0.5}
            />
            <Text
                text={markerText + nameText + someText}
                fontSize={foo(selected ? 28 : 14)}
                lineHeight={1.2}
                padding={foo(selected ? 10 : 5)}
                fill={'white'}
            />
        </Label>
    ) : null;
};

export const TupleLabel = ({ x, y, text, stageScale = 1, ...props }: ITupleLabelProps) => {
    const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
    return (
        <Label x={x} y={y}>
            <Tag
                fill={'rgba(0,0,0,0.7)'}
                pointerDirection={'down'}
                pointerWidth={foo(10)}
                pointerHeight={foo(10)}
                lineJoin={'round'}
                // shadowColor={'black'}
                // shadowBlur={2}
                // shadowOffsetX={2}
                // shadowOffsetY={2}
                // shadowOpacity={0.5}
            />
            <Text text={text} fontSize={foo(14)} lineHeight={1.2} padding={foo(5)} fill={'white'} />
        </Label>
    );
};

export const FloorsLabel = ({
    x,
    y,
    text,
    stageScale = 1,
    planScale = 1,
    ...props
}: IFloorsLabelProps): JSX.Element => {
    const scale = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
    return (
        <Label x={x} y={y} opacity={0.75}>
            <Tag
                fill={'transparent'}
                pointerDirection={'down'}
                pointerWidth={0}
                pointerHeight={0}
                lineJoin={'round'}
                // shadowColor={'black'}
                // shadowBlur={2}
                // shadowOffsetX={2}
                // shadowOffsetY={2}
                // shadowOpacity={0.5}
            />
            <Text text={text} fontSize={scale(14)} lineHeight={1.2} padding={scale(5)} fill={'white'} />
        </Label>
    );
};

export const DrawNodes = ({ coordinates, stageScale = 1, ...props }: IDrawNodes): JSX.Element => {
    const circles = coordinates.map(([x, y], index) => (
        <Circle
            id={`circleSmall-${x}-${y}-${index}`}
            key={`circleSmall-${x}-${y}-${index}`}
            x={x}
            y={y}
            radius={4 / stageScale}
            stroke={'rgba(0,0,0,0.3)'}
            strokeWidth={1 / stageScale}
        />
    ));
    return <>{circles}</>;
};

export { Anchors } from './Anchors/Anchors';
export { Helper } from './Helper/Helper';
