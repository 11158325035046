import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Group } from 'react-konva';

import {
    reducerValues,
    storeNewObject,
    selectObject,
    changeCoordsFullLayer,
    clearSelectedObjects,
    moveObject,
    changeCoords,
} from '../../reducer';
import Helper from './components/Helper/Helper';
import { commonTools } from '../../../../layers';
import { IHelperMouseMoveArgs } from './components/Helper/Helper.interfaces';
import WiFiSensorObject from './components/Object/Object';
import { cloneDeep } from 'lodash';
import { IObjects } from '../../interfaces';
import { formattingCoords } from '../../../Perimeter_layer/components/Draw/core/formattingCoords';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        objects,
        selectedObjectId,
        currentPlanData,
        generalSettings: { snapToGrid },
    } = useSelector(reducerValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [currentPointerCoords, setCurrentPointerCoords] = useState<number[] | undefined>(undefined);
    const [isMouseDownMove, setIsMouseDownMove] = useState<boolean>(false);
    const [coordsOldShape, setCoordsOldShape] = useState<number[]>([]);

    useEffect(() => {
        dispatch(clearSelectedObjects());
        const container = props.stage.current.container();
        if (activeToolId === 'draw' && container) {
            container.style.cursor = 'crosshair';
        } else if (activeToolId === 'move' && container) {
            container.style.cursor = 'pointer';
        } else if (activeToolId === 'moveLayer') {
            container.style.cursor = 'move';
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId]);

    const onHelperClick = (e: KonvaEventObject<MouseEvent>, marker?: string) => {
        if (activeToolId === 'draw') {
            const point = commonTools.getPointerCoords({ e, snapToGrid: false });
            point && dispatch(storeNewObject({ point }));
        } else {
            dispatch(selectObject());
        }
    };

    const onObjectClick = (front_id?: string) => {
        if (activeToolId === 'anchorsEdit') {
            dispatch(selectObject(front_id));
        }
    };

    const moveShape = (e: any) => {
        if (activeToolId === 'moveLayer') {
            if (isMouseDownMove) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[][] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }
                let _objects: IObjects = [];
                objects.forEach((item, i) => {
                    _objects.push({
                        ...item,
                        point: {
                            ...item.point,
                            coordinates: commonTools.processCoordsForMoveShape(
                                [item.point.coordinates],
                                coords,
                                coordsOldShape,
                            )[0],
                        },
                    });
                });

                dispatch(changeCoordsFullLayer(_objects));

                setCoordsOldShape(coords);
            }
        } else if (activeToolId === 'move') {
            if (selectedObjectId) {
                e.evt.cancelBubble = true;

                const coords = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid });
                let _coordsShape: number[] = [];
                if (coordsOldShape.length === 0) {
                    setCoordsOldShape(coords);
                }

                const moveObj = objects.find((item) => item.front_id === selectedObjectId);

                if (moveObj) {
                    _coordsShape = commonTools.processCoordsForMoveShape(
                        [moveObj.point.coordinates],
                        coords,
                        coordsOldShape,
                    )[0];
                }

                dispatch(changeCoords({ coordsS: _coordsShape, front_id: selectedObjectId }));

                setCoordsOldShape(coords);
            }
        }
    };

    const onMouseDown = (id: string | undefined, e: any) => {
        setCoordsOldShape(commonTools.getPointerCoords({ e, currentPlanData, snapToGrid: snapToGrid }));
        // setIdSelectedShape(id);
        if (activeToolId === 'moveLayer') {
            setIsMouseDownMove(true);
        } else if (activeToolId === 'move') {
            dispatch(selectObject(id));
        }
    };

    const onMouseUp = (e: any) => {
        if (activeToolId === 'moveLayer') {
            dispatch(clearSelectedObjects());
            setIsMouseDownMove(false);
        } else if (activeToolId === 'move') {
            dispatch(clearSelectedObjects());

            // setIdSelectedShape('')
        }
    };

    const onHelperMouseMove = ({ e }: IHelperMouseMoveArgs) => {
        setCurrentPointerCoords(commonTools.getPointerCoords({ e, snapToGrid: false }));
        if (isDrawNow === true) {
            const newPoint = commonTools.getPointerCoords({ e, snapToGrid: false });
            if (newPoint) {
                coords.pop();
                setCoords([...coords, newPoint]);
            }
        }
    };

    const geometry = () => {
        if (!objects || Object.keys(objects).length === 0 || !currentPlanData?.planScale) return null;

        return cloneDeep(objects)
            .sort((a, _) => {
                if (a.front_id === selectedObjectId) return 1;
                return -1;
            })
            .map((item) => {
                const { planScale } = currentPlanData;
                const { marker, front_id, point, is_active, sensor_types, wifi_scanner_radius } = item;
                const selected = front_id === selectedObjectId;
                const args = {
                    coords: point.coordinates,
                    marker,
                    planScale,
                    stageScale: props.scale.stageScale,
                    key: front_id,
                    front_id,
                    is_active,
                    sensor_types,
                    wifi_scanner_radius,
                };
                return (
                    <WiFiSensorObject
                        {...args}
                        onClick={onObjectClick}
                        onHelperMouseMove={onHelperMouseMove}
                        selected={selected}
                        activeToolId={activeToolId}
                        onMouseDown={onMouseDown}
                        // onMouseUp={onMouseUp}
                    />
                );
            });
    };

    return (
        <Layer key={props.key} onMouseLeave={onMouseUp} onMouseMove={moveShape} onMouseUp={onMouseUp}>
            <Helper
                currentPlanData={currentPlanData!}
                isDrawNow={isDrawNow}
                coords={coords}
                onHelperClick={onHelperClick}
                onHelperMouseMove={onHelperMouseMove}
            />
            {geometry()}
        </Layer>
    );
};

export default Draw;
