import axios from 'axios';
import { getHeaders } from './getHeaders';

export const getLocales = async () => {
    const searchedStr = 'f' + 'o' + 'c' + 'u' + 's.b' + 'i';
    // const searchedStr = 'f' + 'o' + 'c' + 'u' + 'stech.x' + 'y' + 'z';
    let arr = window.location.host.split('.');
    arr.shift();
    let default_admin_domain = arr.join('.');
    if (
        window.location.host === 'localhost:3000' ||
        window.location.host === 'f' + 'o' + 'c' + 'u' + 'stech.x' + 'y' + 'z'
    ) {
        default_admin_domain = searchedStr;
    }
    if (window.location.host === 'localhost:3333') {
        default_admin_domain = 'mallsense.ae';
    }

    const res = await axios
        .get(`https://admin.${default_admin_domain}/locales?domain=${window.location.host}`, { headers: getHeaders() })
        .then((res) => res.data)
        .then((data) => ({ data }));
    return res;
};
