import * as THREE from 'three';

/**
 * Вычисление координат финишной точки для стрелки.
 * @param point Координаты точки
 * @param i Индекс точки
 * @param arr Массив точек
 * @param planScale Масштаб плана
 * @param margin Ширина прохода
 * @returns Координаты финишной точки для стрелки.
 */
export const getEscalatorArrowPoint = <T extends number>(point1: T[], point2: T[], planScale: T) => {
    const arrowV = new THREE.Vector3(point2[0], point2[1], 0)
        .sub(new THREE.Vector3(point1[0], point1[1], 0))
        .normalize()
        .multiplyScalar(planScale * 1.3)
        .add(new THREE.Vector3(point1[0], point1[1], 0));
    return [arrowV.x, arrowV.y];
};
