import { commonTools } from '../../../../../../../layers';
import { TObjNamingMode } from '../../../../../../../layers.interfaces';
import { IObjects, IItemGroupDetailsList, INavPolygon } from '../../../../../navPolygons.interfaces';
import { useEffect, useState } from 'react';

export const useGroupForNavPolygons = (
    obj: IObjects | null,
    typeSelect: string,
    objNamingMode: TObjNamingMode = 'markers',
) => {
    const [items, setItems] = useState<INavPolygon[] | []>([]);
    const [groups, setGroups] = useState<IItemGroupDetailsList[] | []>([]);

    useEffect(() => {
        if (obj) {
            let tempGroup: IItemGroupDetailsList[] = [];
            let arrNavPolygons: INavPolygon[] = [];
            let tempStartIndex: number = 0;
            tempGroup.push({
                count: 0,
                key: 'polygons_in',
                level: 0,
                name: 'Polygons in',
                startIndex: 0,
            });
            tempGroup.push({
                count: 0,
                key: 'polygons_out',
                level: 0,
                name: 'Polygons out',
                startIndex: 0,
            });

            Object.keys(obj).forEach((item, index) => {
                let tempArrNavPolygons: INavPolygon[] = [];
                tempStartIndex = arrNavPolygons.length;
                obj[item].forEach((itemNavPolygons, indexNavPolygons) => {
                    let fallbackText = indexNavPolygons.toString();

                    switch (objNamingMode) {
                        case 'markers':
                        case 'names':
                        default:
                            break;
                    }
                    const value = {
                        ...itemNavPolygons,
                        labelText: commonTools.getObjectLabelByMode({
                            objNamingMode,
                            fallbackText,
                        }),
                    };
                    tempArrNavPolygons.push(value);
                    arrNavPolygons.push(value);
                });

                tempGroup.forEach((item2, index2) => {
                    if (item2.key === item) {
                        item2.count = tempArrNavPolygons.length;
                        item2.startIndex = tempStartIndex;
                    }
                });
            });

            // list.map((item, index) => {
            //     let tempArrZones:IZone[] = [];
            //     tempStartIndex = arrZones.length;
            //     item.zones.map((itemZones, indexZones) => {
            //
            //
            //         tempArrZones.push(itemZones);
            //         arrZones.push(itemZones);
            //     })
            //
            //     // tempGroup.push({
            //     //     count: tempArrZones.length,
            //     //     key: item.front_id!,
            //     //     level: 0,
            //     //     name: item.group_name!,
            //     //     startIndex: tempStartIndex,
            //     //     front_id: item.front_id!
            //     // })
            //
            // });

            setItems(arrNavPolygons);
            setGroups(tempGroup);
        }
    }, [obj, typeSelect, objNamingMode]);

    return { items, groups };
};
