import { measureCutLayer } from './components/MeasureCut';
import { passWaysLayer } from './components/PassWays';
import { zonesLayer } from './components/Zones';
import { checkoutsLayer } from './components/Checkouts';
import { pccLayer } from './components/PC_IPoints';
import { wifiSensorsLayer } from './components/Radio_IPoints';
import { placesLayer } from './components/Places';
import { perimeterLayer } from './components/Perimeter_layer';
import { navPolygonsLayer } from './components/NavPolygons';
import { trackingTracksLayer } from './components/TracksTraking';
import { tracksNavigationLayer } from './components/TracksNavigation';
import { tracksWifiLayer } from './components/TracksWifi';
import { escalatorsLayer } from './components/Escalators';
import { liftsLayer } from './components/Lifts';
import { stairsLayer } from './components/Stairs';

const layers = [
    { ...measureCutLayer },
    { ...passWaysLayer },
    { ...zonesLayer },
    { ...checkoutsLayer },
    { ...pccLayer },
    { ...wifiSensorsLayer },
    { ...placesLayer },
    { ...perimeterLayer },
    { ...navPolygonsLayer },
    { ...trackingTracksLayer },
    { ...tracksNavigationLayer },
    { ...tracksWifiLayer },
    { ...escalatorsLayer },
    { ...liftsLayer },
    { ...stairsLayer },
];

export default layers;

export * from '../../tools/commonComponents';
export { commonTools } from '../../tools/commonTools';
