/**
 * Словарь соответствий алиасов слоев и отображаемых в контрольной пенели имен слоев.
 */
export const tuplesNamesMapping: Map<string, string> = new Map<string, string>([
    ['passways_pcc_tuple', 'PC ipoints to Passways'],
    ['passways_places_tuple', 'Passways to Places'],
    ['passways_perimeter_tuple', 'Passways to Perimeter'],
    ['passways_zones_tuple', 'Passways to Zones'],
    ['passways_escalators_tuple', 'Passways to Escalators'],
    ['passways_lifts_tuple', 'Passways to Lifts'],
    ['passways_stairs_tuple', 'Passways to Stairs'],
    ['places_zones_tuple', 'Places to Zones'],
    ['escalators_zones_tuple', 'Escalators to Zones'],
    ['escalators_places_tuple', 'Escalators to Places'],
    ['stairs_zones_tuple', 'Stairs to Zones'],
    ['stairs_places_tuple', 'Stairs to Places'],
    ['lifts_zones_tuple', 'Lifts to Zones'],
    ['lifts_places_tuple', 'Lifts to Places'],
]);
