import * as THREE from 'three';
import { IPolygon } from '../../../../../layers.interfaces';
import { IScaleShapeArgs } from '../../../checkouts.interfaces';

/**
 * Масштабирование полигона.
 * @param center  //Координаты центроида.
 * @param initialPointerCoords  //Начальные координаты курсора.
 * @param currentPointerCoords  //Текущие координаты курсора.
 * @param initialPolygon  //Начальный полигон в формате геоджейсон.
 */
export const scaleShape = ({ center, initialPointerCoords, currentPointerCoords, initialPolygon }: IScaleShapeArgs): IPolygon => {
    const centerV = new THREE.Vector3(center.x, center.y, 0);
    const sideV = new THREE.Vector3(...initialPolygon.coordinates[2], 0).sub(new THREE.Vector3(...initialPolygon.coordinates[1], 0));
    const initV = new THREE.Vector3(...initialPointerCoords, 0).sub(centerV);
    const currentV = new THREE.Vector3(...currentPointerCoords, 0).sub(centerV);

    const initAngle = Math.acos(initV.clone().normalize().dot(sideV.clone().normalize()));
    const currentAngle = Math.acos(currentV.clone().normalize().dot(sideV.clone().normalize()));

    const normalV = new THREE.Vector3(0, 0, -1);
    let angle = Math.acos(sideV.normalize().dot(new THREE.Vector3(1, 0, 0)));
    const cross = sideV.cross(new THREE.Vector3(1, 0, 0));
    if (normalV.dot(cross) < 0) {
        angle = -angle;
    }

    const scaleX = Math.abs((Math.cos(currentAngle) * currentV.length()) / (Math.cos(initAngle) * initV.length()));
    const scaleY = Math.abs((Math.sin(currentAngle) * currentV.length()) / (Math.sin(initAngle) * initV.length()));

    const scaleMatrix = new THREE.Matrix3();
    scaleMatrix.set(Math.abs(scaleX), 0, 0, 0, Math.abs(scaleY), 0, 0, 0, 1);

    const rotateMatrix1 = new THREE.Matrix3();
    rotateMatrix1.set(Math.cos(angle), Math.sin(angle), 0, -Math.sin(angle), Math.cos(angle), 0, 0, 0, 1);

    const rotateMatrix2 = new THREE.Matrix3();
    rotateMatrix2.set(Math.cos(-angle), Math.sin(-angle), 0, -Math.sin(-angle), Math.cos(-angle), 0, 0, 0, 1);

    const newCoords = initialPolygon.coordinates.map((point) => {
        const pointV = new THREE.Vector3(...point, 0);
        const centeredPointV = pointV.sub(centerV);
        const scaledCenteredPointV = centeredPointV.applyMatrix3(rotateMatrix1).applyMatrix3(scaleMatrix).applyMatrix3(rotateMatrix2);
        const scaledPointV = scaledCenteredPointV.add(centerV);
        return [scaledPointV.x, scaledPointV.y];
    });
    return { type: 'Polygon', coordinates: newCoords };
};
