/**
 * Функция для сравнения двух массивов чисел.
 * Возвращает два массива:
 * 1. Числа, которые есть в акцепторе, но нет в доноре.
 * 2. Числа, которые есть в доноре, но нет в акцепторе.
 *
 * @param donor - массив чисел донора.
 * @param acceptor - массив чисел акцептора.
 * @returns массивы с уникальными значениями для каждого из массивов.
 */
export function compareArrays(donor: number[], acceptor: number[]): [number[], number[]] {
    const inAcceptorNotInDonor = acceptor.filter((num) => !donor.includes(num));
    const inDonorNotInAcceptor = donor.filter((num) => !acceptor.includes(num));
    return [inAcceptorNotInDonor, inDonorNotInAcceptor];
}
