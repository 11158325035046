import { theme } from '../../../../../../constants/theme';
import styled from 'styled-components/macro';

const { themePrimary, white, neutralDark } = theme.palette;

export const FloorsWrapper = styled.div`
    display: flex;
    width: 24px;
    flex-direction: column;
`;

export const Floor = styled.div<{ isActiveFloor: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${themePrimary};
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    background-color: ${(p) => (p.isActiveFloor ? themePrimary : white)};
    color: ${(p) => (p.isActiveFloor ? white : neutralDark)};
    :hover {
        background-color: ${themePrimary};
        color: ${white};
    }
`;
