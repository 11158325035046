import { IDrawNewCutArgs } from './drawNewCut.interfaces';
import { getPointerCoords } from './getPointerCoords';

/**
 * Рисование линии.
 *
 */
export const drawNewCut = ({ coords, isDrawNow, e, updateIsDrawNow, updateCoords }: IDrawNewCutArgs) => {
    if (isDrawNow === false) {
        const newPoint = getPointerCoords(e);
        updateIsDrawNow(true);
        newPoint && updateCoords([newPoint, newPoint]);
    } else if (isDrawNow === true) {
        updateIsDrawNow(false);
    }
};
