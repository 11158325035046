import styled from 'styled-components/macro';

export const Ul = styled.ul`
    margin: 0;
    padding: 0;
`;
export const Li = styled.li`
    list-style: none;
    font-size: 14px;
    margin-bottom: 3px;
`;
export const FieldName = styled.span`
    font-weight: 700;
`;
export const LocationDataWrapper = styled.div`
    margin: 10px 0;
`;
