import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchOwners, fetchProjects, projectsReducerValues } from '../components/Projects/reducer';
import { generalReducerValues } from '../General.reducer';
import { useAppDispatch } from '../store';
import { fetchProjectLocations, locationsReducerValues } from '../components/Locations/Locations.reducer';
import { fetchPlData, fetchPlans, plansReducerValues } from '../components/Plans/Plans.reducer';
import { versionsReducerValues } from '../components/Versions/Versions.reducer';

export const useCheckAllNeededData = () => {
    const { projectId, plId } = useParams();
    const dispatch = useAppDispatch();
    const { projectsList } = useSelector(projectsReducerValues);
    const { projectLocationsList } = useSelector(locationsReducerValues);
    const { plansList } = useSelector(plansReducerValues);
    const { versions } = useSelector(versionsReducerValues);
    const {
        urls: { PROJECTS_URL, PROJECT_LOCATIONS_URL, PLANS_URL, OWNERS_URL },
        token,
    } = useSelector(generalReducerValues);

    useEffect(() => {
        if (!projectsList.length && PROJECTS_URL && token) {
            dispatch(fetchProjects(PROJECTS_URL, token));
        }
    }, [PROJECTS_URL, dispatch, projectsList.length, token]);

    useEffect(() => {
        if (!projectsList.length && OWNERS_URL && token) {
            dispatch(fetchOwners(OWNERS_URL, token));
        }
    }, [OWNERS_URL, dispatch, projectsList.length, token]);

    useEffect(() => {
        if (projectId && !projectLocationsList.length && PROJECT_LOCATIONS_URL && token) {
            dispatch(fetchProjectLocations({ url: PROJECT_LOCATIONS_URL, token, projectId: projectId }));
        }
    }, [PROJECT_LOCATIONS_URL, dispatch, projectLocationsList.length, token, projectId]);

    // useEffect(() => {
    //     if (projectId && plId && !plansList.length && PROJECT_LOCATIONS_URL && token) {
    //         dispatch(fetchPlData(PROJECT_LOCATIONS_URL, token, plId));
    //     }
    // }, [
    //     PROJECT_LOCATIONS_URL,
    //     dispatch,
    //     projectLocationsList.length,
    //     token,
    //     projectId,
    //     plId,
    //     plansList.length,
    //     PLANS_URL,
    // ]);
};
