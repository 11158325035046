import { DateTime } from 'luxon';

type TRange =
    | {
          date_to: string | null;
          date_from: string;
      }
    | {
          dateTo: string | null;
          dateFrom: string;
      };

/**
 * Функция для фильтрации массива данных по переданному периоду
 * @param relation массив объектоа, который нужно отфильтровать по дате
 * @param period пириод, по которуму фильтровать
 * @returns Отфильтрованный маассив
 */
export function filterValidDateRanges<T extends TRange>(
    relation: Array<T>,
    period: { dateFrom: string; dateTo: string } | undefined,
) {
    return relation?.filter((item) => dateRangesFilter(item, period));
}

export function dateRangesFilter<T extends TRange>(
    item: T,
    period: { dateFrom: string; dateTo: string | null } | undefined,
): boolean {
    if ('date_from' in item && 'date_to' in item) {
        return (
            (DateTime.fromISO(item.date_from).toMillis() <= DateTime.fromISO(period?.dateTo || '').toMillis() ||
                period?.dateTo === null) &&
            (DateTime.fromISO(item.date_to || '').toMillis() >= DateTime.fromISO(period?.dateFrom || '').toMillis() ||
                item.date_to === null)
        );
    } else {
        return (
            (DateTime.fromISO(item.dateFrom).toMillis() <= DateTime.fromISO(period?.dateTo || '').toMillis() ||
                period?.dateTo === null) &&
            (DateTime.fromISO(item.dateTo || '').toMillis() >= DateTime.fromISO(period?.dateFrom || '').toMillis() ||
                item.dateTo === null)
        );
    }
}
