import styled from 'styled-components/macro';
import { theme } from '../../../../constants/theme';
import { IDatePickerStyles, IDropdownStyles, IStackProps } from '@fluentui/react';

export const Title = styled.div`
    font-weight: 700;
    font-size: large;
    color: ${() => theme.palette.themeDarker};
    margin-bottom: 10px;
`;

export const Controls = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    padding-bottom: 20px;
    gap: 10px;
    align-items: flex-end;
    border: ${() => '1px solid ' + theme.palette.neutralQuaternary};
`;

export const drodownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: 300 },
};

export const pickerStyles: Partial<IDatePickerStyles> = {
    root: { minWidth: 150, marginBottom: '-5px' },
};
