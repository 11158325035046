import { Pivot } from '@fluentui/react';
import styled from 'styled-components/macro';

export const PlansWrapper = styled.section<{ bgcolor: string; color: string }>`
    height: 100%;
    overflow: hidden;
    background-color: ${(p) => p.bgcolor};
    color: ${(p) => p.color};
    padding: 10px;
`;

export const StyledPivot = styled(Pivot)`
    margin-bottom: 15px;
    button {
        height: 30px;
    }
`;
