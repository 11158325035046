import React from 'react';
import { TooltipHost, ITooltipHostStyles } from '@fluentui/react/lib/Tooltip';
import { DetailsList, buildColumns, IColumn, DetailsRow, IDetailsRowProps } from '@fluentui/react/lib/DetailsList';
import {
    ListWrapper,
    customStyles,
    NotesContainer,
    Floor,
    ImageWrapper,
    iconButtonStyles,
    IconButtonWrapper,
    StyledIFrame,
} from './List.styles';
import { IPdfPlan } from '../../../../Plans.interfaces';
import { plansReducerValues } from '../../../../Plans.reducer';
import { useSelector } from 'react-redux';
import { IconButton } from '@fluentui/react';

/**
 * Компонента списка планов.
 */
const List = () => {
    const { pdfPlans } = useSelector(plansReducerValues);

    function myBuildColumns(items: IPdfPlan[]) {
        if (items.length === 0) return [];
        const columnsToDisplay = items.map((item) => {
            const { file, location_id, status, actual_from, created, floor, comment } = item;
            return { file, location_id, status, actual_from, created, floor, comment };
        });

        const columns = buildColumns(columnsToDisplay);
        const [imageColumn] = columns.filter((column) => column.name === 'file');
        imageColumn.minWidth = 100;
        imageColumn.maxWidth = 100;
        const [floorColumn] = columns.filter((column) => column.name === 'floor');
        floorColumn.minWidth = 50;
        floorColumn.maxWidth = 50;
        const [activeFromColumn] = columns.filter((column) => column.name === 'actual_from');
        activeFromColumn.minWidth = 200;
        activeFromColumn.maxWidth = 200;

        return columns;
    }

    const onRowClick = (plan: IPdfPlan) => () => {
        console.log('click>>>>', plan);
    };

    const renderRow = (props: IDetailsRowProps | undefined) => {
        if (props) {
            return (
                <div onClick={onRowClick(props.item)}>
                    <DetailsRow {...props} styles={customStyles} />
                </div>
            );
        }
        return null;
    };

    const hostStyles: Partial<ITooltipHostStyles> = {
        root: { display: 'inline-block', width: '100%', height: '100%' },
    };

    function renderItemColumn(item: { [x: string]: any }, index: any, column: IColumn | undefined) {
        if (!column?.fieldName) return null;

        const fieldContent = item[column.fieldName];

        switch (column.key) {
            case 'location_id':
            case 'status':
            case 'actual_from':
            case 'created':
                return <span>{String(fieldContent)}</span>;
            case 'floor':
                return <Floor>{String(fieldContent)}</Floor>;
            case 'file':
                return (
                    <ImageWrapper>
                        <IconButtonWrapper>
                            <IconButton
                                styles={iconButtonStyles}
                                onClick={() => window.open(fieldContent, '_blank')}
                                iconProps={{ iconName: 'Search' }}
                            />
                        </IconButtonWrapper>
                        <StyledIFrame
                            src={fieldContent}
                            title="File to upload"
                            height={'60px'}
                            width="100%"
                        ></StyledIFrame>
                    </ImageWrapper>
                );

            case 'comment':
                return (
                    <TooltipHost content={String(fieldContent)} styles={hostStyles}>
                        <NotesContainer>{String(fieldContent)}</NotesContainer>
                    </TooltipHost>
                );

            default:
                return <span>{fieldContent}</span>;
        }
    }

    return (
        <ListWrapper>
            <DetailsList
                items={pdfPlans}
                onRenderRow={renderRow}
                onRenderItemColumn={renderItemColumn}
                columns={myBuildColumns(pdfPlans)}
                selectionMode={0}
            />
        </ListWrapper>
    );
};

export default List;
