import {
	IItemCoords
} from "../../../perimeter.interfaces";
import Flatten from '@flatten-js/core'
const {
	Point,
	Polygon,
} = Flatten;

/**
 * Нахождения пересечения
 * @param data
 */
export const getIntersect = (data: {
	objects:IItemCoords[][],
	object:IItemCoords[]
}) => {


	let arrPoly: any[] = [];
	let poly: any
	data.objects.forEach((item, index) => {
		poly = new Polygon(item.map((item2, index2) => {

				return new Point(item2.x, item2.y);
			})
		);
		arrPoly.push(poly);
	});



	let intersectStatus = false;

	if (data.object.length > 1) {
		let arrayCoordsAndObject = [
			data.object[data.object.length - 1],
			data.object[data.object.length - 2]
		]
		const lineStartPoint = new Point(arrayCoordsAndObject[0].x, arrayCoordsAndObject[0].y);
		const lineEndPoint = new Point(arrayCoordsAndObject[1].x, arrayCoordsAndObject[1].y);
		if (!lineStartPoint.equalTo(lineEndPoint)) {


			const p = new Polygon([new Point(arrayCoordsAndObject[0].x, arrayCoordsAndObject[0].y),
				new Point(arrayCoordsAndObject[1].x, arrayCoordsAndObject[1].y)]);

			arrPoly.forEach((itemPoly) => {
				let ip = itemPoly.intersect(p);

				if (ip.length) {
					intersectStatus = true;
				}
			})
		}

	}




	return intersectStatus;
}