import React, { useEffect } from 'react';
import { contentStyles, iconButtonStyles } from './Modals.styles';

import { IModalProps } from './Modals.interfaces';
import { Modal } from '@fluentui/react/lib/Modal';
import { dragOptions } from './constants/constants';
import { useBoolean } from '@fluentui/react-hooks';
import { IIconProps, IconButton } from '@fluentui/react';

/**
 * Обертка для всех модалок.
 * @param modalStatus Состояние модалки, управляемое извне.◊
 * @param title Название модалки.
 */
const Modals: React.FC<IModalProps> = ({ topColor, ...props }) => {
    const [isModalOpen, { setTrue: openModal, setFalse: hideModal }] = useBoolean(false);

    useEffect(() => {
        props.modalStatus.show ? openModal() : hideModal();
    }, [props.modalStatus]);

    useEffect(() => {
        !isModalOpen && props.closeModal && props.closeModal();
    }, [isModalOpen]);

    const cancelIcon: IIconProps = { iconName: 'Cancel' };

    return props.children ? (
        <Modal
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={false}
            containerClassName={contentStyles().container}
            dragOptions={props.isDraggable ? dragOptions : undefined}
        >
            <div className={contentStyles(topColor).header}>
                <span>{props.title}</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={hideModal}
                />
            </div>
            <div className={contentStyles().body}>{props.children}</div>
        </Modal>
    ) : null;
};

export default Modals;
