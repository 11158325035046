import React, { useState, useContext } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { useTranslation } from 'react-i18next';

import { MainWrapper, columnProps, footerProps } from './CreateNewLocation.styles';
import { ICreateNewLocationProps } from './CreateNewLocation.interfaces';

import { validateFields, validateLatLongDuringInput, latLongToGeoJson } from './core/validateFields';
import { Checkbox, DatePicker } from '@fluentui/react';
import { LocationsContext } from '../../Locations.context';
import { useParams } from 'react-router-dom';
import { commonTools } from '../../../Layers/layers';
import useDayPickerStrings from '../../../../constants/useDayPickerStrings';
import { DateTime } from 'luxon';
/**
 * Компонент для добавления новой локации.
 *
 * @param onOKClick Внешний обработчик кнопки ОК.
 */
const CreateNewLocation = ({ onOKClick }: ICreateNewLocationProps) => {
    const DAY_PICKER_STRINGS = useDayPickerStrings();
    const { locationsById } = useContext(LocationsContext);
    const [dateFrom, setDateFrom] = React.useState<Date>(new Date());
    const params = useParams();
    const { t } = useTranslation();
    const [name, setName] = useState('');
    const [cloneId, setCloneId] = useState('');
    const [latLong, setLatLong] = useState('');
    const [cloneChecked, setCloneChecked] = useState(false);

    const disabled = !validateFields({ name, latLong }) || (cloneChecked && (!cloneId || !locationsById[cloneId]));

    const onNameChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setName(newValue || '');
    };

    const onCloneIdChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setCloneId(newValue || '');
    };

    const onLatLongChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const lastSymbol: string = newValue ? newValue.charAt(newValue.length - 1) : '';
        newValue !== undefined && validateLatLongDuringInput(lastSymbol) && setLatLong(newValue || '');
    };

    const onCreateClick = () => {
        const point = latLongToGeoJson(latLong);
        const latitude = point.coordinates[0];
        const longitude = point.coordinates[1];

        params.projectId &&
            onOKClick({
                name,
                latitude,
                longitude,
                date_from: DateTime.fromJSDate(dateFrom).toFormat('yyyy-MM-dd'),
                project_id: Number(params.projectId),
            });
    };

    const onCloneCheckChange = (
        ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
        checked?: boolean | undefined,
    ) => {
        if (checked !== undefined) {
            setCloneChecked(() => !cloneChecked);
        }
    };

    const onParseDateFromString = React.useCallback(
        (mode: string) =>
            (newValue: string): Date => {
                return commonTools.getDateFromString({ value: dateFrom, newValue });
            },
        [dateFrom],
    );

    return (
        <MainWrapper>
            <Stack {...columnProps}>
                {/* <Checkbox
                    checked={cloneChecked}
                    label={t('Clone from existing location')}
                    onChange={onCloneCheckChange}
                />
                {cloneChecked && (
                    <TextField
                        label={t('Clone from location id')}
                        value={cloneId}
                        onChange={onCloneIdChange}
                        required
                    />
                )} */}
                <TextField label={t('Name')} value={name} onChange={onNameChange} required />
                <TextField label={t('Latitude, Longitude')} value={latLong} onChange={onLatLongChange} required />
                <DatePicker
                    allowTextInput
                    label={t('Start date')}
                    ariaLabel="Select a date. Input format is day slash month slash year."
                    value={dateFrom}
                    onSelectDate={setDateFrom as (date?: Date | undefined | null) => void}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={onParseDateFromString('date_from')}
                    strings={DAY_PICKER_STRINGS}
                />
            </Stack>
            <Stack {...footerProps}>
                <Stack.Item align={'end'}>
                    <PrimaryButton text={t('Create')} onClick={onCreateClick} allowDisabledFocus disabled={disabled} />
                </Stack.Item>
            </Stack>
        </MainWrapper>
    );
};

export default CreateNewLocation;
