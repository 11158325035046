import React, { createContext } from 'react';

import { IAddNewLocationArgs, IHandleCreateNewLocationArgs, ILocation } from './Locations.interfaces';
interface ILocationsContext {
    readOnly: boolean;
    filteredLocationsList?: object[];
    locationsById: { [x: string]: ILocation };
    changeActiveLocationIdTrans?: (arg: string | number) => void;
    setEditLocationModalStatusTrans?: (arg: string) => void;
}

export const LocationsContext = createContext<ILocationsContext>({
    locationsById: {},
    readOnly: true,
});
