import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import useImage from 'use-image';
import { Circle, Image, Group } from 'react-konva';

import { reducerValues, moveObject } from '../../../../reducer';
import { IWiFiSensorObjectProps } from './Object.interfaces';
import { commonTools, ObjectLabel } from '../../../../../../layers';
import Icon from './components/assets/wifi';
import { theme } from '../../../../../../../../constants/theme';

/**
 * Компонент объект WiFi сенсор
 */
const RadioSensorObject = (props: IWiFiSensorObjectProps) => {
    const {
        coords,
        planScale = 1,
        stageScale,
        onClick,
        selected,
        marker = '',
        front_id = '',
        activeToolId,
        is_active,
        sensor_types,
        wifi_scanner_radius,
        onMouseDown,
    } = props;
    const dispatch = useDispatch();
    const {
        showLabels,
        generalSettings: { readOnly },
    } = useSelector(reducerValues);
    const url = 'data:image/svg+xml;base64,' + window.btoa(Icon);
    const [image] = useImage(url);

    const onObjectClick = () => {
        onClick(front_id);
    };

    const onCentralDragMove = (e: KonvaEventObject<MouseEvent>) => {
        const { x, y } = e.target.attrs;
        const newCoords = [x, y];
        newCoords && dispatch(moveObject({ front_id, newCoords }));
    };
    const _onMouseDown = (e: any) => {
        // onObjectClick()
        onMouseDown(front_id, e);
    };


    const centralCircle = () => {
        const foo = (num: number) => commonTools.scaleTransformFoo(num, stageScale);
        const radius = foo(10);
        const iconSize = 1.5 * radius;
        const wifiScannerRadius = wifi_scanner_radius * planScale;

        return (
            <Group
                onMouseDown={_onMouseDown}
                // onMouseUp={onMouseUp}
            >
                {sensor_types.includes('wifi_mac_sensor') && (
                    <Circle
                        x={coords[0]}
                        y={coords[1]}
                        radius={wifiScannerRadius}
                        opacity={selected ? 0.3 : 0.1}
                        // strokeWidth={selected ? 2 : 1}
                        fill={is_active ? theme.palette.themePrimary : theme.palette.orangeLight}
                        // stroke={'black'}
                    />
                )}
                <Circle
                    x={coords[0]}
                    y={coords[1]}
                    radius={radius}
                    opacity={selected ? 1 : 0.6}
                    fill={is_active ? theme.palette.themePrimary : theme.palette.orangeLight}
                />
                <Circle
                    x={coords[0]}
                    y={coords[1]}
                    radius={radius}
                    strokeWidth={selected ? foo(2) : foo(1)}
                    stroke={'black'}
                />
                <Image
                    width={iconSize}
                    height={iconSize}
                    x={coords[0] - iconSize / 2}
                    y={coords[1] - iconSize / 2}
                    image={image}
                />
                <Circle
                    x={coords[0]}
                    y={coords[1]}
                    radius={radius}
                    fill={'transparent'}
                    draggable={selected && !readOnly}
                    onClick={onObjectClick}
                    // onMouseMove={onCentralDragMove}
                />

                {showLabels && (
                    <ObjectLabel
                        stageScale={stageScale}
                        x={coords[0]}
                        y={coords[1]}
                        marker={marker}
                        selected={selected}
                    />
                )}
            </Group>
        );
    };

    return centralCircle();
};

export default RadioSensorObject;
