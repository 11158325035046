import { DateTime } from 'luxon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IProjectLocation } from '../../../../../../Locations/Locations.interfaces';
import { locationsReducerValues } from '../../../../../../Locations/Locations.reducer';
import { FieldName, Li, LocationDataWrapper, Ul } from './styles';

const LocationData: React.FC = () => {
    const { activeProjectLocation } = useSelector(locationsReducerValues);
    const { t } = useTranslation();

    const fields = {
        created_at: 'Created at',
        cached_at: 'Cashed at',
        updated_at: 'Updated at',
        synced_at: 'Synced at',
    };

    const list = (
        <Ul>
            {Object.entries(fields).map((entry) => {
                const [key, value] = entry;
                const src = activeProjectLocation?.[key as keyof IProjectLocation] ?? '';
                const dateString = DateTime.fromISO(String(src)).toFormat('y-MM-dd HH:mm:ss');

                return (
                    <Li key={`${t(key)}${t(value)}`}>
                        <FieldName> {`${t(value)}: `}</FieldName>
                        {dateString}
                    </Li>
                );
            })}
        </Ul>
    );

    return <LocationDataWrapper>{list}</LocationDataWrapper>;
};

export default LocationData;
