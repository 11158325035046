import { ContextualMenu } from '@fluentui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { deleteAnchor, deleteNavPolygon, navPolygonsValues, toggleContextMenu } from '../../navPolygons.reducer';

/**
 * Компонент Контекстного меню
 */
const ContextMenu = () => {
    const MIN_NUMBER_OF_ANCHORS = 3;
    const { contextMenu, navPolygonFrontId } = useSelector(navPolygonsValues);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onHideContextualMenu = () => {
        dispatch(toggleContextMenu({ show: false }));
    };

    const menuItems = useMemo(() => {
        const result = [
            {
                key: 'delete_el',
                text: t('Delete nav polygon'),
                onClick: () => {
                    navPolygonFrontId && dispatch(deleteNavPolygon({ navPolygonFrontId }));
                }
            }
        ];

        if (contextMenu.pointsNum && contextMenu.pointsNum > MIN_NUMBER_OF_ANCHORS) {
            result.unshift({
                key: 'delete_anchor',
                text: t('Delete anchor'),
                onClick: () => {
                    navPolygonFrontId &&
                        contextMenu.anchorIndex !== undefined &&
                        dispatch(deleteAnchor({ navPolygonFrontId, anchorIndex: contextMenu.anchorIndex }));
                }
            });
        }

        return result;
    }, [contextMenu.anchorIndex, contextMenu.pointsNum, navPolygonFrontId]);

    return menuItems ? (
        <ContextualMenu
            items={menuItems}
            hidden={!contextMenu.show}
            target={contextMenu.cursor}
            onItemClick={onHideContextualMenu}
            onDismiss={onHideContextualMenu}
        />
    ) : null;
};

export default ContextMenu;
