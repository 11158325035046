import styled from 'styled-components/macro';
import { Stack, IStackProps, IStackTokens } from '@fluentui/react/lib/Stack';
import { mergeStyleSets, getTheme, normalize } from '@fluentui/react/lib/Styling';
import { theme } from '../../../../../../../../constants/theme';


export const ObjectOptionsWrapper = styled.div``;
export const StyledStack = styled(Stack)`
    .ms-TextField-fieldGroup {
        background-color: transparent;
    }
    label {
        padding-left: 0px;
    }
`;


export const styles = mergeStyleSets({
    container: {
        overflow: 'auto',
        maxHeight: 115,
        border: '1px solid #CCC',
        marginTop: 20,
        selectors: {
            '.ms-List-cell:nth-child(odd)': {
                // height: 50,
                lineHeight: 25,
                height: 25,
                background: theme.palette.neutralLighter,
                '&:hover': { background: theme.palette.neutralLight },
            },
            '.ms-List-cell:nth-child(even)': {
                // height: 25,
                lineHeight: 25,
                height: 25,
                '&:hover': { background: theme.palette.neutralLight },
            },
        },
    },
    itemContent: [
        theme.fonts.medium,
        normalize,
        {
            position: 'relative',
            boxSizing: 'border-box',
            display: 'block',
            borderLeft: '3px solid ' + theme.palette.themePrimary,
            paddingLeft: 27,
        },
    ],
});

export const stylesSelected = mergeStyleSets({
    container: {
        overflow: 'auto',
        maxHeight: 200,
        border: '1px solid #CCC',
        marginTop: 20,
        selectors: {
            '.ms-List-cell:nth-child(odd)': {
                height: 25,
                lineHeight: 50,
                background: theme.palette.neutralLighter,
            },
            '.ms-List-cell:nth-child(even)': {
                height: 25,
                lineHeight: 25,
            },
        },
    },
    itemContent: [
        theme.fonts.medium,
        normalize,
        {
            position: 'relative',
            boxSizing: 'border-box',
            display: 'block',
            borderLeft: '3px solid ' + theme.palette.themePrimary,
            paddingLeft: 27,
            background: 'rgba(0,0,0,.5)',
            color: 'white'
        },
    ],
});
