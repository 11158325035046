import React, { useContext, useEffect, useState } from 'react';
import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { DatePicker } from '@fluentui/react/lib/DatePicker';
import { PrimaryButton } from '@fluentui/react/lib/Button';

import { useTranslation } from 'react-i18next';

import { PlansContext } from '../../Plans.context';

import { MainWrapper, columnProps, footerProps, LabelWrapper, FileLabel, ChooseFile, ListWrapper } from './styles';
import { parseDateFromString } from './core/parseDateFromString';
import { getImageFile } from './core/getImageFile';
import { MAX_PLAN_IMG_SIZE_MB } from '../../../../constants/files';
import useDayPickerStrings from '../../../../constants/useDayPickerStrings';
import { commonTools } from '../../../../tools/commonTools';
import { useDispatch } from 'react-redux';
import { fetchPdfPlans } from '../../Plans.reducer';
import List from './components/List/List';
import { DayOfWeek } from '@fluentui/date-time-utilities';
import { useAppDispatch } from '../../../../store';

/**
 * Компонент для добавления нового плана.
 *
 * @param onOKClick Внешний обработчик кнопки ОК.
 */
const UploadPdf = ({ onOKClick, floor }: any) => {
    const { activeLocation } = useContext(PlansContext);
    const DAY_PICKER_STRINGS = useDayPickerStrings();
    const { t } = useTranslation();
    const [actualFrom, setActualFrom] = useState(new Date());
    // const [floor, setFloor] = useState('1');
    const [file, setFile] = useState('');
    const [imageFileName, setImageFileName] = useState('');
    const [notes, setNotes] = useState('');
    const dispatch = useAppDispatch();

    useEffect(() => {
        activeLocation?.id && dispatch(fetchPdfPlans(+activeLocation.id));
    }, [activeLocation?.id]);

    const firstDayOfWeek = DayOfWeek.Monday;

    const disabled = file === '' || floor === '';

    // const onFloorChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
    //     newValue !== undefined && setFloor(newValue || '');
    // };
    const onNotesChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        newValue !== undefined && setNotes(newValue || '');
    };

    const onUploadClick = () => {
        onOKClick({
            floor: Number(floor),
            file,
            actualFrom: actualFrom.toISOString(),
            notes,
            locationId: activeLocation?.id,
        });
    };

    const onFileChange = (e: React.FormEvent<HTMLInputElement>) => {
        const files = e.currentTarget.files;
        const { fileName, image } = getImageFile(files, MAX_PLAN_IMG_SIZE_MB, t('File too large'));

        fileName && setImageFileName(fileName);
        image && setFile(image);
    };

    const onSelectDate = (date: Date | null | undefined) => {
        date && setActualFrom(date);
    };

    return (
        <MainWrapper>
            <ListWrapper>
                <List />
            </ListWrapper>
            <Stack {...columnProps}>
                <TextField
                    label={t('Floor')}
                    value={floor}
                    disabled
                    // onChange={onFloorChange}
                    type={'number'}
                    min={-1000}
                />
                <input
                    accept="application/pdf"
                    onChange={onFileChange}
                    style={{ display: 'none' }}
                    id="raised-button-file"
                    type="file"
                />
                <LabelWrapper>
                    <FileLabel className="FileLabel" htmlFor="raised-button-file">
                        <ChooseFile>{t('Choose PDF floor plan')}</ChooseFile>
                    </FileLabel>
                </LabelWrapper>

                {file && <iframe src={`${file}`} title="File to upload" height="300px" width="100%"></iframe>}

                {imageFileName && <span>{imageFileName}</span>}
                <DatePicker
                    label={t('Plan is actual from')}
                    isRequired={true}
                    allowTextInput={true}
                    firstDayOfWeek={firstDayOfWeek}
                    strings={DAY_PICKER_STRINGS}
                    value={actualFrom}
                    onSelectDate={onSelectDate}
                    formatDate={commonTools.onFormatDate}
                    parseDateFromString={parseDateFromString}
                />
                <TextField label={t('Notes (optional)')} value={notes} multiline rows={5} onChange={onNotesChange} />
            </Stack>
            <Stack {...footerProps}>
                <Stack.Item align={'end'}>
                    <PrimaryButton text={t('Upload')} onClick={onUploadClick} allowDisabledFocus disabled={disabled} />
                </Stack.Item>
            </Stack>
        </MainWrapper>
    );
};

export default UploadPdf;
