import React from 'react';
import {
    BoxTable,
    TableComponentST,
    ItemHeaderComponent,
    ItemColumnComponent,
    FirstRow,
    BodyTable,
    FirstColumn,
    ItemBodyComponent,
    ItemColumnDetailComponent,
    ItemColumnDetailNameComponent,
    ItemColumnDetailFirstComponent
} from './TableComponent.styles';
import {IZone} from "../../../../../zones.interfaces";



const TableComponent = ({...props}) => {

    const {
        objFloorsAndGroupZones,
        groupDataByMarkerGroup
    } = props;

    let componentNodeFloors:Array<JSX.Element> = [];

    let componentNodeItemBodyGlobal:Array<JSX.Element> = [];
    const arrayGroupZonesKeys = Object.keys(objFloorsAndGroupZones.groups);

    objFloorsAndGroupZones?.floors?.forEach((item:{
        floor: number,
        statusCurrent: boolean
    }, index: number) => {

        componentNodeFloors.push(
            (<ItemColumnComponent key={`floors${index}`}>
                <ItemColumnDetailFirstComponent
                    statusCurrent={item.statusCurrent}
                >
                    {`floor ${item.floor}`}
                </ItemColumnDetailFirstComponent>

            </ItemColumnComponent>)
        )


    })

    arrayGroupZonesKeys.forEach((itemGroups1, indexGroups1) => {
        let componentNodeItemBody:Array<JSX.Element> = [];
        objFloorsAndGroupZones?.floors?.forEach((item:{
            floor: number,
            statusCurrent: boolean
        }, index: number) => {

            let componentZonesNodeFloor:Array<JSX.Element> = [];
            if (objFloorsAndGroupZones.groups[itemGroups1][item.floor]) {
                objFloorsAndGroupZones.groups[itemGroups1][item.floor].forEach((itemTemp:IZone, indexTemp: number) => {
                    componentZonesNodeFloor.push(
                        <ItemColumnDetailNameComponent key={`zonesForFloor${indexTemp}`}>
                            {`${itemTemp.zone_name}(${itemTemp.zone_marker})`}

                        </ItemColumnDetailNameComponent>
                    )
                })

            }

            componentNodeItemBody.push(
                <ItemColumnDetailComponent key={`floor@@@@${index}`}>
                    {componentZonesNodeFloor}
                </ItemColumnDetailComponent>
            )



        });
        componentNodeItemBodyGlobal.push(
            <ItemBodyComponent key={`sdsweewerwer${indexGroups1}`}>
                {componentNodeItemBody}
            </ItemBodyComponent>
        )
    })



    const componentNodeGroups = arrayGroupZonesKeys.map((itemGroup, indexGroup) => {
        return(<ItemHeaderComponent key={`groupZones${indexGroup}`}>{`${groupDataByMarkerGroup[itemGroup].group_name}(${itemGroup})`}</ItemHeaderComponent>)
    })


    return (
        <TableComponentST>
            <BoxTable>
                <FirstRow>
                    <ItemHeaderComponent key={`groupZones000`}></ItemHeaderComponent>
                    {componentNodeGroups}
                </FirstRow>
                <FirstColumn>
                    {componentNodeFloors}
                </FirstColumn>
                <BodyTable>
                    {/*<ItemBodyComponent>*/}
                    {/*    <ItemColumnDetailComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}

                    {/*    </ItemColumnDetailComponent>*/}
                    {/*    <ItemColumnDetailComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*    </ItemColumnDetailComponent>*/}
                    {/*    <ItemColumnDetailComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*    </ItemColumnDetailComponent>*/}
                    {/*</ItemBodyComponent>*/}
                    {/*<ItemBodyComponent>*/}
                    {/*    <ItemColumnDetailComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}

                    {/*    </ItemColumnDetailComponent>*/}
                    {/*    <ItemColumnDetailComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*    </ItemColumnDetailComponent>*/}
                    {/*    <ItemColumnDetailComponent>*/}
                    {/*        <ItemColumnDetailNameComponent>*/}
                    {/*            children(children)*/}
                    {/*        </ItemColumnDetailNameComponent>*/}
                    {/*    </ItemColumnDetailComponent>*/}
                    {/*</ItemBodyComponent>*/}
                    {/*<ItemBodyComponent>*/}

                    {/*</ItemBodyComponent>*/}
                    {componentNodeItemBodyGlobal}
                </BodyTable>
            </BoxTable>
        </TableComponentST>
    );
}

export default TableComponent;