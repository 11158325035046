import tools from './components/Tools';
import draw from './components/Draw';
import modal from './components/Modal';
import contextMenu from './components/ContextMenu';
import controls from './components/Controls';
import { initialState } from './reducer';

export const sensorsMonitoringLayer = {
    alias: initialState.layerAlias,
    tools,
    modal,
    contextMenu,
    draw,
    controls,
};
