import { INodeContextMenu } from '../../../../tools/commonInterfaces';
import { IGeneralSettings, IPlan, IVersionsData } from '../../layers.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from './store';
import { commonTools } from '../../layers';

import { IEscalatorsReducer, IEscalator, IHotAddObject } from './interfaces';
import { changeOtherLayers } from './components/core/changeOtherLayers';
import { deleteObjectFromOtherLayers } from './components/core/deleteObjectFromOtherLayers';
import { getEscalatorArrowPoint } from './components/core/getEscalatorArrowPoint';
import { getPassPoint } from './components/core/getPassPoint';
import { IObjects } from '../Checkouts/checkouts.interfaces';

export const initialState: IEscalatorsReducer = {
    layerAlias: 'escalators_layer',
    activeToolId: null,
    selectedObjectId: null,
    selectedPassPointId: null,
    createdAt: undefined,
    objects: [],
    allTheFloors: [],
    plansList: [],
    currentPlanData: null,
    showLabels: false,
    versionsData: null,
    generalSettings: {} as IGeneralSettings,
    isNamesValid: { valid: true },
    anotherLocationNames: { markers: [], names: [] },
    anotherLocationLayers: [],
    contextMenu: { show: false },
    hotAddObject: null,
};

export const EscalatorsReducer = createSlice({
    name: 'EscalatorsReducer',
    initialState,
    reducers: {
        /**
         * Изменение этажа объекта
         */
        changeObjectFloors: (state, action: PayloadAction<{ checked: boolean; floor: number; front_id: string }>) => {
            const { front_id, floor, checked } = action.payload;
            const { objects, plansList, currentPlanData, allTheFloors, anotherLocationLayers } = cloneDeep(state);
            if (!currentPlanData) return;
            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    if (item.floors.includes(floor)) {
                        if (!checked) {
                            const escalator = { ...item, floors: item.floors.filter((fl) => fl !== floor) };
                            state.anotherLocationLayers = changeOtherLayers({
                                currentPlanData,
                                allTheFloors,
                                escalator,
                                plansList,
                                anotherLocationLayers,
                            }) as { [x: string]: any }[];
                            return escalator;
                        } else return item;
                    } else {
                        const escalator = { ...item, floors: [currentPlanData.floor, floor] };
                        state.anotherLocationLayers = changeOtherLayers({
                            currentPlanData,
                            allTheFloors,
                            escalator,
                            plansList,
                            anotherLocationLayers,
                        }) as { [x: string]: any }[];
                        return escalator;
                    }
                }
            });

            state.objects = newObjects;
        },

        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string | null>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Сохранение нового объекта в сторе
         */
        storeNewObject: (state, action: PayloadAction<IEscalator | null>) => {
            const { objects, currentPlanData, allTheFloors } = cloneDeep(state);

            if (!currentPlanData || !action.payload) return;

            const currentFloor = currentPlanData.floor;

            const anotherLocationNames = cloneDeep(state.anotherLocationNames);

            const currentMarkers = objects.map((item) => item.marker.split('✚')[0]);
            const currentNames = objects.map((item) => item.name.split('✚')[0]);

            const nextMarkerNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.markers.map((item) => item.split('✚')[0]), ...currentMarkers],
                'escalator',
            );

            const nextNameNumber = commonTools.getNextMarkerNumber(
                [...anotherLocationNames.names.map((item) => item.split('✚')[0]), ...currentNames],
                'Escalator',
            );

            const activeVersionId = cloneDeep(state.versionsData?.activeVersionId);
            const marker = `v${String(activeVersionId)}:escalator${nextMarkerNumber}`;
            const name = 'Escalator' + nextNameNumber;
            const front_id = `v${String(activeVersionId)}:escalator:${commonTools.generateId()}`;

            const generatePassPoinCoords = () => {
                const coords = action.payload?.line.coordinates;
                if (!coords) return [0, 0];
                const arrowStart = getEscalatorArrowPoint(coords[0], coords[1], currentPlanData.planScale || 1);
                const arrowEnd = getEscalatorArrowPoint(coords[1], coords[0], currentPlanData.planScale || 1);
                const inOutPoint = action?.payload?.in_out === 'in' ? arrowEnd : arrowStart;
                const result = getPassPoint(inOutPoint, coords, currentPlanData.planScale || 1);
                return result;
            };

            const newObject: IEscalator = {
                ...action.payload,
                marker,
                passPoints: {
                    [`escalator_passpoint:${commonTools.generateId()}`]: {
                        geometry: { type: 'Point', coordinates: generatePassPoinCoords() },
                        lineName: `Линия 0`,
                    },
                },
                name,
                front_id,
                floors: [currentFloor],
            };
            objects.push(newObject as IEscalator);
            state.objects = objects;
            state.selectedObjectId = front_id;
        },

        /**
         * Выбор объекта
         */
        selectObject: (state, action: PayloadAction<string | undefined>) => {
            if (action.payload === undefined) {
                state.selectedObjectId = null;
            } else {
                state.selectedObjectId = action.payload;
            }
        },

        /**
         * Удаление объекта
         */
        deleteObject: (state, action: PayloadAction<string | undefined>) => {
            const { objects, anotherLocationLayers } = cloneDeep(state);
            if (action.payload !== undefined) {
                state.objects = objects.filter((item) => item.front_id !== action.payload);
                state.anotherLocationLayers = deleteObjectFromOtherLayers(action.payload, anotherLocationLayers) as {
                    [x: string]: any;
                }[];
            }
        },

        /**
         * Удаление узелка
         */
        deleteAnchor: (state, action: PayloadAction<{ elementId: string; anchorIndex: number }>) => {
            if (action.payload !== undefined) {
                const objects = cloneDeep(state.objects).map((item) => {
                    if (item.front_id !== action.payload.elementId) {
                        return item;
                    } else {
                        const coordinates = cloneDeep(item.line.coordinates);
                        coordinates.splice(action.payload.anchorIndex, 1);
                        const result = { ...item, line: { ...item.line, coordinates } };
                        return result;
                    }
                });
                state.objects = objects;
            }
        },

        /**
         * Удаление узелка
         */
        toggleContextMenu: (state, action: PayloadAction<INodeContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Изменение параметров объекта
         */
        changeObjectParams: (
            state,
            action: PayloadAction<{ key: keyof IEscalator; newValue: string | boolean | undefined; front_id: string }>,
        ) => {
            const { front_id, key, newValue } = action.payload;
            const { objects, currentPlanData, anotherLocationNames, plansList, anotherLocationLayers, allTheFloors } =
                cloneDeep(state);
            if (!currentPlanData) return;
            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    let value;
                    let floors = item.floors;
                    switch (key) {
                        case 'width':
                            value = Number(newValue);
                            break;
                        case 'in_out':
                            value = newValue;
                            floors = [currentPlanData.floor];
                            break;
                        case 'direction':
                            value = newValue;
                            floors = [currentPlanData.floor];
                            break;

                        default:
                            value = newValue;
                            break;
                    }
                    const escalator = { ...item, [key]: value, floors };
                    const newAnother = changeOtherLayers({
                        currentPlanData,
                        allTheFloors,
                        escalator,
                        plansList,
                        anotherLocationLayers,
                    }) as { [x: string]: any }[];

                    state.anotherLocationLayers = newAnother;
                    return escalator;
                }
            });
            state.objects = newObjects;
            if (key === 'marker' || key === 'name') {
                state.isNamesValid = commonTools.validateCrossFloorMarkersAndNames(newObjects, anotherLocationNames);
            }
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<Array<IEscalator>>) => {
            /**
             * Для обратной совместимости
             */
            const modifyEscalators = (arr: IEscalator[] | null | undefined) => {
                return Array.isArray(arr)
                    ? arr.map((escalator) => {
                          if (escalator.external_traffic === undefined) {
                              return { ...escalator, external_traffic: false };
                          } else {
                              return escalator;
                          }
                      })
                    : [];
            };

            state.objects = modifyEscalators(action.payload) || [];
        },

        /**
         * Запись в стор загруженных с сервера объектов
         */
        storePlansList: (state, action: PayloadAction<Array<IPlan>>) => {
            state.plansList = action.payload || [];
            const allTheFloors = action.payload
                .filter((item: IPlan) => item.isActual)
                .sort((a: IPlan, b: IPlan) => Number(a.floor) - Number(b.floor))
                .map((item: IPlan) => item.floor);
            state.allTheFloors = allTheFloors || [];
        },

        /**
         * Запись в стор поля createdAt
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        /**
         * Изменение положения узла
         */
        moveAnchor: (
            state,
            action: PayloadAction<{ pointIndex: number; front_id: string; newCoords: Array<number> }>,
        ) => {
            const { currentPlanData, allTheFloors, plansList, anotherLocationLayers } = cloneDeep(state);
            if (!currentPlanData) return;
            const { pointIndex, front_id, newCoords } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const { line } = item;
                    const coordinates = line.coordinates.map((point, i) => {
                        if (pointIndex !== i) {
                            return point;
                        } else {
                            return newCoords;
                        }
                    });
                    line.coordinates = coordinates;
                    const escalator = { ...item, line };
                    state.anotherLocationLayers = changeOtherLayers({
                        currentPlanData,
                        allTheFloors,
                        escalator,
                        plansList,
                        anotherLocationLayers,
                    }) as { [x: string]: any }[];
                    return escalator;
                }
            });
            state.objects = objects;
        },

        /**
         * Сохранение в стор всех имен зи других подобный слоев текущей локации.
         */
        storeAnotherLocationNames: (state, action: PayloadAction<Array<object>>) => {
            const markers: string[] = [];
            const names: string[] = [];

            action?.payload.forEach((item: { data?: Array<{ marker: string; name: string; front_id: string }> }) => {
                if (!item.data) return;
                item?.data.forEach((obj: { marker: string; name: string; front_id: string }) => {
                    markers.push(`${obj.marker}✚${obj.front_id}`);
                    names.push(`${obj.name}✚${obj.front_id}`);
                });
            });
            const anotherLocationNames = { markers, names };
            state.anotherLocationNames = anotherLocationNames;
        },

        /**
         * Сохранение в стор всех слоев текущей локации.
         */
        storeAnotherLocationLayers: (state, action: PayloadAction<Array<object>>) => {
            state.anotherLocationLayers = action.payload;
        },

        /**
         * Добавление узелка.
         */
        addAnchor: (state, action: PayloadAction<{ front_id: string; newPoint: number[] }>) => {
            const { front_id, newPoint } = action.payload;
            const objects = cloneDeep(state.objects).map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const coordinates = cloneDeep(item.line.coordinates);
                    let index: null | number = null;
                    coordinates.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, newPoint)) {
                                index = i;
                            }
                        }
                    });
                    if (index !== null) {
                        coordinates.splice(index, 0, newPoint);
                    }
                    const result = { ...item, passLine: { ...item.line, coordinates } };
                    return result;
                }
            });
            state.objects = objects;
        },
        /**
         * выбор PassPoint
         */
        selectPassPoint: (state, action: PayloadAction<{ passPointId: string }>) => {
            const { passPointId } = action.payload;
            state.selectedPassPointId = passPointId;
        },

        /**
         * Изменение положения PassPoint
         */
        movePassPoint: (
            state,
            action: PayloadAction<{ passPointId: string; front_id: string; newCoords: Array<number> }>,
        ) => {
            const { passPointId, front_id, newCoords } = action.payload;
            const temp = cloneDeep(state.objects) || [];
            const objects = temp.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const { passPoints } = item;
                    const type: 'Point' = 'Point';
                    const geometry = { type, coordinates: newCoords };
                    passPoints[passPointId] = { ...passPoints[passPointId], geometry };
                    return { ...item, passPoints };
                }
            });
            state.objects = objects;
        },

        /**
         * Изменение Имени уса
         */
        renamePassPoint: (
            state,
            action: PayloadAction<{ passPointId: string; newValue: string | undefined; front_id: string }>,
        ) => {
            const { passPointId, front_id, newValue } = action.payload;
            const objects = cloneDeep(state.objects) || [];
            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id || newValue === undefined) {
                    return item;
                } else {
                    const { passPoints } = item;
                    passPoints[passPointId] = {
                        ...passPoints[passPointId],
                        lineName: newValue,
                    };
                    return { ...item, passPoints };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Удаление PassPoint
         */
        deletePassPoint: (state, action: PayloadAction<{ passPointId: string; front_id: string }>) => {
            const { passPointId, front_id } = action.payload;

            let objects = cloneDeep(state.objects) || [];

            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const { passPoints } = item;
                    delete passPoints[passPointId];
                    return { ...item, passPoints };
                }
            });
            state.objects = newObjects;
        },

        /**
         * Запись в стор версионных данных
         */
        storeVersionsData: (state, action: PayloadAction<IVersionsData>) => {
            state.versionsData = action.payload;
        },

        /**
         * Добавление нового PassPoint
         */
        addPassPoint: (state, action: PayloadAction<{ front_id: string; planScale: number | undefined }>) => {
            const { front_id, planScale } = action.payload;
            if (!planScale) return;

            let objects = cloneDeep(state.objects) || [];

            const newObjects = objects.map((item) => {
                if (item.front_id !== front_id) {
                    return item;
                } else {
                    const { passPoints, line, in_out } = item;
                    const currentPassPointsNumber = Object.keys(passPoints).length;
                    const angle = Math.PI / 2 - (currentPassPointsNumber * Math.PI) / 6;

                    const arrowStart = getEscalatorArrowPoint(line.coordinates[0], line.coordinates[1], planScale || 1);
                    const arrowEnd = getEscalatorArrowPoint(line.coordinates[1], line.coordinates[0], planScale || 1);
                    const inOutPoint = in_out === 'in' ? arrowEnd : arrowStart;

                    const end = [
                        inOutPoint[0] + 2 * planScale * Math.cos(angle),
                        inOutPoint[1] - 2 * planScale * Math.sin(angle),
                    ];
                    const newId = `passPoint:${commonTools.generateId()}`;

                    const allLineNames = Object.keys(passPoints).map((key) => {
                        return passPoints[key].lineName;
                    });

                    passPoints[newId] = {
                        geometry: { type: 'Point', coordinates: end },
                        lineName: commonTools.getNextMarkerNumber(allLineNames, 'Линия '),
                    };

                    return { ...item, passPoints };
                }
            });
            state.objects = newObjects;
        },
        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjectId = null;
            state.selectedPassPointId = null;
        },

        /**
         * Изменение координат выделеного объекта
         * @param state
         * @param action
         */
        changeCoords: (
            state,
            action: PayloadAction<{
                coordsS: number[][] | undefined;
                front_id: string | null;
                _coordsLine: { [x: string]: number[] };
            }>,
        ) => {
            const { objects, plansList, currentPlanData, allTheFloors, anotherLocationLayers } = cloneDeep(state);
            if (!currentPlanData) return;
            const { coordsS, front_id, _coordsLine } = action.payload;

            const index = objects.findIndex((item) => item.front_id === front_id);

            if (index !== -1) {
                objects[index] = {
                    ...objects[index],
                    line: {
                        ...objects[index].line,
                        coordinates: coordsS!,
                    },
                };

                // Не двигать усы эскалатора попросили УД

                // Object.keys(objects[index].passPoints).forEach((item2, i2) => {
                //     objects[index].passPoints[item2].geometry.coordinates = _coordsLine[item2];
                // });
            }

            state.objects = objects;

            state.anotherLocationLayers = changeOtherLayers({
                currentPlanData,
                allTheFloors,
                escalator: objects[index],
                plansList,
                anotherLocationLayers,
            }) as { [x: string]: any }[];
        },
        changeCoordsFullLayer: (state, action: PayloadAction<IEscalator[]>) => {
            const { objects, plansList, currentPlanData, allTheFloors, anotherLocationLayers } = cloneDeep(state);

            if (!currentPlanData) return;
            state.objects = action.payload;
            action.payload.forEach((item, i) => {
                state.anotherLocationLayers = changeOtherLayers({
                    currentPlanData,
                    allTheFloors,
                    escalator: item,
                    plansList,
                    anotherLocationLayers,
                }) as { [x: string]: any }[];
            })

        },
    },
});

export const {
    renamePassPoint,
    deletePassPoint,
    toggleTool,
    movePassPoint,
    storeNewObject,
    selectObject,
    addPassPoint,
    deleteAnchor,
    deleteObject,
    changeObjectFloors,
    changeObjectParams,
    addAnchor,
    selectPassPoint,
    toggleContextMenu,
    storeInitialObjects,
    storePlansList,
    storeInitialCreatedAt,
    storeVersionsData,
    moveAnchor,
    storeCurrentPlanData,
    storeGeneralSettings,
    toggleShowLabels,
    storeAnotherLocationLayers,
    storeAnotherLocationNames,
    storeHotAddObject,
    clearSelectedObjects,
    changeCoords,
    changeCoordsFullLayer
} = EscalatorsReducer.actions;

export const reducerValues = (state: RootState) => state.EscalatorsReducer;

export default EscalatorsReducer.reducer;
