import React from 'react';
import { contentStyles, iconButtonStyles } from '../ObjectsList.styles';
import { IconButton, Modal, PrimaryButton, Stack } from '@fluentui/react';
import { TextField } from '@fluentui/react/lib/TextField';
import TableComponent from "./TableComponent";
import { useTranslation } from 'react-i18next';

/**
 * Компонент модального окна
 * @param props
 * @constructor
 */
const ContentModal = ({ ...props }) => {
    const {
        cancelIcon,
        onClickButtonHideModal,
        stackTokens,
        dataModal,
        onChangeNameGroupZones,
        onChangeMarkerGroupZones,
        onClickSaveGroupZones,
        contentType,
        objFloorsAndGroupZones,
        groupDataByMarkerGroup
    } = props;

    const { t } = useTranslation();

    if (contentType === 'newGroupZones') {
        return (
            <>
                <div className={contentStyles.header}>
                    <span>{t('New group zones')}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={onClickButtonHideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={stackTokens}>
                        <TextField label={'Name'} value={dataModal?.name} onChange={onChangeNameGroupZones} />
                        <TextField label={'Marker'} value={dataModal?.marker} onChange={onChangeMarkerGroupZones} />

                        <PrimaryButton text={'Save'} onClick={onClickSaveGroupZones} allowDisabledFocus />
                    </Stack>
                </div>
            </>
        );
    }
    else if (contentType === 'dataGlobalGroupZones') {
        return (
            <>
                <div className={contentStyles.header}>
                    <span>{t('Table')}</span>
                    <IconButton
                        styles={iconButtonStyles}
                        iconProps={cancelIcon}
                        ariaLabel="Close popup modal"
                        onClick={onClickButtonHideModal}
                    />
                </div>
                <div className={contentStyles.body}>
                    <Stack tokens={stackTokens}>
                        <TableComponent
                            objFloorsAndGroupZones={objFloorsAndGroupZones}
                            groupDataByMarkerGroup={groupDataByMarkerGroup}
                        />
                    </Stack>
                </div>
            </>
        );
    }
    return null;

};

export default ContentModal;
