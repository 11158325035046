import { createContext } from 'react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';

import { theme } from './constants/theme';
import { sizes } from './constants/sizes';
import { headersNames } from './constants/headers';

import { IGeneralContext } from './General.interfaces';

initializeIcons();

export const initialGeneralData: IGeneralContext = { theme, headersNames, token: null, sizes, showSpinner: false, urls: {} };

export const GeneralContext = createContext<IGeneralContext>({ ...initialGeneralData });
