import React, { useContext } from 'react';
import { useMemo } from 'react';
import { ILocation, IProjectLocation } from '../../../Locations/Locations.interfaces';
import { Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { controlPanelChaptersMap } from '../../../../constants/controlPanelChaptersMap';
import { projectsReducerValues } from '../../../Projects/reducer';
import { useParams } from 'react-router-dom';

/**
 * Компонент Имя активной локации.
 */
export const Title = ({
    currentLocation,
    selectedChapter,
}: {
    currentLocation: ILocation | null;
    selectedChapter: string;
}) => {
    const { projectsById } = useSelector(projectsReducerValues);
    const { t } = useTranslation();
    const { projectId, plId } = useParams();

    const projectName = useMemo(() => {
        return projectId ? `${projectsById?.[projectId]?.name} / ` : '';
    }, [projectId, projectsById]);

    const locationName = useMemo(() => {
        return currentLocation?.name
            ? `${currentLocation.name} / ${t(controlPanelChaptersMap[selectedChapter] || selectedChapter)}`
            : '';
    }, [currentLocation?.name, selectedChapter, t]);

    return <Wrapper>{`${projectName}${locationName}`}</Wrapper>;
};
