const Flatten = require('@flatten-js/core');
const turf = require('@turf/turf');
const tools = require('./tools');

const OVERLAP_FRACTION = 99;

function get_intersections({ stairsObjects, placesObjects, planScale }) {
    var intersections = [];

    const { Polygon } = Flatten;

    placesObjects.forEach((place) => {
        const placePolygon = tools.getPolygonForTurf(place.coords.coordinates, place.marker);
        stairsObjects.forEach((stairs) => {
            const stairsPolygonCoordinates = stairs.geometry.coordinates;
            const stairsPolygonArea = new Polygon(stairsPolygonCoordinates).area();
            const stairsPoly = tools.getPolygonForTurf(stairs.geometry.coordinates, stairs.marker);
            try {
                const polygonsIntersection = turf.intersect(placePolygon, stairsPoly);
                if (polygonsIntersection?.geometry?.coordinates) {
                    const intersectionsCoords = polygonsIntersection.geometry.coordinates[0];
                    const intersectionArea = new Polygon(intersectionsCoords).area();
                    const contentPercentage = (intersectionArea / stairsPolygonArea) * 100;
                    if (contentPercentage >= OVERLAP_FRACTION) {
                        const placePolygonCenter = tools.findPolygonCenter(place.coords.coordinates);
                        const stairsPolygonCenter = tools.findPolygonCenter(stairs.geometry.coordinates);
                        const info = {
                            stairsMarker: stairs.marker,
                            stairsName: stairs.name,
                            placeMarker: place.marker,
                        };
                        intersections.push({
                            coords: {
                                placePolygonCenter,
                                stairsPolygonCenter,
                            },
                            info,
                        });
                    }
                }
            } catch (err) {
                console.warn('get_intersections >>>>', err);
            }
        });
    });

    return intersections;
}

module.exports = { get_intersections };
