import { IconButton, IIconStyles } from '@fluentui/react';
import { Link } from 'react-router-dom';
import styled, { keyframes } from 'styled-components/macro';
import { sizes } from '../../constants/sizes';

function blinkingEffect() {
    return keyframes`
      50% {
        opacity: 0;
      }
    `;
}

export const HeaderWrapper = styled.section<{ bgcolor: string; color: string }>`
    height: ${sizes.HEADER_HEIGHT + 'px'};
    overflow: hidden;
    background-color: ${(p) => p.bgcolor};
    color: ${(p) => p.color};
    display: grid;
    grid-template-columns: auto auto auto;
`;

export const StIconButton = styled(IconButton)`
    margin-right: 10px;
    i {
        font-size: 18px;
    }
`;

export const SettingsButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content: flex-end;
    /* width: 100%; */
    box-sizing: border-box;
    padding: 9px 0;
    button {
        background-color: transparent !important;
        /* color: white !important; */
        font-family: inherit !important;
        font-size: inherit !important;
    }
    i {
        /* color: white !important; */
    }
`;

export const NavMenuWrapper = styled.div`
    width: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
`;

export const StyledLink = styled(Link)`
    padding: 0 10px 0 10px;
    color: whitesmoke;
`;

export const VersionsVarning = styled.div`
    animation: ${blinkingEffect} 0.5s ease-in-out infinite;
    display: flex;
    margin-left: 10px;
    align-items: center;
    font-weight: 700;
    /* justify-content: center; */
`;
export const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    .location {
        display: flex;
        align-items: center;
    }
`;
export const VersionData = styled.div`
    font-weight: 700;
    margin-left: 10px;
    display: flex;
    align-items: center;
`;

export const itemStyles = {
    root: [
        {
            selectors: {
                ':focus': {
                    outline: 'none',
                },
            },
        },
    ],
    label: { fontSize: 14 },
};

export const menuStyles = {
    root: { minWidth: 'auto', right: '0px' },
    subComponentStyles: { menuItem: itemStyles, callout: {} },
};
