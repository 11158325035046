export const parseDateFromString = (value: string) => {
    const date = new Date();
    const values = (value || '').trim().split('-');
    let year = values.length > 0 ? parseInt(values[0], 10) : date.getFullYear();
    const month = values.length > 1 ? Math.max(1, Math.min(12, parseInt(values[1], 10))) - 1 : date.getMonth();
    const day = values.length > 2 ? Math.max(1, Math.min(31, parseInt(values[2], 10))) : date.getDate();
    if (year < 100) {
        year += date.getFullYear() - (date.getFullYear() % 100);
    }
    return new Date(year, month, day);
};
