import styled from 'styled-components/macro';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IDropdownStyles } from '@fluentui/react/lib/Dropdown';

const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
});

export const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'greenyellow' }, iconClass],
    salmon: [{ color: 'salmon' }, iconClass],
});

export const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: '100%' } };

export const CommonDataWrapper = styled.div`
    width: 100%;
    height: auto;
    font-size: 12px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(161, 159, 157);
    animation-name: css-0;
    animation-duration: 0.267s;
    display: grid;
    grid-template-rows: 1fr;
    grid-gap: 3px;
    animation-timing-function: cubic-bezier(0.1, 0.25, 0.75, 0.9);
    animation-fill-mode: both;
`;

export const Row = styled.div`
    display: grid;
    grid-template-columns: 4fr 2fr;
    height: 38px;
    width: 100%;
`;

export const TextBox = styled.div`
    padding: 5px;
    display: flex;
    align-items: center;
`;

export const ValueBox = styled.div<{ bgColor: string }>`
    display: flex;
    height: 100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    padding: 5px;
    font-size: 14px;
    font-weight: 700;
    background-color: ${(p) => p.bgColor};
`;

export const Circle = styled.div`
    border: 1px solid #bbb;
    border-radius: 50%;
    width: 13px;
    height: 13px;
`;

export const ObjectWrapper = styled.div<{ selected: boolean }>`
    cursor: pointer;
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
