import * as _ from 'lodash';
import { IvalidateFieldsArgs } from './validateFields.interfaces';

/**
 * Валидация полей ввода на то что они не пустые.
 *
 * @param args Инпуты для валидации.
 */
export const validateFields = (args: IvalidateFieldsArgs): boolean => {
    let result = true;
    Object.keys(args).forEach((key) => {
        const value = args[key];
        if (!value || String(value).length < 3 || !validateLatLong(args['latLong'])) {
            result = false;
        }
    });

    return result;
};

/**
 * Валидация поля широты и долготы.
 * 
 * @param latLong широта, долгота (строка через запятую)
 */
export const validateLatLong = (latLong: string): boolean => {
    const arr: Array<number> = splitLatLong(latLong);
    return /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/.test(latLong) && isLatInRange(arr[0]) && isLongInRange(arr[1]);
};

/**
 * Валидация поля широты и долготы во время ввода.
 * 
 * @param lastSymbol последний введенный символ.
 */
export const validateLatLongDuringInput = (lastSymbol: string): boolean => {
    if (lastSymbol === '') return true;
    return /\d|\s|\.|,|-/.test(lastSymbol);
};

/**
 * Преобразование строки в массив.
 * 
 * @param latLong широта, долгота (строка через запятую)
 */
export const splitLatLong = (latLong: string): Array<number> => {
    return latLong.split(',').map((item) => parseFloat(item));
};

/**
 * Преобразование строки в геоджейсон.
 * 
 * @param latLong широта, долгота (строка через запятую)
 */
export const latLongToGeoJson = (latLong: string) => {
    const arr = latLong.split(',').map((item, i) => parseFloat(item));
    return { type: 'Point', coordinates: arr };
};

/**
 * ФВП для валидации числа в диапазоне -maxNumber..+maxNumber.
 *
 * @param maxNumber Максимальное по модулю число.
 */
const isNumberInRange = (maxNumber: number) => (value: string | number): boolean => {
    return Number(value) <= maxNumber && Number(value) >= -maxNumber;
};

/**
 * Валидация широты
 *
 * @param value Широта в градусах
 */
export const isLatInRange = (value: string | number) => isNumberInRange(90)(value);

/**
 * Валидация долготы
 *
 * @param value Долгота в градусах
 */
export const isLongInRange = (value: string | number) => isNumberInRange(180)(value);
