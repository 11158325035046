import styled from 'styled-components/macro';
import { getTheme, mergeStyleSets } from '@fluentui/react/lib/Styling';

const theme = getTheme();

const {
    spacing: { l1, l2, s1, s2, m }
} = theme;

/**
 * Компонента полупрозрачный фон за модалкой.
 */
export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 300;
    display: flex;
    justify-content: center;
    align-items: center;
`;

/**
 * Стили для кнопки закрытия модалки.
 */
export const iconButtonStyles = {
    root: {
        color: theme.palette.themePrimary,
        marginLeft: 'auto',
        marginTop: s2,
        marginRight: '2px',
        selectors: {
            i: { fontSize: '20px !important' }
        }
    },
    rootHovered: {
        color: theme.palette.neutralDark
    }
};

/**
 * Стили для содержимого.
 */
export const contentStyles = (topColor: string = theme.palette.themePrimary) =>
    mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
            minHeight: 'unset'
        },
        header: [
            theme.fonts.large,
            {
                flex: '1 1 auto',
                borderTop: `${s2} solid ${topColor}`,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                padding: '12px 12px 14px 24px'
            }
        ],
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 }
            }
        }
    });
