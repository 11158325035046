import { IPolygon } from "../../../../../../../layers.interfaces";

export const getCentroid = (poly: IPolygon) => {
    const coords = poly.coordinates;
    const x =
        coords.reduce((acc, item) => {
            return (acc += item[0]);
        }, 0) / coords.length;
    const y =
        coords.reduce((acc, item) => {
            return (acc += item[1]);
        }, 0) / coords.length;
    return { x, y };
};
