import { IRawRelationsQueryFNResponse, IStructures } from './interfaces';

interface IArgs {
    response: IRawRelationsQueryFNResponse;
}

const dataAdapter = (args: IArgs): IRawRelationsQueryFNResponse => {
    return args.response
};

export default dataAdapter;
