import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import styled from 'styled-components/macro';

import { theme } from '../../../../constants/theme';

const { green, red, white } = theme.palette;

export const ListWrapper = styled.section`
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .ms-DetailsList-contentWrapper {
        height: calc(100vh - 170px);
    }
    .ms-DetailsList-headerWrapper {
        .ms-DetailsHeader {
            padding: 0;
        }
        position: sticky;
        left: 0;
        top: 0;
        z-index: 10;
    }
`;
export const Floor = styled.div`
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 3px solid #ccc; */
    border-radius: 50%;
    background-color: ${theme.palette.themePrimary};
    color: ${theme.palette.white};
    font-weight: 700;
`;
export const ImageWrapper = styled.div`
    border: 1px solid rgba(0, 0, 0, 0.3);
`;

export const GeoWrapper = styled.div`
    :hover {
        cursor: pointer;
    }
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;
export const NotesContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    /* white-space: pre-wrap; */
    word-wrap: break-word;
`;

export const customStyles = {
    root: {
        display: 'flex',
        backgroundColor: white,
        cursor: 'pointer',
    },
    cell: {
        display: 'flex',
        width: 'auto',
        alignItems: 'center',
    },
};

export const customStylesNotMain = {
    root: {
        display: 'flex',
        backgroundColor: white,
    },
    cell: {
        opacity: '0.4',
        display: 'flex',
        width: 'auto',
        alignItems: 'center',
    },
};

const iconClass = mergeStyles({
    fontSize: 20,
    height: 20,
    width: 20,
    // margin: '0 25px',
});

export const classNames = mergeStyleSets({
    red: [{ color: red }, iconClass],
    grey: [{ color: '#ddd' }, iconClass],
    green: [{ color: green }, iconClass],
});
