import styled from 'styled-components/macro';
import { IStackProps } from '@fluentui/react/lib/Stack';

export const MainWrapper = styled.section``;

export const columnProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { width: 300 } },
};

export const footerProps: Partial<IStackProps> = {
    tokens: { childrenGap: 15 },
    styles: { root: { marginTop: 25 } },
};

export const Footer = styled.div`
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
`;
