import { FC } from 'react';
import ReactDataSheet from 'react-datasheet';
import { omit } from 'lodash';
import { ICell } from '../../MonitoringDetailedData.interfaces';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { MONITORING_DETAILED_DATA_COLUMNS } from '../../constants/constants';

const CellRenderer: FC<ReactDataSheet.CellRendererProps<ICell>> = (props) => {
    const { t } = useTranslation();

    const modifiedProps = {
        ...omit(props, ['cell', 'editing', 'updated', 'selected', 'attributesRenderer']),
        style: { ...(props.style || {}) },
    };
    if (props.cell.isHeader) {
        return (
            <th {...modifiedProps}>
                <span>{t(props.cell.headerText)}</span>
            </th>
        );
    }
    if (props.cell.forceComponent) {
        const Component = MONITORING_DETAILED_DATA_COLUMNS.find(
            (column) => column.columnId === props.cell.columnId,
        )?.component;
        if (Component) {
            return (
                <td {...modifiedProps}>
                    <Component {...props} />
                </td>
            );
        }
    }
    return <td {...modifiedProps}>{props.children}</td>;
};

export default CellRenderer;
