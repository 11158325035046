import { DefaultButton } from '@fluentui/react';
import styled, { keyframes } from 'styled-components/macro';
import { sizes } from '../../../../../../constants/sizes';
import { theme } from '../../../../../../constants/theme';

export const EditorWrapper = styled.section`
    height: calc(100vh - ${sizes.HEADER_HEIGHT}px);
    width: 100%;
    overflow: hidden;
    display: grid;
    /* grid-template-columns: ${sizes.TOOLS_PANEL_WIDTH + 'px auto ' + sizes.CONTROL_PANEL_WIDTH + 'px'}; */
    grid-template-columns: ${'auto ' + sizes.CONTROL_PANEL_WIDTH + 'px'};
`;

const appearence = keyframes`
  0% { top: -800px; }
  100% { top: 10px; }
`;
/**
 * Компонент Обертка контекста выделенного объекта.
 */
export const Wrapper = styled.div`
    margin-top: 10px;
    width: 800px;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    position: absolute;
    top: 10px;
    z-index: 10;
    right: 10px;
    background-color: ${theme.palette.white};
    animation: ${appearence} 0.4s ease-in-out;
    table {
        width: 100%;
    }
`;

export const Header = styled.div`
    margin-bottom: 10px;
    font-weight: 600;
`;

/**
 * Компонент Стилизация библиотечного компонента.
 */
export const StyledText = styled.p`
    margin: 0;
    padding: 0;
    color: ${() => theme.palette.themePrimary};
    font-size: ${() => theme.fonts.large + 'px'};
    margin-bottom: 10px;
`;

export const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;
export const textFieldStyles = { root: { width: '100%', marginBottom: 10 } };
export const labelStyles = { root: { color: 'red' } };

export const MainWrapper = styled.div`
    display: flex;
    width: 600px;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
`;

export const TextWrapper = styled.div`
    width: 100%;
    font-size: 16px;
`;

export const PlanId = styled.div`
    span {
        font-weight: 600;
    }
    margin-right: 10px;
`;

export const StyledDefaultButton = styled(DefaultButton)`
    padding: 5px;
    border: none;
    margin-left: 10px;
    span {
        font-weight: 400 !important;
        justify-content: flex-start;
    }

    background-color: transparent !important;
`;

export const MainPlanIdTitle = styled.div`
    font-weight: 600;
`;

export const MainPlanInputWrapper = styled.div`
    display: flex;
    align-items: flex-start;
    margin-top: 5px;
`;

export const ErrorMessage = styled.div`
    font-size: 14px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin-top: 5px;
`;

export const CustomHeader = styled.th<{ types: string[] }>`
    vertical-align: middle !important;
    padding: 8px 5px !important;
    span {
        font-size: 14px;
        color: ${theme.palette.neutralDark};
        font-weight: 700;
        display: flex !important;
        /* overflow: auto; */
        align-items: center;
        justify-content: flex-start;
        text-align: left !important;
    }
`;
export const CustomCell = styled.td<{ types: string[] }>`
    padding: 8px 5px !important;
    background-color: white !important;
    vertical-align: middle !important;
    white-space: ${(p) => (p.types.includes('date') ? 'nowrap !important' : 'normal')};
    max-width: ${(p) => (p.types.includes('object') ? '200px !important' : 'none')};
    span {
        font-size: 12px;
        color: ${theme.palette.neutralDark};
        display: flex !important;
        /* overflow: auto; */
        overflow: hidden;
        align-items: center;
        justify-content: flex-start;
        text-align: left !important;
    }
`;
