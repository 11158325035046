import { KonvaEventObject } from 'konva/lib/Node';
import { IPlan } from '../../../../Plans/Plans.interfaces';

/**
 * Функция. Служит для втискивания плана в Stage по ширине.
 * @param e Событие
 * @param {number} containerWidth - Ширина панели Draw
 */
export const fitStage = (currentPlanData: IPlan | undefined, containerWidth: number, e?: KonvaEventObject<MouseEvent>) => {
    if (!currentPlanData) return 1;
    let stageScale = 1;

    const imageWidth = currentPlanData.planWidth as number;

    if (imageWidth) {
        stageScale = containerWidth / imageWidth;
    } else if (e) {
        console.log('>>>', e);
        
        // const layersArr = Array.from(e.currentTarget?.children);
        // const imageWidth = layersArr.filter((item) => item.attrs.id === 'imageLayer')[0].children[0].attrs.image.naturalWidth;
        // stageScale = containerWidth / imageWidth;
    }
    return stageScale;
};
