import React from 'react';
import { KonvaEventObject } from 'konva/lib/Node';
import { Rect } from 'react-konva';

import { IHelperProps } from './Helper.interfaces';
import { sizes } from '../../../../../../../../constants/sizes';

/**
 * Компонент вспомогательного прозрачного прямоугольника
 */
const Helper = ({ currentPlanData, onHelperClick, onHelperMouseMove, updateCanTransform }: IHelperProps) => {
    if (!currentPlanData) return null;

    const onMouseUp = (e: KonvaEventObject<MouseEvent>) => {
        updateCanTransform && updateCanTransform(e, false, null);
    };

    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        onHelperMouseMove && onHelperMouseMove({ e });
    };

    return (
        <Rect
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp} 
            onClick={onHelperClick}
            height={currentPlanData?.planHeight! + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND}
            width={currentPlanData?.planWidth! + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND}
            fill={'transparent'}
            opacity={0}
        />
    );
};

export default Helper;
