import React, { useState, useMemo, useEffect, MouseEventHandler } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducerValues, storeIntersections } from '../../reducer';
import { CustomCell, CustomReactDataSheet, IntersectModalWrapper, IntersectTableWrapper } from './styles';
import { cloneDeep } from 'lodash';
import { ICell } from './interfaces';

/**
 * Компонент содержимое модального окна
 */
const Modal = ({ ...props }) => {
    const dispatch = useDispatch();
    const { intersections } = useSelector(reducerValues);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [selected, setSelected] = useState<any>({ start: null, end: null });

    const grid: ICell[][] = [
        [
            { value: 'perimeter', width: '25%', readOnly: true },
            { value: 'passway_marker', width: '25%', readOnly: true },
            { value: 'passway_name', width: '25%', readOnly: true },
            { value: 'direction', width: '25%', readOnly: true },
        ],
    ];

    intersections.forEach((item) => {
        const result: Array<ICell> = [];
        Object.keys(item.info).forEach((key) => {
            result.push({ value: item.info[key] });
        });
        grid.push(result);
    });

    // Убираем повторы
    const filteredGrid = Array.from(new Set(grid.map((item: ICell[]) => JSON.stringify(item)))).map((item) => JSON.parse(item));

    const onMouseUp = (value: boolean) => () => setIsMouseDown(value);

    const onMouseOver = (props: { row: number; col: number; onMouseOver: React.MouseEventHandler }) => (e: React.MouseEvent) => {
        if (isMouseDown) {
            setSelected((prevState: any) => {
                const newState = cloneDeep(prevState);
                newState.end = { i: props.row, j: props.col };
                return newState;
            });
            props.onMouseOver(e);
        }
    };

    const onMouseDown = (props: { row: number; col: number; onMouseDown: React.MouseEventHandler }) => (e: React.MouseEvent) => {
        setIsMouseDown(true);
        setSelected({ start: { i: props.row, j: props.col }, end: { i: props.row, j: props.col } });
        props.onMouseDown(e);
    };

    const cellRenderer = (props: any) => {
        return (
            <CustomCell
                onMouseDown={onMouseDown(props)}
                onMouseUp={onMouseUp(false)}
                onMouseMove={onMouseOver(props)}
                className={props.className}
            >
                {props.children}
            </CustomCell>
        );
    };

    const wrapperClickHandler = () => {
        setSelected({ start: null, end: null });
    };

    return (
        <IntersectModalWrapper onClick={wrapperClickHandler}>
            <IntersectTableWrapper>
                <CustomReactDataSheet
                    selected={selected}
                    cellRenderer={cellRenderer}
                    data={filteredGrid}
                    valueRenderer={(cell: any) => cell.value}
                />
            </IntersectTableWrapper>
        </IntersectModalWrapper>
    );
};

export default Modal;
