import { theme } from './../../../../constants/theme';
import styled from 'styled-components/macro';

export const HeaderWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
`;
export const Container = styled.div<{ order?: number }>`
    display: flex;
    align-items: center;
    ${(p) => (p.order ? `order: ${p.order};` : null)}
    i {
        display: flex;
        align-items: center;
    }
`;

export const IconsWrapper = styled.div`
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 3px;
`;

export const Title = styled.p<{ opened: boolean; hasObjects: boolean }>`
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    color: ${(p) => {
        if (p.opened) {
            return theme.palette.themePrimary;
        } else if (!p.hasObjects) {
            return theme.palette.neutralQuaternary;
        } else {
            return theme.palette.neutralDark;
        }
    }};
`;
