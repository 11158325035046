import styled from 'styled-components/macro';
import { theme } from '../../../../constants/theme';

export const Wrapper = styled.div`
    width: 400px;
    display: flex;
    align-items: center;
    * {
        margin-top: 0 !important;
        color: ${theme.palette.themeDarker} !important;
        :hover {
            color: ${theme.palette.themePrimary} !important;
            background: transparent !important;
        }
    }
`;
