const Flatten = require('@flatten-js/core');
const turf = require('@turf/turf');
const tools = require('./tools');

const OVERLAP_FRACTION = 99;

function get_intersections({ liftsObjects, zonesObjects, planScale }) {
    var intersections = [];

    const { Polygon } = Flatten;

    zonesObjects.forEach((zone) => {
        const zonePolygon = tools.getPolygonForTurf(zone.coords.coordinates, zone.zone_marker);
        liftsObjects.forEach((lift) => {
            const liftPolygonCoordinates = lift.geometry.coordinates;
            const liftPolygonArea = new Polygon(liftPolygonCoordinates).area();
            const liftPoly = tools.getPolygonForTurf(lift.geometry.coordinates, lift.marker);
            try {
                const polygonsIntersection = turf.intersect(zonePolygon, liftPoly);
                if (polygonsIntersection?.geometry?.coordinates) {
                    const intersectionsCoords = polygonsIntersection.geometry.coordinates[0];
                    const intersectionArea = new Polygon(intersectionsCoords).area();
                    const contentPercentage = (intersectionArea / liftPolygonArea) * 100;
                    if (contentPercentage >= OVERLAP_FRACTION) {
                        const zonePolygonCenter = tools.findPolygonCenter(zone.coords.coordinates);
                        const liftPolygonCenter = tools.findPolygonCenter(lift.geometry.coordinates);
                        const info = {
                            liftMarker: lift.marker,
                            liftName: lift.name,
                            zoneMarker: zone.zone_marker,
                            zoneName: zone.zone_name,
                            groupMarker: zone.group_marker,
                            groupName: zone.group_name,
                        };
                        intersections.push({
                            coords: {
                                zonePolygonCenter,
                                liftPolygonCenter,
                            },
                            info,
                        });
                    }
                }
            } catch (err) {
                console.warn('get_intersections >>>>', err);
            }
        });
    });

    return intersections;
}

module.exports = { get_intersections };
