import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Dropdown, DropdownMenuItemType, IDropdownOption, IDropdownStyles } from '@fluentui/react/lib/Dropdown';
import { TablesWrapper, dropdownStyles, StyledPivot } from './TablesList.styles';
import { ITables, ITablesListProps } from '../../../TablesPanel/TablesPanel.interfaces';
import ListItem from './ListItem';
import Accordion from '../Accordion/Accordion';
import { layerGroups } from '../../../../../../constants/layerGroups';
import { useTranslation } from 'react-i18next';
import { DefaultButton, IIconProps, PivotItem, Spinner } from '@fluentui/react';
import useRequestStructures from '../../../../../../tools/useRequestStructures/useRequestStructures';
import { useParams } from 'react-router-dom';
import useRequestRawRelations from '../../../../../../tools/useRequestRawRelations/useRequestRawRelations';
import useCachedTables from '../../../TablesPanel/hooks/useCachedTables';
import useRawTables from '../../../TablesPanel/hooks/useRawTables';

const TablesList = ({ visible, selectTableTrans, selectedTableId, savedLayersFlag }: ITablesListProps) => {
    const { t } = useTranslation();
    const { plId } = useParams();
    const tabs = [
        { key: 'rawTables', text: 'Raw tables', visible: true },
        { key: 'cachedTables', text: 'Cached tables', visible: true },
    ];

    const pivotItems = tabs
        .filter((item) => item.visible)
        .map((item) => {
            return <PivotItem key={item.key} itemKey={item.key} headerText={item.text} />;
        });

    const [selectedTab, setSelectedTab] = useState('rawTables');

    const structureResponse = useRequestStructures({ plId: plId ? Number(plId) : undefined });
    const rawRelationsResponse = useRequestRawRelations({ plId: plId ? Number(plId) : undefined });

    const cachedTables = useCachedTables();
    const rawTables = useRawTables();

    useEffect(() => {
        // setRefreshRelations(() => true);
        structureResponse.refetch();
        rawRelationsResponse.refetch();
    }, [savedLayersFlag]);

    // useEffect(() => {
    //     if (refreshRelations && visible) {
    //         fetchRelationsTrans(String(activeProjectLocation?.id));
    //         setRefreshRelations(() => false);
    //     }
    // }, [activeProjectLocation?.id, refreshRelations, visible]);

    const onTableClick = (tableId: string) => {
        selectTableTrans(tableId);
    };

    const RefreshIcon: IIconProps = { iconName: 'Refresh' };

    const onReloadClick = (mode: string) => () => {
        mode === 'rawTables' && rawRelationsResponse.refetch();
        mode === 'cachedTables' && structureResponse.refetch();
    };

    const handleLinkClick = (item?: PivotItem) => {
        if (item) {
            selectTableTrans(null);
            setSelectedTab(() => item.props.itemKey!);
        }
    };

    const list = useCallback(
        (tables: ITables) => {
            const tableNamesMap: { [x: string]: string } = {};
            layerGroups.forEach((group) => {
                tableNamesMap[group.shortName] = group.fullName;
            });
            if (!Object.keys(tables).length) return null;
            return Object.keys(tables).map((branch, i) => {
                const tList = tables[branch].map((tbl) => {
                    const selected = tbl.tableId === selectedTableId;
                    return (
                        <ListItem
                            disabled={!tbl.tableData.length}
                            key={tbl.tableId}
                            table={tbl}
                            selected={selected}
                            onClick={onTableClick}
                        />
                    );
                });
                return (
                    <Accordion
                        title={t(`${tableNamesMap[branch as keyof typeof tableNamesMap]}`)}
                        key={`${branch}++--`}
                    >
                        {tList}
                    </Accordion>
                );
            });
        },
        [selectedTableId, selectedTab],
    );

    const showSpinner = useMemo(() => {
        return (selectedTab === 'rawTables' && rawRelationsResponse.isFetching) ||
            (selectedTab === 'cachedTables' && structureResponse.isFetching)
            ? true
            : false;
    }, [selectedTab, rawRelationsResponse.isFetching, structureResponse.isFetching]);

    return (
        <TablesWrapper visible={visible}>
            <StyledPivot selectedKey={selectedTab} onLinkClick={handleLinkClick} headersOnly={true}>
                {pivotItems}
            </StyledPivot>
            <br />
            {showSpinner && (
                <>
                    <br />
                    <Spinner label={t('Loading...')} />
                    <br />
                </>
            )}
            {selectedTab === 'rawTables' && !showSpinner && (
                <>
                    {list(rawTables)}
                    <DefaultButton
                        iconProps={RefreshIcon}
                        text={t('Reload raw tables')}
                        onClick={onReloadClick('rawTables')}
                        allowDisabledFocus
                    />
                </>
            )}
            {selectedTab === 'cachedTables' && !showSpinner && (
                <>
                    {list(cachedTables)}
                    <DefaultButton
                        iconProps={RefreshIcon}
                        text={t('Reload cashed tables')}
                        onClick={onReloadClick('cachedTables')}
                        allowDisabledFocus
                    />
                </>
            )}
        </TablesWrapper>
    );
};

export default TablesList;
