import React from 'react';
import { FC } from 'react';
import { ITableCustomComponentProps } from '../../MonitoringDetailedData.interfaces';
import { useSelector } from 'react-redux';
import { reducerValues } from '../../../../../../../../reducer';
import { Circle, Wrapper } from './DataStatus.styles';
import { describeSensors } from '../../../../../../../../core/describeSensors';

const DataStatus: FC<ITableCustomComponentProps> = (props) => {
    const { maxMeasures } = useSelector(reducerValues);
    return (
        <span>
            <Wrapper>
                <Circle background={describeSensors(props.cell.sensor?.values || [], maxMeasures).fill} />
            </Wrapper>
        </span>
    );
};

export default DataStatus;
