import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTranslation } from 'react-i18next';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { commonTools } from '../../../../../../layers';
import {
    reducerValues,
    changeObjectParams,
    changeObjectFloors,
    selectPassPoint,
    addPassPoint,
    deletePassPoint,
    renamePassPoint,
} from '../../../../reducer';
import { classNames, ObjectWrapper, StyledStack, StyledTextField } from './ObjectOptions.styles';
import { IEscalator } from '../../../../interfaces';
import { ChoiceGroup, IChoiceGroupOption } from '@fluentui/react/lib/ChoiceGroup';
import { Label, Stack } from '@fluentui/react';
import { Checkbox, FontIcon } from '@fluentui/react';

const inOutOptions: IChoiceGroupOption[] = [
    { key: 'in', text: 'in' },
    { key: 'out', text: 'out' },
];
const directionOptions: IChoiceGroupOption[] = [
    { key: 'up', text: 'up' },
    { key: 'down', text: 'down' },
];

/**
 * Компонента параметров выделенного объекта.
 */
const ObjectOptions = () => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, generalSettings, allTheFloors, currentPlanData, selectedPassPointId } =
        useSelector(reducerValues);
    const [selectedObject] = objects.filter((item) => item.front_id === selectedObjectId);
    const { t } = useTranslation();

    const stackTokens: IStackTokens = {
        childrenGap: 10,
    };
    const choiceStackTokens: IStackTokens = {
        childrenGap: 20,
    };
    const floorsStackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const onChange =
        (key: keyof IEscalator) =>
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            dispatch(
                changeObjectParams({
                    key,
                    newValue: key === 'marker' ? commonTools.matchChars(newValue) : newValue,
                    front_id: selectedObject.front_id,
                }),
            );
        };

    const inOutChange = (ev: React.SyntheticEvent<HTMLElement> | undefined, option?: IChoiceGroupOption) => {
        option &&
            dispatch(changeObjectParams({ key: 'in_out', newValue: option.key, front_id: selectedObject.front_id }));
    };

    const directionChange = (ev: React.SyntheticEvent<HTMLElement> | undefined, option?: IChoiceGroupOption) => {
        option &&
            dispatch(changeObjectParams({ key: 'direction', newValue: option.key, front_id: selectedObject.front_id }));
    };

    const floorsChange =
        (floor: number) =>
        (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
            checked !== undefined &&
                dispatch(
                    changeObjectFloors({
                        checked,
                        floor,
                        front_id: selectedObject.front_id,
                    }),
                );
        };

    const floorCheckboxes = allTheFloors.map((floor) => {
        if (!selectedObject) return null;
        const { in_out, direction, floors } = selectedObject;
        const checked = floors.includes(floor);
        const disabled: boolean =
            (((in_out === 'in' && direction === 'up') || (in_out === 'out' && direction === 'down')) &&
                currentPlanData !== null &&
                currentPlanData?.floor < floor) ||
            (((in_out === 'in' && direction === 'down') || (in_out === 'out' && direction === 'up')) &&
                currentPlanData !== null &&
                currentPlanData?.floor > floor);

        if (floor !== currentPlanData?.floor) {
            return (
                <Checkbox
                    key={`floor-${floor}`}
                    disabled={disabled || generalSettings.readOnly}
                    checked={checked}
                    onChange={floorsChange(floor)}
                    label={String(floor)}
                    boxSide="start"
                />
            );
        } else {
            return (
                <Checkbox
                    key={`floor-${floor}`}
                    disabled={true}
                    checked={checked}
                    label={String(floor)}
                    boxSide="start"
                />
            );
        }
    });

    const onSelectPassPoint = (passPointId: string) => () => {
        dispatch(selectPassPoint({ passPointId }));
    };

    const onPassPointChange =
        (passPointId: string) =>
        (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            dispatch(
                renamePassPoint({
                    passPointId,
                    newValue: commonTools.matchChars(newValue),
                    front_id: selectedObject.front_id,
                }),
            );
        };

    const onDeleteIconClick = (passPointId: string) => () => {
        dispatch(deletePassPoint({ passPointId, front_id: selectedObject.front_id }));
    };

    const PassPointsList = selectedObject?.passPoints
        ? Object.keys(selectedObject.passPoints).map((key) => {
              const { lineName } = selectedObject.passPoints[key];

              const selected = key === selectedPassPointId;

              return (
                  <ObjectWrapper key={key} selected={selected} onClick={onSelectPassPoint(key)}>
                      <StyledTextField
                          disabled={generalSettings.readOnly}
                          underlined
                          value={lineName}
                          onChange={onPassPointChange(key)}
                      />
                      {!generalSettings.readOnly && (
                          <FontIcon iconName="Cancel" className={classNames.salmon} onClick={onDeleteIconClick(key)} />
                      )}
                  </ObjectWrapper>
              );
          })
        : null;

    const onAddPassPointClick = () => {
        dispatch(addPassPoint({ front_id: selectedObject.front_id, planScale: currentPlanData?.planScale }));
    };

    const onExTrafficChange = (ev?: React.FormEvent<HTMLElement>, isChecked?: boolean) => {
        dispatch(
            changeObjectParams({ key: 'external_traffic', newValue: isChecked, front_id: selectedObject.front_id }),
        );
    };

    return selectedObject ? (
        <StyledStack tokens={stackTokens}>
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Marker')}
                value={String(selectedObject.marker)}
                onChange={onChange('marker')}
            />
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Name')}
                value={String(selectedObject.name)}
                onChange={onChange('name')}
            />
            <Stack tokens={choiceStackTokens} horizontal>
                <ChoiceGroup
                    disabled={generalSettings.readOnly}
                    selectedKey={selectedObject.in_out}
                    options={inOutOptions}
                    onChange={inOutChange}
                    label="In/out"
                />
                <ChoiceGroup
                    disabled={generalSettings.readOnly}
                    selectedKey={selectedObject.direction}
                    options={directionOptions}
                    onChange={directionChange}
                    label="Direction"
                />
            </Stack>
            <Label>{t('Floors')}</Label>
            <Stack wrap tokens={floorsStackTokens} horizontal>
                {floorCheckboxes}
            </Stack>
            <TextField
                disabled={generalSettings.readOnly}
                label={t('Width (m)')}
                value={Number(selectedObject.width).toFixed(1)}
                type={'number'}
                min={1}
                step={0.1}
                onChange={onChange('width')}
            />
            <Checkbox
                disabled={generalSettings.readOnly}
                label={t('External traffic')}
                checked={selectedObject.external_traffic}
                onChange={onExTrafficChange}
            />
            <DefaultButton
                disabled={generalSettings.readOnly}
                text={t('+ Add passPoint')}
                onClick={onAddPassPointClick}
                allowDisabledFocus
            />

            {PassPointsList}
        </StyledStack>
    ) : null;
};

export default ObjectOptions;
