import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Circle = styled.div<{ background?: string }>`
    border: 1px solid #bbb;
    border-radius: 50%;
    background: ${(p) => p.background || 'transparent'};
    width: 13px;
    height: 13px;
    justify-self: center;
`;
