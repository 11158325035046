import { IDropdownStyles } from '@fluentui/react';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';
import { PERMISSIONS_SELECT_WIDTH } from '../../constants/constants';

export const OptionsWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
`;
export const ProductCellWrapper = styled.div`
    display: flex;
    gap: 10px;
`;
export const OptionBubble = styled.div`
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
    margin: 2px;

    background-color: ${theme.palette.neutralLighter};
    display: flex;
    justify-content: center;
    align-items: center;
    height: 16px;
`;

export const dropdownStyles: Partial<IDropdownStyles> = {
    callout: { width: PERMISSIONS_SELECT_WIDTH, height: 'auto' },
    dropdown: { width: '300px', height: 'auto' },
    title: { height: 'auto', paddingTop: 3, paddingBottom: 3 },
    caretDownWrapper: { display: 'flex', height: '100%', alignItems: 'center' },
};
