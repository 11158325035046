import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../../../store';
import { postRequest, getLocalBase64Image, getRequest, patchRequest } from '../../../../tools/api';
import { IReducerState, IModifications } from './SyncPanelInterfaces';
import axios from 'axios';
import { commonTools } from '../../../../tools/commonTools';
import { DateTime } from 'luxon';

const initialState: IReducerState = {
    modifications: null,
};

export const SyncPanelReducer = createSlice({
    name: 'SyncPanelReducer',
    initialState,
    reducers: {
        storeModifications: (state, action: PayloadAction<IModifications>) => {
            state.modifications = action.payload;
        },
    },
});

export const { storeModifications } = SyncPanelReducer.actions;

export const fetchSync =
    (alert?: string): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { SYNC_URL },
        } = cloneDeep(getState().GeneralReducer);
        const { activeProjectLocation } = cloneDeep(getState().LocationsReducer);
        const plId = activeProjectLocation?.id;
        getRequest({
            url: `${SYNC_URL}${plId}/`,
            dispatch,
            token,
            alert,
            allowSpinner: true,
        }).then((data) => {
            dispatch(storeModifications(data));
        });
    };

export const makeSync = (): AppThunk => (dispatch, getState) => {
    const {
        token,
        urls: { SYNC_URL },
    } = cloneDeep(getState().GeneralReducer);
    const { activeProjectLocation } = cloneDeep(getState().LocationsReducer);
    const plId = activeProjectLocation?.id;

    postRequest({
        url: `${SYNC_URL}${plId}/`,
        data: { plId },
        dispatch,
        token,
        alert: 'Successfully synchronized!',
        allowSpinner: true,
    }).then((data) => {
        dispatch(fetchSync());
    });
};

export const SyncPanelReducerValues = (state: RootState) => state.SyncPanelReducer;

export default SyncPanelReducer.reducer;
