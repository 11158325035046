import { IPassWay } from '../../../Layers/components/PassWays/passWays.interfaces';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';
import { IReducer } from './interfaces';
import { IStairs } from '../../../Layers/components/Stairs/interfaces';
const passways_stairs = require('../../../../lambda/passways_stairs');

export const initialState: IReducer = {
    tupleAlias: 'passways_stairs_tuple',
    intersections: [],
};

export const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        storeIntersections: (
            state,
            action: PayloadAction<{ objects: Array<IStairs>; passWaysObjects: Array<IPassWay>; planScale: number }>,
        ) => {
            const { objects, passWaysObjects, planScale } = action.payload;
            const intersections = passways_stairs.get_intersections({
                objects,
                passWaysObjects,
                planScale,
            });
            state.intersections = intersections;
        },
    },
});

export const { storeIntersections } = Reducer.actions;

export const reducerValues = (state: RootState) => state.Reducer;

export default Reducer.reducer;
