import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { IDeletePlanModalProps } from './DeletePlanModal.interfaces';
import {
    Footer,
    MainPlanIdWrapper,
    MainWrapper,
    StyledDefaultButton,
    MainPlanIdTitle,
    MainPlanInputWrapper,
    ErrorMessage,
} from './DeletePlanModal.styles';
import { useDispatch, useSelector } from 'react-redux';
import { deletePlan } from '../../../Plans/Plans.reducer';
import { generalReducerValues } from '../../../../General.reducer';
import { useContext } from 'react';
import { GeneralContext } from '../../../../General.context';
import { IContextualMenuProps, MessageBar, MessageBarType, TextField } from '@fluentui/react';
import { useAppDispatch } from '../../../../store';

/**
 * Компонент Модалка удаления плана.
 */
const DeletePlanModal: React.FC<IDeletePlanModalProps> = React.memo(({ planToDelete, ...props }) => {
    const {
        urls: { PLANS_URL },
    } = useSelector(generalReducerValues);
    const { token } = useContext(GeneralContext);
    const { t } = useTranslation();
    const stackTokens = { childrenGap: 10 };
    const dispatch = useAppDispatch();
    const [idInputValue, setIdInputValue] = useState('');
    const [idInputError, setIdInputError] = useState(false);

    useEffect(() => setIdInputError(false), [idInputValue]);

    const deleteHandler = () => {
        if (planToDelete?.isMain) {
            if (planToDelete?.planId === +idInputValue) {
                dispatch(
                    deletePlan({
                        planId: planToDelete?.planId as number,
                        activeLocationId: planToDelete?.locationId as number,
                    }),
                );
            } else {
                setIdInputError(true);
            }
        } else {
            dispatch(
                deletePlan({
                    planId: planToDelete?.planId as number,
                    activeLocationId: planToDelete?.locationId as number,
                }),
            );
        }
    };

    const idCopyHandler = (id: string | number | undefined) => () => {
        try {
            if (id) {
                navigator.clipboard.writeText(String(id)).then(
                    function () {},
                    function () {
                        /* clipboard write failed */
                        console.log('Write to clipboard failed!');
                    },
                );
            }
        } catch (error) {
            console.log(`Don't use Safari`);
        }
    };

    const idInputHandler = useCallback((_: any, newValue?: string) => {
        setIdInputValue(newValue || '');
    }, []);

    const menuProps: IContextualMenuProps = {
        shouldFocusOnMount: true,
        items: [
            {
                key: `planId(${planToDelete?.planId}`,
                onClick: idCopyHandler(planToDelete?.planId),
                iconProps: { iconName: 'Copy' },
                text: 'Copy id',
            },
        ],
    };

    return (
        <MainWrapper>
            <MessageBar
                messageBarType={planToDelete?.isMain ? MessageBarType.error : MessageBarType.warning}
                isMultiline={false}
            >
                {planToDelete?.isMain
                    ? t('By deleting this plan, you will delete all the floor layers.')
                    : t('Are you sure you want to delete the leasing plan?')}
            </MessageBar>

            {planToDelete?.isMain && (
                <MainPlanIdWrapper>
                    <MainPlanIdTitle>Enter plan id</MainPlanIdTitle>
                    <MainPlanInputWrapper>
                        <TextField value={idInputValue} onChange={idInputHandler} />
                        <StyledDefaultButton text={`Plan id: ${planToDelete.planId}`} menuProps={menuProps} />
                    </MainPlanInputWrapper>
                    {idInputError && <ErrorMessage>{t('Field value must be equal to plan id')}</ErrorMessage>}
                </MainPlanIdWrapper>
            )}
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={t('Delete?')} width={'100px'} onClick={deleteHandler} />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default DeletePlanModal;
