import styled from 'styled-components/macro';
import { Stack } from '@fluentui/react/lib/Stack';
import { TextField } from '@fluentui/react/lib/TextField';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';
import { IDropdownStyles } from '@fluentui/react';

export const ObjectOptionsWrapper = styled.div``;

export const StyledStack = styled(Stack)`
    .ms-TextField-fieldGroup {
        background-color: transparent;
    }
    label {
        padding-left: 0px;
        margin-top: 0px !important;
        :first-child {
            margin-top: 10px !important;
        }
    }
`;

export const StyledTextField = styled(TextField)`
    * {
        border: 0 !important;
    }
`;

const iconClass = mergeStyles({
    fontSize: 12,
    height: 16,
    width: 16,
});

export const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'greenyellow' }, iconClass],
    salmon: [{ color: 'salmon' }, iconClass],
});

export const dropdownStyles: Partial<IDropdownStyles> = {
    dropdown: { width: '100%' },
    root: { marginRight: '10px', marginLeft: 'auto' },
};

export const ObjectWrapper = styled.div<{ selected: boolean }>`
    cursor: pointer;
    padding: 1px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-top: 7px !important;
    justify-content: space-between;
`;

export const OptionsWrapper = styled.div`
    /* max-width: 600px; */
`;

export const TopWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    padding-bottom: 5px;
`;

export const buttonStyles = {
    root: {
        marginTop: 10,
        maxWidth: '200px',
        marginLeft: '10px',
    },
};
