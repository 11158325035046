const tools = require('./tools');
const cloneDeep = require('lodash/cloneDeep');

function get_intersections({ perimeterObjects, passWaysObjects, planScale }) {
    var intersections = [];
    const extendedPassWays = passWaysObjects.map((passWay) => {
        return Object.assign(cloneDeep(passWay), { arrowsCoords: tools.getArrowCoords({ passWay, planScale }) });
    });

    extendedPassWays.forEach((passWay) => {
        for (let i = 0; i < perimeterObjects.length; i++) {
            const polygonObject = perimeterObjects[i];

            const info = {
                perimeterMarker: String(i),
                passWayMarker: passWay.marker,
                passWayName: passWay.name,
            };

            intersections = intersections.concat(tools.getPwPolygonIntersections({ polygonObject, info, passWay }));
        }
    });

    // console.log('....');
    // console.dir({ perimeterObjects, passWaysObjects, planScale, intersections }, { depth: null });

    return intersections;
}

module.exports = { get_intersections };
