import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

export const Header = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 20px auto 20px;
`;

export const Container = styled.div`
    display: flex;
    align-items: center;
    i {
        display: flex;
        align-items: center;
    }
`;

export const Title = styled.div`
    display: flex;
    align-items: center;
    font-weight: 700;
    padding: 0 10px;
    color: ${theme.palette.themePrimary};
`;

export const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export const AccordionContainer = styled.div`
    .accordion-list {
        max-width: unset;
    }
`;
