import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { commonTools } from '../../../../tuples';
import { storeIntersections } from '../../reducer';

/**
 * Компонент контрольной панели
 */

const Controls = ({ ...props }) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const stairsObjects = props?.dataFromLayers['stairs_layer']?.objects || [];
        const placesObjects = props?.dataFromLayers['places_layer']?.objects || [];
        props.visible &&
            dispatch(storeIntersections({ stairsObjects, placesObjects, planScale: props?.currentPlanData?.planScale }));
    }, [
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['stairs_layer']?.objects)),
        JSON.stringify(commonTools.extractGeometry(props?.dataFromLayers['places_layer']?.objects)),
        props?.currentPlanData?.planScale,
        props.visible,
    ]);

    return <></>;
};

export default Controls;
