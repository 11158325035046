import React from 'react';
import { ErrorWrapper } from './styles';
/**
 * Компонент для отрисовки страницы с ошибкой
 * @returns
 */
const ErrorPage = () => {
    return <ErrorWrapper>Page not found</ErrorWrapper>;
};

export default ErrorPage;
