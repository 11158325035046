import { Provider } from 'react-redux';
import { store } from '../../store';
import ContextMenu from './ContextMenu';
import React from 'react';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <ContextMenu {...props} />
        </Provider>
    );
};
