import styled from 'styled-components/macro';
import { mergeStyles } from '@fluentui/react/lib/Styling';

import { theme } from '../../../../constants/theme';

export const ToolsPanelWrapper = styled.section`
    /* height: 100%; */
    /* background-color: ${theme.palette.neutralLight}; */
    display: flex;
    /* justify-content: space-between; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* padding: 0 5px; */
    /* border-right: 1px solid rgba(0, 0, 0, 0.1); */
`;

export const Split = styled.div`
    margin: 5px;
    width: 80%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
`;

export const Footer = styled.div`
    cursor: pointer;
    margin-top: auto;
    margin-bottom: 10px;
    button {
        span {
            display: flex;
            flex-direction: column;
            i :last-of-type {
                position: absolute;
                display: none !important;
                height: 0;
                width: 0;
            }
            i :first-child {
                font-size: 20px;
                height: 26px;
                width: 26px;
                margin: 0;
                padding: 0;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
`;

export const getButtonStyles = (active: boolean) => {
    return {
        root: { margin: '1px', backgroundColor: active ? theme.palette.neutralQuaternary : 'transparent' },
        rootHovered: { backgroundColor: theme.palette.neutralQuaternary },
    };
};

export const iconClass = mergeStyles({
    fontSize: 20,
    height: 26,
    width: 26,
    // marginRight: '5px',
    // color: 'red',
});
