import { FC } from 'react';
import { ITableCustomComponentProps } from '../store';
import ExternalIds from '../components/ExternalIds/ExternalIds';
import WorkingHours from '../components/WorkingHours/WorkingHours';
import AreaHistory from '../components/AreaHistory/AreaHistory';

export const COMPONENT_BY_COL: { [x: string]: FC<ITableCustomComponentProps> } = {
    external_ids: ExternalIds,
    working_hours: WorkingHours,
    area_history: AreaHistory,
};
