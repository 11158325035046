import React from 'react';
import { iconClass, StyledFontIcon } from './ShowLabelIcon.styles';
import { TooltipHost } from '@fluentui/react';
import { useTranslation } from 'react-i18next';

/**
 * Компонента иконки показа маркеров объектов.
 */
export default function ShowLabelIcon({ ...props }) {
    const { t } = useTranslation();
    return (
        <TooltipHost content={`${t('Show / hide label')} (${props.hotKeyText || 'Shift + L'})`}>
            <StyledFontIcon iconName="CommentSolid" className={iconClass(props.color)} showLabels={props.showLabels} />
        </TooltipHost>
    );
}
