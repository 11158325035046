import React from 'react';
import { StyledLogo, StyledLink } from './Logo.styles';
import logoFocus from '../../assets/logo-dark.svg';

/**
 * Компонента логотипа, обернутая в ссылку на Home
 */
const Logo = () => {
    return (
        <StyledLink to="/">
            <StyledLogo logo={logoFocus} />
        </StyledLink>
    );
};

export default Logo;
