import React from 'react';
import { useDispatch } from 'react-redux';
import { IGeometryBackgroundProps } from './GeometryBackground.interfaces';
import { selectNavPolygon } from '../../../../navPolygons.reducer';
import { Group, Layer, Shape } from 'react-konva';

/**
 * Компонента Фона
 */
const GeometryBackground: React.FC<IGeometryBackgroundProps> = ({ typeSelect, objects, activeToolId, navPolygonFrontId, scale }) => {
    const dispatch = useDispatch();

    let typeSelectBackground = typeSelect === 'polygons_in' ? 'polygons_out' : 'polygons_in';
    let colorStroke = typeSelectBackground === 'polygons_in' ? 'rgba(51,143,51,.6)' : 'rgba(255,0,0,.6)';
    if (!objects[typeSelectBackground] || objects[typeSelectBackground].length === 0) return null;

    const componentNode = objects[typeSelectBackground].map((item, index) => {
        let coordsList = item.coords.coordinates;

        const onClickItemColumn = (groupFrontId?: string, itemFrontId?: string) => () => {
            if (activeToolId === 'anchorsEdit') {
                dispatch(selectNavPolygon({ groupFrontId, itemFrontId }));
            }
        };

        return (
            <Group onClick={onClickItemColumn('objects', item.front_id)} key={index + '+++!!++'}>
                <Shape
                    // onClick={onHelperClick(item.front_id)}
                    // onMouseOver={onShapeMouseOver}
                    // onMouseOut={onShapeMouseOut}
                    sceneFunc={(context, shape) => {
                        context.beginPath();
                        context.moveTo(coordsList[0][0], coordsList[0][1]);
                        coordsList.forEach((coord, i) => {
                            if (i > 0) {
                                context.lineTo(coord[0], coord[1]);
                            }
                        });
                        context.closePath();
                        context.fillStrokeShape(shape);
                    }}
                    // fill={color}
                    opacity={1}
                    fill={'rgb(241,238,53,.2)'}
                    stroke={colorStroke}
                    strokeWidth={navPolygonFrontId === item.front_id ? 4 / scale.stageScale : 2 / scale.stageScale}
                />
            </Group>
        );
    });

    return <Layer key={`---Background`}>{componentNode}</Layer>;
};

export default GeometryBackground;
