import React, { useDeferredValue, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Datasheet from 'react-datasheet';
import { ICell } from './TablesPanel.interfaces';

import { resetFilters, tablesPanelReducerValues, toggleContextMenu } from './TablesPanel.reducer';
import { CustomCell, CustomHeader, FilterIndicator, Wrapper } from './TablesPanel.styles';
import ContextMenu from './components/ContextMenu/ContextMenu';
import SpinnerComponent from '../../../Spinner/Spinner';
import useGrid from './hooks/useGrid';

const TablesPanel = () => {
    const { contextMenu, filters } = useSelector(tablesPanelReducerValues);
    const deferredFilters = useDeferredValue(filters);

    const { isPending, grid, table } = useGrid();
    const dispatch = useDispatch();

    const onContextMenu = (e: MouseEvent, cell: ICell, i: number, j: number) => {
        e.preventDefault();
        const cursorCoords = { x: e.clientX, y: e.clientY };
        dispatch(toggleContextMenu({ show: true, cursorCoords, cell }));
    };

    const updateTable = (newTableData: object[] | null) => {
        if (newTableData === null) {
            dispatch(resetFilters());
        }
    };

    const cellRenderer = (props: any) => {
        const { children, editing, ...rest } = props;
        if (props.cell.types.includes('cell')) {
            return (
                <CustomCell {...rest} types={props.cell.types}>
                    {children}
                </CustomCell>
            );
        } else if (props.cell.types.includes('header')) {
            const filterValue = deferredFilters[props.cell.colName]?.filter;
            const filterText = filterValue ? `( ${filterValue} )` : '';

            return (
                <CustomHeader {...rest} types={props.cell.types}>
                    {children}
                    <FilterIndicator>{filterText}</FilterIndicator>
                </CustomHeader>
            );
        }
    };

    const dataSheet = useMemo(() => {
        return (
            <Datasheet
                valueRenderer={(cell: any) => cell.value}
                onContextMenu={onContextMenu}
                cellRenderer={cellRenderer}
                data={grid}
            />
        );
    }, [grid, deferredFilters]);

    const menu = useMemo(() => {
        if (!table) return null;
        return <ContextMenu selectedTable={table} updateTable={updateTable} />;
    }, [contextMenu, deferredFilters, table]);

    return (
        <Wrapper>
            {isPending ? (
                <SpinnerComponent />
            ) : (
                <>
                    {menu}
                    {dataSheet}
                </>
            )}
        </Wrapper>
    );
};

export default TablesPanel;
