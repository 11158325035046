import React from 'react';
import { MainWrapper } from './VersionsHistoryModal.styles';
import Versions from '../../../Versions/Versions';

/**
 * Компонент для отображения модалки с истоией версий
 */
const VesrionsHistoryModal = () => {
    return (
        <MainWrapper>
            <Versions />
        </MainWrapper>
    );
};

export default VesrionsHistoryModal;
