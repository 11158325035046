import * as React from 'react';
import { DefaultPalette, AnimationClassNames, AnimationStyles } from '@fluentui/react/lib/Styling';
import { IconButton, Stack, IStackItemStyles, IStackStyles } from '@fluentui/react';
import { IVersion } from '../Versions.interfaces';
import { useState } from 'react';
import { FontIcon, IStackTokens } from '@fluentui/react';
import styled from 'styled-components/macro';
import DateInput from './DateInput';
import { useMemo } from 'react';
import { useEffect } from 'react';
import { VersionsContext } from '../Versions.context';
import { useContext } from 'react';

const Accordion: React.FC<IAccordionProps> = ({ ...props }) => {
    const { updateVersion, readOnly } = useContext(VersionsContext);
    const [open, setSOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const accordionHeader: IStackItemStyles = {
        root: {
            background:
                props.activeVersionId === props.version.id ? DefaultPalette.themeLight : DefaultPalette.neutralLighter,
            padding: 5,
            cursor: 'pointer',
        },
    };
    const accordion: IStackStyles = {
        root: {
            borderStyle: 'solid',
            borderWidth: 1,
            width: '100%',
            borderColor: DefaultPalette.neutralTertiary,
            ...AnimationStyles.fadeIn200,
        },
    };
    const accordionConent: IStackStyles = {
        root: {
            padding: 10,
        },
    };

    const dateDisabled = props.versionToEdit !== props.version.id;

    useEffect(() => {
        setCounter(() => counter + 1);
    }, [props.versionToEdit, dateDisabled]);

    const showContent = (e: React.MouseEvent) => {
        e.stopPropagation();
        setSOpen(() => !open);
    };

    const selectVersion = (e: React.MouseEvent) => {
        !open && showContent(e);
        props.selectVersion(props.version.id);
        dateDisabled && props.toggleVersionToEditTrans(null);
    };

    const icons = useMemo(() => {
        const stackTokens: IStackTokens = { childrenGap: 10 };

        const onEditVersionClick = (versionId: number | null) => (e: React.MouseEvent) => {
            e.stopPropagation();
            !open && showContent(e);
            props.selectVersion(props.version.id);
            props.toggleVersionToEditTrans(versionId);
            setCounter(() => counter + 1);
        };

        if (dateDisabled) {
            return <FontIcon iconName="Edit" onClick={onEditVersionClick(props.version.id)} />;
        } else {
            return (
                <Stack horizontal tokens={stackTokens}>
                    <FontIcon iconName="Blocked" onClick={onEditVersionClick(null)} />
                    <FontIcon iconName="CheckMark" onClick={updateVersion(props.version.id)} />
                </Stack>
            );
        }
    }, [dateDisabled, props]);

    const date = React.useCallback(
        (mode: string) => {
            const dateString = mode === 'date_from' ? props.dates.from : props.dates.to;
            return (
                <DateInput
                    dateString={dateString}
                    dateDisabled={dateDisabled}
                    counter={counter}
                    mode={mode}
                    versionId={props.version.id}
                />
            );
        },
        [counter, dateDisabled, props.dates.from, props.dates.to, props.version.id],
    );

    return (
        <>
            <Stack horizontal={false} styles={accordion}>
                <Stack.Item styles={accordionHeader}>
                    <Header onClick={selectVersion}>
                        <IconButton
                            onClick={(e) => showContent(e as React.MouseEvent)}
                            iconProps={{
                                iconName: open ? 'ChevronDown' : 'ChevronRight',
                            }}
                        />
                        <Title>{props.title}</Title>
                        {date('date_from')}
                        {date('date_to')}
                        {!readOnly && <IconWrapper>{icons}</IconWrapper>}
                    </Header>
                </Stack.Item>
                {open && (
                    <Stack.Item className={AnimationClassNames.fadeIn200} styles={accordionConent}>
                        {props.children}
                    </Stack.Item>
                )}
            </Stack>
        </>
    );
};

interface IAccordionProps {
    toggleVersionToEditTrans: (id: number | null) => void;
    title: string;
    dates: { from: string; to: string | null };
    version: IVersion;
    activeVersionId: number | null;
    versionToEdit: number | null;
    selectVersion: (id: number) => void;
    children: React.ReactNode;
}

const Header = styled.div`
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 20px 150px auto auto 40px;
`;

const Title = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
`;

const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export default Accordion;
