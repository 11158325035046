import { EmptyDays, Footer, MainWrapper } from './Versions.styles';
import { PrimaryButton, Stack } from '@fluentui/react';
// @ts-nocheck
import React, { useMemo, useState } from 'react';
import {
    changeVersionsById,
    createNewVersion,
    patchVersion,
    selectVersion,
    toggleVersionToEdit,
    versionsReducerValues,
} from './Versions.reducer';
import { cloneDeep, isUndefined } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import Accordion from './components/Accordion';
import CreateNewVersion from './components/CreateNewVersion';
import { DateTime } from 'luxon';
import LeasingPlan from './components/LeasingPlan';
import Modal from '../Modals/Modals';
import { VersionsContext } from './Versions.context';
import { plansReducerValues } from '../Plans/Plans.reducer';
import { settingsReducerValues } from '../Settings/Settings.reducer';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';

/**
 * Компонент для отображения модалки с истоией версий
 */
const Versions = () => {
    const dispatch = useAppDispatch();
    const { versions, activeVersionId, versionToEdit } = useSelector(versionsReducerValues);
    const { plansList, plansIdsByVersionIds } = useSelector(plansReducerValues);
    const {
        generalSettings: { readOnly },
    } = useSelector(settingsReducerValues);
    const [createNewVersionModalStatus, setCreateNewVersionModalStatus] = useState({ show: false });
    const { t } = useTranslation();

    const stackTokens = { childrenGap: 10 };

    const onCreateNewVersionClick = () => {
        setCreateNewVersionModalStatus({ show: true });
    };

    const selectVersionTrans = (versionId: number) => {
        dispatch(selectVersion(versionId));
    };

    const toggleVersionToEditTrans = (versionId: number | null) => {
        dispatch(toggleVersionToEdit(versionId));
    };

    const list = useMemo(() => {
        const result: JSX.Element[] = [];
        if (!Object.keys(plansIdsByVersionIds).length) return result;
        cloneDeep(versions).forEach((item, i) => {
            const plansIncluded = plansIdsByVersionIds[item.id].map((item) => {
                const [plan] = plansList.filter((p) => p.planId === item);
                return <LeasingPlan key={`${item}`} plan={plan}></LeasingPlan>;
            });

            const dates = { from: item.date_from, to: item.date_to };

            result.push(
                <Accordion
                    title={`Version id: ${item.id}`}
                    dates={dates}
                    version={item}
                    activeVersionId={activeVersionId}
                    versionToEdit={versionToEdit}
                    key={`${item.id}`}
                    selectVersion={selectVersionTrans}
                    toggleVersionToEditTrans={toggleVersionToEditTrans}
                >
                    {plansIncluded}
                </Accordion>,
            );

            if (i < versions.length - 1) {
                const date1 = DateTime.fromISO(item.date_from);
                const date2 = DateTime.fromISO(versions[i + 1].date_to || '');
                const diff = date1.diff(date2, ['years', 'months', 'days', 'hours']);
                if (!isUndefined(diff.toObject().days) && diff.toObject().days! > 1) {
                    result.push(<EmptyDays key={`empty_days-${i}`}>{t('Empty days')}</EmptyDays>);
                }
                // console.log(item.id, diff.toObject());
            }
        });
        return result;
    }, [activeVersionId, plansIdsByVersionIds, plansList, versionToEdit, versions]);

    const changeDate = (args: { [x: string]: string | number | null }) => {
        const versionId = args.versionId as number;
        delete args.versionId;
        dispatch(changeVersionsById({ versionId, args }));
    };

    const updateVersion = (versionId: number) => () => {
        dispatch(patchVersion(versionId));
    };

    const versionsContextValue = { changeDate, updateVersion, readOnly };

    return (
        <VersionsContext.Provider value={versionsContextValue}>
            <MainWrapper>
                {list}
                {!readOnly && (
                    <Footer>
                        <Stack horizontal tokens={stackTokens}>
                            <PrimaryButton
                                text={t('Create new version')}
                                width={'100px'}
                                onClick={onCreateNewVersionClick}
                                disabled={versionToEdit !== null}
                            />
                        </Stack>
                    </Footer>
                )}
                <Modal
                    modalStatus={createNewVersionModalStatus}
                    title={t('Create new version')}
                    isDraggable={true}
                    // closeModal={closeVersionModal}
                >
                    <CreateNewVersion />
                </Modal>
            </MainWrapper>
        </VersionsContext.Provider>
    );
};

export default Versions;
