import {IGroupZones, IObjects} from "../zones.interfaces";

const compareNameZones = (
    objects: IObjects,
    itemSelect: IGroupZones,
    anotherLocations: Array<object>,
    zoneFrontId: string,
    nameZone: string,
    markerZone: string
) => {


    let status: boolean = true;


    objects.forEach((itemGroup, indexGroup) => {

        itemGroup.zones.forEach((itemZones, indexZones) => {
            if (itemZones.zone_name === nameZone) {
                if (itemZones.zone_marker !== markerZone) {
                    status = false;
                }
            }
            if (itemZones.zone_marker === markerZone) {
                if (itemZones.zone_name !== nameZone) {
                    status = false;
                }
            }
        })

    })
    anotherLocations?.forEach((itemLocations: {data?: Array<IGroupZones>}, index) => {

        if (!itemLocations.data) return;
        itemLocations?.data.forEach((_item, _index) => {
            if (_item.group_name !== itemSelect.group_name && _item.group_marker !== itemSelect.group_marker) {
                _item.zones.forEach((_itemZones, _indexZones) => {
                    if (_itemZones.front_id !== zoneFrontId) {
                        if (_itemZones.zone_name === nameZone || _itemZones.zone_marker === markerZone) {
                            status = false;
                        }
                    }
                })
            }

            // if ((_item.group_marker === marker && _item.group_name !== name) ||
            //     (_item.group_marker !== marker && _item.group_name === name)) {
            //     status = false;
            // }
        })
    })

    return status;
}


export default compareNameZones;