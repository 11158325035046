/**
 * Объет с размерными константами.
 */
export const sizes = {
    CONTROL_PANEL_WIDTH: 250,
    TOOLS_PANEL_WIDTH: 40,
    GRID_GAP: 3,
    HEADER_HEIGHT: 50,
    GLOBAL_SHIFT: 10000,
    HELPER_EXPAND: 5000,
};
