import { IconButton } from '@fluentui/react/lib/Button';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

const { themePrimary, white, neutralDark } = theme.palette;

/**
 * Компонент Обертка панели рисования.
 */
export const StyledIconButton = styled(IconButton)`
    height: 24px;
    width: 24px;
    /* color: ${theme.palette.themePrimary}; */
    background-color: transparent;
    :hover {
        background-color: transparent;
        color: white;
    }
    .ms-Icon-placeHolder {
        display: none;
    }
`;

export const ToggleBackgroundWrapper = styled.div<{ showBackgroundPlanImage: boolean }>`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${themePrimary};
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
    background-color: ${(p) => (p.showBackgroundPlanImage ? themePrimary : white)};
    color: ${(p) => (p.showBackgroundPlanImage ? white : themePrimary) + ' !important'};
    :hover {
        background-color: ${themePrimary};
        color: ${white};
        i {
            color: ${white};
        }
    }
`;
