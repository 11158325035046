import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';

import { IGeneralSettings, ISettingsContext, TObjNamingMode } from './Settings.interfaces';

const initialState: ISettingsContext = {
    generalSettings: {
        lang: 'en',
        snapToGrid: 0.25,
        gridValue: 0.25,
        readOnly: true,
        displayContent: true,
        displayTables: false,
        displayFpcMonitoring: false,
        objNamingMode: 'markers',
    },
};

export const SettingsReducer = createSlice({
    name: 'SettingsReducer',
    initialState,

    reducers: {
        /**
         * Функция изменения языка
         */
        changeLang: (state, action: PayloadAction<string>) => {
            const { generalSettings } = cloneDeep(state);
            generalSettings.lang = action.payload;
            state.generalSettings = generalSettings;
        },

        /**
         * Функция изменения настроек
         */
        changeSettings: (state, action: PayloadAction<string>) => {
            const generalSettings = JSON.parse(action.payload);
            state.generalSettings = generalSettings;
        },

        /**
         * Функция изменения ролей
         */
        changePermissions: (state, action: PayloadAction<string[]>) => {
            const { generalSettings } = cloneDeep(state);
            if (!generalSettings) return;
            const permissions = action.payload;
            generalSettings.readOnly = !permissions.includes('core/map-service | layers | feature/edit');
            generalSettings.displayContent = permissions.includes('core/map-service | layers | feature/view');
            generalSettings.displayTables = permissions.includes('core/map-service | structure-tables | feature/view');
            generalSettings.displayFpcMonitoring = permissions.includes(
                'core/map-service | fpc-monitoring | feature/view',
            );
            state.generalSettings = generalSettings;
        },

        /**
         * Функция изменения привязки к сетке
         */
        toggleSnapToGrid: (state, action: PayloadAction<boolean | number>) => {
            const { generalSettings } = cloneDeep(state);
            generalSettings.snapToGrid = action.payload;
            if (action.payload) {
                generalSettings.gridValue = action.payload as number;
            }
            state.generalSettings = generalSettings;
        },

        /**
         * Изменение отображения label для элементов списка слоя.
         * Если markers - отображается маркер.
         * Если names - отображается имя (если нет имени, то маркер).
         */
        storeObjNamingMode: (state, action: PayloadAction<TObjNamingMode>) => {
            state.generalSettings.objNamingMode = action.payload;
        },
    },
});

export const { changeLang, toggleSnapToGrid, changePermissions, changeSettings, storeObjNamingMode } =
    SettingsReducer.actions;

export const settingsReducerValues = (state: RootState) => state.SettingsReducer;

export default SettingsReducer.reducer;
