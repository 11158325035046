import styled from 'styled-components/macro';
import ReactDataSheet from 'react-datasheet';

export const ModalWrapper = styled.div`
    /* background-color: rgba(255, 0, 0, 0.3); */
    overflow: auto;
    /* max-height: 700px; */
`;

export const ChartWrapper = styled.div<{ windowSize: { width: number; height: number } }>`
    width: ${(p) => p.windowSize.width - 80 + 'px'};
    height: ${(p) => p.windowSize.height / 2 - 120 + 'px'};

    /* background-color: rgba(255, 0, 0, 0.3); */
    overflow: hidden;
    /* max-height: 700px; */
`;

export const IntersectTableWrapper = styled.div`
    margin-top: 20px;
    min-height: 150px;
    max-height: 600px;
    overflow: auto;
`;

export const CustomReactDataSheet = styled(ReactDataSheet)`
    /* width: 1000px; */
`;

export const CustomCell = styled.td`
    span {
        /* height: 50px; */
        font-size: 14px;
        padding: 8px 5px;
        display: flex !important;
        overflow: auto;
        align-items: center;
        justify-content: flex-start;
    }
`;
