import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Stack } from '@fluentui/react/lib/Stack';
import { PrimaryButton } from '@fluentui/react/lib/Button';
import { Footer, MainWrapper, textFieldStyles, TextWrapper } from './styles';
import { createNewTenant, tenantsReducerValues } from '../../../../../Tenants/Tenants.reducer';
import { useBoolean } from '@fluentui/react-hooks';
import { IProps } from './interfaces';
import { Label, TextField, Toggle } from '@fluentui/react';
import { commonTools } from '../../../../../../../Layers/layers';
import { useAppDispatch } from '../../../../../../../../store';
import { useFetchComparison } from './api/useFetchComparison';
import SpinnerComponent from '../../../../../../../Spinner/Spinner';
import { theme } from '../../../../../../../../constants/theme';

/**
 * Компонент Модалка добавления арендатора.
 */
const CreateNewTenantModal: React.FC<IProps> = React.memo(({ modalStatus, closeModal }) => {
    const { t } = useTranslation();
    const { tenants, selectedTenant, placesByMarker, tenant2place, reloadData, places } =
        useSelector(tenantsReducerValues);
    const [name, setName] = React.useState<string>('');
    const [valid, setValid] = React.useState(false);
    const [similarData, setSimilarData] = React.useState<string[]>([]);
    const [enabled, setEnabled] = React.useState(false);
    const [multiPlace, { toggle: toggleMultiPlace }] = useBoolean(false);
    const stackTokens = { childrenGap: 10 };
    const dispatch = useAppDispatch();

    const { refetch, data, isFetching } = useFetchComparison({ name, enabled });

    const okHandler = () => {
        if (valid) {
            modalStatus?.plId &&
                dispatch(
                    createNewTenant({
                        project_location_id: modalStatus.plId,
                        object_name: name,
                        multi_place: multiPlace,
                        // marker: marker,
                        is_active: true,
                    }),
                );
            closeModal();
        } else {
            setEnabled(true);
            refetch();
        }
    };

    useEffect(() => {
        if (data) {
            setEnabled(false);
            setValid(data.isNameValid);
            setSimilarData(data.similarNames);
        }
    }, [data]);

    useEffect(() => {
        if (tenants.length === 0) {
            setValid(() => true);
        }
    }, [tenants]);

    const changeName = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        const { transliter, onlyNumbersAndLetters } = commonTools;
        tenants.length && setValid(false);
        setSimilarData([]);
        setName(() => newValue || '');
        // newValue && setMarker(() => onlyNumbersAndLetters(transliter(newValue))?.toLowerCase() || '');
    };

    // const tenantsMarkers = tenants.map((item) => item.marker.toLowerCase());

    // const isNewMarkerValid = useMemo(() => {
    //     return !tenantsMarkers.includes(marker);
    // }, [marker, tenantsMarkers]);

    const isNewNameLong = useMemo(() => {
        return name.length >= 1;
    }, [name]);

    const buttonDisabled = !isNewNameLong;

    const text = useMemo(() => {
        return valid ? t('Create new tenant') : t('Validate the name');
    }, [t, valid]);

    const SimilarInfo = useMemo(() => {
        if (!valid && similarData.length) {
            return (
                <Label styles={{ root: { color: theme.palette.redDark } }}>{`${t('The same tenent already exists')}: ${
                    similarData[0]
                }`}</Label>
            );
        } else if (similarData.length && valid) {
            return (
                <Label>
                    {t('Similar names')}: {similarData.join(', ')}
                </Label>
            );
        } else if (valid) {
            return <Label styles={{ root: { color: theme.palette.greenDark } }}>{t('The name is valid')}</Label>;
        } else {
            return null;
        }
    }, [t, valid, similarData]);

    return isFetching ? (
        <SpinnerComponent />
    ) : (
        <MainWrapper>
            <TextWrapper>
                <TextField
                    placeholder={t('Enter a name')}
                    value={name}
                    onChange={changeName}
                    styles={textFieldStyles}
                />
                {/* <TextField value={marker} styles={textFieldStyles} disabled /> */}
                <Toggle inlineLabel label={t('Multi place')} checked={multiPlace} onChange={toggleMultiPlace} />
                {/* {!isNewMarkerValid && <Label styles={labelStyles}>{t('Such a marker already exists')}</Label>} */}
            </TextWrapper>
            {SimilarInfo}
            <Footer>
                <Stack horizontal tokens={stackTokens}>
                    <PrimaryButton text={text} width={'100px'} onClick={okHandler} disabled={buttonDisabled} />
                </Stack>
            </Footer>
        </MainWrapper>
    );
});

export default CreateNewTenantModal;
