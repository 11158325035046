import axios, { AxiosError } from 'axios';
import { Dispatch } from '@reduxjs/toolkit';

// import { toggleAlert, toggleSpinner } from '../General.reducer';
import { IRequestArgs, IToggleSpinnerArgs } from './api.interfaces';
import { getHeaders } from '../../../tools/getHeaders';
// import { headersNames } from '../constants/headers';

/**
 * Get запрос.
 * @param args Аргументы для запроса на сервер.
 */
export const getRequest = async (args: IRequestArgs) => {
    const { url, token, dispatch, allowSpinner = true } = args;
    const headers = getHeaders(token || '');
    try {
        const response = await axios({
            method: 'get',
            url,
            headers,
            responseType: 'json',
        });
        return response.data;
    } catch (error) {
        handleError(error as AxiosError, dispatch);
    }
};

/**
 * Post запрос.
 * @param args Аргументы для запроса на сервер.
 */
export const postRequest = async (args: IRequestArgs) => {
    const { url, token, data, dispatch, additionalParams = null, responseType = 'json', allowSpinner = true } = args;
    try {
        const headers = getHeaders(token || '');
        const response = await axios({
            method: 'POST',
            url,
            responseType,
            data,
            headers,
            // headers: { ...headers, "Content-Type": "application/x-www-form-urlencoded" },
        });
        return response.data;
    } catch (error) {
        handleError(error as AxiosError, dispatch);
    }
};

/**
 * PATCH запрос.
 * @param args Аргументы для запроса на сервер.
 */
export const patchRequest = async (args: IRequestArgs) => {
    const { url, token, data, dispatch, additionalParams = null, responseType = 'json', allowSpinner = true } = args;
    try {
        const headers = getHeaders(token || '');
        const response = await axios({ method: 'PATCH', url, responseType, data, headers });
        return response.data;
    } catch (error) {
        handleError(error as AxiosError, dispatch);
    }
};

/**
 * DELETE запрос.
 * @param args Аргументы для запроса на сервер
 */
export const deleteRequest = async (args: IRequestArgs) => {
    const { url, token, allowSpinner = true, dispatch } = args;
    try {
        const headers = getHeaders(token || '');
        const response = await axios({ method: 'DELETE', url, headers });
        return response.data;
    } catch (error) {
        handleError(error as AxiosError, dispatch);
    }
};

/**
 * Получение изображения из локального файла.
 *
 * @param url Локальная Blob ссылка на картинку.
 */
export const getLocalBlobImage = async (url: string) => {
    try {
        const response = await axios({
            method: 'get',
            url,
            headers: getHeaders(),
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        handleError(error as AxiosError);
    }
};

/**
 * Получение изображения из локального файла.
 *
 * @param url Локальная Blob ссылка на картинку.
 */
export const getLocalBase64Image = async (url: string) => {
    try {
        let result;
        await axios({
            method: 'get',
            url,
            headers: getHeaders(),
            responseType: 'blob',
        })
            .then(
                (response) =>
                    new File([response.data], `${response.data.type.replace('image/', '')}`, {
                        type: response.data.type,
                    }),
            )
            .then(async (file) => {
                result = await convertBase64(file);
            });
        return result;
    } catch (error) {
        handleError(error as AxiosError);
    }
};

/**
 * Конвертация файла в base64.
 *
 * @param file Файл
 */
const convertBase64 = (file: File) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};

/**
 * ФВП для спиннера.
 */
// const _toggleSpinner =
//     (show: boolean) =>
//     ({ dispatch, allowSpinner }: IToggleSpinnerArgs) => {
//         allowSpinner && dispatch && dispatch(toggleSpinner(show));
//     };

/**
 * Обработчик ошибок.
 *
 * @param error Объект описывающий ошибку.
 */
export const handleError = (error: AxiosError, dispatch?: Dispatch): void => {
    if (error.response) {
        // Запрос был сделан и сервер ответил
        // dispatch &&
        //     dispatch(
        //         toggleAlert({
        //             show: true,
        //             // text: `Network Error! Status: ${error.response.status}`,
        //             text: `Network Error! Status: ${error.response.status}, ${JSON.stringify(error.response.data)}`,
        //         }),
        //     );
        console.warn(error.response.data);
        console.warn(error.response.status);
        console.warn(error.response.headers);
    } else if (error.request) {
        // Запрос был сделан но ответа не получено
        console.warn(error.request);
    } else {
        // Что-то вызвало ошибку при запросе.

        console.warn('Error', error.message);
    }
};
