import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';

export const StyledLogo = styled.div<{ logo: string }>`
    width: 200px;
    flex-shrink: 0;
    height: 60%;
    background-size: contain !important;
    box-sizing: border-box;
    /* border-width: 3px;
    border-style: solid; */
    background: ${(p) => `url(${p.logo}) center no-repeat`};
    a {
        display: inline-block;
        height: 50px;
        width: 200px;
        position: fixed;
        cursor: pointer;
        z-index: 10000;
    }
`;

export const StyledLink = styled(Link)`
    height: 100%;
    display: flex;
    align-items: center;
`;
