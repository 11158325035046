import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { GridElement } from './ObjectsManagement';
import ReactDataSheet from 'react-datasheet';
import { IwhModalStatus } from './interfaces';
import { IElementAdminDataObject } from '../../../../tools/useRequestStructures/interfaces';

export interface IStore {
    filters: { [x: string]: { [y: string]: string } };
    contextMenu: IContextMenu;
    sorting: ISorting;
    changeFilters: (x: IChangeFilterArgs) => void;
    toggleContextMenu: (args: IContextMenu) => void;
    changeSorting: (args: ISorting) => void;
    resetFilters: () => void;
}

export interface IChangeFilterArgs {
    value: string | undefined | number;
    colName: string;
    mode?: string;
}

export interface ICell extends GridElement {
    width?: string | number;
    readOnly?: boolean;
    colName?: string;
    types?: string[];
    dataObject?: IElementAdminDataObject;
}

export interface ITableCustomComponentProps extends ReactDataSheet.CellRendererProps<ICell> {
    toggleModal?: (args: IwhModalStatus) => void;
}
export interface IContextMenu {
    show: boolean;
    cursorCoords?: {
        x: number;
        y: number;
    };
    cell?: ICell;
}
export interface ISorting {
    colName: string | null;
    order: number;
}

export const useObjectManagementStore = create<IStore>()(
    devtools((set) => ({
        filters: {},
        contextMenu: { show: false },
        sorting: { colName: null, order: 1 },
        toggleContextMenu: (args: IContextMenu) => set((state: IStore) => ({ contextMenu: args })),
        changeSorting: (args: ISorting) => set((state: IStore) => ({ sorting: args })),
        resetFilters: () => set((state: IStore) => ({ filters: {} })),
        changeFilters: (args) =>
            set((state: IStore) => {
                const { filters } = state;
                const { colName, value, mode } = args;

                if (!value || value === '') {
                    delete filters[colName];
                    return { filters: { ...filters } };
                } else {
                    if (mode) {
                        return { filters: { ...filters, [colName]: { filter: value as string, mode } } };
                    } else {
                        return { filters: { ...filters, [colName]: { filter: value as string } } };
                    }
                }
            }),
    })),
);
