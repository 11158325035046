import { useParams } from 'react-router-dom';
import useRequestStructures from '../../../../../tools/useRequestStructures/useRequestStructures';
import { IBranch, ITables } from '../TablesPanel.interfaces';
import { useEffect, useState } from 'react';
import { TStructureElement } from '../../../../../tools/useRequestStructures/interfaces';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

const useCachedTables = (): ITables => {
    const { plId } = useParams();
    const structureResponse = useRequestStructures({ plId: plId ? Number(plId) : undefined });

    const [tables, setTables] = useState<ITables>({});

    useEffect(() => {
        if (structureResponse.data) {
            const tables = Object.entries(structureResponse.data.structures).reduce(
                (acc: ITables, [branch, value]: [string, { [x: string]: TStructureElement[] }]) => {
                    acc[branch] = Object.entries(value).reduce((acc: IBranch, [tableName, value]) => {
                        acc.push({
                            tableData: value?.map((item) => {
                                const newValue = cloneDeep(item);

                                if ('date_to' in newValue) {
                                    const year = DateTime.fromISO(newValue.date_to).year;
                                    if (year >= 2100 || newValue.date_to === null) {
                                        newValue.date_to = '-';
                                    }
                                }

                                return newValue;
                            }),
                            branch,
                            tableName,
                            tableId: `cached/${branch}/${tableName}`,
                        });
                        return acc;
                    }, []);

                    return acc;
                },
                {},
            );
            setTables(tables);
        }
    }, [structureResponse.data]);
    return tables;
};

export default useCachedTables;
