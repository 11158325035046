import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { TextField } from '@fluentui/react/lib/TextField';
import { useTranslation } from 'react-i18next';
import { IStackTokens } from '@fluentui/react/lib/Stack';
import { DefaultButton } from '@fluentui/react/lib/Button';
import { commonTools } from '../../../../../../layers';
import { passWaysReducerValues, changeObjectParams, reversePassWay } from '../../../../passWays.reducer';
import { StyledStack } from './ObjectOptions.styles';
import { IPassWay } from '../../../../passWays.interfaces';

/**
 * Компонента параметров выделенного объекта.
 */
const ObjectOptions = () => {
    const dispatch = useDispatch();
    const { objects, selectedObjectId, generalSettings } = useSelector(passWaysReducerValues);
    const [selectedObject] = objects.filter((item) => item.id === selectedObjectId);
    const { t } = useTranslation();

    const StackTokens: IStackTokens = {
        childrenGap: 10,
    };

    const onChange = useCallback(
        (key: keyof IPassWay) => (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            dispatch(
                changeObjectParams({
                    key,
                    newValue: key === 'marker' ? commonTools.matchChars(newValue) : newValue,
                    id: selectedObject.id,
                }),
            );
        },
        [dispatch, selectedObject?.id],
    );

    const onReverseClick = () => {
        dispatch(reversePassWay({ id: selectedObject.id }));
    };

    const connectivityMargin = selectedObject?.connectivityMargin ? selectedObject.connectivityMargin : 2;

    return selectedObject ? (
        <StyledStack tokens={StackTokens}>
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Marker')}
                value={String(selectedObject.marker)}
                onChange={onChange('marker')}
            />
            <TextField
                disabled={generalSettings.readOnly}
                underlined
                label={t('Name')}
                value={String(selectedObject.name)}
                onChange={onChange('name')}
            />
            <TextField
                disabled={generalSettings.readOnly}
                label={t('Pass Margin (m)')}
                value={Number(selectedObject.passMargin).toFixed(2)}
                type={'number'}
                min={0.1}
                step={0.01}
                onChange={onChange('passMargin')}
            />
            <TextField
                disabled={generalSettings.readOnly}
                label={t('Connectivity Margin (m)')}
                value={Number(connectivityMargin).toFixed(2)}
                type={'number'}
                min={0.1}
                step={0.01}
                onChange={onChange('connectivityMargin')}
            />
            <DefaultButton
                disabled={generalSettings.readOnly}
                text={t('Change direction')}
                onClick={onReverseClick}
                allowDisabledFocus
            />
        </StyledStack>
    ) : null;
};

export default ObjectOptions;
