import styled, { keyframes } from 'styled-components/macro';
import { FontIcon } from '@fluentui/react/lib/Icon';
import { mergeStyles } from '@fluentui/react/lib/Styling';

export const iconClass = mergeStyles({
    fontSize: 16,
    height: 20,
    width: 20,
    color: 'red',
});

function blinkingEffect() {
    return keyframes`
      50% {
        opacity: 0;
      }
    `;
}

export const StyledFontIcon = styled(FontIcon)`
    cursor: pointer;
    animation: ${blinkingEffect} 0.5s ease-in-out infinite;
    :hover {
        animation: ${blinkingEffect} 2s ease-in-out infinite;
    }
`;
