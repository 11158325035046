import _ from 'lodash';
import { IWorkingHours } from '../../../../../tools/useRequestStructures/interfaces';
import { DateTime, Interval } from 'luxon';

export const getIntervals = (workingHours: IWorkingHours[]) => {
    const sortedWh = _.sortBy(workingHours, (item) => {
        if (item.date_from === null) {
            return -Infinity;
        } else {
            return DateTime.fromISO(String(item.date_from)).toMillis();
        }
    });
    let skips: { days: number; date_to: string; date_from: string | null }[] = [];
    sortedWh.forEach((item, i, arr) => {
        if (item.date_to !== null) {
            const start = DateTime.fromISO(String(item?.date_to));
            const finish = DateTime.fromISO(String(arr[i + 1]?.date_from));
            const interval = Interval.fromDateTimes(start, finish).toDuration('days').toObject();
            if (interval?.days && interval.days > 1) {
                skips.push({ days: interval.days, date_to: item?.date_to, date_from: arr[i + 1]?.date_from });
            }
        }
    });
    return { sortedWh, skips };
};
