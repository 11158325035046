import { Dropdown } from '@fluentui/react';
import styled from 'styled-components/macro';

export const StyledDropdown = styled(Dropdown)`
    .ms-Button-flexContainer {
        padding: 10px 0 !important;
        color: red;
    }
    .ms-Dropdown-header {
        margin-bottom: 10px;
        position: sticky;
        top: 0;
    }
`;
export const SeachWrapper = styled.div`
    padding-top: 10px;
    margin-bottom: 10px;
    position: sticky;
    top: 0;
`;

export const JustText = styled.div`
    box-shadow: none;
    margin: 0px;
    padding: 0px 28px 0px 8px;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(96, 94, 92);
    border-radius: 2px;
    display: block;
    height: 32px;
    line-height: 30px;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    box-shadow: none;
    margin: 0px;
    box-sizing: border-box;
    font-family: 'Segoe UI', 'Segoe UI Web (West European)', 'Segoe UI', -apple-system, BlinkMacSystemFont, Roboto,
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 14px;
    font-weight: 400;
    color: rgb(50, 49, 48);
    border-color: rgb(96, 94, 92);
    position: relative;
    outline: 0px;

    display: flex;
    align-items: center;
`;
