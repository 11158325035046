import { IChoiceGroupOption } from '@fluentui/react';
import { TObjNamingMode } from '../../../Settings.interfaces';

export const snapToGridOptions: IChoiceGroupOption[] = [
    { key: '0.05', text: '0.05' },
    { key: '0.25', text: '0.25' },
    { key: '0.5', text: '0.5' },
    { key: '1', text: '1' },
];

export const displayOptions: ({ key: TObjNamingMode } & IChoiceGroupOption)[] = [
    { key: 'markers', text: 'Markers' },
    { key: 'names', text: 'Names' },
];
