import { IconButton } from '@fluentui/react/lib/Button';
import styled from 'styled-components/macro';
import { theme } from '../../../../../../constants/theme';

/**
 * Компонент Обертка панели рисования.
 */
export const StyledIconButton = styled(IconButton)`
    height: 24px;
    width: 24px;
    color: ${theme.palette.themePrimary};
    background-color: transparent;
    :hover {
        background-color: transparent;
        color: white;
    }
    .ms-Icon-placeHolder {
        display: none;
    }
`;
