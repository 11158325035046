import styled from 'styled-components';
import { theme } from '../../../../../../constants/theme';

export const Intraday = styled.div`
    display: grid;
    grid-gap: 15px;
    grid-template-rows: auto 1fr 1fr;
    padding-left: 10px;
    &:not(:first-child) {
        border-left: ${() => '1px solid ' + theme.palette.neutralDark};
    }
`;
export const Dates = styled.div`
    display: flex;
    font-size: medium;
    margin-bottom: 15px;
`;
export const IntervalWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;
    width: 100%;
    border-radius: 5px;
`;

export const WorkingHoursModalWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`;

export const Time = styled.div`
    display: grid;
    grid-template-columns: 50px auto;
    align-items: center;
`;
export const Text1 = styled.div`
    display: flex;
`;
export const Plate = styled.div`
    padding: 10px;
    background-color: ${() => theme.palette.neutralLighter};
`;
