import { store } from '../../zones.store';
import { Provider } from 'react-redux';
import React from 'react';
import Tools from './Tools';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <Tools {...props} />
        </Provider>
    );
};
