import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';

import { getRequest, patchRequest, postRequest } from '../../tools/api';
import { mapFront2Back, mapBack2Front } from '../../tools/mappingFrontAndBack';
import {
    IAddNewLocationArgs,
    ICloneLocationArgs,
    ILocation,
    ILocationsReducerState,
    IPatchLocationArgs,
    IProjectLocation,
} from './Locations.interfaces';
import { locationsCloneMapping, locationsMapping } from '../../constants/keysMapping';
import { toggleAlert } from '../../General.reducer';
import { log } from 'console';

const initialState: ILocationsReducerState = {
    projectLocationsList: [],
    activeLocation: null,
    activeProjectLocation: null,
    projectLocationsById: {},
};

export const LocationsReducer = createSlice({
    name: 'LocationsReducer',
    initialState,
    reducers: {
        /**
         * Сохранение списка локаций в стор.
         */
        storeProjectLocations: (state, action: PayloadAction<any>) => {
            if (!action.payload) return;
            const projectLocationsList: Array<IProjectLocation> = action.payload.map((item: IProjectLocation) => {
                return mapBack2Front(locationsMapping, item);
            });

            const locationsById = action.payload.reduce((acc: { [x: string]: ILocation }, value: ILocation) => {
                if (value.id) acc[value.id] = value;
                return acc;
            }, {});

            state.projectLocationsById = locationsById;
            state.projectLocationsList = projectLocationsList;
        },

        storeActiveLocation: (state, action: PayloadAction<ILocation | null>) => {
            state.activeLocation = action.payload;
        },

        storeActiveProjectLocation: (state, action: PayloadAction<IProjectLocation | null>) => {
            state.activeProjectLocation = action.payload;
        },
    },
});

export const { storeActiveLocation, storeActiveProjectLocation } = LocationsReducer.actions;

/**
 * Запрос списка локаций с сервера.
 */

interface IFetchLocationsArgs {
    url: string;
    token: string;
    projectId: string;
}

export const fetchProjectLocations =
    (args: IFetchLocationsArgs): AppThunk =>
    (dispatch) => {
        const { url, token, projectId } = args;
        getRequest({ url: `${url}?project_id=${projectId}`, dispatch, allowSpinner: true, token }).then(
            (data: Array<ILocation>) => {
                dispatch(LocationsReducer.actions.storeProjectLocations(data));
            },
        );
    };

export const fetchActiveLocation =
    (ml_id: string): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { LOCATIONS_URL, LOCATION_CLONE_URL },
        } = getState().GeneralReducer;

        getRequest({ url: `${LOCATIONS_URL}${ml_id}/`, dispatch, allowSpinner: true, token }).then(
            (data: ILocation) => {
                dispatch(LocationsReducer.actions.storeActiveLocation(data));
            },
        );
    };

/**
 * Запись новой локации на сервер.
 *
 * @param args url, token, data, getUrl
 */
export const addNewLocation =
    (args: IAddNewLocationArgs): AppThunk =>
    (dispatch, getState) => {
        const { url, token, data } = args;

        token &&
            postRequest({ url, dispatch, allowSpinner: true, token, data }).then(() => {
                dispatch(fetchProjectLocations({ url, token, projectId: String(data.project_id) }));
            });
    };

/**
 * Запрос на клонирование локации на сервере.
 *
 * @param args  data
 */
export const cloneLocation =
    (args: ICloneLocationArgs): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { LOCATIONS_URL, LOCATION_CLONE_URL },
        } = getState().GeneralReducer;
        const { data } = args;

        if (token) {
            const mappedData = mapFront2Back(locationsCloneMapping, data);

            postRequest({ url: LOCATION_CLONE_URL, dispatch, allowSpinner: true, token, data: mappedData }).then(
                (data) => {
                    if (!data) {
                        const text = 'Clone location error';
                        dispatch(toggleAlert({ show: true, text, lifeTime: 3000 }));
                    } else {
                        // dispatch(fetchLocations(LOCATIONS_URL, token));
                    }
                },
            );
        }
    };

/**
 * Обновление информации о локации
 * @param args url, token, data, locationId
 */
export const patchLocation =
    (args: IPatchLocationArgs): AppThunk =>
    (dispatch) => {
        const { url, token, data, locationId, reloadWindow } = args;

        patchRequest({ url: `${url}${locationId}/`, dispatch, token, data }).then(() => {
            reloadWindow && window.location.reload();

            token &&
                data.project_id &&
                dispatch(fetchProjectLocations({ url, token, projectId: String(data.project_id) }));
        });
    };

export const locationsReducerValues = (state: RootState) => state.LocationsReducer;

export default LocationsReducer.reducer;
