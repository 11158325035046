import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { IPassWay } from "../../../Layers/components/PassWays/passWays.interfaces"
import { IGroupZones } from "../../../Layers/components/Zones/zones.interfaces"
import { IExtendedZone, IReducer } from "./interfaces"
import { RootState } from "./store"
const passways_zones = require('../../../../lambda/passways_zones')

export const initialState: IReducer = {
    tupleAlias: 'passways_zones_tuple',
    intersections: []
}

const Reducer = createSlice({
    name: 'Reducer',
    initialState,
    reducers: {
        
        /**
         * Запись в стейт информации о пересечении проходов и зон
         */
        storeIntersections: (state, 
            action: PayloadAction<{ zonesGroups: Array<IGroupZones>; passWaysObjects: Array<IPassWay>; planScale: number }>
        ) => {
            const { zonesGroups, passWaysObjects, planScale } = action.payload;
            
            const zonesObjects = [] as Array<IExtendedZone>

            zonesGroups.forEach(group => {
                if (group.zones.length > 0) {
                    group.zones.forEach((zone) => {
                        zonesObjects.push({...zone, group_marker: group.group_marker, group_name: group.group_name})
                    })
                    
                }
            })
            
            const intersections = passways_zones.get_intersections({zonesObjects, passWaysObjects, planScale})
            state.intersections = intersections

        }
    }
})

export const {storeIntersections} = Reducer.actions

export const reducerValues = (state: RootState) => state.Reducer

export default Reducer.reducer