import * as THREE from 'three';

/**
 * Вычисление координат финишной точки для стрелки.
 * @param point Координаты точки
 * @param arr Массив точек
 * @param planScale Масштаб плана
 * @returns Координаты финишной точки для стрелки.
 */
export const getPassPoint = <T extends number>(point: T[], arr: Array<T[]>, planScale: T) => {
    const angle = Math.PI / 2;
    const center = [(arr[0][0] + arr[1][0]) / 2, (arr[0][1] + arr[1][1]) / 2];
    const normalV = new THREE.Vector3(0, 0, 1);
    const passPointV = new THREE.Vector3(...center, 0)
        .sub(new THREE.Vector3(point[0], point[1], 0))
        .normalize()
        .multiplyScalar(planScale * 2)
        .applyAxisAngle(normalV, angle)
        .add(new THREE.Vector3(point[0], point[1], 0));

    return [passPointV.x, passPointV.y];
};
