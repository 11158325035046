const tools = require('./tools');
const cloneDeep = require('lodash/cloneDeep');

function get_intersections({ zonesObjects, passWaysObjects, planScale }) {
    var intersections = [];

    const extendedPassWays = passWaysObjects.map((passWay) => {
        return Object.assign(cloneDeep(passWay), { arrowsCoords: tools.getArrowCoords({ passWay, planScale }) });
    });

    extendedPassWays.forEach((passWay) => {
        for (let i = 0; i < zonesObjects.length; i++) {
            const polygonObject = zonesObjects[i];

            const info = {
                passWayMarker: passWay.marker,
                passWayName: passWay.name,
                zoneMarker: polygonObject.zone_marker,
                zoneName: polygonObject.zone_name,
                groupMarker: polygonObject.group_marker,
                groupName: polygonObject.group_name,
            };

            intersections = intersections.concat(tools.getPwPolygonIntersections({ polygonObject, info, passWay }));
        }
    });

    return intersections;
}
module.exports = { get_intersections };
