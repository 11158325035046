export const deleteObjectFromOtherLayers = (objectId: string, anotherLocationLayers: { [x: string]: any }[]) => {
    const result = anotherLocationLayers.map((layer) => {
        if (layer.data === null || layer.data.length === 0) {
            return layer;
        } else if (layer.data.length !== 0) {
            const data = layer.data.filter((item: { front_id: string }) => item.front_id !== objectId);
            return { ...layer, data };
        }
    });
    return result;
};
