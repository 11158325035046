/**
 * Функция маппинга свойств с фронта на бэк.
 * 
 * @param mapping Словарь для маппинга.
 * @param item Объект, свойства которого нужно переименовать.
 */
export const mapFront2Back = (mapping: Map<string, string>, item: object): object => {
    const result: { [props: string]: string } = {};
    mapping.forEach((back: string, front: string) => {
        if (item[front as keyof typeof item] !== undefined) {
            result[back] = item[front as keyof typeof item];
        }
    });
    return result;
};

/**
 * Функция маппинга свойств с бэка на фронт.
 * 
 * @param mapping Словарь для маппинга.
 * @param item Объект, свойства которого нужно переименовать.
 */
export const mapBack2Front = (mapping: Map<string, string>, item: object): object => {
    const result: { [props: string]: string } = {};
    mapping.forEach((back: string, front: string) => {
        if (item[back as keyof typeof item] !== undefined) {
            result[front] = item[back as keyof typeof item];
        }
    });
    return result;
};
