import { IHosts, ILocales, IUrls } from './../General.interfaces';
import { useEffect } from 'react';
import { getUrls, hostsMap } from '../constants/urls';
import { useDispatch } from 'react-redux';

import {
    fillBackEnd,
    storeToken,
    fillUrls,
    getCurrent,
    storeAppDomain,
    storeHosts,
    storeLocales,
} from '../General.reducer';
import { getLocales } from './getLocales';
import { cloneDeep } from 'lodash';
import { useAppDispatch } from '../store';

interface IAuthParams {
    authAddress: string;
}

type Token = string | null;

/**
 * Хук для аутентификации.
 * @param authAddress Адрес сервера для аутентификации.
 */

const useUrls = (): void => {
    const dispatch = useAppDispatch();

    const currentUrl = window.location.origin + window.location.pathname;

    useEffect(() => {
        getLocales().then((res: { data: ILocales }) => {
            dispatch(storeLocales(res.data));
            const hosts: { [x: string]: string } = { geoService: 'https://geomapping.focustech.xyz' };

            Object.entries(hostsMap).forEach((entry) => {
                const [key, value] = entry;
                if (key in res.data.services) {
                    hosts[value] = `https://${res.data.services[key]}`;
                }
            });

            dispatch(storeHosts(hosts));

            const newLocalStorageUrls: IHosts = {};
            (Object.keys(hosts) as Array<keyof typeof hosts>).forEach((key: keyof typeof hosts) => {
                newLocalStorageUrls[key] = { checked: false, url: hosts[key] };
            });
            dispatch(fillBackEnd(newLocalStorageUrls));
            let hostsUrls: IHosts = cloneDeep(newLocalStorageUrls);
            const back: string | null = localStorage.getItem('hostsUrls');

            if (back !== null) {
                dispatch(fillBackEnd(JSON.parse(back)));
                const backEnd = JSON.parse(back);
                (Object.keys(hosts) as Array<keyof typeof hosts>).forEach((key: keyof typeof hosts) => {
                    if (backEnd[key]?.checked) {
                        hostsUrls[key] = { ...backEnd[key] };
                    } else {
                        hostsUrls[key] = { ...newLocalStorageUrls[key] };
                    }
                });
            } else {
                localStorage.setItem('hostsUrls', JSON.stringify(newLocalStorageUrls));
                hostsUrls = newLocalStorageUrls;
            }

            dispatch(fillUrls(generateUrls(hostsUrls)));

            const token = findToken();

            dispatch(storeAppDomain(res.data.services['app/client']));

            if (token) {
                dispatch(getCurrent({ token }));
            } else {
                redirectToAuth(generateUrls(hostsUrls).AUTH_URL);
            }
        });
    }, []);

    const generateUrls = (hostsUrls: IHosts) => {
        const hosts: IUrls = {};
        Object.keys(hostsUrls).forEach((key) => {
            hosts[key] = hostsUrls[key].url;
        });
        const urls = getUrls(hosts);
        return urls;
    };

    /**
     * Пытаемся найти токен в урле и в LocalStorage.
     */
    const findToken = (): Token => {
        let token: Token;
        const searchParams = new window.URLSearchParams(document.location.search);
        if (searchParams.get('token')) {
            token = searchParams.get('token');
            token && localStorage.setItem('xtoken', token);
            removeTokenFromUrl();
        } else {
            token = localStorage.getItem('xtoken');
        }
        return token;
    };

    /**

     * Удаляем пришедший нам токен из урла.

     */
    const removeTokenFromUrl = (): void => {
        // window.location.href = currentUrl;
        window.history.pushState(
            'object or string',
            'Title',
            '/' + window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split('?')[0],
        );
    };

    /**
     * Редирект на сервер аутентификации.
     */
    const redirectToAuth = (authUrl: string): void => window.location.replace(`${authUrl}?next=${currentUrl}`);
};

export default useUrls;
