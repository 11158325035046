import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { Stack } from '@fluentui/react/lib/Stack';

import LocationsList from './components/LocationsList/LocationsList';
import StackHorizontal from '../../../StackHorizontal/StackHorizontal';
import FilterInput from '../../../FilterInput/FilterInput';
import { GET_ALL_LOCATIONS_QUERY_KEY } from '../../../../constants/queryKeys';
import { generalReducerValues } from '../../../../General.reducer';
import { getRequest } from '../../../../tools/api';
import { useAppDispatch } from '../../../../store';
import { ILocation } from '../../../Locations/Locations.interfaces';
import { GeneralContext } from '../../../../General.context';

import { LocationsWrapper, MainWrapper } from './styles';
import { CSSProp } from 'styled-components';
import { Spinner } from '@fluentui/react/lib/Spinner';

export interface LocationsModalProps {
    closeModal: () => void;
}

export const LocationsModal: React.FC<LocationsModalProps> = ({ closeModal }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { theme } = useContext(GeneralContext);
    const { neutralLighterAlt, neutralDark } = theme.palette;

    const [filteredProjectsList, setFilteredProjectsList] = useState<ILocation[]>([]);

    const updateFilteredArray = (filteredArray: Array<object>) => {
        setFilteredProjectsList(filteredArray as ILocation[]);
    };

    const {
        urls: { PROJECT_LOCATIONS_URL },
        token,
    } = useSelector(generalReducerValues);

    // const { projectsById, projectsList } = useSelector(projectsReducerValues);

    const { data: locations, isFetching } = useQuery({
        queryKey: [GET_ALL_LOCATIONS_QUERY_KEY],
        queryFn: async () => {
            const res: ILocation[] = await getRequest({ url: PROJECT_LOCATIONS_URL, token });
            return res;
        },
        staleTime: 15 * 60 * 1000,
    });

    const allLocations = locations || ([] as ILocation[]);

    const styles: CSSProp = {
        minHeight: 400,
        minWidth: 600,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };

    if (isFetching) {
        return (
            <div style={styles}>
                <Spinner size={3} />
            </div>
        );
    }

    return (
        <MainWrapper>
            <StackHorizontal>
                <FilterInput
                    inputArray={allLocations}
                    updateFilteredArray={updateFilteredArray}
                    matchField={'name'}
                    matchField1={'id'}
                />
            </StackHorizontal>

            <LocationsWrapper bgcolor={neutralLighterAlt} color={neutralDark}>
                <LocationsList list={filteredProjectsList} onBeforeNavigate={closeModal} />
            </LocationsWrapper>
        </MainWrapper>
    );
};
