import React, { useState, useMemo, useEffect, MouseEventHandler } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { reducerValues } from '../../reducer';
import { ResponsiveHeatMapCanvas, TooltipProps } from '@nivo/heatmap';
import { CustomCell, CustomReactDataSheet, ModalWrapper, IntersectTableWrapper, ChartWrapper } from './styles';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';

/**
 * Тултип
 */
const CustomTooltip = (
    props: React.PropsWithChildren<
        TooltipProps<{
            x: string;
            y: number;
        }>
    >,
) => {
    const { generalSettings, sensors, selectedSensorId } = useSelector(reducerValues);
    const [isMouseDown, setIsMouseDown] = useState(false);
    const [selected, setSelected] = useState<any>({ start: null, end: null });

    return (
        <div
            style={{
                fontSize: 12,
                padding: 12,
                background: '#222222',
                color: 'white',
            }}
        >
            <strong>Sensor serial: {props.cell.serieId}</strong>
            <br />
            <strong>DateTime: {props.cell.data.x}</strong>
            <br />
            <strong>Data fullness: {props.cell.data.y}</strong>
        </div>
    );
};

export default CustomTooltip;
