import styled from 'styled-components/macro';
import { FontWeights, mergeStyleSets, mergeStyles, IStackStyles, IStackTokens, IButtonStyles } from '@fluentui/react';
import { theme } from '../../../../../../../../constants/theme';

export const ObjectsListWrapper = styled.div`
    //min-height: 50px;
    height: auto;
    max-height: 190px;
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    cursor: pointer;
    * {
        background-color: transparent !important;
    }

    .ms-GroupSpacer {
        width: 5px !important;
    }
`;

const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
    // margin: '0 25px',
});

export const ObjectWrapper = styled.div``;

export const GroupHeaderWrapper = styled.div<{ statusSelect: boolean }>`
    position: relative;
    font-size: 14px;
    color: ${(p) => (p.statusSelect ? theme.palette.themeDark : null)};

    .title-94 {
        font-size: 14px;
    }
`;

export const ItemColumnWrapper = styled.div<{ statusSelect: boolean }>`
    /* display: grid; */
    /* grid-template-columns: auto 16px; */
    /* grid-template-rows: 20px; */
    border-bottom: 1px solid #000000;
    font-size: 14px;
    align-items: baseline;
    font-weight: ${(p) => (p.statusSelect ? `bold` : null)};
    height: 20px;
    text-align: left;
    width: 100%;
    position: relative;
    i {
        position: absolute;
        right: 0px;
        top: 0;
    }
`;

export const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'greenyellow' }, iconClass],
    salmon: [{ color: 'salmon' }, iconClass],
});

export const contentStyles = mergeStyleSets({
    container: {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'stretch',
    },
    header: [
        theme.fonts.xLargePlus,
        {
            flex: '1 1 auto',
            borderTop: `4px solid ${theme.palette.themePrimary}`,
            color: theme.palette.neutralPrimary,
            display: 'flex',
            alignItems: 'center',
            fontWeight: FontWeights.semibold,
            padding: '12px 12px 14px 24px',
        },
    ],
    body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        selectors: {
            p: { margin: '14px 0' },
            'p:first-child': { marginTop: 0 },
            'p:last-child': { marginBottom: 0 },
        },
    },
});

export const iconButtonStyles = {
    root: {
        color: theme.palette.neutralPrimary,
        marginLeft: 'auto',
        marginTop: '4px',
        marginRight: '2px',
    },
    rootHovered: {
        color: theme.palette.neutralDark,
    },
};

export const ButtonStyleAdd = {
    root: {
        backgroundColor: '#edebe9',
    },
};

export const DeleteGroupZonesButtonWrapper = styled.div`
    position: absolute;
    top: 11px;
    right: 8px;
`;
