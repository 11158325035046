import { TextField } from '@fluentui/react/lib/TextField';
import { Stack } from '@fluentui/react/lib/Stack';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import styled from 'styled-components/macro';

export const Footer = styled.section`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
`;

export const MainWrapper = styled.div`
    display: flex;
    width: 600px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
`;

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    align-items: flex-end;
`;

export const Container = styled.div`
    display: flex;
    width: 100%;
`;

export const StTextField = styled(TextField)`
    width: 100% !important;
    margin-left: 10px;
    textarea {
        max-height: 300px !important;
        overflow: auto !important;
    }
`;
export const StStack = styled(Stack)`
    width: 100% !important;
`;
export const StCheckbox = styled(Checkbox)`
    margin-bottom: 5px;
`;
