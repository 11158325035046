import React, { ReactNode } from 'react';
import { Stack, IStackProps } from '@fluentui/react/lib/Stack';
import { StStack, topStackStyles } from './StackHorizontal.styles';

/**
 * Компонента для отображения контейнера с горизонтальным позиционированием вложенных компонент.
 */
const StackHorizontal = ({ ...props }) => {
    const content = Array.isArray(props.children) ? (
        props.children.map((child: ReactNode, i: number) => {
            return (
                <Stack.Item align="center" key={`Stack.Item__${i}`}>
                    {child}
                </Stack.Item>
            );
        })
    ) : (
        <Stack.Item align="center">{props.children}</Stack.Item>
    );

    return (
        <Stack horizontal {...topStackStyles}>
            {content}
        </Stack>
    );
};

export default StackHorizontal;
