import styled from 'styled-components/macro';

export const ObjectsListWrapper = styled.div`
    height: calc(100% - 10px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const FooterWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 5px;
`;
