import styled from 'styled-components/macro';
import { theme } from '../../constants/theme';

/**
 * Компонент Обертка алерта.
 * @param type: string - тип алерта, влияет на цвет фона.
 */
export const AlertWrapper = styled.div<{ type: string }>`
    padding: 10px;
    position: absolute;
    bottom: 20px;
    white-space: pre-wrap;
    right: 20px;
    z-index: 1000000001;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    background-color: ${(p) => {
        switch (p.type) {
            case 'warning':
                return theme.palette.yellowLight;
            case 'success':
                return theme.palette.green;

            default:
                return theme.palette.white;
        }
    }};
    color: ${(p) => {
        switch (p.type) {
            case 'warning':
                return theme.palette.black;
            case 'success':
                return theme.palette.white;

            default:
                return theme.palette.black;
        }
    }};
`;
