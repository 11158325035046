import * as React from 'react';
import { useParams, useLocation } from 'react-router-dom';

import { Breadcrumb, IBreadcrumbItem, IDividerAsProps } from '@fluentui/react/lib/Breadcrumb';
import { Label, ILabelStyles } from '@fluentui/react/lib/Label';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import { Icon } from '@fluentui/react/lib/Icon';
import { Wrapper } from './styles';
import { useSelector } from 'react-redux';
import { projectsReducerValues } from '../../../Projects/reducer';

const labelStyles: Partial<ILabelStyles> = {
    root: { margin: '10px 0', selectors: { '&:not(:first-child)': { marginTop: 24 } } },
};

export const Breadcrumbs = ({ ...props }) => {
    const location = useLocation();
    const { projectsById, projectsList, activeProjectId } = useSelector(projectsReducerValues);

    const nameMap = React.useMemo(() => {
        const projectName = projectsById && activeProjectId ? projectsById?.[activeProjectId].name : '';
        return {
            projects: 'Projects',
            // project: `Project ${projectName}`,
            project: `Project`,
            pl: 'Location',
            plan: 'Plan',
        };
    }, [activeProjectId]);

    const items: IBreadcrumbItem[] = React.useMemo(() => {
        let sumHref = window.location.origin;
        const result = location.pathname
            .substring(1)
            .split('/')
            .reduce(
                (acc: any[], item: string, i: number, arr: string[]) => {
                    if (i % 2 !== 0) {
                        const key = arr[i - 1] as keyof typeof nameMap;
                        const obj = { text: nameMap[key], key, href: `${[arr[i - 1]]}/${item}` };

                        return [...acc, obj];
                    } else {
                        return acc;
                    }
                },
                [{ text: nameMap['projects'], key: 'projects', href: '' }],
            )
            .map((item: any, i: number) => {
                if (i !== 0) {
                    sumHref += `/${item.href}`;
                } else {
                    sumHref += `${item.href}`;
                }
                return { ...item, href: sumHref };
            });

        return result;
    }, [location.pathname]);

    const _returnUndefined = () => undefined;

    return (
        <Wrapper>
            <Breadcrumb
                items={items}
                ariaLabel="Breadcrumb with items rendered as links"
                overflowAriaLabel="More links"
                // Returning undefined to OnReduceData tells the breadcrumb not to shrink
                onReduceData={_returnUndefined}
            />
        </Wrapper>
    );
};

function _onBreadcrumbItemClicked(ev: React.MouseEvent<HTMLElement>, item: IBreadcrumbItem): void {
    console.log(`Breadcrumb item with key "${item.key}" has been clicked.`);
}

function _getCustomDivider(dividerProps: IDividerAsProps): JSX.Element {
    const tooltipText = dividerProps.item ? dividerProps.item.text : '';
    return (
        <TooltipHost content={`Show ${tooltipText} contents`} calloutProps={{ gapSpace: 0 }}>
            <span aria-hidden="true" style={{ cursor: 'pointer', padding: 5 }}>
                /
            </span>
        </TooltipHost>
    );
}

function _getCustomOverflowIcon(): JSX.Element {
    return <Icon iconName={'ChevronDown'} />;
}
