import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './navPolygons.store';

import { INavPolygon, INavPolygonsReducer, IObjects } from './navPolygons.interfaces';
import { IPlan } from '../../../Plans/Plans.interfaces';
import { commonTools } from '../../../../tools/commonTools';
import { cloneDeep } from 'lodash';
import { DateTime } from 'luxon';
import { IExtendedPlan, IGeneralSettings, INodeContextMenu, IHotAddObject } from '../../layers.interfaces';

const initialState: INavPolygonsReducer = {
    layerAlias: 'nav_polygons_layer',
    selectedObjectId: null,
    activeToolId: null,
    objects: {
        polygons_out: [],
        polygons_in: [],
    },
    createdAt: undefined,
    currentPlanData: null,
    showLabels: false,
    navPolygonFrontId: '',
    selectedObjects: null,
    generalSettings: {} as IGeneralSettings,
    typeSelect: 'polygons_in',
    contextMenu: { show: false },
    hotAddObject: null,
    objectsTemp: {
        polygons_out: [],
        polygons_in: [],
    }
};

export const NavPolygonsReducer = createSlice({
    name: 'NavPolygonsReducer',
    initialState,
    reducers: {
        /**
         * Переключает активный инструмент
         */
        toggleTool: (state, action: PayloadAction<string>) => {
            state.activeToolId = action.payload;
        },

        /**
         * Запись информации для добавления объекта, после нажатия хоткея
         */
        storeHotAddObject: (state, action: PayloadAction<IHotAddObject>) => {
            state.hotAddObject = action.payload;
        },

        /**
         * Открывает контекстное меню с выбором: удалить узелок (если их больше 3) или удалить весь рисунок
         */
        toggleContextMenu: (state, action: PayloadAction<INodeContextMenu>) => {
            state.contextMenu = action.payload;
        },

        /**
         * Удаление узелка
         */
        deleteAnchor: (state, action: PayloadAction<{ navPolygonFrontId: string; anchorIndex: number }>) => {
            const { navPolygonFrontId, anchorIndex } = action.payload;
            const objects = cloneDeep(state.objects);
            const typeSelect = cloneDeep(state.typeSelect);

            const objectsOfNavPolygons = objects[typeSelect].map((navPolygon: INavPolygon) => {
                if (navPolygon.front_id === navPolygonFrontId) {
                    const coordinates = cloneDeep(navPolygon.coords.coordinates);
                    coordinates.splice(anchorIndex, 1);
                    const result = { ...navPolygon, coords: { ...navPolygon.coords, coordinates } };
                    return result;
                }

                return navPolygon;
            });
            objects[typeSelect] = objectsOfNavPolygons;

            state.objects = objects;
            state.objectsTemp = objects;
        },
        /**
         * Созжание нового помещений
         */
        storeNewNavPolygon: (state, action: PayloadAction<Array<Array<number>>>) => {
            const objects2 = cloneDeep(state.objects);
            const typeSelect = cloneDeep(state.typeSelect);
            const id = `nav_polygon:${+DateTime.local()}:${commonTools.generateId()}`;

            objects2[typeSelect].push({
                front_id: id,
                coords: {
                    type: 'Polygon',
                    coordinates: action.payload,
                },
            });

            state.objects = objects2;
            state.objectsTemp = objects2;
        },
        /**
         * Изменение координат выделеного объекта
         */
        changeCoords: (state, action: PayloadAction<{ coordsS: number[][] | undefined; front_id: string }>) => {
            const { coordsS, front_id } = action.payload;
            const typeSelect = cloneDeep(state.typeSelect);
            const objects = cloneDeep(state.objects[typeSelect]);
            objects.forEach((item, index) => {
                if (item.front_id === front_id) {
                    item.coords.coordinates = coordsS!;
                }
            });

            state.objects[typeSelect] = objects;
        },

        /***
         * Запись в стор загруженных с сервера объектов
         */
        storeInitialObjects: (state, action: PayloadAction<IObjects>) => {
            state.objects = action.payload;
            state.objectsTemp = action.payload
        },

        /**
         * Запись в стор поля createdAt
         */
        storeInitialCreatedAt: (state, action: PayloadAction<string | undefined>) => {
            state.createdAt = action.payload;
        },

        /**
         * Запись в стор данных по текущему плану
         * @param state
         * @param action
         */
        storeCurrentPlanData: (state, action: PayloadAction<IPlan>) => {
            state.currentPlanData = action.payload;
        },

        /**
         * Переключение показа маркеров на объектах
         */
        toggleShowLabels: (state, action: PayloadAction<boolean>) => {
            state.showLabels = action.payload;
        },

        /**
         * Выбор группы зон
         */
        selectGroupNavPolygons: (state, action: PayloadAction<{ key: string | undefined }>) => {
            const { key } = action.payload;

            state.navPolygonFrontId = '';
            state.selectedObjects = null;
            state.typeSelect = key!;
        },

        /**
         * Добавление новых точек в полигон
         */
        addAnchor: (
            state,
            action: PayloadAction<{ anchorCoords?: number[] | undefined; objectId?: string | undefined }>,
        ) => {
            const { anchorCoords, objectId } = action.payload;
            const typeSelect = cloneDeep(state.typeSelect);
            const objects = cloneDeep(state.objects);

            const navPolygons = objects[typeSelect].map((element) => {
                if (element.front_id !== objectId) {
                    return element;
                } else {
                    const coords = element.coords.coordinates;
                    let index = null;
                    let statusAndAnchors = false;
                    coords.forEach((point, i, arr) => {
                        if (i > 0) {
                            if (commonTools.between(arr[i - 1], point, anchorCoords!)) {
                                index = i;
                                statusAndAnchors = false;
                            }
                        } else if (i === 0) {
                            if (commonTools.between(arr[arr.length - 1], point, anchorCoords!)) {
                                statusAndAnchors = true;
                                index = null;
                            }
                        }
                    });

                    if (index !== null) {
                        coords.splice(index, 0, anchorCoords!);
                    } else if (statusAndAnchors) {
                        coords.push(anchorCoords!);
                    }
                    return {
                        ...element,
                        coords: {
                            ...element.coords,
                            coordinates: coords,
                        },
                    };
                }
            });

            let objectsTemp = cloneDeep(objects);
            objectsTemp[typeSelect] = navPolygons;
            //
            state.objects = objectsTemp;
            state.objectsTemp = objectsTemp;
        },

        /**
         * Выбор объекта
         */
        selectNavPolygon: (
            state,
            action: PayloadAction<{ groupFrontId?: string; itemFrontId: string | undefined }>,
        ) => {
            const { itemFrontId } = action.payload;
            let objects = cloneDeep(state.objects);
            let typeSelect = cloneDeep(state.typeSelect);
            let selectedObjects = cloneDeep(state.selectedObjects);
            Object.keys(objects).map((item, index) => {
                objects[item].map((itemNavPolygons, indexNavPolygons) => {
                    if (itemNavPolygons.front_id === itemFrontId) {
                        typeSelect = item;
                        selectedObjects = itemNavPolygons;
                    }
                });
            });

            state.selectedObjects = selectedObjects;
            state.navPolygonFrontId = itemFrontId!;
            state.typeSelect = typeSelect;
        },

        /**
         * Уделение объекта
         */
        deleteNavPolygon: (state, action: PayloadAction<{ navPolygonFrontId: string | undefined }>) => {
            const { navPolygonFrontId } = action.payload;
            let objects = cloneDeep(state.objects);
            let typeSelect = cloneDeep(state.typeSelect);

            const objectsOfNavPolygons = objects[typeSelect].filter(
                (navPolygon: INavPolygon) => navPolygon.front_id !== navPolygonFrontId,
            );

            objects[typeSelect] = objectsOfNavPolygons;
            // let indexTemp: number = 0;

            // Object.keys(objects).map((item, index) => {
            //     objects[item].map((itemNavPolygons, indexNavPolygons) => {
            //         if (itemNavPolygons.front_id === navPolygonFrontId) {
            //             typeSelect = item;
            //             indexTemp = index;
            //         }
            //     });
            // });
            // if (objects[typeSelect].length === 1) {
            //     objects[typeSelect] = [];
            // } else {
            //     objects[typeSelect].splice(indexTemp, 1);
            // }

            state.objects = objects;
            state.objectsTemp = objects;
            state.typeSelect = typeSelect;
            state.selectedObjects = null;
            state.navPolygonFrontId = '';
        },

        /**
         * Очистка выделения
         */
        clearSelectedObjects: (state, action: PayloadAction) => {
            state.selectedObjects = null;
            state.navPolygonFrontId = '';
        },

        /**
         * Запись основных настроек.
         */
        storeGeneralSettings: (state, action: PayloadAction<IGeneralSettings>) => {
            state.generalSettings = action.payload;
        },

        changeShapeCoords: (state, action: PayloadAction) => {
            state.objectsTemp = state.objects;
        },

        removeLastCoords: (state, action: PayloadAction) => {
            state.objects = state.objectsTemp;
        },

        changeCoordsFullLayer: (state, action: PayloadAction<INavPolygon[]>) => {
            const typeSelect = cloneDeep(state.typeSelect);
            state.objects[typeSelect] = action.payload;
        },
    },
});

export const {
    toggleTool,
    changeCoords,
    storeNewNavPolygon,
    selectGroupNavPolygons,
    storeInitialCreatedAt,
    storeInitialObjects,
    storeCurrentPlanData,
    toggleShowLabels,
    addAnchor,
    selectNavPolygon,
    deleteNavPolygon,
    storeGeneralSettings,
    clearSelectedObjects,
    toggleContextMenu,
    deleteAnchor,
    storeHotAddObject,
    changeShapeCoords,
    removeLastCoords,
    changeCoordsFullLayer
} = NavPolygonsReducer.actions;

export const navPolygonsValues = (state: RootState) => state.NavPolygonsReducer;

export default NavPolygonsReducer.reducer;
