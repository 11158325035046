import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { reducerValues } from '../../../../reducer';
import { CommonDataWrapper, TextBox, ValueBox, Row } from './styles';
import { DefaultButton, Stack } from '@fluentui/react';
import { describeSensors } from '../../../../core/describeSensors';
import Modals from '../../../../../../../Modals/Modals';
import MonitoringDetailedData from './components/MonitoringDetailedData/MonitoringDetailedData';

/**
 * Компонент списка объектов слоя
 */
const CommonData = () => {
    const { t } = useTranslation();
    const { sensors, pcIpoints, maxMeasures } = useSelector(reducerValues);
    const [detailedDataModalStatus, setDetailedDataModalStatus] = useState({ show: false });

    const data = useMemo(() => {
        const extendedSensors = sensors?.map((item) => {
            return { ...item, state: describeSensors(item.values, maxMeasures) };
        });

        const fullNumber = extendedSensors?.filter((item) => item.state.operation === 'full').length;
        const partialNumber = extendedSensors?.filter((item) => item.state.operation === 'partial').length;
        const notWorkingNumber = extendedSensors?.filter((item) => item.state.operation === 'no').length;

        return [
            { text: t('PCipoints number'), value: pcIpoints?.length, color: 'black', bgColor: 'white' },
            { text: t('Sensors number'), value: sensors?.length, color: 'black', bgColor: 'white' },
            { text: t('Fully operational sensors'), value: fullNumber, color: 'white', bgColor: '#7CFC00' },
            { text: t('Partially operational sensors'), value: partialNumber, color: 'white', bgColor: 'yellow' },
            { text: t('Not operational sensors'), value: notWorkingNumber, color: 'white', bgColor: 'red' },
        ].map((item) => {
            return (
                <Row key={`${item.bgColor}++${item.text}`}>
                    <TextBox>{item.text}</TextBox>
                    <ValueBox color={item.color} bgColor={item.bgColor}>
                        {item.value}
                    </ValueBox>
                </Row>
            );
        });
    }, [pcIpoints?.length, sensors, t]);

    const openDetailedModal = () => {
        setDetailedDataModalStatus({ show: true });
    };

    const closeDetailedModal = () => {
        setDetailedDataModalStatus({ show: false });
    };

    return (
        <>
            <Modals closeModal={closeDetailedModal} modalStatus={detailedDataModalStatus} title={t('Detailed data')}>
                <MonitoringDetailedData />
            </Modals>
            {sensors && pcIpoints ? (
                <Stack tokens={{ childrenGap: 10, padding: '15px 0px 0px 0px' }}>
                    <DefaultButton onClick={openDetailedModal}>{t('Show detailed data')}</DefaultButton>
                    <CommonDataWrapper>{data}</CommonDataWrapper>
                </Stack>
            ) : null}
        </>
    );
};

export default CommonData;
