import React from 'react';

import { Layer, Rect } from 'react-konva';
import { IHelperProps } from './Helper.interfaces';
import { IPlan } from '../commonInterfaces';
import { commonTools } from '../commonTools';
import { sizes } from '../../constants/sizes';
import { IExtendedPlan } from '../../components/Layers/layers.interfaces';
import { KonvaEventObject } from 'konva/lib/Node';

/**
 * Компонент вспомогательного прозрачного прямоугольника
 */
export const Helper = ({ updateCoords, onHelperClick, isDrawNow, currentPlanData, snapToGrid }: IHelperProps) => {
    const planHeight = currentPlanData?.planHeight || 2000;
    const planWidth = currentPlanData?.planWidth || 2000;
    const imageOffset = currentPlanData?.imageOffset || [0, 0];
    const onHelperMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        if (isDrawNow === true) {
            const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
            if (newPoint) {
                updateCoords(newPoint);
            }
        }
    };

    return (
        <Rect
            onMouseMove={onHelperMouseMove}
            onClick={onHelperClick}
            width={planWidth + imageOffset[0] + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND}
            height={planHeight + imageOffset[1] + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND}
            // fill={'rgba(255,0,0,0.3)'}
            fill={'transparent'}
        />
    );
};
