import { sizes } from '../../../../../../../constants/sizes';
import { commonTools } from '../../../../../layers';
import { IExtendedPlan, IPlan } from '../../../../../layers.interfaces';
import { IDrawNewCutArgs } from './drawNewCut.interfaces';

/**
 * Рисование линии.
 *
 */
export const drawNewPoint = ({ currentPlanData, snapToGrid, e, updateIsDrawNow, addNewPoint }: IDrawNewCutArgs) => {
    const newPoint = commonTools.getPointerCoords({ e, currentPlanData, snapToGrid });
    updateIsDrawNow(true);
    newPoint && addNewPoint(newPoint);
};
