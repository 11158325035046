import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CloseSquare, MinusSquare, PlusSquare } from './components/Icons';
import { IModifications } from './SyncPanelInterfaces';
import TreeView from '@mui/lab/TreeView';

import { SyncPanelReducerValues, fetchSync } from './SyncPanel.reducer';
import { Header, ItemWrapper, Wrapper } from './SyncPanel.styles';
import StyledTreeItem from './components/StyledTreeItem';
import { useTranslation } from 'react-i18next';

const SyncPanel = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState<string[]>([]);

    const { modifications } = useSelector(SyncPanelReducerValues);

    useEffect(() => {
        if (!modifications) return;
        const nodeIds = Object.keys(modifications).map((key) => key);
        setExpanded(nodeIds);
    }, [modifications]);

    const objectsToSync = modifications
        ? Object.values(modifications).reduce((acc, value) => {
              return acc + value?.length;
          }, 0)
        : 0;

    const tree = () => {
        return modifications
            ? Object.keys(modifications).map((key) => {
                  const layers = modifications?.[key as keyof IModifications].map((mod) => {
                      return <StyledTreeItem key={`${mod}`} nodeId={`${mod}`} label={mod}></StyledTreeItem>;
                  });

                  return (
                      <ItemWrapper key={`UUUttt${key}`}>
                          <StyledTreeItem nodeId={key} label={` ${key}`}>
                              {layers}
                          </StyledTreeItem>
                      </ItemWrapper>
                  );
              })
            : null;
    };

    const handleToggle = (event: React.SyntheticEvent, nodeIds: string[]) => {
        setExpanded(nodeIds);
    };

    return (
        <Wrapper>
            <Header>{`${t('Objects to sync')}: ${objectsToSync}`}</Header>
            <TreeView
                aria-label="customized"
                expanded={expanded}
                onNodeToggle={handleToggle}
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                sx={{
                    height: 'auto',
                    maxHeight: '100%',
                    flexGrow: 1,
                    overflowY: 'auto',
                    paddingTop: '10px',
                    paddingBottom: '30px',
                }}
            >
                {tree()}
            </TreeView>
        </Wrapper>
    );
};

export default SyncPanel;
