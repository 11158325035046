import styled from 'styled-components/macro';
import { Stack, IStackProps, IStackTokens } from '@fluentui/react/lib/Stack';
import { theme } from '../../../../../../../../constants/theme';

export const ObjectOptionsWrapper = styled.div``;
export const AreaWarning = styled.div`
    color: ${(p) => theme.palette.red};
    margin: 10px 0;
    font-size: small;
    max-width: 220px;
`;
export const StyledStack = styled(Stack)`
    .ms-TextField-fieldGroup {
        background-color: transparent;
    }
    label {
        padding-left: 0px;
    }
`;
