import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { KonvaEventObject } from 'konva/lib/Node';
import { Layer, Group } from 'react-konva';

import { reducerValues, selectSensor, toggleContextMenu, resetSensors } from '../../reducer';
import Helper from './components/Helper/Helper';
import { commonTools, ObjectLabel } from '../../../../monitoringLayers';
import { IHelperMouseMoveArgs } from './components/Helper/Helper.interfaces';
import Sensor from './components/Sensor/Sensor';
import IPoint from './components/IPoint/IPoint';
import { cloneDeep } from 'lodash';
import { ISensor } from '../../interfaces';

/**
 * Компонент графического редактора
 */
const Draw = ({ ...props }) => {
    const {
        activeToolId,
        pcIpoints,
        sensors,
        selectedSensorId,
        currentPlanData,
        showLabels,
        contextMenu,
        maxMeasures,
    } = useSelector(reducerValues);
    const dispatch = useDispatch();
    const [isDrawNow, setIsDrawNow] = useState(false);
    const [canTransform, setCanTransform] = useState(false);
    const [coords, setCoords] = useState<Array<number[]>>([]);
    const [currentPointerCoords, setCurrentPointerCoords] = useState<number[] | undefined>(undefined);

    useEffect(() => {
        return () => {
            dispatch(resetSensors());
        };
    }, []);

    useEffect(() => {
        const container = props.stage.current.container();
        if (activeToolId === 'draw' && container) {
            container.style.cursor = 'crosshair';
        } else if (activeToolId === 'anchorsEdit' && container) {
            container.style.cursor = 'pointer';
        } else {
            container.style.cursor = 'auto';
        }
    }, [activeToolId]);

    const updateCanTransform = (e: KonvaEventObject<MouseEvent>, can: boolean) => {
        props.stage.current.setAttr('draggable', !can);
        setCanTransform(() => can);
    };

    const onHelperClick = (e: KonvaEventObject<MouseEvent>) => {
        if (activeToolId === 'draw') {
            const newPoint = commonTools.getPointerCoords({ e, snapToGrid: false });
        } else if (contextMenu.show) {
            dispatch(toggleContextMenu({ show: false }));
        } else {
            dispatch(selectSensor());
        }
    };

    const onObjectClick = (frontId?: number) => {
        if (activeToolId !== 'draw') {
            dispatch(selectSensor(frontId));
        }
    };

    const onHelperMouseMove = ({ e }: IHelperMouseMoveArgs) => {
        setCurrentPointerCoords(commonTools.getPointerCoords({ e, snapToGrid: false }));
        if (isDrawNow === true) {
            const newPoint = commonTools.getPointerCoords({ e, snapToGrid: false });
            if (newPoint) {
                coords.pop();
                setCoords([...coords, newPoint]);
            }
        }
    };

    const geometry = useMemo(() => {
        if (!pcIpoints || !sensors || !currentPlanData?.planScale) return null;

        const sensorsGeometry = cloneDeep(sensors)
            .sort((a, b) => {
                if (a.sensor_id === selectedSensorId) return 1;
                return -1;
            })
            .map((item, i) => {
                const { planScale } = currentPlanData;
                const selected = item.sensor_id === selectedSensorId;
                return (
                    <Sensor
                        key={`sensor++${item.sensor_serial}${i}`}
                        planScale={planScale}
                        maxMeasures={maxMeasures}
                        stageScale={props.scale.stageScale}
                        onClick={onObjectClick}
                        onHelperMouseMove={onHelperMouseMove}
                        selected={selected}
                        activeToolId={activeToolId}
                        {...item}
                    />
                );
            });

        const iPointsGeometry = cloneDeep(pcIpoints).map((item) => {
            const { planScale } = currentPlanData;
            return (
                <IPoint
                    key={`ipoint++${item.frontId}`}
                    planScale={planScale}
                    stageScale={props.scale.stageScale}
                    onClick={onObjectClick}
                    onHelperMouseMove={onHelperMouseMove}
                    selected={false}
                    activeToolId={activeToolId}
                    {...item}
                />
            );
        });

        return (
            <Group>
                {iPointsGeometry}
                {sensorsGeometry}
            </Group>
        );
    }, [
        pcIpoints,
        sensors,
        currentPlanData,
        selectedSensorId,
        props.scale.stageScale,
        onObjectClick,
        onHelperMouseMove,
        activeToolId,
    ]);

    const labels = useMemo(() => {
        if (!pcIpoints || !sensors || !currentPlanData?.planScale) return null;

        const unselectedLabels: Array<JSX.Element> = [];
        const selectedLabels: Array<JSX.Element> = [];

        cloneDeep(pcIpoints).forEach((item) => {
            let sensorMarker = null;
            let selected = false;
            sensors.forEach((sensor) => {
                if (sensor.ipoint_marker === item.marker) {
                    sensorMarker = `${sensor.sensor_type} ${sensor.sensor_serial}`;
                    if (sensor.sensor_id === selectedSensorId) {
                        selected = true;
                    }
                }
            });
            const marker = sensorMarker
                ? `pcIpoint: ${item.marker}\nsensor: ${sensorMarker}`
                : `pcIpoint: ${item.marker}`;

            if (selected) {
                selectedLabels.push(
                    <ObjectLabel
                        key={`pcIpoint: ${item.marker}`}
                        stageScale={props.scale.stageScale}
                        x={item.centerPoint.coordinates[0]}
                        y={item.centerPoint.coordinates[1]}
                        marker={marker}
                        selected={selected}
                    />,
                );
            } else {
                unselectedLabels.push(
                    <ObjectLabel
                        key={`pcIpoint: ${item.marker}`}
                        stageScale={props.scale.stageScale}
                        x={item.centerPoint.coordinates[0]}
                        y={item.centerPoint.coordinates[1]}
                        marker={marker}
                        selected={selected}
                    />,
                );
            }
        });
        return (
            showLabels && (
                <Group>
                    {unselectedLabels}
                    {selectedLabels}
                </Group>
            )
        );
    }, [currentPlanData?.planScale, pcIpoints, props.scale.stageScale, selectedSensorId, sensors, showLabels]);

    try {
        return currentPlanData ? (
            <>
                <Layer key={props.key}>
                    <Helper
                        currentPlanData={currentPlanData}
                        isDrawNow={isDrawNow}
                        coords={coords}
                        onHelperClick={onHelperClick}
                        onHelperMouseMove={onHelperMouseMove}
                        updateCanTransform={updateCanTransform}
                    />
                    {geometry}
                    {labels}
                </Layer>
            </>
        ) : null;
    } catch (error) {
        return null;
    }
};

export default Draw;
