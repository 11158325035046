import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { settingsReducerValues, changeLang } from './Settings.reducer';
import { SettingsContext, initialSettingsData } from './Settings.context';
import { useTranslation } from 'react-i18next';
import SettingsButton from './components/SettingsButton/SettingsButton';

/**
 * Компонента Настройки приложения.
 */
const Settings: React.FunctionComponent = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        generalSettings: { lang }
    } = useSelector(settingsReducerValues);

    const changeLangTrans = (lang: string) => {
        dispatch(changeLang(lang));
    };

    const settingsContextValue = {
        ...initialSettingsData,
        changeLangTrans,
        t,
        lang
    };

    return (
        <SettingsContext.Provider value={settingsContextValue}>
            <SettingsButton />
        </SettingsContext.Provider>
    );
};

export default Settings;
