import React from 'react';

import { alpha, styled } from '@mui/material/styles';
import TreeItem, { TreeItemProps, treeItemClasses } from '@mui/lab/TreeItem';
import { colors } from '@mui/material';

const StyledTreeItem = styled((props: TreeItemProps) => {
    return <TreeItem {...props} />;
})((args) => {
    return {
        [`& .${treeItemClasses.iconContainer}`]: {
            '& .close': {
                opacity: 0.3,
            },
        },
        [`& .${treeItemClasses.group}`]: {
            marginLeft: 15,
            paddingLeft: 18,
            borderLeft: `1px dashed ${alpha(args.theme.palette.text.primary, 0.4)}`,
        },
        [`& > div`]: { color: args.className ? args.className : 'rgb(50, 49, 48)' },
    };
});

export default StyledTreeItem;
