import React, { useContext } from 'react';
import { EditorContext } from '../../Editor.context';
import { GeneralContext } from '../../../../General.context';
import { ILayer } from '../../../Layers/layers.interfaces';
import { IMonitoringLayer } from '../../../Monitoring/interfaces';

/**
 * Компонента панели Контекстного меню.
 */

const ContextMenuPanel = () => {
    const { layers, activeLayerId, monitoringLayers, activeMonitoringLayerAlias, selectedChapter } =
        useContext(EditorContext);
    const { generalSettings } = useContext(GeneralContext);

    if (!layers || layers.length === 0 || !activeLayerId) return null;

    const contextMenu = () => {
        switch (selectedChapter) {
            case 'layers':
                const activeLayer = layers.filter(
                    (item: ILayer) => item.id === activeLayerId || item.id === 'measureCut',
                )[0];
                const ContextMenuComponenta = activeLayer?.contextMenu;
                return activeLayer && ContextMenuComponenta && !generalSettings?.readOnly ? (
                    <ContextMenuComponenta />
                ) : null;

            case 'monitoring':
                const activeMonitoringLayer = monitoringLayers.filter(
                    (item: IMonitoringLayer) => item.alias === activeMonitoringLayerAlias,
                )[0];
                const ContextMenuComponenta1 = activeMonitoringLayer?.contextMenu;
                return activeMonitoringLayer && !generalSettings?.readOnly ? <ContextMenuComponenta1 /> : null;

            default:
                return null;
        }
    };

    return contextMenu();
};

export default ContextMenuPanel;
