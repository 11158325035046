import { cloneDeep } from 'lodash';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';
import { DateTime } from 'luxon';

import { deleteRequest, getLocalBase64Image, getRequest, postRequest } from '../../tools/api';
import { mapBack2Front } from '../../tools/mappingFrontAndBack';
import { IPlansReducerState, IPlan, IVersion, IDeletePlanArgs, IUploadPDFArgs, IPdfPlan } from './Plans.interfaces';
import { plansMapping } from '../../constants/keysMapping';
import { geoMatrixConverner } from '../../tools/geoMatrixConverter';
import { fetchVersions, storeVersions } from '../Versions/Versions.reducer';
import { version } from 'react';
import { fetchActiveLocation, storeActiveProjectLocation } from '../Locations/Locations.reducer';

const initialState: IPlansReducerState = {
    plansList: [],
    pdfPlans: [],
    planToAddMeasureCut: null,
    planToEdit: null,
    planBlueprintScale: 1,
    imageOffset: [0, 0],
    plansIdsByVersionIds: {},
    pdfModalStatus: { show: false },
};

export const PlansReducer = createSlice({
    name: 'PlansReducer',
    initialState,
    reducers: {
        /**
         * Сохранение списка планов в стор.
         */
        storePlans: (state, action: PayloadAction<{ plans: IPlan[]; versions: IVersion[] }>) => {
            if (!action.payload.plans) {
                state.plansList = [];
                return;
            }

            let counter = -9999;

            const floorsWithMainPlan: number[] = [];

            const plansList: IPlan[] = action.payload.plans
                .map((item) => {
                    const mappedPlan = mapBack2Front(plansMapping, item) as IPlan;
                    mappedPlan.isMain && floorsWithMainPlan.push(mappedPlan.floor);
                    return mappedPlan;
                })
                .slice()
                .sort((a, b) => {
                    if (a.activeFrom && b.activeFrom) {
                        return DateTime.fromISO(b.activeFrom).toMillis() - DateTime.fromISO(a.activeFrom).toMillis();
                    } else return 0;
                })
                .sort((a, b) => {
                    return Number(b.floor) - Number(a.floor);
                })
                .map((item) => {
                    if (item.floor !== counter && floorsWithMainPlan.includes(item.floor)) {
                        counter = item.floor;
                        return { ...item, isActual: true };
                    } else {
                        return { ...item, isActual: false };
                    }
                });

            const plansIdsByVersionIds: { [x: string]: number[] } = {};

            action.payload.versions.forEach((item) => {
                const versionEndTS = item.date_to ? DateTime.fromISO(item.date_to).valueOf() : Infinity;

                const plansIncluded = plansList
                    .filter((plan) => {
                        const result = versionEndTS >= DateTime.fromISO(plan.activeFrom as string).valueOf();
                        return result;
                    })
                    .sort((a, b) => b.floor - a.floor)
                    .map((plan) => plan.planId) as number[];

                plansIdsByVersionIds[item.id] = plansIncluded;
            });

            state.plansList = plansList as Array<IPlan>;
            state.plansIdsByVersionIds = plansIdsByVersionIds;
        },

        /**
         * Сохранение плана для добавления мерного отрезка.
         */
        storePlanToAddMeasureCut: (state, action: PayloadAction<{ planToAddMeasureCut: IPlan | null }>) => {
            state.planToAddMeasureCut = action.payload.planToAddMeasureCut;
        },
        /**
         * Сохранение текущего плана.
         */
        storePlanToEdit: (state, action: PayloadAction<{ planToEdit: IPlan }>) => {
            const { planToEdit } = action.payload;
            const { plansList } = cloneDeep(state);

            let planBlueprintScale = 1;
            let imageOffset = [0, 0];
            const mainPlan = plansList
                .filter((plan) => plan.floor === planToEdit?.floor)
                .filter((plan) => plan.isMain)[0];
            let mainPlanScale = mainPlan?.planScale || 1;

            if (mainPlanScale && planToEdit?.planScale) {
                planBlueprintScale = mainPlanScale / planToEdit?.planScale;
                const vector = geoMatrixConverner(0, 0, planToEdit.plan2geo);
                if (vector && vector[0]) {
                    imageOffset = geoMatrixConverner(vector[0], vector[1], mainPlan.geo2plan).map((item) => {
                        if (Math.abs(item) < 0.1) {
                            return 0;
                        } else {
                            return item;
                        }
                    });
                }
            }

            state.planToEdit = planToEdit;
            state.planBlueprintScale = planBlueprintScale;
            state.imageOffset = imageOffset;
        },
        /**
         * Сброс редьюсера.
         */
        resetPlansReducer: () => initialState,

        storePdfModalStatus: (state, action: PayloadAction<{ show: boolean; floor?: number }>) => {
            state.pdfModalStatus = action.payload;
        },

        storePdfPlans: (state, action: PayloadAction<IPdfPlan[]>) => {
            state.pdfPlans = action.payload;
        },
    },
});

export const { storePlanToAddMeasureCut, storePlanToEdit, storePdfPlans, resetPlansReducer, storePdfModalStatus } =
    PlansReducer.actions;

const getQueryStringParams = (query: string) => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query).split('&').reduce((params: { [x: string]: string }, param) => {
              let [key, value] = param.split('=');
              params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
              return params;
          }, {})
        : {};
};


export const fetchPlData =
    (url: string, token: string, plId: string): AppThunk =>
    (dispatch, getState) => {
        const {
            token,
            urls: { VERSIONS_URL },
        } = cloneDeep(getState().GeneralReducer);
        getRequest({ url: `${url}${plId}/`, dispatch, allowSpinner: true, token }).then((data) => {
            dispatch(storeActiveProjectLocation(data));
            const url = new URL(data?.map_service_link);
            const params = new URLSearchParams(url.search);
            const ml_id = params.get('location_id');
            if (ml_id) {
                dispatch(fetchVersions(VERSIONS_URL, token, ml_id));
                dispatch(fetchActiveLocation(ml_id));
            }
        });
    };
/**
 * Запрос списка планов с сервера.
 */
export const fetchPlans =
    (plansUrl: string, token: string | null, activeLocationId: string | number | null): AppThunk =>
    (dispatch, getState) => {
        const url = activeLocationId === null ? plansUrl : `${plansUrl}?location_id=${activeLocationId}`;
        const { activeVersionId, versions } = cloneDeep(getState().VersionsReducer);
        getRequest({ url, dispatch, allowSpinner: true, token }).then((plans) => {
            dispatch(PlansReducer.actions.storePlans({ plans, versions }));
        });
    };
/**
 * Запрос списка панов с сервера.
 */
export const storePlansWithVersionChange = (): AppThunk => (dispatch, getState) => {
    const getTS = (str: string) => DateTime.fromISO(str).valueOf();
    const { activeVersionId, versions } = cloneDeep(getState().VersionsReducer);
    const { plansList, planToEdit } = cloneDeep(getState().PlansReducer);

    let plans = cloneDeep(plansList);
    if (activeVersionId) {
        const activeVersion = versions.filter((item) => item.id === activeVersionId)[0];
        // if (activeVersion.date_to) {
        //     plans = cloneDeep(plansList).filter(
        //         (item: IPlan) =>
        //             getTS(item.activeFrom as string) <= getTS(activeVersion.date_to as string) && item.isActual,
        //     );
        // }

        const planIds = plans.map((item) => item.planId);
        if (!planIds.includes(planToEdit?.planId)) {
            dispatch(PlansReducer.actions.storePlanToEdit({ planToEdit: plans[0] }));
        }

        // dispatch(PlansReducer.actions.storePlans(plans));
    }
};

/**
 * Удаление плана из локации
 */

export const deletePlan =
    (args: IDeletePlanArgs): AppThunk =>
    (dispatch, getState) => {
        const { planId, activeLocationId } = args;
        if (!planId && !activeLocationId) return;

        const {
            token,
            urls: { PLANS_URL },
        } = cloneDeep(getState().GeneralReducer);
        const deletePlanUrl = `${PLANS_URL}${planId}/`;

        deleteRequest({ url: deletePlanUrl, dispatch, token, allowSpinner: true }).then(() => {
            dispatch(resetPlansReducer());
            window.location.reload();
            dispatch(fetchPlans(PLANS_URL, token, activeLocationId));
        });
    };

export const plansReducerValues = (state: RootState) => state.PlansReducer;

export default PlansReducer.reducer;

export const uploadPdfToBack =
    (args: IUploadPDFArgs): AppThunk =>
    (dispatch, getState) => {
        const { file, notes, locationId, actualFrom, floor } = args;

        const { token, urls } = cloneDeep(getState().GeneralReducer);

        getLocalBase64Image(file, 'application').then((file) => {
            postRequest({
                url: urls.PLANS_ADD_URL,
                dispatch,
                alert: 'PDF plan uploaded!',
                allowSpinner: true,
                token,
                data: {
                    file,
                    location_id: locationId,
                    floor,
                    actual_from: DateTime.fromISO(actualFrom).toFormat('yyyy-MM-dd'),
                    comment: notes,
                },
            }).then((data) => {
                console.log('>>>', data);

                // dispatch(storePlanToAddMeasureCut({ planToAddMeasureCut: null }));
                // window.location.reload();
            });
        });
    };

export const fetchPdfPlans =
    (activeLocationId: number): AppThunk =>
    (dispatch, getState) => {
        const { token, urls } = cloneDeep(getState().GeneralReducer);
        dispatch(storePdfPlans([]));
        getRequest({
            url: `${urls.PLANS_ADD_URL}?location_id=${activeLocationId}`,
            dispatch,
            allowSpinner: true,
            token,
        }).then((data) => {
            dispatch(storePdfPlans(data));
        });
    };
