import React from 'react';
import { KonvaEventObject } from 'konva/lib/Node';

import { Layer, Rect } from 'react-konva';
import { IHelperProps } from './Helper.interfaces';
import { IExtendedPlan, IPlan } from '../../../../../../interfaces';
import { sizes } from '../../../../../../../../constants/sizes';

/**
 * Компонент вспомогательного прозрачного прямоугольника
 */
const Helper = ({ onHelperClick, currentPlanData, onHelperMouseMove, updateCanTransform }: IHelperProps) => {
    if (!currentPlanData) return null;
    const { planHeight = 2000, planWidth = 2000, imageOffset = [0, 0] } = currentPlanData as IPlan;

    const onMouseUp = (e: KonvaEventObject<MouseEvent>) => {
        updateCanTransform && updateCanTransform(e, false);
    };
    const onMouseMove = (e: KonvaEventObject<MouseEvent>) => {
        onHelperMouseMove && onHelperMouseMove({ e });
    };

    return (
        <Rect
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onClick={onHelperClick}
            width={planWidth + imageOffset[0] + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND}
            height={planHeight + imageOffset[1] + sizes.GLOBAL_SHIFT + sizes.HELPER_EXPAND}
            fill={'transparent'}
            opacity={0}
        />
    );
};

export default Helper;
