const flatten = require('@flatten-js/core');
const cloneDeep = require('lodash/cloneDeep');
const turf = require('@turf/turf');
const isEqual = require('lodash/isEqual');
const round = require('lodash/round');
const THREE = require('three');

function distanceFinder(initPoint, initSegment) {
    const { Point, Segment } = flatten;
    const p = new Point(initPoint[0], initPoint[1]);
    const s = new Segment(
        new Point(initSegment[0][0], initSegment[0][1]),
        new Point(initSegment[1][0], initSegment[1][1]),
    );
    return p.distanceTo(s)[0];
}

function isPointInsidePassWay(beamPoint, polyLine, polygonWidth) {
    let flag = false;
    let distance = null;
    polyLine.forEach((element, i, arr) => {
        if (i !== arr.length - 1 && !flag) {
            const res = distanceFinder(beamPoint, [element, arr[i + 1]]);
            if (res <= polygonWidth) {
                distance = res;
                flag = true;
            }
        }
    });
    return distance;
}

function getArrowPoint(point, i, arr, planScale, margin, direction = 'end') {
    var angle = direction === 'end' ? -Math.PI / 2 : Math.PI / 2;
    var normalV = new THREE.Vector3(0, 0, 1);
    var arrowV = new THREE.Vector3(point[0], point[1], 0)
        .sub(new THREE.Vector3((point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2, 0))
        .normalize()
        .multiplyScalar(Math.max(planScale * margin, planScale * 0.4))
        .applyAxisAngle(normalV, angle)
        .add(new THREE.Vector3((point[0] + arr[i - 1][0]) / 2, (point[1] + arr[i - 1][1]) / 2, 0));

    return [arrowV.x, arrowV.y];
}

function isInside(polygon, p) {
    const point = p.map((num) => Number(num.toFixed(2)));
    let INF = 1000000;

    const onSegment = (p, q, r) => {
        if (
            q[0] <= Math.max(p[0], r[0]) &&
            q[0] >= Math.min(p[0], r[0]) &&
            q[1] <= Math.max(p[1], r[1]) &&
            q[1] >= Math.min(p[1], r[1])
        ) {
            return true;
        }
        return false;
    };

    const orientation = (p, q, r) => {
        let val = (q[1] - p[1]) * (r[0] - q[0]) - (q[0] - p[0]) * (r[1] - q[1]);
        if (val === 0) {
            return 0; // colinear
        }
        return val > 0 ? 1 : 2; // clock or counterclock wise
    };

    const doIntersect = (p1, q1, p2, q2) => {
        let o1 = orientation(p1, q1, p2);
        let o2 = orientation(p1, q1, q2);
        let o3 = orientation(p2, q2, p1);
        let o4 = orientation(p2, q2, q1);
        if (o1 !== o2 && o3 !== o4) {
            return true;
        }
        if (o1 === 0 && onSegment(p1, p2, q1)) {
            return true;
        }
        if (o2 === 0 && onSegment(p1, q2, q1)) {
            return true;
        }
        if (o3 === 0 && onSegment(p2, p1, q2)) {
            return true;
        }
        if (o4 === 0 && onSegment(p2, q1, q2)) {
            return true;
        }
        return false;
    };

    const n = polygon.length;
    if (n < 3) {
        return false;
    }
    let extreme = [INF, point[1]];
    let count = 0,
        i = 0;
    do {
        let next = (i + 1) % n;
        if (doIntersect(polygon[i], polygon[next], point, extreme)) {
            if (orientation(polygon[i], point, polygon[next]) === 0) {
                return onSegment(polygon[i], point, polygon[next]);
            }

            count++;
        }
        i = next;
    } while (i !== 0);
    return count % 2 === 1; // Same as (count%2 == 1)
}

function findPolygonCenter(coords) {
    const coordsCopy = cloneDeep(coords);
    const first = coordsCopy[0],
        last = coordsCopy[coordsCopy.length - 1];
    if (first[0] !== last[0] || first[1] !== last[1]) coordsCopy.push(first);
    let twicearea = 0,
        x = 0,
        y = 0,
        nPts = coordsCopy.length,
        p1,
        p2,
        f;
    for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
        p1 = coordsCopy[i];
        p2 = coordsCopy[j];
        f = p1[0] * p2[1] - p2[0] * p1[1];
        twicearea += f;
        x += (p1[0] + p2[0]) * f;
        y += (p1[1] + p2[1]) * f;
    }
    f = twicearea * 3;
    return [x / f, y / f];
}

function getPolygonForTurf(coords, marker = '') {
    const firstPoint = coords[0];
    const lastPoint = coords[coords.length - 1];

    const arr = cloneDeep(coords);

    if (!isEqual(firstPoint, lastPoint)) {
        arr.push(firstPoint);
    }

    const poly = turf.polygon([arr]);

    var kinks = turf.kinks(poly);
    if (kinks.features.length) {
        console.warn('<<<< INCORRECT POLYGON >>>>  ', marker);
        try {
            const unkinked = turf.unkinkPolygon(poly);
            return unkinked;
        } catch (error) {
            return poly;
        }
    } else {
        return poly;
    }
}

function getArrowCoords({ passWay, planScale }) {
    const { connectivityMargin } = passWay;
    const arrowsCoords = [];

    passWay.passLine.coordinates.forEach((point, i, arr) => {
        if (i === 0) return;
        const arrowEndPoint = getArrowPoint(point, i, arr, planScale || 1, connectivityMargin / 2, 'end');
        const arrowStartPoint = getArrowPoint(point, i, arr, planScale || 1, connectivityMargin / 2, 'start');
        arrowsCoords.push({ arrowEndPoint, arrowStartPoint });
    });
    return arrowsCoords;
}

function getPwPolygonIntersections({ polygonObject, info, passWay, geometryKeyName = 'coords', canEnclose = true }) {
    const result = [];
    const arrowsCoords = passWay.arrowsCoords;

    const isAllStartsIN = arrowsCoords.every((arrow) => {
        return isInside(polygonObject[geometryKeyName].coordinates, arrow.arrowStartPoint);
    });
    const isAllStartsOUT = arrowsCoords.every((arrow) => {
        return !isInside(polygonObject[geometryKeyName].coordinates, arrow.arrowStartPoint);
    });
    const isAllEndsIN = arrowsCoords.every((arrow) => {
        return isInside(polygonObject[geometryKeyName].coordinates, arrow.arrowEndPoint);
    });
    const isAllEndsOUT = arrowsCoords.every((arrow) => {
        return !isInside(polygonObject[geometryKeyName].coordinates, arrow.arrowEndPoint);
    });

    const isConnectedIN = isAllStartsOUT && isAllEndsIN;
    const isConnectedOUT = isAllStartsIN && isAllEndsOUT;
    const isConnectedENCLOSED = isAllStartsIN && isAllEndsIN;

    // isAllEndsOUT && console.log(passWay, isAllEndsOUT);

    if (isConnectedIN) {
        arrowsCoords.forEach((arrow) => {
            result.push({ coords: arrow.arrowEndPoint, info: Object.assign(info, { direction: 'IN' }) });
        });
    } else if (isConnectedOUT) {
        arrowsCoords.forEach((arrow) => {
            result.push({
                coords: arrow.arrowStartPoint,
                info: Object.assign(info, { direction: 'OUT' }),
            });
        });
    } else if (isConnectedENCLOSED && canEnclose) {
        arrowsCoords.forEach((arrow) => {
            result.push({
                coords: null,
                info: Object.assign(info, { direction: 'ENCLOSED' }),
            });
        });
    }

    return result;
}

module.exports = {
    isPointInsidePassWay,
    getPwPolygonIntersections,
    getArrowPoint,
    getArrowCoords,
    isInside,
    distanceFinder,
    findPolygonCenter,
    getPolygonForTurf,
};
