import styled from 'styled-components/macro';
import { Stack } from '@fluentui/react/lib/Stack';
import { Checkbox } from '@fluentui/react/lib/Checkbox';

export const ObjectOptionsWrapper = styled.div``;

export const StyledStack = styled(Stack)`
    .ms-TextField-fieldGroup {
        background-color: transparent;
    }
    label {
        padding-left: 0px;
    }
`;

export const StyledCheckbox = styled(Checkbox)`
    margin: 10px 0 !important;
    label {
        padding-left: 0px;
    }
    span {
        font-size: 14px !important;
    }
`;
