import React from 'react';
import { useSelector } from 'react-redux';
import { reducerValues } from '../../reducer';
import HeatMap from '../HeatMap/HeatMap';

/**
 * Компонент содержимое модального окна
 */
const Modal = ({ ...props }) => {
    const { sensors } = useSelector(reducerValues);

    const damagedSensors = sensors?.filter((sensor) => {
        return sensor.values.some((item) => item.v < 1);
    });

    return damagedSensors ? <HeatMap mode="full" sensors={damagedSensors} /> : null;
};

export default Modal;
