const tools = require('./tools');
const cloneDeep = require('lodash/cloneDeep');

function get_intersections({ zonesObjects, escalatorsObjects, planScale }) {
    var intersections = [];

    escalatorsObjects.forEach((escalator) => {
        const index = escalator.in_out === 'in' ? 1 : 0;
        const anchorPoint = escalator.line.coordinates[index];

        for (let i = 0; i < zonesObjects.length; i++) {
            const zone = zonesObjects[i];

            if (tools.isInside(zone.coords.coordinates, anchorPoint)) {
                const info = {
                    escalatorMarker: escalator.marker,
                    escalatorName: escalator.name,
                    zoneMarker: zone.zone_marker,
                    zoneName: zone.zone_name,
                    groupMarker: zone.group_marker,
                    groupName: zone.group_name,
                };
                intersections.push({ coords: anchorPoint, info });
            }
        }
    });

    return intersections;
}
module.exports = { get_intersections };
