export const layersAllowToPaste = [
    'pass_ways_layer',
    'zones_layer',
    // 'escalators_layer',
    // 'lifts_layer',
    // 'stairs_layer',
    // 'checkouts_layer',
    'pc_sensor_ipoints_layer',
    // 'radio_sensor_ipoints_layer',
    'places_layer',
    'perimeter_layer',
    // 'nav_polygons_layer',
    // 'tracks_tracking_layer',
    // 'tracks_navigation_layer',
    // 'tracks_wifi_layer',
];
