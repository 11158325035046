import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
    placesValues,
    storeCurrentPlanData,
    storeInitialCreatedAt,
    storeInitialObjects,
    toggleShowLabels,
    selectPlace,
    storeGeneralSettings,
    storeVersionsData,
    deletePlace,
    storeTenantsData,
    clearSelectedObjects,
    storeAnotherLocationNames,
    storeSelectedChapter,
    validateNames,
    storeHotAddObject,
    pasteObjectFromClipboard,
} from '../../../../places.reducer';
import { ObjectsListWrapper, ObjectWrapper, classNames } from './ObjectsList.styles';

import { FontIcon } from '@fluentui/react/lib/Icon';
import { IPlace } from '../../../../places.interfaces';
import { commonTools } from '../../../../../../layers';
// import {storeAnotherLocationNames, validateNames} from "../../../../../PassWays/passWays.reducer";

/**
 * Список объектов
 */
const ObjectsList = ({ ...props }) => {
    const scollToRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const {
        objects,
        createdAt,
        placesFrontId,
        layerAlias,
        isNamesValid,
        currentPlanData,
        generalSettings,
        selectedObjects,
    } = useSelector(placesValues);

    useEffect(() => {
        props.hotAddObject && dispatch(storeHotAddObject(props.hotAddObject));
    }, [props.hotAddObject]);

    useEffect(() => {
        if (props.clipboardData) {
            if (selectedObjects?.marker !== (props.clipboardData.obj as IPlace).marker) {
                props.toggleAlertTrans &&
                    props.toggleAlertTrans({
                        text: t('Wrong place marker selected'),
                        show: true,
                    });
            } else {
                dispatch(pasteObjectFromClipboard(props.clipboardData.obj));
            }
        }
    }, [props.clipboardData]);

    useEffect(() => {
        props.versionsData && dispatch(storeVersionsData(props.versionsData));
    }, [props.versionsData]);

    useEffect(() => {
        props.tenantsData && dispatch(storeTenantsData(props.tenantsData));
    }, [props.tenantsData]);

    useEffect(() => {
        props.revertLayer && dispatch(storeInitialObjects(props.initialData.data || []));
    }, [props.revertLayer]);

    useEffect(() => {
        placesFrontId !== props.selectedObjectId && dispatch(selectPlace({ placeFrontId: props.selectedObjectId }));
    }, [props.selectedObjectId]);

    useEffect(() => {
        props.layerChange({ id: layerAlias, objects, lastCreatedAt: createdAt, selectedObjectId: placesFrontId });
    }, [objects, placesFrontId]);

    useEffect(() => {
        dispatch(storeAnotherLocationNames(props.anotherLocationLayers));
    }, [props.anotherLocationLayers]);

    useEffect(() => {
        dispatch(storeSelectedChapter(props.selectedChapter));
    }, [props.selectedChapter]);

    useEffect(() => {
        dispatch(validateNames());
    }, [objects]);

    useEffect(() => {
        props.toggleValidateAlert({
            id: layerAlias,
            show: !isNamesValid.valid,
            floor: currentPlanData?.floor,
            names: isNamesValid.names,
        });
    }, [isNamesValid.valid]);

    useEffect(() => {
        dispatch(storeGeneralSettings(props.generalSettings));
    }, [props.generalSettings]);

    useEffect(() => {
        dispatch(storeCurrentPlanData(props.currentPlanData));
    }, [props.currentPlanData]);

    useEffect(() => {
        if (!props?.initialData) return;
        dispatch(storeInitialObjects(props.initialData.data || []));
        dispatch(storeInitialCreatedAt(props.initialData.createdAt));
    }, [JSON.stringify(props.initialData)]);

    useEffect(() => {
        dispatch(toggleShowLabels(props.showLabels));
    }, [props.showLabels]);

    useEffect(() => {
        dispatch(clearSelectedObjects());
    }, [props.active]);

    useEffect(() => {
        scollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }, [scollToRef, placesFrontId]);

    const onDelete = (frontId: string) => (e: React.SyntheticEvent) => {
        e.stopPropagation();
        dispatch(deletePlace({ placeFrontId: frontId }));
    };

    const onClickObject = (placeFrontId: string) => () => {
        dispatch(selectPlace({ placeFrontId }));
    };

    const objectsList = objects.map((object) => {
        const selected = object.front_id === placesFrontId;
        return selected ? (
            <div key={object.front_id} ref={scollToRef}>
                <ObjectWrapper key={object.front_id} selected={selected} onClick={onClickObject(object.front_id)}>
                    {commonTools.getObjectLabelByMode({ objNamingMode: props?.generalSettings?.objNamingMode, object })}
                    {!generalSettings.readOnly && (
                        <FontIcon iconName="Delete" className={classNames.salmon} onClick={onDelete(object.front_id)} />
                    )}
                </ObjectWrapper>
            </div>
        ) : (
            <ObjectWrapper key={object.front_id} selected={selected} onClick={onClickObject(object.front_id)}>
                {commonTools.getObjectLabelByMode({ objNamingMode: props?.generalSettings?.objNamingMode, object })}
                {!generalSettings.readOnly && (
                    <FontIcon iconName="Delete" className={classNames.salmon} onClick={onDelete(object.front_id)} />
                )}
            </ObjectWrapper>
        );
    });

    return <ObjectsListWrapper>{objectsList}</ObjectsListWrapper>;
};

export default ObjectsList;
