import { store } from '../../../../navPolygons.store';
import { Provider } from 'react-redux';
import React from 'react';
import ObjectOptions from './ObjectOptions';

export default ({ ...props }) => {
    return (
        <Provider store={store}>
            <ObjectOptions {...props} />
        </Provider>
    );
};
