const tools = require('./tools');
const cloneDeep = require('lodash/cloneDeep');

function get_intersections({ placesObjects, escalatorsObjects, planScale }) {
    var intersections = [];

    escalatorsObjects.forEach((escalator) => {
        const index = escalator.in_out === 'in' ? 1 : 0;
        const anchorPoint = escalator.line.coordinates[index];

        for (let i = 0; i < placesObjects.length; i++) {
            const place = placesObjects[i];

            if (tools.isInside(place.coords.coordinates, anchorPoint)) {
                const info = {
                    escalatorMarker: escalator.marker,
                    escalatorName: escalator.name,
                    placeMarker: place.marker,
                };
                intersections.push({ coords: anchorPoint, info });
            }
        }
    });

    return intersections;
}
module.exports = { get_intersections };
