import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import StairsReducer from './reducer';

export const store = configureStore({
    reducer: {
        StairsReducer,
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
