import * as React from 'react';
import { DatePicker, IDatePicker, mergeStyleSets, DefaultButton } from '@fluentui/react';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { IDateInputProps } from '../Versions.interfaces';
import { DateTime } from 'luxon';
import { VersionsContext } from '../Versions.context';
import { useContext } from 'react';
import useDayPickerStrings from '../../../constants/useDayPickerStrings';
import { commonTools } from '../../../tools/commonTools';

const styles = mergeStyleSets({
    root: {
        selectors: {
            '> *': {
                // marginBottom: 15
            },
        },
    },
    control: {
        maxWidth: 200,
        // marginBottom: 15
    },
});

const DateInput: React.FunctionComponent<IDateInputProps> = (props) => {
    const DAY_PICKER_STRINGS = useDayPickerStrings();
    const { changeDate } = useContext(VersionsContext);
    const [value, setValue] = React.useState<Date | undefined>(undefined);
    const datePickerRef = React.useRef<IDatePicker>(null);

    useEffect(() => {
        setValue(props.dateString ? new Date(props.dateString) : undefined);
    }, [props.counter, props.dateString]);

    useEffect(() => {
        let date: string | null = value ? DateTime.fromJSDate(value).toFormat('yyyy-MM-dd') : null;
        if (props.versionId) {
            changeDate({
                [props.mode]: date,
                versionId: props.versionId,
            });
        }
    }, [value, props.dateDisabled]);

    const onClick = React.useCallback((): void => {
        setValue(undefined);
        datePickerRef.current?.focus();
    }, []);

    const onParseDateFromString = React.useCallback(
        (newValue: string): Date => {
            return commonTools.getDateFromString({ value, newValue });
        },
        [value],
    );

    const placeholder = useMemo(() => {
        return value ? undefined : 'Now';
    }, [value]);

    return (
        <DatePicker
            disabled={props.dateDisabled}
            componentRef={datePickerRef}
            allowTextInput
            ariaLabel="Select a date. Input format is day slash month slash year."
            placeholder={placeholder}
            value={value}
            onSelectDate={setValue as (date?: Date | undefined | null) => void}
            formatDate={commonTools.onFormatDate}
            parseDateFromString={onParseDateFromString}
            className={styles.control}
            strings={DAY_PICKER_STRINGS}
        />
    );
};

export default DateInput;
