import styled from 'styled-components/macro';
import { mergeStyles, mergeStyleSets } from '@fluentui/react/lib/Styling';

const iconClass = mergeStyles({
    fontSize: 16,
    height: 16,
    width: 16,
});

export const classNames = mergeStyleSets({
    deepSkyBlue: [{ color: 'deepskyblue' }, iconClass],
    greenYellow: [{ color: 'greenyellow' }, iconClass],
    salmon: [{ color: 'salmon' }, iconClass],
});

export const ObjectsListWrapper = styled.div`
    max-height: 200px;
    display: flex;
    flex-direction: column;
    overflow: auto;
`;

export const ObjectWrapper = styled.div<{ selected: boolean }>`
    cursor: pointer;
    padding: 5px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    font-weight: ${(p) => (p.selected ? '700' : '400')};
    display: flex;
    align-items: center;
    justify-content: space-between;
`;
