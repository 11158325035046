import styled from 'styled-components/macro';

export const Wrapper = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 3px 0;
`;
export const ItemWrapper = styled.div`
    display: flex;
    padding-left: 10px;
    font-size: 14px;
`;
